import styled from 'styled-components';

export const Wrapper = styled.div `
  margin-bottom: 40px;
  @media (max-width: 480px){
    margin-bottom: 30vh;
  }
    button{
      cursor: pointer;
    }
    .ButtonsGroup_talk {
      // color: #437CAE;

      font-weight: 400;
      font-size: 0.8rem;
      color: #fff;
      height: 35px;
      margin: 5px;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: #437cae;
      // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
      // text-shadow: 0px -2px #2e5577;
      border-radius: 999rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      &:hover{
        background-color: #2e5577;
        color: #eee;
      }
    }
    .ButtonsGroup_takeOff {
      font-weight: 400;
      font-size: 0.8rem;
      color: #fff;
      height: 35px;
      margin: 5px;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: #437cae;
      // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
      // text-shadow: 0px -2px #2e5577;
      border-radius: 999rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      &:hover, &.active{
        background-color: #2e5577;
        color: #eee;
      }
    }
    .ButtonsGroup_disable {
      font-weight: 400;
      font-size: 0.8rem;
      color: #333;
      height: 35px;
      margin: 5px;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: rgba(216,216,216,.6);
      box-shadow: outset 0 -0.125rem rgba(0,0,0,0.2);
      border-radius: 999rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .a1{
      text-align: center;
      width: 100%;
      background-color: #DF1C2C;
      border: 0 none;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: large;
      font-weight: bolder;
      color: #fff;
      border-radius: 5px;
      font-weight: bolder;
    }
    .b1{
      text-align: center;
      width: 100%;
      background-color: #D5D5D5;
      border: 0 none;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: large;
      font-weight: bolder;
      color: #fff;
      border-radius: 5px;
      font-weight: bolder;
    }
`;
