import styled from 'styled-components';

export const Wrapper = styled.button `
    width: 100% !important;
    background-color: #DF1C2C;
    border: 0 none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    img{
        height: 18px;
        padding-left: 5px;
        margin-bottom: 1px;
    }
`

