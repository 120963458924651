import React, { Component } from "react";
import { connect } from "react-redux";
import {
    fetchUserInfo,
    sendUserInfo,
    sendUserPassword,
    getoccupationlist,
    getHobbyList,
    getLevels,
    sendVerifyMail,
} from "../../../actions";
import { Wrapper, List } from "./styled";
import { Modal } from "../../../component/Common";
import Banner from "../Banner";
import ModalContent from "./ModalContent";

// let valueArr = [];
class MyInfoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false
        };
    }
    componentDidMount() {
        this.props.getUserInfo();
        this.props.getoccupationlist();
        this.props.getHobbyList();
        this.props.getLevels();
        this.setState({
            phone: "",
            pass: "",
            authPass: "",
            name: this.props.userInfo.name,
            gender: this.props.userInfo.gender,
            year: this.props.userInfo.year,
            month: this.props.userInfo.month,
            day: this.props.userInfo.day,
        });
    }
    componentWillReceiveProps(nextProps) {
        const hobbyArr = [];
        if (this.props.userInfo !== nextProps.userInfo) {
            this.setState(nextProps.userInfo, () => {
                //console.log(nextProps.data.hobby);
                nextProps.userInfo.hobby && nextProps.userInfo.hobby.map(item => hobbyArr.push(item.id));
                this.setState({
                    hobby: hobbyArr
                });
            });
        }
    }

    handleInputChange = e => {
        const target = e.target;
        const value = target.value;
        const inputName = target.name;

        if (inputName === "hobby") {
        } else {
            this.setState({
                [inputName]: value
            });
        }
    };

    handlePhoneInputChange = value => {
        this.setState({
            phone: value
        });
    }

    handleHobbyToggle = hobby => {
        this.setState({
            hobby: hobby
        });
    };

    handleOpenModal = type => {
        this.setState({
            type: type,
            modalIsOpen: true
        });
    };

    applyForAffiliate = () => {
        this.props.sendUserInfo({affiliateStatus: 1})
    }

    handlePhoneSubmit = () => {
        this.props.getUserInfo();
    }

    handleSubmit = data => {
        const object =
            typeof this.state[data] === "object" &&
            !Array.isArray(this.state[data]);
        this.props.sendUserInfo({
            [data]: object ? this.state[data].id : this.state[data]
        });

        if (data === 'birthday') {
            this.props.sendUserInfo({
                'year': this.state.year,
                'month': this.state.month,
                'day': this.state.day,
            });
        }

        if (data === 'address') {
            this.props.sendUserInfo({
                'city': this.state.city,
                'home': this.state.home,
            });
        }

        // valueArr = [];
    };

    handlePasswordSubmit = () => {
        this.props.sendUserPassword(this.state.pass);
    };

    render() {
        const {
            name,
            gender,
            year,
            month,
            day,
            phone,
            pass,
            authPass,
            city,
            home,
            occupation,
            badminton_level,
        } = this.state;
        const { userInfo, occupations, levels, hobby } = this.props;
        const Hobbies =
            userInfo.hobby &&
            userInfo.hobby.map(
                (item, i, arr) =>
                    i <= 2 && (
                        <span key={i}>
                            {item.name} {i === 2 && arr.length > 3 && "..."}
                        </span>
                    ) 
            );
        return [
            <Banner key={0} />,
            <Wrapper key={1} style={{marginBottom: `120px`}}>
                <h2>個人資料</h2>
                <List>
                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("name");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                label="姓名"
                                name="name"
                                value={name}
                                placeholder="姓名"
                                note="可輸入中英文及數字(共計20字元內)"
                                onChange={this.handleInputChange}
                            />
                        }
                    >
                        <i className="material-icons">
                            person
                        </i>
                        {userInfo.name}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("gender");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                label="性別"
                                name="gender"
                                value={gender}
                                placeholder="性別"
                                onChange={this.handleInputChange}
                            />
                        }
                    >
                        <i className="material-icons">
                            wc
                        </i>
                        {userInfo.gender === 1 ? '男' : userInfo.gender === 2 ? '女' : '未輸入'}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("birthday");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                name="birthday"
                                label="出生年"
                                value={year}
                                placeholder="西元年"
                                onChange={this.handleInputChange}

                                label2='出生月份'
                                value2={month}
                                placeholder2="月份"
                                onChange2={this.handleInputChange}

                                label3='出生日期'
                                value3={day}
                                placeholder3="日期"
                                onChange3={this.handleInputChange}
                            />
                        }
                    >
                        <i className="material-icons">
                            cake
                        </i>
                        {userInfo.year ? '西元' + userInfo.year + '年' + userInfo.month + '月' + userInfo.day + '日' : '未輸入'}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <button className="flexBox" onClick={ ()=> {this.props.sendVerifyMail(userInfo.email) }}>
                        <i className="material-icons">
                            email
                        </i>
                        <span className="email">
                            {userInfo.email}
                        </span>
                        <div 
                            className='verified'
                            style={ userInfo.verified ? {display: 'inline-block'} : {display: 'inline-block', color: `rgb(218, 38, 60)`, borderColor: `rgb(218, 38, 60)`} }
                        >
                            {userInfo.verified ? '已驗證': '未驗證' }
                        </div>
                        <i className="material-icons arrow-right">chevron_right</i>
                    </button>

                    <Modal
                        title="修改個人資料"
                        btnClass="flexBox"
                        btnTheme="one"
                        text2="關閉"
                        onClick={() => this.handlePhoneSubmit()}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                label="行動電話"
                                name="phone"
                                value={phone}
                                placeholder="請輸入您的行動電話"
                                onChange={this.handlePhoneInputChange}
                                phoneVerified={userInfo.phoneVerified}
                                message={this.props.message}
                                userId={userInfo.id}
                            />
                        }
                    >
                        <i className="material-icons">
                            phone_iphone
                        </i>
                        <div style={{flex: `1`}}>
                            {phone}
                        </div>
                        <div 
                            className='verified'
                            style={ userInfo.phoneVerified ? {display: 'inline-block'} : {display: 'inline-block', color: `rgb(218, 38, 60)`, borderColor: `rgb(218, 38, 60)`} }
                        >
                            {userInfo.phoneVerified ? '已驗證': '未驗證' }
                        </div>
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            const { pass, authPass } = this.state;
                            let msg = '';
                            let passwordRule = /^(?=.*[a-zA-Z]).{6,15}$/;
                            if (!pass) {
                                msg = '您尚未輸入密碼。';
                            } else if (pass !== authPass || pass.search(passwordRule) === -1) {
                                msg = '您的密碼有誤，請再確認。';
                            }

                            if (msg) {
                                alert(msg);
                                return false;
                            }else{
                                this.handlePasswordSubmit();
                            }
                        }}
                        onClose={() => {
                            this.setState({ pass: "", authPass: "" });
                        }}
                        modalContent={
                            <ModalContent
                                type="password"
                                label="請輸入新密碼"
                                name="pass"
                                value={pass}
                                placeholder="請輸入新密碼(包含英文及數字6個字元)"
                                label2="請再確認密碼"
                                name2="authPass"
                                value2={authPass}
                                placeholder2="請再次輸入您的新密碼"
                                onChange={this.handleInputChange}
                            />
                        }
                    >
                        {/*<img src="/assets/icons/MyBonnyIcon04.png" />*/}
                        <i className="material-icons">
                            lock
                        </i>
                        *******
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("address");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                name="home"
                                label="居住縣市"
                                value={city}
                                onChange={this.handleInputChange}

                                label2="地址"
                                value2={home}
                                onChange2={this.handleInputChange}
                            />
                        }
                    >
                        <i className="material-icons">
                            home
                        </i>
                        {(userInfo.city ? userInfo.city : '') + userInfo.home}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("occupation");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                label="職業"
                                name="occupation"
                                value={occupation && occupation.id}
                                occupations={occupations}
                                placeholder="請選擇您的職業"
                                onChange={this.handleInputChange}
                            />
                        }
                    >
                        {/*<img src="/assets/icons/MyBonnyIcon06.png" />*/}
                        <i className="material-icons">
                            business_center
                        </i>
                        {occupation && occupation.name}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("badminton_level");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                label="請選擇您的打球程度"
                                name="badminton_level"
                                value={badminton_level && badminton_level.id}
                                levels={levels}
                                placeholder="請選擇您的打球程度"
                                onChange={this.handleInputChange}
                            />
                        }
                    >
                        <img src="/assets/icons/badminton.svg" style={{width: `25px`}} alt="" />
                        {badminton_level ? badminton_level.description : '未輸入'}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>

                    <Modal
                        title="修改個人資料"
                        onClick={e => {
                            this.handleSubmit("hobby");
                        }}
                        modalIsOpen={this.state.modalIsOpen}
                        modalContent={
                            <ModalContent
                                label="興趣/目的: (可複選)"
                                name="hobby"
                                hobbyList={hobby}
                                value={this.state.hobby}
                                placeholder="興趣/目的: (可複選)"
                                onChange={this.handleHobbyToggle}
                            />
                        }
                    >
                        {/*<img src="/assets/icons/MyBonnyIcon08.png" />*/}
                        <i className="material-icons">
                            favorite
                        </i>
                        {Hobbies}
                        <i className="material-icons arrow-right">chevron_right</i>
                    </Modal>
                    {
                        userInfo.affiliateStatus === 0 || userInfo.affiliateStatus === 3 ?
                        ""
                        :
                            userInfo.affiliateStatus === 2 ?
                            <div className="memberLevel">
                                <i className="material-icons">assignment_ind</i>
                                <span>{"分銷商"}</span>
                            </div>
                            :
                            <div className="memberLevel flexBox">
                                <i className="material-icons icon">assignment_ind</i>
                                <span style={{fontWeight: `bold`}}>{"分銷商資格審核中"}</span>
                                <i className="material-icons arrow" style={{opacity: `.6`}}>timelapse</i>
                            </div>
                    }
                    {/* {
                        userInfo.affiliateStatus === 0 ?
                        <div className="memberLevel flexBox hoverStyle" onClick={this.applyForAffiliate} style={{cursor: `pointer`,}}>
                            <i className="material-icons icon" style={{color: `#da263c`, }}>assignment_ind</i>
                            <span style={{fontWeight: `bold`, color: `#da263c`}}>{"申請成為分銷商"}</span>
                            <button>點此申請</button>
                        </div>

                        // <a className="applyAffiliateButton" onClick={this.applyForAffiliate}>
                        //     申請成為分銷商
                        // </a>
                        :
                        ""
                    } */}
                </List>
            </Wrapper>
        ];
    }
}

const mapStateToProps = state => ({
    userInfo: state.myInfoReducer.userInfo,
    occupations: state.registerReducer.occupation,
    levels: state.newGroupReducer.levels,
    hobby: state.myInfoReducer.hobby,
    message: state.registerReducer.setMobileVerifyMessage,
});

const mapDispatchToProps = {
    getUserInfo: fetchUserInfo,
    sendUserInfo: sendUserInfo,
    sendUserPassword,
    getoccupationlist: getoccupationlist,
    getLevels: getLevels,
    getHobbyList: getHobbyList,
    sendVerifyMail: sendVerifyMail,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInfoDetail);
