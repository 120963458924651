import styled from "styled-components";

export const Wrapper = styled.div`
    select {
        width: 100%;
        text-align: center;
        text-align-last: center;
    }
`;

export const DatePickerWrapper = styled.div`
	display: flex;
	align-items: center; 
	justify-content: center;
	.startDate {
		flex: 1;
		margin-right: 5px;
	}
	.endDate {
		flex: 1;
	}
	span {
		width: 2rem;
		text-align: center;
	}
`;

export const TimePickerWrapper = styled.div`
	display: flex;
	align-items: center; 
	justify-content: center;
    select {
        flex: 1;
        text-align: center;
        text-align-last: center;
    }
	span {
		width: 2rem;
		text-align: center;
	}
`;
