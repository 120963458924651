import React, { Component } from "react";
import { Wrapper } from './styled.js';

class LoadingIndicator extends Component {
  render() {
    return (
		<Wrapper shouldLittleIcon = {this.props.shouldLittleIcon} isCoverPhoto={this.props.isCoverPhoto}
    isAvatarPhoto={this.props.isAvatarPhoto}>
        <div id="Loading" className={[
          this.props.shouldOpen ? "" : 'close',
          this.props.shouldLittleIcon ? 'shouldLittleIcon' : '',
        ].join(' ')}>
          <div className="lds-css ng-scope" style={{width:'100%',height:'100%',}}>
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default LoadingIndicator;
