import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getNotification, setNotificationStatus } from '../../../actions';
import { Wrapper, ListContent, } from './styled';
import Banner from '../Banner';
const boldStyle = {
    fontWeight: `bold`,
    fontSize: `0.9rem`,
};
class MyNotice extends Component {
    componentDidMount() {
        this.props.getNotification();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.data.status && nextProps.data.status.reload) {
            this.props.getNotification();
            if (nextProps.data.status.url) {
                this.props.history.push(nextProps.data.status.url);
            }
        }
    }
    handleRead = ({ id, url }) => {
        this.props.setNotificationStatus({ id, url });
    };
    render() {
        const { data } = this.props;
        const readNotice = data.notice.filter(n => n.read);
        const unreadNotice = data.notice.filter(n => !n.read);
        const unreadSize = unreadNotice.length;
        return [
            <Banner key={0}/>,
            <Wrapper key={1}>
                <h2>我的通知</h2>
                <Tabs>
                    <TabList>
                        <Tab>全部</Tab>
                        <Tab>
                            <span className="unread">
                                未讀
                                {unreadSize > 0 ?
                                <span className="more">
                                    {unreadSize > 99 ? '99+' : unreadSize}
                                </span>
                                :
                                ''
                                }
                            </span>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <TabContent
                            data={readNotice}
                            markedRead={this.handleRead}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TabContent2
                            data={unreadNotice}
                            markedRead={this.handleRead}
                        />
                    </TabPanel>
                </Tabs>
            </Wrapper>
        ];
    }
}
class TabContent extends Component {
    render() {
        const { data, markedRead } = this.props;
        return <List key={'List' + data.id} notice={data} markedRead={markedRead} />;
    }
}

class TabContent2 extends Component {
    render() {
        const { data, markedRead } = this.props;
        return <List key={'List' + data.id} notice={data} markedRead={markedRead} />;
    }
}

class List extends Component {
    handleRead = ({ id, url }) => {
        this.props.markedRead({ id, url });
    }

    noticeSwitch = (n, data, userInfo) => {
        const { activity, media, payment, order, shipment } = data;
        switch (n.type) {
            case 'partnerActivity': {
                return [
                    <span key={n.id}>
                        親愛的「
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        」，您有一個固定活動
                        活動「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」已再次開團。
                    </span>
                ]
            }
            case 'joinActivityInitiator': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        已經報名參加了
                        你的活動「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」。
                    </span>
                ]
            }
            case 'joinActivityMember': {
                return [
                    <span key={n.id}>
                        您已經報名了
                        <span style={boldStyle}>
                            {moment(data && data.activityStartDate).format('YYYY年MM月DD日 LT')}
                        </span>
                        的
                        「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」。
                    </span>
                ]
            }
            case 'paymentSuccessInitiator': {
                return [
                    <span key={n.id}>
                        您的活動「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」於
                        <span style={boldStyle}>
                            {moment(n.createdAt).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        收到了
                        <span style={boldStyle}>
                            {data && data.userName}
                        </span>
                        的款項。
                    </span>
                ]
            }
            case 'cancelActivityInitiator': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        已經請假/取消參加了
                        你的活動「
                        <span style={boldStyle}>
                            { data && data.groupName}
                        </span>
                        」。
                    </span>
                ]
            }
            case 'cancelActivityMember': {
                return [
                    <span key={n.id}>
                        請假/取消成功通知：<br/>
                        您已經請假/取消報名「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」。
                    </span>
                ]
            }
            case 'paymentSuccess': {
                return [
                    <span key={n.id}>
                        {/* 付款方式 */}
                        付款成功通知：您
                        <span style={boldStyle}>
                            {moment(n.createdAt).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        所報名的活動「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」已成功扣款，
                        提醒您此活動於
                        <span style={boldStyle}>
                            {moment(n.refundPeriod).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        後僅供請假服務，不提供退款服務。
                    </span>
                ]
            }
            case 'paymentFail': {
                return [
                    <span key={n.id}>
                        付款失敗通知：親愛的「
                        <span style={boldStyle}>
                            {userInfo && userInfo.name}
                        </span>
                        」，您於
                        <span style={boldStyle}>
                            {moment(n.createdAt).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        線上付款的活動「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」付費失敗。
                    </span>
                ]
            }
            case 'cancelPayment': {
                return [
                    <span key={n.id}>
                        退刷成功通知： 您於
                        <span style={boldStyle}>
                            {moment(n.createdAt).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        退刷報名的活動「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」已退刷成功。
                    </span>
                ]
            }
            case 'invoiceSuccess': {
                return [
                    <span key={n.id}>
                        發票開立通知： 您報名的團「
                        <span style={boldStyle}>
                            {data && data.groupName}
                        </span>
                        」已於
                        <span style={boldStyle}>
                            {moment(n.createdAt).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        開立發票。
                    </span>
                ]
            }
            case 'activity_reply': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        在活動「
                        <span style={boldStyle}>
                            {activity && activity.group_name}
                        </span>
                        」
                        新增了留言：「
                        <span style={boldStyle}>
                            { data && data.message}
                        </span>
                        」。
                    </span>
                ]
            }
            case 'media_reply': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        在影片「
                        <span style={boldStyle}>
                            {media && media.title}
                        </span>
                        」
                        新增了留言：「
                        <span style={boldStyle}>
                            { data && data.message}
                        </span>
                        」。
                    </span>
                ]
            }
            case 'activity_create': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        新增了活動「
                        <span style={boldStyle}>
                            {activity && activity.group_name}
                        </span>
                        」，快來報名參加吧。
                    </span>
                ]
            }
            case 'media_create': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        新增了影片「
                        <span style={boldStyle}>
                            {media && media.title}
                        </span>
                        」，你快來看看吧。
                    </span>
                ]
            }
            case 'activity_cancelled': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        已經取消「
                        <span style={boldStyle}>
                            {activity && activity.group_name}
                        </span>
                        」，這個活動，不要白跑囉。
                    </span>
                ]
            }
            case 'activity_invited': {
                return [
                    <span key={n.id}>
                        <span style={boldStyle}>
                            {data && data.userName} 
                        </span>
                        已經邀請你來加入「
                        <span style={boldStyle}>
                            {activity && activity.group_name}
                        </span>
                        」，這個活動，不要忘記參加囉。
                    </span>
                ]
            }
            case 'order': {
                return [
                    <span key={n.id}>
                        蓮心專案申請通知：
                        <br/>
                        親愛的「
                        <span style={boldStyle}>
                            {order.user}
                        </span>
                        」，您已於
                        <span style={boldStyle}>
                            {moment(order.date).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        成功完成「
                        <span style={boldStyle}>
                            {
                                order.payment_type === 'A' ? "一次付清"
                                : 
                                order.payment_type === 'Y' ? "年繳專案"
                                : 
                                "月繳專案"
                            }
                        </span>
                        」
                        申請。
                    </span>
                ]
            } 
            case 'order_success': {
                return [
                    <span key={n.id}>
                        蓮心專案申請成功通知：
                        <br/>
                        親愛的「
                        <span style={boldStyle}>
                            {order.user}
                        </span>
                        」，您已於
                        <span style={boldStyle}>
                            {moment(order.date).format('YYYY年MM月DD日 LT')}
                        </span>
                        成功完成「
                        <span style={boldStyle}>
                            {
                                order.payment_type === 'A' ? "一次付清"
                                : 
                                order.payment_type === 'Y' ? "年繳專案"
                                : 
                                "月繳專案"
                            }
                        </span>
                        」申請。
                    </span>
                ]
            }
            case 'payment_success': {
                if (payment.replace_times === 0) {
                    return [
                        <span key={n.id}>
                            蓮心專案付款完成通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {payment.user}
                            </span>
                            」，您已於
                            <span style={boldStyle}>
                                {moment(payment.date).format('YYYY年MM月DD日 HH:mm:ss')}
                            </span>
                            申請之蓮心專案付款完成。
                        </span>
                    ]
                } else if (payment.replace_times === 1){
                    return [
                        <span key={n.id}>
                            蓮心專案付款完成通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {payment.user}
                            </span>
                            」，您已於
                            <span style={boldStyle}>
                                {moment(payment.date).format('YYYY年MM月DD日 HH:mm:ss')}
                            </span>
                            申請之蓮心專案首次換拍已付款完成。
                        </span>
                    ]
                } else if (payment.replace_times === 2){
                    return [
                        <span key={n.id}>
                            蓮心專案付款完成通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {payment.user}
                            </span>
                            」，您已於
                            <span style={boldStyle}>
                                {moment(payment.date).format('YYYY年MM月DD日 HH:mm:ss')}
                            </span>
                            申請之蓮心專案二次換拍已付款完成。
                        </span>
                    ]
                }
                break
            } 
            case 'payment_failed': {
                return [
                    <span key={n.id}>
                        蓮心專案付款失敗通知：
                        <br/>
                        親愛的「
                        <span style={boldStyle}>
                            {payment.user}
                        </span>
                        」，您已於
                        <span style={boldStyle}>
                            {moment(payment.date).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        申請之蓮心專案付款失敗...
                    </span>
                ]
            }  
            case 'replace': {
                if (order.replace_times !== 2) {
                    return [
                        <span key={n.id}>
                            蓮心專案申請成功通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {order && order.user}
                            </span>
                            」，您已成功完成蓮心專案換拍申請。
                        </span>
                    ]
                } else if (order.replace_times === 2){
                    return [
                        <span key={n.id}>
                            蓮心專案申請成功通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {order && order.user}
                            </span>
                            」，您已成功完成第二次蓮心專案換拍申請。
                        </span>
                    ]
                }
                break
            } 
            case 'replace_payment': {
                if (order.replace_times !== 2) {
                    return [
                        <span key={n.id}>
                            蓮心專案申請核定通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {order && order.user}
                            </span>
                            」，你的蓮心專案申請換拍核定已通過。
                        </span>
                    ]
                } else if (order.replace_times === 2){
                    return [
                        <span key={n.id}>
                            蓮心專案申請核定通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {order && order.user}
                            </span>
                            」，你的蓮心專案第二次申請換拍核定已通過。
                        </span>
                    ]
                }
                break
            } 
            case 'replace_failed': {
                return [
                    <span key={n.id}>
                        蓮心專案核定未通過通知：
                        <br/>
                        親愛的「
                        <span style={boldStyle}>
                            {order && order.user}
                        </span>
                        」，您所申請之蓮心專案換拍審核未通過。
                    </span>
                ]
            } 
            case 'shipment': {
                if (shipment.replace_times === 0){
                    return [
                        <span key={n.id}>
                            蓮心專案出貨通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {shipment && shipment.user}
                            </span>
                            」你申請的蓮心專案球拍已經出貨。
                        </span>
                    ]
                } else if(shipment.replace_times >= 1) {
                    return [
                        <span key={n.id}>
                            蓮心專案出貨通知：
                            <br/>
                            親愛的「
                            <span style={boldStyle}>
                                {shipment && shipment.user}
                            </span>
                            」你的蓮心專案換拍申請之球拍已經出貨。
                        </span>
                    ]
                }
                break
            } 
            case 'cancel': {
                return [
                    <span key={n.id}>
                        蓮心專案申請取消通知：
                        <br/>
                        親愛的「
                        <span style={boldStyle}>
                            {order.user}
                        </span>
                        」，您已於
                        <span style={boldStyle}>
                            {moment(order.date).format('YYYY年MM月DD日 HH:mm:ss')}
                        </span>
                        申請取消申請「
                        <span style={boldStyle}>
                            {order.payment_type === 'A' ? "一次付清"
                            : order.payment_type === 'Y' ? "年繳專案"
                                : "月繳專案"}
                        </span>
                        」。
                    </span>
                ]
            } 
            case 'expiration': {
                return [
                    <span key={n.id}>
                        蓮心專案通知：<br/>
                        親愛的「
                        <span style={boldStyle}>
                            {payment.user}
                        </span>
                        」，您的信用卡已經過期，請聯絡信用卡銀行確認...
                    </span>
                ]
            }
            default: {
                return null
            }
        }
    }

    render() {
        const { notice } = this.props;
        return (
            <ListContent key={2}>
                {notice &&
                    notice.map((n, i) => {
                        const data = n.data;
                        const userInfo = n.user;
                        const { user, activity } = data;
                        const typeForAttendee = ['joinActivityMember', 'paymentSuccess', 'cancelActivityMember', 'paymentFail', 'cancelPayment', 'invoiceSuccess', 'partnerActivity'];
                        const typeForHost = ['cancelActivityInitiator', 'paymentSuccessInitiator', 'joinActivityInitiator'];
                        const typeForRent = ['order', 'order_success', 'order_failed', 'payment_success', 'payment_failed', 'replace', 'replace_payment', 'replace_failed', 'shipment', 'cancel', 'expiration'];
                        
                        const url = 
                            n.type === 'activity_invited' ? `/sign_up_activity?${activity.id}` // activity_invited 團主發出活動邀請
                            :
                            n.type === 'activity_cancelled' ? `/my_info/participated_activities` // activity_cancelled 團主將活動取消
                            :
                            typeForAttendee.indexOf(n.type) !== -1 && data.activityId && data.joinId ? `/my_info/participated_activity_detail/${data.activityId}/${data.joinId}`
                            :
                            typeForHost.indexOf(n.type) !== -1 && data.activityId ? `host_activity_detail/${data.activityId}`
                            :
                            typeForRent.indexOf(n.type) !== -1 ? `/my_info/notification_detail/${n.id}`
                            :
                            '';

                        return (
                            <div
                                key={n.id}
                                onClick={() => {
                                    return this.handleRead({
                                        id: n.id,
                                        url
                                    });
                                }}
                            >
                                <div className="list">
                                    <div className="Out_left">
                                        <div className="avatar">
                                            <div className="avatar_photo" style={{backgroundImage: `url('${(user && user.avatar) || `/assets/images/DefaultUserImg.png`}')`}}></div>
                                        </div>
                                    </div>
                                    <div className="Out_right">
                                        <div className="date">
                                            通知時間：
                                            {moment(n.createdAt).startOf('minute').fromNow()}
                                        </div>
                                        <div className="inside">
                                            <div className="In_left">
                                                    {
                                                        user && user.name ?
                                                            <div className="name">
                                                                {user && user.name}
                                                            </div>
                                                        :
                                                        <div className="name">
                                                            <span className="bonny">波力系統通知</span>
                                                        </div>
                                                    }

                                                <div className="message">
                                                    {this.noticeSwitch( n, data, userInfo )}
                                                </div>
                                            </div>
                                            <div className="In_right">
                                                <i className="material-icons" >chevron_right</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </ListContent>
        );
    }
}

const mapStateToProps = state => ({
    data: state.notificationReducer
});

const mapDispatchToProps = {
    getNotification: getNotification,
    setNotificationStatus: (id, url) => setNotificationStatus(id, url),
};

export default connect(mapStateToProps, mapDispatchToProps)(MyNotice);
