import React from "react";
import { DateTimePicker, FormControl } from "../../../component/Common";


export default ({ label, date, time, name, onDateChange, onTimeChange }) => (
    <FormControl>
        <label>{label}</label>
        <DateTimePicker 
        	date={date} 
        	time={time} 
        	name={name} 
        	onDateChange={onDateChange} 
        	onTimeChange={onTimeChange} />
    </FormControl>
);
