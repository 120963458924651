
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items:center; 
    justify-content:center; 
    flex-wrap: wrap; 

    .paymentType__tag{
        width: 7rem;
        height: 35px;
        line-height: 35px;

        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;

        // padding: 1.5% 0.5% .6%;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: white;
        background: #2f82bf;
        border: 1px solid rgba(#2f82bf, 1);
        border-right: unset;
        // box-shadow: 0 0 0 0 rgba(#2f82bf, 1);
    }  
    input[type="number"] {
        flex: 1;
        border-left: unset;
        height: calc( 35px - ( 0.99px * 2 ) );
    }
`