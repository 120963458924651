import React, { Component } from "react";
import { connect } from "react-redux";
import SubmitButton from '../../Login/SubmitButton';
import RememberMeClick from '../../Login/RememberMeClick';
import { Wrapper } from '../../Login/styled';
import { LoadingIndicator, GaSetting } from "../../Common";
import { userLogin } from "../../../actions";
import UAParser from 'ua-parser-js';
import moment from 'moment-timezone';
import Forget from '../../Forget';

class Login extends Component {
    constructor(props) {
        super(props);
        const state = this.props.location.state;
        this.state = {
            account: JSON.parse(localStorage.getItem('Remember')) || '',
            check: JSON.parse(localStorage.getItem('rememberMe')) || '',
            agent: {},
            clientId: state && state.clientId,
            redirectUri: state && state.redirectUri,
            scope: state && state.scope,
            isForgetPassword: false,
        }

        this.accountRef = React.createRef();
        this.pwRef = React.createRef();
    }

    componentDidMount() {
        const parser = new UAParser();
        let ua = navigator.userAgent
        this.setState({
            agent: {
                browser: parser.setUA(ua).getBrowser(),
                os: parser.setUA(ua).getOS(),
                device: parser.setUA(ua).getDevice(),
            }
        }) 
    }

    handleInputChange = (event) => {
        // 從 event object 拿到 target
        const target = event.target;
        // 從 target.type 可以知道欄位的 type
        // 或從 target.value 取出輸入的欄位值
        const value = target.value;
        // 從 target.name 得到該欄位設定的 name
        const name = target.name;
        // 分別更新不同 name 欄位的 state
        this.setState({
            [name]: value
        });
    }

    async loginWaitSend (e) {
        let playerId = (localStorage && localStorage.getItem('playerId')) ? localStorage.getItem('playerId') : null
        
        const { clientId, redirectUri, scope, account, password, agent } = this.state;
        const body = {
            clientId: clientId,
            redirectUri: redirectUri,
            scope: scope,
            email: account || this.accountRef.current.value,
            password: password || this.pwRef.current.value,
            rememberMe: (localStorage.getItem('rememberMe') === 'true'),
            agent: agent,
            playerId: playerId
        };
        await this.props.userLogin(body);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        //驗證電子郵件
        const strEmail = this.state.account || this.accountRef.current.value;
        const emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;

        if (Boolean(strEmail)) {
            if (strEmail.search(emailRule) !== -1) {
                let isRememberMe = localStorage && localStorage.getItem('rememberMe')
                if (isRememberMe === 'false') {
                    let expiresTime = moment().tz("Asia/Taipei").add(1, "day").format();
                    localStorage.setItem('expiresTime', expiresTime);
                } else {
                    // 點選記住我，設置登入過期時間
                    let expiresTime = moment().tz("Asia/Taipei").add(7, "day").format();
                    localStorage.setItem('expiresTime', expiresTime);
                }
                this.loginWaitSend();
            } else {
                alert("請輸入正確的E-Mail帳號");
            }
        } else {
            alert("請輸入正確的E-Mail帳號");
        }
    }

    render() {
        const { clientId, redirectUri, scope } = this.state; 
        const buttonStyle = {
            width: `100%`,
            padding: `5px 0`,
            border: `1px solid #000`,
            borderRadius: `5px`,
            color: `#000`,
            cursor: `pointer`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            fontSize: `1.2rem`,
            fontWeight: `500`,
        }  
        return (
            this.state.isForgetPassword === false ?
            <Wrapper key={1}>
                <form className="main-form">
                    <h1 className="title">我要登入</h1>
                    <div className="form-control">
                        <input 
                            name='account' 
                            type="text" 
                            autocomplete="username"
                            value={this.state.account} 
                            onChange={this.handleInputChange} 
                            placeholder="帳號(請填入E-Mail)" 
                            ref={this.accountRef}/>
                    </div>
                    <div className="form-control">
                        <input 
                            name='password'
                            type="password"
                            autocomplete="current-password"
                            value={!this.state.password ? "" : this.state.password} 
                            onChange={this.handleInputChange} 
                            placeholder="密碼" 
                            ref={this.pwRef}/>
                    </div>
                    <SubmitButton onClick={this.handleSubmit}/>
                    <div style={{marginTop: `10px`}}></div>
                    <div style={{display: `flex`, justifyContent: `space-between`, alignItems: `center`}}>
                        <RememberMeClick remember={this.state.account} onClick={this.handleclick} check={this.state.check} />
                        <div onClick={()=>{this.setState({isForgetPassword: true})}} style={{color: `#000`, fontSize: `18px`, fontWeight: `bold`, cursor: `pointer`}} >
                            忘記密碼?
                        </div>
                    </div>
                    <div style={{marginTop: `40px`}}></div>
                    <hr style={{paddingBottom: '10px', borderStyle: 'none',borderTop: '0.5px solid rgba(0,0,0,.5)',}}/>
                    <div style={{marginTop: `20px`}}></div>
                    <h1 className="Thirdtext">還不是會員嗎?</h1>
                    <div style={{marginTop: `20px`}}></div>
                    <GaSetting 
                        data = {{ 
                            category: 'Oauth', 
                            action: 'Click', 
                            label: 'User go to the register', 
                            tag: 'link',
                            title: '立即註冊',
                            path: {
                                pathname: '/dialog/register',
                                state: {
                                    clientId: clientId,
                                    redirectUri: redirectUri,
                                    scope: scope,
                                }
                            },
                            style: {
                                width: `100%`,
                                display: `block`,
                                marginTop: `10px`,
                            }
                        }}
                    >
                        <div style={buttonStyle}>
                            立即註冊
                            <i className="material-icons" style={{fontSize: `2.4rem`}}>arrow_right</i>
                        </div>
                    </GaSetting>
                </form>
                <div className='placeholder'></div>
                <LoadingIndicator shouldOpen={this.props.loading} />
            </Wrapper>
            :
            <Forget oauthData={{clientId,redirectUri,scope}} />
        );
    }
}


const mapStateToProps = state => ({
    loading: state.oauthReducer.shouldLoadingOpen,
});

const mapDispatchToProps = (dispatch) => ({
    userLogin: (body) => {
        dispatch(userLogin(body))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
