import React, { Component } from "react";
import moment from 'moment';

export default class RacketInfoBeforeContractSign extends Component {

    render(){
        const { orderContent, 
            rentReducerData,
            shipment,
            payment,
            getCancelProgramButton,
            getPaymentButton,
        } = this.props

        return(
            <div key={`main` + orderContent.id} >
                {/* #####   未付款   ##### */}
                {orderContent &&
                    <ul id="unpaid_content">
                        <li>
                            申請編號：
                            {orderContent.id}
                        </li>
                        <li className="border-style margin-top-20px margin-bottom-20px">
                            <div 
                                className="img-top"
                                style={{backgroundImage: `url(${orderContent.racket_id.picture})`}}
                            >
                            </div>
                            <div className="img-bottom">
                                {orderContent.racket_id.name}
                            </div>
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            申請日期：
                            {   
                                orderContent.createdAt !== 0
                                && 
                                    moment(parseInt(rentReducerData ? orderContent.createdAt : '')).format('YYYY/MM/DD')
                            }
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            專案：
                            {(orderContent.payment_type) === 'A'
                            ? 
                                <span> 一次付清 </span>
                            : 
                            (orderContent.payment_type) === 'Y'
                            ? 
                                <span> 年繳 </span>
                            :
                            (orderContent.payment_type) === 'M'
                            && 
                                <span> 月繳 </span>
                            }
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            專案時間(起)：
                            {   
                                orderContent.contract_start_time !== 0
                                && 
                                    moment(parseInt(rentReducerData ? orderContent.contract_start_time : '')).format('YYYY/MM/DD')
                            }
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            專案時間(訖)：
                            {   
                                orderContent.contract_end_time !== 0
                                &&
                                    moment(parseInt(rentReducerData ? orderContent.contract_end_time : '')).format('YYYY/MM/DD')
                            }
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            專案狀態：
                            {
                                orderContent.contract_status === 0
                                ? 
                                    <span> 未簽約 </span>
                                : 
                                orderContent.contract_status === 1
                                ? 
                                    <span> 簽約中 </span>
                                : 
                                orderContent.contract_status === 2
                                ? 
                                    <span> 已簽約 </span>
                                : 
                                orderContent.contract_status === 3
                                ? 
                                    <span> 已解約 </span>
                                :  
                                orderContent.contract_status === 4
                                && 
                                    <span> 約滿並付清餘款 </span>
                            }
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            球拍編號：
                            {shipment[0].racket_number}
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            選配球拍：
                            {orderContent.racket_id.name}
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            付款方式：
                            {payment[payment.length - 1].supplier_trade_payment}
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            贈品選擇：
                            {
                                orderContent.gift === 1
                                ? 
                                    <span> 贈品A：波力羽球線6組（羽拍穿線自理）、柄皮1條。 </span>
                                :
                                orderContent.gift === 2
                                ? 
                                    <span> 贈品B：波力客製化穿線服務乙次（22±1磅數）、波力羽球線4組、柄皮1條。</span>
                                :
                                orderContent.gift === 3
                                && 
                                    <span> 贈品B：波力客製化穿線服務乙次（進階24±1磅數）、波力羽球線4組、柄皮1條。</span>
                            }
                        </li>
                        <li className="margin-left-20px color-LightBlack">
                            備註：
                            {orderContent.note}
                        </li>
                        <li className="button-group">
                            { orderContent && getCancelProgramButton() }
                            { orderContent && getPaymentButton() }
                            <div className="button" onClick={()=>{this.props.history.push('/my_info/rent_contract_list')}}>
                                回上頁
                            </div>
                        </li>
                    </ul>
                }
            </div>
        )
    }
}