import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    fetchPrivateChatInfo,
    fetchPrivateChatList,
    fetchUserInfo,
    messageListener,
    stopMessageListener,
    sendSelfMessageToRedux,
    haveSeenPrivateChat,
} from '../../../../actions';
import { Wrapper, ListContent } from './styled';
import { CommentBox } from '../../../../component/Common';
import Banner from '../../Banner';
import { localStorageUtility } from '../../../../utilities';

class MsgDetail extends Component {
    constructor(props) {
        super(props);
        const search = props.match.params.id.split('&');
        const params = search.reduce((params, param) => {
            let [key, value] = param.split('=');
            params[key] = value
                ? decodeURIComponent(value.replace(/\+/g, ' '))
                : '';
            return params;
        }, {});
        // params 是物件，例子: {userId: '584'}
        // userId 表示 不是透過 myInfo 和他聊天
        // id 表示透過 myInfo 和他聊天
        // userId . id 都是對方的 id
        this.state = {
            id: params['id'],
            userId: params['user_id']
        };
    }

    componentDidMount() {
        const { userId, id } = this.state;
        const {
            fetchPrivateChatInfo,
            fetchPrivateChatList,
            fetchUserInfo
        } = this.props;
        // 透過 myInfo 進來，id 是聊天室 id，對方的個資透過 call API 得到
        if (id) {
            fetchPrivateChatInfo(id);
            this.props.haveSeenPrivateChat(id)
        }
        // 不是透過 myInfo 進來，有極高機率是第一次進來
        if (userId) {
            fetchPrivateChatList(userId);
            fetchUserInfo(userId); // for banner pic 
        }
        this.props.messageListener();

    }

    componentDidUpdate(prevProps, prevState){
        let privateChatID = this.props.messageInformation.privateChat

        // 不是透過 myInfo 進來，聊過天或是未聊過天發訊息 send 已讀 API
        if(this.state.userId){
            if( prevProps.messageInformation.privateChat !== privateChatID){
                this.props.haveSeenPrivateChat(privateChatID)
            }else if( prevProps.data !== this.props.data && this.props.data && this.props.data.id ){ 
                this.props.haveSeenPrivateChat(this.props.data.id)
            }
        }

        if (this.props.messageInformation !== prevProps.messageInformation) {
            const { userId, id } = this.state;
            const {
                fetchPrivateChatInfo,
                fetchPrivateChatList
            } = this.props;
            // 透過 myInfo 進來，id 是聊天室 id，對方的個資透過 call API 得到
            if (id) {
                fetchPrivateChatInfo(id);
            }
            // 不是透過 myInfo 進來，有極高機率是第一次進來
            if (userId) {
                fetchPrivateChatList(userId);
            }
        }

    }

    componentWillUnmount() {
        this.props.stopMessageListener();
    }

    getImageSrc = (message) => {
        var defaultAvatar = '/assets/images/DefaultUserImg.png';
        if (Number.isInteger(message.user)) {
            if (this.props.data.personOne) {
                if (this.props.data.personOne.id === message.user) {
                    return this.props.data.personOne.avatar === "" ? defaultAvatar : this.props.data.personOne.avatar
                }
            }
            if (this.props.data.personTwo) {
                if (this.props.data.personTwo.id === message.user) {
                    return this.props.data.personTwo.avatar === "" ? defaultAvatar : this.props.data.personTwo.avatar
                }
            }
        }else{
            return message.user.avatar === "" ? defaultAvatar :  message.user.avatar 
        }
    }

    render() {
        const { data, otherUser } = this.props;
        const userObj = localStorageUtility('get', 'myData');
        if(!userObj) return null;
        const { id } = JSON.parse(userObj);
        // personOne . personTwo 共生共滅
        const checkIfHasPersonData = data.personOne
        const personOneId = checkIfHasPersonData && data.personOne.id
        const personTwoId = checkIfHasPersonData && data.personTwo.id
        const isMePersonOne =  personOneId === id;
        const chatToUserId = checkIfHasPersonData ? 
            ( personOneId === id ?  personTwoId : personOneId) 
            : 
            this.state.userId
        const currentUser = id => checkIfHasPersonData && (id === personOneId ? data.personOne : data.personTwo)
        let messages = data.message;

        return [
            <Banner
                key={0}
                // 不只塞 id，塞整個 userInfo
                user={ this.state.userId ? otherUser : (isMePersonOne ? data.personTwo : data.personOne) }
                disabledUpload
            />,
            <Wrapper key={1}>
                <h2 style={{display: `none`}}>聊天內容</h2>

                {/* this.props.userInfo 是自己，如果不是從 myInfo 開啟聊天，會有 this.props.otherUser，otherUser 是對方 */}
                {/* userName .userAvatar .userId 是為了 renderSelfMessage  */}
                <CommentBox
                    key={0}
                    commentType="privateChat"
                    to={chatToUserId}
                    renderSelfMessage={this.props.sendSelfMessageToRedux}
                    userName= {this.props.userInfo.name}
                    userAvatar = {this.props.userInfo.avatar}
                    userId ={this.props.userInfo.id}
                />
                {messages && messages.length > 0 && (
                    <ListContent key={1}>
                        {messages && messages.map( (m, i) => {
                            const messageUserID = (m.user && m.user.id) || m.user;
                            return (
                                <div key={i} className={messageUserID === id ? 'list myself' : 'list'}>
                                    <div className="Out_left">
                                        <div className="pic">
                                            <img
                                                alt=""
                                                src={this.getImageSrc(m)}
                                            />
                                        </div>
                                    </div>

                                    <div className="Out_right">
                                        <div className="inside">
                                            <div className="In_left">
                                                <div className="name">
                                                    {m.user.name || (currentUser(m.user) && currentUser(m.user).name)}
                                                </div>
                                                {messageUserID === id
                                                    &&
                                                    <div className="date">
                                                        {
                                                            m.createdAt &&
                                                                moment(new Date()).isAfter(moment(m.createdAt).add(59, 's')) ?
                                                                    moment(m.createdAt).startOf('minute').fromNow()
                                                                    :
                                                                    '剛剛'
                                                        }
                                                    </div>
                                                }
                                                <div className="message">
                                                    <p>{m.message}</p>
                                                </div>
                                                {messageUserID !== id 
                                                    ?
                                                    <div className="date">
                                                        {
                                                            m.createdAt &&
                                                                moment(new Date()).isAfter(moment(m.createdAt).add(59, 's')) ?
                                                                    moment(m.createdAt).startOf('minute').fromNow()
                                                                    :
                                                                    '剛剛'
                                                        }
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </ListContent>
                )}
            </Wrapper>
        ];
    }
}

const mapStateToProps = state => ({
    data: state.messageReducer.privateChatInfo,
    messageInformation: state.messageReducer.messageInformation,
    otherUser: state.myInfoReducer.otherUserInfo,
    userInfo: state.myInfoReducer.userInfo
});
const mapDispatchToProps = {
    fetchPrivateChatInfo,
    fetchPrivateChatList,
    fetchUserInfo,
    messageListener,
    stopMessageListener,
    sendSelfMessageToRedux,
    haveSeenPrivateChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(MsgDetail);
