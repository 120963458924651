import React, { Component } from "react";
import { Wrapper } from "./styled";
import { Link } from 'react-router-dom';
import MemberInfo from './MemberInfo';
import { Modal, ButtonsGroup, RoundButton } from "../../../Common";

class GroupInfo extends Component {
	constructor(props) {
		super(props)
		this.onLeaveActivityClick = this.onLeaveActivityClick.bind(this);
		this.state = {
			paid: this.props.paid,
		}
	}

	onLeaveActivityClick(joinID, userID) {
		this.props.onLeaveClick(joinID, userID)
	}

	handleChangePaid(id, paidStatus, paymentTypePlugin) {
		// 應該是信用卡付款就不進來這個 function
		// paymentTypePlugin === CreditEcpay (信用卡) 不能更改付款狀態
		if (paymentTypePlugin === 'CreditEcpay'){
			console.log("paid by credit card");
			return
		}

		if (!paidStatus) {
			paidStatus = true
		} else {
			paidStatus = false
		}
		this.props.handleChangePaid(id, paidStatus);
		this.setState({ paid: paidStatus });
	}

	renderRegisteredMembers(joinedPeopleData) {
		const { activityFee } = this.props;

		return joinedPeopleData
			.sort((a, b) => a.createdAt - b.createdAt)
			.map((joinedPerson, i) => (
				<div className="registeredMember" key={i}>
					<MemberInfo {...(joinedPerson.user)} 
						index={i + 1} 
						registeredAt={joinedPerson.createdAt} 
						follow={() => this.props.follow(joinedPerson.user.id)} 
						followList={this.props.followList} 
					/>
						{/* <MemberInfo {...(joinedPerson.user)} index={i+1} registeredAt={joinedPerson.createdAt} onfollow = {onfollow}/> */}

					<ButtonsGroup className="btn-group">
						{ /* <RoundButton>talk</RoundButton> */}
						<Link to={`/my_info/chatroom/user_id=${joinedPerson.user.id}`} style={{display:'flex',flex:'1'}}>
							<button className="modal-btn">聊聊</button>
						</Link>
						{
							joinedPerson.paid ?
								<div style={{display:'flex',flex:'1'}}>
									<RoundButton
										className= "grayButton"
										name="dayOff"
									>
										請假
									</RoundButton>
								</div>
							:
								<div style={{display:'flex',flex:'1'}}>
									<Modal
										title='請假/取消'
										btnClass='modal-btn'
										btnText='請假/取消'
										onClick={() => this.onLeaveActivityClick(joinedPerson.id, joinedPerson.user.id)} 
									/>
								</div>
						}
							
						{/* 是不是免費團，免費團不能改付款狀態 */}
						{
							activityFee === 0 ?
								<div style={{display:'flex',flex:'1'}}>
									<RoundButton 
										className= "grayButton"
										// className={joinedPerson.paid ? 'red active' : 'red'} 
										name="paid"
									>
										付款
									</RoundButton>
								</div>
							:
								<div style={{display:'flex',flex:'1'}}>
									<RoundButton 
										className={joinedPerson.paid ? 'transparentRed active' : 'transparentRed'} 
										onClick={ () => this.handleChangePaid(joinedPerson.id, joinedPerson.paid, joinedPerson.paymentTypePlugin) } 
										name="paid"
									>
										{/* 現場線上付款方式判斷 */}
										<span>
											{joinedPerson.paymentTypePlugin === 'Cash' ? '現場' : 
												joinedPerson.paymentTypePlugin === 'CreditEcpay' ? '線上' : "" }
											{joinedPerson.paid ? '已付款' : '未付款'}
										</span>
									</RoundButton>
								</div>
						}
					</ButtonsGroup>
				</div>
			)
			);
	}


	render() {
		const { joinedPeopleData } = this.props;

		let joinedPeopleDataWithoutCancel = joinedPeopleData.filter(e =>{
			// status == false, 有請假
			return e.status === true
		})
		// console.log("kick some people, :", joinedPeopleDataWithoutCancel )

		return (
			<Wrapper>
				<div className="info-title">
					已報名人員：{joinedPeopleDataWithoutCancel.length} / {this.props.people}
				</div>
				{this.renderRegisteredMembers(joinedPeopleDataWithoutCancel)}
			</Wrapper>
		)
	}
}

GroupInfo.defaultProps = {
	joinedPeopleData: {
		paid: true,
		createdAt: 0,
		id: 0,
		user: {
			id: 0,
			name: "Name",
			avatar: "/assets/images/DefaultUserImg.png",
			createdAt: 0,
			//registeredNumber: 0,
			isFollowing: "111",
		}
	},
	people: 0,
	onLeaveClick: (id) => { },
	handleChangePaid: (id) => { },
	follow: (id) => { }
}

export default GroupInfo;
