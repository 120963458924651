import React, { Component } from "react";
import { Wrapper } from './styled';
import { GaSetting } from "../../Common";

class LandingPageB extends Component {

    componentDidMount() {
        this.props.handleChangeMetaTags('BonnyBadmintonIntro')        
    }

    componentWillUnmount(){
        this.props.handleChangeMetaTags('BonnyBadminton')
    }

    render() {
        return (
            <Wrapper key="1">
                <GaSetting 
                    data = {{
                        name: 'componentDidMount', 
                        category: '羽你蓮心-Lobby', 
                        action: 'Lobby_PV', 
                        label: '',
                    }}
                />
                <div id="WRAPPER">
                    <div>
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/main-title-sm.jpg" style={{ 'width': '100%' }} alt=""/>
                    </div>

                    {/* <!--日付不到4元--> */}
                    <div>
                        <GaSetting 
                            data = {{
                                category: '羽你蓮心-Lobby', 
                                action: 'Lobby_4D_bannerToKnowMore_Click', 
                                label: '', 
                                path: '/classic',
                                title: '前往了解頂級熱銷款',
                                style: { cursor: `pointer`, },
                            }}
                        >
                            <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/slice_03_01.jpg" alt=""/>
                        </GaSetting>
                        <div style={{ 'display': 'flex' }}>
                            <GaSetting 
                                data = {{
                                    category: '羽你蓮心-Lobby', 
                                    action: 'Lobby_4D_buttonToKnowMore_Click', 
                                    label: '', 
                                    path: '/classic',
                                    title: '前往了解頂級熱銷款',
                                    style: { cursor: `pointer`, },
                                }}
                            >
                                <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/btn-1.jpg" alt=""/>
                            </GaSetting>
                            <GaSetting 
                                data = {{
                                    category: '羽你蓮心-Lobby', 
                                    action: 'Lobby_4D_goRent_Click', 
                                    label: '', 
                                    path: {
                                        pathname: '/rent',
                                        state: {"level": 'high',}
                                    },
                                    title: '心動～直接前往申請！！',
                                    style: { cursor: `pointer`, },
                                }}
                            >
                                <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/btn-2.jpg" alt=""/>
                            </GaSetting>
                        </div>
                    </div>

                    {/* <!--日付不到3元--> */}
                    <div>
                        <GaSetting 
                            data = {{
                                category: '羽你蓮心-Lobby', 
                                action: 'Lobby_3D_bannerToKnowMore_Click', 
                                label: '', 
                                path: '/feather',
                                title: '前往了解最輕專業款',
                                style: { cursor: `pointer`, },
                            }}
                        >
                            <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/slice_04_01.jpg" alt=""/>
                        </GaSetting>
                        <div style={{ 'display': 'flex' }}>
                            <GaSetting 
                                data = {{
                                    category: '羽你蓮心-Lobby', 
                                    action: 'Lobby_3D_buttonToKnowMore_Click', 
                                    label: '', 
                                    path: '/feather',
                                    title: '前往了解最輕專業款',
                                    style: { cursor: `pointer`, },
                                }}
                            >
                                <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/btn-1.jpg" alt=""/>
                            </GaSetting>
                            <GaSetting 
                                data = {{
                                    category: '羽你蓮心-Lobby', 
                                    action: 'Lobby_3D_goRent_Click', 
                                    label: '', 
                                    path: {
                                        pathname: '/rent',
                                        state: {"level": 'medium',}
                                    },
                                    title: '心動～直接前往申請！！',
                                    style: { cursor: `pointer`, },
                                }}
                            >
                                <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/btn-2.jpg" alt=""/>
                            </GaSetting>
                        </div>
                    </div>

                    {/* <!--日付只要2元--> */}
                    <div>
                        <GaSetting 
                            data = {{
                                category: '羽你蓮心-Lobby', 
                                action: 'Lobby_2D_bannerToKnowMore_Click', 
                                label: '', 
                                path: '/limited',
                                title: '前往了解超值限量款',
                                style: { cursor: `pointer`, },
                            }}
                        >
                            <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/new_slice_05_01.jpg" alt=""/>
                        </GaSetting>
                        <div style={{ 'display': 'flex' }}>
                            <GaSetting 
                                data = {{
                                    category: '羽你蓮心-Lobby', 
                                    action: 'Lobby_2D_buttonToKnowMore_Click', 
                                    label: '', 
                                    path: '/limited',
                                    title: '前往了解超值限量款',
                                    style: { cursor: `pointer`, },
                                }}
                            >
                                <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/btn-1.jpg" alt=""/>
                            </GaSetting>
                            <GaSetting 
                                data = {{
                                    category: '羽你蓮心-Lobby', 
                                    action: 'Lobby_2D_goRent_Click', 
                                    label: '', 
                                    path: {
                                        pathname: '/rent',
                                        state: {"level": 'low',}
                                    },
                                    title: '心動～直接前往申請！！',
                                    style: { cursor: `pointer`, },
                                }}
                            >
                                <img className="w-100" src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lobby/landingPageB/btn-2.jpg" alt=""/>
                            </GaSetting>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}


export default LandingPageB;
