import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchActivity,
  updateSearch,
  updateSearchCity,
  getLevels,
} from "../../actions";
import { Card } from "../Common";
import { Wrapper, FloatingAddress, SearchBar, GoogleMapWrapper, } from "./styled";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';     // https://www.npmjs.com/package/react-places-autocomplete
import GoogleMapReact from 'google-map-react';  // https://www.npmjs.com/package/google-map-react
import Slider from "react-slick";  // https://www.npmjs.com/package/react-slick
import Config from "../../component/config.js";
// 與 Native 對接
// import invoke from 'react-native-webview-invoke/browser';
import UAParser from 'ua-parser-js';
import { localStorageUtility } from '../../utilities';

const UserLocation = (data) => (
    <div className="userLocation">
        <div className='pin'></div>
        <div className='pulse'></div>
    </div>
);
const ActivitysInformation = ({ data, onMouseEnter, onMouseLeave, onClick }) => (
    <div 
        className="activitysInformation"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
    >
        <div className='pin'></div>
        <div className='pulse'></div>
        <div className="group_Name">
            {data.group_name}
        </div>
    </div>
);
    

class LocationFindActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultZoom: 15,
            activityInformationArray: [],
            activityLocation: {},
            query: {},
            search: '',
            hover: false,
            onClick: false,
            place: null,
            mapIsOnClick: {},
            address: '',
            isShowCheckBox: false,
        };

        this.getLocation();
    }

    webViewInvoke = () => {
        let location = localStorageUtility('getAndParse', 'location');
        if ( location ){
            if( this.state.native_location === undefined ){
                this.setState({
                    native_location: location,
                })
            }
        }
    }

    getLocation = () => {  
        navigator.geolocation.getCurrentPosition( (position) => { 
            this.setState({
                query: { 
                    search: this.state.search,
                    latitude: position.coords.latitude, 
                    longitude: position.coords.longitude,
                    start: new Date().valueOf(),
                    end: moment().add((8), 'd').valueOf(),
                    precision: 5,
                }
            }); 
        },  
        (error) => {  
            if( this.state.native_location ) {
                this.setState({
                    query: { 
                        search: this.state.search,
                        latitude: this.state.native_location && this.state.native_location.latitude, 
                        longitude: this.state.native_location && this.state.native_location.longitude,
                        start: new Date().valueOf(),
                        end: moment().add((8), 'd').valueOf(),
                        precision: 5,
                    }
                }); 
            } else {
                this.getGeolocationAPI();
            }
        });  
    }  

    componentDidMount(){
        this.props.getLevels();

        var parser = new UAParser();
        let ua = navigator.userAgent;
        let agent = {
            browser: parser.setUA(ua).getBrowser(),
            os: parser.setUA(ua).getOS(),
            device: parser.setUA(ua).getDevice(),
        }
        if( agent.browser && agent.browser.name.indexOf('WebView') > -1 ){
            this.webViewInvoke();
        }
    }

    onClick = ( event ) => {
        // 如需使用地圖上取得使用者點選之位置，
        // 則須在 <GoogleMapReact />內加入下方動作，
        // onClick={this.onClick}
        this.setState({
            query: { 
                search: this.state.search,
                latitude: event.lat, 
                longitude: event.lng,
                start: new Date().valueOf(),
                end: moment().add((8), 'd').valueOf(),
                precision: 5,
            }
        });
    }

    onBoundsChange = ( center, zoom ) => {
        // 如需使用地圖平移取中間位置，
        // 則須在 <GoogleMapReact />內加入下方動作，
        // onBoundsChange={this.onBoundsChange}
        this.setState({
            isShowCheckBox: false,
        });
        if(zoom > 15){
            this.setState({
                query: { 
                    search: this.state.search,
                    latitude: center.lat, 
                    longitude: center.lng,
                    start: new Date().valueOf(),
                    end: moment().add((8), 'd').valueOf(),
                    precision: 6,
                }
            });
        } else {
            this.setState({
                query: { 
                    search: this.state.search,
                    latitude: center.lat, 
                    longitude: center.lng,
                    start: new Date().valueOf(),
                    end: moment().add((8), 'd').valueOf(),
                    precision: 5,
                }
            });
        }
    }

    getGeolocationAPI = () => {
        fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${Config.googleMapKey}`,{ method: 'POST'})
        .then(res => res.json())
        .then(data => {
            this.setState({
                query: { 
                    search: this.state.search,
                    latitude: data.location.lat, 
                    longitude: data.location.lng,
                    start: new Date().valueOf(),
                    end: moment().add((8), 'd').valueOf(),
                    precision: 5,
                }
            })
        })
        .catch(e => {
            alert("oops!! 你的裝置不支援該服務!!\n請確認是否開啟並允許使用定位相關功能！"); 
            this.props.history.push('/activity_list');
        })
    };

    handleClick = (data) => {
        const { activityData } = this.props;
        var mapData = activityData && activityData.activity.map( (data) => (data) );
        let samePlaceArray =  mapData.filter( e=>{
            return data.latitude === e.latitude
        })
        var chatBody = document.getElementById("chat-widget-container");
        if ( this.state.onClick === true ){
            this.setState({ onClick: false });
            chatBody.style.display="block";
        }
        else {
            this.setState({ 
                onClick: true,
                activityInformationArray: samePlaceArray,
            });
            chatBody.style.display="none";
        }
    }

    trackMouse = (data) => {
        const { activityData } = this.props;
        let mapData = activityData && activityData.activity.map( (data) => (data) );
        let samePlaceArray =  mapData.filter( e=>{
            return data.latitude === e.latitude
        })

        this.setState({ 
            hover: true,
            activityInformationArray: samePlaceArray, 
        });
    }

    untrackMouse = (data) => { 
        this.setState({ hover: false });      
    }

    handleInputChange = (e) => {
        const target = e.target;
        const value = target.value;
        const inputName = target.name;
        const { query } = this.state;
        this.setState(
            {
                [inputName]: value,
                query:{
                    [inputName]: value,
                    start: new Date().valueOf(),
                    end: moment().add((8), 'd').valueOf(),
                    latitude: query.latitude,
                    longitude: query.longitude,
                    precision: 5,                    
                }
            },
        );
    }

    handleChange = (address) => {
        this.setState({ address });
    }
 
    handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(
                latLng => 
                    this.setState({
                        query: { 
                            search: this.state.search,
                            latitude: latLng.lat, 
                            longitude: latLng.lng,
                            start: new Date().valueOf(),
                            end: moment().add((8), 'd').valueOf(),
                            precision: 5,
                        }
                    })
            )
            .catch(error => console.error('Error', error));

        var input = document.getElementById("searchInput");
        input.blur();
    }

    inputOnBlur = () => {
        this.setState({
            isShowCheckBox: false,
        });
    }

    inputOnFocus = () => {
        this.setState({
            isShowCheckBox: true,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevState.onClick === true && this.state.hover === true ) {
            this.setState({ onClick: false }); 
            document.getElementById("chat-widget-container").style.display="block";
        }
        if ( prevState.query.latitude !== this.state.query.latitude ) {
            this.props.onSubmit(this.state.query);
        }
    }
    
    render() {
        const { activityData } = this.props;
        const { defaultZoom, hover, onClick, query, } = this.state;

        var mapData = activityData && activityData.activity.map( (data) => (data) );
        var settings = {
            dots: false,
            infinite: true,
            speed: 1800,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Wrapper key={0}>
                <SearchBar key={1}>
                     <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="autocomplete">
                                <input
                                    {...getInputProps({
                                        placeholder: '輸入搜尋想找的地點',
                                        className: 'location-search-input',
                                    })}    
                                    id="searchInput"     
                                    onBlur={ this.inputOnBlur }
                                    onFocus={ this.inputOnFocus }
                                    />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div className="suggestion-item"><span>Loading...</span></div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { backgroundColor: '#eee', cursor: 'pointer' }
                                            : { backgroundColor: '#fff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                      </PlacesAutocomplete>
                </SearchBar>
                {/* loading === true ?
                    <LoadingIndicator shouldOpen={true} key={2} />
                    :
                    <LoadingIndicator shouldOpen={false} key={2} />
                */}
                <GoogleMapWrapper key={3}>
                    <div className="backList" onClick={() => this.props.history.push('/activity_list')}>
                        <p>返回列表</p>
                    </div>
                    <div className="gpsFixed" onClick={() => this.getLocation()}>
                        <i className="material-icons">
                            gps_fixed
                        </i>
                    </div>
                    <GoogleMapReact
                        key={4}
                        options={{
                            minZoom: 13, 
                            maxZoom: 20,
                        }}
                        bootstrapURLKeys={{ key: Config.googleMapKey }}
                        center={{
                            lat: query.latitude,
                            lng: query.longitude,
                        }}
                        onBoundsChange={this.onBoundsChange}
                        zoom={defaultZoom} 
                    >
                        { 
                            mapData.map((data, i) => (
                                <ActivitysInformation
                                    key={`activity` + i}
                                    lat={data.latitude}
                                    lng={data.longitude}
                                    data={data}
                                    onMouseEnter={() => this.trackMouse(data)}
                                    onMouseLeave={this.untrackMouse}
                                    onClick={() => this.handleClick(data)}
                                />
                            ))
                        }
                        <UserLocation
                            key={`user`}
                            data={`User`}
                            lat={query.latitude}
                            lng={query.longitude}
                        />
                    </GoogleMapReact>       

                    {hover === true || onClick === true ? 
                        <FloatingAddress key={5}>
                            <div style={{position: `relative`, width: `88%`,}}>
                                <Slider {...settings}>
                                    {
                                        this.state.activityInformationArray.map( (activityData, i)=>{
                                            return <Card
                                                data={activityData}
                                                key={i}
                                                url={`/sign_up_activity?${activityData.id}`}
                                            />
                                        })
                                    }
                                </Slider>
                            </div> 
                        </FloatingAddress>
                    :
                    null
                    }
                </GoogleMapWrapper>
            </Wrapper>
        );
    }
}


const mapStateToProps = state => ({
    loading: state.activity.loading,
    activityData: state.activity,
    total: state.activity.totalActivity,
    activityType: state.activity.activityType,
    levels: state.newGroupReducer.levels,
});

const mapDispatchToProps = {
  onSubmit: fetchActivity,
  getLevels: getLevels,
  onUpdateSearch: updateSearch,
  onUpdateSearchCity: updateSearchCity,
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationFindActivity);
