import React, { Component } from "react";
import { Wrapper } from './styled';
import moment from 'moment';
import { localStorageUtility } from '../../../utilities';


class RememberMeClick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      check: true
    }
  }

  componentDidMount() {
    // 檢查登入時間是否過期
    this.localStorageExpire()
  }

  localStorageExpire = () => {
    let expiresTime = localStorageUtility('get', 'expiresTime');
    if (!expiresTime) return
    let expiresDate = Date.parse(expiresTime)
    let now = moment().valueOf()

    if (now > expiresDate) {
      localStorageUtility('remove', 'Remember');
      localStorageUtility('set', 'rememberMe', false);
      localStorageUtility('set', 'expiresTime', '');
      // localStorage.removeItem('expiresTime')
    }
    else {
      // console.log(moment());
    }
  }


  handleChange(evt) {

    if (evt.target.checked === false) {
      localStorageUtility('set', 'rememberMe', JSON.stringify(evt.target.checked));
      localStorageUtility('set', 'Remember', JSON.stringify(""));
    }
  }



  handleclick = (e) => {

    //alert(this.props.remember);
    this.setState({
      user: localStorageUtility('getAndParse', 'myData'),
      check: true
    });

    if (this.props.remember != null) {
      localStorageUtility('set', 'rememberMe', JSON.stringify(this.state.check));
      localStorageUtility('set', 'Remember', JSON.stringify(this.props.remember));
    }
  }

  render() {
    const { check } = this.props;

    return (
      <Wrapper>
        <input type="checkbox" onClick={this.handleclick} defaultChecked={check} onChange={this.handleChange} />
        <span className="cktext">記住我</span>
      </Wrapper>
    );
  }
}

RememberMeClick.defaultProps = {
  label: "",
  onChange: null,
  error: null,
  onClick: null
};

export default RememberMeClick;

