import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 40px;
  @media (max-width: 480px) {
    margin-bottom: 30vh;
  }
    h2 {
      margin: 20px 20px 5px 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }
    a {
        color: unset;
    }
    .all,
    .unread {
        cursor: pointer;
        span.more {
            background-color: rgb(217, 29, 48);
            border-radius: 999rem;
            color: #fff;
            font-size: 12px;
            display: inline-block;
            vertical-align: bottom;
            position: absolute;
            width: 26px;
            line-height: 26px;
            margin-left: 4px;
            margin-top: -2px;
        }
        &.click {
            background-color: rgb(217, 29, 48);
            color: #fff;
        }
    }
    .react-tabs {
        position: relative;
        top: -5px;
        .react-tabs__tab {
            padding: 10px 0;
            position: relative;
            list-style: none;
            cursor: pointer;
            width: 50%;
            text-align: center;
            display: inline-block;
            vertical-align: bottom;
            border: none;
            font-size: .8rem;
        }
    }
    span.bonny{
        color: #DF1C2C;
    }
`;

export const ListContent = styled.div`
    width: calc( 100% - 15px );
    height: auto;
    margin: 5px 10px 60px 5px;
    padding: 2px;
    box-shadow: 2px 3px 3px #eee;
    position:relative;

        a.rent-List-box{
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          color: unset;
          -webkit-text-decoration: none;
          text-decoration: none;
          // border: 1px solid #DF1C2C;
          // border: 1px solid #CCC;
          overflow: hidden;
          margin-top:10px;

          > div.act-img-box{
            flex: 1;
            height: auto;
            margin-right: 5px;
            object-fit: fill;
            position: relative;
            overflow: hidden;
            // flex: 0 0 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .act-img {
              width: 0;
              height: 100%;
              padding-right: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: auto 100%;
            }

            > img{
              width: 100%;
              padding-bottom: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%,-50%);
              -ms-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
            }

            > div.type{
              height: 20px;
              position: absolute;
              bottom: 0px;
              width: 100%;
              font-size: 10px;
              color: #fff;
              background: rgba(0,0,0,0.6);
              text-align: center;
            }
          }

          > ul.act-info{
              text-align: left;
              padding: 0;
              margin: 5px 0;
              width: 60%;
              display:flex;
              flex-direction: column;


              > li{
                margin: 0;    
                font-size: 14px;
                list-style:none;
                width:100%;
                height: 1.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
              }

              > .title{
                width: 8rem;
                margin-left: calc( (100% - 8rem) / 2 );
                font-size: 16px;
                font-weight: bold;
                color: #DF1C2C;
                border: 1px solid #DF1C2C;
                border-radius: 999rem;
                padding: 2px;
                display:flex;
                align-items:center; 
                justify-content:center;
              }

              > small{
                display: block;
                font-size: 12px;
              }
          }

          > div.act-bottom{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            padding-top:5px;
            padding-bottom:3px;
            background-color:#DF1C2C;
            color:#FFF;

            .item-name{
              flex: 1;
              padding-left: 10px;
              height:22px;
              line-height:22px;
            }
            .btn-seemore{
              flex: 1;
              padding-right: 10px;
              height: 22px;
              line-height:22px;
              text-align:right;
              display:flex;
              align-items:center; 
              justify-content:flex-end; 

              span{
                width: 3rem;
                text-align:center;
              }
              img{
                flex: 1;
              }
            }
          }

      }

      > a.cancel{
        opacity: .6;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0)0%, rgba(255, 255, 255, 0.66)35%);
          background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0)0%, rgba(255, 255, 255, 0.66)35%);
          background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0)0%, rgba(255, 255, 255, 0.66)35%);
          background: -o-linear-gradient(0deg, rgba(255, 255, 255, 0)0%, rgba(255, 255, 255, 0.66)35%);

        }

        > div.act-bottom{
          background-color:#CCC;
        }

        > ul.act-info > .title {
          color: #333;
          border-color: #333;
        }
      }
    }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            margin:auto;

            .lightBox-explain-Content-inside{
                padding: 20px;

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#FFF;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description > ul{
                    padding:0;

                    li{
                        list-style-type:none;
                    }
                }

                .LB-C-Description > ul.list-style-type-number{
                    li{
                        list-style-position: inside;
                        list-style-type: decimal;
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }
        }
`;

