import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { getRacketOrderList } from '../../../actions';
import { Wrapper, ListContent, } from './styled';
import { LoadingIndicator } from '../../../component/Common';
import Banner from '../Banner';

class MyRent extends Component {

    componentDidMount(){
        this.props.getRacketOrderList();
    }

    render() {
        const { racketOrderList } = this.props;
        const paidRacketOrderList = racketOrderList && racketOrderList.filter(n => n.contract_status === 2);
        const unPaidRacketOrderList = racketOrderList && racketOrderList.filter(n => n.contract_status !== 2);
        return [
            <Banner key={0}/>,
            <Wrapper key={1}>
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 我的羽你蓮心專案列表
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <h2>羽你蓮心專案</h2>
                <Tabs>
                    <TabList>
                        <Tab>
                            未付款/已取消
                        </Tab>
                        <Tab>   
                            已簽約
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <TabContent
                            data={unPaidRacketOrderList}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TabContent2
                            data={paidRacketOrderList}
                        />
                    </TabPanel>
                </Tabs>
            </Wrapper>
        ];
    }
}

class TabContent extends Component {
    render() {
        const { data } = this.props;
        return <List paidRacketOrderList={data} />;
    }
}

class TabContent2 extends Component {
    render() {
        const { data } = this.props;
        return <List paidRacketOrderList={data} />;
    }
}

class List extends Component {

    render() {
        const { paidRacketOrderList, } = this.props;
        return (
            <ListContent key={2}>
                {paidRacketOrderList &&
                    paidRacketOrderList.map( data =>  {
                        const {  
                            racket_id, 
                            present_shipment_id, 
                            present_payment_id, 
                            contract_status, 
                            payment_status, 
                            contract_start_time, 
                            contract_end_time 
                        } = data;
                        const url = data.id ? (`rent_contract_detail/${data.id}`) : '';

                        return (
                            <Link
                                className={ data.contract_status === 3 ? 'rent-List-box cancel' : 'rent-List-box'}
                                to={url}
                                key={data.id}
                            >
                                <div className="act-img-box" >
                                    <div className="act-img" style={{backgroundImage: `url(${racket_id.picture})`}}></div>
                                </div>

                                <ul className="act-info">
                                    <li> {/* 球拍編號 */}
                                        {
                                            present_shipment_id && present_shipment_id.racket_number 
                                            ? 
                                                `球拍編號：${present_shipment_id.racket_number}` 
                                            : 
                                                `申請編號：${present_shipment_id.order_id}`
                                        }
                                    </li>
                                    <li> {/* 扣款狀態 */}
                                        扣款狀態：
                                        { 
                                            contract_status === 0 
                                            ?
                                                <span>
                                                    未付款
                                                </span>
                                            :
                                            contract_status === 1 
                                            ?
                                                <span>
                                                    付款未完成
                                                </span>
                                            :
                                            contract_status === 2 
                                            && 
                                            payment_status === 2 
                                            ?
                                                <span>
                                                    {moment(present_payment_id && present_payment_id.payment_date).format('YYYY/MM/DD')}
                                                    已扣款
                                                </span>
                                            :
                                            contract_status === 2 
                                            && 
                                            payment_status === 3 
                                            ?
                                                <span>
                                                    {moment(present_payment_id && present_payment_id.payment_date).format('YYYY/MM/DD')}
                                                    已付清
                                                </span>
                                            :
                                            contract_status === 3
                                            ?
                                                <span>
                                                    已取消
                                                </span>
                                            :
                                            contract_status === 4 
                                            &&
                                            <span>
                                                約滿並付清餘款
                                            </span>
                                        }
                                    </li>
                                    <li> {/* 專案起訖 */}
                                        專案起訖：
                                        {
                                            contract_start_time !== 0
                                            &&
                                                moment(parseInt(contract_start_time)).format('YYYY/MM/DD')
                                        }
                                        {(contract_start_time !== 0 && contract_end_time !== 0) && '-'}
                                        {
                                            contract_end_time !== 0
                                            &&
                                                moment(parseInt(contract_end_time)).format('YYYY/MM/DD')
                                        }
                                    </li>
                                    <li> {/* 物流狀態 */}
                                        物流狀態：
                                        {
                                            (present_shipment_id ? present_shipment_id.shipment_date : '') === 0 
                                            ?
                                                <span>
                                                    未出貨
                                                </span>
                                            :
                                                <span>
                                                    {moment(present_shipment_id ? present_shipment_id.shipment_date : '').format('YYYY/MM/DD')}
                                                    已出貨
                                                </span>
                                        }
                                    </li>
                                    <li className="title">
                                        {
                                            contract_status === 0 
                                            ?
                                                <span>
                                                    未付款
                                                </span>
                                            :
                                            contract_status === 1 
                                            ?
                                                <span>
                                                    尚有款項未付
                                                </span>
                                            :
                                            contract_status === 2 
                                            && 
                                            payment_status === 2 
                                            ?
                                                <span>
                                                    使用中
                                                </span>
                                            :
                                            contract_status === 2 
                                            && 
                                            payment_status === 3 
                                            ?
                                                <span>
                                                    使用中
                                                </span>
                                            :
                                            contract_status === 3 
                                            ?
                                                <span>
                                                    已取消
                                                </span>
                                            :
                                            <span> 約滿並付清餘款 </span>
                                        }
                                    </li>
                                </ul>

                                <div className="act-bottom">
                                    <div className="item-name">
                                        {racket_id.name}
                                    </div>
                                    <div className="btn-seemore">
                                        <span>詳細</span>
                                        <i className="material-icons">
                                            arrow_right
                                        </i>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
            </ListContent>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.rentReducer.shouldLoadingOpen,
    data: state.activity,
    racketOrderList: state.rentReducer.racketOrderList.RacketOrder,
});

const mapDispatchToProps = {
    getRacketOrderList: getRacketOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRent);
