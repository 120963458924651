

import { localStorageUtility } from '../utilities';
import moment from 'moment';


export const shareCount = (id, shareMethod, updateMediaFn)=>{
    let campusShare = localStorageUtility('getAndParse', 'campusShare');
    let shareMethodArray = campusShare && campusShare[id] &&campusShare[id][shareMethod]
    let todayShareMethodObject = shareMethodArray && shareMethodArray.filter(e=> e.date === moment().format('MMMM Do YYYY') )[0]
    // console.log( 'todayShareMethodObject is :', todayShareMethodObject);
    if ( todayShareMethodObject && todayShareMethodObject.share === 1 ){
        console.log("already use this share method, will return");
        return
    }

    let newCampusShare
    if (!campusShare ){
        newCampusShare = JSON.stringify(
            {
                [id] : {
                    [shareMethod]: [
                        {
                            date: moment().format('MMMM Do YYYY'),
                            share: 1
                        }
                    ]
                }
            }
        )
    }else{
        if( campusShare[id] && campusShare[id].hasOwnProperty(shareMethod) ){
            // console.log("has campus[id] and fb share");
            newCampusShare = JSON.stringify(
                {
                    ...campusShare,
                    [id] : {
                        ...campusShare[id],
                    [shareMethod]: [
                            ...campusShare[id][shareMethod],
                            {
                                date: moment().format('MMMM Do YYYY'),
                                share: 1
                            }
                        ]
                    }
                }
            )
        }else{
            if (campusShare[id] ){
                // console.log("has campus[id] and no fb share");
                newCampusShare = JSON.stringify(
                    {
                        ...campusShare,
                        [id] : {
                            ...campusShare[id],
                        [shareMethod]: [
                                // ...campusShare[id][shareMethod],
                                {
                                    date: moment().format('MMMM Do YYYY'),
                                    share: 1
                                }
                            ]
                        }
                    }
                )
            }else{
                newCampusShare = JSON.stringify(
                    {
                        ...campusShare,
                        [id] : {
                            // ...campusShare[id],
                        [shareMethod]: [
                                // ...campusShare[id]shareMethod,
                                {
                                    date: moment().format('MMMM Do YYYY'),
                                    share: 1
                                }
                            ]
                        }
                    }
                )
            }
        }
    }

    // console.log(newCampusShare);
    localStorageUtility('set', 'campusShare', newCampusShare);
    updateMediaFn( {type: shareMethod, id: id}) 

    // this.props.updateMediaInteraction({ type: 'lineShare', id: id })


}