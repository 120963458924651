import React, { Component } from "react";
import { Wrapper } from './styled';
import { ContractLightBox, GaSetting } from "../../../Common";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { getCookie } from '../../../../utilities';
// import ReactGA from 'react-ga';

class Contract extends Component {
    constructor(props) {
        super(props);
        const landingPageCode = getCookie('LandingPage');
        this.state = {
          landingPageCode: landingPageCode,
        }
    }

    componentDidMount(){
      document.getElementById('content').scrollTo(0, 0);
      if(this.props.version){
        this.setState({
            version : this.props.version,
        })
      }
    }

    lightBox(e){
        var deal = document.getElementById("ContractLightBox");
        deal.classList.remove("close");
    }

    render() {
      const landingPageCode = getCookie('LandingPage') || this.props.version;
        return (
            <Wrapper key={0} style={{paddingBottom: `30px`,backgroundColor: `#ac8967`,}}>
                <ContractLightBox 
                  coorporationMatterText1 = {<li>
                    一、  甲方同意並接受透過乙方會員專案（不限活動網址、廣告、公告、活動規定或宣傳內容），由甲方線上擇一申請乙方提供之波力三支頂級羽拍（1982Ⅱ、InfinityⅡ、PrincessⅡ定價皆為新台幣6,800元），並自合約起始日起，由乙方提供甲方三年蓮心保障期（不含羽線及柄皮）。
                  </li>}
                  coorporationMatterText2 ={
                    <li>二、  甲方同意接受並遵循乙方付款條件獲取球拍所有權：甲方可選擇年繳制，月繳制，或是一次付清。
                      <p>若選擇年繳制者，三年內分四期，每年初依定期定額方式支付新台幣999元，甲方於首次付款999元後，即可獲取選配球拍和贈品，乙方將於確認甲方付款後，將球拍和贈品寄達甲方指定地址。三年期滿當時，除分別於第一/第二/第三年初已付三期款各新台幣999元之外，甲方須於合約期滿日之前支付第四期款999元，以獲取球拍所有權，以上合計年繳制總支付金額為3996元。</p>
                      <p>若選擇月繳制者，三年內分三十六期，每月依定期定額方式支付新台幣115元，甲方於首次付款115元後，即可獲取選配球拍和贈品，乙方將於確認甲方付款後，將球拍和贈品寄達甲方指定地址。甲方須繳滿三十六期，以獲取球拍所有權。以上合計月繳制總支付金額為4140元。</p>
                      <p>若選擇一次付清者，則於首年申請時，支付3996元，一次繳清費用，即可獲取選配球拍和贈品。除了原專案贈品，一次付清者將加贈波力運動襪一雙。乙方將於確認甲方付款後，將球拍和贈品寄達甲方指定地址。</p>
                    </li> 
                  }
                  coorporationMatterText3 ={
                    <ul className="square">
                      <li>年繳：三年內分四期，每年依定期定額方式支付新台幣999元，三年期滿當
                          時，除分別於第一/第二/第三年初已付三期款各新台幣999元之外，須
                          於合約期滿日之前支付第四期款999元，以獲取球拍所有權，以上合計
                          年繳制總支付金額為3996元。
                      </li>
                      <li>月繳：三年內分三十六期，每月依定期定額方式支付新台幣115元，須繳滿三
                          十六期，以獲取球拍所有權。以上合計月繳制總支付金額為4140元。
                      </li>
                      <li>一次付清3996元，加贈波力運動襪一雙。</li>
                    </ul>
                  }
                />
                <div id="CONTENT">
                    {
                        landingPageCode === 'B' || this.props.version === 'B' ?
                        ''
                        :
                        <div>
                            <img alt="" src="../assets/images/Intro/01.jpg" />
                            <h4>
                              即日起 ，只要上「波力雲羽集 」網站，申請「羽你蓮心」專案 ，選擇月繳 115元、年繳 999元，或一次付清 3996元，首次付款成功後， 即可入手波力頂級球拍，三擇一（ 1982Ⅱ、 InfinityⅡ、 PrincessⅡ，定價皆為新台幣 6,800元），波力提供三年蓮心保障期，隨拍贈送價值千元贈品 ！選擇一次付清者，加贈波力運動襪一雙！
                            </h4>
                            <img alt="" src="../assets/images/Intro/02.jpg" />
                        </div>
                    } 
                  <h1>[ 會員付款方式 ]</h1> 
                  <h3>
                    1.年繳制：
                    <br/>
                    三年內分四期，每年定期定額支付 999元。 首次付款 999元後即可獲取選配球拍和贈品 。連續支付三期後，至合約期滿日前須支付第四期款999元，以獲取球拍所有權，以上合計年繳制總支付金額為3996元。
                    <br/>
                    <br/>
                    2.月繳制：
                    <br/>
                    三年內分三十六期，每月定期定額支付 115元。首次付款 115元後即可獲取選配球拍和贈品 ，須繳滿三十六期，以獲取球拍所有權。以上合計月繳制總支付金額為4140元。
                    <br/>
                    <br/>
                    3.一次付清：
                    <br/>
                    申請時支付 3996元一次繳清費用，即可獲取選配球拍和贈品。 除了原專案贈品，將加贈波力運動襪一雙。
                  </h3>
                  <h1>[ 會員贈品選擇 ]</h1> 
                  <h3>
                    贈品A：波力羽球線6組（羽拍穿線會員自理）、兼具加大握柄功能之柄皮1條，市值約1000元。<br/>
                    贈品B：會員指定一般 22±1或進階24±1磅數由波力客製化穿線服務乙次、羽球線4組、兼具加大握柄功能之柄皮1條，市值約1000元。 若選擇此贈品， 即同意放棄空拍七天鑑賞期並直接使用。
                  </h3>
                  <h1>[ 大力士條款 ]</h1> 
                  <h3>波力公司據近四十年承創國際名牌及頂尖球星用拍的經驗，足以判斷球拍是否正常受護，故對於合約期間三年內 「斷拍換新 」次數最多以兩次為限（含），若會員於合約期間內到達此定上限，則即無條件同意並接受波力於約滿後可能不再與會員續約此專案的權利。</h3>
                  <h1>[ 斷拍換新之規定 ]</h1> 
                  <h3>
                    波力依約提供會員三年蓮心保障期（不含羽線及柄皮），合約間首次申請「斷拍換新」經核定後 ，會員須先支付新台幣599元蓮心保障費 。第二次申請 「斷拍換新」經核定後 ，會員須先支付 899元蓮心保障費。三年內「斷拍換新」達到兩次，則在第二次新拍寄達時，本約即視為期滿，會員須繳清未到期之所有應付款項，以取得具高殘值的球拍所有權。 合約三年期滿後若會員均未提出過任何一次「斷拍換新」之申請，且在三個月內持續蓮心會員者，即可獲得波力特製之蓮心球衣乙件，價值新台幣 1000元以上。
                    <br/>
                    斷拍換新需先行將原貌寄回核定，收回斷拍與寄送新拍運費皆由我司負擔（限台灣本島，台灣離島則使用提供照片供核定，無須將原拍寄回，若甲方欲寄回原拍則運費由甲方自行負擔）。
                  </h3>
                  <h1>[ 羽你蓮心做公益 ]</h1> 
                  <h3>
                  會員三年約滿後若想換置新拍，且不想留用原舊拍，則波力建議轉贈給偏鄉學校（名冊由波力提供），並透過波力付費指派之物流，寄送至會員指定之學校。例如：南投縣長流國小體育組收。地址：國姓鄉長流村大長路559號，電話：049-243-1006。
                  </h3>
                  <h1>[ 羽你蓮心限量申請 ]</h1> 
                  <h3>申請專案前，須先加入 「波力雲羽集 」bonny-badminton 會員，同一個雲羽集會員帳號，申請使用蓮心專案之上限為兩次（含）（合約期滿續約者不在此限），每次僅能申請一支。 若有大宗訂購需求者，可直接聯絡波力客服 。</h3>
                  <p>詳情請參閱</p>
                  <h5>《<span style={{cursor: 'pointer'}} onClick={this.lightBox}>羽你蓮心專案合約書</span>》</h5>
                  <h6>
                    <GaSetting
                      data = {{
                        category: '4D', 
                        action: '4D_Shoppingcart_Click_atContract', 
                        label: 'isType' + landingPageCode,
                        tag: 'link',
                        path: { 
                          pathname: "/rent",
                          state: {
                            "level": 'high',
                          }
                        },
                      }}
                    >
                      <img src="../assets/images/Intro/03.gif" alt="羽你蓮心" style={{width: `100%`}}/>
                    </GaSetting>    
                  </h6>
                </div>
            </Wrapper>
        );
        
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contract));
