import { handleActions } from 'redux-actions';

const getEmptyList = () => ({
	total: 0,
	data: [],
});

const initialState = {
	mediaList: {
		data: [],
	},
	tag: {
		status: "MEDIA_TAG_LIST_SUCCESS",
		list: getEmptyList(),
		err: null,
	},
	media: {
		status: "MEDIA_GET_SUCCESS",
		data: null,
		err: null,
	},
	// dead code
	//  media set list
	interaction: {
		status: "UPDATE_MEDIALIST_INTERACTION",
		data: null,
		err: null,
	}
};

export default handleActions({

	// set list
	// id 前端要更新成 video id 嗎
	MEDIA_SET_LIST(state, action) {
		// let mediaListObject = {
		// 	data: {},
		// 	total: action.payload.total
		// }
		// action.payload.data.forEach(e => {
		// 	mediaListObject.data[e.id] = e
		// })
		// list 會放所有列表資料 等於直接覆蓋舊有資料
		return {
			...state,
			mediaList: action.payload,
		};
	},
	MEDIA_UPDATE_LIST(state, action) {		
		// console.log(action.payload.data);
		return {
			...state,
			mediaList: {
				...state.mediaList,
				data: [ 
					...state.mediaList.data,
				].concat(action.payload.data)
			},
		};
	},
	// MEDIA_TAG_LIST_STATUS and MEDIA_TAG_SET_LIST for tag
	MEDIA_TAG_LIST_STATUS(state, action) {
		return {
			...state,
			tag: {
				...state.tag,
				status: action.payload.status,
				err: action.payload.err || null,
			},
		};
	},
	MEDIA_TAG_SET_LIST(state, action) {
		return {
			...state,
			tag: {
				...state.tag,
				list: action.payload,
			},
		};
	},
	// MEDIA_SET_DATA for media/`id`
	MEDIA_SET_DATA(state, action) {
		return {
			...state,
			media: {
				...state.media,
				data: action.payload,
			},
		};
	},

	// like can be 0, so add judgement, but share only change 0 to 1, so no need to add judgement
	UPDATE_IS_MEDIA_LIKE_CLICK: (state, action) => {
		// 更新 mediaId的 like 和 isLikeClick
		let updateIndex
		if (action.payload.mediaId) {
			state.mediaList.data.forEach((e, index) => {
				if (e.id === action.payload.mediaId) {
					updateIndex = index
				}
			})
		}
		
		// Copy the object before mutating
		if(updateIndex >=0){
			return Object.assign({},
				{
					...state,
					mediaList: {
						...state.mediaList,
						data: [
							...state.mediaList.data.slice(0, updateIndex),
							{
								...state.mediaList.data[updateIndex],
								userLike: action.payload.userLike || action.payload.userLike === false ? action.payload.userLike : state.mediaList.data[updateIndex].userLike,  //=== 'like' ? true : false,
								isClickLike: action.payload.isClickLike, // === 'like' ? true : false,
								_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.mediaList.data[updateIndex]._like // || state.media.data._like,
							},
							...state.mediaList.data.slice(updateIndex + 1)
						]
					}
				}
			)
		}
	},
	UPDATE_MEDIA_INTERACTION: (state, action) => {
		// 有回傳就 更新 那個 id的 like 和 share 和 vote 
		// Copy the object before mutating
		// console.log(action.payload);
		if(action.payload.type === 'vote'){
			// 更新 userVote, like 由 component 更新
			return Object.assign( {},
				{
					...state,
					media: {
						...state.media,
						data: {
							...state.media.data,
							_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.media.data._like,
							userVote: true,
						}
					}
				}
			)
		} else if(action.payload.type === 'like' || action.payload.type === 'dislike'){
			return Object.assign({},
				{
					...state,
					media: {

						...state.media,
						data: {
							...state.media.data,
							_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.media.data._like,
							share: action.payload.share || state.media.data.share,
							userLike: action.payload.type === 'like' ? true : false,
						}
					}
				}
			)
		}else{
			return Object.assign({},
				{
					...state,
					media: {

						...state.media,
						data: {
							...state.media.data,
							_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.media.data._like,
							share: action.payload.share || state.media.data.share,
						}
					}
				}
			)
		}
	},

	// Interaction
	UPDATE_MEDIALIST_INTERACTION: (state, action) => {
		// 有回傳就 更新 那個 id的 like 和 share
		// 用 mediaId 找 redux 的 index 再去更新資料
		let updateIndex
		if (action.payload.mediaId) {
			state.mediaList.data.forEach((e, index) => {
				if (e.id === action.payload.mediaId) {
					updateIndex = index
				}
			})
		}

		if (updateIndex >= 0) {
			// Copy the object before mutating
			if(action.payload.type === 'vote'){
				return Object.assign({},
					{
						...state,
						mediaList: {
							...state.mediaList,
							data: [
								...state.mediaList.data.slice(0, updateIndex),
								{
									...state.mediaList.data[updateIndex],
									_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.mediaList.data[updateIndex]._like, //state.media.data._like,
									userVote: true,
								},
								...state.mediaList.data.slice(updateIndex + 1)
							]
						}
					}
				)
			} else if(action.payload.type === 'like' || action.payload.type === 'dislike'){
				return Object.assign({},
					{
						...state,
						mediaList: {
							...state.mediaList,
							data: [
								...state.mediaList.data.slice(0, updateIndex),
								{
									...state.mediaList.data[updateIndex],
									_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.mediaList.data[updateIndex]._like, //state.media.data._like,
									share: action.payload.share || state.medialist.data[updateIndex].share,
									userLike: action.payload.type === 'like' ? true : false,
								},
								...state.mediaList.data.slice(updateIndex + 1)
							]
						}
					}
				)
			}else{
				return Object.assign({},
					{
						...state,
						mediaList: {
							...state.mediaList,
							data: [
								...state.mediaList.data.slice(0, updateIndex),
								{
									...state.mediaList.data[updateIndex],
									_like: action.payload._like || action.payload._like === 0 ? action.payload._like : state.mediaList.data[updateIndex]._like, // state.media.data._like,
									share: action.payload.share || state.mediaList.data[updateIndex].share, //state.media.data.share,
								},
								...state.mediaList.data.slice(updateIndex + 1)
							]
						}
					}
				)
			}
		}
		// return todo
		return {
			...state,
			mediaList: {
				...state.mediaList,
			},
		};
	}
}, initialState);
