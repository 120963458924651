import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setForgetModalStatus, confirmUserMail, sendResetMail, setEmailUsedStatus, isSendResetMailOk } from '../../actions';
import { Modal, FormControl, LoadingIndicator } from '../Common';
import { Wrapper } from './styled';
import SubmitButton from './SubmitButton';
import ModalContent from './ModalContent';
import { validateEmail } from '../../utilities';
import Reset from './Reset';
import store from '../../store';

const msg = '請輸入您帳戶的E-MAIL，我們將發送一封E-MAIL到您的信箱，您可以重新設定您的新密碼。';

class Forget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			modalProps: {},
			loading: false,
			isGetOauthData: false,
		};
		window.localStorage.removeItem('myData');
		store.dispatch(isSendResetMailOk(false)); 
	}

	componentDidUpdate(prevProps, prevState){
		// 根據 isEmailRegister 是 true 還 false 判斷要塞給 modal 顯示什麼，
		// 以及判斷 modalProps 有沒有值 設定 modal 要不要開
		if(this.props !== prevProps){
			let modalProps;
			if (this.props.isEmailRegistered === true) {
				modalProps = {};
				modalProps.title = '重設密碼';
				modalProps.text1 = '重設';
				modalProps.onClick = e => this.onModalClick(e);
				modalProps.modalContent = <ModalContent msg='我們將重新發送一封E-MAIL到您的信箱，您可以重新設定您的新密碼。' />;
			} else if (this.props.isEmailRegistered === false) {
				modalProps = {};
				modalProps.title = '找不到使用者';
				modalProps.btnTheme = 'one';
				modalProps.text2 = '確認';
				modalProps.modalContent = <ModalContent msg='無此使用者，請註冊一個帳號。' />;
			}

			this.setState({
				modalProps: modalProps
			}, ()=>{
				// 開關 modal
				this.refs.modal && this.refs.modal[this.state.modalProps ? 'openModal' : 'closeModal']();
			})

			if (this.props.isSendResetMailOk) {
				this.setState({loading: false});
			}

			if (this.props.oauthData && this.state.isGetOauthData === false) {
				this.setState({clientId: this.props.oauthData.clientId, redirectUri: this.props.oauthData.redirectUri, scope: this.props.oauthData.scope, isGetOauthData: true})
			}
		}
	}


	onChange(field, e) {
		this.setState({ [field]: e.target.value });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { email } = this.state;

		if (validateEmail(email)) {
			this.props.findUser(email);
		} else {
			this.setState({email: ''});
			alert('您輸入的E-Mail有誤,請重新輸入!!');
		}
	}

	onModalClick(e) {
		// this.props.history is a function can let url push to another url
		this.props.sendResetMail(this.state.email, this.props.history);
		// this.setState({ email: '' });
		this.props.setModal('close');
		this.props.setEmail({});
		this.setState({loading: true});
	}

	// 取消之後 isEmailRegistered 會變 undefined，因為 set一個空的 email
	onModalClose() {
		this.props.setModal('close');
		this.props.setEmail({}); 
	}

	render() {
		const {email, clientId, redirectUri, scope} = this.state;
		return (
			<div>
				<LoadingIndicator shouldOpen={this.state.loading} />
			{ this.props.isSendResetMailOk === false ?
				<Wrapper>
					<Modal
						ref='modal'
						{ ...this.state.modalProps}
						noButton={true}
						notButtonStyle={{ display: 'none' }}
						onClose={() => this.onModalClose()}
					>
					</Modal>
					<form className='main-form'>
						<h1 className='title'>忘記密碼</h1>
						<div className='msg'>{msg}</div>
						<FormControl>
							<label>E-MAIL：</label>
							<input name='email' type='text' value={email} placeholder='請輸入您的E-MAIL' onChange={this.onChange.bind(this, 'email')} />
						</FormControl>
						<SubmitButton onClick={e => this.handleSubmit(e)} />
					</form>
				</Wrapper>
			:
				<Reset data={{email,clientId,redirectUri,scope}}/>
			}
			</div>
		);
	}
}

const mapStateToProps = ({ forgetReducer, rentReducer }) => ({
	isSendResetMailOk: forgetReducer.isSendResetMailOk,
	isEmailRegistered: rentReducer.userEmailConfirm.isEmailRegistered,
	status: forgetReducer.modalStatus,
	err: forgetReducer.err,
});

const mapDispatchToProps = dispatch => ({
	setEmail: status => dispatch(setEmailUsedStatus(status)),
	setModal: status => dispatch(setForgetModalStatus(status)),
	findUser: email => dispatch(confirmUserMail(email)),
	sendResetMail: (email, history) => dispatch(sendResetMail(email, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forget);
