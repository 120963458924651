import styled from 'styled-components';

export const Wrapper = styled.div ` 
    background-color: rgb(36,39,42);
    position: fixed;
    display: flex;
    height: calc(100vh - 57px);
    align-items: center; 
    justify-content: center;
    text-align: center;
    top: 57px;
    right: 0;
    left: 0;
    z-index: 2147483647;

    #error-inner { 
        margin: auto;
        display: block;
        position: relative;
        width: 100%;

        .logo {
            display: block;
            width: 120px;
            height: 120px;
            margin: auto; 
            padding: 5px;
            background-color: #fff;
            border: 2px solid rgba(255,255,255,0.5);
            border-radius: 5px;
            position: relative;

            img {
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            @media(max-width:480px and min-width:376px) {
                width: 100px;
                height: 100px;
            }
            @media(max-width:375px) {
                width: 80px;
                height: 80px;
            }
        }

        .tv {
            display: block;
            position: relative;
            width: 100%;
            height: 50vh;
            font-family: Arial, sans-serif;
        
            .tv-frame {
                position: absolute;
                left: 0;
                top: 50px;
                right: 0;
                bottom: 0;
                width: 380px;
                height: 220px;
                box-sizing: border-box;
                padding: 10px;
                border-right: 80px solid #ad7443;
                border-top: 30px solid #ad7443;
                border-left: 30px solid #ad7443;
                border-bottom: 30px solid #ad7443;
                border-radius: 20px;
                margin: auto;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.45)), linear-gradient(to right bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));
                background-color: #666;
                box-shadow: 0 0 50px rgba(255, 252, 234, 0.3), inset 0px 0px 30px rgba(255, 252, 234, 0.3);
                animation: light .2s linear infinite;

                @media(max-width:440px) {
                    width: 80%;
                    height: 180px;
                }

                &::before {
                    position: absolute;
                    content: '404';
                    font-family: 'Impact';
                    text-shadow: 2px 2px #000;
                    width: 50px;
                    height: 20px;
                    left: 30px;
                    top: 20px;
                    font-size: 100px;
                    white-space: pre;
                    color: white;
                    opacity: 1;
                    animation: fade 1s linear infinite;

                    @media(max-width:440px) {
                        font-size: 70px;
                    }
                }

                // &::after {
                //     content: '__\A__\A__';
                //     font-size: 40px;
                //     font-weight: bold;
                //     position: absolute;
                //     color: #614126;
                //     right: -60px;
                //     top: 100px;
                //     white-space: pre;
                //     line-height: 10px;
                // }
            }

            .buttons {
                position: absolute;
                width: 30px;
                right: -50px;

                &::before {
                    content: '⚇';
                    position: absolute;
                    color: #db9356;
                    font-size: 30px;
                    background-color: #614126;
                    border: 1px solid transparent;
                    border-radius: 30px;
                    top: 10px;
                }

                &::after {
                    content: '⚇';
                    position: absolute;
                    color: #db9356;
                    font-size: 30px;
                    top: 60px;
                    left: 3px;
                    transform: rotate(60deg);
                    background-color: #614126;
                    border: 1px solid transparent;
                    border-radius: 30px;
                }
            }

            .aerial {
                position: absolute;
                width: 100px;
                height: 40px;
                background-color: #db9356;
                margin: auto;
                top: -70px;
                left: 80px;
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;

                &::before {
                    content: '\|';
                    color: #614126;
                    font-size: 60px;
                    position: absolute;
                    top: -60px;
                    transform: rotate(-30deg);

                    @media(max-width:440px) {
                        font-size: 40px;
                        top: -35px;
                    }
                }

                &::after {
                    content: '\|';
                    color: #614126;
                    font-size: 60px;
                    transform: rotate(30deg);
                    position: absolute;
                    top: -60px;
                    left: 75px;

                    @media(max-width:440px) {
                        font-size: 40px;
                        top: -35px;
                    }
                }
            }
        } 

        @keyframes fade {
            0%,100% { opacity: 0.5 }
            50% { opacity: 0.7 }
        }

        @keyframes light {
            0%, 100% { opacity: 0.8 }
            50% { opacity: 1 }
        }

        .text {
            margin: 40px auto 0;
            display: flex;
            align-items:center; 
            justify-content:center;
            font-size: 1.8rem;
            border-style: unset;
            color: #fff;
            outline: none;
            width: auto;
            max-width: 480px;
            height: auto;
            cursor: pointer;

            @media(max-width:480px) {
                margin: 20px auto 0;
                font-size: 1.3rem;
            }
        }

        .back-home {
            position: relative;
            margin: 20px auto 0;
            display: flex;
            align-items:center; 
            justify-content:center;
            padding: 10px 20px;
            font-size: 1.8rem;
            background: #da263c;
            border-style: unset;
            color: #fff;
            border-radius: 999rem;
            outline: none;
            width: auto;
            max-width: 480px;
            height: auto;
            cursor: pointer;

            i.material-icons {
                font-size: 1.5rem;
            }

            @media(max-width:480px) {
                margin: 10px auto 0;
                font-size: 1.3rem;
                padding: 5px 0px;
                width: calc( 100vw - 40px );

                i.material-icons {
                    font-size: 1rem;
                }
            }

            &::after,
            &::before {
                position: absolute;
                background: #2f82bf;
                content: "";
                width: 0%;
                height: 100%;
                bottom: 0px;
                left: 0;
                z-index: -999999999;
                border-radius: 999rem;
            }

            &::before {
                background: #da5162;
                width: 100%;
            }

            &:hover {
                background: none;
            }

            &:hover::after {
                width: 100%;
                -webkit-transition: all 1s ease-in-out;
                -moz-transition: all 1s ease-in-out;
                transition: all 1s ease-in-out;
            }

            a {
                color: white;
                text-decoration: none
            }
        }
    }

`