import React, { Component } from "react";
import { Wrapper } from "./styled";

class InputButton extends Component {
    render() {
        const { input, onClick } = this.props;
        return (
            <Wrapper className="input-button">
                <input type="text" {...input} />
                <button onClick={onClick}>
                    <i className="material-icons">search</i>
                    {/*搜尋*/}
                </button>
            </Wrapper>
        );
    }
}

InputButton.defaultProps = {
    label: "",
    error: null,
    onClick: null
};

export default InputButton;
