import styled from 'styled-components';

export const Wrapper = styled.div`
    width: calc(100% - 40px);
    height: auto;
    margin: 0 20px 40px;
    @media (max-width: 480px) {
        margin: 0 20px 30vh;
    }
    h2 {
        margin-left: 0;
    }
`;

export const ListContent = styled.div`
    // background-color: rgba(255,0,0,.3);
    width: 100%;
    height: auto;
    a {
        text-decoration: unset;
        color: inherit;
        .list{
            display: flex;
            margin-top: 0.5rem;
            // margin-bottom: .5rem;
            .Out_left{
                display: flex;
                width: 60px;
                margin-right: 5px;
                align-items: center;
                justify-content: center;
                // @media (max-width: 480px) {
                //     width: 50px;
                // } 
                .pic{
                    width: 50px;
                    height: 50px;
                    border-radius: 999rem;
                    overflow: hidden;
                    position: relative;
                    // @media (max-width: 480px) {
                    //     width: 50px;
                    //     height: 50px;
                    // }
                    img{
                        // width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .avatar {
                    width: 50px;
                    height: 50px;
                    border: 2px solid #ccc;
                    border-radius: 999rem;
                    overflow: hidden;
                    position: relative;

                    .avatar_photo{
                        width: 100%;
                        height: 0;
                        padding-bottom: 100%;
                        background-color:#fff;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
            .Out_right{
                flex: 1;
                // width: calc( 100% - 4.7rem );
                display: flex;
                align-items: flex-start;
                .inside{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 0.5rem;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid #ccc;
                    .In_left{
                        width: calc( 90% );
                        display: flex;
                        align-items: center;
                        align-content: center;
                        flex-wrap: wrap;
                        .name{
                            width: 100%;
                            height: 1.2rem;
                            font-size: 1rem;
                            font-weight: 600;
                            line-height: 1.2rem;
                            display: flex;
                            @media (max-width: 480px){
                                font-size: .9rem;
                                font-weight: 500;
                            }
                            span {
                                flex: 1;
                                overflow: hidden;  
                                text-overflow: ellipsis;  
                                display: -webkit-box;  
                                -webkit-box-orient: vertical;  
                                -webkit-line-clamp: 1; 
                            }
                            span.date {
                                max-width: 5rem;
                                overflow: hiden;
                                height: 1.2rem;
                                font-size: .8rem;
                                font-weight: 400;
                                text-align: right;
                                color: #ccc;
                            }
                        }
                        .message{
                            width: 100%;
                            max-height: 2.8rem;
                            font-size: .9rem;
                            color: #aaa;
                            font-weight: 400;
                            line-height: 1.4rem;
                            word-break: break-all;
                            overflow: hidden;  
                            text-overflow: ellipsis;  
                            display: -webkit-inline-box;  
                            -webkit-box-orient: vertical;  
                            -webkit-line-clamp: 2; 
                        }
                        // .date{
                        //     height: 1.1rem;
                        //     font-size: .8rem;
                        //     text-align: right;
                        //     color: #ccc;
                        // }
                    }
                    .In_right{
                        // width: 5%;
                        flex: 1;
                        .arrow{
                            position: relative;
                            width: 100%;
                            height: 3.7rem;
                            .notice{
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                background-color: rgb(217, 29, 48);
                                border-radius: 999rem;
                                color: #fff;
                                width: 20px;
                                height: 20px;
                                font-size: 10px;
                                line-height: 20px;
                                padding: 2px 2px;
                                text-align: center; 
                            }
                            i{
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                text-align: center; 
                            }
                        }
                    }
                }
            }
        }
    }
`;