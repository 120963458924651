import styled from 'styled-components';

export const Wrapper = styled.div `
    width: 100%;
    font-size: 14px;
`;
export const ButtonGroup = styled.div `
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    .ButtonGroup_button {
        font-weight: 400;
        height: 35px;
        margin: 5px;
        flex: 1;
        background-color: #da263c;
        // border: 1px solid rgba(162,28,46,1);
        // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
        // text-shadow: 0px -2px rgb(223,28,44);
        border-radius: 999rem;
        display: flex;
        align-items:center; 
        justify-content:center;
        position: relative;

        

        p {
            flex: 1;
            font-size: .8rem;
            font-weight: 400;
        }

        .ButtonGroup_button_child{
            display: flex;
            align-items:center; 
            justify-content:center;

            i.material-icons {
                font-size: 1.4rem;
                font-weight: 400;
            }
        }
    }
    .ButtonGroup_button_copyLink:hover {
        text-shadow: unset;
        &::before {
            content: '${props => ( props.children[0] && props.children[0].props && props.children[0].props.copyLink )}';
            font-size: 10px;
            background-color: #fffcec;
            width: auto;
            height: 1.4rem;
            line-height: 1.4rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            position: absolute;
            bottom: -1.4rem;
            left: -0.4rem;
            padding: 2px;
            opacity: 0.8;
            color: #000;
            z-index: 1;
        }
    }

`;