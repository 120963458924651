import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestResetPassword } from '../../../actions';
import { FormControl } from '../../Common';
import { Wrapper } from './styled';
import SubmitButton from '../SubmitButton';

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      authPassword: '',
      code: '',
      email: '',
    };
  }

  componentDidMount() {
    const {email, clientId, redirectUri, scope} = this.props.data;
    this.setState({ email,clientId,redirectUri,scope });
  }

  onChange(field, e) {
    this.setState({ [field]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email, password, authPassword, code, clientId, redirectUri, scope } = this.state;
    const passwordRule = /^(?=.*[a-zA-Z]).{6,15}$/;

    if (!password) {
      alert('您尚未輸入密碼。');
      return;
    }
    if( password && password.search(passwordRule) === -1){
      alert('請確認密碼格式正確！')
      return
    }
    if (password !== authPassword) {
      alert('您的密碼有誤，請再確認。');
      return;
    }
    
    if (!code) {
      alert('您尚未輸入驗證碼。');
      return;
    }

    if (clientId && redirectUri && scope) {
      this.props.requestResetPassword(email, code, password, clientId, redirectUri, scope);
    } else {
      this.props.requestResetPassword(email, code, password);
    }

  }

  render() {
    const { password, authPassword, code } = this.state;
    return (
      <Wrapper>
        <form className='main-form'>
          <h1 className='title'>修改密碼</h1>
          <FormControl>
            <label>設定新密碼：</label>
            <input name='password' type='password' value={password} placeholder='請輸入新密碼(包含英文及數字6個字元以上)' onChange={this.onChange.bind(this, 'password')} />
          </FormControl>
          <FormControl>
            <label>再次輸入新密碼：</label>
            <input name='authPassword' type='password' value={authPassword} placeholder='請再次輸入您的新密碼' onChange={this.onChange.bind(this, 'authPassword')} />
          </FormControl>
          <FormControl>
            <label>驗證碼：</label>
            <input name='code' type='text' value={code} placeholder='請輸入寄到您信箱中的驗證碼' onChange={this.onChange.bind(this, 'code')} />
          </FormControl>
          <SubmitButton onClick={e => this.handleSubmit(e)} />
        </form>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>  ({
  requestResetPassword:  (email, code, password, clientId, redirectUri, scope) => dispatch(requestResetPassword(email, code, password, clientId, redirectUri, scope)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
