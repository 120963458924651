import React, { Component } from "react";
import { CityData } from "../../SelectLocation/tw-city";
import { Select, FormControl } from "../../Common";
import { Wrapper } from './styled';
import Config from '../../config';

const style = {
    marginTop: "10px"
};

// 將資料塞到 CityData.counties . CityData.districts
const makeAreaOption = () => {
    const districts = [{ text: '請選擇', value: '', disabled: true }];
    CityData.districts[0][0].map(value =>
        districts.push({ text: `${value}`, value: value })
    );

    return districts;
};

const makeCityOption =() =>{
    const counties = [
        {
            value: "選擇其他縣市",
            disabled: true
        }
    ]
    CityData.counties.map(value => counties.push({ value: value }));
    return counties;
}


class GoogleMapInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            area: "中正區",
            city: "台北市",
            cityOptions: makeCityOption(),
            areaOptions: makeAreaOption(),
        };
        this.cityRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state.city);
        if (this.props.mapAddress &&
            this.props.mapAddress !== prevProps.mapAddress) {

            let city =  this.props.mapAddress.find( e => {
                return e.types[0] === "administrative_area_level_1" || 
                e.types[0] === "administrative_area_level_2"
            }) 

            let area =  this.props.mapAddress.find( e => {
                return e.types[0] === "administrative_area_level_3"
            } )
            if (!city) return

            // set new city new districts
            if ( city.long_name !== this.state.city) {
                // find index of new city
                let cityIndex
                this.cityRef.current.props.options.forEach((e, index) => {
                    if (cityIndex) return
                    if (e.value === city.long_name ) {
                        cityIndex = index
                    }
                })

                if (!cityIndex) {
                    // console.log("no this index, error handling");
                    return
                }

                const districts = [{ text: '請選擇', value: '', disabled: true }];
                // 將 CityData.districts 複製到 districts
                CityData.districts[cityIndex - 1][0].map(value =>
                    districts.push({
                        text: `${value}`,
                        value: value
                    })
                );

                this.setState({
                    areaOptions: districts,
                })
                this.setState({
                    area: districts[1].value
                }, () => this.props.onChange(this.state))
            }

            if(!area) {
                this.setState({
                    city: city.long_name,
                    address: ""
                }, () => this.props.onChange(this.state))
                return
            } 
            let areaIndex = this.props.mapAddressFormat.indexOf(area.long_name)
            let areaLength = area.long_name.length

            let address = this.props.mapAddressFormat.substring(areaIndex + areaLength)

            

            this.setState({
                city: city.long_name,
                area: area.long_name,
                address: address
            }, () => this.props.onChange(this.state));

            
        }

        // if suggestionLocate changes
        if ( this.props.suggestionLocate &&
            this.props.suggestionLocate !== prevProps.suggestionLocate ){
                if ( !this.props.suggestionLocate.city) return
                if (this.props.suggestionLocate.city !== this.state.city ){
                    // find index of new city
                    let cityIndex
                    this.cityRef.current.props.options.forEach((e, index) => {
                        if (cityIndex) return
                        if (e.value === this.props.suggestionLocate.city ) {
                            cityIndex = index
                        }
                    })

                    if (!cityIndex) {
                        // console.log("no this index, error handling");
                        return
                    }

                    const districts = [{ text: '請選擇', value: '', disabled: true }];
                    // 將 CityData.districts 複製到 districts
                    CityData.districts[cityIndex - 1][0].map(value =>
                        districts.push({
                            text: `${value}`,
                            value: value
                        })
                    );

                    this.setState({
                        areaOptions: districts,
                    })
                }
                this.setState({
                    city: this.props.suggestionLocate.city,
                    area: this.props.suggestionLocate.area,
                    address: this.props.suggestionLocate.address,
                }, () => this.props.onChange(this.state));

        }

        // can set more restrict (為了防止 place_name 是台北市的情況)
        if( this.props.isFindAddress === true && this.props !== prevProps ){

            let city
            if ( this.props.isFindAddress ){

                let citySetByPlace = CityData.counties.filter( e => e === this.props.place_name)
                
                if(citySetByPlace.length>0){
                    city = citySetByPlace
                }
                // have to let it not execute only one time , set isFindAddress false
                this.props.setIsFindAddressFalse()
            }

            if(!city) return

            // console.log("after find no address, city exists, city: ", city);
            if ( city[0] !== this.state.city) {
                // find index of new city
                let cityIndex
                this.cityRef.current.props.options.forEach((e, index) => {
                    if (cityIndex) return
                    if (e.value === city[0] ) {
                        cityIndex = index
                    }
                })

                if (!cityIndex) {
                    // console.log("no this index, error handling");
                    return
                }

                const districts = [{ text: '請選擇', value: '', disabled: true }];
                // 將 CityData.districts 複製到 districts
                CityData.districts[cityIndex - 1][0].map(value =>
                    districts.push({
                        text: `${value}`,
                        value: value
                    })
                );

                this.setState({
                    city: city[0],
                    address: "",
                    areaOptions: districts,
                    area: districts[1].value,
                }, () => this.props.onChange(this.state))
            }
        }

    }


    handleChange = e => {
        this.setState({
            address: e.target.value
        }, () => this.props.onChange(this.state));
    }

    handleSelect = (e) => {
        // 選擇 city 的時候
        const index = e.nativeEvent.target.selectedIndex;
        const currentCity = e.target.value;
        const districts = [{ text: '請選擇', value: '', disabled: true }];
        // 將 CityData.districts 複製到 districts
        CityData.districts[index - 1][0].map(value =>
            districts.push({
                text: `${value}`,
                value: value
            })
        );
        this.setState({
            city: currentCity,
            area: "",
            areaOptions: districts
        }, () => this.props.onChange(this.state));
    }

    handleSelectArea = (e) => {
        this.setState({
            area: e.target.value
        }, () => this.props.onChange(this.state));
    }


    render() {
        const { address, cityOptions, areaOptions, area, city } = this.state;
        let googleMapAddress = `${city} ${area} ${address}`

        return (
            <Wrapper>
                <FormControl>
                    <label>球場地址：</label>
                    <div className="city">
                        <Select
                            ref={this.cityRef}
                            value={city}
                            options={cityOptions}
                            onChange={this.handleSelect}
                        />
                    </div>
                    <div className="area">
                        <Select
                            // ref = {this.areaRef}
                            value={area}
                            options={areaOptions}
                            onChange={this.handleSelectArea}
                        />
                    </div>

                    <input className="address"
                        type="text"
                        onChange={this.handleChange}
                        name="address"
                        value={address}
                        placeholder="請輸入球場地址"
                        // id="mapSearch" 
                    />


                    <iframe
                        title="Google Map"
                        width="100%"
                        height="130"
                        frameBorder="0"
                        style={style}
                        src={`https://www.google.com/maps/embed/v1/place?key=${Config.googleMapKey}&q=${googleMapAddress}`}
                        allowFullScreen
                    />
                </FormControl>
            </Wrapper>
        );
    }
}

export default GoogleMapInput;
