import React, { Component } from "react";
import { FrequencySelect, SkillLevelSelect, CourtSizeSelect, CourtName, AgeSelect, GroupPeopleNumberSeelct, ImageUploader, SubmitButton, TimeForPlay, FormControl, Select } from "../Common";
import Banner from './Banner';
import GroupNameInput from './GroupNameInput';
import GroupPrice from './GroupPrice';
import CourtFeatures from "./CourtFeatures";
import CourtAddress from "./CourtAddress";
import { Wrapper } from './styled';
import { connect } from "react-redux";
import { createGroup, getLevels, getPreferences, createFeature, uploadImg, setImgUrl, getActivity, addFeature, fetchUserInfo, getMapApiData } from "../../actions";
import moment from 'moment'
import { withRouter } from 'react-router'

import Config from '../config';
import { localStorageUtility } from '../../utilities';

class NewGroup extends Component {
    constructor(props) {
        super(props);
        const now = moment();
        this.state = {
            group_name: '',
            people: 1,
            startDate: moment(now),
            startTime: '12:00',
            endDate: moment(now),
            endTime: '12:00',
            frequency: {
                type: '',
                value: ''
            },
            place_name: '',
            geo: {
                latitude: '',
                longitude: ''
            },
            latitude: '',
            longitude: '',
            address: '',
            badminton_level: 1,

            plan: {
                Cash: 0,
                // CreditEcpay: ''
            },

            place_count: 1,
            age_min: '0',
            age_max: '100',
            tagNames: [],
            tag: [],
            city: '',
            area: '',
            defaultCity: '',
            defaultArea: '',
            defaultAddress: '',
            start: '',
            end: '',
            images: { url: [] },
            ball_brand: ' ',
            disable: false,

            refundPeriod:1,

            type:1,
            offline: true,
            online: false,
            suggestionLocate:{
                city: '',
                area: ''
            },
            isAutoSuggested: false,
            
            isFindAddress: false,
        };
    }

    componentDidMount() {
        const data = localStorageUtility('get', 'myData');
        if (data == null) {
            document.cookie = "backurl="+window.location.pathname;
            alert('請先登入')
            this.props.history.replace('/login')
        } else {
          this.props.getLevels()
          this.props.getPreferences()
          this.props.fetchUserInfo()
        }
        const urlParams = new URLSearchParams(this.props.location.search)
        const key = urlParams.get('id')
        if (key) {
            this.props.getActivity(key)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userInfo !== this.props.userInfo && nextProps.userInfo.phoneVerified === false) {
            document.cookie = "backurl="+window.location.pathname;
            alert('您的手機尚未經過驗證，請先完成驗證')
            this.props.history.replace('/my_info/profile')
        }
        if (nextProps.url !== this.props.url) {
            this.setState({ images: { url: [nextProps.url] } });
        }
        if (nextProps.activity && Object.keys(nextProps.activity).length > 0 && 
            nextProps.activity !== this.props.activity) {
            var ac = nextProps.activity
            const acStateDate = moment(moment.unix(ac.start / 1000).format('YYYY/MM/DD'))
            const acEndDate = moment(moment.unix(ac.end / 1000).format('YYYY/MM/DD'))
            const acStartTime = moment.unix(ac.start / 1000).format('HH:mm')
            const acEndTime = moment.unix(ac.end / 1000).format('HH:mm')
            this.setState({
                group_name: ac.group_name,
                people: ac.people,
                frequency: { value: ac.frequency },
                address: ac.address,
                city: ac.city,
                area: ac.area,
                badminton_level: ac.badminton_level,
                
                place_count: ac.place_count,
                age_min: ac.age_min,
                age_max: ac.age_max,
                place_name: ac.place_name,
                startDate: acStateDate,
                startTime: acStartTime,
                endDate: acEndDate,
                endTime: acEndTime,
                images: ac.images,
                tagNames: ac.tag.map((item) => {
                    var payload = { name: item.name, id: item.id }
                    this.props.addFeature(payload)
                    return item.name
                }),
                defaultAddress: ac.address,
                defaultArea: ac.area,
                defaultCity: ac.city,
            });
            if(ac.fee){
                this.setState({
                    plan:{
                        Cash: ac.fee,
                    } 
                    // CreditEcpay ?
                })
            }else if(ac.plan){
                if(ac.plan.Cash){
                    this.setState({
                        plan:{
                            Cash: ac.plan.Cash,
                        } 
                    })
                }
                if(ac.plan.CreditEcpay){
                    this.setState({
                        plan:{
                            // Cash: ac.fee,
                            CreditEcpay: ac.plan.CreditEcpay
                        }
                    })
                    if(ac.plan.Cash){
                        this.setState({
                            plan:{
                                Cash: ac.plan.Cash,
                                CreditEcpay: ac.plan.CreditEcpay
                            } 
                        })
                    }
                }
                
            }
        }
    }

    handleGetMapAddress = (event)=>{
        // click search (find address)
        // if(event === "click"){
        //     this.props.getMapApiData(this.state.place_name, Config.googleMapKey)
        // } 
        // if (this.state.isAutoSuggested){
            this.props.getMapApiData({address: this.state.place_name})
        // }

        this.setState({isFindAddress: true})
    }

    handleCourtNameSelected = ({ suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            suggestionLocate:{
                city:suggestion.city,
                area:suggestion.area,
                address: suggestion.address,
            },
            defaultArea: suggestion.area,
            defaultCity: suggestion.city,
            defaultAddress: suggestion.address,
            place_name: suggestion.place_name,
            isAutoSuggested: true,
        })
    }
    componentDidUpdate(prevProps){
        if( JSON.stringify( this.props.userInfo ) !=='{}' && this.props !== prevProps ){
                // console.log("will setstat of type ");
            this.setState({
                type: this.props.userInfo.type
            }) 
            // if(this.state.type === 1 ) this.setState({offline: true })

        }    
    }

    // handle below:
    // age_max, age_min, frequency, address
    // "startDate", "startTime", "endDate", "endTime"
    handleOnChange = (element, e) => {
      let { startDate, startTime, endDate, endTime } = this.state;
        if (element === "frequency") {
            if (e.target.value !== "") {
                var frequency = { type: e.target.value, value: 1 }
                this.setState({ frequency: frequency })
            } else {
                this.setState({ frequency: {} })
            }
        } else if (element === "address") {
            this.setState({
                address: e.address,
                city: e.city,
                area: e.area
            })
        } else if (-1 !== ["startDate", "startTime", "endDate", "endTime"].indexOf(element)) {
            const state = {};

            if (element === "startDate") {
                startDate = state[element] = e.d;
            } else if (element === "startTime") {
                startTime = state[element] = e;
            } else if (element === "endDate") {
                endDate = state[element] = e.d;
            } else {
                endTime = state[element] = e;
            }

            let start = this.toDateTime(startDate, startTime);
            let end = this.toDateTime(endDate, endTime);

            if (start.isAfter(end)) {
                if (element === "startDate" || element === "startTime") {
                    state['endDate'] = moment(startDate);
                    state['endTime'] = startTime;
                } else {
                    state['startDate'] = moment(endDate);
                    state['startTime'] = endTime;
                }
            }
            this.setState(state);
        } else {
            if (element === 'age_min') {
              if (Number.parseInt(e.target.value, 10) > Number.parseInt(this.state['age_max'], 10)) {
                  this.setState({ age_max: e.target.value });
              }
            } else if (element === 'age_max') {
              if (Number.parseInt(e.target.value, 10) < Number.parseInt(this.state['age_min'], 10)) {
                  this.setState({ age_min: e.target.value });
              }
            }            
            this.setState({ [element]: e.target.value })
        }
    }

    handlePaymentMethodOnChange = (element, e) =>{
        // delete input value when it is empty, will happen ''
        if( element === 'Cash'){
            this.setState({
                plan:{
                    Cash: parseInt( e.target.value) || 0
                }
            })
        }

        if(element === 'CreditEcpay'){
            this.setState({
                plan:{
                    CreditEcpay: parseInt( e.target.value) || 0
                }
            })
        }

        if (this.state.online ===true && this.state.offline===true ){
            // console.log(e.target, e.target.value);
            if(element === 'CreditEcpay'){
                this.setState({
                    plan:{
                        ...this.state.plan,
                        CreditEcpay: parseInt( e.target.value) || 0
                    }
                }) 
                // this.setState( prevState => ({
                //     plan:{
                //         ...prevState,
                //         CreditEcpay: e.target.value
                //     }
                // }) )
            }
            if(element === 'Cash'){
                this.setState({
                    plan:{
                        ...this.state.plan,
                        Cash: parseInt( e.target.value) || 0
                    }
                })
            }
        }
    }

    toDateTime = (date, time) => moment(date.format("YYYY/MM/DD") + " " + time)

    handleSubmit = (e) => {
        // debugger
        if (this.props.uploadStatus) { alert('圖片上傳中'); e.preventDefault(); return }
        if (!this.state.group_name) { alert('團名為必填欄位'); e.preventDefault(); return }
        if (!this.state.place_name) { alert('場地名稱為必填欄位'); e.preventDefault(); return }
        if (!this.state.address) { alert('球場地址為必填欄位'); e.preventDefault(); return }
        if (!this.state.area) { alert('球場地址的區域為必填欄位'); e.preventDefault(); return }
        // '' 就先篩掉
        if(this.state.online === true && (this.state.plan.CreditEcpay === '' || this.state.plan.CreditEcpay === undefined )){
            e.preventDefault();
            return alert('oops!! 線上付款不可設為 0 元!!')
        }
        if(this.state.offline === true && !this.state.plan.Cash ){
            if(window.confirm('oops!! 忘記輸入打球費用囉! 還是您確定要開免費團?') ){
                // 確定 就讓他開團
                this.setState({ plan:{
                        Cash: 0
                }})
            } else{
                e.preventDefault();
                return 
            }
        }
        if(this.state.online === true && this.state.offline === true ){
            this.setState({ plan:{
                Cash: this.state.plan.Cash ,
                CreditEcpay: this.state.plan.CreditEcpay
            }})
        } else if (this.state.online === true){
            this.setState({ plan:{
                CreditEcpay: this.state.plan.CreditEcpay
            }})
        } else if (this.state.offline === true){
            this.setState({ plan:{
                Cash: this.state.plan.Cash
            }})
        }
        // 關掉線上和現場付費，設 Cash 0 元
        if( this.state.online === false && this.state.offline === false ){

            if( this.state.type === 1){
                if(window.confirm('確定要開免費團嗎') ){
                    // 確定 就讓他開團
                    this.setState({ plan:{
                        Cash: 0
                    }})
                } else{
                    e.preventDefault();
                    return 
                }
            }else if (this.state.type === 0 && this.state.plan.Cash === ''){
                if(window.confirm('確定要開免費團嗎') ){
                    // 確定 就讓他開團
                    this.setState({ plan:{
                        Cash: 0
                    }})
                } else{
                    e.preventDefault();
                    return 
                }                
            }
            
        }

        // 線上付費不設 0
        if(this.state.plan.CreditEcpay <= 0 ){
            alert('線上付款不可設為 0元')
            e.preventDefault();
            return 
        }

        var unixTime = this.toDateTime(this.state.startDate, this.state.startTime).unix();
        var unixTime2 = this.toDateTime(this.state.endDate, this.state.endTime).unix();
        if (moment.unix(unixTime).isBefore(moment())) { alert('開團時間已過'); e.preventDefault(); return }
        var tags = this.props.features
        var tagsId = []
        for (var i = 0; i < this.state.tagNames.length; i++) {
            for (var j = 0; j < tags.length; j++) {
                if (this.state.tagNames[i] === tags[j].name) {
                    tagsId.push(tags[j].id)
                }
            }
        }
        
        this.setState({refundPeriod : parseInt(this.state.refundPeriod, 10) })
        this.setState({ start: unixTime + '000', end: unixTime2 + '000', tag: tagsId, disable: true }, () => {
            var address = this.state.city + this.state.area + this.state.address
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${Config.googleMapKey}`, { method: 'get' })
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                this.setState({
                    latitude: response.results[0].geometry.location.lat,
                    longitude: response.results[0].geometry.location.lng
                }, () => {
                    this.props.onSubmit(this.state);
                });
            }.bind(this));

        })

        e.preventDefault();
    }

    setIsFindAddressFalse = () => {
        this.setState({isFindAddress: false})
    }

    handleAddFeature = (text) => {
        this.props.createFeature({ name: text });
        this.setState({ tagNames: [...this.state.tagNames, text] })
    }

    handleRemoveFeature = (text) => {
        //debugger
        this.setState({ tagNames: this.state.tagNames.filter(x => x !== text) })
    }

    setOnline = () =>{
        if (this.state.offline === true ) {
            this.state.online ? this.setState({ online: false }) : this.setState({ online: true })
        }
    }
    setOffline = () =>{
        if (this.state.online === true ) {
            this.state.offline ? this.setState({ offline: false }) : this.setState({ offline: true })
        }
    }

    onFileChange = file => {
        if (file) {
            this.props.uploadImg(file);
        } else {
            var emptyImage = { url: [] };
            this.setState({ images: emptyImage })
            this.props.setImgUrl('');
        }
    }

    print = ()=>{
        // '' 就先篩掉
        if(this.state.online === true && (this.state.plan.CreditEcpay === '' || this.state.plan.CreditEcpay === undefined )){
            // e.preventDefault();
            return alert('請輸入線上付費金額 或是關閉線上付款方式')
        }
        if(this.state.offline === true && (this.state.plan.Cash === '' || this.state.plan.Cash === undefined ) ){
            // e.preventDefault();
            return alert('請輸入現場付費金額 或是關閉現場付款方式')
        }

        if(this.state.online === true && this.state.offline === true ){
            this.setState({ plan:{
                Cash: this.state.plan.Cash ,
                CreditEcpay: this.state.plan.CreditEcpay
            }})
        } else if (this.state.online === true){
            this.setState({ plan:{
                CreditEcpay: this.state.plan.CreditEcpay
            }})
        } else if (this.state.offline === true){
            this.setState({ plan:{
                Cash: this.state.plan.Cash
            }})
        }
        // 如果有金流權限 又關掉線上和現場付費
        if( this.state.online === false && this.state.offline === false ){

            if( this.state.type === 1){
                if(window.confirm('確定要開免費團嗎') ){
                    // 確定 就讓他開團
                    this.setState({ plan:{
                        Cash: 0
                    }})
                } else{
                    // e.preventDefault();
                    return 
                }
            }else if (this.state.type === 0){
                if(this.state.plan.Cash === ''){
                    this.setState({ plan:{Cash: 0} })
                }
            }
        }

        // 線上付費不設 0
        if(this.state.plan.CreditEcpay === 0 ){
            this.setState({plan:{
                Cash: this.state.plan.Cash || 0
            }})
        }

        // setTimeout( ()=>{  console.log(this.state.plan) }, 1000)
    }

    render() {
        const { preferences } = this.props;

        return (
            <Wrapper key={0}>
                <Banner />
                <form onSubmit={this.handleSubmit} className="main-form">
                    <GroupNameInput value={this.state.group_name} onChange={(e) => this.handleOnChange("group_name", e)} />

                    {this.state.type === 1 
                        &&
                        <Select label={"請假截止日(開團前幾天)："} options={[{value:1}, {value:2}, {value:3}]} 
                        onChange={ e=> { this.handleOnChange('refundPeriod', e)}} value={this.state.refundPeriod} />
                    }

                    <GroupPeopleNumberSeelct value={this.state.people} onChange={(e) => this.handleOnChange("people", e)} />
                    <TimeForPlay
                        label='開始時間：'
                        date={this.state.startDate}
                        time={this.state.startTime}
                        name={'d'}
                        onDateChange={(e) => this.handleOnChange("startDate", e)}
                        onTimeChange={(e) => this.handleOnChange("startTime", e)} />
                    <TimeForPlay
                        label='結束時間：'
                        date={this.state.endDate}
                        time={this.state.endTime}
                        name={'d'}
                        onDateChange={(e) => this.handleOnChange("endDate", e)}
                        onTimeChange={(e) => this.handleOnChange("endTime", e)} />
                    <FrequencySelect value={this.state.frequency} onChange={(e) => this.handleOnChange("frequency", e)} />
                    <div className="place_name">
                        <label>場地名稱：</label>
                        <div className="place_name__box">
                            <CourtName
                                inputProps={{
                                    onChange: (e) => this.handleOnChange("place_name", e),
                                    // onBlur: this.handleGetMapAddress,
                                    placeholder: "請輸入場地名稱"
                                }}
                                value={this.state.place_name}
                                suggestions={preferences.filter((preference) => preference.place_name.indexOf(this.state.place_name) !== -1)}
                                onSuggestionSelected={this.handleCourtNameSelected}
                                getSuggestionValue={(suggestion) => `${suggestion.place_name}`}
                                renderSuggestion={(suggestion) => `${suggestion.place_name}`}
                            />  
                            <span className="place_name_findAddress" onClick={ ()=>{this.handleGetMapAddress('click') }}>
                                <i className="material-icons">search</i>
                                <span className='place_name_findAddress__span'>找地址</span> 
                            </span>
                        </div>
                    </div>
                    <CourtAddress
                        setIsFindAddressFalse ={this.setIsFindAddressFalse}
                        place_name = {this.state.place_name}
                        isFindAddress = {this.state.isFindAddress}
                        isAutoSuggested = {this.state.isAutoSuggested}
                        suggestionLocate = {this.state.suggestionLocate}
                        mapAddress={this.props.mapAddress}
                        mapAddressFormat={this.props.mapAddressFormat}
                        value={this.state.address}
                        onChange={(e) => this.handleOnChange("address", e)}
                        defaultCity={this.state.defaultCity}
                        defaultArea={this.state.defaultArea}
                        defaultAddress={this.state.defaultAddress}
                    />
                    <SkillLevelSelect 
                        levels={this.props.levels} 
                        value={this.state.badminton_level} 
                        onChange={(e) => this.handleOnChange("badminton_level", e)} />

                    {/* <span onClick={this.print}> print</span> */}

                    <FormControl>
                        { 
                            this.state.type === 1 ?  
                            <div className="paymentType__chooseBox">
                                <label>請選擇付款方式(可複選)：</label>
                                <div className={["paymentType__label", this.state.offline ? "active" : "" ].join(' ')} onClick={this.setOffline }> 現場付費 </div>
                                <div className={["paymentType__label", this.state.online ? "active" : "" ].join(' ')} onClick={this.setOnline}> 線上付費 </div>
                            </div>
                            :
                            <GroupPrice value={this.state.plan.Cash} onChange={(e) => this.handlePaymentMethodOnChange("Cash", e)} />
                        }
                    </FormControl>
                    
                    {
                        this.state.type === 1 && this.state.offline &&
                        // prevent deleting input value when value is empty will get ''
                        <GroupPrice value={ this.state.plan.Cash || this.state.plan.Cash ==='' ? this.state.plan.Cash : 0 } onChange={(e) => this.handlePaymentMethodOnChange("Cash", e)} paymentTypeText="現場付費金額"/> 
                    }
                    {
                        this.state.type === 1 && this.state.online &&
                        // prevent deleting input value when value is empty will get ''
                        <GroupPrice value={ this.state.plan.CreditEcpay || this.state.plan.CreditEcpay ==='' ? this.state.plan.CreditEcpay : 0 } onChange={(e) => this.handlePaymentMethodOnChange("CreditEcpay", e)} paymentTypeText="線上付費金額"/> 
                    }
                    <CourtSizeSelect value={this.state.place_count} onChange={(e) => this.handleOnChange("place_count", e)} />
                    <AgeSelect t={"(起)"} isStart label={"球友年齡"} value={this.state.age_min} onChange={(e) => this.handleOnChange("age_min", e)} />
                    <AgeSelect t={"(迄)"} isEnd value={this.state.age_max} onChange={(e) => this.handleOnChange("age_max", e)} />
                    <FormControl>
                        <ImageUploader
                            showThumbnail={true}
                            useSizeLimit
                            url={this.state.images.url[0]}
                            onChange={file => this.onFileChange(file)}
                            onCancel={() => this.onFileChange()}
                        />
                    </FormControl>
                    <FormControl>
                        <CourtFeatures features={this.state.tagNames} addFeature={this.handleAddFeature} removeFeature={this.handleRemoveFeature} />
                    </FormControl>
                    <br />
                    <SubmitButton text="確認開團" onClick={this.handleSubmit} disable={this.state.disable}/>
                </form>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        url: state.imageUploadReducer.url,
        uploadStatus: state.imageUploadReducer.uploadStatus,
        levels: state.newGroupReducer.levels,
        preferences: state.newGroupReducer.preferences || [],
        features: state.newGroupReducer.features,
        activity: state.newGroupReducer.activity,
        mapAddress: state.googleMapReducer.address.addressArray,		
        mapAddressFormat: state.googleMapReducer.address.formatted_address,
        userInfo: state.myInfoReducer.userInfo,
    }
};

const mapDispatchToProps = ({
    onSubmit: createGroup,
    getLevels: getLevels,
    getPreferences: getPreferences,
    createFeature: createFeature,
    uploadImg: uploadImg,
    setImgUrl: setImgUrl,
    getActivity: getActivity,
    addFeature: addFeature,
    fetchUserInfo: fetchUserInfo,
    getMapApiData: getMapApiData,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewGroup));
