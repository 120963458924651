// import moment from 'moment';
import { createAction } from 'redux-actions';
import { fetchObject } from './fetchModule';
import {
    BELONG_UPDATE_DATA,
} from '../constants/actionTypes';
import { history } from '../index';

export const belongUpdateData = createAction(BELONG_UPDATE_DATA);

export const belongUpdate = ( data ) => {
    //debugger
    return (dispatch) => {
        fetchObject(`racketorder/belongUpdate`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(function (response) {
                console.log(response);
                if (response.ok) return response;
                else
                    response.then(function(err) {
                        //throw new Error(err);
                    });
            })
            .then(function (json) {
                // const status = {
                //     reload: false
                // }
                let jsonparse = '';
                const contentType = json.headers.get('Content-Type');
                //確認json型態取值
                if(contentType != null) {
                    if(contentType.indexOf('text') > -1) {
                        jsonparse = json.text()
                    }
                    if(contentType.indexOf('form') > -1) {
                        jsonparse = json.formData();
                    }
                    if(contentType.indexOf('video') > -1) {
                        jsonparse = json.blob();
                    }
                    if(contentType.indexOf('json') > -1) {
                        jsonparse = json.json();
                    }
                }
                if (!data.previous) {
                    return;
                }
                return jsonparse;
            })
            .then((resData) => {
                //get form data
                dispatch(belongUpdateData(resData));
                //return data;
                window.alert('申請成功');
                // window.location.href = "/my_info/rent_contract_detail/"+data.order_id;
                history.replace(`/my_info/rent_contract_detail/${data.order_id}`);
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}
