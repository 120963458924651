import styled from 'styled-components';

export const Wrapper = styled.div `

background-color: #999;

@media (max-width: 480px) {
	margin-bottom: 120px;
}

#WRAPPER{
	width:100%;
	max-width: 440px;/* 限制最大寬不超過440px */
	margin:0 auto;
	font-size: 8px; /* 指使文字em的標準 */
}

.w-100{
	width:100%;
}

.video{
	width: 100%;
    height: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    background-color: rgb(46, 46, 46);
    padding: 20px 0px;
}

.video .video_title{
	color: rgb(255, 255, 255);
	text-align: center;
	margin-bottom: 20px;
	flex: 0 0 100%;
}

.video  .video_title h2{
	font-size: 1.2rem;
}

.video  .video_title h3{
	font-size: 0.9rem;
}

/* .title-area{
	display:flex;
	justify-content:space-between;
	align-items: center;
	background-color: #555354;
	padding:10px 20px;
}
.title-area .title-text{
	color:#fff;
	font-size:3.5em
} */

.separate{
	height:15px;
	background-color:#fff0;
	padding:10px;
}

.separate div{
	border-bottom:3px dashed #fff;
	padding-top: 6px;
}
`