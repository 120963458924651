import React, { Component } from "react";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import{
    checkLoveCode,
    checkMobileBarCode,
    updateIsLoveCodeCorrect,
    updateIsMobileBarCodeCorrect,
} from "../../../actions";
import store from '../../../store';
import { Wrapper } from "./styled";

let vatNumberKey;
class InvoiceField extends Component {
    constructor(props) {
        super(props);
        vatNumberKey = this.props.isGroupDetail ? 'vatNumber' : 'vat_number'
        this.state = {
            invoiceInfo:{
                invoiceType: 'twoCopies',
                invoiceCheck: true,
                [vatNumberKey]: '',
                // vat_number: '',
                print: 0,
                donation: 0,
                loveCode: '',
                carruerType: 1,
                carruerNum: '',
                errMessage: '',
            },
        };
    }

    componentDidUpdate(prevProps, prevState){
        if( prevProps.isLoveCodeCorrect !== this.props.isLoveCodeCorrect){
            // console.log(this.props.isLoveCodeCorrect);
            if (this.props.isLoveCodeCorrect ){
                this.setState({
                    invoiceInfo: {
                        ...this.state.invoiceInfo,
                        invoiceCheck: true,
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }else{
                // console.log(" this.props. is correct === false");
                this.setState({
                    invoiceInfo: {
                        ...this.state.invoiceInfo,
                        invoiceCheck: false,
                        errMessage: '請確認愛心碼格式是否正確！'
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }
        }
        if( prevProps.isMobileBarCodeCorrect !== this.props.isMobileBarCodeCorrect){
            // console.log(this.props.isMobileBarCodeCorrect);
            if (this.props.isMobileBarCodeCorrect ){
                this.setState({
                    invoiceInfo: {
                        ...this.state.invoiceInfo,
                        invoiceCheck: true,
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }else{
                // console.log(" this.props. is correct === false");
                this.setState({
                    invoiceInfo: {
                        ...this.state.invoiceInfo,
                        invoiceCheck: false,
                        errMessage: '請確認手機憑證條碼格式是否正確！'
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }
        }
    }

    handleInvoiceTypeClick = (e)=>{
        let value = e.target.value;
        if(value === 'twoCopies'){
            this.setState({
                invoiceInfo:{
                    invoiceType: e.target.value,
                    invoiceCheck: true,

                    print: 0, // 先不開放使用者自行列印
                    donation: 0,
                    loveCode: '',
                    [vatNumberKey]: '',

                    carruerType: 1,
                    carruerNum: '',
                }
            }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            store.dispatch(updateIsMobileBarCodeCorrect(false));
        } else if (value === 'threeCopies'){
            this.setState({
                invoiceInfo:{
                    invoiceType: e.target.value,
                    invoiceCheck: false,
                    errMessage: '請確認統一編號格式是否正確！',
                    // vat_number: ,

                    print: 1,
                    donation: 0,
                    loveCode: '',
                    carruerType: '',
                    carruerNum: '',
                }
            }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
        } else if (value === 'donation'){
            this.setState({
                invoiceInfo:{
                    invoiceType: e.target.value,
                    invoiceCheck: false,
                    errMessage: '請確認愛心碼格式是否正確！',

                    print: 0,
                    donation: 1,
                    // loveCode: ,
                    carruerType: '',
                    carruerNum: '',
                    [vatNumberKey]: '',
                }
            }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            store.dispatch(updateIsLoveCodeCorrect(false));
        }
    }

    handleCarruerClick = (e) => {
        let value = e.target.value;
        if(value === '' || value === '1'){
            this.setState({
                invoiceInfo:{
                    ...this.state.invoiceInfo,
                    invoiceCheck: true,
                    print: 0,
                    carruerType: value === '' ? '' : parseInt(value, 10),
                    carruerNum: '',
                }
            }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
        } else if (value === '3' || value === '2'){
            this.setState({
                invoiceInfo:{
                    ...this.state.invoiceInfo,
                    invoiceCheck: false,
                    print: 0,
                    carruerType: parseInt(value, 10),
                    carruerNum: '',
                    errMessage: '請確認自然人或手機憑證條碼格式是否正確！',
                }
            }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
        } else if (value === 'print'){
            this.setState({
                invoiceInfo:{
                    ...this.state.invoiceInfo,
                    invoiceCheck: true,
                    print: 1,
                    carruerType: '',
                    carruerNum: '',
                }
            }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
        }
    }

    handleInvoiceFocus = (carruerType) =>{
        // console.log("focus");
        this.setState({
            invoiceInfo:{
                ...this.state.invoiceInfo,
                carruerType: carruerType,
                errMessage: carruerType === 2 ? '請確認自然人憑證條碼格式是否正確！' : '請確認手機憑證條碼格式是否正確！'
            }
        }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
    }

    // check citizenDigitalCertificate, vat_number, mobileBarCode, and loveCode here
    handleInvoiceChange = (element, e) =>{
        let value = e.target.value;
        if(element === 'loveCode'){
            this.setState({
                invoiceInfo:{
                    ...this.state.invoiceInfo,
                    loveCode: value
                }
            }, () => {
                this.props.handleChangeParentState(this.state.invoiceInfo)
                this.props.checkLoveCode({ loveCode: this.state.invoiceInfo.loveCode})
            })
        } else if (element === 'citizenDigitalCertificate'){
            var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
            // console.log( regexp.test(value) );
            if(regexp.test(value)){
                this.setState({
                    invoiceInfo:{
                        ...this.state.invoiceInfo,
                        // carruerType: '',
                        carruerNum: value,
                        invoiceCheck: true
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }else{
                this.setState({
                    invoiceInfo:{
                        ...this.state.invoiceInfo,
                        // carruerType: '',
                        carruerNum: value,
                        invoiceCheck: false
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }
        } else if (element === 'mobileBarCode'){
            this.setState({
                invoiceInfo:{
                    ...this.state.invoiceInfo,
                    // carruerType: '',
                    carruerNum: value
                }
            }, () => {
                this.props.handleChangeParentState(this.state.invoiceInfo);
                this.props.checkMobileBarCode({ mobileBarCode: this.state.invoiceInfo.carruerNum});
            })
        } else if (element === 'vat_number'){
            if ( value !== '' && value.length >= '8' ) {
                var id = value;
                var invalidList = "00000000,11111111";
                if (/^\d{8}$/.test(id) === false || invalidList.indexOf(id) !== -1) {
                    this.setState({
                        invoiceInfo: {
                            ...this.state.invoiceInfo,
                            [vatNumberKey]: value,
                            invoiceCheck: false
                        }
                    }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
                }

                var validateOperator = [1, 2, 1, 2, 1, 2, 4, 1],
                    sum = 0,
                    calculate = function (product) { // 個位數 + 十位數
                        var ones = product % 10,
                            tens = (product - ones) / 10;
                        return ones + tens;
                    };
                for (var i = 0; i < validateOperator.length; i++) {
                    sum += calculate(id[i] * validateOperator[i]);
                }
                if (sum % 10 === 0 || (id[6] === "7" && (sum + 1) % 10 === 0)) {
                    this.setState({
                        invoiceInfo: {
                            ...this.state.invoiceInfo,
                            [vatNumberKey]: value,
                            invoiceCheck: true,
                        }
                    }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
                } else {
                    this.setState({
                        invoiceInfo: {
                            ...this.state.invoiceInfo,
                            [vatNumberKey]: value,
                            invoiceCheck: false
                        }
                    }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
                }
            }
            else if(e.target.value !== '') {
                this.setState({
                    invoiceInfo: {
                        ...this.state.invoiceInfo,
                        [vatNumberKey]: value,
                        invoiceCheck: false
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo))
            }
            else if(e.target.value === '') {
                this.setState({
                    invoiceInfo: {
                        ...this.state.invoiceInfo,
                        [vatNumberKey]: value,
                        invoiceCheck: false
                    }
                }, () => this.props.handleChangeParentState(this.state.invoiceInfo));
            }
        }
    }

    render(){
        return(
            <Wrapper>
                <div>
                    <div className="label">發票資訊：</div>
                    <div className="field">
                        <span className="invoiceType fontSize14p">
                            <input type="radio" name="invoiceType" onClick ={this.handleInvoiceTypeClick} value="twoCopies" defaultChecked /> 二聯式發票
                        </span>
                        <span className="invoiceType fontSize14p">
                            <input type="radio" name="invoiceType" onClick ={this.handleInvoiceTypeClick} value="threeCopies"/> 三聯式發票
                        </span>
                        <span className="invoiceType fontSize14p">
                            <input type="radio" name="invoiceType" onClick ={this.handleInvoiceTypeClick} value="donation"/> 捐贈發票
                        </span>
                    </div>
                </div>

                {
                    this.state.invoiceInfo.invoiceType === 'twoCopies' &&
                    <>
                        <div className="label fontSize14p">請選擇載具類型：</div>
                        <div className="field">
                            <div className="carruerType fontSize14p" style={{marginBottom:'10px'}}>
                                <input type="radio" name="carruerType" onClick={this.handleCarruerClick} value={1} defaultChecked/> 綠界載具
                                <div style={{margin: '5px 0 0 1rem'}}>如需列印紙本發票，請來信告知。</div>
                                <a href="mailto: invoices@bonnyworldwide.com?subject=申請列印紙本發票&body=您好,%0A我要申請列印紙本發票,%0A%0A購買人資料%0A姓名：%0A電話：%0A%0A%0A謝謝。" style={{marginLeft: '1rem', position: 'relative', color: 'rgb(73, 80, 87)'}}>
                                    <i className="material-icons" style={{fontSize:'16px', lineHeight:'20px', position: 'absolute'}}>
                                        mail
                                    </i>
                                    <span style={{marginLeft: '1.2rem'}}>invoices@bonnyworldwide.com</span>
                                </a>
                            </div>
                            <div className="carruerType fontSize14p" style={{marginTop:'10px',}}>
                                <input type="radio" name="carruerType" onClick={this.handleCarruerClick} value={3}/> 共通性載具
                            </div>
                            {/* <span className="carruerType">
                                <input type="radio" name="carruerType" onClick={this.handleCarruerClick} value={'print'} /> 紙本發票
                            </span> */}
                        </div>

                        {
                            this.state.invoiceInfo.carruerType === 2 || this.state.invoiceInfo.carruerType === 3 ?
                            <>
                                {
                                    this.state.invoiceInfo.carruerType === 3 && this.state.invoiceInfo.carruerNum !== '' ?
                                    <div>
                                        <div className="label fontSize14p">自然人憑證條碼：</div>
                                        <div className="field carruerNum">
                                            <input
                                                disabled
                                                type="text"
                                                value=""
                                                placeholder="自然人憑證條碼與手機憑證條碼請擇一輸入"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="label fontSize14p">自然人憑證條碼：</div>
                                        <div className="field">
                                            <input id="citizenDigitalCertificate" name="citizenDigitalCertificate" type="text" maxLength="16" value={this.state.invoiceInfo.carruerNum || ''}
                                                placeholder="自然人憑證條碼與手機憑證條碼請擇一輸入"
                                                onChange={(e) => this.handleInvoiceChange("citizenDigitalCertificate", e)}
                                                onFocus ={ ()=> { this.handleInvoiceFocus(2) } }
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.invoiceInfo.carruerType === 2 && this.state.invoiceInfo.carruerNum !== '' ?
                                    <div>
                                        <div className="label fontSize14p">手機憑證條碼：</div>
                                        <div className="field carruerNum">
                                            <input
                                                disabled
                                                type="text"
                                                value=""
                                                placeholder="自然人憑證條碼與手機憑證條碼請擇一輸入"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="label fontSize14p">手機憑證條碼：</div>
                                        <div className="field">
                                            <input id="mobileBarCode" name="mobileBarCode" type="text" maxLength="8" value={this.state.invoiceInfo.carruerNum || ''} 
                                                placeholder="自然人憑證條碼與手機憑證條碼請擇一輸入"
                                                onChange={(e) => {
                                                    this.handleInvoiceChange("mobileBarCode", e)
                                                }}
                                                onFocus ={ ()=> { this.handleInvoiceFocus(3) } }
                                            />
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            ''
                            // 因為 || && 會全部條件都沒過
                        }
                    </>
                }

                {
                    this.state.invoiceInfo.invoiceType === 'threeCopies' &&
                    <div>
                        <div className="label fontSize14p">統一編號：</div>
                        <div className="field">
                            <input id="vat_number" name="vat_number" type="text" maxLength="8"
                            value={this.state.invoiceInfo[vatNumberKey] || ''} placeholder="請輸入正確統一編號"
                            onChange={(e) => this.handleInvoiceChange("vat_number", e)} />
                        </div>
                    </div>
                }

                {
                    this.state.invoiceInfo.invoiceType === 'donation' &&
                    <div>
                        <div className="label fontSize14p">愛心碼：</div>
                        <div className="field">
                            <input id="loveCode" name="loveCode" type="text" maxLength="7"
                                value={this.state.invoiceInfo.loveCode || ''}
                                placeholder="請輸入正確愛心碼"
                                onChange={(e) => {
                                    this.handleInvoiceChange("loveCode", e)
                                }}
                            />
                        </div>
                    </div>
                }
            </Wrapper>
        );
    }
}


const mapStateToProps = state => ({
    isLoveCodeCorrect: state.invoiceReducer.isLoveCodeCorrect,
    isMobileBarCodeCorrect: state.invoiceReducer.isMobileBarCodeCorrect,
});

const mapDispatchToProps = ({
    checkLoveCode,
    checkMobileBarCode,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceField));