import React, { Component } from "react";
import moment from "moment";
import ModalContent from "../ModalContent";
import {
    DatePicker,
    Select,
    InputButton,
    Modal,
    GaSetting,
} from "../../../component/Common";
import { getMapApiData } from '../../../actions';
import { connect } from "react-redux";
import { Wrapper, SortSelect, TopBarWrapper, BottomBarWrapper } from "./styled";
import Config from "../../../component/config.js";
// import invoke from 'react-native-webview-invoke/browser';
import UAParser from 'ua-parser-js';
import { localStorageUtility } from '../../../utilities';

const sortOptions = [
    {
        text: "排序：最新開團",
        value: "start asc"
    },
    {
        text: "排序：離我最近",
        value: "distance asc"
    },
    {
        text: "排序：空缺人數",
        value: "remain asc"
    },
    {
        text: "排序：價格由低至高",
        value: "fee asc"
    },
    {
        text: "排序：報名截止時間",
        value: "end asc"
    }
];

class SearchBar extends Component {
    constructor(props) {
        super(props);
        const { query } = props.activityData;
        this.state = {
            search: query.search || "",
            city: query.city || "",
            area: query.area || "",
            start: query.start || moment(),
            end: query.end || moment().add(3, "M"),
            remain: query.remain || "",
            interval_start: query.interval_start || 0,
            interval_end: query.interval_end || 1410,
            badminton_level: query.badminton_level || "",
            sort: query.sort || "end asc",
            address: query.address || "",
            user:'',
            latitude: query.latitude || "",
            longitude: query.longitude || "",
        };
    }

    componentDidMount() {
        // 檢查 activityLocationQuery 有沒有過期
        let activityLocationQueryExpire = localStorageUtility('get', 'activityLocationQueryExpire');
        if (!!activityLocationQueryExpire) {
            let expiresDate = Date.parse(activityLocationQueryExpire)
            let now = moment().valueOf()
            if (now > expiresDate) {
                localStorageUtility('remove', 'activityLocationQuery');
                localStorageUtility('remove', 'activityLocationQueryExpire');
            }
        }
        let activityLocationQuery = localStorageUtility('getAndParse', 'activityLocationQuery');
        if( activityLocationQuery ){
            // activityLocationQuery 條件只有縣市區域
            this.fetchNewActivityData({ ...this.state, ...activityLocationQuery })
        }else{
            var parser = new UAParser();
            let ua = navigator.userAgent
            let agent = {
                browser: parser.setUA(ua).getBrowser(),
                os: parser.setUA(ua).getOS(),
                device: parser.setUA(ua).getDevice(),
            }
            // judge if it is app
            if(
                (agent.browser && agent.browser.name.indexOf('WebView') > -1 )
                ||
                window.ReactNativeWebView
            ){
                this.webViewInvoke();
            }else{
                this.updateSearchAndFetch();
            }
        }
        // window.addEventListener('scroll', () =>
        //     console.log(document.body.scrollTop || document.documentElement.scrollTop)
        // )
        if( activityLocationQuery ){
            this.setState({
                city: activityLocationQuery.city,
                area: activityLocationQuery.area || "",
            })
        }
    }

    // changing city and area in didMount won't triger rerender 
    // because this kind of cause to trigger rerender will only happen in didUpdate
    // and didUpdate do nothing when activityLocationQuery exists except shouldAddActivity is true 
    // for other function calling fetchActivity will use the user select city and area first
    componentDidUpdate(prevProps, prevState){
        // 利用新的 props 更新 city, 
        // 更新 priority :
        // 1. user select (save to localStorage, named as activityLocationQuery )
        // 2. native  
        // 3. web browser
        // 4. default 
        // the 2, 3, and 4 doing in the update search 
        let activityLocationQuery = localStorageUtility('getAndParse', 'activityLocationQuery');
        if( !activityLocationQuery ){
            // Google map address 有變化後執行
            if(this.props.address && JSON.stringify(this.props.address) !== '{}' &&
                this.props.address !== prevProps.address){
                let cityObject =  this.props.address.find( e => {
                    return e.types[0] === "administrative_area_level_1" || 
                    e.types[0] === "administrative_area_level_2"
                })
                let city = cityObject ? cityObject.long_name : ""
                // let areaObject =  this.props.address.find( e => {
                //     return e.types[0] === "administrative_area_level_3"
                // })
                this.setState({
                    city : city,
                    // area : area
                })
            }
        }

        // city 或 緯度 有變化才會執行
        if(
            (this.state.city && prevState.city !== this.state.city)
            || this.shouldLatitudeUpdate(prevState, this.state)
        ){
            if( !activityLocationQuery){
                if(prevState.city === ''){
                    // alert("maybe it is first time to onSubmit");
                    this.props.onUpdateSearch(this.state);
                    this.fetchNewActivityData(this.state)
                }else {
                    // alert("else calling new  fetch activity");
                    this.props.onUpdateSearch(this.state);
                    this.fetchNewActivityData(this.state)
                }
            }
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    // 判斷 localStorage 有沒有 location(手機的 native 位置), 沒有就 call default
    webViewInvoke = () => {
        let location = localStorageUtility('getAndParse', 'location');
        if ( location ){
            if( this.state.native_location === undefined ){
                this.setState({
                    native_location: location
                }, () => this.updateSearchAndFetch())
            }
        }else{
            if(this.state.city === ""){
                this.setState({city: '台北市'})
                this.fetchNewActivityData({ ...this.state, city:'台北市'})
            }else{
                this.fetchNewActivityData(this.state)
            }
            this.props.onUpdateSearch(this.state);
            this.isDefaultQueryFinish = true
        }
    }

    fetchNewActivityData = (query)=>{
        this.props.setQuery(query)
        this.props.clearAndFetchActivities(query)
    }

    shouldLatitudeUpdate = (prevState, thisState)=>{
        // Latitude: 1 deg = 110.574 km
        // 1/110
        // const distance = 
        // >
        // let subtraction ＝ 1/110.574 // km

        let subtraction = 1/110.574

        if (thisState.latitude){
            // first time ?
            if(prevState.latitude === null){
                return true
            }else{
                if( Math.abs(prevState.latitude - thisState.latitude) > subtraction ){
                    return true
                }else{
                    return false
                }
            }
        }else{
            return false
        }
    }

    // 更改 state 就會進來
    updateSearchAndFetch = () => {
        if (this.state.city === '' && this.state.sort === 'end asc' && window.navigator) {
            // this.props.onUpdateSearch({ ...this.state, ...(this.coords || {}) });
            if (!!this.state.native_location && Object.keys(this.state.native_location).length !== 0) {
                // console.log('Hey girl Im here~~~~~~ native');
                let { latitude, longitude } = this.state.native_location
                delete this.state["native_location"];
                const state = { ...this.state, latitude, longitude };
                this.props.onUpdateSearch(state);
                this.fetchNewActivityData(state)
                // alert('update search mobile function')
                this.setState({
                    latitude: latitude,
                    longitude: longitude
                })
                this.props.getMapApiData({latitude: latitude,longitude: longitude})
            }else if (!this.coords) {
                // console.log('Hey girl Im here~~~~~~ browser');
                // delete this.state["native_location"];
                this.props.onUpdateSearch(this.state);
                var tryAPIGeolocation = function() {
                    fetch( `https://www.googleapis.com/geolocation/v1/geolocate?key=${Config.googleMapKey}`, 
                    { method: 'POST'})
                        .then( (success)=> {
                            // console.log(JSON.stringify(success))
                            // apiGeolocationSuccess({coords: {latitude: success.location.lat, longitude: success.location.lng}});
                        })
                        .catch( err => console.log(err))
                };
                var browserGeolocationFail = function(error) {
                    switch (error.code) {
                        case error.TIMEOUT:
                            // console.log("Browser geolocation error !\n\nTimeout.");
                            break;
                        case error.PERMISSION_DENIED:
                            if(error.message.indexOf("Only secure origins are allowed") === 0) {
                                tryAPIGeolocation();
                            }
                            break;
                        case error.POSITION_UNAVAILABLE:
                            // dirty hack for safari
                            if(error.message.indexOf("Origin does not have permission to use Geolocation service") === 0) {
                                tryAPIGeolocation();
                            } else {
                                // console.log("Browser geolocation error !\n\nPosition unavailable.");
                            }
                            break;
                        /* no default */
                    }
                };
                // give it default activity data when user doesn't click yes or no for addressing
                if(this.state.city === ""){
                    this.setState({city: '台北市'})
                    this.fetchNewActivityData({ ...this.state, city:'台北市'})
                }else{
                    this.fetchNewActivityData(this.state)
                }
                // navigator is async
                if (navigator.geolocation) {
                    // alert('will call web GPS')
                    navigator.geolocation.getCurrentPosition(pos => {
                            if (this.unmounted) {
                                return;
                            }
                            const { latitude, longitude } = pos.coords
                            this.coords = { latitude, longitude };
                            if( !latitude && !longitude ) return

                            // alert('delete state when get its value in updateSearchAndFetch web2')
                            // delete this.state["native_location"];
                            // if (this.state.sort === 'distance asc') {
                            //     const state = { ...this.state, ...this.coords };
                            //     this.props.onUpdateSearch(state);
                            // }
                            this.setState({
                                latitude: latitude,
                                longitude: longitude
                            })
                            this.props.getMapApiData({latitude: latitude,longitude: longitude})
                        },
                        browserGeolocationFail,
                        // null,
                        {
                            maximumAge        : 3000,
                            // timeout           : 2700
                        }
                    );
                }
            }else{
                // default?
                alert('Hey girl Im here~~~~~~ else');
                let activityLocationQuery = localStorageUtility('getAndParse', 'activityLocationQuery');
                if( !activityLocationQuery){
                    this.setState({
                        city: '台北市',
                    });
                }
                this.props.onUpdateSearch(this.state);
                this.fetchNewActivityData(this.state)
            }
        } else {
            // console.log('else')
            // alert('delete state when it is else ')
            // delete this.state["native_location"];
            // alert(JSON.stringify(this.state.native_location))
            // alert(JSON.parse(this.state.native_location))
            if(this.state.native_location){
                let { latitude, longitude } = this.state.native_location
                // alert('[else] delete state when get its value in updateSearchAndFetch mobile')
                // alert('[else] latitude in native is:')
                // alert(latitude)
                delete this.state["native_location"];
                const state = { ...this.state, latitude, longitude };
                this.props.onUpdateSearch(state);
                this.fetchNewActivityData(state)
                // alert('update search mobile function')
                this.setState({
                    latitude: latitude,
                    longitude: longitude
                })
                this.props.getMapApiData({latitude: latitude, longitude: longitude})
            } else {
                // maybe this is dead code
                // will work when it is from other webpage
                this.props.onUpdateSearch(this.state);
                this.fetchNewActivityData(this.state) 
            }
        }
    };

    // async waittingTheGaForUse (data) {
    //     // await console.log(data)
    //     await this.setTheGA(data);
    //     await this.goThePage(data);
    // } 

    handleInputChange = e => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const inputName = target.name;

        this.setState(
            {
                [inputName]: value
            },
            () => {
                if (inputName === "sort") {
                    this.fetchNewActivityData(this.state)
                }
            }
        );
    };
    handleInputOnBlur = e => {
        this.updateSearchAndFetch();
    };
    handleDatePickerChange = state => {
        if ('start' in state) {
            state['start'] = state['start'].startOf('day')
        }
        if ('end' in state) {
            state['end'] = state['end'].endOf('day')
        }
        this.setState(state, () => this.updateSearchAndFetch());
    };

    handleDatePickerChange_Loc = state => {
        if ('start' in state) {
            state['start'] = state['start'].startOf('day')
        }
        if ('end' in state) {
            state['end'] = state['end'].endOf('day')
        }
        this.setState(state);
    };

    handleSubmit = () => {
        const { start, end } = this.state;
        if (start.isBefore(end)) {
            // never in  ?? 
            // console.log("handle submit");
            this.fetchNewActivityData(this.state) 
        } else {
            alert("請選擇正確日期區間");
        }
    };

    render() {
        const { search, start, end, sort } = this.state;
        const { levels } = this.props;
        // console.log( this.state )
        return (
            <Wrapper>
                <TopBarWrapper>
                    {/*活動起始日*/}
                    <div className="dateSearch">
                        <label className="dataText">日期</label>
                        <GaSetting 
                            data = {{
                                category: 'activitySignUp-badminton', 
                                action: 'activitySignUp-DatePickerStartChange-click', 
                                label: '', 
                                style : {width: `100%`,}
                            }}
                        >
                            <DatePicker
                                name="start"
                                ref={this.startDate}
                                value={start}
                                onChange={this.handleDatePickerChange}
                            />
                        </GaSetting>
                    </div>
                    <div className="sortSelect">
                        <GaSetting 
                            data = {{
                                category: 'activitySignUp-badminton', 
                                action: 'activitySignUp-sortSelectClick-click', 
                                label: '', 
                                style: { flex: '1', display: 'flex', },
                            }}
                        >
                            <SortSelect>
                                <div className="select-group">
                                    {/*排列方式*/}
                                    <Select
                                        options={sortOptions}
                                        name="sort"
                                        value={sort}
                                        onChange={this.handleInputChange}
                                        // onBlur={this.handleInputOnBlur} 
                                    />
                                </div>
                            </SortSelect>
                        </GaSetting>
                    </div>
                </TopBarWrapper>
                <BottomBarWrapper>
                    <div className="selectLocation" style={{display: `flex`}}>
                        <GaSetting
                            data = {{
                                className: 'loc',
                                category: 'activitySignUp-badminton', 
                                action: 'activitySignUp-selectLocation-click', 
                                label: '', 
                                tag: 'link',
                                path: '/select_location',
                                style : {flex: `1`},
                            }}
                        >   
                            <p className="titleText">
                                <i className="material-icons">
                                    place
                                </i>
                            </p>   
                            <p className="address">
                                { (this.state.city && this.state.city.substring(0, 2)) || '台北' }
                                { (this.state.area && this.state.area.substring(0, 2)) || '' }
                            </p>
                        </GaSetting>
                        <div className="map_button">
                            <GaSetting
                                data = {{
                                    category: 'activitySignUp-badminton', 
                                    action: 'click', 
                                    label: 'activitySignUp-LBS', 
                                    tag: 'link',
                                    path: '/location_find_activity',
                                }}
                            >
                                羽球地圖
                            </GaSetting>
                        </div>
                    </div>
                    <div className="searchGroup"> 
                        {/*活動搜尋*/}
                        <div className="activitysSearch">
                            {/*<label>活動搜尋</label>*/}
                            <GaSetting 
                                data = {{
                                    category: 'activitySignUp-badminton', 
                                    action: 'activitySignUp-activitySearch-click', 
                                    label: '', 
                                    style: { display: 'flex', },
                                }}
                            >
                                <InputButton
                                    input={{
                                        placeholder: "請輸入活動名稱",
                                        name: "search",
                                        value: search,
                                        onChange: this.handleInputChange,
                                        // onBlur: this.handleInputOnBlur 
                                    }}
                                    onClick={this.handleSubmit}
                                />
                            </GaSetting>
                        </div>
                        {/*進階搜尋*/}
                        <div className="moreSearch">
                            <GaSetting
                                data = {{
                                    className: 'loc',
                                    category: 'activitySignUp-badminton', 
                                    action: 'activitySignUp-AdvancedClick-click', 
                                    label: '', 
                                }}
                            >
                                <Modal
                                    title="進階搜尋"
                                    btnClass="btn"
                                    btnText="進階搜尋"
                                    onClick={this.handleSubmit}
                                    modalContent={
                                        <ModalContent
                                            levels={levels}
                                            data={this.state}
                                            dateStartName="start"
                                            dateStartRef={this.startDate}
                                            dateStart={start}
                                            dateEndName="end"
                                            dateEndRef={this.endDate}
                                            dateEnd={end}
                                            onBlur={this.handleInputOnBlur}
                                            onChange={this.handleInputChange}
                                            dateOnChange={this.handleDatePickerChange_Loc}
                                        />
                                    }
                                />
                            </GaSetting>
                        </div>
                    </div>
                </BottomBarWrapper>
            </Wrapper>
        );
    }
}

const mapStateToProps = ({ googleMapReducer }) => ({
    address: googleMapReducer.addressActivitySignUp.addressArray
});
const mapDispatchToProps = ({
    getMapApiData: getMapApiData
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);