import styled from 'styled-components';

export const Wrapper = styled.div `
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .activity-content-wrapper {
      display: flex;
      // margin: 20px 0px 20px 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 2.2em;
    };

    p.content-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 2.2em;
    }
`
