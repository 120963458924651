import styled from 'styled-components';

export const Wrapper = styled.div ` 
    background-color: rgb(36,39,42);
    position: fixed;
    display: flex;
    align-items:center; 
    justify-content:center;
    text-align: center;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 999999;

    #error-inner { 
        margin: auto;
        flex: 1;

        .logo {
            display: block;
            width: auto;
            height: 80px;
            margin: auto auto 160px auto; 
            position: relative;

            img {
                height: 100%;
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            @media(max-width:480px and min-width:376px) {
                height: 70px;
                margin: auto auto 90px auto; 
            }
            @media(max-width:375px) {
                height: 60px;
                margin: auto auto 70px auto; 
            }
        }

        .badminton {
            position:relative;
            display: flex;
            align-items:center; 
            justify-content:center;
            animation-name: floating;
            -webkit-animation-name: floating;
            animation-duration: 3s;
            -webkit-animation-duration: 3s;
            animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;

            .bonnybadminton-ball {
                width: 250px;
                max-width: calc( 100vw - 200px );
                height: 300px;
                background-image: url('https://d2vsnoltqccfhr.cloudfront.net/image/errorPage/BonnyBadminton-ball01.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                animation-name:zoomin; 
                animation-duration:5s; 
                -webkit-animation-name:zoomin;
                -webkit-animation-duration:3s;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
            }

            .pesan-eror {
                width: 200px;
                height: 200px;
                position: relative;
                display: flex;
                align-items:center; 
                justify-content:center;
                margin: 0 auto 60px;
                background-color: #fff;
                color: #da263c;
                border-top-left-radius: 999rem;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 999rem;
                border-bottom-right-radius: 0px;
                animation-name: floating;
                -webkit-animation-name: floating;

                animation-duration: 1.5s;
                -webkit-animation-duration: 1.5s;

                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;

                h2 {
                    font-size: 100px;
                    font-weight: bold;
                }

                &::after {
                    content:"";
                    width:30px;
                    height:100%;
                    background-color: red;
                    right: 0px;
                    position:absolute;
                }
            } 
        }
        
        .text {
            margin: 40px auto 0;
            display: flex;
            align-items:center; 
            justify-content:center;
            font-size: 1.8rem;
            border-style: unset;
            color: #fff;
            outline: none;
            width: auto;
            max-width: 480px;
            height: auto;
            cursor: pointer;

            @media(max-width:480px) {
                margin: 20px auto 0;
                font-size: 1.3rem;
            }
        }

        .back-home {
            position: relative;
            margin: 20px auto 0;
            display: flex;
            align-items:center; 
            justify-content:center;
            padding: 10px 20px;
            font-size: 1.8rem;
            background: #da263c;
            border-style: unset;
            color: #fff;
            border-radius: 999rem;
            outline: none;
            width: auto;
            max-width: 480px;
            height: auto;
            cursor: pointer;

            i.material-icons {
                font-size: 1.5rem;
            }

            @media(max-width:480px) {
                margin: 10px auto 0;
                font-size: 1.3rem;
                padding: 5px 0px;
                width: calc( 100vw - 40px );

                i.material-icons {
                    font-size: 1rem;
                }
            }

            &::after,
            &::before {
                position: absolute;
                background: #2f82bf;
                content: "";
                width: 0%;
                height: 100%;
                bottom: 0px;
                left: 0;
                z-index: -999999999;
                border-radius: 999rem;
            }

            &::before {
                background: #da5162;
                width: 100%;
            }

            &:hover {
                background: none;
            }

            &:hover::after {
                width: 100%;
                -webkit-transition: all 1s ease-in-out;
                -moz-transition: all 1s ease-in-out;
                transition: all 1s ease-in-out;
            }

            a {
                color: white;
                text-decoration: none
            }
        }
    }

    @keyframes floating {
        0% {
            transform: translateY(0%) rotate(25deg);
        }
        50% {
            transform: translateY(-45%) rotate(75deg);
        }
        100% {
            transform: translateY(5%) rotate(0deg);
        }
    }

    @-webkit-keyframes floating {
        0% {
            -webkit-transform: translateY(0%) rotate(25deg);
        }
        50% {
            -webkit-transform: translateY(-45%) rotate(75deg);
        }
        100% {
            -webkit-transform: translateY(5%) rotate(0deg);
        }
    }

    @keyframes zoomin {
        0% {
            transform:scale(0.2,0.2); 
            opacity: 20; 
            filter:alpha(opacity=20);
        }
        10% {
            transform:scale(0.5,0.5); 
            opacity: 0; 
            filter:alpha(opacity=0);
        }
        95% {
            transform:scale(1.3,1.3); 
            opacity: 1; 
            filter:alpha(opacity=100);
        }
        100% {
            transform:scale(1.3,1.3); 
            opacity: 0.8;
            filter:alpha(opacity=80);
        }
    }
    @-webkit-keyframes zoomin {  /* Safari and Chrome */
        0% {
             -webkit-transform:scale(0.2,0.2); 
            opacity: 20; 
            filter:alpha(opacity=20);
        }
        10% {
             -webkit-transform:scale(0.5,0.5); 
            opacity: 0; 
            filter:alpha(opacity=0);
        }
        95% {
             -webkit-transform:scale(1.3,1.3); 
            opacity: 1; 
            filter:alpha(opacity=100);
        }
        100% {
             -webkit-transform:scale(1.3,1.3); 
            opacity: 0.8;
            filter:alpha(opacity=80);
        }
    }


`