import styled from 'styled-components';

export const Wrapper = styled.div`
 	width: 100%;
 	display: flex;
	justify-content:flex-start;
	align-items:center;
	align-content:space-between;
	flex-wrap:wrap;

 	label {
 		width: calc( ( 100% - ( 24px * 3 ) ) / 3 );
 		border: 1px solid #D8D8D8;
	    padding: 2px 8px;
	    margin: 5px 3px;
	    border-radius: 999rem;
	    background-color: rgba(216,216,216,.6);
	    text-align: center;
	    color: #333;

	    @media (max-width: 480px) {
	    	font-size: 12px;
	    }
 	}
`;
