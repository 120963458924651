import React, { Component } from "react";
import { Wrapper } from './styled';
import LandingPageLayout from '../LandingPageLayout';
import Contract from '../../Contract';
import { GaSetting } from "../../../../Common";

class LandingPageB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIntro: false,
            landingPageCode: this.props.landingPageCode,
            navDStyle: 'show',
        }
    }

    render() {
        return (
            <Wrapper key="1">
                <GaSetting
                    data = {{
                        name: 'componentDidMount', 
                        category: '4D', 
                        action: '4D_PV_isTypeB', 
                        label: '',
                    }}
                />
                <LandingPageLayout version='B' landingPageCode={this.state.landingPageCode}/>
                <div id="NAV" 
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        let content = document.getElementsByClassName('contract')[0]
                        if (content.style.display === '' || content.style.display === 'none') {
                            content.style.display = 'block'
                        }else{
                            content.style.display = 'none'
                        }
                        this.state.navDStyle ? this.setState({ navDStyle : ''}) : this.setState({ navDStyle : 'show'}) 
                    }}
                >
                    <GaSetting 
                        data = {{
                            category: '4D', 
                            action: 'Click', 
                            label: '4D_OpenTheContract_isTypeB', 
                            tag: 'div',
                            style: {cursor: 'pointer'}
                        }}
                    >
                        <img className={['nav_D', this.state.navDStyle].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_D.jpg" width="100%" alt="" />
                    </GaSetting>
                    <GaSetting 
                        data = {{
                            category: '4D', 
                            action: 'Click', 
                            label: '4D_CloseTheContract_TopButton_isTypeB', 
                            tag: 'div',
                            style: {cursor: 'pointer'}
                        }}
                    >
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_D_2.jpg" width="100%" alt="" />
                    </GaSetting>  
                </div>

                <div className="contract">
                    <Contract 
                        version='B'
                    />
                    <div 
                        style={{backgroundColor: '#ac8967',paddingBottom: `60px` }} 
                        onClick={() => {
                            let content = document.getElementsByClassName('contract')[0]
                            if (content.style.display === 'block') {
                                content.style.display = 'none'
                            }
                            this.setState({navDStyle: 'show'})
                        }}
                    >
                        <GaSetting 
                            data = {{
                                category: '4D', 
                                action: 'Click', 
                                label: '4D_CloseTheContract_BottomButton_isTypeB', 
                                tag: 'div',
                                style: {cursor: 'pointer'}
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/btn_close.png" width="100%" alt="" />
                        </GaSetting>
                    </div>
                </div>
            </Wrapper>
        );

    }
}

export default LandingPageB
