import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  flex: 0 0 3rem;

  i {
  	color: #da263c;
  }
  .text {
  	width: 3rem;
  }
  img {
    width: 20px;
    height: 20px;
  }
`;
