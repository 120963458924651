import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
// import { GaSetting } from "../../Common";
import LandingPageA from './LandingPageA';
import LandingPageB from './LandingPageB';
import { getCookie, setCookie } from '../../../../utilities';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        var landingPageCode = getCookie('LandingPage');
        this.state = {
            landingPageCode: landingPageCode,
        };
    }

    componentDidMount() {
        if(this.state.landingPageCode){
        }else{
            let randomType = Math.floor(( (Math.random()*100) +2))%2
            if(getCookie('LandingPage')){
            }else{
                // 單雙數判斷
                if( randomType === 0 ){
                    this.setState({landingPageCode: 'A'})
                    setCookie( 'LandingPage', 'A', 30)
                } 
                if( randomType === 1 ){
                    this.setState({landingPageCode: 'B'})
                    setCookie( 'LandingPage', 'B', 30)
                }
            }
        }
    }

    lightBox(e){
        var deal = document.getElementById("ContractLightBox");
        deal.classList.remove("close");
    }

    render() {
        return (
            <div>
                {
                    this.state.landingPageCode === 'A' ?
                        <LandingPageA landingPageCode={this.state.landingPageCode}/>
                    :
                    null
                }
                {
                    this.state.landingPageCode === 'B' ?
                        <LandingPageB landingPageCode={this.state.landingPageCode}/>
                    :
                    null
                }
            </div>
        );
        
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage));
