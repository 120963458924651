import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';
import moment from 'moment';
import { fetchAllNotification } from "../../actions";
// component is below
import Info from './Info';
import Detail from './Detail';
import ActivityDetail from './ActivityDetail';
import SignUpDetail from './SignUpDetail';
import HostActivity from './HostActivity';
import JoinActivity from './JoinActivity';
import Notice from './Notice';
import Messages from './Message/All';
import MessageDetail from './Message/Detail';
import Follow from './Follow';
import FollowDetail from './Follow/Detail';
import Myrent from './Myrent';
import RacketDetail from './RacketDetail';
import NoticeDetail from './NoticeDetail';
import ReplaceRacket from './ReplaceRacket';
import CancelProgram from './CancelProgram';
import Affiliates from "./Affiliates";
import ErrorPage from "../ErrorPage";
import { Modal } from "../../component/Common/";
// component is above
import { Wrapper, NoticeWrapper } from './styled';
import { getCookie, localStorageUtility } from '../../utilities';


class Myinfo extends Component {
    constructor(props) {
        super(props)
        const { query } = props.activityData;
        this.state = {
            search: query.search || "",
            user: localStorageUtility('getAndParse', 'myData'),
            sort: '',
            status_end: '',
            isFirstComeMyInfo: !getCookie('myinfo') ? true : false,
            userInfo: localStorageUtility('getAndParse', 'myData'),
        }
        if(this.state.userInfo.token === undefined){
            localStorageUtility('remove', 'myData');
            this.props.history.replace("/login");
        }
    }

    componentDidMount() {
        if (this.state.userInfo.token !== undefined){
            this.props.fetchAllNotification({ 'sort': 'start desc', 'status_end': '<' });
        }
    }

    renderActivityNotPaid(data, i) {
        if (data.activity.fee > 0) {
            return (
                <NoticeWrapper 
                    key={`ActivityNotPaid` + i} 
                    onClick={()=>{ 
                        this.props.history.push(`/my_info/host_activity_detail/${data.activity.id}`);
                    }} 
                >
                    <div className="leftArea">
                        <div className="data">
                            {moment(parseInt(data.activity.start)).format('YYYY年MM月DD日')}
                        </div>
                        <div className="groupName">
                            ・{data.activity.group_name}
                        </div>
                    </div>
                    <button 
                        key={`Link` + i} 
                        className="rightArea" 
                    >  
                        前往
                    </button>
                </NoticeWrapper>
            )
        }
    }

    renderJoinedNotPaid(data, i) {
        if (!data.paid) {
            return (
                <NoticeWrapper 
                    key={`JoinedNotPaid` + i}
                    onClick={()=>{ 
                        this.props.history.push(`/my_info/SignUpDe_dail/${data.activity.id}/${data.id}`);
                    }} 
                >
                    <div className="leftArea">
                        <div className="data">
                            {moment(parseInt(data.activity.start)).format('YYYY年MM月DD日')}
                        </div>
                        <div className="groupName">
                            ・{data.activity.group_name}
                        </div>
                    </div>
                    <button 
                        key={`Link` + i} 
                        className="rightArea" 
                    >  
                        前往
                    </button>
                </NoticeWrapper>
            )
        }
    }

    rendeContinuousActivity(data, i) {
        if (moment().valueOf() >= data.end) {
            return (
                <NoticeWrapper 
                    key={`ContinuousActivity` + i}
                    onClick={()=>{ 
                        this.props.history.push(`/my_info/host_activity_detail/${data.id}`);
                    }} 
                >
                    <div className="leftArea">
                        <div className="data">
                            {data.frequency.type === "week" ? `每週開團` : `每月開團`}
                        </div>
                        <div className="groupName">
                            ・{data.group_name}
                        </div>
                    </div>
                    <button 
                        key={`Link` + i} 
                        className="rightArea"
                    >  
                        前往
                    </button>
                </NoticeWrapper>
            )
        }
    }

    setTheFirstComeMyInfo = () => {
        document.cookie = `myinfo=${moment().valueOf()}`;
    }

    render() {
        const { match, activityData, activityType } = this.props;
        const isMyInfo = this.props.location.pathname === "/my_info";
        var isPaid = Array.isArray(activityType) && 
            ((activityData.allNotificationActivity && activityData.allNotificationActivity.length > 0) || activityType.length > 0);
        var userContinuousActivityLength = activityData.userContinuousActivity && activityData.userContinuousActivity.length;
        return (
            <Wrapper key="1">
                {
                    this.state.userInfo.token ?
                    <div>
                        {
                            this.state.isFirstComeMyInfo ?
                                (isPaid) || (userContinuousActivityLength > 0) ?
                                (
                                    isMyInfo ?  
                                        <Modal
                                            title="貼心提醒"
                                            modalIsOpen={true}
                                            btnTheme={"one"}
                                            text2="確認"
                                            noButton={true}
                                            callFunction={this.setTheFirstComeMyInfo()}
                                            modalContent={
                                                [
                                                    activityData.allNotificationActivity.length !== 0 
                                                    &&
                                                        <div key='0' >
                                                            <h3>近期內您尚有活動<span style={{fontWeight: `bold`, color: `#da263c`,}}>未收款</span>：</h3>
                                                            {activityData.allNotificationActivity && activityData.allNotificationActivity.map(
                                                                (data, i) => 
                                                                    this.renderActivityNotPaid(data, i)
                                                            )}
                                                        </div>
                                                    ,
                                                    activityData.activityType.length !== 0 
                                                    &&
                                                        <div key='1' >
                                                            <br />
                                                            <h3>近期內您尚有活動<span style={{fontWeight: `bold`, color: `#da263c`,}}>未付款</span>：</h3>
                                                            {activityData.activityType && Array.isArray(activityData.activityType) && activityData.activityType.map(
                                                                (data, i) => 
                                                                    this.renderJoinedNotPaid(data, i)
                                                            )}
                                                        </div>
                                                    ,
                                                    <div key='2' >
                                                        <br />
                                                        <h3>挑戰羽球不停歇，再次揪團齊挑戰！</h3>
                                                        {Array.isArray(activityData.userContinuousActivity) && activityData.userContinuousActivity.map(
                                                            (data, i) => 
                                                                this.rendeContinuousActivity(data, i)
                                                        )}
                                                    </div>
                                                ]
                                            }
                                        />
                                    :
                                    null
                                )
                                : 
                                null
                            :
                            null
                        }
                        <Switch>
                            <Route exact path={match.url} component={Info} />
                            {/*我的開團 - 活動內頁*/}
                            <Route
                                path="/my_info/host_activity_detail/:id"
                                component={ActivityDetail}
                            />
                            {/*分銷商專區*/}
                            <Route path="/my_info/social_selling_dashboard" component={Affiliates} />
                            {/*個人資料*/}
                            <Route path="/my_info/profile" component={Detail} />
                            {/*我的通知*/}
                            <Route path="/my_info/notification_list" component={Notice} /> 
                            {/*聊天內容*/}
                            <Route path="/my_info/chat_room_list" component={Messages} />
                            {/*聊天內容 - 聊天頁*/}
                            <Route path="/my_info/chatroom/:id" component={MessageDetail} />
                            {/*我的追蹤*/}
                            <Route path="/my_info/follower_list" component={Follow} />
                            {/*我的追蹤 - 追蹤者開團資訊*/}
                            <Route path="/my_info/follower_host_activities/:id" component={FollowDetail} /> 
                            {/*我的報隊 - 活動內頁*/}
                            <Route
                                path="/my_info/participated_activity_detail/:id/:joinId"
                                component={SignUpDetail}
                            /> 
                            {/*我的開團*/}
                            <Route path="/my_info/host_activities" component={HostActivity} /> 
                            {/*我的報隊*/}
                            <Route path="/my_info/participated_activities" component={JoinActivity} />
                            {/*羽你蓮心專案*/}
                            <Route path="/my_info/rent_contract_list" component={Myrent} /> 
                            {/*羽你蓮心專案 - 個別專案內頁*/}
                            <Route path="/my_info/rent_contract_detail/:id" component={RacketDetail} /> 
                            {/*我的通知 - 內頁*/}
                            <Route path="/my_info/notification_detail/:id" component={NoticeDetail} />
                            {/*羽你蓮心換拍申請*/}
                            <Route path="/my_info/replace_racket/:id" component={ReplaceRacket} /> 
                            {/*羽你蓮心取消專案*/}
                            <Route path="/my_info/cancel_rent_contract/:id" component={CancelProgram} />
                            {/*ErrorPage*/}
                            <Route component={ErrorPage} />
                        </Switch>
                    </div>
                    :
                    ""
                }
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    activityData: state.activity,
    activityType: state.activity.activityType,
})

const mapDispatchToProps = {
    fetchAllNotification: fetchAllNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Myinfo);
