import styled from 'styled-components';

export const Wrapper = styled.div`
    .city,.area{
        width: 22%;
        display:inline-block;
        vertical-align: bottom;
        margin-right: calc( 3% - 2px );
    }

    .address{
        width: 50% !important;
        display:inline-block;
        vertical-align: bottom;
        padding: 0;
    }
`