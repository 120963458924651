import React, { Component } from "react";
import { Wrapper } from './styled';
import Autosuggest from 'react-autosuggest';
//import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

class AutoSuggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      suggestions: props.suggestions
    }
  }

  static getDerivedStateFromProps(props, state) {
    return { suggestions: props.suggestions, value: props.value || state.value }
  }

  customMatch = (text, query) => {
    const results = [];
    const trimmedQuery = query.trim().toLowerCase();
    const textLower = text.toLowerCase();
    const queryLength = trimmedQuery.length;
    let indexOf = textLower.indexOf(trimmedQuery);
    while (indexOf > -1) {
      results.push([indexOf, indexOf + queryLength]);
      indexOf = textLower.indexOf(query, indexOf + queryLength);
    }
    return results;
  }

  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = this.props.renderSuggestion? this.props.renderSuggestion(suggestion): `${suggestion.name}, ${suggestion.email}`
    const matches = this.customMatch(suggestionText, query);
    //const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    let imageURL = ""
    if ('avatar' in suggestion) {
      imageURL = (suggestion.avatar === "")? '/assets/images/DefaultUserImg.png': `${suggestion.avatar}`
    }
    return (
      <div className="suggestion-wrapper">
        { imageURL !== "" && <img className="suggestion-image" src={imageURL} alt="" /> }
        <span>
          {parts.map((part, index) => {
            const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </div>
    );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.props.onSuggestionsFetchRequested) this.props.onSuggestionsFetchRequested(value)
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    if (this.props.onSuggestionSelected) this.props.onSuggestionSelected({ suggestion, suggestionValue, suggestionIndex, sectionIndex, method })
  }

  getSuggestionValue = (suggestion) => {
    if (this.props.getSuggestionValue) return this.props.getSuggestionValue(suggestion);
    return `${suggestion.name}, ${suggestion.email}`
  };

  onChange = (event, { newValue, method }) => {
    if (method === "type") {
      if (this.props.inputProps && this.props.inputProps.onChange) {
        this.props.inputProps.onChange(event, {newValue, method})
      }
    }
    this.setState({
      value: newValue
    });
  };

  render() {
    const { 
      inputProps, 
      suggestions, 
    } = this.props;

    return (
      <Wrapper>
      <Autosuggest 
        inputProps={Object.assign({}, inputProps, {value: this.state.value, onChange: this.onChange})} 
        suggestions={suggestions} 
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
      />
    </Wrapper>
    );
  }
}

AutoSuggest.defaultProps = {
  suggestions: [],
  onSuggestionSelected: null,
  getSuggestionValue: null,
  renderSuggestion: null,
  inputProps: {
    placeholder: "",
    name: "",
    onChange: null,
  },
  value: "",
  onClick: null
};

export default AutoSuggest;
