import styled from "styled-components"

export const Wrapper = styled.div`
    width:100%;
    position:relative;
    margin-bottom: 40px;
    
    select{
        width:100%
    };
    form.main-form{
        .title {
            text-align: center;
            width:100%
            border: 0 none
            font-size: 30px;
            font-weight: 500;
            color: #000000
            border-radius: 5px
        }   
        .form-control{
            input[type="text"]{
                padding: 0;
                padding-left: 1em;
                width: calc(100% - 1em);
                margin-top: 20px;
            }
            input[type="password"]{
                padding: 0;
                padding-left: 1em;
                width: calc(100% - 1em);
                margin-bottom: 20px;
            }
        }
        button{
            font-size: 20px;
            padding: 10px 0;
            border-radius: 5px;
            margin-bottom: 10px;
        }
        a{
            color: #fff;
        }
        a.Forget{
            color: #000;
        }   
    }
    .Thirdtext {
        text-align: center;
        width:100%
        font-size: 25px;
        font-weight:bold;
        color: #000000
    }
`
