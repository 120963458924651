import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Select } from '../../../Common';
import { Wrapper, SortSelect } from './styled';
import { localStorageUtility } from '../../../../utilities';

const sortOptions = [
    {
        text: '日期由新至舊',
        value: 'start desc'
    },
    {
        text: '剩餘人數由多到少',
        value: 'remain desc'
    },
    {
        text: '剩餘人數由少到多',
        value: 'remain asc'
    }
];

class TAB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: "start desc",
        };
    }

    handleSortChange = e => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const inputName = target.name;
        let Tab = localStorageUtility('get', 'Tab');

        if(Tab === 'Cancel'){
            delete this.state.status_end;
            this.setState(
                {
                    [inputName]: value,
                    status: 0,
                },
                () => {
                    if (inputName === "sort") {
                        // console.log(inputName,this.state);
                        this.props.onFetchJoin(this.state);
                    }
                }
            );
        }else{
            this.setState(
                {
                    [inputName]: value,
                    status_end: (Tab === 'Success' ? '>': '<'),
                    status: 1,
                },
                () => {
                    if (inputName === "sort") {
                        // console.log(inputName,this.state);
                        this.props.onFetchJoin(this.state);
                    }
                }
            );
        }
    };

    handleInputOnBlur = e => {
        this.props.onUpdateSearch(this.state);
    };

    handleClickCancel = e => {
        localStorageUtility('set', 'Tab', 'Cancel'); 
        this.props.onFetchJoin({ 'sort': 'start desc','status': '0'});
        this.setState({
            'sort': 'start desc'
        })
        // console.log('handleClickCancel', this.props);

        //alert('Finished');
    };

    handleComClick = e => {    
        localStorageUtility('set', 'Tab', 'Finished'); 
        this.props.onFetchJoin({ 'sort': 'start desc','status_end': '<','status': '1'});
        this.setState({
            'sort': 'start desc'
        })
        //alert('Finished');
    };

    handleClick = e => {
        localStorageUtility('set', 'Tab', 'Success');  
        this.props.onFetchJoin({ 'sort': 'start desc','status_end': '>','status': '1'});
        this.setState({
            'sort': 'start desc'
        })
        //alert('Success');
    };
    
    render() {
        // console.log(this.props)

        const isNotPaid = this.props.isNotPaid;
        const isPaidDone = this.props.isPaidDone;
        const { sort } = this.state;
        return (
            <Wrapper >
            <Tabs>
                <TabList>
                    <Tab onClick={this.handleClick}>已報名</Tab>
                    <Tab onClick={this.handleComClick}>已完成</Tab>
                    <Tab onClick={this.handleClickCancel}>已取消</Tab>
                </TabList>
                <TabPanel>
                    <SortSelect>
                        <div className="selectDiv">
                            <div className="choosePaidIsOrNot">
                                <div id="chooseNotPaidLabel" className={isNotPaid}>
                                    <label className="notPaid" htmlFor="chooseNotPaid">未付款</label>
                                </div>
                                <div id="chooseIsPaidLabel" className={isPaidDone}>
                                    <label className="isPaid" htmlFor="chooseIsPaid">已付款</label>
                                </div>
                            </div>
                            
                            <div className="select-group">
                                <span>排序</span>
                                <Select
                                    options={sortOptions}
                                    name="sort"
                                    value={sort}
                                    onChange={this.handleSortChange}
                                    onBlur={this.handleInputOnBlur}
                                />
                            </div>
                        </div>
                    </SortSelect>
                </TabPanel>
                <TabPanel>
                    <SortSelect>
                        <div className="selectDiv">
                            <div className="choosePaidIsOrNot">
                                <div id="chooseNotPaidLabel" className={isNotPaid}>
                                    <label className="notPaid" htmlFor="chooseNotPaid">未付款</label>
                                </div>
                                <div id="chooseIsPaidLabel" className={isPaidDone}>
                                    <label className="isPaid" htmlFor="chooseIsPaid">已付款</label>
                                </div>
                            </div>
                            
                            <div className="select-group">
                                <span>排序</span>
                                <Select
                                    options={sortOptions}
                                    name="sort"
                                    value={sort}
                                    onChange={this.handleSortChange}
                                    onBlur={this.handleInputOnBlur}
                                />
                            </div>
                        </div>
                    </SortSelect>
                </TabPanel>
                <TabPanel>
                    <SortSelect>
                        <div className="selectDiv">
                            <div className="choosePaidIsOrNot">
                            </div>
                            
                            <div className="select-group">
                                <span>排序</span>
                                <Select
                                    options={sortOptions}
                                    name="sort"
                                    value={sort}
                                    onChange={this.handleSortChange}
                                    onBlur={this.handleInputOnBlur}
                                />
                            </div>
                        </div>
                    </SortSelect>
                </TabPanel>
            </Tabs>
            </Wrapper>
        )
    }
}

export default TAB;

