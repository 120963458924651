import styled from 'styled-components';

export const Wrapper = styled.div `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;

    input {
        background:#000;
    }

    .ckctext{
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        padding-left: 2rem;
    }


    .ckbox{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #000000;  
    }

    .ckcmodalbox{
        text-decoration: underline;
        background-color: #fff;
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        border: none;  
    }

    @media (max-width: 480px) {
        padding: 10px 10px 10px 25px;

        .ckctext{
            font-size: 16px;
        }

        .ckbox{
            font-size: 16px;    
        }

        .ckcmodalbox{
            padding: 0;
            font-size: 16px;
        }
    }
`
