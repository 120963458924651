import styled from 'styled-components';

export const Wrapper = styled.div `
width: 100%;
display: flex;
justify-content: center;
align-items: center;

input {
    background:#000;
    height: 25px;
    width: 25px;
}

.ckctext{
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.ckbox{
    width: 25px;
    display: flex;
    text-align: center;
    position:relative; 
    font-size: 18px;
    font-weight: bold;
}

.ckcmodalbox{
    position: relative; 
    text-decoration: underline;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    border: none;   
}

`