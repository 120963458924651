// import moment from 'moment';
import { createAction } from 'redux-actions';
import { fetchObject, VALIDATE_ERROR_CODE } from './fetchModule';
import { history } from '../index';
import {localStorageUtility } from '../utilities';

export const fetchNotification = createAction("FETCH_NOTIFICATION_DATA");
export const fetchNotificationAboutRent = createAction("FETCH_NOTIFICATION_ABOUT_RENT");
export const updatedNotificationStatus = createAction("UPDATE_NOTIFICATION_STATUS");
export const getNotificationUnreadCount = createAction("GET_NOTIFICATION_UNREAD_COUNT");
export const shouldLoadingOpen = createAction("SHOULD_LOADING_OPEN");

export const getNotification = (defaultQuery = {}) => {
    return (dispatch, getState) => {
        fetchObject(`notification/list`)
            .then(function(response) {
                if (response.ok) return response.json();
                else
                    response.json().then(function(err) {
                        ////throw new Error(err);
                        if(err.code === 'TOKEN_INVALID'){
                            localStorageUtility('remove', 'myData');
                            // window.location.href = "/Login";
                            history.replace('/login');
                        }
                    });
            })
            .then(function(json) {
                const status = {
                    reload: false
                }

                if(json && json.data)
                {
                   dispatch(fetchNotification(json.data)); 
                }
                dispatch(updatedNotificationStatus(status));
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
                // alert(VALIDATE_ERROR_CODE(ex.code));
            });
    };
};

export const notificationCountListener = () => {
    return dispatch => {
        if (window.io !== undefined) {
            window.io.socket.on('allNotification', event =>{
                dispatch(getNotificationUnreadCount(event));
            });
        }

    }
}

export const stopNotificationCountListener = () => {
    return dispatch => {
        window.io.socket.off('allNotification', event =>{
        });
    }
}

export const getRentContentNotification = ( id ) => {
    return (dispatch, getState) => {
        fetchObject(`notification/${id}`)
            .then(function(response) {
                if (response.ok) return response.json();
                else
                    response.json().then(function(err) {
                        //throw new Error(err);
                    });
            })
            .then(function(json) {
                dispatch(fetchNotificationAboutRent(json.data));
                dispatch(shouldLoadingOpen(false));
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
                alert(VALIDATE_ERROR_CODE(ex.code));
            });
    };
};

export const setNotificationStatus = ({ id, url }) => {
    return (dispatch, getState) => {
        let apiUrl = `notification/read/${id}`;
        fetchObject(apiUrl, '', {
            method: 'post'
        })
            .then(function(response) {
                if (response.ok) {
                    const status = {
                        reload: true,
                        url
                    }
                    dispatch(updatedNotificationStatus(status));
                }
                else {
                    response.json().then(function(err) {
                        //throw new Error(err);
                    });
                }
            })
            .catch(function(ex) {
                alert(VALIDATE_ERROR_CODE(ex.code));
            });
    };
};
