import styled from 'styled-components';

export const Wrapper = styled.div `
    position: relative;
    width: 100%;
    height: 100%;

    // transform css
    @-moz-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
    }
    @-webkit-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
    }
    @-o-keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
    }
    @keyframes pulsate {
        0% {
          -webkit-transform: scale(0.1, 0.1);
          -moz-transform: scale(0.1, 0.1);
          -o-transform: scale(0.1, 0.1);
          -ms-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
        50% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        100% {
          -webkit-transform: scale(1.2, 1.2);
          -moz-transform: scale(1.2, 1.2);
          -o-transform: scale(1.2, 1.2);
          -ms-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
        }
    }
    @-moz-keyframes bounce {
        0% {
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          -webkit-transform: translateY(-2000px) rotate(-45deg);
          -moz-transform: translateY(-2000px) rotate(-45deg);
          -o-transform: translateY(-2000px) rotate(-45deg);
          -ms-transform: translateY(-2000px) rotate(-45deg);
          transform: translateY(-2000px) rotate(-45deg);
        }
        60% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
          -webkit-transform: translateY(30px) rotate(-45deg);
          -moz-transform: translateY(30px) rotate(-45deg);
          -o-transform: translateY(30px) rotate(-45deg);
          -ms-transform: translateY(30px) rotate(-45deg);
          transform: translateY(30px) rotate(-45deg);
        }
        80% {
          -webkit-transform: translateY(-10px) rotate(-45deg);
          -moz-transform: translateY(-10px) rotate(-45deg);
          -o-transform: translateY(-10px) rotate(-45deg);
          -ms-transform: translateY(-10px) rotate(-45deg);
          transform: translateY(-10px) rotate(-45deg);
        }
        100% {
          -webkit-transform: translateY(0) rotate(-45deg);
          -moz-transform: translateY(0) rotate(-45deg);
          -o-transform: translateY(0) rotate(-45deg);
          -ms-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
        }
    }
    @-webkit-keyframes bounce {
        0% {
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          -webkit-transform: translateY(-2000px) rotate(-45deg);
          -moz-transform: translateY(-2000px) rotate(-45deg);
          -o-transform: translateY(-2000px) rotate(-45deg);
          -ms-transform: translateY(-2000px) rotate(-45deg);
          transform: translateY(-2000px) rotate(-45deg);
        }
        60% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
          -webkit-transform: translateY(30px) rotate(-45deg);
          -moz-transform: translateY(30px) rotate(-45deg);
          -o-transform: translateY(30px) rotate(-45deg);
          -ms-transform: translateY(30px) rotate(-45deg);
          transform: translateY(30px) rotate(-45deg);
        }
        80% {
          -webkit-transform: translateY(-10px) rotate(-45deg);
          -moz-transform: translateY(-10px) rotate(-45deg);
          -o-transform: translateY(-10px) rotate(-45deg);
          -ms-transform: translateY(-10px) rotate(-45deg);
          transform: translateY(-10px) rotate(-45deg);
        }
        100% {
          -webkit-transform: translateY(0) rotate(-45deg);
          -moz-transform: translateY(0) rotate(-45deg);
          -o-transform: translateY(0) rotate(-45deg);
          -ms-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
        }
    }
    @-o-keyframes bounce {
        0% {
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          -webkit-transform: translateY(-2000px) rotate(-45deg);
          -moz-transform: translateY(-2000px) rotate(-45deg);
          -o-transform: translateY(-2000px) rotate(-45deg);
          -ms-transform: translateY(-2000px) rotate(-45deg);
          transform: translateY(-2000px) rotate(-45deg);
        }
        60% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
          -webkit-transform: translateY(30px) rotate(-45deg);
          -moz-transform: translateY(30px) rotate(-45deg);
          -o-transform: translateY(30px) rotate(-45deg);
          -ms-transform: translateY(30px) rotate(-45deg);
          transform: translateY(30px) rotate(-45deg);
        }
        80% {
          -webkit-transform: translateY(-10px) rotate(-45deg);
          -moz-transform: translateY(-10px) rotate(-45deg);
          -o-transform: translateY(-10px) rotate(-45deg);
          -ms-transform: translateY(-10px) rotate(-45deg);
          transform: translateY(-10px) rotate(-45deg);
        }
        100% {
          -webkit-transform: translateY(0) rotate(-45deg);
          -moz-transform: translateY(0) rotate(-45deg);
          -o-transform: translateY(0) rotate(-45deg);
          -ms-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
        }
    }
    @keyframes bounce {
        0% {
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          -webkit-transform: translateY(-2000px) rotate(-45deg);
          -moz-transform: translateY(-2000px) rotate(-45deg);
          -o-transform: translateY(-2000px) rotate(-45deg);
          -ms-transform: translateY(-2000px) rotate(-45deg);
          transform: translateY(-2000px) rotate(-45deg);
        }
        60% {
          opacity: 1;
          -ms-filter: none;
          filter: none;
          -webkit-transform: translateY(30px) rotate(-45deg);
          -moz-transform: translateY(30px) rotate(-45deg);
          -o-transform: translateY(30px) rotate(-45deg);
          -ms-transform: translateY(30px) rotate(-45deg);
          transform: translateY(30px) rotate(-45deg);
        }
        80% {
          -webkit-transform: translateY(-10px) rotate(-45deg);
          -moz-transform: translateY(-10px) rotate(-45deg);
          -o-transform: translateY(-10px) rotate(-45deg);
          -ms-transform: translateY(-10px) rotate(-45deg);
          transform: translateY(-10px) rotate(-45deg);
        }
        100% {
          -webkit-transform: translateY(0) rotate(-45deg);
          -moz-transform: translateY(0) rotate(-45deg);
          -o-transform: translateY(0) rotate(-45deg);
          -ms-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
        }
    }
`

export const SearchBar = styled.div `
    width: 100%;
    height: 45px;
    padding-top: 10px;
    padding-bottom: 5px; 
    background-color: #f2f2f2;
    display: flex;
    align-items: center; 
    justify-content: center;

    .input-button {
        display: flex;
        align-items: center; 
        justify-content: center;
        margin: auto;
        flex: 0 0 90% !important;

        input[type="text"] {
            flex: 1;
        }
        button {
            flex: 0 0 20%;
        }
    }

    .autocomplete {
      width: 90%;
      display: flex;
      align-items: center; 
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;

      input[type="text"] {
        flex: 1;
      }
      .autocomplete-dropdown-container {
        position: absolute;
        z-index: 1000;
        top: 37px;
        left: 0;
        width: 100%;
        display: flex;
        align-items: start-flex; 
        justify-content: start-flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .suggestion-item , .suggestion-item--active {
          padding: 0 1rem;
          line-height: 2rem;
          flex: 0 0 100%;
          display: flex;

          span {
            flex: 0 0 100%;
          }
        }
      }
    }
`

export const GoogleMapWrapper = styled.div `
    width: 100%;
    height: calc( 100% - 60px );
    position: relative;
    //$ {children => (console.log(children))}

    .backList {
      position: absolute;
  
      display: flex;
      align-items: center; 
      justify-content: center;
      top: 0px;
      left: 0px;
      z-index: 999;

      background-color: rgba(218, 38, 60, .9);
      border: 0px;
      margin: 10px;
      padding: 10px;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

      p {
        font-size: .8rem;
        color: #fff;
      }
    }

    .gpsFixed {
      position: absolute;

      display: flex;
      align-items: center; 
      justify-content: center;
      top: 50px;
      right: 0px
      z-index: 999;

      background: none rgb(255, 255, 255);
      border: 0px;
      margin: 10px;
      padding: 0px;
      cursor: pointer;
      user-select: none;
      border-radius: 2px;
      height: 40px;
      width: 40px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
      overflow: hidden;
    }

    .userLocation {

        .pin {
            width: 30px;
            height: 30px;
            border-radius: 50% 50% 50% 0;
            background-color: #2f82bf;
            position: absolute;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 50%;
            top: 50%;
            margin: -20px 0 0 -20px;
            -webkit-animation-name: bounce;
            -moz-animation-name: bounce;
            -o-animation-name: bounce;
            -ms-animation-name: bounce;
            animation-name: bounce;
            -webkit-animation-fill-mode: both;
            -moz-animation-fill-mode: both;
            -o-animation-fill-mode: both;
            -ms-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            -moz-animation-duration: 1s;
            -o-animation-duration: 1s;
            -ms-animation-duration: 1s;
            animation-duration: 1s;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                margin: 6px 0 0 6px;
                background: #fff;
                position: absolute;
                border-radius: 50%;
            }
          }
        .pulse {
            background: rgba(0,0,0,0.2);
            border-radius: 50%;
            height: 14px;
            width: 14px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: 11px 0px 0px -12px;
            -webkit-transform: rotateX(55deg);
            -moz-transform: rotateX(55deg);
            -o-transform: rotateX(55deg);
            -ms-transform: rotateX(55deg);
            transform: rotateX(55deg);
            z-index: -2;

                &::after {
                  content: ''; 
                  border-radius: 50%;
                  height: 40px;
                  width: 40px;
                  position: absolute;
                  margin: -13px 0 0 -13px;
                  -webkit-animation: pulsate 1s ease-out;
                  -moz-animation: pulsate 1s ease-out;
                  -o-animation: pulsate 1s ease-out;
                  -ms-animation: pulsate 1s ease-out;
                  animation: pulsate 1s ease-out;
                  -webkit-animation-iteration-count: infinite;
                  -moz-animation-iteration-count: infinite;
                  -o-animation-iteration-count: infinite;
                  -ms-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
                  opacity: 0;
                  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                  filter: alpha(opacity=0);
                  -webkit-box-shadow: 0 0 1px 2px #89849b;
                  box-shadow: 0 0 1px 2px #89849b;
                  -webkit-animation-delay: 1.1s;
                  -moz-animation-delay: 1.1s;
                  -o-animation-delay: 1.1s;
                  -ms-animation-delay: 1.1s;
                  animation-delay: 1.1s;
                }
        }
    }

    .activitysInformation{
        position: relative;

        .activityDetail {
            display: none;
            position: fixed;
            z-index: 999;
            top: -50px;
            left: -30px;
            border-radius: 5px;
            border: 2px solid #ccc;
            background-color: #fff;
        }
        
        .group_Name {
            display: none;
            width: auto;
            height: 1rem;
            padding: 5px;
            border-radius: 5px;
            border: 2px solid #da263c;
            background-color: #fff;
            padding: 5px; 
            white-space: nowrap;
            align-items: center; 
            justify-content: center;

            position: absolute;
            top: 0;
            left: 0;
        }
        &:hover .group_Name {
            display: flex;
        }

        &:hover .pin{
            background-color: #b92235;
            z-index: 998;
        }
        .pin {
            width: 30px;
            height: 30px;
            border-radius: 50% 50% 50% 0;
            background-color: #da263c;
            position: absolute;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 50%;
            top: 50%;
            margin: -20px 0 0 -20px;
            -webkit-animation-name: bounce;
            -moz-animation-name: bounce;
            -o-animation-name: bounce;
            -ms-animation-name: bounce;
            animation-name: bounce;
            -webkit-animation-fill-mode: both;
            -moz-animation-fill-mode: both;
            -o-animation-fill-mode: both;
            -ms-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 1s;
            -moz-animation-duration: 1s;
            -o-animation-duration: 1s;
            -ms-animation-duration: 1s;
            animation-duration: 1s;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                margin: 6px 0 0 6px;
                background: #fff;
                position: absolute;
                border-radius: 50%;
            }
          }
        .pulse {
            background: rgba(0,0,0,0.2);
            border-radius: 50%;
            height: 14px;
            width: 14px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: 11px 0px 0px -12px;
            -webkit-transform: rotateX(55deg);
            -moz-transform: rotateX(55deg);
            -o-transform: rotateX(55deg);
            -ms-transform: rotateX(55deg);
            transform: rotateX(55deg);
            z-index: -2;

                &::after {
                  content: ''; 
                  border-radius: 50%;
                  height: 40px;
                  width: 40px;
                  position: absolute;
                  margin: -13px 0 0 -13px;
                  -webkit-animation: pulsate 1s ease-out;
                  -moz-animation: pulsate 1s ease-out;
                  -o-animation: pulsate 1s ease-out;
                  -ms-animation: pulsate 1s ease-out;
                  animation: pulsate 1s ease-out;
                  -webkit-animation-iteration-count: infinite;
                  -moz-animation-iteration-count: infinite;
                  -o-animation-iteration-count: infinite;
                  -ms-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
                  opacity: 0;
                  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                  filter: alpha(opacity=0);
                  -webkit-box-shadow: 0 0 1px 2px #89849b;
                  box-shadow: 0 0 1px 2px #89849b;
                  -webkit-animation-delay: 1.1s;
                  -moz-animation-delay: 1.1s;
                  -o-animation-delay: 1.1s;
                  -ms-animation-delay: 1.1s;
                  animation-delay: 1.1s;
                }
        }
    }
`

export const FloatingAddress = styled.div `
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    width: 100%;
    overflow-x: hidden;
    --outSideHeight: 120px;
    height: var(--outSideHeight);
    display: flex;
    align-items: center; 
    justify-content: center;
    background-color: #fff;
    -moz-box-shadow:4px 4px 12px -2px rgba(20%,20%,40%,0.5);
    -webkit-box-shadow:4px 4px 12px -2px rgba(20%,20%,40%,0.5);
    box-shadow:4px 4px 12px -2px rgba(20%,20%,40%,0.5);

    a {
        box-shadow: unset;
    }
    
    button.slick-prev {
      left: -6%;
      height: var(--outSideHeight);
      cursor: pointer;
      &::before {
        color: #da263c;
      }
    }
    button.slick-next {
      right: -6%;
      height: var(--outSideHeight);
      cursor: pointer;
      &::before {
        color: #da263c;
      }
    }
    .cardWrapper{
      flex: 0 0 100%;
      display: flex;
      align-items: center; 
      justify-content: flex-start;
      margin: 0;
      padding: 5px 0;
    }
  

`

