const initialState = {
    shouldLoadingOpen: false,
    authorizeData: {},
    verifyData: {},
}

export default function oauthReducer(state = initialState, action) {
	switch (action.type) {

        case 'SHOULD_LOADING_OPEN': 
            return { ...state, shouldLoadingOpen: action.payload, };

        case 'GET_VERIFY_DATA':
        	return { ...state, verifyData: action.payload, }

        case 'RETURN_AUTHORIZE':
        	return { ...state, authorizeData: action.payload, };

		    
		default: return state
	}
}
