import React, { Component } from "react";
import { Wrapper } from "./styled";

class ErrorPage extends Component {

    render() {
        return (
            <Wrapper>
                <div id='error-inner'>
                    <a href="/" title="回到官網" className="logo">
                        <img src="/assets/images/logoWhite.svg" alt="BonnyBadminton"/>
                    </a>
                    <div className="badminton">
                        <div className="bonnybadminton-ball"></div>
                    </div>
                    <h2 className="text">
                        Oops! 無法瀏覽此頁面
                    </h2>
                    <h1 className="back-home">
                        <a href="/" title="回到官網">
                            回到官網
                            <i className="material-icons">
                                arrow_right
                            </i>
                        </a>
                    </h1>
                </div>
            </Wrapper>
        );
    }
}


export default ErrorPage;