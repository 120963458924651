import styled from 'styled-components';

export const Wrapper = styled.div`
    h2 {
        margin: 20px 0 20px 20px;
        a {
            border: 1px solid #2f82bf;
            color: #2f82bf;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 15px;
            float: right;
            margin: 3px 20px;
            letter-spacing: 1px;
            text-decoration: none;
        }
    }
    .all,
    .unread {
        flex: 1;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
        span.more {
            color: #fff;
            background-color: #DF1C2C;
            width: 20px;
            height: 20px;
            border-radius: 999rem;
            font-size: 10px;
            line-height: 20px;
            display: inline-block;
            margin-left: 1rem;
        }
        &.click {
            background-color: rgb(217, 29, 48);
            color: #fff;
        }
    }
    .react-tabs {
        position: relative;
        top: -5px;
        .react-tabs__tab {
            padding: 10px 0;
            position: relative;
            list-style: none;
            cursor: pointer;
            width: 50%;
            text-align: center;
            display: inline-block;
            vertical-align: bottom;
            border: none;
            font-size: .8rem;
        }
    }
`;
