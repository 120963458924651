import React, { Component } from "react";
import { FormControl } from "../../../component/Common";

export default class CourtPriceSelect extends Component {
    options() {
        var options = [];
        for (var i = 0; i <= 3000; i += 50) {
            options.push({ value: i })
        }
        return options
    }

    render() {

        return (
            <FormControl>
                <span className="paymentType__tag">
                    {this.props.paymentTypeText}
                </span>
                <input
                value={this.props.value}
                onChange={this.props.onChange}
                placeholder="請輸入金額"
                type="number"
                step="50"
                min="0"
                max="5000"
                style={{
                    display: 'inline',
                    // micro wrong width
                    // 114 = 7em(102px) + 1*2(border) + 5*2(padding)
                    width: 'calc(99% - 8em)',
                    padding: '0 5px',        
                    borderBottomLeftRadius: '0px',
                    borderTopLeftRadius: '0px',
            
                }} 
                />
                    {/* label={"打球費用："}  */}
            </FormControl>
        );
    }
}
