import React, { Component } from "react";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Banner from './Banner';
import {
    Wrapper,
    UserInformation,
    Mode
} from './styled';
import {
    ContractLightBox,
    GaSetting,
    LoadingIndicator,
    InvoiceField,
    Select,
} from "../Common";
import {
    getRacketInformation,
    getRacketProgram,
    createRent,
    getUserRent,
    confirmUserMail,
    fetchUserInfo,
    fetchlogin,
    setIsGetPaymentRes,
} from "../../actions";
import moment from 'moment-timezone';
import UAParser from 'ua-parser-js';
import ReactGA from 'react-ga';
import Config from '../config';
import { getCookie, validateEmail, localStorageUtility } from '../../utilities';
import store from '../../store';
import { CityData } from "../SelectLocation/tw-city";

const makeCityOption =() =>{
    const counties = [
        {
            value: "寄送縣市",
            disabled: false
        }
    ]
    CityData.counties.map(value => counties.push({ value: value }));
    return counties;
}
class Rent extends Component {
    constructor(props) {
        super(props);
        const userInfo = localStorageUtility('getAndParse', 'myData');
        const linkUrl = this.props.location.search;
        const linkArr = linkUrl.split('?level=');
        const linkLevelGet = linkArr[0] !== '' ? linkArr[0] : linkArr[1];
        let linkLevelClassify;
        if (linkLevelGet !== undefined) {
            const linkLevel = linkLevelGet.toUpperCase();
            switch(linkLevel) {
                case "CLASSIC":
                case "HIGH":
                    linkLevelClassify = 'high';
                    break;
                case "FEATHER":
                case "MEDIUM":
                    linkLevelClassify = 'medium';
                    break;
                case "LIMITED":
                case "LOW":
                    linkLevelClassify = 'low';
                    break;
                default:
                    this.props.history.push('/rackets_lobby');
            }
        }
        const level = linkLevelClassify ? linkLevelClassify : this.props.location.state && this.props.location.state.level;
        const landingPageCode = getCookie('LandingPage');
        this.state = {
            userInfo: userInfo,
            isUserLogin: (userInfo && userInfo.token) ? true : false,
            info_isLoaded: false,
            info_items: [],
            prog_isLoaded: false,
            prog_items: [],
            bornDate: moment(),
            cityOptions: makeCityOption(),
            rentData: {
                racket_id: '',
                paymenttype: '',
                amount: '1',
                user_id: userInfo && userInfo.id ? userInfo.id : '',
                password: '',
                confirmpassword: '',
                user: null,
                year: 0,
                month: 0,
                day: 0,
                gender: 0,
                email: null,
                phone: null,
                city: '寄送縣市',
                home: '',
                // district: '中正區',
                address: null,
                clientNote: null,
                gift: '1', //預設都送第一種贈品
                payment_supplier: '1', //信用卡
                racket_belong: '1',
                contract_agree_time: new Date().getTime(),
                agree: false,
            },
            rentConfirm: false,
            mailConfirm: true,
            agent:{},
            modalIsOpen: false,
            level: level ? level : 'high',
            isLoginAPICalled: false,
            isFromWhatLevel: level === 'low' ? '2D' : level === 'medium' ? '3D' : '4D',
            landingPageCode: landingPageCode,
            invoiceInfo:{
                // default value
                invoiceType: 'twoCopies',
                invoiceCheck: true,
                vatNumber: '',
                print: 0,
                donation: 0,
                loveCode: '',
                carruerType: 1,
                carruerNum: '',
                errMessage: '',
            },
        };
    }

    initializeReactGA() {
        ReactGA.initialize(Config.gaId);
    }

    reactGAsEvent = (action,label) => {
        const { landingPageCode } = this.state;
            ReactGA.event({
                category: `${this.state.isFromWhatLevel}-Rent`,
                action: `Rent_${action}`,
                label: `${this.state.isFromWhatLevel}_${label}_isType${landingPageCode}`,
            });
    }

    componentDidMount() {
        document.getElementById('content').scrollTo(0, 0);
        this.initializeReactGA();

        const { userInfo, isUserLogin } = this.state;
        document.cookie = "backurl=" + window.location.pathname + '?level=' + this.state.level;

        if (isUserLogin) {
            this.props.getUserInfo();
            this.props.getUserRent(userInfo.id);
            this.props.confirmUserMail(userInfo.email); // 已登入的 User 判斷是否為大力士或黑名單使用
        }

        // get Racket Program
        store.dispatch(setIsGetPaymentRes(false));
        this.props.getRacketProgram();
        this.props.getRacketInformation();

        var parser = new UAParser();
        let ua = navigator.userAgent;
        this.setState({
            agent :{
                browser: parser.setUA(ua).getBrowser(),
                os: parser.setUA(ua).getOS(),
                device: parser.setUA(ua).getDevice(),
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            isUserLogin,
            rentConfirm,
            rentData,
            isLoginAPICalled,
        } = this.state;
        // 當 User 未登入狀態下送出訂單
        // 在 User call autoLogin 去做登入動作
        // 接收到 isUserLogin === true
        // setState
        if (prevProps.isUserLogin !== this.props.isUserLogin && this.props.isUserLogin === true) {
            this.setState({ isUserLogin: true });
        }

        // 當 rentConfirm === true 時候，
        // 判斷使用者是否登入
        // 做不同導向
        // 未登入 => 協助登入
        if (this.props.isGetPaymentRes === true) {
            if (
                rentConfirm === true
                &&
                isUserLogin === false
                &&
                isLoginAPICalled === false
            ) {
                this.autoLogin(this.props);
            }
        }

        // 當 User 已經是登入中
        // 確認 this.state.rentData.email 裡面是否值
        // 如果沒有值 則setState 塞入 User資料
        const { userInfo } = this.props;
        if (isUserLogin && !rentData.email && userInfo.email) {
            const { email, name, phone, city, home, year, month, day, gender } = userInfo;
            this.setState( prevState => ({
                rentData: {
                    ...prevState.rentData,
                    user: name ? name : rentData.user,
                    email: email ? email : rentData.email,
                    phone: phone ? phone : rentData.phone,
                    city: city  ? userInfo.city : rentData.city,
                    home: home !== '' ? userInfo.home : rentData.address,
                    address: city && home !== '' ? city + home : !city && home !== '' ? home : rentData.address,
                    year: year ? year : rentData.year,
                    month: month ? month : rentData.month,
                    day: day ? day : rentData.day,
                    gender: gender ? gender : rentData.gender,
                }
            }))
        }

        if (
            (this.props.userInfo && this.props.userInfo.affiliateReferrerId)
            &&
            (prevProps.userInfo.affiliateReferrerId !== this.props.userInfo.affiliateReferrerId)
        ) {
                this.setState({ affiliateCode: this.props.userInfo.affiliateReferrerId.code })
        }


        if (
            (prevProps.racketProgram !== this.props.racketProgram)
            ||
            (prevState.rentData !== this.state.rentData)
        ) {
            this.setState({
                prog_isLoaded: true,
                prog_items: this.props.racketProgram,
                level: this.state.level,
            });
        }

        if (
            prevProps.racketInformation !== this.props.racketInformation
        ) {
            //  將需render的球拍資料 塞入state內
            this.setState({
                info_isLoaded: true,
                info_items: this.props.racketInformation.filter(data => data.tag === this.state.level),
                level: this.state.level,
            });
        }

        // A user cann't submit until isUserLogin is true
        // because a user who is not member will submit multiple times when he creates a order
        if(this.props.isGetPaymentRes === true && this.state.isUserLogin === true ){
            this.setSubmitGAevent(this.state.rentData);
            document.getElementById("purchase_form").submit();
        }
    }

    autoLogin(nextProps) {
        // 自動登入
        let playerId = localStorageUtility('get', 'playerId');
        const payload = {
            email: (nextProps.userInfo && nextProps.userInfo.email) || this.state.rentData.email,
            password: (nextProps.userInfo && nextProps.userInfo.password) || this.state.rentData.password,
            rentPay: true,
            agent: this.state.agent,
            playerId: playerId,
        };

        let expiresTime= moment().tz("Asia/Taipei").add(1, "day").format();
        localStorageUtility('set', 'expiresTime', expiresTime);
        this.props.login(payload);
        this.setState({isLoginAPICalled: true});
    }

    // racketID
    setSubmitGAevent(data) {
        const racketChoose =
            data.racket_id === '1' ? "1982II 3U"
            : 
            data.racket_id === '2' ? "InfinityII 3U" 
            :
            data.racket_id === '3' ? "PrincesII 3U" 
            : 
            data.racket_id === '4' ? "1982II 4U" 
            :
            data.racket_id === '5' ? "InfinityII 4U"
            :
            data.racket_id === '6' ? "PrincesII 4U"
            :
            data.racket_id === '7' ? "FT68 PK 櫻之粉羽" 
            : 
            data.racket_id === '8' ? "FT68 PP 神秘紫羽" 
            :
            data.racket_id === '9' ? "戰神Ares671" 
            : 
            data.racket_id === '10' ? "戰神Ares672" 
            :
            data.racket_id === '11' ? "弓箭Arrow780"
            :
            data.racket_id === '12' ? "弓箭Arrow790"
            :
            null;
        const paymentSupplier =
            data.paymenttype === 'A' ? '一次付清'
            :
            data.paymenttype === 'Y' ? '年繳'
            :
            data.paymenttype === 'M' ? '月繳'
            :
            null;
        const giftChoose =
            data.gift === '1' ? '贈品A'
            :
            data.gift === '2' ? '贈品B'
            :
            data.gift === '3' ? '贈品C'
            :
            null;
        const userID = data.user_id;

        this.reactGAsEvent(
            'Submit',
            `User:${userID}/RacketChoose:${racketChoose}/PaymentSupplier:${paymentSupplier}/GiftChoose:${giftChoose}`
        );
    }

    // racketID
    handleOnChange = (element, e) => {
        const { rentData } = this.state;
        let changeValue = '';
        let updateCheck = 0;

        if (element === 'racket_id') {
            if (e.target.value !== '99') {
                this.reactGAsEvent(
                    'RacketsChoose', 
                    e.target.value === '1' ? "1982II 3U" 
                    : 
                    e.target.value === '2' ? "InfinityII 3U" 
                    :
                    e.target.value === '3' ? "PrincesII 3U" 
                    : 
                    e.target.value === '4' ? "1982II 4U" 
                    :
                    e.target.value === '5' ? "InfinityII 4U"
                    :
                    e.target.value === '6' ? "PrincesII 4U"
                    :
                    e.target.value === '7' ? "FT68 PK 櫻之粉羽" 
                    : 
                    e.target.value === '8' ? "FT68 PP 神秘紫羽" 
                    :
                    e.target.value === '9' ? "戰神Ares671" 
                    : 
                    e.target.value === '10' ? "戰神Ares672" 
                    :
                    e.target.value === '11' ? "弓箭Arrow780"
                    :
                    e.target.value === '12' ? "弓箭Arrow790"
                    :
                    null
                );
                changeValue = e.target.value;
                updateCheck = 1;
            }
            else if (e.target.value === '99') {
                this.setState(prevState => ({
                    rentData: {
                        ...prevState.rentData,
                        paymenttype: "",
                    }
                }))
                this.reactGAsEvent(
                    'RacketsChoose', 'OtherPlan'
                );
            }
        }

        if (element === 'paymenttype') {
            changeValue = e.target.value;
            updateCheck = 1;
            this.reactGAsEvent(
                'PaymentSupplier',
                e.target.value === 'A' ? '一次付清'
                :
                e.target.value === 'Y' ? '年繳'
                :
                e.target.value === 'M' ? '月繳'
                :
                null
            );
        }

        if (element === 'gift') {
            changeValue = e.target.value;
            updateCheck = 1;
            if (changeValue !== '1') {
                alert('您選擇的贈品因有客製化服務，將無法提供七天鑑賞期退貨服務！')
            }
            this.reactGAsEvent(
                'GiftChoose', 
                e.target.value === '1' ? '贈品A'
                :
                e.target.value === '2' ? '贈品B'
                :
                e.target.value === '3' ? '贈品C'
                :
                null
            );
        }

        if (
            (element === 'email')
            &&
            (e.target.value !== '' )
            &&
            ( validateEmail(e.target.value) === true )
        ) {
            this.props.confirmUserMail(e.target.value);
        }

        if (element === 'city' && changeValue !== '寄送縣市') {
            changeValue = e.target.value;
            updateCheck = 1;
            this.setState(prevState => ({
                rentData: {
                    ...prevState.rentData,
                    [element]: changeValue,
                    address: changeValue + rentData.home,
                },
            }))
        }
        
        if (element === 'home' && changeValue !== null) {
            changeValue = e.target.value;
            updateCheck = 1;
            this.setState(prevState => ({
                rentData: {
                    ...prevState.rentData,
                    [element]: changeValue,
                    address: rentData.city + changeValue,
                },
            }))
        }

        if (element === 'agree') {
            if (rentData.agree === true) {
                changeValue = false;
            }
            else {
                changeValue = true;
            }
            updateCheck = 1;
        }
        else {
            // all condition will into here except agree element
            changeValue = e.target.value;
            updateCheck = 1;
        }

        // handleChange
        if (updateCheck === 1) {
            this.setState(prevState => ({
                rentData: {
                    ...prevState.rentData,
                    [element]: changeValue,
                }
            }))
        }
    }

    handleChangeInvoiceInfoState = (invoiceInfo)=>{
        this.setState({
            invoiceInfo:{
                ...invoiceInfo
            }
        })
    }
    // // 縣市
    // handleChange = (data) => {
    //     this.setState(prevState => ({
    //         rentData: {
    //             ...prevState.rentData,
    //             city: data.city,
    //         }
    //     }))
    // };

    // 年
    listYears() {
        var years = [];
        const now = moment().format('YYYY');
        const short = now - 10;
        const long = now - 87;
        for (let i = short; i >=long ; i--) {
            years.push({
                val: `${i}`,
                text: `西元 ${i}年`
            });
        }
        return years;
    }

    // 月
    listMonths() {
        var months = [];
        for (let i = 1; i <= 12; i++) {
            months.push({
                val: `${i}`,
                text: `${i}月`
            });
        }
        return months;
    }

    // 日
    listDays() {
        var days = [];
        const getYearMonth = this.state.rentData.year + '-' + this.state.rentData.month;
        const day = moment(getYearMonth, "YYYY-MM").daysInMonth();
        for (let i = 1; i <= day; i++) {
            days.push({
                val: `${i}`,
                text: `${i}日`
            });
        }
        return days;
    }

    lightBox = (e) => {
        var deal = document.getElementById("ContractLightBox");
        deal.classList.toggle("close");
    }

    LightBoxOtherProgram = (e) => {
        var lightboxotherprogram = document.getElementById("lightbox-other-program");
        lightboxotherprogram.classList.toggle("close");
    }

    descriptionExpand = (element, e) => {
        var descriptionPreNumber = "descriptionPre" + element.id;
        var descriptionPreID = document.getElementById(descriptionPreNumber);
        descriptionPreID.classList.toggle("open");

        var descriptionNameNumber = "descriptionName" + element.id;
        var descriptionNameID = document.getElementById(descriptionNameNumber);
        descriptionNameID.classList.toggle("open");

        this.setState({
            description: element.description,
        })

        var lightboxIntroduction = document.getElementById("lightboxIntroduction");
        lightboxIntroduction.classList.toggle("close");
    }

    lightboxIntroductionClose = () => {
        var lightboxIntroduction = document.getElementById("lightboxIntroduction");
        lightboxIntroduction.classList.toggle("close");
    }

    loginFun = ()=> {
        document.cookie = "backurl=" + window.location.pathname + '?level=' + this.state.level;
        this.props.history.push(`/login`);
    }

    submit = ()=> {
        document.getElementById("SendDataButton").disabled = true;

        //send rent
        const { createRent } = this.props;
        const { userInfo, mailConfirm, rentData, isUserLogin } = this.state;
        let notifyMessage = '';
        var mobileRule = /^[09]{2}[0-9]{8}$/;
        var passwordRule = /^(?=.*[a-zA-Z]).{6,15}$/;
        
        //confirm form
        var formCheck = 1;
        if (!rentData.racket_id) //racket_id must get value and not be 99
        {
            notifyMessage = '請選擇球拍';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (rentData.racket_id === '99'){
            notifyMessage = '請聯繫客服協助處理！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!rentData.paymenttype) //racket_id must get value and not be 99
        {
            notifyMessage = '請選擇專案類型';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!rentData.user) //racket_id must get value and not be 99
        {
            notifyMessage = '請輸入您的姓名';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!userInfo.id && rentData.year === 0) {
            notifyMessage = '請選擇出生西元年欄位！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!userInfo.id && rentData.month === 0) {
            notifyMessage = '請選擇出生月份欄位！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!userInfo.id && rentData.day === 0) {
            notifyMessage = '請選擇出生日期欄位！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!userInfo.id && rentData.gender === 0) {
            notifyMessage = '請選擇性別欄位！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!rentData.phone) {
            notifyMessage = '請確認電話欄位不可為空！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (rentData.phone && rentData.phone.search(mobileRule) === -1 ) {
            notifyMessage = '請確認電話格式是否正確！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (rentData.city === '寄送縣市') {
            notifyMessage = '請選擇寄送地址縣市欄位！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (!rentData.home) {
            notifyMessage = '請確認地址欄位不可為空！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (rentData.agree === false) //racket_id must get value and not be 99
        {
            notifyMessage = '請詳細閱讀合約並勾選確認！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (mailConfirm === false) {
            notifyMessage = '請聯繫客服協助處理！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (this.state.invoiceInfo.invoiceCheck === false) {
            notifyMessage = this.state.invoiceInfo.errMessage;
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else {
            // 沒有放在 else if 是因為 else if 可能會被移到不是最後一個，else 則需要放到最後
            if(!userInfo.id){
                if (rentData.password !== rentData.confirmpassword) {
                    formCheck = 0;
                    document.getElementById("SendDataButton").disabled = false;
                    notifyMessage = '請確認兩次密碼輸入是否皆相同！';
                }
                else if (!rentData.password || !rentData.confirmpassword){
                    formCheck = 0;
                    document.getElementById("SendDataButton").disabled = false;
                    notifyMessage = '請確認密碼欄位不可為空！';
                }
                else if (rentData.password && rentData.password.search(passwordRule) === -1) {
                    formCheck = 0;
                    document.getElementById("SendDataButton").disabled = false;
                    notifyMessage = '請確認密碼格式是否正確！';
                }
                else if (!rentData.email) {
                    formCheck = 0;
                    document.getElementById("SendDataButton").disabled = false;
                    notifyMessage = '請確認信箱欄位不可為空！';
                }
                else if (rentData.email && validateEmail(rentData.email) === false ) {
                    formCheck = 0;
                    document.getElementById("SendDataButton").disabled = false;
                    notifyMessage = '請確認信箱格式是否正確！';
                }
            }
        }

        if (formCheck === 1) //發送訂單
        {
            const userEmailConfirm = (this.props.rentReducerData && this.props.rentReducerData.userEmailConfirm) || false;
            const isHercules = userEmailConfirm && userEmailConfirm.isHercules;
            const isBlockedUser = userEmailConfirm && userEmailConfirm.isBlockedUser;
            const isEmailRegistered = userEmailConfirm && userEmailConfirm.isEmailRegistered;

            if (isHercules === true || isBlockedUser === true) {
                if(isHercules === true){
                    alert(
                        '提醒您！\n您本次申請羽你蓮心專案失敗，\n請洽本公司客服專員與您確認\n' +
                        '• 客服專線：02-23564250分機377或688\n' +
                        '• 客服信箱：service@bonny-live.com\n' +
                        '本公司客服專員將於收到通知後為您服務。'
                        );
                }
                else {
                    alert(
                        '提醒您！\n您尚未完成羽你蓮心專案申請，\n請洽本公司客服專員與您確認\n' +
                        '• 客服專線：02-23564250分機377或688\n' +
                        '• 客服信箱：service@bonny-live.com\n' +
                        '本公司客服專員將於收到通知後為您服務。'
                        );
                }

                document.getElementById("SendDataButton").disabled = false;
                return formCheck = 0;
            } else if (isEmailRegistered === true && isUserLogin === false) {
                alert(
                    '提醒您！\n' +
                    '該E-Mail已經被註冊使用\n' +
                    '所以無法完成羽你蓮心專案申請，\n如有問題請洽本公司客服專員與您確認\n' +
                    '• 客服專線：02-23564250分機377或688\n' +
                    '• 客服信箱：service@bonny-live.com\n' +
                    '本公司客服專員將於收到通知後為您服務。'
                    );

                document.getElementById("SendDataButton").disabled = false;
                return formCheck = 0;
            } else {
                this.setState({
                    rentConfirm: true,
                });

                // 租借是帶 上線的 affiliate code, 假如 user/`id` 沒有 affiliate code 就抓 localStorage 的
                let affiliateCode = localStorageUtility('getAndParse', 'affiliate').code;
                delete this.state.invoiceInfo.errMessage
                delete this.state.invoiceInfo.invoiceCheck
                delete this.state.invoiceInfo.invoiceType
                createRent({ ...this.state.rentData, ...this.state.invoiceInfo, affiliateCode: this.state.affiliateCode || affiliateCode });
            }
        }
        else //show message if form value check failed
        {
            alert(notifyMessage);
        }
    }

    render() {
        const { info_items, prog_items, rentData, level, userInfo, landingPageCode, isFromWhatLevel } = this.state;
        const { rentReducerData } = this.props;
        const programList = prog_items && rentData && rentData.racket_id && prog_items.filter(n => n.racket_id.id === parseInt(rentData.racket_id));
        const userRentOrder = rentReducerData && rentReducerData.userRent && rentReducerData.userRent.filter(n => (n.contract_status === 2 || n.contract_status === 4) && n.tag === level);
        const getYears = this.listYears();
        const yearsList = getYears.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const getMonths = this.listMonths();
        const monthsList = getMonths.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const getDays = this.listDays();
        const daysList = getDays.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        return (
            <Wrapper key={1}>
                <h1
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 羽你蓮心專案申請
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <ContractLightBox/>
                <Banner />
                {
                    isFromWhatLevel &&
                        <GaSetting
                            data = {{
                                name: 'componentDidMount', 
                                category: `${isFromWhatLevel}-Rent`,
                                action: `${isFromWhatLevel}_Rent_Type${landingPageCode}_PV`,
                                label: ``,
                            }}
                        />
                }
                <div id="program_content">
                    <div id="equipment" onChange={(e) => this.handleOnChange("racket_id", e)}>
                        <div className="title">
                            <h2 className="color-red">請先選擇球拍</h2>
                        </div>

                        {info_items.map((item, i) => (
                            <div className="item" key={'info_items' + i}>
                                <input id={'pid' + item.id} type="radio" name="racket_id" value={item.id} />

                                <label htmlFor={'pid' + item.id}>
                                    <div
                                        className= 'pic'
                                        style= {{
                                            backgroundImage: `url(${item.picture})`,
                                            textAlign: `center`,
                                        }}
                                    >
                                        <div className="checked_img"></div>
                                        <img 
                                            style={{ zIndex: "-999", height: '100%', }} 
                                            src={item.picture}
                                            alt="羽你蓮心其他方案"
                                        />
                                    </div>
                                </label>

                                <div className="description_id">
                                    <input 
                                        type="radio" 
                                        id={'description' + item.id} 
                                        name={'description_id' + item.id} 
                                        value={item.id}
                                    />
                                    <label htmlFor={'description' + item.id} >
                                        <pre><b>{item.description}</b></pre>
                                    </label>
                                </div>

                                <div className="description_id">
                                    <div 
                                        id={"descriptionName" + item.id} 
                                        className="name" 
                                        onClick={
                                            (e) => 
                                            this.descriptionExpand(item, e)
                                        } 
                                        checked={false}
                                    >
                                        {item.name}
                                    </div>
                                    <pre id={"descriptionPre" + item.id}>
                                        <b>{item.description}</b>
                                    </pre>
                                </div>
                            </div>
                        ))}
                        <div className="item">
                            <input type="radio" id="pid99" name="racket_id" value="99" />
                            <label htmlFor="pid99" onClick={this.LightBoxOtherProgram}>
                                <div className="pic other-pic" align="center">
                                    <img style={{ zIndex: "-999" }} src={"../assets/images/Rent-OtherProgram-Image.png"} height="100%" alt="羽你蓮心其他方案"/>
                                </div>
                                <div className="name">
                                    <br />
                                </div>
                            </label>
                        </div>
                    </div>

                    <hr className="margin-all-20px" />

                    <Mode onChange={this.changeMode}>
                        <div className="title">
                            <h2 className="color-red">請選選擇專案</h2>
                        </div>
                        <div className="label array">專案時間(起)：</div>
                        <div className="label array">專案時間(訖)：</div>
                        <div className="field array color-red font-size-big font-weight-bold">
                            {moment().add((10), 'd').format('YYYY/MM/DD')}
                        </div>
                        <div className="field array color-red font-size-big font-weight-bold">
                            {moment().add(((3 * 365) + 10), 'd').format('YYYY/MM/DD')}
                        </div>
                        <div className="label">專案類型：</div>
                        <div className="field">
                            <select value={rentData.paymentType} name="paymenttype" onChange={(e) => this.handleOnChange("paymenttype", e)}>
                                <option>請選擇專案類型</option>
                                {programList && programList.map((data, i) => (
                                    <option key={'programList' + i} value={data.type}>{data.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="label">付款方式：</div>
                        <div className="field">
                            <select value={rentData.payment_supplier} onChange={(e) => this.handleOnChange("payment_supplier", e)}>
                                {/*<option value ="0" selected>請選擇</option>*/}
                                <option value="1">信用卡線上付費</option>
                            </select>
                        </div>
                        <div className="label">贈品選擇：</div>
                        <div className="field">
                            <select value={rentData.gift} onChange={(e) => this.handleOnChange("gift", e)}>
                                <option >請選擇專案贈品</option>
                                <option value="1">贈品A：波力羽球線6組（羽拍穿線自理）、柄皮1條。</option>
                                <option value="2">贈品B：波力客製化穿線服務乙次（22±1磅數）、波力羽球線4組、柄皮1條。</option>
                                <option value="3">贈品B：波力客製化穿線服務乙次（24±1磅數）、波力羽球線4組、柄皮1條。</option>
                            </select>
                        </div>
                        <div className="label" style={{ display: 'none', }}>球拍歸屬：</div>
                        <div className="field" style={{ display: 'none', }}>
                            <select value={rentData.racket_belong} onChange={(e) => this.handleOnChange("racket_belong", e)}>
                            </select>
                        </div>
                        <br />
                    </Mode>

                    <hr className="margin-all-20px" />

                    <UserInformation>
                        <div className="title">
                            <h2 className="color-red">請輸入申請資料</h2>
                        </div>
                        <div>
                            <div className="label">姓名：</div>
                            <div className="field">
                                <input id="user" name="user" type="text" value={rentData.user || ''} placeholder="請輸入您的姓名" onChange={(e) => this.handleOnChange("user", e)} />
                            </div>
                        </div>
                        {!userInfo.id &&
                            <div>
                                <div className="label">出生年月日：</div>
                                <div className="field" style={{display: `flex`}}>
                                    <select name="year" onChange={(e) => this.handleOnChange("year", e)} value={rentData.year} style={{paddingLeft:`1em` , marginRight:`5px`}}>
                                        <option key='0' value='0'>
                                            請選擇西元年
                                        </option>
                                        {yearsList}
                                    </select>
                                    <select name="month" onChange={(e) => this.handleOnChange("month", e)} value={rentData.month} style={{paddingLeft:`1em` , margin:`0 5px`}}>
                                        <option key='0' value='0'>
                                            請選擇月份
                                        </option>
                                        {monthsList}
                                    </select>
                                    <select name="day" onChange={(e) => this.handleOnChange("day", e)} value={rentData.day} style={{paddingLeft:`1em` , marginLeft:`5px`}}>
                                        <option key='0' value='0'>
                                            請選擇日期
                                        </option>
                                        {daysList}
                                    </select>
                                </div>
                                <div className="label">性別：</div>
                                <div className="field" style={{display: `flex`, alignItems: `center`}} onChange={(e) => this.handleOnChange("gender", e)}>
                                    <input type="radio" name="gender" value="1" /><span style={{marginRight: `1em`,marginLeft: `5px`}}>男</span>
                                    <input type="radio" name="gender" value="2" /><span style={{marginRight: `1em`,marginLeft: `5px`}}>女</span>
                                </div>
                            </div>
                        }
                        {
                            userInfo.id ?
                            <div>
                                <div className="label">E-MAIL：</div>
                                <div className="field">
                                    {rentData.email}
                                </div>
                            </div>
                            :
                            <div>
                                <div className="label">E-MAIL：</div>
                                <div className="field accountAddress">
                                    <input className="mail" type="text" id="email" name="email" placeholder="請輸入您的E-Mail(此為日後登入帳號)" onChange={(e) => this.handleOnChange("email", e)} />
                                    <GaSetting
                                        data = {{
                                            category: `${isFromWhatLevel}-Rent`,
                                            action: `Rent_HaveAnAccount_Click`,
                                            label: `isType${landingPageCode}`,
                                        }}
                                    >
                                        <div className="login" onClick={this.loginFun}>我已有帳號</div>
                                    </GaSetting>
                                    <div className="clear"></div>
                                </div>
                                <div className="label">密碼：</div>
                                <div className="field">
                                    <input type="password" placeholder="請輸入您的密碼(6~15位英文數字混合)" onChange={(e) => this.handleOnChange("password", e)} />
                                </div>
                                <div className="label">密碼確認：</div>
                                <div className="field">
                                    <input type="password" placeholder="請再次輸入您的密碼" onChange={(e) => this.handleOnChange("confirmpassword", e)} />
                                </div>
                            </div>
                        }
                        <div>
                            <div className="label">行動電話：</div>
                            <div className="field">
                                <input id="phone" name="phone" type="text" value={rentData.phone || ''} placeholder="請輸入您的行動電話" onChange={(e) => this.handleOnChange("phone", e)} />
                            </div>
                        </div>
                        <div>
                            <div className="label">寄送地址：</div>
                            <div className="field">
                                <div className="cityAddress">
                                    <Select
                                        name='city'
                                        value={rentData.city}
                                        options={this.state.cityOptions}
                                        onChange={(e) => this.handleOnChange("city", e)}
                                    />
                                    <input id="home" name="home" type="text" value={rentData.home || ''} placeholder="請輸入正確收件地址" onChange={(e) => this.handleOnChange("home", e)} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="label">備註：</div>
                            <div className="field">
                                <textarea id="clientNote" name="clientNote" value={rentData.clientNote || ''} placeholder="請在此輸入.." onChange={(e) => this.handleOnChange("clientNote", e)} ></textarea>
                            </div>
                        </div>


                        <InvoiceField
                            // invoiceInfo = {this.state.invoiceInfo} // it will loop when add this line
                            handleChangeParentState = {this.handleChangeInvoiceInfoState }
                        />

                        <div className="checkBox">
                            <input type="checkbox" name="" value="agree" onClick={(e) => this.handleOnChange("agree", e)} />
                            <div className="field" onClick={this.lightBox}> 
                                <GaSetting 
                                    data = {{
                                        category: `${isFromWhatLevel}-Rent`,
                                        action: `Rent_ContractShow_Click`,
                                        label: `isType${landingPageCode}`,
                                    }}
                                >
                                    <span>我已詳細閱讀</span>
                                    <span className="cursor-pointer">《 合約 》</span>
                                    <span>並同意申請線上服務契約，並願意遵守規則。</span>
                                </GaSetting>
                            </div>
                        </div>
                        {
                            userRentOrder.length < Config.rentLength ? //限制申請數量 2 => 2支為上限
                                <div>
                                    <input type="hidden" name="payment_amount" value="" />
                                    <button id="SendDataButton" className="button-style" onClick={this.submit}>確認申請</button>
                                </div>
                            :
                                <div>
                                    <GaSetting 
                                        data = {{
                                            category: `${isFromWhatLevel}-Rent`,
                                            action: `Rent_isSubmilButton_Service_Click`,
                                            label: `isType${landingPageCode}`,
                                        }}
                                    >
                                        <button id="SendDataButton" className="button-style" onClick={this.LightBoxOtherProgram}>聯絡客服申請</button>
                                    </GaSetting>
                                </div>
                        }
                        <div>
                            <ul>
                                <li>注意事項：</li>
                                <li>提醒您！當您在線上點選「確認申請」時，即已經閱讀、了解本合約之規定，且同意本合約條款之規定。</li>
                                <li>提醒您!當您贈品選擇為由波力綁線+4條線，即同意放棄空拍七天鑑賞期並直接使用該服務。</li>
                                <li>若您有大量球拍申請需求，請洽客服專線:<a href="tel:0223564250">02-23564250分機377或688</a>，將會由專人為您處理。</li>
                            </ul>
                        </div>
                        <br />
                    </UserInformation>
                </div>

                {/*LightBox ----- 其他專案 */}
                <div id="lightbox-other-program" className="lightBox-explain close">
                    <div className="lightBox-explain-Content">
                        <div className="lightBox-explain-Content-inside">
                            <div className="lightBox-inside-close-button" onClick={this.LightBoxOtherProgram}>X</div>
                            <div className="LB-C-title">羽你蓮心</div>
                            <div className="LB-C-Description">
                                <ul>
                                    <li>若您有不可申請或大量申請之需求，</li>
                                    <li>請洽本公司客服專員為您服務，</li>
                                    <li>客服專線：<a href="tel:0223564250">02-23564250分機377或688</a></li>
                                    <li>客服信箱：<a href="mailto:service@bonny-live.com">service@bonny-live.com</a></li>
                                    <li>本公司客服專員將於收到通知後為您服務。</li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="lightBox-outside-close-button" onClick={this.LightBoxOtherProgram}>返回</div>
                    </div>
                </div>

                <div id="lightboxIntroduction" className="lightBox-explain close">
                    <div className="lightBox-explain-Content">
                        <div className="lightBox-explain-Content-inside">
                            <div className="lightBox-inside-close-button" onClick={this.lightboxIntroductionClose}>X</div>
                            <div className="LB-C-title">球拍介紹</div>
                            <div className="LB-C-Description">
                                <pre style={{display: `block`,}}>
                                    <b>
                                        {this.state.description}
                                    </b>
                                </pre>
                            </div>
                        </div>
                        <hr />
                        <div className="lightBox-outside-close-button" onClick={this.lightboxIntroductionClose}>返回</div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    rentReducerData: state.rentReducer,
    userInfo: state.myInfoReducer.userInfo,
    racketProgram: state.rentReducer.racketProgram,
    racketInformation: state.rentReducer.racketInformation,
    loading: state.rentReducer.shouldLoadingOpen,
    isGetPaymentRes: state.rentReducer.isGetPaymentRes,
    isUserLogin: state.loginReducer.isUserLogin,
});

const mapDispatchToProps = ({
    createRent: (data) => createRent(data),
    getUserRent: (data) => getUserRent(data),
    confirmUserMail: (data) => confirmUserMail(data),
    getRacketProgram: () => getRacketProgram(),
    getRacketInformation: () => getRacketInformation(),
    getUserInfo: fetchUserInfo,
    login: fetchlogin,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rent));
