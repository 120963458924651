import styled from 'styled-components';

export const Wrapper = styled.div `
    margin: 20px 20px 120px 20px;

    @media screen and (max-width: 480px){ 
        margin-bottom: 160px;
    }
    .main-form{
        margin: auto;
        width: 100%;
    }
   
    select{
        width:100%
    };
   
    .title {
        text-align: center;
        width:100%
        border: 0 none
        font-size: 30px;
        font-weight: 500;
        color: #000000
        border-radius: 5px
    }
             
    .Ckbox {
        position:relative; 
        left:100px; 
        bottom:28px;
        font-size: 18px;
        font-weight: bold;
        color: #000000;
    }

    .form {
        text-align: center;
    }
         
    .link{
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-left:10px;
        color: #000000;
    }
    
    .text {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-left: -10px;
        color: #000000;
    }
    
    input[disabled] {
        background-color: #EEE;
    }

    .SubmitButton{
        text-align: center;
        width: 100%;
        background-color: #DF1C2C;
        border: 0 none;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 1.2rem;
        font-weight: bolder;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
    }
    
    // 縣市地址 style設定
    .cityAddress {
        width: 100%;
        display: flex;
        .select {
            width: 6rem;
            margin-right: 5px;

            select {
                padding-left: 1em;
            }
        }
        input[type="text"] {
            flex: 1;
            margin-left: 5px;
        }
    }

    .flex{
        display: flex;
    }

    .alignCenter{
        align-items: center;
    }

    .mobileVerifyButton{
        height: 37px;
        width: calc( 14px * 8 );
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #fff;
        font-size: 14px;
        border-radius: 0 5px 5px 0;
        background-color: #DF1C2C;

        &:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }

    .mobileVerifySubmit{
        height: 37px;
        width: calc( 14px * 6 );
        margin-left: 5px;
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #fff;
        font-size: 14px;
        border-radius: 5px;
        background-color: #2f82bf;

        &:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }

    .mobileVerifyCancel{
        height: 37px;
        width: auto;
        margin-left: 10px;
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #000;
        font-size: 12px;
        text-decoration: underline;

        &:hover{
            color:#DF1C2C;
        }
    }
   
`;

   

  
  


