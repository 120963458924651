import styled from 'styled-components';

export const Wrapper = styled.div `
  margin-bottom: 40px;
  @media (max-width: 480px){
    margin-bottom: 30vh;
  }

    div.header {
      display: flex; 
      align-items: baseline;
      
      > * {
        flex: none
      }
    }

    h2 {
      margin: 20px 20px 5px 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }
    
    div.btn-group {
      margin-left: auto;
      position: absolute;
      right: 0;
      bottom: 5px;
    }
    .search-info {
      margin-top: 8px;
      a {
          width: 22%;
      }
      .input-button {
          width: 59%;
      }
      @media (max-width: 320px) {
          a {
              width: 26%;
          }
          .input-button {
              width: 51%;
          }
      }
    }

    
  // .search-info,
`;
