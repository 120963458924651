
export const localStorageUtility = (method, key, value) => {

    // client side
    if (typeof window !== 'undefined') {
        // console.log("in client side");
        switch (method) {
            case 'set':
                // console.log("set");
                localStorage.setItem(key, value);
                break;
            case 'get':
                // console.log("get");
                // localStorage.getItem(key);
                return localStorage && localStorage.getItem(key);
            case 'getAndParse':
                // console.log("get and parse");
                return localStorage && localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : '';
            case 'remove':
                // console.log("remove");
                localStorage.removeItem(key);
                break;
            default:
                // console.log('default');
                break;
        }
    } else {
        console.log("in server side");
    }

}
