import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    // display: flex;
    // display: -webkit-box;
    // display: -webkit-flex;
    // display: -ms-flexbox;
    // flex-flow: row wrap;
    display: block;
    margin: 5px 10px 0px 5px;
    padding-bottom: 5px;
    border-bottom: 0px solid #ccc;
    width: calc( 100% - 15px );
    flex: 1;
    a {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        width: 100%;
        align-items: flex-end;
        color: unset;
        text-decoration: none;
        padding: 2px;
        box-shadow: 2px 3px 3px #eee;
        &:hover {
            opacity: 0.8;
        }
    }
    .act-img {
        flex: 0 0 30%;
        height: 90px;
        object-fit: fill;
        position: relative;
        overflow: hidden;
        margin: 0 5px 0 0;
        .act-bag{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
        img {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .type {
            height: 20px;
            position: absolute;
            bottom: 0px;
            width: 100%;
            font-size: 10px;
            color: #fff;
            background: rgba(0, 0, 0, 0.6);
            text-align: center;
        }
    }
    .act-info {
        flex: 1;
        height: 90px;
        text-align: left;
        margin-right: 0px;
        margin-top: 0px;
        .title {
            height: 24px;
            margin: 0 0 4px;
            font-size: 16px;
            font-weight: bold;
            color: #E50614;
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;

            .title-text {
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .title-label {
                max-width: calc( 8rem + 6px);
                height: 24px;
                overflow: hidden;
                display: flex;
                // flex: 1;
                // justify-content:center;
                justify-content: flex-end;
                align-items:center; 
                span{
                    // float: right;
                    // flex: 1;
                    max-width: 4rem;
                    // margin-top: 1px;
                    color: #666;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 300;
                    // line-height: 18px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 0px 4px;
                    margin-right: 2px;
                    // display: inline-block;
                    // vertical-align: bottom;
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    // white-space: nowrap;
                }
            }
        }
        p {
            height: calc( ( 90px - 30px ) / 3 );
            line-height: calc( ( 90px - 30px ) / 3 );
            margin: 0;
            font-size: 12px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        small {
            height: calc( ( 90px - 30px ) / 3 );
            line-height: calc( ( 90px - 30px ) / 3 );
            display: block;
            font-size: 12px;
        }
        label {
            font-size: 10px;
            color: #666;
            font-weight: 300;
            display: inline-block;
            vertical-align: bottom;
            border: 1px solid #ccc;
            margin: 0px 1px;
            padding: 1px 2px;
            border-radius: 5px;
        }
    }
`;
