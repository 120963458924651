import { handleActions } from 'redux-actions';

const initialState = {
	url: '',
	uploadStatus: '',
};

export default handleActions({
	SET_IMG_URL(state, action) {
		return {
			...state,
			url: action.payload,
		};
	},
	UPLOAD_IMG_STATUS(state, action) {
		return {
			...state,
			uploadStatus: action.payload,
		};
	},
}, initialState);
