import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    select{
        width:100%;
    }
    .image-uploader-section{
        width: 100%;
    } 
    .view_follow{
        text-align: center
    }
    .main-form{
        p{
            font-size: 16px;
            line-height: 2.2em;
            @media (max-width: 480px) {
                 font-size: 14px;
                 line-height: 2em;
             }
        }
    }

    #joinButton{
        text-align: center;
        width:50%
        border: 0 none
        margin-top: 10px
        margin-bottom: 10px
        padding-top: 5px
        padding-bottom: 5px
        font-size: 20px
        font-weight: bolder
        color: #fff
        cursor:pointer
        img{
        }
    }

    #joinButton.left{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: #DF1C2C;
    }

    #joinButton.right{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #2f82bf;
    }

    #joinButton:hover{
        box-shadow:rgba(0, 0, 0, 0.33) 0px 0px 10px 2px inset;
    }

    #joinButton:hover.left{
        background-color: #bd1725;  
    }

    #joinButton:hover.right{
        background-color: #246798;  
    }
    

    .b1{
        display: block;
        width:100%
        text-align: center;
        background-color: #D5D5D5;
        border: 0 none;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: large;
        // font-weight: bolder
        color: #fff;
        border-radius: 5px;
        img{
          padding-left: 5px;
          top: 5px;
          position: relative;
        }
    }
    .icon{
        cursor: pointer;
        i {
          color: #DF1C2C;
          font-size: 26px;
          // text-shadow: -1px -1px 0 #FFF,1px -1px 0 #FFF,-1px 1px 0 #FFF,1px 1px 0 #FFF;
        }
            small {
                // text-shadow: -1px -1px 0 #FFF,1px -1px 0 #FFF,-1px 1px 0 #FFF,1px 1px 0 #FFF;
            }
    }
    .checkbox {
        margin: 10px auto 10px;
        display: flex;
        align-items: center; 
        justify-content: center;
        width: calc( 35px + 5rem );

        input[type="checkbox"] {
            flex: 0 0 35px;
        }
        h3 {
            width: 5rem;
        }
    }

    @media screen and (max-width: 480px){
        #checkBTN {
            position :fixed;
            bottom: 60px;
            padding-top: 10px;
            padding-bottom: 10px;
            left: 0;
            width: 100vw;
            background-color: #FFF;
            z-index: 1;
            border-top: 1px solid #ccc;
        }
        #checkBTN button{
            width: 80% !important;
            margin-left: 10%;
        }
        .m_b_100{
            margin-bottom: 100px;
        }
    }
`
