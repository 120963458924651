import styled from "styled-components";

export const Wrapper = styled.div`

#ContractLightBox{
  font-size:16px;

  ul{
    list-style-type:none;
    line-height:26px;
  }

  p{
    margin:0;
    padding:0;
    line-height:26px;
  }

  .title{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight:bold;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom:20px;
  }
  .sub{
    font-weight:bold;
  }
  .indent{
    text-indent: 2rem;
  }
  .red{
    color:red;
  }
  .fontWeightBold{
    font-weight:bold;
  }
  // 實心圓 
  ul.disc{
    list-style-type:disc;
    list-style-position: inside; 
    text-indent: 4rem;
  }
  // 實心框
  ul.square{
    list-style-type:square;
    list-style-position: inside;
    text-indent: 4rem;
  }
  // 數字
  ul.decimal{
    list-style-type:decimal;
    text-indent: 2rem;
  }
}

// LightBox ----- 說明文字
    .lightBox-Contract{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
    }
    .lightBox-Contract.close{
        display:none;
    }
    .lightBox-Contract-Content{
        max-width:420px;
        height:auto;
        max-height: calc(76vh - 40px); 
        overflow-y:auto;  
        position: relative;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: hidden auto;
        border-radius: 20px;
        outline: none;
        margin: 12vh auto;

      .lightBox-Contract-Content-inside{
            padding: 20px;
            padding-bottom:0;

          .lightBox-inside-close-button{
                border-radius:999rem;
                border:1px solid #ccc;
                background-color:#FFF;
                width:20px;
                height:20px;
                line-height:20px;
                text-align:center;
                font-weight:bold;
                font-size:14px;
                cursor:pointer;
                position:absolute;
                right:10px;
                top:10px;
            }
        }

      .lightBox-outside-close-button{
            text-align:center;
            height:37px;
            line-height:37px;
            font-size:20px;
            font-weight:bold;
            cursor:pointer;
            padding:10px 0;
        }
      .lightBox-footer{
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;

        .lightBox-footer-button{
          display: flex;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          text-align:center;
          height:37px;
          line-height:37px;
          font-size:20px;
          font-weight:bold;
          cursor:pointer;
          padding:10px 0;
          a{
            color:#000;
          }
        }
        .lightBox-footer-button-left{
          border-right:1px solid #ccc;
          justify-content: center;
        }
        .lightBox-footer-button-right{
          border-left:1px solid #ccc;
          justify-content: center;
        }
      }
    }

    @media (max-width: 480px) {
      #ContractLightBox{
        font-size:14px;

        .lightBox-Contract-Content{
          width: 100vw;
          height: calc( 100vh - 139px);
          max-height: none;
          margin: 57px auto 72px auto;
          padding: 0 10px 10px;
          border-radius: 0;
        }
      }
    }

`;
