import styled from "styled-components";

export const Wrapper = styled.div`
  div {
    width: 100%;
  }
  input {
    box-sizing: border-box;
  }
		.react-autosuggest__container {
			position: relative;
		}

		.react-autosuggest__input {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		.react-autosuggest__input:focus {
			outline: none;
		}

		.react-autosuggest__container--open .react-autosuggest__input {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		.react-autosuggest__suggestions-container {
			display: none;
		}

		.react-autosuggest__container--open .react-autosuggest__suggestions-container {
			display: block;
			box-sizing: border-box;
			position: absolute;
			top: 37px;
			width: 100%;
			border: 1px solid #aaa;
			background-color: #fff;
			font-family: Helvetica, sans-serif;
			font-weight: 300;
			font-size: 16px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			max-height: 100px;
			overflow-y: auto;
			z-index: 2;
		}

		.react-autosuggest__suggestions-list {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		.react-autosuggest__suggestion {
			cursor: pointer;
			padding: 10px 20px;
		}

		.react-autosuggest__suggestion:not(:first-child) {
			border-top: 1px solid #ddd;
		}

		.react-autosuggest__suggestion--focused {
			background-color: #0C7EAF;
			color: #fff;
		}

		.react-autosuggest__suggestion--highlighted {
			background-color: #ddd;
		}
		
		.react-autosuggest__suggestion-match {
		  color: #E50614;
		  font-weight: bold;
		}

		.suggestion-content {
			display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
			align-items: center;
			background-repeat: no-repeat;
		}
`;
