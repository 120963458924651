import React, { Component } from "react";
import { Wrapper } from "./styled";
import MemberInfo from '../../ActivityDetail/GroupInfo/MemberInfo';

class SignUpInfo extends Component {
    constructor(props) {
        super(props)
        this.onLeaveActivityClick = this.onLeaveActivityClick.bind(this)
    }

    onLeaveActivityClick(id) {
      this.props.onLeaveClick(id)
    }

    renderRegisteredMembers() {
      const { joinedPeopleData } = this.props
      return joinedPeopleData
        .sort((a, b) => a.createdAt - b.createdAt)
        .filter(data => data.status === true)
        .map((data, i) => (
          <div className="registeredMember" key={i}>
            <MemberInfo {...(data.user)} index={i+1} registeredAt={data.createdAt} follow={() =>this.props.follow(data.user.id)} followList={this.props.followList}/>
          </div>
        )
      );
    }
    
    render() {
      var joinedPeopleDataLength = this.props && this.props.joinedPeopleData && this.props.joinedPeopleData.filter(data => data.status === true).length;
      return (
        <Wrapper>
          <div className="info-title">
            已報名人員：{joinedPeopleDataLength} / {this.props.people}
          </div>
          {this.renderRegisteredMembers()}
        </Wrapper>
      )
    }
}

SignUpInfo.defaultProps = {
  joinedPeopleData: {
      createdAt: 0,
      user: {
        id: 0,
        name: "Name",
        avatar: "/assets/images/DefaultUserImg.png",
        createdAt: 0,
        //registeredNumber: 0,
        isPaid: false,
        isFollowing: false,
      }
  },
  people: 0,
  onLeaveClick: (id) => {},
  follow :(id) =>{}
}

export default SignUpInfo;
