import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin-top: 20px;
    .info-title {
      margin: 20px 10px;
    }   
    .btn-group {
      margin-top: 10px;
    }
    .registeredMember {
      margin: 10px auto;
      padding: 10px 5px;
      background-color: #F1F1F1;
    }
    .grayButton{
      border: 1px solid #D8D8D8;
      padding: 2px 8px;
      margin: 5px 3px;
      border-radius: 999rem;
      box-shadow: unset;
      text-shadow: unset;
      background-color: rgba(216,216,216,.6);
      text-align: center;
      color: #ccc;
      cursor: default;
    }
    .talk {
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      color: #fff;
      height: 35px;
      margin: 5px;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: #437cae;
      // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
      // text-shadow: 0px -2px #2e5577;
      border-radius: 999rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;    

      &:hover, &.active{
        background-color: #2e5577;
        color: #eee;
      }   

      &.red {
        background-color: #da263c;
        // text-shadow: 0px -2px rgb(223,28,44);   

        &:hover, &.active {
          background-color: rgba(162,28,46,1);
          color: #eee;
        }
      }
    }
`;