import React, { Component } from "react";
import moment from 'moment';
import ContentItem from './ContentItem'
import { Wrapper } from './styled';
import { 
    Features, 
} from "../../../Common";

const level = level => {
    switch (level) {
        case 2:
            return "新手";
        case 3:
            return "中手";
        case 4:
            return "高手";
        case 5:
            return "無限制";
        default:
            return "入門";
    }
};

class ActivityContent extends Component {

    render() {
        const {
          createdAt,
          start,
          end,
          groupName,
          people,
          placeName,
          city,
          area,
          address,
          badmintonLevel,
          // fee,
          cash,
          creditEcpay,
          placeCount,
          tag,
          type,
          remain,
        } = this.props;

        const startTime = moment.unix(start/1000),
              endTime = moment.unix(end/1000),
              createdTime = moment.unix(createdAt/1000);
        return (
            <Wrapper>
              <ContentItem title="開團時間" content={`${createdAt > 0? createdTime.format('YYYY年MM月DD日 LT'): ''}`} />
              <ContentItem title="團名" content={`${groupName}`} />
              {!!remain ? 
                <ContentItem title="已報名/徵求人數" content={`${people - remain}/${people}`} />
                :
                <ContentItem title="徵求人數" content={`${people}人`} />
              }
              <ContentItem title="開始時間" content={`${start > 0? startTime.format('YYYY年MM月DD日 LT') : ''}`} />
              <ContentItem title="結束時間" content={`${end > 0? endTime.format('YYYY年MM月DD日 LT') : ''}`} />
              <ContentItem title="球場名稱" content={`${placeName}`} />
              <ContentItem title="球場地址" content={`${city + area + `-` + address}`} />
              <ContentItem title="球場程度" content={level(badmintonLevel)} />
              {/*
              <ContentItem title="報名費用" content={`${fee} 元`} />
              */}
              { cash !== 0 ?
              <ContentItem title="現場付費費用" content={`${cash} 元`} />
              :''}
              { type === 1 && !!creditEcpay === true ?
              <ContentItem title="線上付費費用" content={`${creditEcpay} 元`} />
              : ''}
              <ContentItem title="場地數量" content={`${placeCount}`} />
              <p className="content-title">特色與備註：</p>
              <Features features={tag.map(x => x.name)} />  
         </Wrapper>     
        );
    }
}

ActivityContent.defaultProps = {
  createdAt: 0,
  start: 0,
  groupName: "",
  people: 0,
  placeName: "",
  address: "",
  badmintonLevel: 0,
  fee: 0,
  placeCount: 0,
  tag: [],
  cash: 0,
  creditEcpay: 0,
}

export default ActivityContent;
