import { handleActions } from 'redux-actions';

import {
	FORGET_LIST_USER_BEGIN,
	FORGET_LIST_USER_SUCCESS,
	FORGET_LIST_USER_FAILURE,
} from '../constants/actionTypes';

const initialState = {
	status: FORGET_LIST_USER_SUCCESS,
	modalStatus: 'close',
	err: null,
	isSendResetMailOk: false,
};

export default handleActions({
	FORGET_LIST_USER_BEGIN(state, action) {
		return {
			...state,
			status: FORGET_LIST_USER_BEGIN,
			err: null,
		};
	},
	FORGET_LIST_USER_SUCCESS(state, action) {
		return {
			...state,
			status: FORGET_LIST_USER_SUCCESS,
			modalStatus: action.payload,
			err: null,
		};
	},
	FORGET_LIST_USER_FAILURE(state, action) {
		return {
			...state,
			status: FORGET_LIST_USER_FAILURE,
			modalStatus: 'close',
			err: action.payload,
		};
	},
	FORGET_SET_MODAL_STATUS(state, action) {
		return {
			...state,
			modalStatus: action.payload,
		};
	},
	IS_SEND_RESET_MAIL_OK(state, action) {
		return {
			...state,
			isSendResetMailOk: action.payload,
		};
	}
}, initialState);


