import React from "react";
import moment from 'moment';

export default () => (
    <div>
        {
            ( moment().valueOf() > moment('2020/01/16 00:00:00').valueOf() )
                &&
            ( moment().valueOf() < moment('2020/02/04 13:00:00').valueOf() )
            ?
                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/inventory/rent-20200114_NewYear.jpg" alt="佳節愉快" className="banner" />
            :
                <img src="./assets/images/rent-banner.jpg" alt="羽你蓮心" className="banner" />
        }
    </div>
);