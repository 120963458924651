import styled from 'styled-components';

export const Wrapper = styled.div `
    width: calc( 100% - 40px );
    margin: 20px;
    .b1{
        text-align: center;
        width:100%
        background-color: #D5D5D5;
        border: 0 none
        padding-top: 10PX
        padding-bottom: 10PX
        font-size: large
        font-weight: bolder
        color: #fff
        border-radius: 5px
        font-weight: bolder
        img{
          padding-left: 5px;
          top: 5px;
          position: relative;
        }
    }
`
