import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { followList } from '../../../actions';
import { Wrapper, ListContent } from './styled';
import { InputButton } from '../../../component/Common';
import Banner from '../Banner';

class Follow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "follower"
        };
    }
    componentDidMount() {
        this.props.getFollowList(this.state);
    }
    handleInputChange = e => {
        const target = e.target;
        const value = target.value;
        const inputName = target.name;
        this.setState({
            [inputName]: value
        });
    };
    handleSubmit = () => {
        this.props.getFollowList(this.state);
    };

    render() {
        const { myFollow } = this.props

        const followers = []
        Array.isArray(myFollow) && myFollow.forEach((data)=>{
            followers.push(data.followee)
        })
        return [
            <Banner key={0} />,
            <Wrapper key={1}>
                <div className="header">
                    <h2>我的追蹤</h2>
                    <InputButton
                        input={{
                            name: 'search',
                            onChange: this.handleInputChange
                        }}
                        onClick={this.handleSubmit}
                    />
                </div>
                <ListContent>
                    {followers.map(data => List(data))}
                </ListContent>
            </Wrapper>
        ];
    }
}

const List = (data) => {
    return <Link to={`follower_host_activities/${data.id}`} key={data.id}>    
        <div className="list">
            <div className="Out_left">
                <div className="avatar">
                    <div className="avatar_photo" style={{backgroundImage: `url(${data.avatar ||'/assets/images/DefaultUserImg.png'})`}}></div>
                </div>
            </div>
            <div className="Out_right">
                <div className="inside">
                    <div className="In_left">
                        <div className="name">{data.name}</div>
                        {/* <div className="message">
                            Lorem ipsum dolor sit
                        </div> */}
                    </div>
                    <div className="In_right">
                        <i className="material-icons arrow-right" style={{color: `#808080`,verticalAlign:`middle`,}}>chevron_right</i>
                    </div>
                </div>
            </div>
        </div>
    </Link>
}

const mapStateToProps = state => ({
    myFollow: state.groupDetailReducer.follow
});

const mapDispatchToProps = {
    getFollowList: followList
};

export default connect(mapStateToProps, mapDispatchToProps)(Follow);
