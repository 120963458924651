import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Wrapper } from "./styled";

const level = level => {
    switch (level) {
        case 2:
            return "新手";
        case 3:
            return "中手";
        case 4:
            return "高手";
        case 5:
            return "無限制";
        default:
            return "入門";
    }
};
export default class extends Component {

    isMobile = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        }
        catch (e) {
            return false;
        }
    }

    render() {
        const { data, url, paymentStatus } = this.props;

        return (
            <Wrapper className="cardWrapper">
                <Link to={url}>
                    <div className="act-img" style={{ height: `${!!paymentStatus ? '110px' : '90px'}` }}>
                        <div className="act-bag"
                            style={{ backgroundImage: `url(${(data.images && data.images.url[0]) || '/assets/images/default.jpg'})` }}
                        >
                        </div>
                        <div className="type">
                            {level(data.badminton_level)}
                        </div>
                    </div>
                    <div className="act-info" style={{ height: `${!!paymentStatus ? '110px' : '90px'}` }}>
                        <div className="title" style={{ fontSize: `${this.isMobile() ? '14px' : ''}` }}>
                            <div className="title-text" style={{ maxWidth: `${this.isMobile() ? '49%' : ''}` }}>
                                {data.group_name}
                            </div>
                            <div className="title-label">
                                {data.tag && data.tag.filter((e, i) => {
                                    return i < 2
                                }).map((tag, i) => {
                                    return <span key={i}>{tag.name}</span>;
                                })}
                            </div>
                        </div>
                        <p>
                            {data.area} - {data.place_name}
                        </p>

                        <small>
                            {moment(data.start).format("M/D HH:mm") + ' - ' + moment(data.end).format("HH:mm")}
                        </small>

                        <small>
                            費用：{data.fee > 0 ? '$' + data.fee + '/人' : <span style={{ 'fontWeight': 'bold', 'color': '#E50614', }}>免費</span>} | 徵 {data.remain} 人
                        </small>

                        {
                            !!paymentStatus ?
                                <small>
                                    {paymentStatus}
                                </small>
                                :
                                null
                        }

                    </div>
                </Link>
                {this.props.children}
            </Wrapper>
        );
    }
}
