import React, { Component } from "react";
import ReactGA from 'react-ga';
import Config from '../../config';
import { Link, withRouter } from 'react-router-dom';
import { openExternalWebsiteUrl } from '../../../utilities';

// <GaSetting 
//     data = {{
//         className: 'live', 
//         category: 'index-badminton', 
//         action: 'live-click', 
//         label: '', 
//         tag: 'button',
//         target: '_bank',
//         title: ''
//         path: 'https://www.bonny-live.com/Show/List', 
//         style : {
//             backgroundImage:'url(/assets/images/liveButton.png)',
//             backgroundSize: 'cover',
//             width: '100%',
//             height: `${this.state.isMobile ? '70px' : '80px'}`,
//             backgroundPosition: `${this.state.isMobile ? '54.666666%' : 'center'}`,
//         },
//     }}
// >
// </GaSetting>

class GaSetting extends Component {
    componentDidMount() {
        const { data } = this.props;
        const name = data && data.name;
        const category = data && data.category;
        const action = data && data.action;
        const label = data && data.label;

        this.initializeReactGA();

        if(name === 'componentDidMount'){
            ReactGA.event({
              category: category || '',
              action: action || '',
              label: label || '',
            });
            ReactGA.pageview(window.location.pathname)
        }
        // this.waittingTheGaForUse(name,category,action,label,path);
    }

    initializeReactGA() {
        ReactGA.initialize(Config.gaId);
        // ReactGA.pageview(window.location.pathname + window.location.search);
        // this.affiliateUseFunction = this.affiliateUseFunction.bind(this);
    }
    
    // async waittingTheGaForUse (data) {
    //     // await console.log(data)
    //     await this.setTheGA(data);
    //     await this.goThePage(data);
    // } 

    setTheGA(data){
        const name = data && data.name;
        const category = data && data.category;
        const action = data && data.action;
        const label = data && data.label;
        
        if(name !== 'componentDidMount'){
            ReactGA.event({
              category: category || '',
              action: action || '',
              label: label || '',
            });
            // console.log(`is not componentDidMount`)
        }
        if(data.tag !== 'link'){
            this.goThePage(data);
        }
    }

    goThePage(data){
        const path = data && data.path;
        const target = data && data.target;

        if(path){
            // 開其他分頁的視為開外站
            if(target === '_bank'){
                openExternalWebsiteUrl(path, 'windowOpen')
            // only use for logout now(2019 11/15)
            }else if(target === 'windowRedirect'){
                window.location.href = path;
            }else{
                this.props.history.push(path);
            }
        }
    }

    render() {
        const {data} = this.props;
        return (
            data.name !== 'componentDidMount' &&
                (
                    data.tag === 'link' ?
                    (
                        <Link 
                            className={data.className}
                            style={data.style}
                            title={data.title}
                            onClick={()=>{this.setTheGA(data)}}                                
                            to={data.path}
                        >
                            {this.props.children}
                        </Link>
                    )
                    :
                    data.tag === 'button' ?
                    (
                        <button 
                            className={data.className}
                            style={data.style}
                            onClick={()=>{this.setTheGA(data)}}
                            title={data.title}
                        >  
                           {this.props.children}
                        </button>
                    )
                    :
                    (
                        <div 
                            className={data.className}
                            style={data.style}
                            onClick={()=>{this.setTheGA(data)}}
                            title={data.title}
                        >  
                            {this.props.children}
                        </div>
                    )
                )
        );
    }
}

GaSetting.defaultProps = {
    data: []
};


export default withRouter(GaSetting);