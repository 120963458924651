import React, { Component } from "react";
import { Wrapper } from "./styled"
import AutoSuggest from "../AutoSuggest";

class InviteButton extends Component {

  render() {
    const { 
      inputProps, 
      suggestions, 
      onSuggestionSelected,
      getSuggestionValue,
      renderSuggestion,
      value,
      onClick
    } = this.props;


    return (
      <Wrapper className="input-button">
        <AutoSuggest 
          inputProps={inputProps} 
          suggestions={suggestions} 
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          value={value}
        />
        <button onClick={onClick} className="inviteButton">
          邀請/新增
        </button>
      </Wrapper>
    );
  }
}

InviteButton.defaultProps = {
  suggestions: [],
  onSuggestionSelected: null,
  getSuggestionValue: null,
  renderSuggestion: (suggestion) => `${suggestion.name}, ${suggestion.email}`,
  inputProps: {
    placeholder: "",
    name: "",
    onChange: null,
  },
  value: "",
  onClick: null
};

export default InviteButton;
