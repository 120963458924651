
import * as loadImage from 'blueimp-load-image'


export const rotateImage = (someRef) => {
    // console.log(someRef.current);
    let domObject = someRef.current;

    if ( domObject ) {
        var imgUrl = domObject.style.backgroundImage.split('"')[1];
        loadImage(imgUrl, 
            function(img, data) {
                // console.log(data);
                if ( data && data.exif) {
                    var imgOrien = data.exif.get("Orientation");
                    // console.log("Exif imgOrien: ", imgOrien); // requires exif extension
                    if ( imgOrien === 6 ) {
                        domObject.style.transform = "rotate(90deg)";
                    }
                }
            }, 
            {
                meta: true
            }
        );
    }

}