import { createAction } from 'redux-actions';
import { fetchObject, VALIDATE_ERROR_CODE } from './fetchModule';
import {
    SET_PRIVATECHAT_LIST,
    SET_PRIVATECHAT_INFO,
    SET_SOCKET_MESSAGE,
    SET_MESSAGE_UNREAD_COUNT,
    SAVE_MESSAGE_RES_TO_REDUX,
} from '../constants/actionTypes';
import { localStorageUtility } from '../utilities';

export const setPrivateChatList = createAction(SET_PRIVATECHAT_LIST);
export const setPrivateChatInfo = createAction(SET_PRIVATECHAT_INFO);
export const saveMessageResToRedux = createAction(SAVE_MESSAGE_RES_TO_REDUX);
export const setSocketMessage = createAction(SET_SOCKET_MESSAGE);
export const setMessageUnreadCount = createAction(SET_MESSAGE_UNREAD_COUNT);

let e;
export const messageListener = () => {
    return (dispatch, getState) => {
        console.log("message listener");
        // 兩個人一直聊天 personOne . personTwo 不會變
        e = event => {
            const privateChatInfoData = getState().messageReducer.privateChatInfo ;

            if(window.location.pathname.split('/').pop().split('=')[0] === 'user_id') {
                if( !event.user ){
                    return
                }
                let chatToUserIdInUrl = parseInt(window.location.pathname.split('/').pop().split('=')[1])
                let chatToUserIdFromSocket = event.user.id
                let myId = localStorageUtility('getAndParse', 'myData').id
                if ( chatToUserIdInUrl === chatToUserIdFromSocket ) {
                    // 前端的預防機制
                    if(myId !== chatToUserIdFromSocket){
                        dispatch(setSocketMessage(event));
                    }
                }
            }else{
                if(privateChatInfoData ){
                    // const currentUser = getState().myInfoReducer.userInfo;
                    // const currentOtherUser = getState().myInfoReducer.otherUserInfo;
                    // console.log(privateChatInfoData, privateChatInfoData.personOne, event);
                    if( !event.user ){
                        return
                    }
                    // reducer 有值
                    if (privateChatInfoData.personOne) {
                        if (event.privateChat === privateChatInfoData.id){
                            dispatch(setSocketMessage(event));
                        }
                    // 從來沒聊過天或 第一次聊天的時候，第一次聊天 privateChatInfoData 只有 message
                    } else {
                        if (event.privateChat === privateChatInfoData.id){
                            dispatch(setSocketMessage(event));
                        }
                    }
                }
            }

        };
        if (window.io !== undefined) {
            window.io.socket.on('privateChat', e);
        }
    };
};

export const messageCountListener = () => {
    return dispatch => {
        if (window.io !== undefined) {
            window.io.socket.on('privateChat', event =>{
                dispatch(setMessageUnreadCount(event));
            });
        }
    }
}

export const stopMessageListener = () => {
    console.log('message stop listener');
    return dispatch => {

        window.io.socket.off('privateChat', e);

    }
    // io.socket.off('message', e);


};

export const stopMessageCountListener = () => {
    console.log("message stop ");
    return dispatch => {

        window.io.socket.off('privateChat', event =>{
        });
        

    }
}

export const sendSelfMessageToRedux = (message)=>{
    return dispatch => {

        console.log('in send message to redux');
      
        dispatch(setSocketMessage(message));
    }
}


export const fetchPrivateChatList = userId => {
    return dispatch => {
        let api = 'privatechat/list';
        if (userId) api = 'privatechat/list?person=' + userId;
        fetchObject(api)
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                if (userId) {
                    if (json.data[0]) {
                        dispatch(setPrivateChatInfo(json.data[0]));
                    } else {
                        dispatch(setPrivateChatInfo([]));
                    }
                } else {
                    // 20190617 唯一會用到 setPrivateChatList 的地方
                    dispatch(setPrivateChatList(json.data));
                }
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    };
};

export const fetchPrivateChatInfo = id => {
    return dispatch => {
        fetchObject(`privatechat/${id}`)
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                dispatch(setPrivateChatInfo(json));
            })
            .catch(function(ex) {
                dispatch(setPrivateChatInfo([]));
                console.log('parsing failed', ex);
            });
    };
};

/**
 * @param {Object} data
 * @param {string} data.chatroom - Send a message to chatroom. [optional]
 * @param {string} data.to - Send a message to someone. [optional]
 * @param {string} data.message - message content.
 */
export const sendMessage = data => {
    return (dispatch, getState) => {
        let userObj = localStorageUtility('get', 'myData');
        let { token } = JSON.parse(userObj);
        fetchObject(`message/`, '', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { token }
        })
            .then(response => {
                return response.json();
                //throw new Error('Bad response from server');
            })
            .then(json => {
                if (json.code) {
                    // error block
                    throw new Error(VALIDATE_ERROR_CODE(json.code));
                }else{
                    dispatch(saveMessageResToRedux(json));
                }
            })
            .catch(err => {
                alert(err.message);
            });
    };
};

export const haveSeenPrivateChat = privateChatId => {
    return (dispatch) => {
        fetchObject(`privatechat/read/${privateChatId}`, '', {
            method: 'PUT',
        })
            .then(response => {
                // console.log(privateChatId);
                if (response.status === 200) {
                    return
                }
                return response.json();
            })
            .then(json => {
            })
            .catch(err => {
                console.log(err.message);
            });
    };
};
