
export const openExternalWebsiteUrl = (url, method) => {
    // open web by RN
    if(window.ReactNativeWebView){
        const linkContent = {
            type: 'openExternalUrl',
            url: url,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(linkContent));
    }else{
        switch (method) {
            case 'windowOpen':                
                window.open(url)
                break;
            default:
                break;
        }
    }
}
