import React, { Component } from "react";
import { connect } from 'react-redux';
import { Wrapper } from "./styled";
import { fetchPrivateChatList, getNotification } from '../../actions';
import { GaSetting } from "../Common";
import { localStorageUtility } from '../../utilities';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginIn: false,
            notAndroid: false,
        };
    }
    componentDidMount() {
        const userInfo = localStorageUtility('getAndParse', 'myData');

        if (userInfo && userInfo.id && userInfo.token) {
            this.setState({
                loginIn: userInfo && true
            });
            // console.log(" will call get notification in footer");
            this.props.getNotification();
            this.props.getPrivateChatList();
        }

        if (navigator.userAgent.indexOf("Android") <= -1) {
            this.setState({
                notAndroid: true
            })
        }
    }
    render() {
        const { loginIn, notAndroid } = this.state;
        const { data } = this.props;
        const unreadNotice = data.notice.filter(n => !n.read);

        var unread = 0;
        if (loginIn) {
            const { message } = this.props;
            const { id } = localStorageUtility('getAndParse', 'myData');

            // 有從 socket 接到未讀訊息數量，就完全以 socket 的數量為主
            if (this.props.messageUnreadCount || this.props.messageUnreadCount === 0){
                unread += this.props.messageUnreadCount
            } else{
                // privateChatList，在沒有開啟 socket 的時候需要
                message &&
                message.forEach(m => {
                    const me = m.personOne && m.personOne.id === id;
                    unread += me ? m.personOneUnread : m.personTwoUnread;
                })
            }

            if (this.props.notificationUnreadCount){
                unread += this.props.notificationUnreadCount
            } else{
                unread += unreadNotice.length;
            }

        }
        return (
            <Wrapper id="footer" displayOnMobile={this.props.mobile}>
                <div className={notAndroid ? 'nav' : 'nav android'}>
                    { notAndroid === false &&
                        <GaSetting
                            key = "footerLink_3"
                            data = {{
                                className: 'footerLink',
                                category: 'footer-badminton',
                                action: 'footer-rent-click',
                                label: '',
                                tag: 'link',
                                path: '/',
                            }}
                            >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_home.png" alt="首頁"/>
                        </GaSetting>
                    }
                    <GaSetting 
                        key = "footerLink_0"
                        data = {{
                            className: 'footerLink',
                            category: 'footer-badminton', 
                            action: 'footer-sign-click', 
                            label: '', 
                            tag: 'link',
                            path: '/activity_list',
                            disabled: this.props.disableActivitySignUpButton,
                        }}
                    >
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_activitySignUp.png" alt="臨打報名"/>
                    </GaSetting>
                    <GaSetting 
                        key = "footerLink_1"
                        data = {{
                            className: 'footerLink',
                            category: 'footer-badminton', 
                            action: 'footer-activity-click', 
                            label: '', 
                            tag: 'link',
                            path: '/create_activity',
                        }}
                    >
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_newGroup.png" alt="開團找我"/>
                    </GaSetting>
                    <GaSetting 
                        key = "footerLink_2"
                        data = {{
                            className: 'footerLink',
                            category: 'footer-badminton', 
                            action: 'footer-know-click', 
                            label: '', 
                            tag: 'button',
                            target: '_bank',
                            path: 'https://www.bonny-live.com/WebDocument/SportCategory',
                        }}
                    >
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_knowHow.png" alt="羽球知識家"/>
                    </GaSetting>
                    {notAndroid &&
                        <GaSetting 
                            key = "footerLink_3"
                            data = {{
                                className: 'footerLink',
                                category: 'footer-badminton', 
                                action: 'footer-live-click', 
                                label: '', 
                                tag: 'button',
                                target: '_bank',
                                path: 'https://www.bonny-live.com/Show/List',
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_hotLive.png" alt="Live直播"/>
                        </GaSetting>
                    }
                    {loginIn ? (
                        <div className="footerLink">
                            { 
                                unread > 0 ? 
                                ( 
                                    <div className="msg_count" >
                                        {
                                            unread < 100 ?
                                                unread
                                            :
                                                `99+`
                                        }
                                    </div> 
                                ) 
                                :
                                null
                            }
                                <GaSetting 
                                    key = "footerLink_4"
                                    data = {{
                                        className: '',
                                        category: 'footer-badminton', 
                                        action: 'footer-myInfo-click', 
                                        label: '', 
                                        tag: 'link',
                                        path: '/my_info',
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_myBonny.png" alt="MyBonny"/>
                                </GaSetting>
                        </div>
                    ) : (
                            <GaSetting 
                                key = "footerLink_4"
                                data = {{
                                    className: 'footerLink',
                                    category: 'footer-badminton', 
                                    action: 'footer-myInfo-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/login',
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_myBonny.png" alt="MyBonny"/>
                            </GaSetting>
                        )}
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    message: state.messageReducer.privateChat,
    messageUnreadCount: state.messageReducer.privateChatUnreadCount,
    data: state.notificationReducer,
    notificationUnreadCount: state.notificationReducer.notificationUnreadCount,
    disableActivitySignUpButton: state.activity.disableActivitySignUpButton,
});
const mapDispatchToProps = {
    getPrivateChatList: fetchPrivateChatList,
    getNotification: getNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
