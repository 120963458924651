import styled from "styled-components";

export const Wrapper = styled.div`
    #chat-widget-container.displayNone {
        display:none;
    }
    background-color: #000;
    background-image: ${props =>
        !props.isIndex && "url('/assets/images/bg-inner.jpg')"};
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: auto;
    @media (max-width: 480px) {
        background: ${props =>
            !props.isIndex && "url('/assets/images/bg-inner.jpg')"};
        background-repeat: no-repeat;
        background-size: contain;
    }

    .index-slide {
        position: relative;
        width: 100vw;
        height: calc( 100vh - 177px);
        // padding-bottom: 56.25%; /* 16:9 */
        // @media (max-width: 480px){
        //     height: calc( 100vh - 287px );
        // }

        @media (min-width: 641px) and (max-width: 1279px){
            height: calc( 100vh - 157px );
        }
        @media (max-width: 640px) {
            height: calc( 100vh - 127px );
        }
        @media (max-width: 380px) {
            height: calc( 100vh - 117px );
        }
        // @media (max-width: 767px) {
        //     height: calc( 100vh - 127px );
        // }

        img {
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            z-index: -1;
            @media (min-width: 1280px) {
                height: calc(100vh - 177px);
            }
            @media (min-width: 641px) and (max-width: 1279px){
            height: calc( 100vh - 157px );
            }
            @media (max-width: 640px) {
                height: calc( 100vh - 127px );
            }
            @media (max-width: 380px) {
                height: calc( 100vh - 117px );
            }
        }

        .slide__caption {
            bottom: 0;
            min-height: 80px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 2;
        }
    }

`;
