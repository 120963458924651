import React, { Component } from "react";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Wrapper } from './styled';
import { createActivityPaymentOrder , getPaymentResult } from "../../actions";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { LoadingIndicator } from "../Common";

class ActivityPaymentResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentNumber: this.props.match && this.props.match.params && this.props.match.params.paymentNumber,
            isSendPaymentClick: false,
        };
    } 

    componentDidMount() {
        this.props.getPaymentResult(this.state.paymentNumber);
    }

    componentDidUpdate(prevProps, prevState){
        if (
            this.state.isSendPaymentClick === true 
            && 
            this.props.isGetPaymentRes === true
        ) {
            document.getElementById("purchase_form").submit();
        }
    }

    sendPayment = (e) => {
      this.setState({isSendPaymentClick: true});
      this.props.createActivityPaymentOrder(e);
    }

    render() {
        const { paymentResultData } = this.props;
        const activityId = paymentResultData && paymentResultData.activityId;
        const joinId = paymentResultData && paymentResultData.joinId;
        const result = paymentResultData && paymentResultData.result;

        return (
            <Wrapper key={0}>
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 付款完成/未完成
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />
                { paymentResultData.status === 1 &&
                    <div id="program_done">
                        <div className="title">
                            <h2>付款成功通知</h2>
                        </div>
                        <div className="sub_style">
                            本次活動報名，線上付款已完成！
                            <br/>交易訊息如下
                        </div>
                        <ul>
                            <li>活動名稱：
                                <span>{activityId.group_name}</span>
                            </li>
                            <li>活動位置：
                                <span>{activityId.place_name}</span>
                            </li>
                            <li>詳細地址：
                                <span>{activityId.city}{activityId.area}{activityId.address}</span>
                            </li>
                            <li>打球日期：
                                <span>{moment(parseInt(activityId.start)).format('YYYY年MM月DD日')}</span>
                            </li>
                            <li>打球時間：
                                <span>{moment(parseInt(activityId.start)).format('hh:mm')} 至 {moment(parseInt(activityId.end)).format('hh:mm')}</span>
                            </li>
                            <li>交易編號：
                                <span>{paymentResultData.paymentNumber}</span>
                            </li>
                            <li>付款時間：
                                <span>{moment(parseInt(paymentResultData.time)).format('YYYY年MM月DD日')}</span>
                            </li>
                            <li>付款方式：
                                <span>{result.PaymentType === `Credit_CreditCard` ? `信用卡` : ``}</span>
                            </li>
                            <li>付款金額：
                                <span>{paymentResultData.amount} 元</span>
                            </li>
                            <br/>
                            <li>
                                <Link className="button-style" to={`../my_info/participated_activity_detail/` + activityId.id + `/` + joinId.id}>
                                    轉往活動詳細頁
                                </Link>
                            </li>
                        </ul>
                    </div>
                }

                { paymentResultData.status === 0 &&
                <div id="program_fail" >
                    <div className="title">
                        <h2>交易失敗通知</h2>
                    </div>
                    <div className="sub_style">
                        <p>訊息代碼 <span>{result.RtnCode ? result.RtnCode : '無失敗代碼'}</span></p>
                    </div>
                    <br/>
                    <div className="sub_style">請點選重新付款鈕重新付款！</div>

                    <ul>
                        <li>
                            <button className="button-style" 
                                onClick={(e) => {
                                    this.sendPayment({
                                        activityId: parseInt(activityId.id),
                                        joinId: parseInt(joinId.id),
                                        paymentType: `CreditEcpay`,
                                    },e)
                            }}>
                                重新付款</button>
                        </li>
                    </ul>
                </div>
            }           
            </Wrapper>
        );
        
    }
}

const mapStateToProps = state => ({
    loading: state.groupDetailReducer.shouldLoadingOpen,
    paymentResultData: state.groupDetailReducer.paymentResult,
    isGetPaymentRes: state.groupDetailReducer.isGetPaymentRes,
});

const mapDispatchToProps = ({
    createActivityPaymentOrder: createActivityPaymentOrder,
    getPaymentResult: getPaymentResult,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityPaymentResult));
