import styled from "styled-components";

export const Wrapper = styled.div `
    select {
        width: 100%;
    }
    p {
        color: #7b7b7b;
        margin-top: 10px;
        font-size: 14px;
    }

    .flex{
        display: flex;
    }

    .alignCenter{
        align-items: center;
    }

    .mobileVerifyButton{
        height: 37px;
        width: calc( 14px * 8 );
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #fff;
        font-size: 14px;
        border-radius: 0 5px 5px 0;
        background-color: #DF1C2C;

        &:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }

    .mobileVerifySubmit{
        height: 37px;
        width: calc( 14px * 6 );
        margin-left: 5px;
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #fff;
        font-size: 14px;
        border-radius: 5px;
        background-color: #2f82bf;

        &:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }

    .mobileVerifyCancel{
        height: 37px;
        width: auto;
        margin-left: 10px;
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #000;
        font-size: 12px;
        text-decoration: underline;

        &:hover{
            color:#DF1C2C;
        }
    }
`;

export const TimePickerWrapper = styled.div `
    select {
        width: calc(50% - 4.5px);
        &:first-child {
            margin-right: 5px;
        }
    }
`;

export const LabelWrapper = styled.div `
    button {
        width: 31%;
        margin: 10px 1% 10px;
        background: none;
        border: 2px solid #666;
        border-radius: 20px;
        padding: 10px 0;
        cursor: pointer;
        /* &:hover, */
        &.active {
            border: 2px solid #da263c;
            color: #da263c;
            font-weight: 500;
        }
    }
`;
