import styled from "styled-components";


export const Wrapper = styled.div`

width:100%;
margin-bottom: 40px;
@media (max-width: 480px) {
    margin-bottom: 30vh;
}

select{
    width:90%
};

font-size: 1rem;
padding: 10px 0;

.div {
    width:100%; 
    padding:0; 
    position:relative;
}



.text {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    text-decoration:none;
    margin-left:5%;
    padding: .5rem 0;
    cursor: pointer;
    position:relative;
    @media (max-width: 480px){
       font-size: 1rem; 
    }
    .notice {
        display: flex;
        vertical-align: middle;
        background-color: #da263c;
        border-radius: 999rem;
        color: #fff;
        font-size: 10px;
        width: 1.8rem;
        height: 1.8rem;
        // line-height: 1.8rem;
        // padding: 2px 2px;
        // margin: auto 0 auto .5rem;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 6%;
        align-items:center; 
        justify-content:center;
    }
  }
  
i {
    color: #6a7382;
    position: absolute;
    right: 0px; 
    line-height: 2.2rem;
    margin-top: 0;
    margin-right: 5%;
    font-size: 2rem;
}

.hr {
    width: 90%;
    height: 1px;
    margin: 0 auto;
    border: 0px solid #ccc;
    border-top-width: 1px;
}



.Banner {
    position:relative;
}
.leftbutton, .rightbotton {
    position:absolute;
}



  `;


