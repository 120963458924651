
export const shareToFacebook = (title, url, description, picture) => {

    // console.log(`${String(`fb://facewebmodal/f?href=https://www.facebook.com/sharer/sharer.php?u=${url}${title}${description ? description : '' }${picture ? picture : '' }`)}`);

    if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.open(`${String(`fb://facewebmodal/f?href=https://www.facebook.com/sharer/sharer.php?u=${url}${title}${description ? description : null }${picture ? picture : null }`)}`);
        // https://www.facebook.com/sharer/sharer.php?u={Link}&quote={quote}&picture={aaa}&title={title}&description={description}&caption={caption}
    // 判斷是不是 iOS app, RN 才需要 PostMessage
    } else if(/iPhone/i.test(navigator.userAgent) && window.ReactNativeWebView){
        let shareContent = {
            type: 'FBshare',
            title: title.split('&title=')[1],
            url: encodeURIComponent(url),
        };
        description && (shareContent.description = description);
        picture && (shareContent.picture = picture);
        window.ReactNativeWebView.postMessage(JSON.stringify(shareContent));
    } else {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}${description}${picture}`);
    }
}