import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin-bottom: 40px;
    @media (max-width: 480px) {
        margin-bottom: 30vh;
    }
    button:disabled{
        background-color:grey;
    }
    .b1{
        text-align: center;
        width:100%
        background-color: #D5D5D5;
        border: 0 none
        padding-top: 10PX
        padding-bottom: 10PX
        font-size: large
        font-weight: bolder
        color: #fff
        border-radius: 5px
        font-weight: bolder
        img{
          padding-left: 5px;
          top: 5px;
          position: relative;
        }
    }

    #RacketDetail_program_content{
        width:calc( 100% - 40px );
        margin:auto;

        .title{}

        ul{
            padding:0;
            li{
                list-style:none;
                margin:2px 0;
                line-height:1.5rem;

                input[type="text"]{
                    width:calc( 100% - 14px );
                }

                input[type="text"].remark-input-style{
                    height:150px;
                }
            }
            .margin-top-5px{
                margin-top:5px;
            }
            .margin-top-20px{
                margin-top:20px;
            }
            .margin-bottom-20px{
                margin-bottom:20px;
            }
            .border-style{
                border: 2px solid #DF1C2C;

                .img-top{
                    width:100%;
                    height:199px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                }
                .img-bottom{
                    width:100%;
                    height:22px;
                    padding-top:5px;
                    padding-bottom:3px;
                    background-color:#DF1C2C;
                    color:#FFF;
                    text-align:center;
                    line-height:22px;
                    font-weight:bold;
                }
            }
            .button-group{
                margin-top:40px;
                margin-bottom:60px;
                display:flex;

                button{
                    text-align: center;
                    flex: 0 0 calc(50% - 10px);
                    margin: 5px;
                    background-color: #DF1C2C;
                    border: 0 none;
                    padding: 10PX;
                    font-size: large;
                    font-weight: 500;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                }
                .button-cancel{
                    background-color:#aaa;
                }
            }
            .button-only-one{
                margin-top:40px;
                margin-bottom:60px;
                display:flex;

                button{
                    flex:1;
                    text-align: center;
                    width:100%;
                    margin: auto;
                    background-color: #DF1C2C;
                    border: 0 none;
                    padding: 10PX;
                    font-size: large;
                    font-weight: 500;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                }

                .button{
                    flex:1;
                    text-align: center;
                    width:100%;
                    margin: auto;
                    background-color: #DF1C2C;
                    border: 0 none;
                    padding: 10PX;
                    font-size: large;
                    font-weight: 500;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                }
            }
            .display-flex-mom{
                display:flex;

                .list-content-flex-son{
                    flex: 0 0 calc( 50% - 10px );
                }
                .margin-left-5px{
                    margin-left:5px;
                }
                .margin-right-5px{
                    margin-right:5px;
                }
            }
        }

    }
    .clear{
        clear:both;
    }
    .hide{
        display:none;
    }
    .button-blue-oval{
        padding:8px 0;
        margin-top:10px;
        border-radius:999rem;
        border:1px solid #3b84b7;
        width:100px;
        line-height:20px;
        color:#3b84b7;
        font-weight:bold;
        font-size:14px;
        float:right;
        cursor:pointer;
    }
    .button-blue-oval:hover{
        background-color:#3b84b7;
        color:#FFF;
    }
    .color-red{
        color:red;
    }
    .color-LightBlack{
        color:#757575;
    }
    .font-weight-bold{
        font-weight:bold;
    }
    .margin-left-20px{
        margin-left:20px;
    }
    .margin-top-5px{
        margin-top:5px;
    }
    .margin-top-20px{
        margin-top:20px;
    }
    .margin-bottom-20px{
        margin-bottom:20px;
    }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow-y:auto;
            border-radius: 20px;
            outline: none;
            margin:auto;

            .lightBox-explain-Content-inside{
                padding: 20px;

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#FFF;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description > ul{
                    padding:0;

                    li{
                        list-style-type:none;
                    }
                }

                .LB-C-Description > ul.list-style-type-number{
                    li{
                        list-style-position: inside;
                        list-style-type: decimal;
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }

            .light-outside-button-group{
                width:100%;
                height:37px;
                display:flex;
                text-align:center;
                line-height:37px;
                font-size:20px;
                padding-bottom:8px;

                .light-outside-button-group-check{
                    flex:0 0 calc( 50% - 0.5px);
                    cursor:pointer;
                }
                .light-outside-button-group-line{
                    flex:0 0 1px;
                    border:none;
                    margin:0;
                    padding:0;
                    border-left:1px solid #000;
                }
                .light-outside-button-group-close{
                    flex:0 0 calc( 50% - 0.5px);
                    cursor:pointer;
                }
            }
        }
`
