import React from 'react';
import { connect } from 'react-redux';
import { uploadAvatar, uploadBackground, fetchUserInfo } from '../../../actions';
import { ImageUploader, LoadingIndicator } from '../../Common';
import { Wrapper } from './styled';

class Banner extends React.Component {
    componentDidMount() {
        this.props.getUserInfo();
    }

    onCheckSkip() {
        return !!this.props.uploadStatus;
    }
   
    onFileChange(file, isBackground) {
        if (file) {
            // open loading icon 
            isBackground ? 
            document.querySelectorAll('#Loading')[0].classList.remove('close') 
            :
            document.querySelectorAll('#Loading')[1].classList.remove('close') 
            
            this.props[isBackground ? 'uploadBackground' : 'uploadAvatar'](file)
            .then(result => {
                result && this.props.getUserInfo()
                // close loading icon
                isBackground ? 
                document.querySelectorAll('#Loading')[0].classList.add('close') 
                :
                document.querySelectorAll('#Loading')[1].classList.add('close')
            });
        }
    }

    render() {
        const { data, user, disabledUpload } = this.props;
        let target = user || data || {};
        return (
            <Wrapper>
                <div
                    className="background"
                    style={{ backgroundImage: `url(${target.background || '/assets/images/DefaultBgimg.jpg'})` }}
                >
                    {!disabledUpload && 
                        <div className="icon_bg">
                            <ImageUploader
                                isWrapMode
                                needSkip={() => this.onCheckSkip()}
                                onChange={file => this.onFileChange(file, true)}
                            >
                                <i className="material-icons">camera_alt</i>
                            </ImageUploader>
                        </div>
                    }
                </div>
                <LoadingIndicator
                    shouldLittleIcon={true}
                    isCoverPhoto = {true}
                />
                
                
                <div className="userInfo">
                {!disabledUpload ? 
                    <ImageUploader
                        isWrapMode
                        needSkip={() => this.onCheckSkip()}
                        onChange={file => this.onFileChange(file)}
                    >
                        <div className="image_Cropper">
                            <div className="image_Cropper_Photo" style={{backgroundImage: `url(${target.avatar || '/assets/images/DefaultUserImg.png'})`}}>
                            </div>
                            <LoadingIndicator
                                shouldLittleIcon={true}
                                isAvatarPhoto = {true}
                            />
                            <div className="icon_avatar">
                                <i className="material-icons icon2">camera_alt</i>
                            </div>
                        </div>
                        <p className="userName">{target.name}</p>
                    </ImageUploader>
                    :
                    <div>
                        <div className="image_Cropper">
                            <div className="image_Cropper_Photo" style={{backgroundImage: `url(${target.avatar || '/assets/images/DefaultUserImg.png'})`}}>
                            </div>
                        </div>
                        <p className="userName">{target.name}</p>
                    </div>
                }
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    uploadStatus: state.imageUploadReducer.uploadStatus,
    data: state.myInfoReducer.userInfo
});

const mapDispatchToProps = {
    uploadAvatar,
    uploadBackground,
    getUserInfo: fetchUserInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
