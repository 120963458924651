import moment from 'moment';
const env = process.env;
export const nodeEnv = env.REACT_APP_STAGE || env.NODE_ENV || 'development';
//export const nodeEnv = env.NODE_ENV || 'development';

export default {
  // port: env.PORT || 1337,
  // https://api.bonny-badminton.com.tw
  // host: env.HOST || nodeEnv == 'development'? 'api.bonnylive.biz': 'bonny-badminton.tw',
  // host: env.HOST || nodeEnv === 'local' ? 'api.bonnylive.biz': 'api.bonny-badminton.tw',
  // host:env.HOST || nodeEnv === 'production' ? 'api.bonny-badminton.tw': 'api.bonnylive.biz',

  // host:env.HOST || nodeEnv === 'development' ? 'http://192.168.10.76:1337' : 'https://api.bonny-badminton.tw',
  host: env.HOST || nodeEnv === 'development' ? 'https://api.bonnylive.biz' : 'https://api.bonny-badminton.tw',
  source: env.HOST || nodeEnv === 'development' ? '068a20b9208f85d67a5a3de8221f87cee7dac31e80' : '4b5d33b99338bee0a589d94e6eed299328425e4269ada42cc779bdfa69d0',
  facebook: env.HOST || nodeEnv === 'development' ? '312080846292103' : '808073912721704',
  facebookURL: env.HOST || nodeEnv === 'development' ? 'https://staging.bonnylive.biz/login' : 'https://www.bonny-badminton.tw/login',
  shouldFBLoginHidden: window.ReactNativeWebView ? true : false,

  mascotDemoUrl: 'mascotDemo',
  isMascot: window.location.pathname.indexOf('mascotDemo') > -1 ? true : true,
  isBonnyStar: moment("20200814").valueOf() < moment().valueOf(),
  //isBonnyStar: true,
  socketUrl: env.HOST || nodeEnv === 'development' ? 'https://admin.bonnylive.biz' : 'https://api.bonny-badminton.com.tw',

  googleMapKey: "AIzaSyBvn1xuekv-8NxCc9Y-NtoQjE6Fk_eEwu4", //ByBonnyFeature
  // googleMapKey: "AIzaSyD3OkqTsjWvjcBLnokcsil3I23rZvLYl5g",
  // googleMapKeyByHanfour:"AIzaSyCQ8FyJ19tRx8AsmycwTlbaOzDkK1mLwqs"
  // googleMapKeyByBonnyFeature:"AIzaSyBIZ9IaUTtqjEovFfPV6FF9uqWb5q_OkLY"
  rentLength: env.HOST || nodeEnv === 'development' ? '999' : '2',
  gaId: env.HOST || nodeEnv === 'development' ? 'UA-109282609-5' : 'UA-109282609-4',
  oneSignalAppId: env.HOST || nodeEnv === 'development' ? '6f9609e1-8e68-43a3-a365-f2ff02ba1b65' : '97b69e85-9bb6-4686-8972-a7fd8d5bdf7a',

  campusAmbassador: true,
  get serverUrl() {
    return `${this.host}`
    // return `https://${this.host}:${this.port}`;
  }
};
