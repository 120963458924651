import { handleActions } from "redux-actions";
const initialState = {
    notifFetchTime: null,
    notifiedActivity: null,
    activity: [],
    activityType: '',
    banner_activity: [],
    banner_frontpage: [],
    banner_media: [],
    query: {},
    hostActivityQuery:{},
    loading: false,
    allNotificationActivity: [],
    userContinuousActivity: [],
    putjoinpaidStatus: '',
    totalActivity: 0,
    disableActivitySignUpButton: false,
};
const activityReducer = handleActions(
    {
        FETCHING_DATA: (state, {payload}) => {
            //console.log(payload)
            return { ...state, loading: payload.loading }
        },
        FETCHING_PUT_JOIN_PAID: (state, { payload }) => {
            return { ...state, putjoinpaidStatus: payload.putjoinpaidStatus }
        },
        // joinActivity and hostActivity modify activity and activityType 
        FETCH_ACTIVITY_DATA: (state, { payload }) => {
            // console.log("payload is:", payload);
            return {
                ...state,
                activity: payload.activity,
                activityType: payload.activityType,
                totalActivity: payload.total,
                loading: false
            };
        },
        UPDATE_ACTIVITYSIGNUP_BUTTON:(state, { payload }) => {
            return { ...state, disableActivitySignUpButton: payload.status }
        },
        // allNotification modify activityType and allNotificationActivity
        // so, activityType will be replaced situationly
        FETCH_ALL_NOTIFICATION_DATA: (state, { payload }) => {
            // console.log("payload in allNotification:", payload);
            return {
                ...state,
                userContinuousActivity: payload.userContinuousActivity,
                activityType: payload.activityType,
                allNotificationActivity: payload.allNotificationActivity,
                loading: false
            };
        },

        GET_BANNER_LIST: (state, { payload }) => {
            const { type } = payload;
            return {
                ...state,
                // Update our Post object with a new "comments" array
                ["banner_" + type]: payload.banner
            };
            // return { ...state, payload };
        },
        UPDATE_SEARCH_QUERY: (state, { payload }) => {
            return { ...state, query: payload };
        },
        UPDATE_SEARCH_QUERY_HOST_ACTIVITY: (state, { payload }) => {
            return { ...state, hostActivityQuery: payload };
        },
        POST_LEAVE_ACTIVITY: (state, { payload }) => {
            return { ...state };
        },
        ACTIVITY_UPDATE_NOTIF_ACTIVITY: (state, { payload }) => {
            return { ...state, notifiedActivity: payload };
        },
        ACTIVITY_UPDATE_NOTIF_FETCH_TIME: (state, { payload }) => {
            return { ...state, notifFetchTime: payload };
        },
        RESET_ACTIVITY_DATA: (state, { payload }) => {
            return { ...state, activity: [] };
        },
    },
    initialState
);

export default activityReducer;
