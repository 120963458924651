import React, { Component } from "react";
import moment from 'moment'
import { Wrapper } from "./styled";
import { FollowButton } from "../../../../Common";

class MemberInfo extends Component {

    render() {
      let { 
        id,
        name,
        avatar,
        registeredAt, 
      } = this.props; 
      // console.log(this.props)
      avatar = avatar || "/assets/images/DefaultUserImg.png"
      
      return (
        <Wrapper>
          {/*<img src={`${avatar}`} alt="user avatar"/>*/}
          <div className="avatar">
            <div className="avatar_photo" style={{ backgroundImage: `url(${avatar})` }}></div>
          </div>
          <div className="user-info">
            <div className="user-info-Data">
              <div>{name}</div>
              <div>{`報名時間：${moment.unix(registeredAt/1000).format("YYYY年MM月DD日")} 
              `}</div>
            </div>
            <FollowButton  onClick={this.props.follow} followList={this.props.followList} groupUserId={id} />
          </div>
        </Wrapper>
      )
    }
}

MemberInfo.defaultProps = {
  id: 0,
  name: "Name",
  avatar: "/assets/images/DefaultUserImg.png",
  registeredAt: 0,
  //registeredNumber: 0,
  onfollowClick:(id) =>{},
  follow:(id) =>{},
  followList:(id) =>{}
}

export default MemberInfo;
