import React, { Component } from "react";
import { GaSetting } from "../../../../Common";
import { Wrapper, GoToRent } from './styled';
import { getCookie } from '../../../../../utilities';

class LandingPageLayout extends Component {
    constructor(props) {
        super(props);
        const landingPageCode = getCookie('LandingPage');
        this.state= {
            commentAStyle:{
                comment1: 'show',
                comment2: '',
                comment3: ''
            },
            commentBStyle:{
                comment1: 'show',
                comment2: '',
                comment3: ''
            },
            commentCStyle:{
                comment1: 'show',
                comment2: '',
                comment3: ''
            },
            sliderAStyle:{
                img1: 'show',
                img2: '',
                img3: '',
            },
            sliderBStyle:{
                img1: 'show',
                img2: '',
                img3: '',
            },
            sliderCStyle:{
                img1: 'show',
                img2: '',
                img3: '',
            },
            navAStyle: 'show',
            navBStyle: 'show',
            navCStyle: 'show',
            landingPageCode: landingPageCode,
            notAndroid: false,
        }
    }

    componentDidMount(){ 
        this.createSliderAInterval();
        this.createSliderBInterval();
        this.createSliderCInterval();

        if (navigator.userAgent.indexOf("Android") <= -1) {
            this.setState({
                notAndroid: true
            })
        }
    }

    componentWillUnmount(){
        this.unmount = true 
    }

    createSliderAInterval(){
        this.sliderAInterval = setInterval( this.sliderAChange.bind(this), 5000);
    }
    clearSliderAInterval(){
        clearInterval(this.sliderAInterval);
    }

    createSliderBInterval(){
        this.sliderBInterval = setInterval( this.sliderBChange.bind(this), 5000);
    }
    clearSliderBInterval(){
        clearInterval(this.sliderBInterval);
    }

    createSliderCInterval(){
        this.sliderCInterval = setInterval( this.sliderCChange.bind(this), 5000);
    }
    clearSliderCInterval(){
        clearInterval(this.sliderCInterval);
    }

    sliderAChange(){
        if(this.unmount){
            return
        }

        if(this.state.sliderAStyle.img1 === 'show'){
            this.setState({sliderAStyle:{ img2: 'show'} })
        }else if(this.state.sliderAStyle.img2 === 'show'){
            this.setState({sliderAStyle:{ img3: 'show'}})
        }else if(this.state.sliderAStyle.img3 === 'show'){
            this.setState({sliderAStyle:{ img1: 'show'}})
        }
        // setTimeout( this.sliderAChange.bind(this) , 1000)
    }

    sliderBChange(){
        if(this.unmount){
            return
        }

        if(this.state.sliderBStyle.img1 === 'show'){
            this.setState({sliderBStyle:{ img2: 'show'} })
        }else if(this.state.sliderBStyle.img2 === 'show'){
            this.setState({sliderBStyle:{ img3: 'show'}})
        }else if(this.state.sliderBStyle.img3 === 'show'){
            this.setState({sliderBStyle:{ img1: 'show'}})
        }
        // setTimeout( this.sliderBChange.bind(this) , 1000)
    }

    sliderCChange(){
        if(this.unmount){
            return
        }

        if(this.state.sliderCStyle.img1 === 'show'){
            this.setState({sliderCStyle:{ img2: 'show'} })
        }else if(this.state.sliderCStyle.img2 === 'show'){
            this.setState({sliderCStyle:{ img3: 'show'}})
        }else if(this.state.sliderCStyle.img3 === 'show'){
            this.setState({sliderCStyle:{ img1: 'show'}})
        }
        // setTimeout( this.sliderCChange.bind(this) , 1000)
    }

    hideElement = (e)=>{
        let content = document.querySelector(`#${e}`)

        if (content.style.display === 'block'){
            content.style.display = 'none';
        }
    }

    toggleShowElement = (e) => {
        let content = document.querySelector(`#${e}`)

        if (content.style.display === 'none' || content.style.display === ''){
            content.style.display = 'block';
        } else{
            content.style.display = 'none';
        }
    }

    render() {  
        return (
            <Wrapper key={0}>
                <div id="WRAPPER">
                    <div id="HEADER">
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/key.jpg" width="100%" alt=""/>
                    </div>
                {/* 1982II */}
                    {/* open */}
                    <div id="NAV" 
                        style={{cursor: 'pointer'}}
                        onClick={ ()=>{
                            this.toggleShowElement('CONTENT-A') 
                            this.state.navAStyle ? this.setState({navAStyle:''}) : this.setState({navAStyle:'show'})
                        }}
                    >
                        <GaSetting
                            data = {{ 
                                className: `${['nav_A', this.state.navAStyle].join(' ')}`,
                                category: '4D', 
                                action: 'Click', 
                                label: `4D_1982_Show_isType${this.state.landingPageCode}`,
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_A.jpg" width="100%" alt="" />
                        </GaSetting>
                    </div>
                    {/* Hide */}
                    <div id="NAV" 
                        style={{cursor: 'pointer'}}
                        onClick={ ()=>{
                            this.toggleShowElement('CONTENT-A') 
                            this.state.navAStyle ? this.setState({navAStyle:''}) : this.setState({navAStyle:'show'})
                        }}
                    >
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: 'Click', 
                                label: `4D_1982_Hide_TopButton_isType${this.state.landingPageCode}`,
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_A_2.jpg" width="100%" alt="" />
                        </GaSetting>
                    </div>
                    {/*展開內容*/}
                    <div id="CONTENT-A" className=''>
                        <div className="sliderA">
                            <img className={['sliderA__img1', this.state.sliderAStyle.img1].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_AH_1.jpg" width="100%" alt=""/>
                            <img className={['sliderA__img2', this.state.sliderAStyle.img2].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_AH_2.jpg" width="100%" alt=""/>
                            <img className={['sliderA__img3', this.state.sliderAStyle.img3].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_AH_3.jpg" width="100%" alt=""/>
                        </div>
                        <div className="PAGE-A">
                            <p>
                                <span 
                                    className={ this.state.sliderAStyle.img1 }
                                    style={{cursor: 'pointer'}} 
                                    onClick={ ()=>{
                                        this.setState({sliderAStyle:{ img1: 'show'} }) 
                                        this.clearSliderAInterval()
                                        this.createSliderAInterval()
                                    }}
                                > 
                                    1
                                </span>
                                <span
                                    className={ this.state.sliderAStyle.img2 }
                                    style={{cursor: 'pointer'}} 
                                    onClick={ ()=>{
                                        this.setState({sliderAStyle:{ img2: 'show'} }) 
                                        this.clearSliderAInterval()
                                        this.createSliderAInterval()
                                    }}
                                >
                                    2
                                </span>
                                <span 
                                    className={ this.state.sliderAStyle.img3 }
                                    style={{cursor: 'pointer'}} 
                                    onClick={ ()=>{
                                        this.setState({sliderAStyle:{ img3: 'show'} }) 
                                        this.clearSliderAInterval()
                                        this.createSliderAInterval()
                                    }}
                                >
                                    3
                                </span>
                            </p>
                        </div>
                        <p>
                            拍框材質：高剛性碳纖維+T頭+高分子填充+烏缺<br />
                            拍框類型：窄邊小平頭<br />
                            中管材質：高彈性中管+烏缺<br />
                            中管軟硬度：M<br />
                            球拍長度：675 ±1mm（中管212mm）<br />
                            平衡：295±0mm(未穿線)<br />
                            柄號：2#/G4（86mm）<br />
                            最高磅數：30磅<br />
                            重量（未穿線）：3U / 4U 兩款<br />
                            球拍屬性：攻擊型<br />
                            成型方式：三次成型<br />
                        </p>
                        <div id="WRAPPER2">
                            {this.state.notAndroid &&
                                <div className="iframe-16x9">
                                    <iframe 
                                        title="Bonny烏缺系列 1982_II" 
                                        width="100%" 
                                        src="https://www.youtube.com/embed/3le4fFd9B9Q" 
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ margin: '0 auto' }}
                                    ></iframe>
                                </div>
                            }
                            {this.state.notAndroid && <br /> }
                            <img className={["comment", this.state.commentAStyle.comment1].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_A_1.jpg" width="100%" alt=""/>
                            <img className={["comment", this.state.commentAStyle.comment2].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_A_2.jpg" width="100%" alt=""/>
                            <img className={["comment", this.state.commentAStyle.comment3].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_A_3.jpg" width="100%" alt=""/>
                            <div className="page">
                                <div className="PAGE-A">
                                    <p>
                                        <span 
                                            style={{cursor: 'pointer'}} 
                                            onClick={ ()=>{this.setState({commentAStyle:{comment1:'show'}})}}
                                        >
                                            1
                                        </span>
                                        <span 
                                            style={{cursor: 'pointer'}} 
                                            onClick={ ()=>{this.setState({commentAStyle:{comment2:'show'}})}}
                                        >
                                            2
                                        </span>
                                        <span 
                                            style={{cursor: 'pointer'}} 
                                            onClick={ ()=>{this.setState({commentAStyle:{comment3:'show'}})}}
                                        >
                                            3
                                        </span>
                                    </p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: '4D_Shoppingcart_Click_at1982', 
                                label: `isType${this.state.landingPageCode}`,
                                tag: 'link',
                                path: { 
                                    pathname: "/rent",
                                    state: {
                                        "level": 'high',
                                    }
                                },
                            }}
                        >
                            <GoToRent>
                                <h5>前往申請</h5>
                            </GoToRent>
                        </GaSetting>
                        <div 
                            style={{cursor: 'pointer'}}
                            onClick={ ()=>{
                                this.hideElement('CONTENT-A') 
                                this.setState({navAStyle:'show'}) 
                            }} 
                        >
                            <GaSetting
                                data = {{ 
                                    category: '4D', 
                                    action: 'Click', 
                                    label: `4D_1982_Hide_BottomButton_isType${this.state.landingPageCode}`,
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/btn_close.png" width="100%" alt="" />
                            </GaSetting>
                        </div>
                    </div>
                {/* InFinity */}
                    {/* open  */}
                    <div id="NAV" 
                        style={{cursor: 'pointer'}}
                        onClick={ ()=>{
                            this.toggleShowElement('CONTENT-B')
                            this.state.navBStyle ? this.setState({navBStyle:''}) : this.setState({navBStyle:'show'})
                        }}
                    >
                        <GaSetting
                            data = {{ 
                                className: `${['nav_B', this.state.navBStyle].join(' ')}`,
                                category: '4D', 
                                action: 'Click', 
                                label: `4D_Infinity_Show_isType${this.state.landingPageCode}`,
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_B.jpg" width="100%" alt="" />
                        </GaSetting>
                    </div>
                    {/* Hide */}
                    <div id="NAV" 
                        style={{cursor: 'pointer'}}
                        onClick={ ()=>{
                            this.toggleShowElement('CONTENT-B')
                            this.state.navBStyle ? this.setState({navBStyle:''}) : this.setState({navBStyle:'show'})
                        }}
                    >
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: 'Click', 
                                label: `4D_Infinity_Hide_TopButton_isType${this.state.landingPageCode}`,
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_B_2.jpg" width="100%" alt="" />
                        </GaSetting>
                    </div>
                    {/*展開內容*/}
                    <div id="CONTENT-B">
                        <div className="sliderB">
                            <img className={['sliderB__img1', this.state.sliderBStyle.img1].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_BH_1.jpg" width="100%" alt=""/>
                            <img className={['sliderB__img2', this.state.sliderBStyle.img2].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_BH_2.jpg" width="100%" alt=""/>
                            <img className={['sliderB__img3', this.state.sliderBStyle.img3].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_BH_3.jpg" width="100%" alt=""/>
                        </div>
                        <div className="PAGE-B">
                            <p>
                                <span 
                                    className={ this.state.sliderBStyle.img1 }
                                    style={{cursor: 'pointer'}} onClick={ ()=>{
                                    this.setState({sliderBStyle:{ img1: 'show'} }) 
                                    this.clearSliderBInterval()
                                    this.createSliderBInterval()    
                                }}>1</span>
                                <span 
                                    className={ this.state.sliderBStyle.img2 }
                                    style={{cursor: 'pointer'}} onClick={ ()=>{
                                    this.setState({sliderBStyle:{ img2: 'show'} }) 
                                    this.clearSliderBInterval()
                                    this.createSliderBInterval()    
                                }}>2</span>
                                <span 
                                    className={ this.state.sliderBStyle.img3 }
                                    style={{cursor: 'pointer'}} onClick={ ()=>{
                                    this.setState({sliderBStyle:{ img3: 'show'} }) 
                                    this.clearSliderBInterval()
                                    this.createSliderBInterval()        
                                }}>3</span>
                            </p>
                        </div>
                        <p>拍框材質：高剛性碳纖維+烏缺+碳T頭+高分子填充<br />
                            拍框類型：中邊小平頭<br />
                            中管材質：高彈性中管+烏缺工藝<br />
                            中管軟硬度：M<br />
                            球拍長度：675 ±1mm（中管222mm）<br />
                            平衡：290±0mm<br />
                            柄號：2#/G4<br />
                            最高磅數：30磅<br />
                            重量（未穿線）：3U / 4U 兩款<br />
                            球拍屬性：攻擊拍<br />
                            成型方式：三次成型<br />
                        </p>
                        <div id="WRAPPER2">
                            {this.state.notAndroid &&
                                <div className="iframe-16x9">
                                    <iframe title="Bonny烏缺系列 Infinity_II" width="380" height="213" src="https://www.youtube.com/embed/2srzuL02dOc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ margin: '0 auto' }}></iframe>
                                </div>
                            }
                            {this.state.notAndroid && <br /> }
                            <img className={["comment", this.state.commentBStyle.comment1].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_B_1.jpg" width="100%" alt=""/>
                            <img className={["comment", this.state.commentBStyle.comment2].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_B_2.jpg" width="100%" alt=""/>
                            <img className={["comment", this.state.commentBStyle.comment3].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_B_3.jpg" width="100%" alt=""/>
                            <div className="page">
                                <div className="PAGE-A">
                                    <p>
                                        <span style={{cursor: 'pointer'}} onClick={ ()=>{this.setState({commentBStyle:{comment1:'show'}})}}>1</span>
                                        <span style={{cursor: 'pointer'}} onClick={ ()=>{this.setState({commentBStyle:{comment2:'show'}})}}>2</span>
                                        <span style={{cursor: 'pointer'}} onClick={ ()=>{this.setState({commentBStyle:{comment3:'show'}})}}>3</span>
                                    </p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: '4D_Shoppingcart_Click_atInfinity', 
                                label: `isType${this.state.landingPageCode}`,
                                tag: 'link',
                                path: { 
                                    pathname: "/rent",
                                    state: {
                                        "level": 'high',
                                    }
                                },
                            }}
                        >
                            <GoToRent>
                                <h5>前往申請</h5>
                            </GoToRent>
                        </GaSetting>
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={()=>{
                                this.hideElement('CONTENT-B')
                                this.setState({navBStyle:'show'})
                            }} 
                        >
                            <GaSetting
                                data = {{ 
                                    category: '4D', 
                                    action: 'Click', 
                                    label: `4D_Infinity_Hide_BottomButton_isType${this.state.landingPageCode}`,
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/btn_close.png" width="100%" alt="" />
                            </GaSetting>
                        </div>
                    </div>
                {/* PrincessII */}
                    {/* open */}
                    <div id="NAV" 
                        style={{cursor: 'pointer'}}
                        onClick={ ()=>{
                            this.toggleShowElement('CONTENT-C')
                            this.state.navCStyle ? this.setState({navCStyle:''}) : this.setState({navCStyle:'show'})
                        }}
                    >
                        <GaSetting
                            data = {{ 
                                className: `${['nav_C', this.state.navCStyle].join(' ')}`,
                                category: '4D', 
                                action: 'Click', 
                                label: `4D_Princess_Show_isType${this.state.landingPageCode}`,
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_C.jpg" width="100%" alt="" />
                        </GaSetting>
                    </div>
                    {/* Hide */}
                    <div id="NAV" 
                        style={{cursor: 'pointer'}}
                        onClick={ ()=>{
                            this.toggleShowElement('CONTENT-C')
                            this.state.navCStyle ? this.setState({navCStyle:''}) : this.setState({navCStyle:'show'})
                        }}
                    >
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: 'Click', 
                                label: `4D_Princess_Hide_TopButton_isType${this.state.landingPageCode}`,
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/nav_C_2.jpg" width="100%" alt="" />
                        </GaSetting>
                    </div>
                    {/*展開內容*/}
                    <div id="CONTENT-C">
                        <div className="sliderC">
                            <img className={['sliderC__img1', this.state.sliderCStyle.img1].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_CH_1.jpg" width="100%" alt=""/>
                            <img className={['sliderC__img2', this.state.sliderCStyle.img2].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_CH_2.jpg" width="100%" alt=""/>
                            <img className={['sliderC__img3', this.state.sliderCStyle.img3].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_CH_3.jpg" width="100%" alt=""/>
                        </div>
                        <div className="PAGE-A">
                            <p>
                                <span 
                                    className={ this.state.sliderCStyle.img1 }
                                    style={{cursor: 'pointer'}} onClick={ ()=>{
                                    this.setState({sliderCStyle:{ img1: 'show'} }) 
                                    this.clearSliderCInterval()
                                    this.createSliderCInterval()    
                                }}>1</span>
                                <span 
                                    className={ this.state.sliderCStyle.img2 }
                                    style={{cursor: 'pointer'}} onClick={ ()=>{
                                    this.setState({sliderCStyle:{ img2: 'show'} })
                                    this.clearSliderCInterval()
                                    this.createSliderCInterval()  
                                }}>2</span>
                                <span 
                                    className={ this.state.sliderCStyle.img3 }
                                    style={{cursor: 'pointer'}} onClick={ ()=>{
                                    this.setState({sliderCStyle:{ img3: 'show'} })
                                    this.clearSliderCInterval()
                                    this.createSliderCInterval()   
                                }}>3</span>
                            </p>
                        </div>
                        <p>拍框材質：超高剛性40T碳素纖維+烏缺工藝<br />
                            拍框類型：中邊小平頭<br />
                            中管材質：Woven高剛性中管+6.8高彈中管+烏缺<br />
                            中管軟硬度：M<br />
                            球拍長度：675±1mm（中管222mm）<br />
                            平衡：287±0mm(未穿線)<br />
                            柄號：2#/G4（86mm）<br />
                            最高磅數：30磅<br />
                            重量（未穿線）：3U / 4U 兩款<br />
                            球拍屬性：攻防型<br />
                            成型方式：二次成型<br />
                        </p>
                        <div id="WRAPPER2">
                            {this.state.notAndroid &&
                                <div className="iframe-16x9">
                                    <iframe title="Bonny烏缺系列 公主拍第二代" width="380" height="213" src="https://www.youtube.com/embed/PziODpntaho" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen style={{ margin: '0 auto' }}></iframe>
                                </div>
                            }
                            {this.state.notAndroid && <br /> }
                            <img className={["comment", this.state.commentCStyle.comment1].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_C_1.jpg" width="100%" alt=""/>
                            <img className={["comment", this.state.commentCStyle.comment2].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_C_2.jpg" width="100%" alt=""/>
                            <img className={["comment", this.state.commentCStyle.comment3].join(' ')} src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/comment_C_3.jpg" width="100%" alt=""/>
                            <div className="page">
                                <div className="PAGE-A">
                                    <p>
                                        <span style={{cursor: 'pointer'}} onClick={ ()=>{this.setState({commentCStyle:{comment1:'show'}})}}>1</span>
                                        <span style={{cursor: 'pointer'}} onClick={ ()=>{this.setState({commentCStyle:{comment2:'show'}})}}>2</span>
                                        <span style={{cursor: 'pointer'}} onClick={ ()=>{this.setState({commentCStyle:{comment3:'show'}})}}>3</span>
                                    </p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: '4D_Shoppingcart_Click_atPrincess', 
                                label: `isType${this.state.landingPageCode}`,
                                tag: 'link',
                                path: { 
                                    pathname: "/rent",
                                    state: {
                                        "level": 'high',
                                    }
                                },
                            }}
                        >
                            <GoToRent>
                                <h5>前往申請</h5>
                            </GoToRent>
                        </GaSetting>
                        <div
                            style={{cursor: 'pointer'}}
                            onClick={()=>{
                                this.hideElement('CONTENT-C')
                                this.setState({ navCStyle:'show'})
                            }} 
                        >
                            <GaSetting
                                data = {{ 
                                    category: '4D', 
                                    action: 'Click', 
                                    label: `4D_Princess_Hide_BottomButton_isType${this.state.landingPageCode}`,
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/btn_close.png" width="100%" alt="" />
                            </GaSetting>
                        </div>
                    </div>
                    { this.state.landingPageCode === 'B' ?
                        <GaSetting
                            data = {{ 
                                category: '4D', 
                                action: '4D_Shoppingcart_Click_Outside', 
                                label: `isType${this.state.landingPageCode}`,
                                tag: 'link',
                                path: { 
                                    pathname: "/rent",
                                    state: {
                                        "level": 'high',
                                    }
                                },
                            }}
                        >
                            <div className="shoppingCart">
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_1.jpg" width="100%" alt=""/>
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/shoppingcart.jpg" width="100%" alt=""/>
                            </div>
                        </GaSetting>
                    : "" }
                </div>
            </Wrapper>
        );
    }
}

export default LandingPageLayout;

