import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from './styled';
import {
	// updateSearch,
	updateSearchHostActivity,
	resetSearch,
	fetchActivity
} from "../../../actions";
import Banner from "../Banner";
import Tabs from './Tabs';
import { 
	ActList, 
	Card, 
	InputButton 
} from "../../Common";
// import Card from './Card';
// import { Modal } from './contract';
import { withRouter } from 'react-router'
// import socketIOClient  from "socket.io-client";
import { localStorageUtility } from '../../../utilities';

class HostActivity extends Component {
	constructor(props) {
		super(props)
		this.state = {
			search: props.activityReducerData.search || "",
			user: localStorageUtility('getAndParse', 'myData'),
			sort: '',
			status_end: '',
			shouldPaidHide: false,
			isShowAllButtonChecked: true,
			
		}
	}

	componentDidMount() {

		this.props.updateSearchHostActivity(
			{
				search: this.props.activityReducerData.search || "",
				user: localStorageUtility('getAndParse', 'myData'),
				sort: 'start desc',
				status_end: '',
			}
		);


		const user = localStorageUtility('getAndParse', 'myData');

		localStorageUtility('set', 'Tab', 'Opening');

		this.props.fetchActivity({ 
			'sort': 'start desc',
			'status_end': '>',
			user: user.id 
		})

	}


	copyActivity = (e) => {
		var id = e.target.attributes['data-id'].value;
		this.props.history.push(`/create_activity?id=${id}`)
	}

	renderCardPairs = (activityData) => {
		
		if( !activityData || !activityData.join ) return
		// status === true 表示有參加，paid === 0 表示未付款
		let joinUnpaidArray = []
		if (activityData.fee > 0){
			joinUnpaidArray = activityData.join.filter( e => e.paid === 0 && e.status === true )
		}

		let paymentStatus
		
		let paidFinishGroupStyle = ""
		let shouldPaidHideCardStyle = ""
		if(localStorageUtility('get', 'Tab') === "Finished"){
			paidFinishGroupStyle = joinUnpaidArray.length === 0 ? "paid" : ""
			shouldPaidHideCardStyle = this.state.shouldPaidHide && joinUnpaidArray.length === 0 ? "hidePaid" : ""
		}

		// 如果最便宜的付款方式是免費(0)
		if(activityData.fee === 0){
			if(activityData.join.filter( e => e.status === true).length > 0 ){
				paymentStatus = `參加者：${activityData.join.filter( e => e.status === true).length} 人`
			}else{
				paymentStatus = '尚無參加者'
			}
		}else{
			if(joinUnpaidArray.length>0){
				paymentStatus = `未付款：${joinUnpaidArray.length} 人`
			} else{
				if(activityData.join.filter(e=>e.status ===true).length === 0){
					paymentStatus = "尚無參加者"
				}else{
					paymentStatus = " 團員皆已付款"
				}
			}
		}

		// let paidOfflinePeople
		// let paidOnlinePeople
		// let paymentStatus = `only shows ${cardData.people}`
		// if (cardData.join){
		// 	// 1 是現金 2是信用卡，線下付款人數
		// 	paidOfflinePeople= cardData.join.filter( (e)=>{
		// 		e.paid === true && e.paymentTypeId === 1
		// 	} )

		// 	paidOnlinePeople= cardData.join.filter( (e)=>{
		// 		e.paid === true && e.paymentTypeId === 2
		// 	} )

		// 	paymentStatus = `付款：${paidOfflinePeople.length}/線下 ${paidOnlinePeople.length}/線上`
		// }

		return (
            <Wrapper key={activityData.id} >
                <div className={ `${shouldPaidHideCardStyle} ${paidFinishGroupStyle} `}>
                    <Card data={activityData} key={activityData.id} paymentStatus={paymentStatus} 
                        url={`/my_info/host_activity_detail/${activityData.id}`}>
                        <span className='btn1' data-id={activityData.id}
                            onClick={this.copyActivity} style={{ cursor: "pointer" }}>
                            {'複製'}
                        </span>
                    </Card>
                </div>
            </Wrapper>
        )
	}

	handleInputChange = e => {
		this.setState(
			{
				[e.target.name]: e.target.value
			}
		);
	};

	handleHidePaid = () =>{
		this.setState({ shouldPaidHide: true, isShowAllButtonChecked: false });
	}

	handleShowAll = () => {
		this.setState({ shouldPaidHide: false, isShowAllButtonChecked: true })
	}

	handleSubmit = () => {
		const user = localStorageUtility('getAndParse', 'myData');
		const Tab = localStorageUtility('get', 'Tab');
		if (Tab === 'Opening') {
			this.setState({
				user: user.id,
				sort: 'start desc',
				status_end: '>'
			}, () => {
				this.props.fetchActivity({
					search: this.state.search,
					user: this.state.user,
					sort: this.state.sort,
					status_end: this.state.status_end,
				})
			})
		} else {
			this.setState({
				user: user.id,
				sort: 'start desc',
				status_end: '<'
			}, () => {
				this.props.fetchActivity({
					search: this.state.search,
					user: this.state.user,
					sort: this.state.sort,
					status_end: this.state.status_end,
				})
			})
		}
	};

	render() {
		const { activityReducerData, url, updateSearchHostActivity, search, fetchActivity } = this.props;
		// console.log(activityReducerData.activity);
		return [
			<Banner key="0" />,
			<Wrapper key="1" style={{marginBottom: `120px`,}}>
				<div key="2" className="header">
					<h2> 我的開團 </h2>
					<InputButton
						input={{
							placeholder: "團名",
							name: 'search',
							value: search,
							onChange: this.handleInputChange,
						}}
						onClick={this.handleSubmit}
					/>
				</div>
				{/* 根據 sort 條件去 fetch */}
				<Tabs
					updateSearchHostActivity={updateSearchHostActivity}
					onFetchActivity={fetchActivity}
					shouldPaidHide ={this.state.shouldPaidHide}
				/>

				{/* paymentStatusFilter filter */}
				<div style={{display: `none`}} >
					<input 
						id="hidePaidRadioButton" 
						value="hidePaid"
						onClick={this.handleHidePaid}
						type="radio" name="paymentStatusFilter" 
						onChange= { ()=>{ /* I exist to fix the console warning */}}
					/>
					<span> 未收款</span>

					<input 
						id="showAllRadioButton" 
						value="showAll"
						onClick={this.handleShowAll} 
						checked={this.state.isShowAllButtonChecked} 
						type="radio" name="paymentStatusFilter" 
						onChange= { ()=>{ /* I exist to fix the console warning */}}
					/>
					<span> 全部</span>
				</div>

				{/* ActivityList */}
				<ActList key="3">
					{
						activityReducerData.activity && 
						activityReducerData.activity.length > 0 &&
						activityReducerData.activity.map((data, i) => this.renderCardPairs(data, url))
					}
				</ActList>
			</Wrapper>
		];
	}
}

HostActivity.defaultProps = {
	url: "https://www.bonny-badminton.com/x3hid2",
}

const mapStateToProps = state => ({
	loading: state.loading,
	activityReducerData: state.activity,
});

const mapDispatchToProps = {
	resetSearch: resetSearch,
	fetchActivity: fetchActivity,
	updateSearchHostActivity: updateSearchHostActivity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HostActivity));
