import React, { Component } from "react";
// import LandingPageA from './LandingPageA';
import LandingPageB from './LandingPageB';

class Feather extends Component {
    constructor(props) {
        super(props);
        var landingPageCode = this.getCookie('LandingPage');
        this.state = {
            landingPageCode: landingPageCode,
        };
    }

    componentDidMount() {
        if(this.state.landingPageCode){
        }else{
            let randomType = Math.floor(( (Math.random()*100) +2))%2
            if(this.getCookie('LandingPage')){
            }else{
                // 單雙數判斷
                if( randomType === 0 ){
                    this.setState({landingPageCode: 'A'})
                    this.setCookie( 'LandingPage', 'A', 30)
                } 
                if( randomType === 1 ){
                    this.setState({landingPageCode: 'B'})
                    this.setCookie( 'LandingPage', 'B', 30)
                }
            }
        }
    }

    getCookie =  (cookie) => {
        let cookieEqual = cookie + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let cookieArray = decodedCookie.split(';');
        for (var i = 0; i < cookieArray.length; i++) {
            let cookiePart = cookieArray[i];
            cookiePart = cookiePart.replace(/^\s+/g, '')
            if (cookiePart.indexOf(cookieEqual) === 0) {
                return cookiePart.substring(cookieEqual.length, cookiePart.length);
            }
        }
    }

    setCookie(name, value, days) {
        var d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    render() {
        return (
            <div>
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 羽你蓮心 最輕專業款
                </h1>
                <LandingPageB landingPageCode={this.state.landingPageCode}/>

                {/* {
                    this.state.landingPageCode === 'A' ?
                        <LandingPageA landingPageCode={this.state.landingPageCode}/>
                    :
                    null
                }
                {
                    this.state.landingPageCode === 'B' ?
                        <LandingPageB landingPageCode={this.state.landingPageCode}/>
                    :
                    null
                } */}
            </div>
        );
        
    }
}

export default Feather;
