import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    width: 440px;
    height: calc(92vh - 151px);
    margin: 4vh auto;
    background-color: #feffff;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    overflow-y: auto;
    overflow-x: hidden;

    // -webkit-overflow-scrolling: touch;
    backface-visibility:hidden;
    -webkit-backface-visibility:hidden; /* Chrome 和 Safari */
    -moz-backface-visibility:hidden;    /* Firefox */
    -ms-backface-visibility:hidden;     /* Internet Explorer */
    // -webkit-transform: translate3d(0,0,0);
    // -webkit-transform: translateZ(0);

    box-shadow: 0 2px 15px 3px rgba(175,175,175,.25);
    div::-webkit-scrollbar { width: 0 !important }
    div::-moz-scrollbar { width: 0 !important }
    div::-ms-scrollbar { width: 0 !important }
    div::-o-scrollbar { width: 0 !important }
    div::scrollbar { width: 0 !important }
    div{ 
        -webkit-overflow-style: none;
        -moz-overflow-style: none;
        -ms-overflow-style: none;
        -o-overflow-style: none;
        overflow-style: none;
        overflow: -webkit-scrollbars-none; 
        overflow: -moz-scrollbars-none; 
        overflow: -ms-scrollbars-none; 
        overflow: -o-scrollbars-none; 
     }
    .banner {
        width: 100%;
    }
    @media (max-width: 480px) {
        width: 100vw;
        height: calc(100vh - (97px + 2rem));
        // margin-top: 0;
        // margin-bottom: calc(40px + 2rem);
        // margin-left: auto;
        // margin-right: auto;
        margin: 0 auto;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }
`;

export const Snow = styled.div`
    .snow-container {
        position: absolute;
        height: 100%;
        width: 100%;
        max-width: 100%;
        top: 0;
        overflow: hidden;
        z-index: 2;
        pointer-events: none;
        // background-color: black;
    }
    .snow {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        -webkit-transform: translate3d(-5%, -100%, 100px);
        transform: translate3d(-5%, -100%, 100px);
        -webkit-animation: snow linear infinite;
        animation: snow linear infinite;
    }
    .snow.foreground {
        background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");
        -webkit-animation-duration: 30s;
        animation-duration: 30s;
    }
    .snow.foreground.layered {
        -webkit-animation-delay: 12s;
        animation-delay: 12s;
    }
    .snow.middleground {
        background-image: url(https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png);
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
    }
    .snow.middleground.layered {
        -webkit-animation-delay: 20s;
        animation-delay: 20s;
    }
    .snow.background {
        background-image: url(https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png);
        -webkit-animation-duration: 37s;
        animation-duration: 37s;
    }
    .snow.background.layered {
        -webkit-animation-delay: 25s;
        animation-delay: 25s;
    }
    @-webkit-keyframes snow {
        0% {
            -webkit-transform: translate3d(-5%, -100%, 0);
            transform: translate3d(-5%, -100%, 0);
        }
        100% {
            -webkit-transform: translate3d(5%, 100%,0);
            transform: translate3d(5%, 100%, 0);
        }
    }
    @keyframes snow {
        0% {
            -webkit-transform: translate3d(-5%, -100%, 0);
            transform: translate3d(-5%, -100%, 0);
        }
        100% {
            -webkit-transform: translate3d(5%, 100%, 0);
            transform: translate3d(5%, 100%, 0);
        }
    }
`