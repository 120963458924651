import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-right: 20px;
    flex: 1 !important;
    -webkit-flex: 1 !important;
    -ms-flex: 1 !important;
    input,
    button {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
    }
    input[type=text]{
        // width:calc( 100% - 5rem - 2px);
        height:calc(35px - 1.98px);
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        flex: 1;
    }
    button {
        // margin-left: -3px;
        width: 3em;
        height: 35px;
        margin: 0;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #fff;
        background: #2f82bf;
        text-align: center;
        position: relative;
        img {
            position: relative;
            // top: 2px;
            margin-right: 5px;
        }
        i{
            position: absloute;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
`;
