import { createAction } from 'redux-actions';
import { VALIDATE_ERROR_CODE, fetchObject, queryString } from "./fetchModule";
import { fetchlogin } from './LoginActions';
import { userLogin } from './oauthActions';
import moment from 'moment-timezone';
import UAParser from 'ua-parser-js';
import { localStorageUtility } from '../utilities';

export const fetchListUserBegin = createAction("FORGET_LIST_USER_BEGIN");
export const fetchListUserSuccess = createAction("FORGET_LIST_USER_SUCCESS");
export const fetchListUserFailure = createAction("FORGET_LIST_USER_FAILURE");
export const setForgetModalStatus = createAction("FORGET_SET_MODAL_STATUS");
export const isSendResetMailOk = createAction("IS_SEND_RESET_MAIL_OK");

export const listUser = email => {
	return dispatch => {
		dispatch(fetchListUserBegin());

		const query = queryString({ search: email })
		return fetchObject('user/list', query, {})
			.then(response => {
				if (response.status !== 200) {
					//throw new Error("Bad response from server");
				}
				return response.json();
			})
			.then(json => {
				for (let user of json.data) {
					if (user.email === email) {
						dispatch(fetchListUserSuccess('has_user'));
						return;
					}
				}

				dispatch(fetchListUserSuccess('no_user'));
			})
			.catch(err => {
				dispatch(fetchListUserFailure(err));
				alert('err', err);
			});
	}
}

export const sendResetMail = (email, history) => {
	return dispatch => {
		return fetchObject('user/resetrequest', '', {
			method: 'post',
			body: JSON.stringify({ email }),
		})
			.then(response => {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }
                    
                    return jsonparse;
                } 
				// history.push('/forget/reset', { email });
			})

            .then((json) => {
                if (json === 'OK') {
                	dispatch(isSendResetMailOk(true));
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {

            }, (error) => {
                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    default:
                        window.alert('請聯繫客服協助處理！');
                }
            })
			.catch(err => {
				alert('err', err);
			});
	}
}

export const requestResetPassword = (email, code, password, clientId, redirectUri, scope) => {
	var parser = new UAParser();
	let ua = navigator.userAgent
	let agent = {
		browser: parser.setUA(ua).getBrowser(),
		os: parser.setUA(ua).getOS(),
		device: parser.setUA(ua).getDevice(),
	}
	let playerId = localStorageUtility('get', 'playerId')

	return dispatch => {
		return fetchObject('user/resetpassword', "", {
			method: 'post',
			body: JSON.stringify({ email, code, password }),
		})
			.then(response => {
				if (response.ok) {
					return;
				}
				if (response.status === 400) {
					return response.json();
				}
			})
			.then(json => {
				if (json) {
					throw new Error(VALIDATE_ERROR_CODE(json.code));
				}
				let expiresTime = moment().tz("Asia/Taipei").add(1, "day").format()
				localStorageUtility('set', 'expiresTime', expiresTime)

				if (clientId && redirectUri && scope) {
					dispatch(userLogin({ clientId, redirectUri, scope, email, password, agent, playerId: playerId }));
				} else {
					dispatch(fetchlogin({ email, password, agent, playerId: playerId }));
				}
			})
			.catch(err => {
				alert(err.message);
			});
	}
}
