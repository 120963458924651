import React, { Component } from "react";
import { Wrapper } from "./styled";
import CapitalWord from "./CapitalWord";

export default class extends Component {
    render() {
        return (
            <Wrapper>
               <CapitalWord />
            </Wrapper>
        );
    }
}
