import styled from 'styled-components';

export const Wrapper = styled.div `

    div.header {
      display: flex; 
      align-items: baseline;
      
      > * {
        flex: none
      }
    }
    h2 {
      margin: 20px 20px 5px 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }

    h3 {
      margin: 10px 10px 10px 10px;
      font-size: 2rem;
    }

    .btn1 {
      position: absolute;
      right: 0;
      bottom: 5px;
      width: 5rem;
      height: 1.5rem;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      color: #333;
      margin: 5px;
      background-color: #fff;
      box-shadow: unset;
      text-shadow: unset;
      border-radius: 999rem;
      border: 2px solid #D8D8D8;
      display: flex;
      align-items: center;
      justify-content: center;    

      &:hover, &.active{
        color: #da263c;
        border-color: #da263c;
      } 
    }

    .paid{
      opacity: .3;
    }

    .hidePaid{
      display: none;
      // background-color: purple;
    }

    @media(max-width:480px) {
      .btn1 {
        width: 4rem;
        font-size: 12px;
        line-height: 20px;
        border-width: 1px;
      }
    }

    div.btn-group {
      margin-left: auto;
    }
`
