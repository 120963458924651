import styled from 'styled-components';

export const Wrapper = styled.div `

position: ${props => (props.isCoverPhoto ? 'absolute' : '')};
width: ${props => (props.isCoverPhoto ? '100%' : '')};
height: ${props => (props.isCoverPhoto ? '0%' : '')};
padding-bottom: ${props => (props.isCoverPhoto ? '50%' : '')};
top: ${props => (props.isCoverPhoto ? 0 : '')};

position:  ${props => (props.isAvatarPhoto ?  'absolute' : '')};
top:  ${props => (props.isAvatarPhoto ?  '0' : '')};
width:  ${props => (props.isAvatarPhoto ?  '100%' : '')};
margin:  ${props => (props.isAvatarPhoto ?  '2px' : '')};
height:  ${props => (props.isAvatarPhoto ?  '0' : '')};
border-radius:  ${props => (props.isAvatarPhoto ?  '999rem' : '')};
margin-top:  ${props => (props.isAvatarPhoto ?  '20px' : '')};
padding-bottom:  ${props => (props.isAvatarPhoto ?  '100%' : '')};

#Loading{
  position: ${props => (props.shouldLittleIcon ? "relative" : "fixed")};
  background-color: ${props => (props.shouldLittleIcon ? "" : "rgba(0,0,0,0.5)")};
  /* position:fixed; */
  /* background-color:rgba(0,0,0,0.5); */
  width: 100%;
  height: 100%;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin: auto;
  z-index:999999;
}

#Loading.close{
  display:none;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-spinner {
  width: 150px !important;
  height: 150px !important;
  position: ${
    props => ( props.isAvatarPhoto ? " ":
    props.shouldLittleIcon ? "relative" : "absolute")
  };
  /* position:absolute; */
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  -webkit-transform: ${props => (props.isAvatarPhoto ?  '' : 'translate(-75px, -75px) scale(0.75) translate(75px, 75px)')};
  transform: ${props => (props.isAvatarPhoto ?  '' : 'translate(-75px, -75px) scale(0.75) translate(75px, 75px)')};
}

.lds-spinner div {
  left: ${props => (props.isAvatarPhoto ?  '50%' : '94px')} ;
  /* left: 94px; */
  top: ${props => (props.isAvatarPhoto ?  '0' : '48px')} ;
  /* top: 48px; */
  position: absolute;
  -webkit-animation: lds-spinner linear 1.5s infinite;
  animation: lds-spinner linear 1.5s infinite;
  background: #da263c;
  width: ${props => (props.isAvatarPhoto ?  '7px' : '12px')} ; 
  /* width:  12px; */
  height: ${props => (props.isAvatarPhoto ?  '19px' : '24px')} ;
  /* height: 24px; */
  border-radius: 40%;
  -webkit-transform-origin: 6px 52px;
  transform-origin: ${props => (props.isAvatarPhoto ?  '2px 28px' : ' 6px 52px')};
  /* transform-origin: 6px 52px; */
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -1.375s;
  animation-delay: -1.375s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -1.125s;
  animation-delay: -1.125s;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.625s;
  animation-delay: -0.625s;
}

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.375s;
  animation-delay: -0.375s;
}

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.125s;
  animation-delay: -0.125s;
}

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

`;