import { createAction } from 'redux-actions';
import { signUpGroup } from './groupDetailActions';
import { fetchObject,VALIDATE_ERROR_CODE } from './fetchModule';
import {
    UPDATE_ACTIVITY_STATUS,
    GET_USER_INFO,
    PUT_USER_INFO,
    GET_OTHER_USER_INFO,
    GET_HOBBY_LIST,
    GET_USER_LIST,
    SET_USERINFO_DATA,
    SET_DELETE_USERINFO_DATA,
} from '../constants/actionTypes';
import { fetchActivity } from './activityActions';
import { history } from '../index';
import { localStorageUtility } from '../utilities';

export const updateActivityStatus = createAction(UPDATE_ACTIVITY_STATUS);
export const getUserInfoData = createAction(GET_USER_INFO);
export const getOtherUserInfoData = createAction(GET_OTHER_USER_INFO);
export const putUserInfo = createAction(PUT_USER_INFO);
export const getHobbyListData = createAction(GET_HOBBY_LIST);
export const fetchUserData = createAction(GET_USER_LIST);
export const setUserInfoData = createAction(SET_USERINFO_DATA);
export const setDeleteUserInfoData = createAction(SET_DELETE_USERINFO_DATA);

export const setUserInfoToRedux = (userInfo) =>{
    return (dispatch) => {
        dispatch(setUserInfoData(userInfo));
    }
}

export const setDeleteUserInfoToRedux = () => {
    return (dispatch) => {
        dispatch(setDeleteUserInfoData(null));
    }
};

export const fetchActivityInvite = (param) => {
    return (dispatch, getState) => {
        console.log("param in myInfo action:", param);
        return fetchObject(`activity/invite`, '', {
            method: 'Post',
            body: JSON.stringify(param),
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                console.log(json);
               
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    };
};


export const fetchUserlist = (user = {}) => {
    return (dispatch, getState) => {

        fetchObject(`user/list?search=${user}`)
            .then(function(response) {
                if (response.ok) return response.json();
                else
                    response.json().then(function(err) {
                        //throw new Error(err);
                    });
            })
            .then(function(json) {
                console.log(json);
                dispatch(fetchUserData(json));
            })
            .catch(function(ex) {
                console.log("parsing failed", ex);
                alert(VALIDATE_ERROR_CODE(ex.code));
            });
    };
};

export const fetchUserInfo = (Body) => {
    // userId, withActivity
    //debugger
    const userInfo = localStorageUtility('getAndParse', 'myData');
    return (dispatch) => {
        fetchObject(`user/${Body && Body.userId ? Body.userId : userInfo.id}`)
            .then((response) => {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                if (json.id) {
                    if (Body && Body.redirectUrl) {
                        if (json.phoneVerified) {
                            signUpGroup({ activity: Body.signUpGroupId, redirectUrl: Body.redirectUrl })(dispatch);
                        } else {
                            throw new Error('NOT_VERIFIED');
                        }
                    } else {
                        return json;
                    }
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                if (Body && Body.userId) {
                    dispatch(getOtherUserInfoData(data));
                } else {
                    dispatch(getUserInfoData(data));
                }
                if (Body && Body.withActivity) {
                    return dispatch(fetchActivity({ user: Body && Body.userId, sort: 'start desc' }));
                }
            }, (error) => {
                // 錯誤狀態判斷 顯示
                // console.log(error);
                switch(error.message) {
                    case "TOKEN_INVALID":
                        window.alert('登入過期，請重新登入');
                        localStorageUtility('remove', 'myData');
                        history.replace('/login');
                        break;
                    case "NOT_VERIFIED":
                        window.alert('您的手機尚未經過驗證，請先完成驗證');
                        history.replace('/my_info');
                        break;
                    default:
                        window.alert('非預期錯誤，請重新整理網頁或是聯繫客服');
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const sendUserInfo = (data, headers) => {
    // console.log('sendUserInfo', data, headers);
    return (dispatch, getState) => {
        const options = {
            method: 'PUT',
            body: JSON.stringify(data)
        };
        if (headers) {
            options.headers = headers;
        }

        return fetchObject(`user`, '', options)
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                dispatch(putUserInfo(json));
                return true;
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    };
};

export const sendUserPassword = password => {
    return dispatch => {
        fetchObject('user/changepassword', '', {
            method: 'post',
            body: JSON.stringify({ password })
        })
            .then(response => {
                if (response.ok) {
                    return;
                }

                if (response.status === 400) {
                    return response.json();
                }
            })
            .then(json => {
                if (json) {
                    //throw new Error(json.details || 'reset passowrd error');
                }
            })
            .catch(ex => {
                console.log('parsing failed', ex);
            });
    };
};

export const getHobbyList = type => {
    return dispatch => {
        fetchObject(`hobby/list`)
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                dispatch(getHobbyListData(json.data));
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    };
};
