import styled from 'styled-components';

export const Wrapper = styled.button`
  text-align: center;
  width:100%
  background-color: #DF1C2C;
  border: 0 none
  margin-top: 30px
  margin-bottom: 10px
  padding-top: 10px
  padding-bottom: 13px
  font-size: 20px
  font-weight: bolder
  color: #fff
  font-family: Microsoft JhengHei
  border-radius: 5px
  img{
    padding-left: 5px
  }
`
