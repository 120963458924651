import styled from 'styled-components';

export const Wrapper = styled.div `

$bonny: #da263c;

    width:100%;
    select{
        width:100%
    };
    .image-uploader-section{
        width: 110%;
        margin-left:-6%
    }    
    .features{
        border: 1px solid
        padding: 8px 10px 8px 10px
        border-color: #D8D8D8
        border-radius: 20px
        text-align: center
        margin: 7px
        width: 80px
        display:inline-block
    }
    // 場地名稱
        .place_name{
            // width: calc( 100% - 6em);
            width: 100%;
            display : inline-block;
            vertical-align: bottom;
            input[type="text"]{
                width:100%;
                padding-left: 5px;
                border-right-width: 0px;
            }
            .place_name__box{
                display: flex;
                align-items:center;
                .form-control{
                    flex: 1;
                }
                .place_name_findAddress{
                    width: 6em;
                    height: 35px;
                    color: white;
                    background-color: rgb(47, 130, 191);
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    cursor: pointer;        

                    display: flex;;
                    text-align: center;

                    i {
                        width: 1rem;
                        margin-left: 1rem;
                    }
                    
                    &__span{
                        flex: 1;
                        font-size: 14px;
                        line-height: 35px;
                        display: inline-block;
                        vertical-align: bottom;
                    }
                }
            }
        }
    // // 場地名稱
    // .place_name > .form-control{
    //     margin: 0
    //     width: calc( 100% - 6em);
    //     display : inline-block;

    //     input[type="text"]{
    //         width:100%;
    //     }
    // }
    // .place_name_findAddress{
    //     width: 6em;
    //     height: 35px;
    //     color: white;
    //     background-color: rgb(47, 130, 191);
    //     border-top-right-radius: 5px;
    //     border-bottom-right-radius: 5px;
    //     cursor: pointer;

    //     display: inline-block;
    //     text-align: center;
    //     margin-bottom: 5px;
    //     vertical-align: bottom;

    //     &__span{
    //         font-size: 14px;
    //         line-height: 35px;
    //         display: inline-block;
    //         margin-bottom: 5px;
    //         vertical-align: bottom;
    //     }
    // }
    // don't rename, or it will not shows a icon
    .material-icons{
        // width: 8%;
        line-height: 35px;
    
        margin-bottom: 5px;
        vertical-align: bottom;
    }


    // payment method
    .paymentType{
// <<<<<<< HEAD
//         text-align:center;
//         width:100%;
//         display : inline-block;

//         &__label{
//             display: inline-block;
//             width: 21%;
//             margin: 0 3%;
//             padding: 10px;
//             color: #da263c;
//             border: 1px solid #da263c;
//             text-align:center;
//             cursor: pointer;
//         }
    

//         // payment pricing, one for offline, one for online
//         + .form-control{
    
//             + .form-control{
//                 .paymentType__tag{
//                     display: inline-block;
//                     position: relative;
//                     width: 15%;
//                     padding: 1% 0.5%;
//                     font-weight: light;
//                     font-family: 'Trebuchet MS', sans-serif;
//                     text-transform: uppercase;
//                     text-align: center;
//                     color: white;
//                     border: none;
//                     border-radius: 5%;
//                     background: #5a99d4;
//                     box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
                        
//                 }
//                 .select{
//                     display: inline-block;
//                     width: calc(83% - 1.98px );
//                     padding: 0;
//                     margin-left: 1%;
//                 }
// =======
        // text-align:center;
        // width:100%;
        // display : inline-block;
        // background-color: #da263c;

        // first p
        + .form-control{
        
            .paymentType__tag{
                // display: inline-block;
                // position: relative;
                // width: 15%;
                // padding: 1% 0.5%;
                // font-weight: light;
                // font-family: 'Trebuchet MS', sans-serif;
                // text-transform: uppercase;
                // text-align: center;
                // color: white;
                // border: none;
                // border-radius: 5%;
                // background: #5a99d4;
                // box-shadow: 0 0 0 0 rgba(#5a99d4, .5);

                vertical-align: bottom;

                display: inline-block;
                position: relative;
                width: 7em;
                line-height: 29px;

                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;

                padding: 1.5% 0.5% .6%;
                font-size: 14px;
                font-weight: light;
                font-family: 'Trebuchet MS', sans-serif;
                text-align: center;
                color: white;
                background: #5a99d4;
                border: none;
                box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
                // animation: pulse 1.5s infinite;
                    
            }
            .select{
                display: inline-block;
                // 114 = 7em(102px) + 1*2(border) + 5*2(padding)
                width: calc(100% - 102px);
                // padding: 0 5px;
                borderBottomLeftRadius: 0px;
                borderTopLeftRadius: 0px;

                select{
                    height: 37.39px;
                    border-radius: 0px;
                }
                // display: inline-block;
                // width: calc(83% - 1.98px );
                // padding: 0;
                // margin-left: 1%;
            }
// >>>>>>> developv2-willy-hostActivity

        }

// <<<<<<< HEAD
// =======
        + .form-control + .form-control{
        
            // one of payment method 
// >>>>>>> developv2-willy-hostActivity
            .paymentType__tag{
                // display: inline-block;
                // position: relative;
                // width: 15%;
                // padding: 1% 0.5%;
                // font-weight: light;
                // font-family: 'Trebuchet MS', sans-serif;
                // text-transform: uppercase;
                // text-align: center;
                // color: white;
                // border: none;
                // border-radius: 5%;
                // background: #5a99d4;
                // box-shadow: 0 0 0 0 rgba(#5a99d4, .5);

                display: inline-block;
                position: relative;
                width: 7em;
                line-height: 29px;
                vertical-align: bottom;

                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;

                padding: 1.5% 0.5% .6%;
                font-size: 14px;
                font-weight: light;
                font-family: 'Trebuchet MS', sans-serif;
                text-align: center;
                color: white;
                background: #5a99d4;
                border: none;
                box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
                // animation: pulse 1.5s infinite;
                    
            }
            .select{
                display: inline-block;
                // 114 = 7em(102px) + 1*2(border) + 5*2(padding)
                width: calc(100% - 102px);
                // padding: 0 5px;
                borderBottomLeftRadius: 0px;
                borderTopLeftRadius: 0px;

                select{
                    height: 37.39px;
                    border-radius: 0px;
                }
                // display: inline-block;
                // width: calc(83% - 1.98px );
                // padding: 0;
                // margin-left: 1%;
            }
        }

// <<<<<<< HEAD
// =======
    }
    // payment method	
    .form-control:nth-child(11){	
        // background-color: #da263c;	
        // text-align:center;	
        
        width:100%;		
        display : inline-block;		
    }		
    .paymentType__label{		
        display: inline-block;		
        margin: 0 3%;		
        // background-color: #da263c;		
        padding: 10px;		
        cursor: pointer;		
        width: 21%;		
        text-align:center;		
        color: #ced4da;		
        border: 1px solid #ced4da;		
        border-radius: 999rem;		

        &.active{	
            border: 2px solid #da263c;	
            color: #da263c;	
            font-weight: 500;	
        }
        // &.hover{	
        //     border: 2px solid #da263c;	
        //     color: #da263c;	
        //     font-weight: 500;	
        // }
    }
    // .paymentType__label{
    //     display: inline-block;
    //     width: 21%;
    //     margin: 0 3%;
    //     padding: 10px;
    //     color: #da263c;
    //     border: 1px solid #da263c;
    //     text-align:center;
    //     cursor: pointer;
    // }


    // .place_name_findAddress{
    //     width: 19%;
    //     height: 35px;
    //     color: white;
    //     background-color: rgb(47, 130, 191);
    //     border-top-right-radius: 5px;
    //     border-bottom-right-radius: 5px;
    //     cursor: pointer;

    //     display: inline-block;
    //     text-align: center;
    //     margin-bottom: 5px;
    //     vertical-align: bottom;
// >>>>>>> developv2-willy-hostActivity

    // }

    // img upload css
    form.main-form{
        .form-control{
            .img-uploader{
                label.title{
                    width: 25%;
                }
            }
            .upload-btn-wrapper {
                position: relative;
                display: inline-block;
                vertical-align: bottom;
                width: calc ( 25% - 28px);
                @media (max-width: 480px){
                    width: 25%;
                }
                label {
                    height: 35px;
                    text-align: center;
                    line-height: 35px;
                    border: none;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    color: #fff;
                    background: #2f82bf;
                    margin: 0;
                    img {
                        position: relative;
                        margin-right: 5px;
                    }
                }
                input[type=file] {
                    font-size: 100px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                }
            }
        }    
    }
    
    #spaceForMobile{
        height: 60px;
    }

   

`