import { createAction } from 'redux-actions';
import { fetchObject } from "./fetchModule";
export const createAffiliateData = createAction("CREATE_AFFILIATE_DATA");
export const getAffiliateStatisticData = createAction("GET_AFFILIATE_STATISTIC_DATA");
export const getAffiliateHistoryData = createAction("GET_AFFILIATE_HISTORY_DATA");

//建立分銷引流會員追蹤紀錄
//必填欄位: code, firstEntry
export const createAffiliate = (data) => {
    // console.log(data)
    return (dispatch) => {
        fetchObject(`affiliateTrack`, '', {
            method: 'POST',
            body: data
        })
            .then(function (response) {
                if(response && response.ok) {
                    // If result was ok save it to cache
                    // cache.put(response.clone());
                    // console.log(`ok`)
                    return response.json();
                }else{
                    // console.log(`error`)
                }
                // return response.json()
            })
            .then(function (json) {
                // console.log(json)
                var payload = { 
                    // affiliateId: json.affiliateId, 
                    // affiliateUserId: 957,
                    // createdAt: 1546925860841,
                    // firstEntry: true,
                    id: json.id,
                    // ip: "",
                    // path: "http://localhost:3000/",
                    // updatedAt: 1546925860841,
                    // userId:,
                };
                // console.log(payload)
                dispatch(createAffiliateData(payload));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

//更新分銷引流會員追蹤紀錄
//必填欄位: userId
export const updateAffiliate = (data) => {
    // console.log(data)
    return (dispatch) => {
        // console.log(`data:`,data)
        // console.log(`data.id:`,data && data.id)
        var id = data && data.id;
        // var userId = data && data.userId;
        // delete data.user
        fetchObject(`affiliateTrack/${id}`, "", {
            method: "PUT",
            // body: data
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                // console.log(json)
                var payload;
                if(!!json.code){
                    if(json.code === "USER_AFFILIATE_TRACK_EXIST"){
                        payload = {
                            firstEntry: false,
                        }
                    }
                }else{
                    payload = json;
                }
                dispatch(createAffiliateData(payload));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const getAffiliateStatistic = (data) => {
    //console.log(data);
    return (dispatch, getState) => {
        fetchObject(`affiliate/statistic`, "", {
            method: "POST",
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(getAffiliateStatisticData(json));
                // console.log(json);
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const getAffiliateHistory = (data) => {
    //console.log(data);
    return (dispatch, getState) => {
        fetchObject(`affiliate/history`, "", {
            method: "GET",
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(getAffiliateHistoryData(json));
                // console.log(json);
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};
