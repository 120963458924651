// @flow

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_COMPLETE = 'REGISTER_COMPLETE';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const START_LOGOUT = 'START_LOGOUT';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const SET_USER = 'SET_USER';
export const LOADING_DATA = 'LOADING_DATA';
export const UPDATE_USER_LOGIN = 'UPDATE_USER_LOGIN';

//Group
export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_JOIN_HOST_DATA = 'GET_JOIN_HOST_DATA';
export const GET_LEVELS = 'GET_LEVELS';
export const GET_PREFERENCES = 'GET_PREFERENCES';
export const CREATE_FEATURE = 'CREATE_FEATURE';
export const EDIT_GROUP_DATA = 'EDIT_GROUP_DATA';

//Group detail
export const GET_GROUP_DETAIL = 'GET_GROUP_DETAIL';
export const GET_FOLLOWEECOUNT = 'GET_FOLLOWEECOUNT';
// export const SIGNUP_GROUP = 'SignUp_Group';
export const GET_JOINED_LIST = 'Get_Joined_List';
export const FOLLOW_USER = 'Follow_User';
export const UNFOLLOW_USER = 'UnFollow_User';
export const FOLLOW_LIST = 'Follow_List';
export const GET_ACTIVITY = "GET_ACTIVITY"; 
export const ADD_FEATURE = "ADD_FEATURE"; 
export const GET_PAYMENT_RESULT_DATA = "GET_PAYMENT_RESULT_DATA";

// @flow
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER = 'REGISTER';
export const GET_USER = 'GET_USER';
export const GET_OCCUPATIONS = 'GET_OCCUPATIONS';
export const GET_HOBBY = 'GET_HOBBY';
export const SET_EMAIL_VERIFY_MESSAGE = 'SET_EMAIL_VERIFY_MESSAGE';
export const SHOULD_MOBILE_VERIFY_TRUE = 'SHOULD_MOBILE_VERIFY_TRUE';
export const SET_MOBILE_VERIFY_MESSAGE = 'SET_MOBILE_VERIFY_MESSAGE';

// @flow in myInfo
export const GET_MY_INFO_ACTIVITY_DATA = 'GET_MY_INFO_ACTIVITY_DATA';
export const UPDATE_ACTIVITY_STATUS = 'UPDATE_ACTIVITY_STATUS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const PUT_USER_INFO = 'PUT_USER_INFO';
export const GET_OTHER_USER_INFO = 'GET_OTHER_USER_INFO';
export const SET_USERINFO_DATA = 'SET_USERINFO_DATA';
export const SET_DELETE_USERINFO_DATA = 'SET_DELETE_USERINFO_DATA';

// @flow in comment
export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_FINISH = 'POST_COMMENT_FINISH';
export const POST_COMMENT_FAILED = 'POST_COMMENT_FAILED';

// Activity
export const FETCHING_DATA = 'FETCHING_DATA';
export const FETCH_ACTIVITY_DATA = 'FETCH_ACTIVITY_DATA';
export const UPDATE_ACTIVITYSIGNUP_BUTTON = 'UPDATE_ACTIVITYSIGNUP_BUTTON';
export const FETCH_ALL_NOTIFICATION_DATA = 'FETCH_ALL_NOTIFICATION_DATA';
export const GET_BANNER_LIST = 'GET_BANNER_LIST';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const UPDATE_SEARCH_QUERY_HOST_ACTIVITY = 'UPDATE_SEARCH_QUERY_HOST_ACTIVITY';
export const LEAVE_ACTIVITY = 'LEAVE_ACTIVITY';
export const ACTIVITY_UPDATE_NOTIF_FETCH_TIME = 'ACTIVITY_UPDATE_NOTIF_FETCH_TIME';
export const ACTIVITY_UPDATE_NOTIF_ACTIVITY = 'ACTIVITY_UPDATE_NOTIF_ACTIVITY';
export const RESET_ACTIVITY_DATA = 'RESET_ACTIVITY_DATA';
export const FETCHING_PUT_JOIN_PAID = 'FETCHING_PUT_JOIN_PAID';

// hobby list
export const GET_HOBBY_LIST = 'GET_HOBBY_LIST';
export const GET_USER_LIST = 'GET_USER_LIST';

// Forget
export const FORGET_LIST_USER_BEGIN = 'FORGET_LIST_USER_BEGIN';
export const FORGET_LIST_USER_SUCCESS = 'FORGET_LIST_USER_SUCCESS';
export const FORGET_LIST_USER_FAILURE = 'FORGET_LIST_USER_FAILURE';
export const FORGET_SET_MODAL_STATUS = 'FORGET_SET_MODAL_STATUS';
export const IS_SEND_RESET_MAIL_OK = 'IS_SEND_RESET_MAIL_OK';

// Media - List
export const MEDIA_SET_LIST = 'MEDIA_SET_LIST';
export const MEDIA_UPDATE_LIST = 'MEDIA_UPDATE_LIST';
// Media - Tag
export const MEDIA_TAG_LIST_STATUS = 'MEDIA_TAG_LIST_STATUS';
export const MEDIA_TAG_LIST_BEGIN = 'MEDIA_TAG_LIST_BEGIN';
export const MEDIA_TAG_LIST_SUCCESS = 'MEDIA_TAG_LIST_SUCCESS';
export const MEDIA_TAG_LIST_FAILURE = 'MEDIA_TAG_LIST_FAILURE';
export const MEDIA_TAG_SET_LIST = 'MEDIA_TAG_SET_LIST';
// Media - Get
export const MEDIA_SET_DATA = 'MEDIA_SET_DATA';
export const UPDATE_MEDIALIST_INTERACTION = 'UPDATE_MEDIALIST_INTERACTION';
export const UPDATE_MEDIA_INTERACTION = 'UPDATE_MEDIA_INTERACTION';
export const UPDATE_IS_MEDIA_LIKE_CLICK = 'UPDATE_IS_MEDIA_LIKE_CLICK';

// Notification
export const FETCH_NOTIFICATION_DATA = 'FETCH_NOTIFICATION_DATA';
export const FETCH_NOTIFICATION_ABOUT_RENT = 'FETCH_NOTIFICATION_ABOUT_RENT';
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';
export const GET_NOTIFICATION_UNREAD_COUNT = 'GET_NOTIFICATION_UNREAD_COUNT';

// Message 
export const SET_PRIVATECHAT_LIST = 'SET_PRIVATECHAT_LIST';
export const SET_PRIVATECHAT_INFO = 'SET_PRIVATECHAT_INFO';
export const SAVE_MESSAGE_RES_TO_REDUX = 'SAVE_MESSAGE_RES_TO_REDUX';
export const SET_SOCKET_MESSAGE = 'SET_SOCKET_MESSAGE';
export const SET_MESSAGE_UNREAD_COUNT = 'SET_MESSAGE_UNREAD_COUNT';

// Image Upload
export const SET_IMG_URL = 'SET_IMG_URL';
export const UPLOAD_IMG_STATUS = 'UPLOAD_IMG_STATUS';

//rent
export const SET_EMAIL_USED_STATUS = 'SET_EMAIL_USED_STATUS';
export const SET_RACKET_PROGRAM ='SET_RACKET_PROGRAM';
export const SET_RACKET_INFORMATION ='SET_RACKET_INFORMATION';
export const GET_RACKET_ORDER_LIST = 'GET_RACKET_ORDER_LIST';
export const FETCH_RACKET_ORDER_DETAIL_DATA = 'FETCH_RACKET_ORDER_DETAIL_DATA';
export const POST_CANCEL_REPLACE_DATA = 'POST_CANCEL_REPLACE_DATA';
export const DELETE_REPLACE_DATA = 'DELETE_REPLACE_DATA';
export const UPDATE_CANCEL_PROGRAM_OR_REPLACE_RACKET = 'UPDATE_CANCEL_PROGRAM_OR_REPLACE_RACKET';

//racketDetail
export const BELONG_UPDATE_DATA = 'BELONG_UPDATE_DATA';

// map
export const SET_MAP_API_DATA = 'SET_MAP_API_DATA';
export const SET_MAP_API_DATA_FROM_ACTIVITY_SIGN_UP = 'SET_MAP_API_DATA_FROM_ACTIVITY_SIGN_UP';

//Affiliate
export const CREATE_AFFILIATE_DATA = 'CREATE_AFFILIATE_DATA';
export const GET_AFFILIATE_STATISTIC_DATA = 'GET_AFFILIATE_STATISTIC_DATA';
export const GET_AFFILIATE_HISTORY_DATA = 'GET_AFFILIATE_HISTORY_DATA';

// Invoice
export const UPDATE_IS_LOVE_CODE_CORRECT = 'UPDATE_IS_LOVE_CODE_CORRECT';
export const UPDATE_IS_MOBILE_BAR_CODE_CORRECT = 'UPDATE_IS_MOBILE_BAR_CODE_CORRECT';
