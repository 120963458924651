import { createAction } from 'redux-actions';
import { fetchObject } from "./fetchModule";
import querystring from 'querystring';

export const updateIsLoveCodeCorrect = createAction("UPDATE_IS_LOVE_CODE_CORRECT");
export const updateIsMobileBarCodeCorrect = createAction("UPDATE_IS_MOBILE_BAR_CODE_CORRECT");


export const checkLoveCode = (loveCode) => {
	return dispatch => {
        let params = querystring.stringify(loveCode);

		return fetchObject('invoice/checkLoveCode', params)
			.then(response => {
                const contentType = response.headers.get('Content-Type');
                if (contentType.indexOf('text') > -1) {
                    return response.text()
                }else{
                    return response.json()
                }
			})
            .then((json) => {
                if (json === 'OK') {
                	dispatch(updateIsLoveCodeCorrect(true));
                    return json;
                } else {
                	dispatch(updateIsLoveCodeCorrect(false));
                    throw new Error(json.code);
                }
            })
            .then(
            // (data) => {

            // }, (error) => {
            //     // 錯誤狀態判斷 顯示
            //     switch(error.message) {
            //         default:
            //             window.alert('請聯繫客服協助處理！');
            //     }
            // }
            // "LOVECODE_NOT_EXIST"
            )
			.catch(err => {
				// alert('err', err);
			});
	}
}



export const checkMobileBarCode = (mobileBarCode) => {
	return dispatch => {
        let params = querystring.stringify(mobileBarCode);

		return fetchObject('invoice/checkMobileBarCode', params)
			.then(response => {
                const contentType = response.headers.get('Content-Type');
                if (contentType.indexOf('text') > -1) {
                    return response.text()
                }else{
                    return response.json()
                }
			})
            .then((json) => {
                if (json === 'OK') {
                	dispatch(updateIsMobileBarCodeCorrect(true));
                    return json;
                } else {
                	dispatch(updateIsMobileBarCodeCorrect(false));
                    throw new Error(json.code);
                }
            })
            .then(
            // (data) => {

            // }, (error) => {
            //     // 錯誤狀態判斷 顯示
            //     switch(error.message) {
            //         default:
                        // window.alert('請聯繫客服協助處理！');
            //     }
            // }
            // ""MOBILEBARCODE_NOT_EXIST"
            )
			.catch(err => {
				// alert('err', err);
			});
	}
}

