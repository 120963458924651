import React, { Component } from "react";
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Wrapper } from './styled';
import { 
    GaSetting, 
    LoadingIndicator 
} from "../Common";
import { createRent, getRacketOrderDetail } from "../../actions";
import moment from 'moment';
import { getCookie } from '../../utilities';

class PaymentResult extends Component {
    constructor(props) {
        super(props);
        const landingPageCode = getCookie('LandingPage');

        this.state = {
            info_error: null,
            info_isLoaded: false,
            info_items: [],
            prog_error: null,
            prog_isLoaded: false,
            prog_items: [],
            rentData:{
                racket_id: '',
                paymenttype: '',
                amount: '',
                user_id: '',
                user: '',
                email: '',
                phone: '',
                address: '',
                gift: '',
                payment_supplier: '',
                payment_auth_status: '',
                racket_belong: '',
                creditcard_expiration_date: '',
            },
            orderID: this.props.match.params && this.props.match.params.orderId,
            orderDetail: '',
            paymentID: this.props.match.params && this.props.match.params.paymentId,
            isFromWhatLevel: '',
            tradingStatus: '',
            racketChoose: '',
            paymentSupplier: '',
            giftChoose: '', 
            landingPageCode: landingPageCode,
        }; 
    }

    componentDidMount() {
        const orderId = this.props.match.params.orderId ? this.props.match.params.orderId : undefined;
        this.props.getRacketOrderDetail(orderId);
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        //前往綠界付款
        if(this.props.isGetPaymentRes === true){
            document.getElementById("purchase_form").submit();
        }

        //更新付款資訊
        const { rentReducerData } = this.props;
        const { racketOrderDetail } = rentReducerData;
        if ( 
            (prevProps.rentReducerData && prevProps.rentReducerData.racketOrderDetail) !== racketOrderDetail
            &&
            racketOrderDetail.order !== undefined 
        ) {
            let orderDetail = racketOrderDetail && racketOrderDetail.order.filter(data => data.id === Number(this.state.orderID));
            let paymentDetail = racketOrderDetail && racketOrderDetail.payment.filter(data => data.id === Number(this.state.paymentID));
            this.setState({
                rentData:{
                    racket_id: orderDetail[0].racket_id.id,
                    paymenttype: orderDetail[0].payment_type,
                    amount: '1',
                    user_id: orderDetail[0].user_id.id,
                    user: orderDetail[0].user,
                    email: orderDetail[0].email,
                    phone: racketOrderDetail.shipment[0].phone,
                    address: racketOrderDetail.shipment[0].address,
                    gift: orderDetail[0].gift,
                    payment_supplier: orderDetail[0].payment_supplier,
                    payment_auth_status: orderDetail[0].payment_auth_status,
                    racket_belong: orderDetail[0].racket_belong,
                    creditcard_expiration_date: orderDetail[0].creditcard_expiration_date,
                    order_id: orderDetail[0].id,
                },
                orderDetail: orderDetail[0],
                paymentDetail: paymentDetail[0],
                isFromWhatLevel: orderDetail[0].tag === 'low' ? '2D' : orderDetail[0].tag === 'medium' ? '3D' : '4D',
                tradingStatus: racketOrderDetail.payment[0].supplier_trade_status === '1' ? 'Success' : 'Fail',
                racketChoose: orderDetail[0].racket_id.name,
                paymentSupplier: orderDetail[0].payment_type === 'A' ? '一次付清' : orderDetail[0].payment_type === 'Y' ? '年繳' : '月繳',
                giftChoose: orderDetail[0].gift === 1 ? '贈品A' : orderDetail[0].gift === 2 ? '贈品B' : '贈品C',
            });
        }
    }

    render() {  
        const { isFromWhatLevel, tradingStatus, racketChoose, paymentSupplier, giftChoose, orderID, paymentID, orderDetail, paymentDetail } = this.state;
        const { user_id } = this.state.rentData;

        return (
            <Wrapper key={0}>
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 羽你蓮心專案交易訊息
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />
                {
                    isFromWhatLevel &&
                        <GaSetting 
                            data = {{
                                name: 'componentDidMount', 
                                category: `${isFromWhatLevel}-PaymentResult`, 
                                action: `${isFromWhatLevel}_PaymentResult_PV`, 
                                label: `User:${user_id}/OrderID:${orderID}/PaymentId:${paymentID}/tradingStatus:${tradingStatus}/RacketChoose:${racketChoose}/PaymentSupplier:${paymentSupplier}/GiftChoose:${giftChoose}`, 
                            }}
                        />
                }
                {/*supplier_trade_status => payment 1:success 2:fail*/}
                { 
                    paymentDetail && paymentDetail.supplier_trade_status === '1' 
                    ?
                        <div id="program_done">
                            <div className="title">
                                <h2>付款成功通知</h2>
                            </div>      
                            <div className="sub_style">您於【{moment(paymentDetail.supplier_paid_time).format('YYYY-MM-DD')}】雲羽集APP 蓮心方案<br/>申請已付款成功，交易訊息如下</div>
                            <hr className="margin-all-20px"/>  
                            <ul>
                                <li>方案起訖：{moment(parseInt(orderDetail.contract_start_time)).format('YYYY-MM-DD')} 至 {moment(parseInt(orderDetail.contract_end_time)).format('YYYY-MM-DD')} 止</li>
                                <li>選配球拍：{orderDetail && orderDetail.racket_id.name}</li>
                                <li>寄送地址：{orderDetail && orderDetail.address}</li>
                                <li>付款時間：{paymentDetail && moment(paymentDetail.supplier_paid_time).format('YYYY-MM-DD')}</li>
                                <li>付款方式：{paymentDetail && paymentDetail.supplier_trade_payment}</li>
                                <li><hr className="margin-all-20px"/></li>
                                <br/>
                                <li>
                                    <GaSetting 
                                        key={2}
                                        data = {{
                                            className: 'button-style',
                                            category: `${isFromWhatLevel}-PaymentResult`, 
                                            action: `PaymentResult_Click`, 
                                            label: `goToRacketDetail`,
                                            tag: 'link',
                                            style : {
                                                margin: `auto`,
                                            },
                                            path: `/my_info/rent_contract_detail/${orderID}`,
                                        }}
                                    >
                                        返回羽你蓮心方案
                                    </GaSetting>
                                </li>
                                <br/>
                                <li>注意事項：
                                    <ul>
                                        <li>當您成功申請方案即享有球拍三年蓮心保障期 (不含羽線及柄皮)。</li>
                                        <li>提醒您！首次更換球拍需先行支付蓮心保障費新台幣599元，二次換拍需先行支付蓮心保障費新台幣899元。</li>
                                        <li>當您成功申請方案即同意本公司若於您方案起訖遇信用卡過期或一切非本公司責任歸屬而未收到款項時，我司有權要求負擔損失賠償之責。</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    : 
                        null
                }
                { 
                    paymentDetail && paymentDetail.supplier_trade_status !== '1'
                    ? 
                        <div id="program_fail" >
                            <div className="title">
                                <h2>交易失敗通知</h2>
                            </div>
                            <div className="sub_style">雲羽集APP 蓮心方案申請<br/>尚未付款成功<br/>請點選重新付款鈕支付款項</div>
                            <hr className="margin-all-20px"/>      
                            <ul>
                                <li onClick={() => this.props.createRent(this.state.rentData)}>
                                    <GaSetting 
                                        key={2}
                                        data = {{
                                            className: 'button-style', 
                                            category: `${isFromWhatLevel}-PaymentResult`, 
                                            action: `PaymentResult_Submit`, 
                                            label: `goToECPay`,
                                            tag: 'button',
                                        }}
                                    >
                                        重新付款
                                    </GaSetting>
                                </li>
                                <br/>
                                <li>注意事項：
                                    <ul>
                                        <li>當您成功申請方案即享有球拍三年蓮心保障期 (不含羽線及柄皮)。</li>
                                        <li>提醒您！首次更換球拍需先行支付蓮心保障費新台幣599元，二次換拍需先行支付蓮心保障費新台幣899元。</li>
                                        <li>當您成功申請方案即同意本公司若於您方案起訖遇信用卡過期或一切非本公司責任歸屬而未收到款項時，我司有權要求負擔損失賠償之責。</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    : 
                        null
                }           
            </Wrapper>
        );
        
    }
}

const mapStateToProps = state => ({
    rentReducerData: state.rentReducer,
    isGetPaymentRes: state.rentReducer.isGetPaymentRes,
    loading: state.rentReducer.shouldLoadingOpen,
});

const mapDispatchToProps = ({
    createRent : (data) => createRent(data),
    getRacketOrderDetail: (id) => getRacketOrderDetail(id),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentResult));
