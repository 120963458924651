import moment from "moment";
import { createAction } from "redux-actions";
import { fetchObject, queryString, VALIDATE_ERROR_CODE } from "./fetchModule";
import { getGroupDetail } from "./groupDetailActions";
import { history } from '../index';
import { localStorageUtility } from '../utilities';

export const fetching_data = createAction('FETCHING_DATA');
export const fetchPutjoinpaid = createAction('FETCHING_PUT_JOIN_PAID');
export const fetchActivityData = createAction('FETCH_ACTIVITY_DATA');
export const updateActivitySignUpButtonStatus = createAction('UPDATE_ACTIVITYSIGNUP_BUTTON');
export const fetchAllNotificationData = createAction('FETCH_ALL_NOTIFICATION_DATA');
export const getBannerListData = createAction('GET_BANNER_LIST');
export const updateSearchQuery = createAction('UPDATE_SEARCH_QUERY');
export const updateSearchQueryHostActivity = createAction('UPDATE_SEARCH_QUERY_HOST_ACTIVITY');
export const leaveActivity = createAction('LEAVE_ACTIVITY');
export const updateNotifFetchTime = createAction('ACTIVITY_UPDATE_NOTIF_FETCH_TIME');
export const updateNotifActivity = createAction('ACTIVITY_UPDATE_NOTIF_ACTIVITY');
export const resetActivityData = createAction('RESET_ACTIVITY_DATA');
export const getMyInfoActivityData = createAction('GET_MY_INFO_ACTIVITY_DATA');

export const updateActivitySignUpButton = ( boolean) => {
    return (dispatch, getState) => {
        dispatch( updateActivitySignUpButtonStatus({status: boolean}))
    };
};
export const fetchActivity = (defaultQuery = {}) => {
    return (dispatch, getState) => {
        const queryData = getState().activity.query;
        if(!!queryData.nativeLocation){
            delete queryData["nativeLocation"];
        }
        // console.log(queryData);

        if(queryData.city === ""){
            queryData.city = '台北市';
            const state =
            Object.keys(defaultQuery).length === 0
                ? Object.assign({}, getState().activity.query)
                : defaultQuery;
            const query = queryString(state);
            let config = {};
            dispatch(fetching_data({ loading: true }))
            // console.log("query in activity action: ", query);
            fetchObject(`activity/list`, query, config)
                .then(function (response) {
                    if (response.ok) return response.json();
                    else
                        response.json().then(function (err) {
                            //throw new Error(err);
                        });
                })
                .then(function (json) {
                    dispatch(fetchActivityData({ activity: json.data, activityType: "Activity", total:json.total }));
                })
                .catch(function (ex) {
                    console.log("fetch city empty activities error");
                    if (ex.name === 'AbortError') {
                        //console.log('Fetch aborted');
                    } else {
                        alert(VALIDATE_ERROR_CODE(ex.code));
                    }
                });
        }else{
            const state =
            Object.keys(defaultQuery).length === 0
                ? Object.assign({}, getState().activity.query)
                : defaultQuery;
            
            const query = queryString(state);
            let config = {};
            dispatch(fetching_data({ loading: true }))
            fetchObject(`activity/list`, query, config)
                .then(function (response) {
                    if (response.ok) return response.json();
                    else
                        response.json().then(function (err) {
                            //throw new Error(err);
                        });
                })
                .then(function (json) {
                    dispatch(fetchActivityData({ activity: json.data, activityType: "Activity", total:json.total }));
                })
                .catch(function (ex) {
                    console.log("fetch city activities error");
                    //TODO: if TOKEN_INVALID, localStorage.removeItem("myData"); and redirect
                    if (ex.name === 'AbortError') {
                        //console.log('Fetch aborted');
                    } else {
                        console.log(VALIDATE_ERROR_CODE(ex.code));
                    }
                });
        }
    };
};

export const fetchJoin = (defaultQuery = {}) => {
    return (dispatch, getState) => {
        const state =
            Object.keys(defaultQuery).length === 0
                ? Object.assign({}, getState().activity.query)
                : defaultQuery;
        delete state.end;
        delete state.start;
        const query = queryString(state);
        fetchObject(`join/list`, query, '')
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(fetchActivityData({ activity: json.data, activityType: "JoinedActivity" }));
            })
            .catch(function (ex) {
                //console.log("parsing failed", ex);
            });
    };
};


export const fetchAllNotification = (defaultQuery = {}) => {
    let userObj = localStorageUtility('get', 'myData')
    return (dispatch, getState) => {
        var date = new Date();
        var y = date.getFullYear();
        let month = date.getMonth() + 1;
        var m = month < 10 ? '0' + month : month;
        var d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const find = [
            'userId=' + JSON.parse(userObj).id,
            'startTime=' + y + '-' + m + '-' + d
        ]
        const query1 = find.join('&');
        //console.log(query1);
        dispatch(fetching_data({ loading: true }))

        fetchObject(`allNotification`, query1)
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                if(json.id){
                    let payMyMoneyBackActivity = Array.isArray(json.ownActivityMembers) ? json.ownActivityMembers : [];
                    let unpaidActivityList = []; //未付款團名
                    let unpaidActivityName = [];
                    let userContinuousActivity = json && json.activity.filter(activity => activity.frequency !== null && activity.end < moment().valueOf() && activity.notify === true ); // 固定開團
                    //取得有未付款團員的團
                    if (payMyMoneyBackActivity.length > 0) {
                        payMyMoneyBackActivity.forEach( activities => {
                            if (activities.paid === false && activities.activity && activities.activity.fee > 0) {
                                if (unpaidActivityName.indexOf(activities.activity.group_name) === -1) {
                                    unpaidActivityName.push(activities.activity.group_name);
                                    unpaidActivityList.push(activities);
                                }
                            }
                        })
                    }

                    let jactivityType = Array.isArray(json.activityPaid) ? json.activityPaid : [];
                    //取得自已尚未付費的團清單
                    let unpaidJoinActivitylist = [];
                    if (jactivityType.length > 0) {
                        jactivityType.forEach( data => {
                            if (data.paid === false && data.activity && data.activity.fee > 0) {
                                unpaidJoinActivitylist.push(data);
                            }
                        })
                    }


                    // one save I join, another saves I host
                    dispatch(fetchAllNotificationData({ 
                        allNotificationActivity: unpaidActivityList,
                        // activity: unpaidActivitylist,
                        activityType: unpaidJoinActivitylist,
                        userContinuousActivity: userContinuousActivity,
                    }));
                }else{
                    if (json.code === 'TOKEN_INVALID') {
                        localStorageUtility('remove', 'myData')
                        window.location.href = "/login";
                    }
                }
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const getBannerList = type => {
    return dispatch => {
        fetchObject(`landingbanner/list?type=${type}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                const data = {
                    type,
                    banner: json.data
                };
                dispatch(getBannerListData(data));
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const postLeaveActivity = data => {
    return (dispatch, getState) => {
        let options = {
            method: "POST",
            body: JSON.stringify(data),
        };
        fetchObject(`join/cancel`, "", options)
            .then(function (response) {
                if (response.ok) {
                    dispatch(leaveActivity);
                    dispatch(getGroupDetail(data.activity));
                    history.push('/my_info/participated_activities');
                } else {
                    response.json().then(function (err) {
                        //throw new Error(err);
                    });
                }
            })
            .then(function (json) {

            })
            .catch(function (ex) {
                alert("Leave Activity should return json, but it didn't.");
                console.log("parsing failed", ex);
            });
    };
};


export const putjoinpaid = (id, paid) => {
    return (dispatch, getState) => {
        fetchObject(`join/${id}`, '', {
            method: 'PUT',
            body: JSON.stringify(paid),
        })
            .then(function (response) {
                dispatch(fetchPutjoinpaid( {putjoinpaidStatus: 'success'}));

                return response.json();
            })
            .catch(function (ex) {
                console.log('parsing failed', ex);
            });
    };
};

export const updatejoinpaidStatus = () => {
    return (dispatch) => {
        dispatch(fetchPutjoinpaid( {putjoinpaidStatus: 'waiting'}));
    };
};

export const follow = (followee) => {
    return (dispatch, getState) => {
        fetchObject(`follow`, '', {
            method: 'POST',
            body: JSON.stringify(followee),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                if (json.createdAt != null) {
                    localStorageUtility('set', 'follow', JSON.stringify(json))
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex);
            });
    };
};

export const postSignUpLeaveActivity = data => {
    return (dispatch, getState) => {
        let options = {
            method: "POST",
            body: JSON.stringify(data),
        };
        fetchObject(`join/cancel`, "", options)
            .then(function (response) {
                if (response.ok) {
                    dispatch(leaveActivity);
                    dispatch(getGroupDetail(data.activity));
                    history.push('/my_info/participated_activities');
                } else {
                    response.json().then(function (err) {
                        //throw new Error(err);
                    });
                }
            })
            .then(function (json) { })
            .catch(function (ex) {
                alert("Leave Activity should return json, but it didn't.");
                console.log("parsing failed", ex);
            });
    };
};

export const resetSearch = data => {
    return (dispatch, getState) => {
        dispatch(updateSearchQuery(data));
    };
};

export const updateSearch = data => {
    return (dispatch, getState) => {
        const query = getState().activity.query;
        const result = Object.assign(query, data);
        dispatch(updateSearchQuery(result));
    };
};
export const updateSearchHostActivity= data => {
    return (dispatch, getState) => {
        const query = getState().activity.hostActivityQuery;
        const result = Object.assign(query, data);
        dispatch(updateSearchQueryHostActivity(result));
    };
};

export const updateSearchCity = data => {
    return (dispatch, getState) => {
        dispatch(updateSearchQuery(data));
    };
};

export const resetActivity = () => {
    return (dispatch, getState) => {
        dispatch(resetActivityData());
    };
}

export const fetchUpdateActivityData = (id = 0, hash = {}) => {
    return (dispatch, getState) => {
        fetchObject(`activity/${id}`, '', {
            method: 'PUT',
            body: JSON.stringify(hash)
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(json) {
                dispatch(getMyInfoActivityData(id));
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    };
};

