import React from "react";

import {Wrapper} from './styled.js';

export default ({paymentTypeText, value, onChange }) => (
    paymentTypeText ?
    <div className="form-control">
        {/* <label>打球費用：</label> */}
        {/* <div> */}
        <Wrapper>
            <span className="paymentType__tag">
                {paymentTypeText}
            </span>
        
        
            <input
                value={value || null}
                onChange={onChange}
                placeholder="請輸入金額"
                type="number"
                step="50"
                min="0"
                max="999"
                style={{
                    display: 'inline',
                    // 114 = 7em(102px) + 1*2(border) + 5*2(padding)
                    width: 'calc(100% - 114px)',
                    paddingLeft: '5px',        
                    borderBottomLeftRadius: '0px',
                    borderTopLeftRadius: '0px',
            
                }} />
        </Wrapper>
        {/* </div> */}
    </div>
    :
    <div className="form-control">
        <label>打球費用：</label>
        <div>
            <input 
            value={value || null}
            onChange={onChange} 
            placeholder="請輸入金額" 
            type="number" 
            step="50" 
            min="0" 
            max="999" 
            style={{width: 'calc( 100% - 6.98px )', paddingLeft: `5px`}}
            />
        </div>
    </div>
);