const initialState = {
	userLoginLoading: false,
	isUserLogin: false,
}

export default function loginReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOADING_DATA': {
			// console.log(action.payload);
			return {
				...state,
				userLoginLoading: action.payload
			}
		}
		case 'UPDATE_USER_LOGIN': {
			return {
				...state,
				isUserLogin: action.payload
			}
		}
		default:
			return state
	}
}
