import styled from 'styled-components';

export const Wrapper = styled.button `
    width:100%;
    color: #000;
    border: unset !important;
    padding: 0 !important;
    margin-top: 20px;

    a {
      width: calc(100% - 2px);
      display: flex;
      text-align: center;
      align-items: center; 
      justify-content: center;
      cursor: pointer;

      padding: 10px 0;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 5px;

      i {
        width: 40px;
        color: black;
        font-size: 40px;
        line-height: 20px;
        vertical-align: middle;
      } 

      p ,.text{
        width: 5rem;
        font-size: 20px;
        font-weight: 500;
        color: #000;
      }
    }




`

