
const initialState = {
	userRent: [],
	userEmailConfirm: {},
	racketProgram:{},
	racketInformation:{},
    racketOrderDetail: {},
    shouldLoadingOpen: true,
    racketOrderList: [],
    isGetPaymentRes: false,
    isCancelOrReplaceDone: false,
}

export default function rentReducer(state = initialState, action) {
	switch (action.type) {

		case 'GET_USER_RENT': 
			return { ...state, userRent: action.payload }

		case 'CONFIRM_USER_EMAIL': 
			return { ...state, userEmailConfirm: action.payload }

		case 'SET_EMAIL_USED_STATUS': 
			return {
				...state,
				userEmailConfirm: action.payload
			}

		case 'SET_RACKET_PROGRAM': 
			return {
				...state,
				racketProgram: action.payload
			}
		
		case 'SET_RACKET_INFORMATION': 
			return {
				...state,
				racketInformation: action.payload
			}

		case 'GET_RACKET_ORDER_LIST': 
			return { ...state, racketOrderList: action.payload };

        case 'FETCH_RACKET_ORDER_DETAIL_DATA': 
            return { 
            	...state, 
            	racketOrderDetail: action.payload 
            };

        case 'UPDATE_CANCEL_PROGRAM_OR_REPLACE_RACKET':
        	return {
        		...state,
        		isCancelOrReplaceDone: action.payload 
        	};
        	
        case 'DELETE_REPLACE_DATA':
            return { 
            	...state, 
				status: action.payload 
			};

        case 'SHOULD_LOADING_OPEN': 
            return { ...state, shouldLoadingOpen: action.payload, };

        case 'SET_IS_GET_PAYMENT_RES':
        	return { ...state, isGetPaymentRes: action.payload, };
		    
		default: return state
	}
}
