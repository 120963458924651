import styled from "styled-components";
export const Wrapper = styled.div `
	display: flex;
	.date{
		flex: 1;
		display: inline-block;
        vertical-align: bottom;
		div.react-datepicker__input-container{
			width:100%;
			input[type="text"] {
				padding-left: 5px;
			}
		}
	}	
	.time{
		flex: 1;
		margin-left: 10px;
		display: inline-block;
        vertical-align: bottom;
		select{
			width:100%;
		}
	}
`