import { createAction } from 'redux-actions';
import querystring from 'querystring';
import { history } from '../index';

import {
	MEDIA_SET_LIST,
	MEDIA_UPDATE_LIST,
	MEDIA_TAG_LIST_STATUS,
	MEDIA_TAG_LIST_BEGIN,
	MEDIA_TAG_LIST_SUCCESS,
	// MEDIA_TAG_LIST_FAILURE,
	MEDIA_TAG_SET_LIST,
	MEDIA_SET_DATA,
	UPDATE_MEDIALIST_INTERACTION,
	UPDATE_MEDIA_INTERACTION,
	UPDATE_IS_MEDIA_LIKE_CLICK,
} from '../constants/actionTypes';

import {
	fetchObject,
	// VALIDATE_ERROR_CODE,
} from "./fetchModule";

export const setMediaList = createAction(MEDIA_SET_LIST);
export const updateMediaList = createAction(MEDIA_UPDATE_LIST);
export const fetchMediaTagListStatus = createAction(MEDIA_TAG_LIST_STATUS);
export const setMediaTagList = createAction(MEDIA_TAG_SET_LIST);
export const setMedia = createAction(MEDIA_SET_DATA);
export const putMediaListInteraction = createAction(UPDATE_MEDIALIST_INTERACTION);
export const putMediaInteraction = createAction(UPDATE_MEDIA_INTERACTION);
export const putIsMediaLikeClick = createAction(UPDATE_IS_MEDIA_LIKE_CLICK);

export const getMediaList = ( query ) => dispatch => {
	
	let params = {};
	let sort = query.sort
	let search = query.search
	let tagId = query.tagId
	let tag = query.tag
	let limit = query.limit
	let skip = query.skip

	sort && Object.assign(params, { sort });
	search && Object.assign(params, { search });
	Number.isInteger(tagId) && Object.assign(params, { tagId });
	limit && Object.assign(params, { limit });
	tag && Object.assign(params, { tag });
	(skip || skip === 0 ) && Object.assign(params, { skip });

	params = querystring.stringify(params);

	return fetchObject('media/list', params)
		.then(response => {
			// known error
			if (response.status === 400) {
				alert("查無資料或操作逾時，請稍後再試一次");
				return history.replace('/error_page');
			}

			// unknow error
			if (!response.ok) {
				//throw new Error("Bad response from server");
			}

			// ok
			return response.json().then(json => {

				if( skip >0){
					dispatch(updateMediaList(json));
				}else{
					dispatch(setMediaList(json));
				}
			})
		})
		.catch(err => {
			alert(err.message || err);
		});
};

export const getMediaTagList = () => dispatch => {
	dispatch(fetchMediaTagListStatus({ status: MEDIA_TAG_LIST_BEGIN }));

	return fetchObject('tag/list')
		.then(response => {
			// known error
			if (response.status === 400) {
				throw new Error("查無資料或操作逾時，請稍後再試一次");
			}

			// unknow error
			if (!response.ok) {
				//throw new Error("Bad response from server");
			}

			// ok
			return response.json().then(json => {
				dispatch(fetchMediaTagListStatus({ status: MEDIA_TAG_LIST_SUCCESS }));
				dispatch(setMediaTagList(json));
			})
		})
		.catch(err => {
			dispatch(fetchMediaTagListStatus({ status: MEDIA_TAG_LIST_SUCCESS, err }));
			alert(err.message || err);
		});
};

export const getMedia = id => dispatch => {
	// console.log("get media/`id`");

	return fetchObject(`media/${id}`)
		.then(response => {
			// known error
			if (response.status === 400) {
				alert("查無資料或操作逾時，請稍後再試一次");
				return history.replace('/error_page');
			}

			// unknow error
			if (!response.ok) {
				//throw new Error("Bad response from server");
			}

			// ok
			return response.json().then(json => {
				dispatch(setMedia(json));
				return json;
			})
		})
		.catch(err => {
			alert(err.message || err);
		});
};

// update the like and share about the media
export const updateMediaListInteraction = (data) => {
	// console.log(data)
	return (dispatch) => {

		// var userId = data && data.userId;
		// delete data.user

		// data include id and type, but id 不需要傳給後端
		var id = data && data.id;
		fetchObject(`media/${id}`, "", {
			method: "PUT",
			body: JSON.stringify(data),
		})
			.then(function (response) {

				// response.ok is boolean, ok 好像是 成功打到 的意思
				if (response.ok) {
					// console.log(" in response ok");

					// update status . id . list 
					// get media (id) api
					// dispatch(listMedia());

				}

				return response.json()
			})
			.then(function (json) {
				// console.log(json)
				if( !json.code){
					dispatch(putMediaListInteraction( { ...json, type: data.type}));
				}else {
					throw new Error(json.code);
				}
			})
			.then( ()=>{}, err =>{
				switch(err.message){
					case 'USER NO VERIFY!':
						alert('信箱尚未驗證成功，請前往個人資訊頁驗證')
						history.push('/my_info/profile');
						break;
					case 'VOTE EXIST!':
						alert('今日已進行此操作囉，請明天再蒞臨')
						break;
					case 'VOTE ERROR!':
						alert('操作異常，請再試一次')
						break;
					default:
						alert('操作逾時，請重新登入或聯絡客服')
						break;
				}
			})
			.catch(function (ex) {
				console.log('parsing failed', ex)
			})
	}
}

export const updateMediaInteraction = (data) => {
	return (dispatch) => {

		// var userId = data && data.userId;
		// delete data.user

		// data include id and type, but id 不需要傳給後端
		var id = data && data.id;
		fetchObject(`media/${id}`, "", {
			method: "PUT",
			body: JSON.stringify(data),
		})
			.then(function (response) {

				// response.ok is boolean, ok 好像是 成功打到 的意思
				if (response.ok) {
					// console.log(" in response ok");

					// update status . id . list 
					// get media (id) api
					// dispatch(listMedia());

				}

				return response.json()
			})
			.then(function (json) {
				// console.log(json)

				if( !json.code){
					dispatch(putMediaInteraction( { ...json, type: data.type}));
				}else {
					throw new Error(json.code);
				}
			})
			.then( ()=>{}, err =>{
				switch(err.message){
					case 'USER NO VERIFY!':
						alert('信箱尚未驗證成功，請前往個人資訊頁驗證')
						history.push('/my_info/profile');
						break;
					case 'VOTE EXIST!':
						alert('今日已進行此操作囉，請明天再蒞臨')
						break;
					case 'VOTE ERROR!':
						alert('操作異常，請再試一次')
						break;
					default:
						alert('操作逾時，請重新登入或聯絡客服')		
						break;
				}
			})
			.catch(function (ex) {
				console.log('parsing failed', ex)
			})
	}
}

// for real-time like number change
export const updateIsMediaLikeClick = (data) => {
	// console.log(data)
	return (dispatch) => {
		dispatch(putIsMediaLikeClick( { ...data }));
	}
}


