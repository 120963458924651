import styled from 'styled-components';

export const Wrapper = styled.div`
    div.header {
        display: flex;
        align-items: baseline;

        > * {
            flex: none
        }
    }
    h2 {
        margin: 20px 10px 10px 10px;
        // font-size: 18px;
    }
    .all,
    .unread {
        flex: 1;
        font-size: .8rem;
        text-align: center;
        cursor: pointer;
        span.more {
            color: #fff;
            background-color: #DF1C2C;
            width: 20px;
            height: 20px;
            border-radius: 999rem;
            font-size: 10px;
            line-height: 20px;
            display: inline-block;
            margin-left: 1rem;
        }
        &.click {
            background-color: rgb(217, 29, 48);
            color: #fff;
        }
    }
    .react-tabs {
        position: relative;
        top: -5px;
        .react-tabs__tab {
            padding: 10px 0;
            position: relative;
            list-style: none;
            cursor: pointer;
            width: 50%;
            text-align: center;
            display: inline-block;
            vertical-align: bottom;
            border: none;
            font-size: .8rem;
        }
    }
`;

export const ListContent = styled.div`
    width: 100%;
    height: auto;
    a {
        text-decoration: none;
        color: inherit;
    }
    .list {
        display: -webkit-flex;
        display: flex;
        margin: 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid #eee;
        cursor: pointer;
        @media (max-width: 480px) {
            margin: 0 20px;
            padding: 10px 0;
        }
        .Out_left {
            -webkit-flex: none;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            @media (max-width: 480px) {
                width: 40px;
            }
            .pic {
                display: flex;
                flex: 1;
                width: 60px;
                height: 60px;
                border-radius: 999rem;
                overflow: hidden;
                background: url('/assets/images/DefaultUserImg.png');
                @media (max-width: 480px) {
                    width: 40px;
                    height: 40px;
                }
                img {
                    width: auto;
                    height: 60px;
                    margin: auto;
                    @media (max-width: 480px) {
                        height: 40px;
                    }
                }
            }
            .avatar {
                width: 50px;
                height: 50px;
                border: 2px solid #ccc;
                border-radius: 999rem;
                overflow: hidden;
                position: relative;
                @media (max-width: 480px) {
                    width: 30px;
                    height: 30px;
                }

                .avatar_photo{
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    background-color:#fff;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .Out_right {
            flex: 1;
            display: flex;
            margin-left: 10px;
            .date {
                width: 100%;
                height: 16px;
                font-size: .8rem;
                text-align: right;
            }
            .inside {
                display: flex;
                width: 100%;
                align-items: center;
            }
            .In_right {
                -webkit-flex: none;
                flex: none;
                // width: 35px;
                .notice {
                    background-color: rgb(217, 29, 48);
                    border-radius: 999rem;
                    color: #fff;
                    font-size: .8rem;
                    padding: 2px 2px;
                    text-align: center;
                }
                .arrow {
                    line-height: 80px;
                    text-align: center;
                }
            }
            .In_left {
                flex: 1;
                .name {
                    height: 1.4rem;
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 1.4rem;
                    // margin-bottom: 5px;
                    overflow: hidden;
                }
                .message {
                    width: 100%;
                    height: auto;
                    font-size: 1rem;
                    line-height: 1.4rem;
                    text-overflow: ellipsis; 
                }
            }
        }
    }
`;
