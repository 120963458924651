import styled from "styled-components";

export const Wrapper = styled.div`
    display: block;
    position: fixed;
    z-index: 98;
    background: #da263c;
    width: 100%;
    bottom: 0;
    .nav{
        width: 640px;
        height: auto;
        padding: 0.5rem 0;
        margin: auto;
        display: flex;
        button {
            margin: 0;
            padding: 0;
            border: 0px; 
            outline: none;
            background-color: transparent; 
        }
        a.footerLink, button.footerLink, div.footerLink{
            flex: 1;
            text-decoration: unset;
            position: relative;
            height: calc( 50px + 1.8rem);
            // margin: 0 0.5rem;
            color: #fff;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                top: 50px;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 1.8rem;
                line-height: 1.8rem;
                font-size: 1.1rem;
                font-weight: 500;
                text-align: center;
            }
            &:first-child{
                border-right: 1px solid #fff;
                &::after {
                    content: '臨打報名';
                    // background-image: url('/assets/icons/SignUpMenu.png');
                }
                // &::before {}
            }
            &:nth-child(2){
                border-right: 1px solid #fff;
                &::after {
                    content: '開團找我';
                    // background-image: url('/assets/icons/SignUpMenu.png');
                }
                // &::before {}
            }
            &:nth-child(3){
                border-right: 1px solid #fff;
                &::after {
                    content: '羽球知識家';
                    // background-image: url('/assets/icons/SignUpMenu.png');
                }
                // &::before {}
            }
            &:nth-child(4){
                border-right: 1px solid #fff;
                &::after {
                    content: 'Live 直播';
                    // background-image: url('/assets/icons/SignUpMenu.png');
                }
                // &::before {}
            }
            &:last-child{
                &::after {
                    content: 'My Bonny';
                    // background-image: url('/assets/icons/SignUpMenu.png');
                }
                // &::before {}
            }

            img{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 50px;
                height: 50px;
                border-radius: 999rem;
            }

            .msg_count{
                display: flex;
                vertical-align: middle;
                // background-color: rgb(162, 28, 46);
                background-color: rgba(162, 28, 46, 0.6);
                border-radius: 999rem;
                color: #fff;
                font-size: 10px;
                width: 1.8rem;
                height: 1.8rem;
                // line-height: 1.8rem;
                // padding: 2px 2px;
                // margin: auto 0 auto .5rem;
                text-align: center;
                position: absolute;
                z-index: 999;
                top: 0px;
                right: 10px;
                align-items:center; 
                justify-content:center;
                // box-shadow: 0 1px 10px 1px rgba(0,0,0,0.4);
            }
        }
    }

    .nav.android {
        a.footerLink, button.footerLink, div.footerLink {
            &:first-child{
                &::after {
                    content: '首頁';
                }
            }
            &:nth-child(2){
                &::after {
                    content: '臨打報名';
                }
            }
            &:nth-child(3){
                &::after {
                    content: '開團找我';
                }
            }
            &:nth-child(4){
                &::after {
                    content: '羽球知識家';
                }
            }
            &:last-child{
                &::after {
                    content: 'My Bonny';
                }
            }
        }
    }

    @media (max-width: 480px) {
        // position: relative;
        .nav{
            width: 100vw;
            a.footerLink, button.footerLink, div.footerLink{
                height: calc( 40px + 1rem );
                &::after {
                    top: 40px;
                    font-weight: 400;
                    font-size: 10px;
                    height: 1rem;
                    line-height: 1rem;
                }
                img{
                    width: 40px;
                    height: 40px;
                }
                .msg_count{
                    right: 2px;
                    height: 1rem;
                    background-color: rgba(162, 28, 46, 1);
                }
            }
        } 
    }
`;
