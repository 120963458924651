import { createAction } from 'redux-actions';
import { fetchObject } from './fetchModule';
import {
	sendUserInfo,
} from '../actions';

export const setImgUrl = createAction("SET_IMG_URL");
export const uploadImgStatus = createAction("UPLOAD_IMG_STATUS");

export const uploadImg = file => (dispatch, getState) => {
	if (getState().imageUploadReducer.uploadStatus) {
		console.log('image uploading')
		return Promise.resolve();
	}

	dispatch(uploadImgStatus('uploading'));

	const body = new FormData();
	body.append('file', file);

	return fetchObject('file/upload', '', {
		method: 'post',
		body,
	})
		.then(response => {
			// known error
			if (response.status === 400) {
				return response.json().then(json => {
					//throw new Error(VALIDATE_ERROR_CODE(json.code));
				})
			}

			// unknow error
			if (!response.ok) {
				//throw new Error('Bad response from server');
			}

			// ok
			return response.json().then(json => {
				dispatch(setImgUrl(json.files[0].extra.Location));
				dispatch(uploadImgStatus(''));
				return json.files[0].extra.Location;
			})
		})
		.catch(err => {
			dispatch(uploadImgStatus(''));
			alert(err.message);
		});
};

export const uploadAvatar = file => (dispatch, getState) => {
	return dispatch(uploadImg(file))
		.then(url => {
			if (url) {
				return dispatch(sendUserInfo({ avatar: url }))
			}
		});
};

export const uploadBackground = file => (dispatch, getState) => {
	return dispatch(uploadImg(file))
		.then(url => {
			if (url) {
				return dispatch(sendUserInfo({ background: url }))
			}
		});
};
