import React, { Component } from "react";
import { Wrapper, ButtonGroup } from "./styled";
import { Button, Modal } from "../../../Common";
import { shareToLine, copyLink, shareToFacebook } from '../../../../utilities';

class ShareButton extends Component {

    handleShareToLine = () => {
        const url = this.props.url;

        let shareText = `這裡有個很棒的羽球團『${this.props.groupName}』，你也快一起來參加吧！`
        shareToLine(shareText, url)
    }

    handleShareToFacebook = () => {
        const url = this.props.url;

        let title = '' 
        
        shareToFacebook(title, url)
        

    }

    render() {
        const { url } = this.props;
        return (
            <Wrapper>
                <ButtonGroup>
                    <Button className='ButtonGroup_button ButtonGroup_button_copyLink' onClick={() => { copyLink('/quick_sign_up_activity', true, `?${this.props.activityId}`) }} copyLink={url}>
                        <p>複製報名連結</p>
                    </Button>
                    <Button className='ButtonGroup_button'>
                        <Modal
                            title="分享到"
                            onClick={(e) => {
                                this.handleSubmit(e);
                            }}
                            noButton={true}
                            btnTheme="one"
                            modalContent={
                                <div style={{ textAlign: 'center', display: 'flex' }}>
                                    {/* Line */}
                                    <div onClick={this.handleShareToLine} style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                        <img alt="" src="/assets/images/lineShareicon.png" />
                                        <br />
                                        <div>Line</div>
                                    </div>
                                    {/* Facebook */}
                                    <div onClick={this.handleShareToFacebook} style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                        <img alt="" src="/assets/images/fbShareicon.png" />
                                        <br />
                                        <div>Facebook</div>
                                    </div>
                                    {/* CopyLink */}
                                    <div onClick={() => { copyLink('/quick_sign_up_activity', true, `?${this.props.activityId}`) }} style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                        <img alt="" src="/assets/images/linkShareicon.png" />
                                        <br />
                                        <div>複製連結</div>
                                    </div>
                                </div>
                            }
                        >
                            <div className="ButtonGroup_button_child" onClick={this.openModal}>
                                <i className="material-icons">share</i>
                                <p>我要分享</p>
                            </div>
                        </Modal>
                    </Button>
                </ButtonGroup>
            </Wrapper>
        );
    }
}

ShareButton.defaultProps = {
    url: "",
}

export default ShareButton;
