import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;

    select{
        width:100%;
    }

    .image-uploader-section{
        width: 100%;
    }  

    .view_follow{
        text-align: center;
    }

    .main-form{
        p{
            font-size: 16px;
            line-height: 2.2em;
            @media (max-width: 480px) {
                 font-size: 14px;
                 line-height: 2em;
             }
        }
    }

    button.facebook{
        width: 100%;
        margin: auto;
    }

    .b1{
        text-align: center;
        width:100%;
        background-color: #D5D5D5;
        border: 0 none;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: large;
        font-weight: bolder;
        color: #fff;
        border-radius: 5px;
        font-weight: bolder;
        img{
            padding-left: 5px;
            top: 5px;
            position: relative;
        }
    }

    .alwaysJoin {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        width: calc( 35px + 5rem );

        .checkbox {
            flex: 0 0 35px;
            display: flex;

            input[type="checkbox"] {
                width: 100%;
                height: 35px;
            }
        }
        h3 {
            font-weight: bold;
            width: 5rem;
        }
    }

    .flex{
        display: flex;
    }

    .alignCenter{
        align-items: center;
    }

    .mobileVerifyButton{
        height: 37px;
        width: calc( 14px * 8 );
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #fff;
        font-size: 14px;
        border-radius: 0 5px 5px 0;
        background-color: #DF1C2C;

        &:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }

    .mobileVerifySubmit{
        height: 37px;
        width: calc( 14px * 6 );
        margin-left: 5px;
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #fff;
        font-size: 14px;
        border-radius: 5px;
        background-color: #2f82bf;

        &:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }

    .mobileVerifyCancel{
        height: 37px;
        width: auto;
        margin-left: 10px;
        cursor: pointer;
        text-align: center;
        line-height: 37px;
        color: #000;
        font-size: 12px;
        text-decoration: underline;

        &:hover{
            color:#DF1C2C;
        }
    }
    
`

export const LoginWrap = styled.div`
    h2 {
        text-align: center;
        font-size: 1.4rem;
        fontW-wight: bold;
        line-height: 2rem;
        margin-top: 20px;
    }
    input[type="text"],
    input[type="password"] {
        width: calc(100% - 0.85rem);
        margin: 10px 0;
    }
    label {
        font-size: 14px;
    }
    .goToLogin {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto 20px;

        h3, h4 {
            color: #000;
        }
        h4 {
            font-weight: bold;
            text-decoration: underline;
        }
    }
    .line, .facebook, .google {
        color: white;
        display: inline-block;
        vertical-align: bottom;
        cursor: pointer;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        width: 31%;
        padding: 14px 0;
        text-align: center;
        margin: 5px 1% 10px;
    }
    .line{
        background-color: #00c300;
    }

    .facebook{
        background-color: #3b5998;
    }

    .google{
        background-color: #dd4b39;
    }

`

export const TabHeader = styled.div`
    margin: 0 -40px;
    border-bottom: 1px solid #DF1C2C;
    .tab{
        width: 50%;
        display: inline-block;
        vertical-align: bottom;
        text-align: center;
        padding: 10px 0px;
        &.active {
            background: #DF1C2C;
            color: #fff;
        }
    }
` 

