import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin-bottom: 40px;
    @media (max-width: 480px) {
        margin-bottom: 30vh;
    }
    .main-form{
        // margin: 20px 20px 40px 20px;
        // width: calc(100% - 40px);
        .form-control{
            input[type="text"]{
                width: calc( 100% - 1.98px);
                height: calc( 35px - 1.98px);
                border: 1px solid #ccc;
                outline: none;
                padding: 0;
                margin: 0;
            }
            input[type="text"].react-autosuggest__input{
                height: 35px;
            }
            select{
                width: 100%;
            }
        }

        // 場地名稱
        .place_name{
            // width: calc( 100% - 6em);
            width: 100%;
            display : inline-block;
            vertical-align: bottom;
            input[type="text"]{
                width:100%;
                padding-left: 5px;
                border-right-width: 0px;
            }
            .place_name__box{
                display: flex;
                align-items:center;
                .form-control{
                    flex: 1;
                }
                .place_name_findAddress{
                    width: 6em;
                    height: 35px;
                    color: white;
                    background-color: rgb(47, 130, 191);
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    cursor: pointer;
                    align-items:center; 
                    justify-content:center;        

                    display: flex;;
                    text-align: center;

                    i {
                        width: 1rem;
                        height: 35px;
                        line-height: 35px;
                        margin: auto 0 auto 1rem;
                        align-self: center;
                    }
                    
                    &__span{
                        flex: 1;
                        font-size: 14px;
                        height: 35px;
                        line-height: 35px;
                        margin: auto;
                        align-self: center;
                        // display: inline-block;
                        // vertical-align: bottom;
                    }
                }
            }
        }

        // .place_name_findAddress{
        //     width: 6em;
        //     height: 35px;
        //     color: white;
        //     background-color: rgb(47, 130, 191);
        //     border-top-right-radius: 5px;
        //     border-bottom-right-radius: 5px;
        //     cursor: pointer;

        //     display: inline-block;
        //     text-align: center;
        //     margin-bottom: 5px;
        //     vertical-align: bottom;
            
        //     &__span{
        //         font-size: 14px;
        //         line-height: 35px;
        //         display: inline-block;
        //         margin-bottom: 5px;
        //         vertical-align: bottom;
        //     }
        // }
            // don't rename, or it will not shows a icon
            .material-icons{
                // width: 8%;
                line-height: 35px;
            
                margin-bottom: 5px;
                vertical-align: bottom;
            }
            
        
        // payment method
        //.form-control:nth-child(11){
        .paymentType__chooseBox{            
            width:100%;
            display : flex;
            align-items:center; 
            justify-content:center; 
            flex-wrap: wrap;

            label {
                width: 100%;
            }
            .paymentType__label {
                flex: 1;
                text-align:center;
                color: #ced4da;
                border: 1px solid #ced4da;
                border-radius: 999rem;
                padding: 4px 0;
                margin: 0 5px;
                cursor: pointer;

                &.active{
                    background-color: rgba(218,38,60,1);
                    border: 1px solid #da263c;
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
        
    }
    select{
        width:100%
    };
    .features{
        border: 1px solid
        padding: 8px 10px 8px 10px
        border-color: #D8D8D8
        border-radius: 20px
        text-align: center
        margin: 7px
        width: 80px
        display:inline-block
    }
`
