import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin-bottom: 100px;
    select{
        width:100%;
    }
    pre{
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align:left;
        font-size:14px;
        line-height:1.5rem;
        padding: 5px;
        margin: 0;
        border:2px solid #da263c;
        display:none;
        font-family: 'Noto Sans TC', 'Microsoft JhengHei', sans-serif, Arial;
    }
    pre.open{
        display: block;
        flex: 0 0 calc( 100% - 14px);

        @media screen and (max-width: 439px){
            display: none;
        }
    }
    a {
        text-decoration:none;
    }
    button > a{
        color: #fff;
    }
    button:disabled{
        background-color:grey;
    }
    .features{
        border: 1px solid;
        padding: 8px 10px 8px 10px;
        border-color: #D8D8D8;
        border-radius: 20px;
        text-align: center;
        margin: 7px;
        width: 80px;
        display: inline-block;
    }
    .hide{
        display: none !important;
    }
    .title{
        width:100%;
        margin: 10px 0px;

        h2.color-red{
            color:#ce243b;
        }
    }
    .margin-all-20px{
        margin: 20px;
    }
    .cursor-pointer{
        cursor: pointer;
        color: #DF1C2C;
    }
    #equipment{
        width: calc(100% - 40px);
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
        .item{
            margin: 5px;
            width: calc((100% - 28px) / 2);
            border: 2px solid #fdfdfd;
            height: auto;
            border-radius: 5px;
            overflow: hidden;
        }
            .item > input[type=radio]{
                position: absolute;
                z-index: -999;
            }
            .item > label{
                display: inline;
                cursor: pointer;
            }
            .item > input[name=racket_id]:checked + label > .pic .checked_img{
                display: block;
            }
            .item > .description_id{
                position: relative;
                display: flex;
                align-items: center; 
                justify-content: center;
                flex-wrap: wrap;
            }
            .item > .description_id > input[type=radio]{
                position: absolute;
                z-index: -999;
            }
            .item > .description_id > label{
                margin: 0 !important;
            }
            .item > .description_id > input[type=radio]:checked + label > pre{
                display: block;
                border: 2px solid #da263c;
            }
            .pic{
                width: calc( 100% - 4px);
                height: auto;
                border: 2px solid #da263c;
                display: block;
                background-size: cover;
                background-color: #fff;
                background-position: center center;
                background-repeat: no-repeat;
                position: relative;

                img{
                    width: 100%;
                    height: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                div.checked_img{
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: rgba(218,38,60,.9);
                    background-image: url('../assets/images/program_check.png');
                }
            }
            .other-pic {
                background-color: #da263c;

                img.checked_img{
                    display: none;
                    background-color: rgba(255,255,255,0);
                }
            }
            .description_id > .name{
                background-image: url(./assets/icons/sharp_arrow_drop_down_white_18dp.png);
                background-repeat: no-repeat;
                background-position: 100% 50%;
                background-size: 2rem; 

                @media screen and (max-width: 439px) {
                    background-size: 1.2rem;  
                }
            }
            .description_id > .name.open{
                @media screen and (min-width: 440px){
                    background-image: url(./assets/icons/sharp_arrow_drop_up_white_18dp.png);
                }
            }
            #racketDescription{
                border: 2px solid #da263c;
            }
            #racketDescription.close{
                display: none;
            }
        .clear{
            clear: both;
        }
    .button-style{
        text-align: center;
        width: calc(100% - 40px);
        margin: 10px;
        background-color: #DF1C2C;
        border: 0 none;
        padding: 10px;
        font-size: large;
        font-weight: 500;
        color: #fff;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        float: right;
    }
    a.button-style{
        width: 100%;
        display: block;
        padding: 10px 0;
    }
    .agree{
        float: left;
        margin: 0 15px;
    }

    .plan{
        float:left
        margin: 5px;
        width: calc(100% - 14px);
        border: 2px solid #fdfdfd;
        height: 300px;
        border-radius: 5px;
        overflow: hidden;

        input[type=radio]{
            position: absolute;
            z-index: -999;
        }
        label{
            display: inline;
            cursor: pointer;
        }
        input[type=radio]:checked + label > .text {
            width:calc( 100% - 4px );
            height:calc( 100% - 44px );
            border: 2px solid #da263c;
        }
    }

    .name{
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background-color: #da263c;
        font-size: .9rem;
        font-weight: bold;
        color: #fff;
        overflow: hidden;
        display: block;
        cursor: pointer;
    }

    .clear{
        clear:both;
    }

    // LightBox ----- 商品簡介
    .lightBox-item{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-item.close{
        display:none;
    }
        .lightBox-item-Content{
            width:320px;
            height:500px;
            max-height: calc(100vh - 197px);    
            position: absolute;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            padding: 20px;
            margin:auto;
        }
            .lightBox-item-Content > .CloseBTN{
                border-radius:999rem;
                border:1px solid #ccc;
                background-color:#fff;
                width:20px;
                height:20px;
                line-height:20px;
                text-align:center;
                font-weight:bold;
                font-size:14px;
                cursor:pointer;
                float:right;
            }
            .lightBox-item-Content > .LB-C-Pic{
                width:100%;
                height:200px
                border:1px solid #ccc;
                border-radius:5px;
                margin-bottom:20px;
            }
            .lightBox-item-Content > .LB-C-Name{
                width:100%;
                hidden:30px;
                line-height:30px;
                font-size:20px;
                font-weight:bold;

            }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            margin: auto;

            @media screen and (max-width: 439px) {
                width: 100vw;
                height: 100%;
                max-height: calc( 100vh - 139px );
                margin: 57px auto 72px auto;
                padding: 0 0 10px;
                border-radius: 0px;
            }

            .lightBox-explain-Content-inside{
                padding: 20px;
                @media screen and (max-width: 439px) {
                    height: calc( 100% - 115px );
                    overflow-y: scroll;
                    padding-bottom: 0px;
                }

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#fff;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description {
                    pre {
                        display: block;
                        border: unset;
                    }
                    ul{
                        padding:0;  

                        li{
                            list-style-type:none;
                        }
                    }
                    ul.list-style-type-number{
                        li{
                            list-style-position: inside;
                            list-style-type: decimal;
                        }
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }
        }
        @media screen and (min-width: 440px){
            #lightboxIntroduction {
                display none;
            }
        }

        // 縣市地址 style設定
        .cityAddress {
            width: 100%;
            display: flex;
            .select {
                width: 6rem;
                margin-right: 5px;

                select {
                    padding-left: 1em;
                }
            }
            input[type="text"] {
                flex: 1;
                margin-left: 5px;
            }
        }
        textarea {
            font-size: 14px;
            width: calc( 100% - 2em - 2px);
            min-height: 4em;
            padding: 5px 1em;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(206, 212, 218);
            border-radius: 5px;
        }
`

export const Mode = styled.div `
    width: calc(100% - 40px);
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .title {
        flex: 0 0 100%;
    }

    .label {
        margin: 10px 0;
        flex: 0 0 100%;
    }

    .field {
        flex: 0 0 100%;
    }

    .array {
        flex: 0 0 50%;
    }

    input{
        height: 35px;
    }

    select{
        height: 37px;
        font-size: 0.9rem;
    }

    .color-red{
        color: #e40426;
    }

    .font-size-big {
        font-size: 1.4rem;
    }

    .font-weight-bold{
        font-weight: bold;
    }
}
`

export const UserInformation = styled.div `
    width: calc(100% - 40px);
    margin: auto;
    display: block;
    
    input{
        height:35px;
    }
    .label {
        margin: 10px 0;
    }
    .accountAddress{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        input.mail{
            flex: 1;
            height:35px;
            display:inline-block;
            vertical-align: bottom;
            border-bottom-right-radius:0;
            border-top-right-radius:0;
            border-right-width: 0;
        }
        .login{
            font-size: 12px;
            width: calc( 14px * 6 );
            height:37px;
            line-height:37px;
            text-align:center;
            vertical-align: bottom;
            border:0;
            border-bottom-right-radius:0.25rem;
            border-top-right-radius:0.25rem;
            background-color:#da263c;
            cursor:pointer;
            color:#fff;
            font-weight:500;
            text-decoration:none;
        }
        .login:hover{
            -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
            color:#eee;
        }
    }
    select{
        width:100%;
        height:37px;
        font-size: 14px;
    }
    .checkBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 32px 0 10px;

        input[type=checkbox]{
            width: 2rem;
            height: 3rem;
        }
        .field{
            font-size:14px;
            height: 3rem;
            flex: 1;
        }
    }
    .button-style{
        width:100%;
        margin:auto;
        float:none;
    }
    ul{
        font-size:14px;
        margin-top:40px;
        li{
            list-style:disc;
            list-style-position:inside;
            margin-bottom:5px;
        }
        li:first-child{
            list-style:none;
        }
    }
`