import styled from 'styled-components';
export const Wrapper = styled.div `

background-color: #d2d2d2;

@media (max-width: 480px) {
	padding-bottom: 120px;
}

#WRAPPER{
	width:100%;
	max-width: 440px;/* 限制最大寬不超過440px */
	margin:0 auto;
	font-size: 8px; /* 指使文字em的標準 */
}

/* ===影片區=== */

.iframe-16x9 {
    position: relative;
    padding-bottom: 50.25%;
    padding-top: 35px;
	
    height: 0;
    overflow: hidden;
}
.iframe-4x3 {
    position: relative;
    padding-bottom: 75%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}
.iframe-16x9 iframe, .iframe-4x3 iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}



/* ===頁首區=== */

#HEADER{
	width: 100%;
	height: auto;
	position: relative;
}
#HEADER .HEADER-buy{
	position: absolute;
	right:18px;
	bottom:50px;
}
@media screen and (max-width: 640px) {
	#HEADER .HEADER-buy{
	bottom:30px;
}
}
#HEADER .HEADER-buy img{
	width:150px;
}


/* ===主選單區=== */

#NAV{
	background-color:#F90;
	overflow:hidden;
}

#NAV li{
	float:left;
	display:block;
}



/* ===內容區=== */

.CONTENT-AREA{
	position: relative;
}
.CONTENT-AREA-BUY{
	position:absolute;
	right:20px;
	bottom:10px;
}
.CONTENT-AREA-BUY img{
	width: 70px;
	margin-right: 10px;
}

.PROGRAM{
	text-align: center;
}
.PROGRAM>div{
	font-size: 21px;
    color: white;
	background: #b10514;
    text-decoration: none;
	font-weight: 700;
	display:flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 20px;
}
.PROGRAM>div>div{
	text-align: left;
}
.PROGRAM img{
	width:165px;
}

.SERVICE{
	text-align: center;
}
.SERVICE>div{
	font-size: 21px;
    color: white;
    background: #252525;
    text-decoration: none;
	font-weight: 700;
	display:flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 20px;
}
.SERVICE>div>div{
	text-align: left;
}
.SERVICE img{
	width:165px;
}

.CASE{
	background-color: #b10514;
	padding:20px 20px; 
	display:flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
.CASE a{
	display:flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	/* margin: 0px 15px; */
	font-size: 21px;
	text-decoration: none;
	color:#fff;
	font-weight: 700;
}
.CASE img{
	width:30px;
	margin-left:3px;
}
/* .DPURCHASE{
	text-decoration: none;
	color:white;
	font-size:24px;
	font-weight: 700;
}
.DPURCHASE div{
	padding:15px;
	background-color: #2f82bf;
	text-align:center;
} */
.DPURCHASE{
	text-align: center;
}
.DPURCHASE>div{
	font-size: 21px;
    color: white;
    background: #2f82bf;
    text-decoration: none;
	font-weight: 700;
	display:flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 20px;
}
.DPURCHASE>div>div{
	text-align: left;
}
.DPURCHASE img{
	width:165px;
}

#CONTENT-A{
	clear:both;
	background-color:#d2d2d2;
	color: #12100b;
}

#CONTENT-A p{
	font-size:2em;
	line-height:1.7;
	padding:0 23px;
	padding-top:15px;
	padding-bottom:15px;
}
#CONTENT-A p:nth-child(1){
	padding-top:0px;
	padding-bottom:0px;
}


#CONTENT-A iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}

#CONTENT-B{
	clear:both;
	background-color:#d2d2d2;
	color: #12100b;
}

#CONTENT-B p{
	font-size:2em;
	line-height:1.7;
	padding:0 30px;
	padding-top:15px;
	padding-bottom:15px;
}

#CONTENT-B p:nth-child(1){
	padding-top:0px;
	padding-bottom:0px;
}

#CONTENT-B iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}

#CONTENT-C{
	clear:both;
	background-color:#d2d2d2;
	color: #12100b;
}

#CONTENT-C p{
	font-size:2em;
	line-height:1.7;
	padding:0 30px;
	padding-top:15px;
	padding-bottom:15px;
}

#CONTENT-C p:nth-child(1){
	padding-top:0px;
	padding-bottom:0px;
}

#CONTENT-C iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}


#CONTENT-D{
	clear:both;
	background-color:#d2d2d2;
	color: #12100b;
}

#CONTENT-D p{
	font-size:2em;
	line-height:1.7;
	padding:0 30px;
	padding-top:15px;
	padding-bottom:15px;
}

#CONTENT-D p:nth-child(1){
	padding-top:0px;
	padding-bottom:0px;
}

#CONTENT-D iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}




/* ===評論區=== */

.comment{
	margin: 0 auto;
}


/* ===影片&心得內容區=== */


#WRAPPER2 {
	width:90%;
	margin:0 auto;
}




/* ===頁碼區=== */

.dotPosition{
    display: flex !important;
	justify-content: space-around;
	align-items: center;
    padding-top: 1rem;
    margin: 0 2rem;
	li{
		flex:1;		
	}
	.slick-active span:hover{
		background-color: gray;
	}
}

.page{
	margin: 0 auto;
	width: 100%;
	background-color: #FFF;
}
.PAGE-A {
	display: flex;
	text-align: center;
	align-items: center;
}

.PAGE-A p{
	margin:0;
}

.PAGE-A p span{
	font-size: 2.2em;
	display:inline-block;
	line-height:1.1em;
	padding:0 0.3em;
	background-color:#e06400;
	text-align:center;
	color:#FFF;
	text-decoration:none;
	/* margin-right: 30px; */
	margin: 10px auto 15px auto;
}

.PAGE-B p{
	margin:0;
	text-align:center;
}
.PAGE-B p span{
	font-size: 2.2em;
	display:inline-block;
	line-height:1.1em;
	padding:0 0.3em;
	background-color:#cebb00;
	text-align:center;
	color:#FFF;
	text-decoration:none;
	/* margin-right: 30px; */
	margin: 10px auto 15px auto;
}

.PAGE-C p{
	margin:0;
	text-align:center;

}
.PAGE-C p span{
	font-size: 2.2em;
	display:inline-block;
	line-height:1.1em;
	padding:0 0.3em;
	background-color:#f42b00;
	text-align:center;
	color:#FFF;
	text-decoration:none;
	/* margin-right: 30px; */
	margin: 10px auto 15px auto;
}

.PAGE-D p{
	margin:0;
	text-align:center;

}
.PAGE-D p span{
	font-size: 2.2em;
	display:inline-block;
	line-height:1.1em;
	padding:0 0.3em;
	background-color:#323232;
	text-align:center;
	color:#FFF;
	text-decoration:none;
	/* margin-right: 30px; */
	margin: 10px auto 15px auto;
}
`