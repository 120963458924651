import styled from 'styled-components';

export const Wrapper = styled.div `

  &.child-comment {
    margin: 10px 0px;
    background: white;
    .comment {
      align-items: center;
    }
    .content {
      color: black;
    }
  }
  
  &.parent-comment {
    margin: 0;
    margin-top: 2px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(225,225,225,.4);
    .content {
      color: #525252;
    }
  }

  .comment {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    .content-wrapper {
      width: 100%;
      flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
    }
    .content {
      line-height: 24px;
      font-size: 14px;
      color: #525252;
    }
  }
  
  .avatar {
    width: 70px;
    height: 70px;
    border: 2px solid #ccc;
    margin-right: 5px;
    border-radius: 999rem;
    overflow: hidden;

    @media(max-width: 480px){
      width: 50px;
      height: 50px;
    }

    .avatar_photo{
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background-color: #fff;
      background-size: cover;
      background-position: center;
    }
  }
  
  .user-block {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: 20px;

    .messageName{
      font-size: 14px;
    }
    .messageTimes{
      font-size: 10px;
      text-align: right;
    }
  }

  .reply-btn {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: flex-end;

    button {
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      color: #fff;
      height: 35px;
      margin: 0 5px;
      width: 5rem;
      flex: unset;
      padding: 0 5px;
      background-color: #2f82bf;
      // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
      text-shadow: unset;
      border-radius: 999rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #ccc;
        background-color: #2e5577;
        box-shadow: unset;
      }
    }
  }
`
