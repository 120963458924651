import React, { Component } from 'react';

import { Wrapper } from './styled';

class ModalContent extends Component {
  render() {
    return (
      <Wrapper>
        <div className='form-control'>
          {this.props.msg}
        </div>
      </Wrapper>
    );
  }
}

export default ModalContent;
