import React, { Component } from "react";
import moment from "moment";

export default class RacketInfoAfterContractSign extends Component {


    convertShipmentStatusToText = (shipmentData) => {
        let shipmentStatus
        switch(shipmentData.shipment_status){
            case 4: 
                shipmentStatus = '換拍不通過退回原拍'
                break;
            case 3: 
                shipmentStatus = '已退貨'
                break;
            case 2: 
                shipmentData.racket_number !== '' 
                ? 
                    shipmentStatus = '退貨處理中'
                :
                    shipmentStatus = '未出貨取消專案'
                break;
            case 1: 
                shipmentStatus = '已出貨'
                break;
            default:
                shipmentStatus = '未出貨'
                break;
        }
        return <> 出貨狀態：{shipmentStatus} </>
    }

    render() {
        const { 
            orderContent, 
            rentReducerData,
            payment,
            shipment,
            contractStatus,
            getCancelProgramButton,
            getPaymentButton,
            lastPaymentDetail,
            lastShipmentDetail,
            shipmentLength,
            handleOnChange,
            belongUpdateSend,
            handleInformationHidden,
            LightBoxCustomerServiceOpen,
            racketBelong,
            changeCardType,
            handleChangePaymentMethod,
        } = this.props

        // 換拍付款是在 getPaymentButton 不是在 getReplaceRacketButton
        const getReplaceRacketButton = () => { 
            const replaceTimes = orderContent && orderContent.replace_times; //換拍次數
            const replacePaymentStatus = orderContent && orderContent.replace_payment_status; //換拍付費狀態 0無換拍/換拍付款完成 1換拍處理中
            const programDate = orderContent && orderContent.contract_test_time;
            let replaceStatus = 0; //0 using 1 processing 2 confirmed 3reject
            let shipmentStatus;

            if(replacePaymentStatus === 1)
            {
                const parentShipmentId = shipment && orderContent && orderContent.present_shipment_id.parent_id;
                const shipmentArr = shipment && shipment.filter(n => n.id === parentShipmentId);
                const replaceShipmentInfo = shipment && shipmentArr && shipmentArr[0];
                replaceStatus = shipment && orderContent && replaceShipmentInfo.replace_status; //0 using 1 processing 2 confirmed 3reject
                shipmentStatus = shipment && orderContent && replaceShipmentInfo.shipment_status; //0未出貨 1已出貨 2退貨處理中 3已退貨 4換拍不通過退回原拍   
            }
            else if(replacePaymentStatus === 0){
                shipmentStatus = shipment && orderContent && orderContent.present_shipment_id.shipment_status; //0未出貨 1已出貨 2退貨處理中 3已退貨 4換拍不通過退回原拍
            }
            
            if ( replaceTimes === 0 || replaceTimes === 1){
                //換拍次數小於或等於兩次
                if( replacePaymentStatus === 1 ) {     //換拍處理中
                    if( replaceStatus === 1 ){ // 審核中
                        return([
                            <div key={2} id="SendCancelReplaceButton" className="button" onClick={ ()=>{ handleInformationHidden('deleteReplace', 'toggle')} }>
                                取消換拍
                            </div>
                        ])
                    }
                    // else if ( replaceStatus === 2 ){ // 審核完畢
                    //     // getPaymentButton 負責渲染，這邊不用做事
                    // }
                }
                else if( new Date().valueOf() >= programDate && shipmentStatus === 1 ){
                    // console.log('##########  申請換拍  ##########');
                    return([
                        <div key={2} className="button" 
                            onClick={()=>{this.props.history.push('../replace_racket/' + (orderContent.id))}}>
                            申請換拍
                        </div>
                    ])
                }
            }
            //exception times and just show cancel if manager confirm
            else if( replacePaymentStatus === 1 && replaceStatus === 1 ){     //換拍處理中
                // console.log('##########  取消申請換拍  ##########');
                return([
                    <div key={2} id="SendCancelReplaceButton" className="button" onClick={ ()=>{ handleInformationHidden('deleteReplace', 'toggle')} }>
                        取消換拍
                    </div>
                ])
            }
        }

        var paymentDetail = payment && payment.filter(n => n.supplier_trade_amount !== '').map(data =>
            <div className="margin-top-20px" key={`paymentDetail` + data.id}>
                <li>
                    <ul 
                        style={{
                            backgroundColor: 'rgba(213,213,213,0.4)',
                            padding:'10px',
                            borderRadius:'5px',
                            border:'1px solid #CCC',
                        }}
                    >
                        <li className="color-LightBlack">
                            扣款時間：
                            {   
                                data.supplier_trade_time !== 0
                                && 
                                    data.supplier_trade_time
                            }
                        </li>
                        <li className="color-LightBlack">
                            扣款金額：
                            {data.supplier_trade_amount}
                        </li>
                        <li className="color-LightBlack">
                            {data.invoice_number ? `發票號碼：${data.invoice_number}(發票金額：${data.invoice_amount}` : null}
                        </li>
                        <li className="color-LightBlack">
                            付款狀態：
                            {   
                                data.supplier_trade_status === '1' 
                                ? 
                                    '付款完成'
                                :
                                data.supplier_trade_status === '0' 
                                ? 
                                    '付款失敗'
                                :
                                    '付款未完成'
                            }
                        </li>
                    </ul>
                </li>
            </div>
        );

        var changeRacketDetail = shipment && shipment.filter(n => n.racket_number !== '').map((data, index) =>
            <div className="margin-top-20px" key={`changeRacketDetail` + data.id}>
                <li>
                    <ul style={{backgroundColor: 'rgba(213,213,213,0.4)',padding:'10px',borderRadius:'5px',border:'1px solid #CCC',}}>
                        <li className="color-LightBlack">
                            {shipment.length - 1 === index ? '申請編號：' : '換拍編號：'}
                            {data.id}
                        </li>
                        <li className="color-LightBlack">
                            球拍編號：
                            {data.racket_number}
                        </li>
                        <li className="color-LightBlack">
                            申請時間：
                            {   
                                data.createdAt !== 0 && data.racket_number !== ''
                                && 
                                    moment(parseInt(data ? data.createdAt : '')).format('YYYY/MM/DD')
                            }
                        </li>
                        <li className="color-LightBlack">
                            {this.convertShipmentStatusToText(data)}
                        </li>
                    </ul>
                </li>
            </div>
        );

        var logisticsDetail = shipment && shipment.filter(n => n.shipment_number !== '').map(data => 
            <div className="margin-top-20px" key={`logisticsDetail` + data.id}>
                <li>
                    <ul style={{backgroundColor: 'rgba(213,213,213,0.4)',padding:'10px',borderRadius:'5px',border:'1px solid #CCC',}}>
                        <li className="color-LightBlack">
                            出貨日期：
                            {   
                                data.shipment_date !== 0
                                && 
                                    moment(parseInt(data ? data.shipment_date : '')).format('YYYY/MM/DD')
                            }
                        </li>
                        <li className="color-LightBlack">
                            出貨編號：
                            {data.shipment_number}
                        </li>
                        <li className="color-LightBlack">
                            球拍編號：
                            {data.racket_number}
                        </li>
                        <li className="color-LightBlack">
                            {this.convertShipmentStatusToText(data)}
                        </li>
                    </ul>
                </li>
            </div>
        );
        // changeCardType //信用卡換卡類型(0:無換卡, 1:換卡維持定期定額 2:換卡改為一次付清)
        var canUseNewCreditCardGoOrPayOff = orderContent && changeCardType ? changeCardType : 0;
        return (
            <div key={`main` + orderContent.id} >
                {/* #####   已付款   ##### */}
                {orderContent && (
                    <ul id="already_paid_content">
                        <li>
                            球拍編號：
                            {lastShipmentDetail.racket_number}
                        </li>
                        <li className="border-style margin-top-20px margin-bottom-20px">
                            <div
                                className="img-top"
                                style={{
                                    backgroundImage: `url(${orderContent.racket_id.picture})`
                                }}
                            ></div>
                            <div className="img-bottom">
                                {orderContent.racket_id.name}
                            </div>
                        </li>
                        <li className="color-LightBlack">
                            專案類別：
                            {orderContent.payment_type === "A" ? (
                                <span> 一次付清 </span>
                            ) : orderContent.payment_type === "Y" ? (
                                <span> 年繳付款 </span>
                            ) : (
                                orderContent.payment_type === "M" && (
                                    <span> 月繳付款 </span>
                                )
                            )}
                        </li>
                        <li className="color-LightBlack">
                            專案狀態：
                            {orderContent.contract_status === 0 ? (
                                <span> 未簽約 </span>
                            ) : orderContent.contract_status === 1 ? (
                                <span> 簽約中 </span>
                            ) : orderContent.contract_status === 2 ? (
                                <span> 已簽約 </span>
                            ) : (
                                orderContent.contract_status === 3 && (
                                    <span> 已解約 </span>
                                )
                            )}
                        </li>
                        <li className="color-LightBlack">
                            專案時間(起)：
                            {orderContent.contract_start_time !== 0 &&
                                moment(
                                    parseInt(
                                        rentReducerData
                                            ? orderContent.contract_start_time
                                            : ""
                                    )
                                ).format("YYYY/MM/DD")}
                        </li>
                        <li className="color-LightBlack">
                            專案時間(訖)：
                            {orderContent.contract_end_time !== 0 &&
                                moment(
                                    parseInt(
                                        rentReducerData
                                            ? orderContent.contract_end_time
                                            : ""
                                    )
                                ).format("YYYY/MM/DD")}
                        </li>
                        {/* payment_times */}
                        {orderContent.payment_type !== "A" &&
                            <li className="color-LightBlack">
                                剩餘期數：
                                {orderContent.contract_status === 2 &&
                                    orderContent && ( orderContent.payment_times - orderContent.contract_payment_times)
                                }
                                期
                            </li>
                        }
                        {orderContent.payment_type !== "A" &&
                            <li className="color-LightBlack">
                                剩餘金額：
                                <span style={{color: '#DF1C2C',}}>
                                    $
                                    {orderContent.contract_status === 2 &&
                                        orderContent && ( orderContent.total_amount - (orderContent.payment_amount * orderContent.contract_payment_times))
                                    }
                                    元整
                                </span>
                            </li>
                        }
                        {/*
                            <li className="color-LightBlack">
                                選配球拍：
                                {orderContent.racket_id.name}
                            </li>
                            <li className="color-LightBlack">
                                付款方式：
                                {payment[payment.length - 1].supplier_trade_payment}
                            </li>
                        */}
                        <li className="color-LightBlack">
                            贈品類別：
                            {orderContent.gift === 1 ? (
                                '【A】波力羽球線6組(羽拍穿線自理)、柄皮1條'
                            ) : orderContent.gift === 2 ? (
                                '【B】波力客製化穿線服務乙次(22±1磅數)、波力羽球線4組、柄皮1條'
                            ) : (
                                orderContent.gift === 3 && (
                                    '【B】波力客製化穿線服務乙次(進階24±1磅數)、波力羽球線4組、柄皮1條'
                                )
                            )}
                        </li>
                        {orderContent.contract_status === 4 ||
                        (
                            moment(
                                parseInt(orderContent.contract_end_time)
                            ).valueOf() !== 0 &&
                            moment(
                                parseInt(orderContent.contract_end_time)
                            ).valueOf() <= new Date().valueOf()
                        )
                        ? (
                            <li className="color-LightBlack racket_belong">
                                <p>球拍歸屬：</p>
                                <select
                                    value={racketBelong}
                                    onChange={e =>
                                        handleOnChange("racket_belong", e)
                                    }
                                >
                                    <option value="1">自行保留(預設值)</option>
                                    <option value="2">
                                        與波力聯名將蓮心球拍愛心捐贈偏鄉做公益
                                    </option>
                                </select>
                                <button onClick={belongUpdateSend}>
                                    確認
                                </button>
                            </li>
                        )
                        : (
                            ""
                        )}

                        <li className="button-group" style={{display: 'block', margin:'20px 0px 0px'}}>
                            {orderContent && getCancelProgramButton()}
                            {orderContent && getPaymentButton()}
                            {/* 已簽約的狀態 */}
                            {orderContent && contractStatus === 2 && getReplaceRacketButton()}
                            {/* 已得到授權可以更換信用卡 || 已得到授權可以一次付清餘款 */}
                            {canUseNewCreditCardGoOrPayOff === 1 &&
                                <div className="button button-blue" onClick={ ()=>{ handleChangePaymentMethod()} }>
                                    變更信用卡號
                                </div>
                            }
                            {canUseNewCreditCardGoOrPayOff === 2 &&
                                <div className="button button-blue" onClick={ ()=>{ handleChangePaymentMethod()} }>
                                    前往付清餘款
                                </div>
                            }
                        </li>

                        <hr className="margin-top-20px margin-bottom-20px" />
                        <li>
                            <div className="Sub-Text-Style">【 扣款資訊 】</div>
                        </li>
                        <li className="color-LightBlack">
                            扣款總金額：
                            {payment &&
                                payment
                                    .filter(
                                        data =>
                                            data.supplier_trade_status === "1"
                                    )
                                    .map(data => data.supplier_trade_amount)
                                    .reduce((x, y) => x + y, 0)}
                        </li>
                        <li className="color-LightBlack">
                            扣款時間：
                            {lastPaymentDetail &&
                                lastPaymentDetail.supplier_trade_time}
                        </li>
                        <li className="color-LightBlack">
                            扣款金額：
                            {lastPaymentDetail &&
                                lastPaymentDetail.supplier_trade_amount}
                        </li>
                        <li className="color-LightBlack">
                            {lastPaymentDetail.invoice_number && "發票號碼："}
                            {orderContent.contract_version <= "1"
                                ? lastPaymentDetail.invoice_number
                                : orderContent.replace_times < 1 &&
                                  orderContent.contract_version >= "2" &&
                                  lastPaymentDetail.invoice_number
                                ? lastPaymentDetail.invoice_number +
                                  " (發票金額：" +
                                  lastPaymentDetail.invoice_amount +
                                  ")"
                                : orderContent.replace_times >= 1 &&
                                  orderContent.contract_version >= "2" &&
                                  lastPaymentDetail.invoice_number
                                ? lastPaymentDetail.invoice_number
                                : !orderContent.contract_version &&
                                  lastPaymentDetail.invoice_number}
                        </li>
                        <li style={{ display: "flex" }}>
                            <div style={{ flex: "0 0 50%" }}></div>
                            <div
                                id="historyShipmentButton"
                                onClick={ ()=>{ handleInformationHidden('showhistoryShipment', 'toggle')} }
                                style={{
                                    flex: "0 0 50%",
                                    textAlign: "right",
                                    cursor: "pointer",
                                    color: "#3b84b7",
                                    lineHeight: "48px"
                                }}
                            >
                                歷史紀錄 ({paymentDetail.length})
                            </div>
                        </li>
                        <div
                            className="color-LightBlack close"
                            id="showhistoryShipment"
                        >
                            {paymentDetail}
                        </div>
                        <hr className="margin-top-20px margin-bottom-20px" />
                        <li>
                            <div className="Sub-Text-Style">【 球拍資訊 】</div>
                        </li>
                        <li className="color-LightBlack">
                            {changeRacketDetail.length <= 1 &&
                                shipmentLength <= 1
                                    ? "申請編號："
                                    : changeRacketDetail.length >= 1 &&
                                        shipmentLength > 1 &&
                                        "換拍編號："
                            }
                            {lastShipmentDetail.id}
                        </li>
                        <li className="color-LightBlack">
                            球拍編號：
                            {lastShipmentDetail.racket_number}
                        </li>
                        <li className="color-LightBlack">
                            換拍日期：
                            {(lastShipmentDetail &&
                                lastShipmentDetail.createdAt) !== 0 &&
                                lastShipmentDetail.racket_number !== "" &&
                                moment(
                                    parseInt(
                                        lastShipmentDetail
                                            ? lastShipmentDetail.createdAt
                                            : ""
                                    )
                                ).format("YYYY/MM/DD")}
                        </li>
                        <li className="color-LightBlack">
                            換拍狀態：
                            {lastShipmentDetail.replace_status === 0
                                ? lastShipmentDetail.racket_number !== "" && (
                                        <span>使用中</span>
                                    )
                                : lastShipmentDetail.replace_status === 1 && (
                                        <span>換拍中</span>
                                    )}
                        </li>
                        <li className="color-LightBlack">
                            {this.convertShipmentStatusToText(lastShipmentDetail)}
                        </li>
                        <li style={{ display: "flex" }}>
                            <div style={{ flex: "0 0 50%" }}>
                                <div
                                    className={
                                        orderContent.replace_times >= 2 &&
                                        orderContent.replace_payment_status ===
                                            0
                                            ? "button-blue-oval"
                                            : "none"
                                    }
                                    style={{
                                        textAlign: "center",
                                        float: "left"
                                    }}
                                    onClick={LightBoxCustomerServiceOpen}
                                >
                                    聯絡客服
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div style={{ flex: "0 0 50%" }}>
                                <div
                                    id="historyChangeRacketButton"
                                    onClick={ ()=>{ handleInformationHidden('showhistoryChangeRacket', 'toggle')} }
                                    style={{
                                        textAlign: "right",
                                        cursor: "pointer",
                                        color: "#3b84b7",
                                        lineHeight: "48px"
                                    }}
                                >
                                    歷史紀錄 ({changeRacketDetail.length})
                                </div>
                            </div>
                        </li>
                        <div
                            className="color-LightBlack close"
                            id="showhistoryChangeRacket"
                        >
                            {changeRacketDetail}
                        </div>
                        <hr className="margin-top-20px margin-bottom-20px" />
                        <li>
                            <div className="Sub-Text-Style">【 物流資訊 】</div>
                        </li>
                        <li className="color-LightBlack">
                            出貨編號：
                            {lastShipmentDetail.shipment_number}
                        </li>
                        <li className="color-LightBlack">
                            出貨日期：
                            {lastShipmentDetail &&
                                lastShipmentDetail.shipment_date !== 0 &&
                                lastShipmentDetail.racket_number !== "" &&
                                moment(
                                    parseInt(
                                        lastShipmentDetail
                                            ? lastShipmentDetail.shipment_date
                                            : ""
                                    )
                                ).format("YYYY/MM/DD")}
                        </li>
                        <li className="color-LightBlack">
                            {this.convertShipmentStatusToText(lastShipmentDetail)}
                        </li>
                        <li
                            id="historyLogisticsButton"
                            style={{ display: "flex" }}
                        >
                            <div style={{ flex: "0 0 50%" }}></div>
                            <div
                                onClick={()=>{ handleInformationHidden('showhistoryLogistics', 'toggle') }}
                                style={{
                                    flex: "0 0 50%",
                                    textAlign: "right",
                                    cursor: "pointer",
                                    color: "#3b84b7",
                                    lineHeight: "48px"
                                }}
                            >
                                歷史紀錄 ({logisticsDetail.length})
                            </div>
                        </li>
                        <div
                            className="color-LightBlack close"
                            id="showhistoryLogistics"
                        >
                            {logisticsDetail}
                        </div>
                        <hr className="margin-top-20px margin-bottom-20px" />
                        <li className="margin-left-20px color-LightBlack">
                            備註：
                            {orderContent.note}
                        </li>
                        
                        <li className="button-group">
                            <div
                                className="button"
                                onClick={() => {
                                    this.props.history.push("/my_info/rent_contract_list");
                                }}
                            >
                                回上頁
                            </div>

                        </li>
                    </ul>
                )}
            </div>
        );
    }
}
