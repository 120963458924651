import React, { Component } from "react";
import moment from "moment";
// import "react-datepicker/dist/react-datepicker.css";
import { Wrapper } from "./styled";
import DatePicker from "react-datepicker";

export default class DateInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            [props.name]: props.value || moment()
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ [this.props.name]: nextProps.value });
        }
    }

    handleChange = date => {
        console.log(this.props)
        this.setState(
            {
                [this.props.name]: date
            },
            () => {
                this.props.onChange({
                    [this.props.name]: date
                });
            }
        );
    };

    render() {
        return (
            <Wrapper>
            <DatePicker 
                popperClassName={this.props.className}
                dateFormat="YYYY年MM月DD日"
                selected={this.state[this.props.name]}
                onChange={this.handleChange}
                readOnly={true}
                withPortal
                //onClickOutside={this.handleClickOutSide}
                children={
                    <div className="dateSelect"
                        type=""w
                        onClick= {
                            function onclick(e) {
                                if(document.getElementsByClassName("react-datepicker__day--selected")[0])
                                {
                                    document.getElementsByClassName("react-datepicker__day--selected")[0].click();
                                }
                            }
                        }
                        style={{
                            position:`absolute`,
                            right:0 + `px`,
                            top: -30 + `px`,
                            width: 5 + `rem`,
                            height: 35 +`px`,
                            backgroundColor: `#f0f0f0`,
                            textAlign: `center`,
                            lineHeight: 30 + `px`,
                            borderTopRightRadius: 5 + `px`,
                            borderTopLeftRadius: 5 + `px`,
                            cursor: `pointer`,
                            boxShadow: `0 0 20px 5px rgba(255,255,255,.12)`
                        }} >Ｘ 關閉</div>}
            /> 
            </Wrapper>
                
                
        );
    }
}