/* @import url("reset.css"); */

import styled from 'styled-components';
export const Wrapper = styled.div `

body{
	background-color:#999;
	font-family:"微軟正黑體", Arial, "sans-serif";
}

#WRAPPER{
	width:100%;
	max-width: 440px;/* 限制最大寬不超過440px */
	margin:0 auto;
	font-size: 8px; /* 指使文字em的標準 */
}

/* slider  */
.sliderA, .sliderB, .sliderC{
	position: relative;
	width: 100%;
}
.sliderA img{
	position: absolute;
	opacity: 0;
}
.sliderB img{
	position: absolute;
	opacity: 0;
}
.sliderC img{
	position: absolute;
	opacity: 0;
}
.sliderA .sliderA__img3{
	position: relative;
}
.sliderB .sliderB__img3{
	position: relative;
}
.sliderC .sliderC__img3{
	position: relative;
}
.sliderA .show{
	opacity: 1;
}
.sliderB .show{
	opacity: 1;
}
.sliderC .show{
	opacity: 1;
}


/* ===影片區=== */

.iframe-16x9 {
    position: relative;
    padding-bottom: 50.25%;
    padding-top: 35px;
	
    height: 0;
    overflow: hidden;
}
.iframe-4x3 {
    position: relative;
    padding-bottom: 75%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}
.iframe-16x9 iframe, .iframe-4x3 iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}



/* ===頁首區=== */

#HEADER{
	width: 100%;
	height: auto;
}


/* ===主選單區=== */

#NAV{
	background-color:#F90;
	overflow:hidden;
}

.nav_A{
	position: absolute;
	opacity: 0;
}
.nav_A.show{
	opacity: 1;
}
.nav_B{
	position: absolute;
	opacity: 0;
}
.nav_B.show{
	opacity: 1;
}
.nav_C{
	position: absolute;
	opacity: 0;
}
.nav_C.show{
	opacity: 1;
}

#NAV li{
	float:left;
	display:block;
}



/* ===內容區=== */

#CONTENT-A, #CONTENT-B, #CONTENT-C{
	display: none;
}

#CONTENT-A{
	clear:both;
	background-color:#2e2e2e;
	color: #FFF;
}

#CONTENT-A p{
	font-size:2em;
	line-height:1.7;
	padding:0 30px;
	padding-top:15px;
	padding-bottom:15px;
}

#CONTENT-A iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}

#CONTENT-B{
	clear:both;
	background-color:#f40a1c;
	color: #FFF;
}

#CONTENT-B p{
	font-size:2em;
	line-height:1.7;
	padding:0 30px;
	padding-top:15px;
	padding-bottom:15px;
}

#CONTENT-B iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}

#CONTENT-C{
	clear:both;
	background-color:#ffad20;
	color: #000000;
}

#CONTENT-C p{
	font-size:2em;
	line-height:1.7;
	padding:0 30px;
	padding-top:15px;
	padding-bottom:15px;
}

#CONTENT-C iframe{
/*	padding:30px 30px;讓影片不要被往右推*/
}




/* ===評論區=== */

.comment{
	margin: 0 auto;
	display: none;
}
.show{
	display: block;
}


/* ===影片&心得內容區=== */


#WRAPPER2 {
	width:90%;
	margin:0 auto;
}




/* ===頁碼區=== */

.page{
	margin: 0 auto;
	width: 100%;
	background-color: #FFF;
}

.PAGE-A {
	padding:10px;
}

.PAGE-A p{
	margin:0;
	text-align:center;

}
.PAGE-A p span{
	font-size: 2.2em;
	display:inline-block;
	line-height:1.1em;
	padding:0 0.3em;
	background-color:#365899;
	text-align:center;
	color:#FFF;
	text-decoration:none;
	margin-right: 30px;
	margin-top: 20px;
}

.PAGE-A p span.show{
	background-color:#777;
	color:#FFF;
}

.PAGE-A span:hover{
	background-color:#777;
	color:#FFF;
}


.PAGE-B {
	padding:10px;
}

.PAGE-B p{
	margin:0;
	text-align:center;
}
.PAGE-B p span{
	font-size: 2.2em;
	display:inline-block;
	line-height:1.1em;
	padding:0 0.3em;
	background-color:#FFF;
	text-align:center;
	color:#F40a1c;
	text-decoration:none;
	margin-right: 30px;
	margin-top: 20px;
}
.PAGE-B p span.show{
	background-color:#777;
	color:#FFF;
}

.PAGE-B span:hover{
	background-color:#777;
	color:#FFF;
}


/* ===購物車=== */

.shoppingCart{
    cursor: pointer;
}
`

export const GoToRent = styled.div `
	display: none;
	opacity: 0;
	width: 100%;
	padding: 15px 0;
	align-items: center; 
	justify-content: center;
	cursor: pointer;	

	h5 {
		flex: 0 0 33.333333%;
		border-radius: 999rem;
		background-color: #da263c;
		color: #FFF;
		font-size: 1.2rem;
		padding: 5px 0;
		text-align: center;
	}
`

