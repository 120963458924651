import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from './styled';
// component below
import { FrequencySelect, SkillLevelSelect, CourtSizeSelect, CourtName, AgeSelect, GroupPeopleNumberSeelct, ImageUploader, SubmitButton, TimeForPlay, FormControl, Select } from "../Common";
import Banner from './Banner';
import GroupNameInput from './GroupNameInput';
import CourtPrice from './CourtPrice';
import CourtFeatures from "./CourtFeatures";
import CourtAddress from "./CourtAddress";
import GroupPrice from "./GroupPrice";
// component above
import { updateGroup, getLevels, getPreferences, createFeature, uploadImg, setImgUrl, getMapApiData } from "../../actions";
import moment from 'moment'
import Config from '../config';
import { localStorageUtility } from '../../utilities';

class EditGroup extends Component {
    constructor(props) {
        super(props);

        if (!props.city) {
            this.props.history.replace('/login');
            return
        }

        // 把 activityDetail 傳到 redux 的資料由 props 存到 state 裡
        const { url, ...state } = props;
        // this.state = state;


        this.state = {
            ...state,
            // place_name:'',
            suggestionLocate: {
                city: '',
                area: '',
                address: '',
            },
            submitIsDisabled: false,

            // plan: {
            //     Cash: 0,
            //     CreditEcpay: 0
            // },

            authority: 1,
            offline: false,
            online: false,

            // someone join the group, set disabled change edit group
            isDisabled: false,
            isFindAddress: false,
        }

    }

    componentDidMount() {
        const data = localStorageUtility('get', 'myData');
        if (data == null) {
            alert('請先登入')
            this.props.history.replace('/login');
        }
        if (this.props.group_name === '')
            this.props.history.replace("/my_info/host_activities");
        this.props.setImgUrl(this.state.images.url[0] || '');
        this.props.getLevels()
        this.props.getPreferences()

        if (this.state.people - this.state.remain > 0) this.setState({ isDisabled: true })
        if (this.state.plan && this.state.plan.Cash !== undefined && this.state.plan.Cash >= 0) this.setState({ offline: true })
        if (this.state.plan && this.state.plan.CreditEcpay !== undefined && this.state.plan.CreditEcpay >= 0) this.setState({ online: true })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.setState({ images: { url: [nextProps.url] } });
        }
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props.user);
        if (
            this.props.user &&
            this.props !== prevProps
        ) {
            // console.log("will setstat of authority yyyyyy");
            this.setState({
                authority: this.props.type
            }) 
        }
    }


    handleGetMapAddress = () => {

        this.props.getMapApiData({address: this.state.place_name})
        this.setState({ isFindAddress: true })

    }

    setIsFindAddressFalse = () => {
        console.log("in find address");
        this.setState({ isFindAddress: false })
    }


    handleCourtNameSelected = ({ suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        this.setState({
            suggestionLocate: {
                city: suggestion.city,
                area: suggestion.area,
                address: suggestion.address,
            },
            defaultArea: suggestion.area,
            defaultCity: suggestion.city,
            defaultAddress: suggestion.address,
            place_name: suggestion.place_name,

            // 
            isAutoSuggested: true,
        })
    }

    //   handleCourtNameSelected = ({ suggestion, suggestionValue,suggestionIndex, sectionIndex, method }) => {
    //       this.setState({
    //         area: suggestion.area,
    //         city: suggestion.city,
    //         address: suggestion.address,
    //         defaultArea: suggestion.area,
    //         defaultCity: suggestion.city,
    //         defaultAddress: suggestion.address,
    //         place_name: suggestion.place_name
    //       })
    //     }

    handleOnChange = (element, e) => {
        let { startDate, startTime, endDate, endTime } = this.state;
        if (element === "frequency") {
            if (e.target.value !== "") {
                var frequency = { type: e.target.value, value: 1 }
                this.setState({ frequency: frequency })
            } else {
                this.setState({ frequency: {} })
            }
        } else if (element === "address") {
            this.setState({
                address: e.address,
                city: e.city,
                area: e.area
            }, () => console.log(this.state))
        } else if (-1 !== ["startDate", "startTime", "endDate", "endTime"].indexOf(element)) {
            const state = {};

            if (element === "startDate") {
                startDate = state[element] = e.d;
            } else if (element === "startTime") {
                startTime = state[element] = e;
            } else if (element === "endDate") {
                endDate = state[element] = e.d;
            } else {
                endTime = state[element] = e;
            }

            let start = this.toDateTime(startDate, startTime);
            let end = this.toDateTime(endDate, endTime);

            if (start.isAfter(end)) {
                if (element === "startDate" || element === "startTime") {
                    state['endDate'] = moment(startDate);
                    state['endTime'] = startTime;
                } else {
                    state['startDate'] = moment(endDate);
                    state['startTime'] = endTime;
                }
            }

            this.setState(state);
        } else {
            if (element === 'age_min') {
                if (Number.parseInt(e.target.value, 10) > Number.parseInt(this.state['age_max'], 10)) {
                    this.setState({ age_max: e.target.value });
                }
            } else if (element === 'age_max') {
                if (Number.parseInt(e.target.value, 10) < Number.parseInt(this.state['age_min'], 10)) {
                    this.setState({ age_min: e.target.value });
                }
            }
            this.setState({ [element]: e.target.value })
        }
    }

    toDateTime = (date, time) => moment(date.format("YYYY/MM/DD") + " " + time)

    handleSubmit = (e) => {
        if (this.props.uploadStatus) { alert('圖片上傳中'); e.preventDefault(); return }
        if (!this.state.group_name) { alert('團名為必填欄位'); e.preventDefault(); return }
        if (!this.state.place_name) { alert('場地名稱為必填欄位'); e.preventDefault(); return }
        if (!this.state.address) { alert('球場地址為必填欄位'); e.preventDefault(); return }
        var unixTime = this.toDateTime(this.state.startDate, this.state.startTime).unix();
        var unixTime2 = this.toDateTime(this.state.endDate, this.state.endTime).unix();
        let originalTime = this.toDateTime(this.props.startDate, this.props.startTime).unix();
        console.log(this.props.startDate, this.props.startTime, originalTime, this.state.startDate, this.state.startTime, unixTime);

        // 如果編輯後的時間和原開團時間一樣就可以成功 submit
        if (originalTime === unixTime) {
        } else if (moment.unix(unixTime).isBefore(moment())) {
            alert('開團時間已過');
            e.preventDefault();
            return
        }
        var tags = this.props.features
        var oldtag = this.props.oldtag
        var tagsId = []
        var oldtagId = []

        // '' 就先篩掉
        if (this.state.online === true && (this.state.plan.CreditEcpay === '' || this.state.plan.CreditEcpay === undefined)) {
            e.preventDefault();
            return alert('線上付款不可設為 0元')
        }
        if (this.state.offline === true && (this.state.plan.Cash === '' || this.state.plan.Cash === undefined)) {
            e.preventDefault();
            return alert('請輸入現場付費金額 或是關閉現場付款方式')
        }

        if (this.state.online === true && this.state.offline === true) {
            this.setState({
                plan: {
                    Cash: this.state.plan.Cash,
                    CreditEcpay: this.state.plan.CreditEcpay
                }
            })
        } else if (this.state.online === true) {
            this.setState({
                plan: {
                    CreditEcpay: this.state.plan.CreditEcpay
                }
            })
        } else if (this.state.offline === true) {
            this.setState({
                plan: {
                    Cash: this.state.plan.Cash
                }
            })
        }
        // 關掉線上和現場付費，設 Cash 0 元
        if (this.state.online === false && this.state.offline === false) {

            if (this.state.authority === 1) {
                if (window.confirm('確定要開免費團嗎')) {
                    // 確定 就讓他開團
                    this.setState({
                        plan: {
                            Cash: 0
                        }
                    })
                } else {
                    e.preventDefault();
                    return
                }
            } else if (this.state.authority === 0 && this.state.plan.Cash === '') {
                if (window.confirm('確定要開免費團嗎')) {
                    // 確定 就讓他開團
                    this.setState({
                        plan: {
                            Cash: 0
                        }
                    })
                } else {
                    e.preventDefault();
                    return
                }
            }

        }

        // 線上付費不設 0
        if (this.state.plan.CreditEcpay <= 0) {
            alert('線上付款不可設為 0元')
            e.preventDefault();
            return
        }

        for (var i = 0; i < this.state.tagNames.length; i++) {
            for (var j = 0; j < tags.length; j++) {
                // console.log(this.state.tagNames[i])   //頁面上的Tag
                // debugger
                // console.log(tags[j].name)              //新增Tag
                //debugger



                if (this.state.tagNames[i] === tags[j].name) {
                    // console.log('add', tags[j].id)
                    tagsId.push(tags[j].id)
                }

            }
            if (oldtag !== []) {
                for (var k = 0; k < oldtag.length; k++) {

                    // console.log(oldtag[k].name)
                    //debugger           //舊有Tag
                    if (this.state.tagNames[i] === oldtag[k].name) {
                        // console.log('add', oldtag[k].id)
                        oldtagId.push(oldtag[k].id)
                    }


                }
            }

            // if(oldtagId != null){
            //     alert();
            tagsId.push.apply(tagsId, oldtagId)
            // }

        }

        // this.setState({ start: unixTime + '000', end: unixTime2 + '000', tag: tagsId }, () => {
        //     console.log(this.state);
        //     this.props.onSubmit(this.state);
        // })

        this.setState({ refundPeriod: parseInt(this.state.refundPeriod, 10) })
        this.setState({ start: unixTime + '000', end: unixTime2 + '000', tag: tagsId, submitIsDisabled: true }, () => {
            var address = this.state.city + this.state.area + this.state.address
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${Config.googleMapKey}`, { method: 'get' })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    this.setState({
                        latitude: response.results[0].geometry.location.lat,
                        longitude: response.results[0].geometry.location.lng
                    }, () => {
                        // console.log(this.state.latitude, this.state.longitude , this.state.geo);
                        this.props.onSubmit(this.state);
                    });
                }.bind(this));
        })

        e.preventDefault();
    }

    handleAddFeature = (text) => {
        this.props.createFeature({ name: text });
        this.setState({ tagNames: [...this.state.tagNames, text] })
        //this.state.tagNames.add('');
        // console.log(this.state.tagNames);
        // localStorage.setItem('tagNames', this.state.tagNames);

    }

    handleRemoveFeature = (text) => {

        this.setState({ tagNames: this.state.tagNames.filter(x => x !== text) })
    }

    onFileChange = file => {
        if (file) {
            this.props.uploadImg(file);
        } else {
            this.props.setImgUrl('');
        }
    }

    handlePaymentMethodOnChange = (element, e) => {
        if (element === 'Cash') {
            this.setState({
                plan: {
                    Cash: parseInt(e.target.value) || 0
                }
            })
        }

        if (element === 'CreditEcpay') {
            this.setState({
                plan: {
                    CreditEcpay: parseInt(e.target.value) || 0
                }
            })
        }

        if (this.state.online === true && this.state.offline === true) {
            if (element === 'CreditEcpay') {
                this.setState({
                    plan: {
                        ...this.state.plan,
                        CreditEcpay: parseInt(e.target.value) || 0
                    }
                })
            }
            if (element === 'Cash') {
                this.setState({
                    plan: {
                        ...this.state.plan,
                        Cash: parseInt(e.target.value) || 0
                    }
                })
            }
        }
    }

    setOnline = () => {
        if (this.state.offline === true) {
            this.state.online ? this.setState({ online: false }) : this.setState({ online: true })
        }
    }
    setOffline = () => {
        if (this.state.online === true) {
            this.state.offline ? this.setState({ offline: false }) : this.setState({ offline: true })
        }
    }

    printState = () => {
        // '' 就先篩掉
        if (this.state.online === true && (this.state.plan.CreditEcpay === '' || this.state.plan.CreditEcpay === undefined)) {
            // e.preventDefault();
            return alert('請輸入線上付費金額 或是關閉線上付款方式')
        }
        if (this.state.offline === true && (this.state.plan.Cash === '' || this.state.plan.Cash === undefined)) {
            // e.preventDefault();
            return alert('請輸入現場付費金額 或是關閉現場付款方式')
        }

        if (this.state.online === true && this.state.offline === true) {
            this.setState({
                plan: {
                    Cash: this.state.plan.Cash,
                    CreditEcpay: this.state.plan.CreditEcpay
                }
            })
        } else if (this.state.online === true) {
            this.setState({
                plan: {
                    CreditEcpay: this.state.plan.CreditEcpay
                }
            })
        } else if (this.state.offline === true) {
            this.setState({
                plan: {
                    Cash: this.state.plan.Cash
                }
            })
        }
        // 關掉線上和現場付費，設 Cash 0 元
        if (this.state.online === false && this.state.offline === false) {
            if (this.state.authority === 1) {
                if (window.confirm('確定要開免費團嗎')) {
                    // 確定 就讓他開團
                    this.setState({
                        plan: {
                            Cash: 0
                        }
                    })
                } else {
                    // e.preventDefault();
                    return
                }
            } else if (this.state.authority === 0) {
                if (this.state.plan.Cash === '') {
                    this.setState({ plan: { Cash: 0 } })
                }
            }

        }

        if (this.state.plan.CreditEcpay === 0) {
            this.setState({
                plan: {
                    Cash: this.state.plan.Cash || 0
                }
            })
        }


        setTimeout(() => { console.log(this.state.plan) }, 1000)
    }


    render() {
        const { preferences } = this.props

        return (
            <Wrapper>
                <Banner />
                <form onSubmit={this.handleSubmit} className="main-form">

                    <GroupNameInput value={this.state.group_name} onChange={(e) => this.handleOnChange("group_name", e)} />

                    {this.state.authority === 1 ?
                        <Select label={"請假截止日(開團前幾天)："} options={[{ value: 1 }, { value: 2 }, { value: 3 }]}
                            onChange={e => { this.handleOnChange('refundPeriod', e) }} value={this.state.refundPeriod} />
                        : ''
                    }

                    <GroupPeopleNumberSeelct value={this.state.people} onChange={(e) => this.handleOnChange("people", e)} />
                    <TimeForPlay
                        label='開始時間：'
                        date={this.state.startDate}
                        time={this.state.startTime}
                        name={'d'}
                        onDateChange={(e) => this.handleOnChange("startDate", e)}
                        onTimeChange={(e) => this.handleOnChange("startTime", e)} />
                    <TimeForPlay
                        label='結束時間：'
                        date={this.state.endDate}
                        time={this.state.endTime}
                        name={'d'}
                        onDateChange={(e) => this.handleOnChange("endDate", e)}
                        onTimeChange={(e) => this.handleOnChange("endTime", e)} />
                    <FrequencySelect value={this.state.frequency} onChange={(e) => this.handleOnChange("frequency", e)} />

                    {
                        this.state.isDisabled ?
                            <div>
                                <span>場地名稱：</span>
                                {this.state.place_name}
                                <br></br>
                                <span>場地地址：</span>
                                {this.state.city}
                                {this.state.area}
                                {this.state.address}
                                <br></br>
                            </div>
                            :
                            <div>
                                <div className="place_name">
                                    <label>場地名稱：</label>
                                    <div className="place_name__box">
                                        <CourtName
                                            inputProps={{
                                                onChange: (e) => this.handleOnChange("place_name", e),
                                                placeholder: "請輸入場地名稱",
                                                // onBlur: this.handleGetMapAddress,
                                            }}
                                            value={this.state.place_name}
                                            suggestions={preferences.filter((preference) => preference.place_name.indexOf(this.state.place_name) !== -1)}
                                            onSuggestionSelected={this.handleCourtNameSelected}
                                            getSuggestionValue={(suggestion) => `${suggestion.place_name}`}
                                            renderSuggestion={(suggestion) => `${suggestion.place_name}`}
                                        />

                                        <span className="place_name_findAddress" onClick={() => { this.handleGetMapAddress('click') }}>
                                            <i className="material-icons">search</i>
                                            <span className='place_name_findAddress__span'>找地址</span>
                                        </span>
                                    </div>
                                </div>

                                <CourtAddress
                                    setIsFindAddressFalse={this.setIsFindAddressFalse}
                                    place_name={this.state.place_name}
                                    isFindAddress={this.state.isFindAddress}

                                    isAutoSuggested={this.state.isAutoSuggested}
                                    suggestionLocate={this.state.suggestionLocate}
                                    mapAddress={this.props.mapAddress}
                                    mapAddressFormat={this.props.mapAddressFormat}

                                    address={this.state.address} area={this.state.area} city={this.state.city}
                                    onChange={(e) => this.handleOnChange("address", e)} defaultCity={this.state.defaultCity} defaultArea={this.state.defaultArea} defaultAddress={this.state.defaultAddress}

                                />
                            </div>
                    }
                    <SkillLevelSelect levels={this.props.levels} value={this.state.badminton_level} onChange={(e) => this.handleOnChange("badminton_level", e)} />
                    {/* <span onClick={this.printState}> print  </span> */}

                    {
                        this.state.isDisabled ?
                        <div>
                            {this.state.plan.CreditEcpay !== undefined ?
                                <span>線上付款金額：
                                {this.state.plan.CreditEcpay}
                                    <br></br>
                                </span>
                                : ""
                            }

                            {
                                this.state.plan && this.state.plan.Cash !== undefined ?
                                <span>
                                    <span>現場繳費金額：</span>
                                    {this.state.plan && this.state.plan.Cash}
                                </span>
                                : ""
                            }
                        </div>
                        :
                        <div>

                            {/* === 1 表示有線上金流權限 */}
                            {
                                this.state.authority === 1 ?
                                <div>
                                    <div className="paymentType">
                                        <span>
                                            <label>請選擇付款方式(可複選)：</label>
                                            <span className={["paymentType__label", this.state.offline ? "active" : ""].join(' ')} onClick={this.setOffline}> 現場付費 </span>
                                            <span className={["paymentType__label", this.state.online ? "active" : ""].join(' ')} onClick={this.setOnline}> 線上付費 </span>
                                        </span>
                                    </div>

                                    {
                                        this.state.offline ?
                                            <GroupPrice value={this.state.plan && this.state.plan.Cash ? this.state.plan.Cash : 0}
                                                onChange={(e) => this.handlePaymentMethodOnChange("Cash", e)}
                                                paymentTypeText="現場付費金額" />
                                            : ""
                                    }
                                    {
                                        this.state.online ?
                                            // this.state.plan && this.state.plan.CreditEcpay !== undefined ? 
                                            <GroupPrice value={this.state.plan && this.state.plan.CreditEcpay ? this.state.plan.CreditEcpay : 0}
                                                onChange={(e) => this.handlePaymentMethodOnChange("CreditEcpay", e)}
                                                paymentTypeText="線上付費金額"
                                            />
                                            // : <GroupPrice value={this.state.plan.CreditEcpay} 
                                            //     onChange={(e) => this.handlePaymentMethodOnChange("CreditEcpay", e)}
                                            //     paymentTypeText="線上付費金額"
                                            //         /> 
                                            : ""
                                        // isDisabled= {true}
                                    }
                                </div>
                                :
                                <CourtPrice
                                    value={this.state.plan && this.state.plan.Cash}
                                    onChange={(e) => this.handlePaymentMethodOnChange("Cash", e)} 
                                />
                            }
                        </div>
                    }


                    <CourtSizeSelect value={this.state.place_count} onChange={(e) => this.handleOnChange("place_count", e)} />
                    <AgeSelect t={"(起)"} isStart label={"球友年齡"} value={this.state.age_min} onChange={(e) => this.handleOnChange("age_min", e)} />
                    <AgeSelect t={"(迄)"} isEnd value={this.state.age_max} onChange={(e) => this.handleOnChange("age_max", e)} />
                    <FormControl>
                        <ImageUploader
                            showThumbnail={true}
                            useSizeLimit
                            url={this.state.images.url[0]}
                            onChange={file => this.onFileChange(file)}
                            onCancel={() => this.onFileChange()}
                        />
                    </FormControl>
                    <FormControl>
                        <CourtFeatures features={this.state.tagNames} addFeature={this.handleAddFeature} removeFeature={this.handleRemoveFeature} />
                    </FormControl>
                    <br />
                    <SubmitButton text="確認修改開團資訊" onClick={this.handleSubmit} disable={this.state.submitIsDisabled} />
                    <div id="spaceForMobile"></div>
                </form>
            </Wrapper>
        );
    }
}

const now = moment();

EditGroup.defaultProps = {
    group_name: '',
    people: 1,
    startDate: moment(now),
    startTime: '0:00',
    endDate: moment(now),
    endTime: '0:00',
    frequency: {
        type: '',
        value: ''
    },
    place_name: '',
    geo: {
        latitude: '',
        longitude: ''
    },
    address: '',
    badminton_level: 1,
    //   plan.CreditEcpay: 0,
    place_count: 1,
    age_min: '0',
    age_max: '100',
    tagNames: [],
    tag: [],
    city: '',
    area: '',
    start: '',
    end: '',
    images: { url: [] },
    ball_brand: ' ',
    preferences: [],
    defaultAddress: '',
    defaultCity: '',
    defaultArea: ''
}

const mapStateToProps = state => {
    let { url, uploadStatus } = state.imageUploadReducer;
    let { activityInformation, levels, preferences, features } = state.newGroupReducer;

    let start_date = moment(),
        start_time = '0:00',
        end_date = moment(),
        end_time = '0:00',
        tagNames = [],
        oldtag = [];

    if (activityInformation && activityInformation.start) {
        start_date = moment.unix(activityInformation.start / 1000)
        start_time = moment.unix(activityInformation.start / 1000).format("HH:mm")
    }

    if (activityInformation && activityInformation.end) {
        end_date = moment.unix(activityInformation.end / 1000)
        end_time = moment.unix(activityInformation.end / 1000).format("HH:mm")
    }

    if (activityInformation && activityInformation.tag) {
        tagNames = activityInformation.tag.map((tag) => tag.name)
        oldtag = activityInformation.tag
    }


    return {
        mapAddress: state.googleMapReducer.address.addressArray,
        mapAddressFormat: state.googleMapReducer.address.formatted_address,

        ...activityInformation, levels, preferences, features, tagNames, oldtag, url, uploadStatus,
        startDate: start_date, startTime: start_time,
        endDate: end_date, endTime: end_time,
    }
};

const mapDispatchToProps = ({
    onSubmit: updateGroup,
    getLevels: getLevels,
    getPreferences: getPreferences,
    createFeature: createFeature,
    uploadImg: uploadImg,
    setImgUrl: setImgUrl,

    getMapApiData: getMapApiData,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
