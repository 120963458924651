import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin-bottom: 100px;
    select{
        width:100%
    };
    a {
        text-decoration:none;
    }
    hr {
        border: none;
        border-bottom-width: 1px; 
        border-bottom-color: #ccc;
        border-bottom-style: solid;
    }
    button > a{
        color:#fff;
    }
    .features{
        border: 1px solid
        padding: 8px 10px 8px 10px
        border-color: #D8D8D8
        border-radius: 20px
        text-align: center
        margin: 7px
        width: 80px
        display:inline-block;
        vertical-align: bottom;
    }
    .hide{
        display:none !important;
    }
    .title{
        width:100%;
        margin: 10px 0px;
        h2.color-red{
            font-size:2em;
            font-weight:bold;
            color:#ce243b;
        }
    }
    .margin-all-20px{
        margin: 20px;
    }
    .cursor-pointer{
        cursor:pointer;
    }
    #equipment{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        display:block;
    }
        .item{
            float:left
            margin: 5px;
            width: calc(50% - 14px);
            border: 2px solid #fdfdfd;
            height: 235px;
            border-radius:5px;
            overflow:hidden;
        }
            .item > input[type=radio]{
                position: absolute;
                z-index: -999;
            }
            .item > label{
                display:inline;
                cursor:pointer;
            }
            .item > input[type=radio]:checked + label > .pic .checked_img{
                display:block;
            }
            .pic{
                width:calc( 100% - 4px);
                height:calc( 100% - 54px );
                border: 2px solid #da263c;
                display:block;
                background-size: cover;
                background-color:#FFF;
                background-position: center center;
                background-repeat: no-repeat;
                position:relative;

                img{
                    width:100%
                    height:auto;
                    position:absolute;
                    top:0;
                    bottom:0;
                    left:0;
                    right:0;
                    margin:auto;
                }
                img.checked_img{
                    display:none;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: rgba(218,38,60,.9);
                    background-image:url('../assets/images/program_check.png');
                }
            }
            .other-pic {
                background-image:url(../assets/images/Rent-OtherProgram-Image.png);
                background-color:#da263c;

                img.checked_img{
                    display:none;
                    background-color:rgba(255,255,255,0);
                }
            }
            .name{
                width:100%;
                height:50px;
                text-align:center;
                line-height:25px;
                background-color:#da263c;
                font-size:14px;
                font-weight:bold;
                color:#FFF;
                overflow:hidden;
                display:block;
            }
        .clear{
            clear:both;
        }
    .button-style{
        text-align: center;
        width:calc(100% - 40px);
        margin:10px;
        background-color: #DF1C2C;
        border: 0 none;
        padding: 10PX;
        font-size: large;
        font-weight: 500;
        color: #fff;
        border-radius: 5px;
        font-weight: 500;
        cursor:pointer;
        text-decoration: none;
        float: right;
    }
    a.button-style{
        width: 100%;
        display: block;
        padding: 10px 0;
    }
    .agree{
        float: left;
        margin: 0 15px;
    }

    #mode{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        display:block;
        
        ul{
            padding:0;

            li{
                list-style:none;
                margin-bottom:10px;

                input{
                    width:calc( 100% - 14px);
                    height:35px;
                }
                select{
                    width:100%;
                    height:37px;
                }
            }

            .float-left{
                float:left;
                width:50%;
            }

            .float-right{
                float:right;
                width:50%;
            }

            .color-red{
                color:#e40426;
            }

            .font-size-big {
                font-size:20px;
            }

            .font-weight-bold{
                font-weight:bold;
            }
        }
    }


    #payment{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        display:block;
    }
        .plan{
            float:left
            margin: 5px;
            width: calc(100% - 14px);
            border: 2px solid #fdfdfd;
            height:300px;
            border-radius:5px;
            overflow:hidden;
        }
            .plan > input[type=radio]{
                position: absolute;
                z-index: -999;
            }
            .plan > label{
                display:inline;
                cursor:pointer;
            }
            .plan > input[type=radio]:checked + label > .text {
                width:calc( 100% - 4px);
                height:calc( 100% - 44px );
                border: 2px solid #da263c;
            }
            .text{
                width:calc( 100% - 4px);
                height:calc( 100% - 44px );
                display:block;
                background-img:;
                background-color:#ccc;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                overflow:hidden auto;
                border:2px solid #ccc;
            }
                .text-content{
                    margin:20px;
                }
            .name{
                width:100%;
                height:40px;
                text-align:center;
                line-height:20px;
                background-color:#da263c;
                font-size:14px;
                font-weight:bold;
                color:#FFF;
                overflow:hidden;
                display:block;
            }
        .clear{
            clear:both;
        }
    .explain{
        font-size:12px;
        color:#ccc;
        text-align:center;
        text-decoration:underline;
        cursor:pointer;
        margin-bottom:20px;
    }


    #contact{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        display:block;

        ul{
            padding:0;

            li{
                list-style:none;
                margin-bottom:10px;

                input{
                    width:calc( 100% - 14px);
                    height:35px;
                }
                input.mail{
                    width:calc( 100% - 115px);
                    height:35px;
                    display:inline-block;
                    border-bottom-right-radius:0;
                    border-top-right-radius:0;
                    border-right-width: 0;
                }
                a.login{
                    width:100px;
                    height:37px;
                    line-height:37px;
                    text-align:center;
                    display:inline-block;
                    vertical-align: bottom;
                    border:0;
                    border-bottom-right-radius:0.25rem;
                    border-top-right-radius:0.25rem;
                    background-color:#da263c;
                    cursor:pointer;
                    color:#fff;
                    font-weight:bold;
                    text-decoration:none;
                }
                a.login:hover{
                    -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                    -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                    box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                    color:#eee;
                }
                select{
                    width:100%;
                    height:37px;
                }
                input[type=checkbox].float-left{
                    float:left;
                    width:20px;
                }
                span{
                    font-size:14px;
                    line-height:41px;
                    .float-right{
                        float:right;
                        width:calc( 100% - 30px );
                    }
                }
                .button-style{
                    width:100%;
                    margin:auto;
                    float:none;
                }
                ul{
                    font-size:14px;
                    margin-top:40px;
                    li{
                        list-style:disc;
                        list-style-position:inside;
                        margin-bottom:5px;
                    }
                    li:first-child{
                        list-style:none;
                    }
                }
            }
        }
    }

    #program_done{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        display:block;

        .title{
            margin-top: 30px;
            margin-bottom: 30px;
            color: #ce243b;
            text-align: center;
            h2{
                font-size: 1.6em;
                line-height: 1.6em;
                font-weight: bold;
            }
        }

        .sub_style{
            text-align:center;
            width:100%;
        }

        ul{
            padding:0;
            li{
                list-style:none;
                margin-bottom:10px;

                button.button-style{
                    width:100%;
                    margin:auto;
                    float:none;
                }
                ul{
                    li{
                        list-style:disc;
                        list-style-position:inside;
                    }
                }
            }
        }
    }

    #program_fail{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        display:block;

        .title{
            margin-top: 30px;
            margin-bottom: 30px;
            color: #ce243b;
            text-align: center;
            h2{
                font-size: 1.6em;
                line-height: 1.6em;
                font-weight: bold;
            }
        }

        .sub_style{
            text-align:center;
            width:100%;
        }

        ul{
            padding:0;
            li{
                list-style:none;
                margin-bottom:10px;

                button.button-style{
                    width:100%;
                    margin:auto;
                    float:none;
                }
                ul{
                    li{
                        list-style:disc;
                        list-style-position:inside;
                    }
                }
            }
        }
    }

    // LightBox ----- 商品簡介
    .lightBox-item{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-item.close{
        display:none;
    }
        .lightBox-item-Content{
            width:320px;
            height:500px;
            max-height: calc(100vh - 197px);    
            position: absolute;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            padding: 20px;
            margin:auto;
        }
            .lightBox-item-Content > .CloseBTN{
                border-radius:999rem;
                border:1px solid #ccc;
                background-color:#FFF;
                width:20px;
                height:20px;
                line-height:20px;
                text-align:center;
                font-weight:bold;
                font-size:14px;
                cursor:pointer;
                float:right;
            }
            .lightBox-item-Content > .LB-C-Pic{
                width:100%;
                height:200px
                border:1px solid #ccc;
                border-radius:5px;
                margin-bottom:20px;
            }
            .lightBox-item-Content > .LB-C-Name{
                width:100%;
                hidden:30px;
                line-height:30px;
                font-size:20px;
                font-weight:bold;

            }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            margin:auto;

            .lightBox-explain-Content-inside{
                padding: 20px;

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#FFF;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description > ul{
                    padding:0;

                    li{
                        list-style-type:none;
                    }
                }

                .LB-C-Description > ul.list-style-type-number{
                    li{
                        list-style-position: inside;
                        list-style-type: decimal;
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }
        }


    @media screen and (max-width: 480px){
        #equipment{
            display:block;
        }
            .item{
                flex:none;
                width: calc( 100% - 14px);
                height:250px;
                margin:10px 5px;
            }
    }
`
