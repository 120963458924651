import styled from "styled-components";

export const Wrapper = styled.div`
    
.react-tab-list {
    list-style: none;
    background: black;
    margin: 0;
    padding: 0;
    
    li {
      display: inline-block;
      vertical-align: bottom;
      padding: 10px;
      
      &.active {
        background: #eee;
      }
      
      a {
        text-decoration: none;
        color: orange;
      }
    }
  }
  
  .tab{
    background-color: #DF1C2C;
  }

  .react-tab-panel {
    display: none;
    background: rgba(251,243, 221,1);
    min-height: 400px;
    padding: 0.5em 1em;
  
    &.active {
      display: block;
    }
  }

  .react-tabs {
    position: relative;
    top: 5px;
    .react-tabs__tab {
        padding: 10px 0;
        position: relative;
        list-style: none;
        cursor: pointer;
        width: 50%;
        text-align: center;
        display: inline-block;
        vertical-align: bottom;
        border: none;
        font-size: .8rem;
    }
}
`;

export const TabButton = styled.div`
width: 100%;
height: 40px;
border-bottom: 2px solid rgb(217, 29, 48);
display: flex;
.all,
.unread {
    width: 49.666666%;
    height: 40px;
    flex: 1;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    span.more {
        color: #fff;
        background-color: #DF1C2C;
        width: 20px;
        height: 20px;
        border-radius: 999rem;
        font-size: 10px;
        line-height: 20px;
        display: inline-block;
        margin-left: 1rem;
    }
    &.click {
        background-color: rgb(217, 29, 48);
        color: #fff;
    }
}
`;


export const SortSelect = styled.div`
    background: #fff;
    padding: 5px 10px 10px 5px;
    color: #000;
    text-align: right;
    .select-group {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 auto;
        .select {
            display: inline;
            margin-left: 10px;
        }
    }
    .selectDiv{
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        justify-content: center;
        .paymentStatusSort{
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            // width: 50%;
            flex: 4;
            div{
                display: flex;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                justify-content: center;
                width: 50%;
                text-align: center;

                label{
                    width: 100%;
                    height: 30px;
                    z-index: 1;
                    cursor: pointer;
                    background-color: #fff;
                    color: #333;
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: normal;
                    text-align: center;
                    text-shadow: none;
                    padding: 0.15rem 0rem 0.15rem 0rem;
                    // padding: 6px 14px;
                    margin: 0px;
                    border: 1px solid rgb(206, 212, 218);
                    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
                    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
                    -webkit-transition: all 0.02s ease-in-out;
                    -moz-transition:    all 0.02s ease-in-out;
                    -ms-transition:     all 0.02s ease-in-out;
                    -o-transition:      all 0.02s ease-in-out;
                    transition:         all 0.02s ease-in-out;

                    @media screen and (max-width: 480px){
                        margin-top: 2px;
                        height: 26px;
                        line-height: 26px;
                    }
                }
                label.hidePaidLabel{
                    border-top-left-radius: 999rem;
                    border-bottom-left-radius: 999rem;
                    margin-right: -15px;
                    @media screen and (max-width: 480px){
                        margin-right: -10px;
                    }
                }
                label.showAllLabel{
                    border-top-right-radius: 999rem;
                    border-bottom-right-radius: 999rem;
                    margin-left: -15px;
                    @media screen and (max-width: 480px){
                        margin-left: -10px;
                    }
                }
            }
            div.check{
                label{
                    border-radius: 999rem;
                    background-color: #da263c;
                    color: #fff;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    z-index: 2;
                }
            }
            label.check{
              background-color: rgba(223,28,44,.6);
              -webkit-box-shadow: none;
              box-shadow: none;
            }
        }
        .select-group{
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            justify-content: center;
            margin-left: 5px;
            // width: 50%;
            flex: 6;
            span{
                font-size: .8rem;
                line-height: 40px; 
                width:2.2rem;
                text-align:center;
                display:flex;
            }
            .select{
                margin-left: 5px;
                flex:1;
                display:flex;
            }
        }
    }
`;
