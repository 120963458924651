import { combineReducers } from "redux";
import activity from "./activityReducer";
import myInfo from "./myInfo";
import NewGroup from "./NewGroup";
import Register from "./Register";
import groupDetailReducer from "./groupDetailReducer";
import comment from './comment';
import forget from './forget';
import media from './media';
import notification from './notification';
import imageUpload from './imageUpload';
import message from './message';
import rentReducer from "./rentReducer";
import loginReducer from "./login"
import googleMapReducer from './googleMapReducer';
import affiliateReducer from './affiliateReducer';
import oauthReducer from './oauthReducer.js';
import invoiceReducer from './invoiceReducer';

export default combineReducers({
    // 一次讀入Reducers
    activity: activity,
    myInfoReducer: myInfo,
    newGroupReducer: NewGroup,
    registerReducer: Register,
    groupDetailReducer: groupDetailReducer,
    commentReducer: comment,
    forgetReducer: forget,
    mediaReducer: media,
    notificationReducer: notification,
    imageUploadReducer: imageUpload,
    messageReducer: message,
    rentReducer:rentReducer,
    loginReducer: loginReducer,
    googleMapReducer:googleMapReducer,
    affiliateReducer:affiliateReducer,
    oauthReducer:oauthReducer,
    invoiceReducer:invoiceReducer,
});
