import React, { Component } from "react";
import { Wrapper } from "./styled"

class CommentForm extends Component {
    constructor(props) {
      super(props);
      this.messageRef = React.createRef()
    }
    
    componentDidUpdate(prevProps) {
      const messageRef = this.messageRef.current;
      const { value, onChange } = this.props;
      //console.log(this.props)
      if (prevProps.value !== this.props.value && this.props.value.trim() !== '') {
        messageRef.value = `@${value} ${messageRef.value}`;

        onChange(messageRef.value)
      } else if (this.props.value === "" && this.props.message.trim() === "") {
        messageRef.value = ""
      } 
    }

    onFocus() {
      setTimeout( ()=> {
        let message = this.messageRef.current;
        if(!message) return
        // but commentForm not only use at message, but also comment
        let footer = document.getElementById("footer");
        let { top: messageTop, height: messageHeight } = message.getBoundingClientRect();
        let { top: footerTop, height: footerHeight } = footer.getBoundingClientRect();
        let bottomLine = messageTop + messageHeight;

        // 假如 message 可能在 footer 下面 (?)
        if ((messageTop < footerTop && footerTop < bottomLine) || (footerTop+footerHeight) < messageTop){
          footer.style.display = 'none'
        }

        let el = document.getElementsByClassName("input-button")[0].firstElementChild
        if(el.scrollIntoViewIfNeeded){
          el.scrollIntoViewIfNeeded()
        }else{
          el.scrollIntoView()
        }
        // 應該是怕抓不到所以 setTimeOut
      }, 500)
    }

    onBlur() {
      let footer = document.getElementById("footer")
      footer.style.display = 'block'
    }

    render() {
        const { input, onChange, onClick } = this.props;
        
        return (
            <Wrapper className="input-button">
                <input type="text" ref={this.messageRef} {...input} onChange={(e) => onChange(e.target.value)} onFocus={() => this.onFocus()} onBlur={() => this.onBlur()}/>
                <button onClick={onClick}>
                    送出
                </button>
            </Wrapper>
        );
    }
}

CommentForm.defaultProps = {
    label: "",
    onChange: null,
    error: null,
    onClick: null,
    value: ''
};

export default CommentForm;
