import { createAction } from 'redux-actions';
import { fetchObject } from './fetchModule';
import { fetchlogin } from './LoginActions';
import ReactGA from "react-ga";
import Config from "../component/config";
import { history } from '../index';
import { getCookie, localStorageUtility } from '../utilities';

export const getLevelsData = createAction("GET_LEVELS");
export const getoccupationData = createAction("GET_OCCUPATIONS");
export const gethobbyData = createAction("GET_HOBBY");
export const shouldLoadingOpen = createAction("SHOULD_LOADING_OPEN"); // Loading Mask ON
export const setEmailVerifyMessage = createAction("SET_EMAIL_VERIFY_MESSAGE");
export const shouldMobilelVerifyTrue = createAction("SHOULD_MOBILE_VERIFY_TRUE");
export const setMobileVerifyMessage = createAction("SET_MOBILE_VERIFY_MESSAGE");


export const fetchRegister = (Body) => {
    return (dispatch, getState) => {
        fetchObject('user/register', "", {
            method: 'post',
            body: JSON.stringify(Body)
        })
        .then(function (response) {
            dispatch(shouldLoadingOpen(true));
            return response.json();
        })
        .then(function (json) {
            if (json.id) {
                alert('註冊成功！');
                let userIP = getCookie('IP');
                let landingPageCode = getCookie('LandingPage');
                ReactGA.initialize(Config.gaId);
                ReactGA.event({
                    category: `Member`,
                    action: `Register_isSuccess`,
                    label: `UserID:${json.id}/UserIP:${userIP}/isType${landingPageCode ? landingPageCode : `Origin`}`, 
                });

                return json;
            } else {
                switch (json.code) {
                    case 'EMAIL_USED': {
                        if (!Body.alertMessage){
                            alert('信箱已註冊，請登入！');
                            history.push('/login');
                        }
                        else {
                            localStorageUtility('set', 'registerSuccess', false);
                            alert(Body.alertMessage)
                        }
                        break
                    }
                    case 'E_INVALID_NEW_RECORD': {
                        alert('產生資料失敗，欠缺必填項目或是格式不符');
                        break
                    }
                    default: {
                        window.alert('請聯繫客服協助處理！');
                        history.replace("/ErrorPage");
                    }
                }
                dispatch(shouldLoadingOpen(false));
            }
        })
        .then(function (data) {
            // console.log(data);
            let payload;
            if (Body.autoLogin === true) {
                payload = { 
                    email: Body.email, 
                    password: Body.password, 
                    dontGoToMyInfo: true, 
                    signUpGroupId:Body.signUpGroupId,
                    redirectUrl: Body.redirectUrl,
                    agent: Body.agent,
                    playerId: Body.playerId,
                    userId: data.id
                }
                fetchlogin(payload)(dispatch);
            } else {
                history.replace("/login");
            }
        })
        .catch(function (ex) {
            localStorageUtility('set', 'dontGoToLogin', false);
            console.log("Register failed", ex);
        });
    };
};

export const verifyEmail = (query) => {
    return (dispatch, getState) => {
        fetchObject('verify/verify', query)
            .then(function (response) {
                if(!response.ok){
                    return response.json();
                }
            })
            .then( json =>{
                if( !json || !json.code){
                    dispatch(setEmailVerifyMessage('verify success'));
                }else{
                    throw new Error(json.code);
                }
            })
            .then( ()=>{}, err =>{
                switch(err.message){
                    case 'USER_VEIFIED':
                        dispatch(setEmailVerifyMessage('USER_VEIFIED'));
                        alert('信箱已驗證，無需再驗證')
                        break;
                    case 'USER_NOT_EXIST':
                        dispatch(setEmailVerifyMessage('USER_NOT_EXIST'));
                        alert('信箱不正確，請重新查看驗證信連結')
                        break;
                    case 'VERIFY_CODE_INVALID':
                        dispatch(setEmailVerifyMessage('VERIFY_CODE_INVALID'));
                        alert('驗證錯誤，請前往個人資訊頁確認')
                        break;
                    default:
                        dispatch(setEmailVerifyMessage(''));
                        alert('操作逾時，請重新驗證或聯絡客服')
                        break;
                }
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const sendVerifyMail = (email) => {
    return (dispatch, getState) => {
        fetchObject('user/resend', '',{
            method: 'POST',
            body: JSON.stringify({ email })
        })
            .then(function (response) {
                if(!response.ok){
                    return response.json();
                }
            })
            .then( json =>{
                if( !json || !json.code){
                    // success area
                    alert('驗證信已寄送，請前往信箱進行驗證')
                }else{
                    throw new Error(json.code);
                }
            })
            .then( ()=>{}, err =>{
                switch(err.message){
                    case 'USER_VEIFIED':
                        alert('信箱已驗證，無需再驗證信箱')
                        break;
                    default:
                        alert('操作逾時，請重新登入或聯絡客服')
                        break;
                }
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

// 新增手機驗證 --- 2020/01/15
export const verifyMobile = (Body) => {
    //debugger
    return (dispatch) => {
        fetchObject(`verify/verifyCode`, "", {
            method: "POST",
            body: JSON.stringify(Body)
        })
            .then((response) => {
                dispatch(shouldMobilelVerifyTrue(false));
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                // console.log(json)
                if (json === 'OK') {
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // console.log('post is success of the verifyMobile');
                // alert('恭喜，已成功驗證您的手機！');
                dispatch(shouldMobilelVerifyTrue(true));
                dispatch(setMobileVerifyMessage('VERIFY_SUCCESS'));
            }, (error) => {
                // 錯誤狀態判斷 顯示
                // console.log(error);
                switch(error.message){
                    case 'NO_CODE':
                        // alert('未輸入驗證碼');
                        dispatch(setMobileVerifyMessage('NO_CODE'));
                        break;
                    case 'NO_PHONE_NUMBER':
                        // alert('未輸入手機號碼');
                        dispatch(setMobileVerifyMessage('NO_PHONE_NUMBER'));
                        break;
                    case 'INCORRECT_CODE':
                        // alert('驗證碼不正確');
                        dispatch(setMobileVerifyMessage('INCORRECT_CODE'));
                        break;
                    case 'CODE_EXPIRED':
                        // alert('驗證碼過期');
                        dispatch(setMobileVerifyMessage('CODE_EXPIRED'));
                        break;
                    default:
                        // alert('其他錯誤，請重新驗證或聯絡客服');
                        dispatch(setMobileVerifyMessage('OTHER_ERROR'));
                        break;
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const sendVerifyMobile = (Body) => {
    //debugger
    return (dispatch) => {
        fetchObject(`verify/sendVerificationCode`, "", {
            method: "POST",
            body: JSON.stringify(Body)
        })
            .then((response) => {
                // console.log('post of the sendVerifyMobile');
                dispatch(shouldMobilelVerifyTrue(false));
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                // console.log('json:',json);
                if (json === 'OK') {
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // console.log('data:',data);
                // alert('已發送驗證碼至您的手機號碼：' + mobile);
                dispatch(setMobileVerifyMessage('SEND_SUCCESS'));
            }, (error) => {
                // 錯誤狀態判斷 顯示
                // console.log(error);
                switch(error.message) {
                    case 'NO_PHONE_NUMBER':
                        // alert('無該手機號碼，請重新輸入');
                        dispatch(setMobileVerifyMessage('NO_PHONE_NUMBER'));
                        break;
                    case 'PHONE_USED':
                        // alert('手機號碼：' + mobile + '已被使用');
                        dispatch(setMobileVerifyMessage('PHONE_USED'));
                        break;
                    case 'SEND_FAIL':
                        // alert('簡訊發送失敗，請重新操作');
                        dispatch(setMobileVerifyMessage('SEND_FAIL'));
                        break;
                    default:
                        // alert('其他錯誤，請重新操作或聯絡客服');
                        dispatch(setMobileVerifyMessage('OTHER_ERROR'));
                        break;
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const getlevellist = () => {
    return (dispatch, getState) => {
        fetchObject(`level/list`)
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(getLevelsData(json.data));

            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const getoccupationlist = () => {
    return (dispatch, getState) => {
        fetchObject('occupation/list')
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(getoccupationData(json.data));
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const gethobby = () => {
    return (dispatch, getState) => {
        fetchObject('hobby/list')
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(gethobbyData(json.data));
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};
