import styled from 'styled-components';

export const Wrapper = styled.div`
  // display: inline-block;
  vertical-align: bottom;
  display: flex;
  flex: 1;
  align-items:center; 
  justify-content:center;
  text-align: center;
  // padding-left:20px;  

  img {
    width: 20px;
    height: 20px;
  }
  i {
    color: #da263c;
    margin-right: 2px;
  }
  span {
    padding-left:5px;  
    color: #666;
  } 
`;
