import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { postAuthorize, getVerify } from "../../../actions";
import { LoadingIndicator, GaSetting } from "../../Common";
import { Wrapper } from './styled';

class AskForAuthorization extends Component {
    constructor(props) {
        super(props);
        const state = this.props.location.state;
        
        this.state = {
            userInfo: state && state.userInfo,
            clientId: state && state.clientId,
            redirectUri: state && state.redirectUri,
            scope: state && state.scope,
            email: state && state.email,
            status: state && state.status,
            code: state && state.code,
        }
    }

    componentDidMount() {
        const { clientId, redirectUri, scope, status, code } = this.state;
        if (status === 2 && code) {
            this.props.history.replace({ pathname: '/dialog/redirect', state: { clientId: clientId, redirectUri: redirectUri, scope: scope, isAuthorization: true, code: code, status: status} });
        } else {
            this.props.getVerify({'clientId': clientId, 'redirectUri': redirectUri, 'scope': scope});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // "description": "資料驗證狀態 (0: 未登入, 1: 已登入未驗證, 2: 已登入已驗證)"
        const { clientId, redirectUri, scope } = this.state;
        const { verifyData } = this.props;
        const status = verifyData && verifyData.status;
        const code = verifyData && verifyData.code;

        if (!prevProps.verifyData.code && status === 2 && code) {
            this.props.history.replace({ pathname: '/dialog/redirect', state: { clientId: clientId, redirectUri: redirectUri, scope: scope, isAuthorization: true, code: code, status: status} });
        } 
    }

    render() {
        const { userInfo, clientId, redirectUri, scope, email } = this.state;
        const style = {
            backgroundColor: `#f2f2f2`,
            width: `100%`,
            height: `100%`
        }
        return (
            <div style={style}>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <Wrapper>
                    <div className="BonnyLive_Logo">
                        <div className="logoImage">
                            <img src="/assets/images/logoWhite.svg" alt=""/>
                        </div>
                        <div className="logoImage">
                            <img src="https://www.bonny-live.com/img/logo.png?20180209" alt=""/>
                        </div>
                    </div>
                    <div className="text">
                        <h3><b>Bonny-Live</b> 將會取得以下資訊：</h3>
                        <h3>您的{scope}資料。</h3>
                    </div>
                    <div className="button">
                        <p>
                            {(userInfo && userInfo.email) || email ? '您同意使用以下身份繼續' : ''}
                        </p>
                        <button
                            onClick={(e) => {
                                this.props.postAuthorize({clientId: clientId, redirectUri: redirectUri, scope: scope, authorization: 1},e)
                            }}      
                        >
                            {userInfo && userInfo.email ? userInfo.email : email ? email : '確認使用目前帳號'}
                        </button>
                        <p style={{fontSize:'0.8rem',color:'gray'}}>如同意請點擊，上方按鈕繼續。</p>
                    </div>
                    <GaSetting 
                        data = {{ 
                            className: 'cancel',
                            category: 'Oauth', 
                            action: 'Click', 
                            label: 'User dont want to authorize', 
                            tag: 'link',
                            title: '切換帳號',
                            path: {
                                pathname: '/dialog/login',
                                state: {
                                    clientId: clientId,
                                    redirectUri: redirectUri,
                                    scope: scope,
                                }
                            },
                            style: {
                                color: `#000`,
                                cursor: `pointer`
                            }
                        }}
                    >
                        <div onClick={()=> window.localStorage.removeItem('myData')}>取消</div>
                    </GaSetting>
                </Wrapper>
            </div>
        );
        
    }
}

const mapStateToProps = state => ({
    loading: state.oauthReducer.shouldLoadingOpen,
    verifyData: state.oauthReducer.verifyData,
});

const mapDispatchToProps = ({
    postAuthorize: postAuthorize,
    getVerify: getVerify,
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AskForAuthorization));
