import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { fetchUserInfo } from '../../../../actions';
import { Wrapper } from './styled';
import { ActList, Card } from '../../../../component/Common';
import Banner from '../../Banner';

class MyNotice extends Component {
    componentDidMount() {
        const pathname = this.props.history.location.pathname;
        const userId = pathname.substr(pathname.lastIndexOf('/') + 1);
        this.props.getUserInfo(userId);
    }

    render() {
        if(!this.props.data) return null
        const { 
            // media, 
            name, 
            avatar, 
            background 
        } = this.props.data;
        const userData = {
            name,
            avatar,
            background
        }
        return [
            <Banner key={0} user={userData} disabledUpload={true} />,
            <Wrapper key={1}>
                <h2>
                    我的追蹤會員<Link to="/my_info/follower_list">返回我的追蹤</Link>
                </h2>
                <Tabs>
                    <TabList>
                        <Tab>開團</Tab>
                        {/*<Tab>影片</Tab>*/}
                    </TabList>
                    <TabPanel>
                        <TabContent data={this.props.activity} />
                    </TabPanel>
                    {/*<TabPanel>
                        <TabContent2 data={media} user={name} />
                    </TabPanel>*/}
                </Tabs>
            </Wrapper>
        ];
    }
}
class TabContent extends Component {
    render() {
        const { data } = this.props;
        return (
            <ActList key="1">
                {data &&
                    data.map((activity, i) => (
                        <Card
                            data={activity}
                            key={i}
                            url={`/sign_up_activity?${activity.id}`}
                        />
                    ))}
            </ActList>
        );
    }
}

// class TabContent2 extends Component {
//     render() {
//         const { data, user } = this.props;
//         console.log(data);
//         return ( 
//             <VideoList
//                 list={data}
//                 name={user}
//             />
//         );
//     }
// }

const mapStateToProps = state => ({
    activity: state.activity.activity,
    data: state.myInfoReducer.otherUserInfo
});
const mapDispatchToProps = dispatch => ({
    getUserInfo: userId => dispatch(fetchUserInfo(userId, true))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyNotice);
