import React, { Component } from "react";
import { Wrapper } from "./styled";
import { GaSetting } from "../../Common";

class SubmitButton extends Component {
	render() {
		return (
	        <Wrapper>
	          	<GaSetting 
		            data = {{
		                category: 'MyBonny', 
		                action: 'MyBonny-member-register', 
		                label: '', 
		                tag: 'link',
		                path: '/register',
		            }}
	          	>
		          	<p>立即註冊</p>
		          	<i className="material-icons">
						arrow_right
					</i>
	         	</GaSetting>
	        </Wrapper>
		);
	}
}

export default SubmitButton;
