import styled from "styled-components";

export const Wrapper = styled.div`
`;

export const List = styled.div`
    width: calc(100% - 40px);
    margin: auto;
    button {
        // padding: 0px 15px;
        position: relative;
        display: block;
        line-height: 40px;
        width: 100%;
        text-align: left;
        border: none;
        border-bottom: 1px #ccc solid;
        font-size: .9rem;
        outline: none;
        background: #fff;
        &:hover {
            // opacity: 0.8;
            cursor: pointer;
        }
        &:disabled {
            &:hover {
                opacity: 1;
                cursor: default;
            }
            &::after {
                content: "";
            }
        }
        img {
            // position: relative;
            // top: 6px;
            margin-right: 20px;
        }
        &:last-child {
            border: none;
        }
        // &::after {
        //     content: url('/assets/icons/arrowJoin.png');
        //     position: absolute;
        //     right: 15px;
        //     // top: 2px;
        // }
        span {
            &::before {
                content: '、';
            }
            &:nth-child(2) {
                &::before {
                    content: '';
                    
                }
            }
        }
        i{
            width: 25px;
            height: auto;
            font-size: 25px;
            vertical-align: middle;
            margin-right: 20px;
            text-align: center;
            color: gray; 
        }
        .arrow-right{
            position:absolute;
            right:0;
            top: 0;
            bottom: 0;
            margin: auto;
            line-height: 40px;
        }
    }
    .memberLevel{
        padding: 0px 0px 0px 6px;
        position: relative;
        display: block;
        line-height: 40px;
        text-align: left;
        border: none;
        border-bottom: 1px #ccc solid;
        font-size: .9rem;
        outline: none;
        background: #fff;
        i{
            width: 25px;
            height: auto;
            font-size: 25px;
            vertical-align: middle;
            margin-right: 20px;
            text-align: center;
            color: gray;
        }
    }
    .flexBox{
        display: flex;
        justify-content: center;
        align-items: center;
        i.icon{
            display: flex;
        }
        span{
            display: inline-block;
            flex: 1;
            overflow : hidden;
            text-overflow : ellipsis;
            white-space : nowrap;
        }
        .verified {
            width: 4em;
            height: 2em;
            line-height: 2em;
            text-align: center;
            margin-right: 25px;
            border-radius: 999rem;
            border: 1px solid #808080;
            color: #808080;
            font-size: 12px;
        }
        i.arrow{
            display: flex;
            margin-right: 6px;
        }
    }
    .hoverStyle{
    }
    .hoverStyle:hover {
        // animation-name: hoverAnimation;
        // animation-duration: 10s;
        // animation-iteration-count: 1;
    }
    @keyframes hoverAnimation{
        0% { 
            box-shadow: unset;
        }
        25% { 
            -moz-box-shadow: inset -50px 0 0 rgba(218, 38, 60, 1);
            -webkit-box-shadow: inset -50px 0 0 rgba(218, 38, 60, 1);
            box-shadow: inset -50px 0 0 rgba(218, 38, 60, 1);
        }
        100% { 
            -moz-box-shadow: inset -50px 0 0 rgba(218, 38, 60, 1);
            -webkit-box-shadow: inset -50px 0 0 rgba(218, 38, 60, 1);
            box-shadow: inset -50px 0 0 rgba(218, 38, 60, 1);
        }
    }
    .applyAffiliateButton{
        display: block;
        margin: 30px auto 5px auto;
        padding: 5px 10px;
        cursor: pointer;
        width: 30%;
        text-align: center;
        
        border-radius: 999rem;
        border: 2px solid #da263c;
        color: #da263c;
        font-weight: 500;
    }
}
`;
