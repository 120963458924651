import styled from 'styled-components';

export const DefaultButton = styled.button`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.8rem;
  color: #fff;
  height: 35px;
  margin: 5px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #437cae;
  // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
  // text-shadow: 0px -2px #2e5577;
  border-radius: 999rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  &.active{
    background-color: #2e5577;
    color: #eee;
  }
  &:hover {
    background-color: #80a3c1;
    color: #eee;
    border: 2px solid #80a3c1;
    box-shadow: unset;
    text-shadow: unset;
    height: unset;
    min-height: 35px;
  }

  &.red {
    background-color: #da263c;
    // text-shadow: 0px -2px rgb(223,28,44);

    &.active {
      background-color: rgba(162,28,46,1);
      color: #eee;
    }
    &:hover {
      background-color: #d85969;
      color: #eee;
      border: 2px solid #d85969;
    }
  }
  &.transparentBlue {
    color: #437cae;
    background-color: transparent;
    text-shadow: unset;
    box-shadow: unset;
    font-weight: 600;
    border: 2px solid #2e5577;

    &.active {
      background-color: #2e5577;
      color: #eee;
    }
    &:hover {
      background-color: #80a3c1;
      color: #eee;
      border: 2px solid #80a3c1;
      box-shadow: unset;
      text-shadow: unset;
      height: unset;
      min-height: 35px;
    }
  }
  &.transparentRed {
    color: #da263c;
    background-color: transparent;
    text-shadow: unset;
    box-shadow: unset;
    font-weight: 600;
    border: 2px solid #da263c;

    &.active {
      background-color: #da263c;
      color: #eee;
    }
    &:hover {
      background-color: #d85969;
      color: #eee;
      border: 2px solid #d85969;
    }
  }

  &[disabled] {
    display: none;
    cursor: default;
    color: #333;
    background-color: rgba(216,216,216,.6);
    box-shadow: outset 0 -0.125rem rgba(0,0,0,0.2);
  }
`;
