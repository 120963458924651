export const getSharedLink = (urlParam, isSearchRequire, search) => {
    var url = window.location.href 
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2] 

    if (urlParam === undefined ){
        console.log("no urlParam");
        return
    }


    if( isSearchRequire === true ){
        if(search){
            console.log(result + urlParam + search);
            return result + urlParam + search
        }else{
            return
        }
    }else{
        return result + urlParam 
    }

}