import React, { Component } from "react";
import moment from 'moment';

export default class ReplaceCancelLightBox extends Component {

    sendDeleteReplace = () => {
        const {
            order_id,
            payment_id, 
            shipment_id,
        } = this.props

        document.getElementById("SendCancelReplaceButton").disabled = true;
        this.props.deleteReplace({
            order_id,
            shipment_id,
            payment_id,
        })
        this.props.handleInformationHidden("deleteReplace", "toggle");
    };

    render(){

        const {
            handleInformationHidden,
            shipment
        } = this.props

        return(
            <div id="deleteReplace" className="lightBox-explain close">
                {/*LightBox ----- 取消申請換拍確認 */}
                {/* Replace lightBox */}
                <div className="lightBox-explain-Content">
                    <div className="lightBox-explain-Content-inside">
                        <div
                            className="lightBox-inside-close-button"
                            onClick={() => {
                                handleInformationHidden(
                                    "deleteReplace",
                                    "toggle"
                                );
                            }}
                        >
                            X
                        </div>
                        <div className="LB-C-title">
                            確認取消申請換拍
                        </div>
                        <div className="LB-C-Description">
                            <ul>
                                <li>
                                    申請換拍日期：
                                    <span>
                                        {(shipment &&
                                            shipment[0]
                                                .replace_date) !== 0 &&
                                            moment(
                                                parseInt(
                                                    shipment
                                                        ? shipment[0]
                                                                .replace_date
                                                        : ""
                                                )
                                            ).format("YYYY/MM/DD")}
                                    </span>
                                </li>
                                <li>
                                    申請換拍原因類型：
                                    <span>
                                        {shipment
                                            ? shipment[0].replace_type
                                            : ""}
                                    </span>
                                </li>
                                <li>
                                    申請換拍備註：
                                    <span>
                                        {shipment
                                            ? shipment[0].replace_note
                                            : ""}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="light-outside-button-group">
                        <div
                            className="light-outside-button-group-check"
                            onClick={this.sendDeleteReplace}
                        >
                            確認
                        </div>
                        <div className="light-outside-button-group-line"></div>
                        <div
                            className="light-outside-button-group-close"
                            onClick={() => { handleInformationHidden("deleteReplace", "toggle");} }
                        >
                            取消
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}