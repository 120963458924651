import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #EBEBEB;
    padding: 5px 15px;
    margin: 0;
    margin-top: 20px;

    
    .hint {
        font-weight: normal;
        color: #a5a5a5;
        width: 100%;
    }
    label.title{
        // display: inline-block;
        // vertical-align: bottom;
        width: 6rem;
        @media (max-width: 480px){
            display:block;
            width:100%;
        }
    }
    input[type="text"].fileNames{
        flex: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0px !important;
    }
    .upload-btn-wrapper {
        width: 5em;
        height: 35px;
        position: relative;
        
        // display: inline-block;
        // vertical-align: bottom;
        // display: flex;
        // display: -webkit-box;
        // display: -webkit-flex;
        // display: -ms-flexbox;
        // flex: 2;
        // -webkit-flex: 2;
        // -ms-flex: 2;
        // width: 20%;
        // @media (max-width: 480px){
        //     width: 30%;
        // }
        label {
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            // margin-left: -3px;
            // width: 4rem;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            color: #fff;
            background: #2f82bf;
            font-weight: unset;
            margin: 0;
            // padding: 7.5px 10px;
            img {
                position: relative;
                // top: 2px;
                margin-right: 5px;
            }
            i {
                margin-left: .5rem;
                width: 1rem;
                // height: 35px;
                // line-height: 1rem;
                margin-bottom: calc( ( 35px - 1rem ) / 2 ) !important;
            }
            div {
                flex: 1;
                // height: 35px;
                // line-height: 1rem;
                margin-bottom: calc( ( 35px - 1rem ) / 2 ) !important;
            }
        }
        input[type=file] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }
    }

    .thumbnail {
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .cancel {
            position: absolute;
            top: 0;
            right: 0;
            color: white;
            background-color: rgba(218,38,60,0.6);
            padding: 5px 10px;
            cursor: pointer;

            &:hover {
              background-color: rgba(218,38,60,1);
            }
        }
    }
`;

export const ChildrenWrapper = styled.div`
    width: 100%;
    height: 100%;

    label {
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
    }
`;
