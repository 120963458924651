import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import moment from 'moment';
import { 
    getRacketOrderDetail, 
    cancelProgramOrReplaceRacket 
} from '../../../actions';
import { LoadingIndicator } from "../../Common";
import { Wrapper } from './styled';
import { validateEmail, localStorageUtility } from '../../../utilities';

class ReplaceRacket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateReplaceData:{
                action: 'replace',
                order_id: this.props.match && this.props.match.params && this.props.match.params.id,
                replace_type: '',
                replace_note: '',
                replace_user: '',
                replace_email: '',
                replace_phone: '',
                replace_address: '',
            },
            id: this.props.match && this.props.match.params && this.props.match.params.id,
        }
    }

    componentDidMount() {
        const userInfo = localStorageUtility('getAndParse', 'myData');
        this.props.getRacketOrderDetail(this.state.updateReplaceData.order_id);
        this.setState({
            updateReplaceData:{
                action: 'replace',
                order_id: this.state.updateReplaceData.order_id,
                replace_type: '',
                replace_note: '',
                replace_user: '',
                replace_email: userInfo.email,
                replace_phone: '',
                replace_address: '',
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isCancelOrReplaceDone) {
            this.props.history.push(`/my_info/rent_contract_detail/${this.state.id}`);
        }
    }
    
    handleOnChange = (element, e) => {
        let updateReplaceData = this.state.updateReplaceData;
        if(element === 'replace_type'){
            const replace_type_val = e.target.value;
            if(replace_type_val === '1'){
                document.getElementById("replace_type_Text").innerHTML = '正常使用損壞';
            }else if(replace_type_val === '2'){
                document.getElementById("replace_type_Text").innerHTML = '非正常使用損壞';
            }else if(replace_type_val === '3'){
                document.getElementById("replace_type_Text").innerHTML = '其他';
            }
        }
        if(element === 'replace_note'){
            const replace_note_val = e.target.value;
            document.getElementById("replace_note_Text").innerHTML = replace_note_val;
        }
        if(element === 'replace_user'){
            const replace_user_val = e.target.value;
            document.getElementById("replace_user_Text").innerHTML = replace_user_val;
        }
        if(element === 'replace_email'){
            const replace_email_val = e.target.value;
            document.getElementById("replace_email_Text").innerHTML = replace_email_val;
        }
        if(element === 'replace_phone'){
            const replace_phone_val = e.target.value;
            document.getElementById("replace_phone_Text").innerHTML = replace_phone_val;
        }
        if(element === 'replace_address'){
            const replace_address_val = e.target.value;
            document.getElementById("replace_address_Text").innerHTML = replace_address_val;
        }
        var changeValue = '';
        var updateCheck = 0;
        if(element === 'agree')
        {
            if(updateReplaceData.agree)
            {
                changeValue = false;
            }
            else{
                changeValue = true; 
            }
            
            updateCheck = 1;
        }
        else
        {
            changeValue = e.target.value;
            updateCheck = 1;
        }
        if(updateCheck === 1)
        {
            this.setState(prevState => ({
                updateReplaceData: {
                    ...prevState.updateReplaceData,
                    [element]: changeValue
                }
            }))    
        }
    }
    LightBoxChangeRacketOpen(e){
        var lightboxchangeracket = document.getElementById("lightbox-ChangeRacket");
        lightboxchangeracket.classList.remove("close");
    }
    LightBoxChangeRacketClose(e){
        var lightboxchangeracket = document.getElementById("lightbox-ChangeRacket");
        lightboxchangeracket.classList.add("close");
    }
    send = () => {
        document.getElementById("SendDataButton").disabled = true;
        const { cancelProgramOrReplaceRacket } = this.props;
        let updateReplaceData = this.state.updateReplaceData;
        let notifyMessage = '';
        // var MobileReg = /^(09)[0-9]{8}$/;
        var formCheck = 1;
        if(!updateReplaceData.replace_type)
        {
            notifyMessage = '請選擇取消理由';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if(!updateReplaceData.replace_note)
        {
            notifyMessage = '請確認已填寫其他/備註！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if(!updateReplaceData.replace_user)
        {
            notifyMessage = '請確認已填寫姓名！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if(!updateReplaceData.replace_email)
        {
            notifyMessage = '請確認已正確填寫信箱！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if(updateReplaceData.replace_email && validateEmail(updateReplaceData.replace_email) === false)
        {
            notifyMessage = '請確認信箱格式正確！';
            formCheck = 0; 
            document.getElementById("SendDataButton").disabled = false;  
        }
        else if(!updateReplaceData.replace_phone)
        {
            notifyMessage = '請確認已填寫電話！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        // else if( updateReplaceData.replace_phone && updateReplaceData.replace_phone.search(MobileReg) === -1)
        // {
        //     notifyMessage = '請確認電話格式正確！';
        //     formCheck = 0;
        //     document.getElementById("SendDataButton").disabled = false;
        // }
        else if(!updateReplaceData.replace_address)
        {
            notifyMessage = '請確認已填寫地址！';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }

        if(formCheck === 1) //發送訂單
        {   
            cancelProgramOrReplaceRacket(updateReplaceData);
        }
        else //show message if form value check failed
        {
            alert(notifyMessage);
        }
    }

    render() {
        const { rentReducerData } = this.props;
        const { updateReplaceData } = this.state;
        const racketOrderDetail = rentReducerData && rentReducerData.racketOrderDetail;
        const orderData = racketOrderDetail.order && racketOrderDetail.order[0];
        const shipmentData = racketOrderDetail.shipment && racketOrderDetail.shipment[0];
        return (
            <Wrapper key={0}>
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 申請換拍
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <form id="rent-form" method="POST" action="" >
                    <div id="RacketDetail_program_content">
                        <div className="title">
                            <h2> 羽你蓮心專案 </h2>
                        </div>
                    
                    {/* #####   換拍   ##### */}
                    {orderData && 
                        <ul id="change_racket" onChange={this.changeItem}>
                            <li>申請換拍：</li>
                            <li className="border-style margin-top-20px margin-bottom-20px">
                                <div className="img-top"
                                    style={{backgroundImage: `url(${orderData.racket_id.picture})`}}
                                ></div>
                                <div className="img-bottom">
                                    {orderData.racket_id.name}
                                </div>
                            </li>
                            <li className="margin-left-20px color-LightBlack">
                                申請日期：
                                {moment(new Date()).format('YYYY/MM/DD')}
                            </li>
                            <li className="margin-left-20px color-LightBlack display-flex-mom">
                                <div className="list-content-flex-son margin-right-5px">
                                    專案時間(起)：
                                    {moment(parseInt(orderData.contract_start_time)).format('YYYY/MM/DD')}
                                </div>
                                <div className="list-content-flex-son margin-left-5px">
                                    專案時間(訖)：
                                    {moment(parseInt(orderData.contract_end_time)).format('YYYY/MM/DD')}
                                </div>
                            </li>
                            <li className="margin-left-20px color-LightBlack display-flex-mom">
                                <div className="list-content-flex-son margin-right-5px">
                                    換拍編號：
                                    {shipmentData.id}
                                </div>
                                <div className="list-content-flex-son margin-left-5px">
                                    目前換拍次數：
                                    {orderData.replace_times}
                                </div>
                            </li>
                            <li className="margin-left-20px color-LightBlack display-flex-mom">
                                <div className="list-content-flex-son margin-right-5px">
                                    目前球拍編號：
                                    {shipmentData.racket_number}
                                </div>
                                <div className="list-content-flex-son margin-left-5px">
                                    換拍狀態：
                                    {
                                        shipmentData.replace_status === 0
                                    ? 
                                        <span>使用中</span> 
                                    :
                                        shipmentData.replace_status === 1
                                    && 
                                        <span>換拍中</span>
                                    }
                                </div>
                            </li>
                            <li className="margin-top-20px"></li>
                            <li className="margin-left-20px margin-top-5px color-LightBlack">姓名：</li>
                            <li className="margin-left-20px color-LightBlack">
                                <input type="text" name="replace_user" placeholder="請輸入聯絡人的姓名" onChange={(e) => this.handleOnChange("replace_user", e)}/>
                            </li>
                            <li className="margin-left-20px margin-top-5px color-LightBlack">E-mail：</li>
                            <li className="margin-left-20px color-LightBlack">
                                { updateReplaceData && updateReplaceData.replace_email}
                                {/*
                                <input type="text" name="replace_email" placeholder="請輸入聯絡人的E-MAIL" onChange={(e) => this.handleOnChange("replace_email", e)} onBlur={this.confirmEmail()}/>
                                */}
                            </li>
                            <li className="margin-left-20px margin-top-5px color-LightBlack">行動電話：</li>
                            <li className="margin-left-20px color-LightBlack">
                                <input type="text" name="replace_phone" placeholder="請輸入聯絡人的聯絡電話" onChange={(e) => this.handleOnChange("replace_phone", e)}/>
                            </li>
                            <li className="margin-left-20px margin-top-5px color-LightBlack">收送地址：</li>
                            <li className="margin-left-20px color-LightBlack">
                                <input type="text" name="replace_address" placeholder="請輸入聯絡人收件地址" onChange={(e) => this.handleOnChange("replace_address", e)}/>
                            </li>
                            <li className="margin-left-20px margin-top-5px color-LightBlack">換拍理由：</li>
                            <li className="margin-left-20px color-LightBlack">
                                <select name="replace_type" defaultValue="" onChange={(e) => this.handleOnChange("replace_type", e)}>
                                <option value="">---請選擇---</option>
                                <option value="1">正常使用損壞</option>
                                <option value="2">非正常使用損壞</option>
                                <option value="3">其他：文字方塊自行填寫</option>
                                </select>
                            </li>
                            <li className="margin-left-20px margin-top-5px color-LightBlack">其他/備註：</li>
                            <li className="margin-left-20px color-LightBlack">
                                <textarea style=
                                {{
                                    width: 100 + "%",
                                    maxWidth: 378 + "px",
                                    height: 150 + "px",
                                    minHeight: 150 + "px",
                                    borderStyle: "solid",
                                    borderWeight : 1 + "px",
                                    borderColor: "rgb(206, 212, 218)",
                                    borderRadius: 0.25 + "rem",
                                    padding: 10 + "px",
                                    boxSizing: "border-box"
                                }} name="replace_note" onChange={(e) => this.handleOnChange("replace_note", e)}></textarea>
                            </li>
                            <li className="margin-top-20px color-LightBlack">
                                注意事項：
                                    <ul>
                                        <li>當您申請換拍即同意接受我司據近40年承創國際名牌及頂尖球星用拍的經驗，足以判斷球拍是否正常受護，故對於合約期間內連續三年內斷拍次數最多以兩次為限（含），若甲方於合約期間內連續三年超過此約定上限，即無條件同意乙方並接受於約約滿後不再續約租賃的權利。</li>
                                        <li>首次申請換拍經我司核定後需先支付新台幣599元蓮心保障費，而於第二次申請換拍經我司核定後則須先行支付新台幣899元蓮心保障費。</li>
                                    </ul>
                            </li>
                            <li className="button-only-one">
                                <div className="button" onClick={this.LightBoxChangeRacketOpen}>確定換拍</div>
                            </li>
                        </ul>
                    }
                    </div>
                </form>
                {/*LightBox ----- 換拍確認 */}
                <div id="lightbox-ChangeRacket" className="lightBox-explain close">
                    <div className="lightBox-explain-Content">
                        <div className="lightBox-explain-Content-inside">
                            <div className="lightBox-inside-close-button" onClick={this.LightBoxChangeRacketClose}>X</div>
                            <div className="LB-C-title">確認申請換拍</div>
                            <div className="LB-C-Description">
                                <ul>
                                    <li>姓名：<span id="replace_user_Text"></span></li>
                                    <li>E-MAIL：
                                        <span id="replace_email_Text">
                                        { updateReplaceData && updateReplaceData.replace_email}
                                        </span>
                                    </li>
                                    <li>行動電話：<span id="replace_phone_Text"></span></li>
                                    <li>寄送地址：<span id="replace_address_Text"></span></li>
                                    <li></li>
                                    <li><hr/></li>
                                    <li>換拍理由：<span id="replace_type_Text"></span></li>
                                    <li>其他/備註：<span id="replace_note_Text"></span></li>
                                    <li></li>
                                    <li><hr/></li>
                                    <li>
                                        {
                                        orderData && orderData.replace_times >= 1
                                        ?
                                            <p>
                                                提醒您！<span style={{color:'red',}}>你於合約期間內已達換拍上限，本合約已視為期滿，請聯繫客服繳清未到期之所有應付款項，以取得具高殘值的球拍所有權。</span>
                                            </p>
                                        :
                                        orderData && orderData.replace_times < 1
                                        &&
                                            <p>
                                                提醒您！若您換拍次數達上限次數，本合約將自動視為期滿，需聯繫客服繳清未到期之所有應付款項，以取得具高殘值的球拍所有權。
                                            </p>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                        {/*<div className="lightBox-outside-close-button" onClick={this.LightBoxChangeRacketClose}>返回</div>*/}
                        <div className="light-outside-button-group">
                            <div id="SendDataButton" className="light-outside-button-group-check" onClick={this.send}>確認</div>
                            <div className="light-outside-button-group-line"></div>
                            <div className="light-outside-button-group-close" onClick={this.LightBoxChangeRacketClose}>取消</div>
                        </div>
                    </div>
                </div>
                {/*LightBox ----- 正常使用損壞說明 */}
                <div id="lightbox-ChangeReplace1" className="lightBox-explain close">
                    <div className="lightBox-explain-Content">
                        <div className="lightBox-explain-Content-inside">
                            <div className="lightBox-inside-close-button" onClick={this.LightBoxChangeReplace1Close}>X</div>
                            <div className="LB-C-title">正常使用損壞說明</div>
                            <div className="LB-C-Description">
                                <ul>
                                    <li>1：</li>
                                    <li>2：</li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                        <div className="lightBox-outside-close-button" onClick={this.LightBoxChangeReplace1Close}>確定</div>
                    </div>
                </div>
                {/*LightBox ----- 非正常使用損壞說明 */}
                <div id="lightbox-ChangeReplace2" className="lightBox-explain close">
                    <div className="lightBox-explain-Content">
                        <div className="lightBox-explain-Content-inside">
                            <div className="lightBox-inside-close-button" onClick={this.LightBoxChangeReplace2Close}>X</div>
                            <div className="LB-C-title">非正常使用損壞說明</div>
                            <div className="LB-C-Description">
                                <ul>
                                    <li>1：</li>
                                    <li>2：</li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                        <div className="lightBox-outside-close-button" onClick={this.LightBoxChangeReplace2Close}>確定</div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.rentReducer.shouldLoadingOpen,
    rentReducerData: state.rentReducer,
    isCancelOrReplaceDone: state.rentReducer.isCancelOrReplaceDone,
})

const mapDispatchToProps = {
    cancelProgramOrReplaceRacket : (data) => cancelProgramOrReplaceRacket(data),
    getRacketOrderDetail: (id) => getRacketOrderDetail(id),
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReplaceRacket));
