

const initialState = {
    address: {},
    addressActivitySignUp: {}
};


export default function googleMapReducer(state = initialState, { type, payload }) {
    // console.log(type); 會print 出 每個 action, cool
    switch (type) {
        case 'SET_MAP_API_DATA':
            let addressArray = payload.address_components.reverse()
            // console.log(addressArray);
            return { ...state, address: {addressArray: addressArray, formatted_address: payload.formatted_address} };
        case 'SET_MAP_API_DATA_FROM_ACTIVITY_SIGN_UP':
            let addressArrayFromActivitySignUp = payload.address_components.reverse()
            return { ...state, addressActivitySignUp: {addressArray: addressArrayFromActivitySignUp} }
        default:
            return state;
    }
}


