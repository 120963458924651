import styled from 'styled-components';

export const Wrapper = styled.div `
    display: flex;
    align-items: center; 
    justify-content: center;
    align-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #f2f2f2;

    @media screen and (max-width: 480px) {
        height: 90%;
        padding-top: 5%;
    }

    .BonnyLive_Logo {
        width: 100%;
        display: flex;
        align-items: center; 
        justify-content: center;
        margin-top: 5%;

        .logoImage {
            width: 120px;
            height: 120px;
            display: flex;
            margin: auto 5px;
            align-items: center; 
            justify-content: center;
            border-radius: 5px;
            background-color: #c30d22;
            border: 2px solid rgba(255,255,255,0.5);

            img {
                width: 100%;
            }
        }
    }

    .text {
        width: 100%;
        text-align: center;

        h3 {
            font-size: 1.2rem;
            b {
                font-weight: 500;
            }

            @media screen and (max-width: 480px) {
                font-size: 1rem;
            }
        }   
    }

    .button {
        width: 100%;
        display: flex;
        align-items: center; 
        justify-content: center;
        flex-wrap: wrap;

        p {
            width: 100%;
            text-align: center;
            font-size: 1rem;
            line-height: 2;
        }

        button {
            display: block;
            border: none;
            border-radius: 5px;
            padding: 1rem 2rem;
            margin: 0;
            text-decoration: none;
            background-color: #2f82bf;
            color: #fff;
            font-size: 1rem;
            line-height: 1;
            cursor: pointer;
            text-align: center;
            transition: background 250ms ease-in-out, transform 150ms ease;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        button:hover {
            background-color: #205882;
        }

        button:focus {
            color: #ddd;
            background-color: #133650;
        }

        button:active {
            transform: scale(0.99);
        }
    }

    .cancel {
        width: 100%;
        display: flex;
        align-items: center; 
        justify-content: center;
    }

`
