import styled from 'styled-components';

export const Wrapper = styled.div `

background-color: rgb(172, 137, 103);

@media (max-width: 480px) {
    padding-bottom: 120px;
}

.nav_D{
	position: absolute;
	opacity: 0;
}
.nav_D.show{
	opacity: 1;
}

.contract {
    width:100%;
    max-width:440px;
    font-family:"微軟正黑體", Arial, Helvetica, sans-serif;
    margin-left:auto;
    margin-right:auto;
    position:relative;
    display: none;
}

`
