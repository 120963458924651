
const initialState = {
    notice: [],
    noticeContent: [],
    status: {},
    notificationUnreadCount: 0,
    shouldLoadingOpen: true,
 };

export default function reducer(state = initialState, {type, payload}) {
    switch ( type ) {
        case 'FETCH_NOTIFICATION_DATA':
            return { ...state, notice: payload };

        case 'FETCH_NOTIFICATION_ABOUT_RENT':
            return { ...state, noticeContent: payload };

        case 'UPDATE_NOTIFICATION_STATUS':
            return { ...state, status: payload };
        
        case 'GET_NOTIFICATION_UNREAD_COUNT':
            return { 
                ...state, 
                notificationUnreadCount: payload.unReadCount 
            };

        case 'SHOULD_LOADING_OPEN': 
            return { ...state, shouldLoadingOpen: payload };

        default:
            return state;
    }
}
