import React, { Component } from "react";
import { Wrapper } from "./styled";
import { GaSetting } from "../../Common";

class SubmitButton extends Component {
    render() {
        const { onClick } = this.props;
        return (
            <Wrapper onClick={onClick}>
                <GaSetting 
                    data = {{
                        category: 'MyBonny', 
                        action: 'MyBonny-member-login', 
                        label: '', 
                        tag: 'div',
                    }}
                >
                    <p>登入</p>
                    <i className="material-icons">
                        arrow_right
                    </i>
                </GaSetting>
            </Wrapper>
      );
    }
}

SubmitButton.defaultProps = {
  onClick: null
};

export default SubmitButton;
