
const initialState = {
	occupation: [],
	hobby: [],
	shouldLoadingOpen: false,
	emailVerifyMessage: '',
	shouldMobilelVerifyTrue: false,
	setMobileVerifyMessage: '',
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_OCCUPATIONS': {
			return { ...state, occupation: action.payload }
		}
		case 'GET_HOBBY': {
			return { ...state, hobby: action.payload }
		}
        case 'SHOULD_LOADING_OPEN':
			return { ...state, shouldLoadingOpen: action.payload, };
		case 'SET_EMAIL_VERIFY_MESSAGE':
			return { ...state, emailVerifyMessage: action.payload, };
		case 'SHOULD_MOBILE_VERIFY_TRUE':
			return { ...state, shouldMobilelVerifyTrue: action.payload };
		case 'SET_MOBILE_VERIFY_MESSAGE':
			return { ...state, setMobileVerifyMessage: action.payload };
		default:
			return state
	}
}
