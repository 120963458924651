import React, { Component } from "react";
import { Wrapper } from "./styled";

class JoinedIcon extends Component {
  render() {
    return (
      <Wrapper className={this.props.className} onClick={this.props.onClick}>
        <i className="material-icons">
          group_add
        </i>
        <span>{this.props.counts}人</span>
      </Wrapper>
    )
  }
}

export default JoinedIcon;
