export const shareToLine = (shareContent, url) => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        if (navigator.userAgent.indexOf("WebView") > -1) {
        // alert('這不是 browsers')
            window.open(`https://line.me/R/msg/text/?${shareContent}%0D%0A${url}`);
        } else {
        // alert('這是 browsers')
            if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
                window.open(`https://line.me/R/msg/text/?${shareContent}%0D%0A${url}`);
            } else {
                window.open(`line://msg/text/?${shareContent}%0D%0A${url}`);
            }
        }
    } else {
        window.open(`https://social-plugins.line.me/lineit/share?text=${shareContent}&url=${url}`);
    }
};