import { createAction } from 'redux-actions';
import { fetchObject } from "./fetchModule";
import { updateNotifActivity } from './activityActions';
import { fetchActivityInvite } from './myInfoActions';
import { history } from '../index';
import { localStorageUtility } from '../utilities';

export const getLevelsData = createAction("GET_LEVELS");
export const getPreferencesData = createAction("GET_PREFERENCES");
export const createGroupData = createAction("CREATE_GROUP");
export const editGroupData = createAction("EDIT_GROUP_DATA");
export const createFeatureData = createAction("CREATE_FEATURE");
export const getJoinedListData = createAction("GET_JOINED_LIST");
export const getActivityData = createAction("GET_ACTIVITY");
export const addFeatureData = createAction("ADD_FEATURE");

export const createGroup = (data) => {
    //debugger
    return (dispatch) => {
        fetchObject(`activity/`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                dispatch(createGroupData(json.data));
                //debugger

                if (!data.previous) {
                    return;
                }

                return dispatch(getActivity(json.id))
                .then(json => {
                    const { partners } = json;
                    if (partners && partners.length) {
                        return Promise.all(partners.map(p => dispatch(fetchActivityInvite({
                            rejoin: true,
                            activity: json.id,
                            user: p.id,
                        }))));
                    }
                });
            })
            .then(() => {
                window.alert('開團成功');
                history.push('/my_info/host_activities');
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const editGroup = (data) => {
    return (dispatch) => {
        dispatch(editGroupData(data));
    }
}

export const updateGroup = (data, skip) => {
    return (dispatch) => {
        delete data.user
        fetchObject(`activity/${data.id}`, "", {
            method: "PUT",
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                if (skip) { return }
                dispatch(createGroupData(json.data));
                history.replace(`/my_info/host_activity_detail/${data.id}`);
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const duplicateGroup = (oldData, time) => dispatch => {
    const data = { ...time };
    data.address = oldData.address;
    data.age_min = oldData.age_min;
    data.age_max = oldData.age_max;
    data.area = oldData.area;
    data.badminton_level = oldData.badminton_level;
    data.ball_brand = oldData.ball_brand;
    data.city = oldData.city;
    data.description = oldData.description;
    data.frequency = oldData.frequency;
    data.group_name = oldData.group_name;
    data.images = oldData.images;
    data.latitude = oldData.latitude;
    data.longitude = oldData.longitude;
    data.people = oldData.people;
    data.place_count = oldData.place_count;
    data.place_name = oldData.place_name;
    data.plan = oldData.plan;
    data.previous = oldData.id;
    data.refundPeriod = oldData.refundPeriod;
    data.tag = oldData.tag.map(tag => tag.id);

    dispatch(createGroup(data));
};

export const createFeature = (data) => {
    return (dispatch) => {
        fetchObject(`tag`, '', {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                var payload = { name: data.name, id: json.id };
                dispatch(createFeatureData(payload));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const addFeature = (payload) => {
    return (dispatch) => {
        dispatch(addFeatureData(payload));
    }
}

export const getLevels = () => {
    return (dispatch) => {
        fetchObject(`level/list`, '', {
            method: 'GET',
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(getLevelsData(json.data));
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const getPreferences = () => {
    return (dispatch) => {
        fetchObject(`preference/list`, '', {
            method: 'GET',
        })
            .then(function (response) {
                return response.json();
            })
        .then(function (json) {
                dispatch(getPreferencesData(json.data));
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const getJoinedList = () => {
    return (dispatch) => {
        fetchObject(`join/list`, '', {
            method: 'GET',
        })
            .then(function (response) {
                if (response.ok){
                    return response.json();
                }else{
                    response.json().then(function(err) {
                        if(err.code === 'TOKEN_INVALID'){
                            localStorageUtility('remove', 'myData');
                            // window.location.href = "/Login";
                            history.replace('/login');
                        }
                    });
                }
            })
            .then(function (json) {
                dispatch(getJoinedListData(json.data));
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const postpone = id => (dispatch, getState) => {
    const activity = getState().activity.notifiedActivity;
    if (activity && activity.id === id) {
        // cleanup current notification
        dispatch(updateNotifActivity());
    }

    return fetchObject(`activity/${id}/postpone`, '', {
        method: 'post',
    })
    .then(response => {
        if (!response.ok) {
            //throw new Error("Bad response from server");
        }
        history.replace(`/my_info/host_activity_detail/${id}`);
    })
    .catch(function (ex) {
        console.log("parsing failed", ex);
        alert(ex);
    });
}

export const getActivity = (id) => {
    return (dispatch) => {
        return fetchObject(`activity/${id}`, '', {
            method: 'GET',
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(getActivityData(json));
                return json;
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
}
