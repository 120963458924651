import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Wrapper } from './styled';
import Config from "../config";
// import Main from './MainMascot';
// import Detail from './DetailMascot';

let Main
let Detail

const isBonnyStar = Config.isBonnyStar
export default class extends Component {

	componentDidMount() {
		this.setImportComponent()
	}

	setImportComponent = () => {

		if (isBonnyStar) {
			import('./MainBonnyStar')
				.then(module => {
					Main = module.default;
				})
				.then(() => {
					this.setState({
						isMainSetAlready: true
					})
				})
			import('./DetailBonnyStar')
				.then(module => {
					Detail = module.default;
				})
				.then(() => {
					this.setState({
						isDetailSetAlready: true
					})
				})
		} else {
			import('./Main')
				.then(module => {
					Main = module.default;
				})
				.then(() => {
					this.setState({
						isMainSetAlready: true
					})
				})
			import('./Detail')
				.then(module => {
					Detail = module.default;
				})
				.then(() => {
					this.setState({
						isDetailSetAlready: true
					})
				})
		}
	}


	render() {
		const { match } = this.props;
		if (!Main || !Detail) return null

		return (
			<Wrapper>
				{/* <Route exact path={match.url} component={Main} /> */}
				<Route exact path={match.url} render={(props) => (
					<Main
						{...props}
						isBonnyStar={isBonnyStar}
					/>
				)} />
				<Route exact path={`/media/${Config.mascotDemoUrl}`} render={(props) => (
					<Main
						{...props}
						isBonnyStar={isBonnyStar}
					/>
				)} />
				<Route path='/media/detail/:id' render={(props) => (
					<Detail
						{...props}
						isBonnyStar={isBonnyStar}
					/>
				)} />
				<Route path={`/media/${Config.mascotDemoUrl}/detail/:id`} render={(props) => (
					<Detail
						{...props}
						isBonnyStar={isBonnyStar}
					/>
				)} />
			</Wrapper>
		);
	}
}
