import React, { Component } from "react";
import { 
    Modal,
    MessagesIcon, 
    JoinedIcon, 
} from "../../../component/Common/";
import { Wrapper, BannerArea, UserArea, Icons, } from './styled';
import { Link } from 'react-router-dom';
import { getSharedLink, copyLink, shareToLine, shareToFacebook, localStorageUtility } from '../../../utilities';

const getCommentsCount = (comments) => {
    //debugger
    if(comments && comments.reply)
        return comments.reply.length
    else
        return 0
}

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
        };
    }

    handleShareToLine = (title, urlParam) => {
        const url = getSharedLink(urlParam, true, window.location.search);

        let text = `這裡有個很棒的羽球團『${title}』，你也快一起來參加吧！` 
        shareToLine(text, url)
    }

    handleShareToFacebook = (title, urlParam) => {
        const url = getSharedLink(urlParam, true, window.location.search);
        const pictureUrl = this.props.activityInformation.images.url.length > 0 ? this.props.activityInformation.images.url[0] : 'https://d2vsnoltqccfhr.cloudfront.net/39fd771f-d2dc-45e0-88d4-7ab4a1e14d09.jpg';
        let description = '&description=我發現了一個羽球人的好物！！這裡有很棒的羽球團，我報名了，希望你也快一起來參加！'
        let picture = `&picture=${pictureUrl}`
        let titleText = `&title=${title}`
        shareToFacebook(titleText, url, description, picture)
    }

    isFollowed = () => {
        var followList = this.props.followList
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === this.props.activityInformation.user.id)
                    return true
            }
        }
        return false
    }

    renderFollowBtn = () => {
        if (this.props.isBelongToMe === true) {
            return <span className="icon favorite"></span>
        }
        return this.isFollowed() ?
            <span className="icon favorite" onClick={this.props.follow}>
                <i className="material-icons">favorite</i>
                <small>追蹤</small>
            </span> 
            :
            <span className="icon favorite_border" onClick={this.props.follow}>
                <i className="material-icons">favorite_border</i>
                <small>追蹤</small>
            </span>

    }
    getBg = () => {
        const bg = this.props.activityInformation.images ? this.props.activityInformation.images.url[0] : null;
        let result
        if (bg) {
            result = {
                backgroundImage: `url("${bg}"`,
            };
        } else {
            result = {
                backgroundImage: `url(/assets/images/DefaultBgimg.jpg)`,
            };
        }
        return result;
    }

    render() {
        const userInfo = localStorageUtility('getAndParse', 'myData');
        var { activityInformation , comments } = this.props;
        if (!activityInformation || !activityInformation.user)
            return ( null )
        else
            return (
                <Wrapper>
                    <BannerArea className="banner" style={this.getBg()}>
                        <div className="userNameWrapper">                        
                            <div className="header-buttons">
                                { this.renderFollowBtn() }
                                <Modal
                                    title="分享到"
                                    onClick={(e) => {
                                        this.handleSubmit(e);
                                    }}
                                    modalIsOpen={this.state.modalIsOpen}
                                    noButton={true}
                                    btnTheme="one"
                                    notButtonStyle={{ width: "3rem", marginLeft: "5px" }}
                                    modalContent={
                                        <div style={{ textAlign: 'center',display: `flex`, }}>
                                        {/* Line */}
                                            <div onClick={()=>{ this.handleShareToLine(activityInformation.group_name, '/quick_sign_up_activity' ) }} style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/lineShareicon.png" alt=""/>
                                                <br />
                                                <div>Line</div>
                                            </div>
                                        {/* Facebook */}
                                            <div onClick={()=>{ this.handleShareToFacebook(activityInformation.group_name, '/quick_sign_up_activity' ) }} style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/fbShareicon.png" alt=""/>
                                                <br />
                                                <div>Facebook</div>
                                            </div>
                                        {/* CopyLink */}
                                            <div onClick={ ()=>{ copyLink('/quick_sign_up_activity', true, window.location.search) } } style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/linkShareicon.png" alt=""/>
                                                <br />
                                                <div>複製連結</div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <span className="icon share" onClick={this.openModal}>
                                        <i className="material-icons">share</i>
                                        <small>分享</small>
                                    </span>
                                </Modal>
                            </div>
                        </div>
                    </BannerArea>
                    { 
                            this.props.isBelongToMe 
                        ? 
                            null 
                        :
                            <UserArea>
                                <div className="avatarWrapper">
                                    <div className="pic">
                                    {
                                        activityInformation.user.avatar !== "" ?
                                            <div className='banner-avatar' style={{backgroundImage: `url('${activityInformation.user.avatar}')`}}></div>
                                            :
                                            <div className='banner-avatar' style={{backgroundImage: 'url(/assets/images/DefaultUserImg.png)'}}></div>
                                    }
                                    </div>
                                    <div className="userName">
                                        {activityInformation.user.name}
                                    </div>
                                    {
                                        userInfo && userInfo.id && userInfo.token ?
                                        <Link className="talk" to={`/my_info/chatroom/user_id=${this.props.activityInformation.user.id}`}>聊聊</Link>
                                        :
                                        <div className="talk" onClick={ ()=>{ alert('請先登入')}}>聊聊</div>
                                    }
                                </div>
                            </UserArea>
                    }
                    <Icons>
                        <div className="social-item">
                            <i className="material-icons">
                                favorite
                            </i>
                            <span>{activityInformation.user.followeeCount}人</span>
                        </div>
                        <MessagesIcon counts={getCommentsCount(comments)} />
                        <JoinedIcon counts={activityInformation.people - activityInformation.remain} />
                        <div className="social-item">
                            <i className="material-icons">
                                visibility
                            </i>
                            <span>{activityInformation.view}人</span>
                        </div>
                    </Icons>
                </Wrapper>
            );
    }
}

export default Banner
