import { fetchObject } from './fetchModule';

export const fetchPostPlayerId = (userAgent) => {
    return dispatch => {

        fetchObject(`userAgent/`, "", {
            method: "POST",
            body: JSON.stringify(userAgent)
        })
            .then(function (res) {
                if(res === true){
                    console.log("send userAgent success");
                }
            })
            .catch(err => {
                console.log('err', err);
            });
    }
}
