import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 0px;
  
  .comment-header-wrapper {
    background-color: ${props => props.isBonnyStar && "#D7D7D7"};
    padding: 10px 20px 10px 20px;

    .comment-header {
      display: block;
      .comment-count{
        ${props => props.isBonnyStar && "font-weight: 700;" }
      }
    }

    .input-button{
      display: flex;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      margin: auto;
      input[type="text"]{
        // display: flex;
        // display: -webkit-box;
        // display: -webkit-flex;
        // display: -ms-flexbox;
        flex: 8;
        -webkit-flex: 8;
        -ms-flex: 8;
        width: calc( 80% - 1em - 2px);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @media (max-width: 375px){
         flex: 7;
         -webkit-flex: 7;
         -ms-flex: 7;
         width: calc( 70% - 1em - 2px);
        }
      }
      button{
        // display: flex;
        // display: -webkit-box;
        // display: -webkit-flex;
        // display: -ms-flexbox;
        flex: 2;
        -webkit-flex: 2;
        -ms-flex: 2;
        width: 20%;
        justify-content: center
        text-align: center;
        @media (max-width: 375px){
         flex: 3;
         -webkit-flex: 3;
         -ms-flex: 3;
         width: 30%;
        }
      }
    }
  }
  
  

  .comments {
    padding: 10px;
    padding-bottom: 150px;
    background: #F1F1F1;
  }
`;
