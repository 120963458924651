import React, { Component } from "react";
import { connect } from 'react-redux';
import { 
    fetchRegister, 
    getlevellist, 
    getoccupationlist, 
    gethobby, 
    sendUserInfo, 
    fetchLoginFB,
    sendVerifyMobile,
    verifyMobile,
    shouldLoadingOpen,
    shouldMobilelVerifyTrue,
    setMobileVerifyMessage
} from '../../actions';
import { 
    SkillLevelSelect, 
    LoadingIndicator, 
    GaSetting,
    Select,
    Modal
} from "../../component/Common";
import OccupationSelect from './OccupationSelect';
import Hobby from './HobbyButton';
import Checkbox from './Checkbox';
import { Wrapper } from './styled';
import { getCookie, validateEmail, localStorageUtility } from '../../utilities';
import moment from 'moment-timezone';
import { CityData } from "../../component/SelectLocation/tw-city";
import store from '../../store';

let valueArr = [];
const makeCityOption =() =>{
    const counties = [
        {
            value: "居住縣市",
            disabled: false
        }
    ]
    CityData.counties.map(value => counties.push({ value: value }));
    return counties;
}
class Register extends Component {

    constructor(props) {
        super(props);
        this.fbData = localStorageUtility('getAndParse', 'fbData');

        if (this.fbData) {
            localStorageUtility('set', 'myData', JSON.stringify(this.fbData.bonny));
        }
        localStorageUtility('remove', 'fbData');
        store.dispatch(shouldLoadingOpen(false));
        store.dispatch(shouldMobilelVerifyTrue(false));
        this.fbData && (this.avatar = `https://graph.facebook.com/${this.fbData.fb.id}/picture?type=large`);
        const landingPageCode = getCookie('LandingPage');
        let affiliateData = localStorageUtility('getAndParse', 'affiliate');
        this.state = {
            name: this.fbData ? this.fbData.fb.name : '',
            email: this.fbData ? this.fbData.bonny.email : '',
            phone: '',
            prevPhone: '',
            badminton_level: 1,
            occupation: '1',
            hobby: [],
            password: '',
            authPassword: '',
            gender: 0,
            year: 0,
            month: 0,
            day: 0,
            city: '居住縣市',
            // home: '',
            code: '',
            mobileInputIsDisable: 0,
            remindTime: 0,
            message: '',
            prevMessage: '',
            modalIsOpen: false,

            affiliateCode: affiliateData ? affiliateData.code : '',
            landingPageCode: landingPageCode ? landingPageCode : 'Origin',
            cityOptions: makeCityOption(),
        };
    }

    componentDidMount() {
        this.props.getlevellist();
        this.props.getoccupationlist();
        this.props.gethobby();
        localStorageUtility('remove', 'signup');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.message !== "" && this.props.message !== prevProps.message) {
            this.setState({
                prevMessage: this.props.message,
            })
            this.onModalSwitch(true, this.messageContent(this.props.message));
        }
    }

    messageContent(message) {
        switch (message) {
            case 'SEND_SUCCESS':
                this.setState({
                    mobileInputIsDisable: 1,
                })
                return '已發送驗證碼至您的手機號碼：' + this.state.phone;
            case 'NO_CODE':
                return '未輸入驗證碼';
            case 'NO_PHONE_NUMBER':
                return '手機號碼未輸入';
            case 'INCORRECT_CODE':
                return '驗證碼不正確，請重新輸入';
            case 'CODE_EXPIRED':
                return '驗證碼過期，請重新驗證';
            case 'PHONE_USED':
                this.handleMobileVerifyCancel();
                return '此手機號碼已被註冊，請登入或重新輸入';
            case 'SEND_FAIL':
                return '簡訊發送失敗，請重新操作';
            case 'VERIFY_SUCCESS':
                this.countDown(2);
                return '恭喜，已成功驗證您的手機！';
            default:
                return '未知錯誤，請重新操作或聯絡客服';
        }
    }

    onModalClose() {
        this.onModalSwitch(false);
    }
    
    onModalSwitch(setting, message) {
        this.setState({
            modalIsOpen: setting,
            message: message
        }, ()=>{
            // 開關 modal
            this.refs.modal && this.refs.modal[this.state.modalIsOpen ? 'openModal' : 'closeModal']();
        })
        store.dispatch(setMobileVerifyMessage(''));
    }

    countDown = (second) => {
        this.setState({
            remindTime: second,
        });
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            let remindTime = this.state.remindTime;
            const setRemindTime = remindTime -= 1;
            this.setState({
                remindTime: setRemindTime,
            });
            if (this.state.remindTime <= 0) {
                clearInterval(this.interval);
            }
        }, 1000);
    }

    showTime = () => {
        const timer = moment()
            .startOf('day')
            .add(this.state.remindTime, 'second');
        return timer.format('ss');
    }

    mobileNumberVerification = (mobile) => {
        const mobileRule = /^[09]{2}[0-9]{8}$/;
        if (mobile !== '' && String(mobile).replace(/\s+/g, "").length !== 0 && mobile.search(mobileRule) !== -1){
            return true;
        } else {
            return false;
        }
    }

    handleMobileVerifySend = () => {
        const { phone, prevPhone, mobileInputIsDisable, prevMessage } = this.state;
        let message;
        if (this.props.verify === false && this.showTime() === '00' ){
            if (this.mobileNumberVerification(phone)) {
                if (phone === prevPhone && mobileInputIsDisable !== 1) {
                    if (prevMessage !== 'SEND_SUCCESS' && prevMessage !== '') {
                        this.handleMobileVerifyCancel();
                        this.onModalSwitch(true, this.state.message);
                    } else {
                        message = '兩次手機號碼輸入相同，請輸入驗證碼或變更手機號碼';
                        // this.handleMobileVerifyCancel();
                        this.countDown(15);
                        this.onModalSwitch(true, message);
                        this.setState({
                            prevPhone: '',
                            mobileInputIsDisable: 1,
                        });
                    }
                } else {
                    this.props.sendVerifyMobile({phone});
                    this.countDown(30);
                    this.setState({
                        prevPhone: phone,
                    });
                }
            } else {
                message = '手機號碼有誤，請重新輸入';
                this.onModalSwitch(true, message);
            }
        } else if(this.props.verify){
            // 驗證完成，無動作
            message = '此手機號碼驗證已完成，請繼續完成註冊流程';
            this.onModalSwitch(true, message);
        } else {
            message = '操作過快，請等候' + this.showTime() + '秒';
            this.onModalSwitch(true, message);
        }
    }

    handleMobileVerifySubmit = () => {
        const { phone, code } = this.state;
        let message;
        if (this.mobileNumberVerification(phone)) {
            this.props.verifyMobile({phone, code});
        } else {
            message = '手機號碼有誤，請重新輸入';
            this.onModalSwitch(true, message);
        }
    }

    handleMobileVerifyCancel = () => {
        if (this.state.phone) {
            this.countDown(3);
            this.setState({
                phone: '',
                code: '',
                mobileInputIsDisable: 0,
            });
        }
        store.dispatch(shouldMobilelVerifyTrue(false));
    }

    handleOnChange = (element, e) => {
        this.setState({ [element]: e.target.value })
    }

    handleInputChange = (event) => {
        // 從 event object 拿到 target
        const target = event.target;
        // 從 target.type 可以知道欄位的 type
        // 或從 target.value 取出輸入的欄位值
        const value = target.value;
        // 從 target.name 得到該欄位設定的 name
        const name = target.name;

        //興趣清單
        if (name === "hobby") {
            valueArr.push(value);
            this.setState({
                [name]: valueArr
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let registercheck = 1;
        let message;

        if (this.fbData) {
            this.handleFBSubmit();
            return;
        }
        const {
            name,
            email,
            phone,
            badminton_level,
            occupation,
            hobby,
            affiliateCode,
            password,
            authPassword,
            city,
            // home,
            gender,
            year,
            month,
            day,
            code
        } = this.state;
        const mobileRule = /^[09]{2}[0-9]{8}$/;
        const passwordRule = /^(?=.*[a-zA-Z]).{6,15}$/;

        if (name === '') {
            message = '・姓名不得為空';
            registercheck = 0;
        }
        if (email === '' || validateEmail(email) === false ) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・E-MAIL不得為空或格式錯誤';
            } else {
                message = '・E-MAIL不得為空或格式錯誤';
            }
            registercheck = 0;
        }
        if (phone === '' || String(phone).replace(/\s+/g, "").length === 0 || phone.search(mobileRule) === -1) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・電話不得為空或格式錯誤';
            } else {
                message = '・電話不得為空或格式錯誤';
            }
            registercheck = 0;
        }
        if (code === '' || this.props.verify === false) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・手機號碼尚未認證';
            } else {
                message = '・手機號碼尚未認證';
            }
            registercheck = 0;
        }
        if (password === '' || password.search(passwordRule) === -1) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・密碼不得為空或格式錯誤';
            } else {
                message = '・密碼不得為空或格式錯誤';
            }
            registercheck = 0;
        }
        if (authPassword === '') {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・密碼驗證不得為空';
            } else {
                message = '・密碼驗證不得為空';
            }
            registercheck = 0;
        }
        if (authPassword !== '' && authPassword !== password) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・密碼與驗證密碼不相符';
            } else {
                message = '・密碼與驗證密碼不相符';
            }
            registercheck = 0;
        }
        if (city === '居住縣市') {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請選擇居住縣市';
            } else {
                message = '・請選擇居住縣市';
            }
            registercheck = 0;
        }
        if (gender === 0) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請選擇性別';
            } else {
                message = '・請選擇性別';
            }
            registercheck = 0;
        }
        if (year === 0 || month === 0 || day === 0) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請選擇你的出生年月日';
            } else {
                message = '・請選擇你的出生年月日';
            }
            registercheck = 0;
        }
        if ( localStorageUtility('getAndParse', 'check') !== true ) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請閱讀並接受個資保護聲明';
            } else {
                message = '・請閱讀並接受個資保護聲明';
            }
            registercheck = 0;
        }

        if (registercheck === 1) {
            const body = { 
                name, 
                email, 
                password, 
                phone, 
                badminton_level, 
                occupation, 
                hobby, 
                affiliateCode, 
                city, 
                // home, 
                year, 
                month, 
                day, 
                gender, 
                code 
            };
            this.props.onFetchRegister(body);
        } else {
            this.onModalSwitch(true, message);
        }
    }

    handleFBSubmit() {
        let registercheck = 1;
        let message;

        const { 
            name, 
            email, 
            phone, 
            badminton_level, 
            occupation, 
            hobby, 
            affiliateCode, 
            city, 
            // home, 
            year, 
            month, 
            day, 
            gender 
        } = this.state;
        const mobileRule = /^[09]{2}[0-9]{8}$/;

        if (name === '') {
            message = '・姓名不得為空';
            registercheck = 0;
        }
        if (phone === '' || String(phone).replace(/\s+/g, "").length === 0 || phone.search(mobileRule) === -1) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・電話不得為空或格式錯誤';
            } else {
                message = '・電話不得為空或格式錯誤';
            }
            registercheck = 0;
        }
        if (city === '居住縣市') {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請選擇居住縣市';
            } else {
                message = '・請選擇居住縣市';
            }
            registercheck = 0;
        }
        if (gender === 0) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請選擇性別';
            } else {
                message = '・請選擇性別';
            }
            registercheck = 0;
        }
        if (year === 0 || month === 0 || day === 0) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請選擇你的出生年月日';
            } else {
                message = '・請選擇你的出生年月日';
            }
            registercheck = 0;
        }
        if ( localStorageUtility('getAndParse', 'check') !== true ) {
            if (message !== undefined) { 
                message += '<br>' 
                message += '・請閱讀並接受個資保護聲明';
            } else {
                message = '・請閱讀並接受個資保護聲明';
            }
            registercheck = 0;
        }

        if (registercheck === 1) {
            const body = { 
                name, 
                email, 
                phone, 
                badminton_level, 
                occupation, 
                hobby, 
                affiliateCode, 
                city, 
                // home, 
                year, 
                month, 
                day, 
                gender 
            };
            body.avatar = this.avatar;
            this.props.onFBRegister(body, this.fbData);
        } else {
            this.onModalSwitch(true, message);
        }
    }

    // 年
    listYears() {
        var years = [];
        const now = moment().format('YYYY');
        const short = now - 10;
        const long = now - 87;
        for (let i = short; i >=long ; i--) {
            years.push({
                val: `${i}`,
                text: `西元 ${i}年`
            });
        }
        return years;
    }

    // 月
    listMonths() {
        var months = [];
        for (let i = 1; i <= 12; i++) {
            months.push({
                val: `${i}`,
                text: `${i}月`
            });
        }
        return months;
    }

    // 日
    listDays() {
        var days = [];
        const getYearMonth = this.state.year + '-' + this.state.month;
        const day = moment(getYearMonth, "YYYY-MM").daysInMonth();
        for (let i = 1; i <= day; i++) {
            days.push({
                val: `${i}`,
                text: `${i}日`
            });
        }
        return days;
    }

    render() {
        const {
            email,
            name,
            phone,
            badminton_level,
            password,
            authPassword,
            occupation,
            landingPageCode,
            // gender,
            year,
            month,
            day,
            city,
            cityOptions,
            // home,
            mobileInputIsDisable,
            message
        } = this.state;

        const getYears = this.listYears();
        const yearsList = getYears.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const getMonths = this.listMonths();
        const monthsList = getMonths.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const getDays = this.listDays();
        const daysList = getDays.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));
        return (
            <Wrapper key={1}>
                {
                    landingPageCode &&
                        <GaSetting
                            data = {{
                                name: 'componentDidMount', 
                                category: 'Member',
                                action: 'Register_PV',
                                label: `isType${landingPageCode}`,
                            }}
                        />
                }
                <h1
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 會員註冊
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <Modal
                    ref='modal'
                    title='提示通知'
                    onClick={() => this.onModalClose()}
                    noButton={true}
                    btnTheme="one"
                    text2="關閉"
                    modalContent={
                        <div dangerouslySetInnerHTML={{__html: message}} />
                    }
                />
                <h2 className="title">會員註冊</h2>
                <p style={{color: '#DF1C2C', fontSize: '0.8em'}}>* 為必填欄位</p>
                <form className="main-form">
                    <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>姓名：</label>
                        <input name='name' type="text" value={name} onChange={this.handleInputChange} placeholder="請輸入您的姓名" />
                    </div>
                    <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>Email(登入帳號)：</label>
                        <input name='email' type="text" value={email} onChange={this.handleInputChange} placeholder="請輸入您的E-MAIL(此為日後登入帳號)" disabled={!!this.fbData} />
                    </div>
                    {!this.fbData ?
                        <div className="form-control">
                            <label><span style={{color: '#DF1C2C'}}>*</span>行動電話(需驗證)：</label>
                            <div className="flex alignCenter">
                                <input 
                                    name='phone' 
                                    type="text" 
                                    value={phone} 
                                    onChange={this.handleInputChange} 
                                    disabled={mobileInputIsDisable === 1 ? "disabled" : ""}
                                    placeholder="請輸入您的行動電話" 
                                    style={{ width:'calc(95% - (14px * 8))' ,borderRadius: `5px 0 0 5px` ,borderRight: `none`}}
                                />
                                <div 
                                    className="mobileVerifyButton" 
                                    onClick={this.handleMobileVerifySend}
                                >
                                    {this.showTime() === '00' && mobileInputIsDisable !== 1 ? 
                                        "發送驗證碼" 
                                        :
                                        this.showTime() !== '00' && mobileInputIsDisable !== 1 ? 
                                            "發送驗證碼(" + this.showTime() + ")" 
                                            :
                                                this.showTime() !== '00' && mobileInputIsDisable === 1 ? 
                                                    "請稍候 " + this.showTime() + "秒"
                                                    :
                                                    this.props.verify ?
                                                        "驗證完成"
                                                        : 
                                                        "重新發送"
                                    }
                                </div>
                            </div>
                            {this.props.verify === false ?
                                <div className="flex alignCenter" style={{marginTop: `10px`, width: `95%`}}>
                                    <input 
                                        name='code' 
                                        type="text" 
                                        value={this.state.code} 
                                        onChange={this.handleInputChange} 
                                        disabled={this.props.verify ? "disabled" : ""}
                                        placeholder="請輸入驗證碼" 
                                        style={{ flex: `1`}}
                                    />
                                    <div 
                                        className="mobileVerifySubmit" 
                                        onClick={this.handleMobileVerifySubmit}
                                    >
                                        送出
                                    </div>
                                    <div className="mobileVerifyCancel" onClick={this.handleMobileVerifyCancel}>變更手機號碼</div>
                                </div>
                                :
                                <div className="mobileVerifyCancel" onClick={this.handleMobileVerifyCancel} style={{width: `95%`, textAlign: `right`}}>變更手機號碼</div>
                            }
                        </div>
                        :
                        <div className="form-control">
                            <label>行動電話：</label>
                            <input name='phone' type="text" value={phone} onChange={this.handleInputChange} placeholder="請輸入您的行動電話" />
                        </div>
                    }
                    {!this.fbData && <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>密碼：</label>
                        <input name='password' type="password" value={password} onChange={this.handleInputChange} placeholder="請輸入您的密碼(6~15位英文數字混合)" />
                    </div>}
                    {!this.fbData && <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>密碼確認：</label>
                        <input name='authPassword' type="password" value={authPassword} onChange={this.handleInputChange} placeholder="請再次輸入您的密碼" />
                    </div>}
                    <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>居住縣市：</label>
                        <div className="cityAddress">
                            <Select
                                name='city'
                                value={city}
                                options={cityOptions}
                                onChange={this.handleInputChange}
                            />
                            {/* <input name='home' type="text" value={home} onChange={this.handleInputChange} placeholder="請輸入您的常用住址" /> */}
                        </div>
                    </div>
                    <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>性別：</label>
                        <div style={{display: `flex`, alignItems: `center`}} onChange={this.handleInputChange}>
                            <input type="radio" name="gender" value="1" style={{width: `auto`}}/><span style={{marginRight: `1em`,marginLeft: `5px`}}>男</span>
                            <input type="radio" name="gender" value="2" style={{width: `auto`}}/><span style={{marginRight: `1em`,marginLeft: `5px`}}>女</span>
                        </div>
                    </div>
                    <div className="form-control">
                        <label><span style={{color: '#DF1C2C'}}>*</span>出生年月日：</label>
                        <div style={{display: `flex`}}>
                            <select name="year" onChange={this.handleInputChange} value={year} style={{paddingLeft:`1em` , marginRight:`5px`}}>
                                <option key='0' value='0'>
                                    請選擇西元年
                                </option>
                                {yearsList}
                            </select>
                            <select name="month" onChange={this.handleInputChange} value={month} style={{paddingLeft:`1em` , margin:`0 5px`}}>
                                <option key='0' value='0'>
                                    請選擇月份
                                </option>
                                {monthsList}
                            </select>
                            <select name="day" onChange={this.handleInputChange} value={day} style={{paddingLeft:`1em` , marginLeft:`5px`}}>
                                <option key='0' value='0'>
                                    請選擇日期
                                </option>
                                {daysList}
                            </select>
                        </div>
                    </div>
                    <OccupationSelect occupation={this.props.occupation} value={occupation} onChange={(e) => this.handleOnChange("occupation", e)} />
                    <SkillLevelSelect levels={this.props.levels} value={badminton_level} onChange={(e) => this.handleOnChange("badminton_level", e)} />
                </form>

                <Hobby label="興趣/目的: (可複選)" name="hobby" hobby={this.props.hobby} placeholder="興趣/目的: (可複選)" onChange={this.handleInputChange} />

                <Checkbox check={ localStorageUtility('getAndParse', 'check') } />

                <form className="main-form form" >
                    <div className='SubmitButton' onClick={this.handleSubmit}>
                        <GaSetting 
                            data = {{
                                className: 'Member', 
                                category: 'Member', 
                                action: 'Register_isSubmit', 
                                label: `isType${landingPageCode}`, 
                            }}
                        >
                            確認註冊
                            <i className="material-icons" style={{color: `#fff`, fontSize: `2rem`, verticalAlign: `middle`,}}>
                              arrow_right
                            </i>
                        </GaSetting>   
                    </div>
                    <span className="text" style={{fontSize: `16px`,}}>已擁有Bonny雲羽集帳號?</span>
                    <GaSetting
                        data = {{
                            className: 'link', 
                            category: 'Member', 
                            action: 'Register_isBackToLogin', 
                            label: `isType${landingPageCode}`,   
                            tag: 'link',
                            path: '/login',
                        }} 
                    >
                        我要登入
                    </GaSetting>
                </form>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    levels: state.newGroupReducer.levels,
    occupation: state.registerReducer.occupation,
    hobby: state.registerReducer.hobby,
    loading: state.registerReducer.shouldLoadingOpen,
    verify: state.registerReducer.shouldMobilelVerifyTrue,
    message: state.registerReducer.setMobileVerifyMessage,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchRegister: (Body) => {
        dispatch(fetchRegister(Body));
    },
    onFBRegister: (body, fbData) => {
        dispatch(sendUserInfo(body, { token: fbData.bonny.token }))
            .then(() => {
                dispatch(fetchLoginFB(fbData.fb, {}, 
                    { 
                        agent: localStorageUtility('getAndParse', 'agent'),
                        playerId: localStorageUtility('get', 'playerId'),
                    } 
                ));
            });
    },
    getlevellist: () => {
        dispatch(getlevellist())
    },
    getoccupationlist: () => {
        dispatch(getoccupationlist())
    },
    gethobby: () => {
        dispatch(gethobby())
    },
    sendVerifyMobile: (mobile) => {
        dispatch(sendVerifyMobile(mobile));
    },
    verifyMobile: (code) => {
        dispatch(verifyMobile(code));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);