import React, { Component } from "react";
import { GaSetting } from "../../../../Common";
import LandingPageLayout from '../LandingPageLayout';
import { Wrapper } from './styled';

class LandingPageA extends Component {

    render() {
        return (
            <Wrapper key="1">
                <div>
                    <GaSetting
                        data = {{
                            name: 'componentDidMount', 
                            category: '4D', 
                            action: '4D_PV_isTypeA', 
                            label: '',
                        }}
                    />
                    <LandingPageLayout />
                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/img_1.jpg" alt="羽你蓮心" style={{width: `100%`,}}/>  
                    <GaSetting key="0"
                        data = {{
                            className: 'text', 
                            category: '4D', 
                            action: '4D_Contract_Click', 
                            label: 'isTypeA',
                            tag: 'link',
                            path: '/classic/contract',
                        }}
                    >
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/shoppingcart.jpg" alt="羽你蓮心" style={{width: `100%`,}}/>
                    </GaSetting>
                </div>
            </Wrapper>
        );
    }
}

export default LandingPageA;

