import styled from 'styled-components';
export const Wrapper = styled.div`
 width: 100%;
 height: auto;  
`;
export const BannerArea = styled.div`
    background-color: rgba(255,255,255,.2);
    // background-image: url(/assets/images/DefaultBgimg.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    overflow: hidden;
    display: flex;
    position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
        }

    .avatarWrapper{
        // width: 100px;
        height: 200px;
        flex: 1;
        display: flex;
        flex-direction:column;
        padding-top: 20px;
        margin-left: 20px;
        z-index: 2;

        @media (max-width: 480px) {
            height: 160px;
            padding-top: 10px;
            margin-left: 10px;
        }

        .pic{
            width: 100px;
            height: 100px;
            border-radius: 999rem;
            overflow: hidden;
            position: relative;
            background-color: #fff;
            border: 2px solid #ccc;

            @media (max-width: 480px) {
                width: 75px;
                height: 75px;
            }

            .banner-avatar{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                background-position: center center;
                background-size: cover;
            }

            img{
                height: calc( 100% - 5px);
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
        }
        .userName{
            margin-top: 5px;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: rgba(0, 0, 0, 1);
            text-shadow: 0 5px 5px rgba(0, 0, 0, .1), 0 -5px 20px rgba(255, 255, 255, .1);
            // text-shadow: -1px -1px 0 rgba(255, 255, 255, 1), 1px -1px 0 rgba(255, 255, 255, 1), -1px 1px 0 rgba(255, 255, 255, 1), 1px 1px 0 rgba(255, 255, 255, 1);
            // text-shadow: 1px 1px 0 #fff, 0px 1px 0 #fff, -1px -1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff;

            @media (max-width: 480px) {
                max-width: 75px;
                margin-top: 0;
            }
        }
        .talk{
            width:100px;
            margin-top: 10px;
            background-color: rgb(223, 28, 44);
            display: block;
            color: #fff;
            text-align: center;
            float: none;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            padding: 10px 0;
            box-shadow: inset 0 -0.125rem rgba(0, 0, 0, 0.2);
            text-shadow: 0px -2px rgb(223, 28, 44);
            border-radius: 5em;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            @media (max-width: 480px) {
                width: 75px;
                margin-top: 5px;
                padding: 8px 0;
            }
        }
    }
    .userNameWrapper{
        // width: calc( 100% - 120px);
        flex: 1;
        padding-top: 20px;
        margin-right: 20px;
        position: relative;
        z-index: 2;

        @media (max-width: 480px){
            padding-top: 10px;
            margin-right: 10px;  
        }

        .header-buttons {
            // margin-right: 20px;
            // margin-top: 25px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            // position: absloute;
            // right: 0;

            span.icon {
                color: #fff;
                text-align: center;
                position: inherit;
                // float: right;
                width: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 5px 0;
                // background-color: rgba(255,255,255,.2);
                border-radius: 5px;


                i {
                    color: #da263c;
                    font-size: 30px;
                    flex: 0 0 100%;
                    // text-shadow: -1px -1px 0 rgba(0,0,0,.3),1px -1px 0 rgba(0,0,0,.3),-1px 1px 0 rgba(0,0,0,.3),1px 1px 0 rgba(0,0,0,.3);
                }
                small {
                    display: block;
                    font-size: 12px;
                    // color: #000;
                    // color: transparent;
                    font-weight: 500;
                    flex: 0 0 100%;
                    // width: 3rem;
                    // text-shadow: -1px -1px 0 #FFF,1px -1px 0 #FFF,-1px 1px 0 #FFF,1px 1px 0 #FFF;
                    position: relative;
                    text-shadow: #111 0 0 1px, rgba(255,255,255,0.1) 0 1px 3px;
                    
                    &::after {
                        content: '';
                        color: #fff;
                        position: absolute;
                        background-color: rgba(223, 28, 44, 0.75);
                        border-radius: 999rem;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }

            span.icon.favorite_border,span.icon.favorite {
                small{
                    &::after {
                        content: '追蹤';
                    }
                }
            }
            span.icon.share {
                small{
                    &::after {
                        content: '分享';
                    }
                }
            }

        }
    }
`;
export const UserArea = styled.div`
    width: 100%;
    height: 40px;
    margin-top: 10px;
    position: relative;

    .avatarWrapper {
        width: calc( 100% - 40px );
        margin: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        .pic {
            width: 35px;
            height: 35px;
            border-radius: 999rem;
            overflow: hidden;
            position: relative;
            background-color: #fff;
            border: 2px solid #ccc; 

            .banner-avatar{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                background-position: center center;
                background-size: cover;
            }   

            img{
                height: calc( 100% - 5px);
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
        }
        .userName{
            flex: 1;
            margin-left: 5px;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
            // max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: rgba(0, 0, 0, 1);
            // text-shadow: 0 5px 5px rgba(0, 0, 0, .1), 0 -5px 20px rgba(255, 255, 255, .1);
            // text-shadow: -1px -1px 0 rgba(255, 255, 255, 1), 1px -1px 0 rgba(255, 255, 255, 1), -1px 1px 0 rgba(255, 255, 255, 1), 1px 1px 0 rgba(255, 255, 255, 1);
            // text-shadow: 1px 1px 0 #fff, 0px 1px 0 #fff, -1px -1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff;

        }
        .talk{
            width:4rem;
            // margin-top: 10px;
            background-color: rgb(223, 28, 44);
            display: block;
            color: #fff;
            text-align: center;
            float: none;
            text-decoration: none;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.2rem;
            padding: 5px 0;
            box-shadow: inset 0 -0.125rem rgba(0, 0, 0, 0.2);
            text-shadow: 0px -2px rgb(223, 28, 44);
            border-radius: 5em;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
        }
    }
`;
export const Icons = styled.div`
    width: calc( 100% - 40px );
    margin: 10px auto 0 auto;
    text-align:center;
    display: flex;
    align-items:center; 
    justify-content:center;

    div {
        padding: 0;

        display: flex;
        flex: 1;
        align-items:center; 
        justify-content:center;

        i {
          color: #da263c;
          margin-right: 2px;

          @media (max-width: 480px) {
            font-size: 18px;
          }
        }
        span {
          padding-left:5px;  
          color: #666;
          position: relative;

          @media (max-width: 480px) {
            font-size: 14px;
          }
        }

        &:hover {
            span::before{
                content:'';
                color: #000;
                font-size: 10px;
                background-color: #fffcec;
                width: 5rem;
                height: 1.4rem;
                line-height: 1.4rem;
                border: 1px solid #ccc;
                border-radius: 5px;
                position: absolute;
                bottom: -1.4rem;
                left: -0.4rem;
                opacity: 0.8;
            }
        }
    }

    div:nth-child(1) {
        &:hover {
            span::before{
                content:'追蹤數/人';
            }
        }
    }
    div:nth-child(2) {
        &:hover {
            span::before{
                content:'留言數/則';
            }
        }
    }
    div:nth-child(3) {
        &:hover {
            span::before{
                content:'報名數/人';
            }
        }
    }
    div:nth-child(4) {
        &:hover {
            span::before{
                content:'瀏覽數/人';
            }
        }
    }
`;