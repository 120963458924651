import React, { Component } from "react";
import { Wrapper } from './styled';
import Slider from 'react-slick';
import { GaSetting } from "../../../Common";

class LandingPageB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            willShowDetailA: false,
            willShowDetailB: false,
            willShowDetailC: false,
            willShowDetailD: false,
        }
    }

    toggleShowDetail = (item, willShow)=>{
        this.setState({
            [item]: willShow
        })
    }
    
    render(){
        const settings = {
            autoplay: true,
            dots: true,
            infinite: true,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dotsClass: 'dotPosition',
        };

        return(
            <Wrapper key="1">
                <GaSetting
                    data = {{
                        name: 'componentDidMount', 
                        category: '2D', 
                        action: '2D_PV', 
                        label: '',
                    }}
                />
                <div id="WRAPPER">

                    <div id="HEADER">
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/key.jpg" width="100%" alt=""/>
                        <GaSetting 
                            data = {{ 
                                className: "HEADER-buy",
                                category: '2D', 
                                action: '2D_ShoppingCart_Click_HeaderBuy', 
                                label: '',
                                tag: 'link',
                                path: { 
                                    pathname: "/rent",
                                    state: {
                                        "level": 'low',
                                    }
                                },
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-3.png" alt="" width="100%"/>
                        </GaSetting>
                    </div>

                    {
                        this.state.willShowDetailA ?
                        <div>
                            <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailA', false)}}>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: 'Click', 
                                        label: '2D_Ares671_Hide_TopButton', 
                                        title: '2D_Ares671_Hide_TopButton',
                                        style: {cursor: 'pointer'}
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_A_close.jpg" width="100%" alt="2D_Ares671_Hide_TopButton"/>
                                </GaSetting>
                            </div>
                            <div id="CONTENT-A">
                                <Slider  {...settings} 
                                    customPaging = {i => (<div className="PAGE-A">  <p>  <span style={{cursor: 'pointer'}}>{i + 1}</span> </p> </div> )}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_A_1.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_A_2.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_A_3.jpg" width="100%" alt=""/>
                                </Slider>
                                {/* <div className="PAGE-A">
                                    <p>
                                        <span style={{cursor: 'pointer'}}>1</a>
                                        <span style={{cursor: 'pointer'}}>2</a>
                                        <span style={{cursor: 'pointer'}}>3</a>
                                    </p>
                                </div> */}
                                <div className="CONTENT-AREA">
                                    <p>拍框材質：高剛性碳纖維+1.5K Woven<br/>
                                        中管軟硬度：M(適中)<br/>
                                        球拍長度：675±1mm<br/>
                                        平衡：290±5mm（未穿線）<br/>
                                        柄號：2#/G4<br/>
                                        最高磅數：28LBS<br/>
                                        重量：3U（未穿線）<br/>
                                        球拍屬性：攻擊拍<br/>
                                    </p>
                                    <GaSetting
                                        data = {{ 
                                            className: "CONTENT-AREA-BUY",
                                            category: '2D', 
                                            action: '2D_ShoppingCart_Click_atAres671', 
                                            label: '',
                                            tag: 'link',
                                            path: { 
                                                pathname: "/rent",
                                                state: {
                                                    "level": 'low',
                                                }
                                            },
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-2.png" alt=""/>
                                    </GaSetting>
                                </div>
                                <span style={{cursor: 'pointer'}} onClick={ ()=>{this.toggleShowDetail('willShowDetailA', false)}}>
                                    <GaSetting 
                                        data = {{ 
                                            category: '2D', 
                                            action: 'Click', 
                                            label: '2D_Ares671_Hide_BottomButton', 
                                            title: '2D_Ares671_Hide_BottomButton',
                                            style: {cursor: 'pointer'}
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn_close.png" width="100%" alt="2D_Ares671_Hide_BottomButton" />
                                    </GaSetting>
                                </span>
                            </div>
                        </div>
                        :
                        <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailA', true)}}>
                            <GaSetting 
                                data = {{ 
                                    category: '2D', 
                                    action: 'Click', 
                                    label: '2D_Ares671_Show', 
                                    title: '2D_Ares671_Show',
                                    style: {cursor: 'pointer'}
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_A.jpg" width="100%" alt="2D_Ares671_Show" />
                            </GaSetting>
                        </div>
                    }

                    {
                        this.state.willShowDetailB ?
                        <div>
                            <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailB', false)}}>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: 'Click', 
                                        label: '2D_Ares672_Hide_TopButton', 
                                        title: '2D_Ares672_Hide_TopButton',
                                        style: {cursor: 'pointer'}
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_B_close.jpg" width="100%" alt="2D_Ares672_Hide_TopButton" />
                                </GaSetting>
                            </div>
                            <div id="CONTENT-B">
                                <Slider  {...settings} 
                                    customPaging = {i => (<div className="PAGE-B">  <p>  <span style={{cursor: 'pointer'}}>{i + 1}</span> </p> </div> )}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_B_1.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_B_2.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_B_3.jpg" width="100%" alt=""/>
                                </Slider>
                                {/* <div className="PAGE-B">
                                    <p>
                                        <span style={{cursor: 'pointer'}}>1</a>
                                        <span style={{cursor: 'pointer'}}>2</a>
                                        <span style={{cursor: 'pointer'}}>3</a>
                                    </p>
                                </div> */}
                                <div className="CONTENT-AREA">
                                    <p>拍框材質：高剛性碳纖維+烏缺工藝<br/>
                                        中管軟硬度：M（適中）<br/>
                                        球拍長度：675±1mm<br/>
                                        平衡：295±5mm（未穿線）<br/>
                                        柄號：2#/G4<br/>
                                        最高磅數：28LBS<br/>
                                        重量：3U（未穿線）<br/>
                                        球拍屬性：攻防拍<br/>
                                    </p>
                                    <GaSetting
                                        data = {{ 
                                            className: "CONTENT-AREA-BUY",
                                            category: '2D', 
                                            action: '2D_ShoppingCart_Click_atAres672', 
                                            label: '',
                                            tag: 'link',
                                            path: { 
                                                pathname: "/rent",
                                                state: {
                                                    "level": 'low',
                                                }
                                            },
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-2.png" alt=""/>
                                    </GaSetting>
                                </div>
                                <span style={{cursor: 'pointer'}} onClick={ ()=>{this.toggleShowDetail('willShowDetailB', false)}}>
                                    <GaSetting 
                                        data = {{ 
                                            category: '2D', 
                                            action: 'Click', 
                                            label: '2D_Ares672_Hide_BottomButton', 
                                            title: '2D_Ares672_Hide_BottomButton',
                                            style: {cursor: 'pointer'}
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn_close.png" width="100%" alt="2D_Ares672_Hide_BottomButton" />
                                    </GaSetting>
                                </span>
                            </div>
                        </div>
                        :
                        <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailB', true)}}>
                            <GaSetting 
                                data = {{ 
                                    category: '2D', 
                                    action: 'Click', 
                                    label: '2D_Ares672_Show', 
                                    title: '2D_Ares672_Show',
                                    style: {cursor: 'pointer'}
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_B.jpg" width="100%" alt="2D_Ares672_Show" />
                            </GaSetting>
                        </div>
                    }

                    {
                        this.state.willShowDetailC ?
                        <div>
                            <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailC', false)}}>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: 'Click', 
                                        label: '2D_Arrow780_Hide_TopButton', 
                                        title: '2D_Arrow780_Hide_TopButton',
                                        style: {cursor: 'pointer'}
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_C_close.jpg" width="100%" alt="2D_Arrow780_Hide_TopButton" />
                                </GaSetting>
                            </div>
                            <div id="CONTENT-C">
                                <Slider  {...settings} 
                                    customPaging = {i => (<div className="PAGE-C">  <p>  <span style={{cursor: 'pointer'}}>{i + 1}</span> </p> </div> )}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_C_1.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_C_2.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_C_3.jpg" width="100%" alt=""/>
                                </Slider>
                                {/* <div className="PAGE-C">
                                    <p>
                                        <span style={{cursor: 'pointer'}}>1</a>
                                        <span style={{cursor: 'pointer'}}>2</a>
                                        <span style={{cursor: 'pointer'}}>3</a>
                                    </p>
                                </div> */}
                                <div className="CONTENT-AREA">
                                    <p>拍框材質：M30碳布+12點、6點1.5K Woven設計<br/>
                                        中管軟硬度：S<br/>
                                        球拍長度：675±1mm<br/>
                                        平衡：290±5mm（未穿線）<br/>
                                        柄號：2#/G4<br/>
                                        最高磅數：30LBS<br/>
                                        重量：3U（未穿線）<br/>
                                        球拍屬性：攻防拍<br/>
                                    </p>
                                    <GaSetting
                                        data = {{ 
                                            className:"CONTENT-AREA-BUY",
                                            category: '2D', 
                                            action: '2D_ShoppingCart_Click_atArrow780', 
                                            label: '',
                                            tag: 'link',
                                            path: { 
                                                pathname: "/rent",
                                                state: {
                                                    "level": 'low',
                                                }
                                            },
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-2.png" alt=""/>
                                    </GaSetting>
                                </div>
                                <span style={{cursor: 'pointer'}} onClick={ ()=>{this.toggleShowDetail('willShowDetailC', false)}}>
                                    <GaSetting 
                                        data = {{ 
                                            category: '2D', 
                                            action: 'Click', 
                                            label: '2D_Arrow780_Hide_BottomButton', 
                                            title: '2D_Arrow780_Hide_BottomButton',
                                            style: {cursor: 'pointer'}
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn_close.png" width="100%" alt="2D_Arrow780_Hide_BottomButton" />
                                    </GaSetting>
                                </span>
                            </div>
                        </div>
                        :
                        <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailC', true)}}>
                            <GaSetting 
                                data = {{ 
                                    category: '2D', 
                                    action: 'Click', 
                                    label: '2D_Arrow780_Show', 
                                    title: '2D_Arrow780_Show',
                                    style: {cursor: 'pointer'}
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_C.jpg" width="100%" alt="2D_Arrow780_Show" />
                            </GaSetting>
                        </div>
                    }

                    {
                        this.state.willShowDetailD ?
                        <div>
                            <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailD', false)}}>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: 'Click', 
                                        label: '2D_Arrow790_Hide_TopButton', 
                                        title: '2D_Arrow790_Hide_TopButton',
                                        style: {cursor: 'pointer'}
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_D_close.jpg" width="100%" alt="2D_Arrow790_Hide_TopButton" />
                                </GaSetting>
                            </div>
                            <div id="CONTENT-D">
                                <Slider  {...settings} 
                                    customPaging = {i => (<div className="PAGE-D">  <p>  <span style={{cursor: 'pointer'}}>{i + 1}</span> </p> </div> )}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_D_1.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_D_2.jpg" width="100%" alt=""/>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_D_3.jpg" width="100%" alt=""/>
                                </Slider>
                                {/* <div className="PAGE-D">
                                    <p>
                                        <span style={{cursor: 'pointer'}}>1</a>
                                        <span style={{cursor: 'pointer'}}>2</a>
                                        <span style={{cursor: 'pointer'}}>3</a>
                                    </p>
                                </div> */}
                                <div className="CONTENT-AREA">
                                    <p>拍框材質：M30碳布+纖維薄膜+12點、6點1.5K Woven設計<br/>
                                        中管軟硬度：S<br/>
                                        球拍長度：675±1mm<br/>
                                        平衡：295±5mm（未穿線）<br/>
                                        柄號：2#/G4<br/>
                                        最高磅數：30LBS<br/>
                                        重量：3U（未穿線）<br/>
                                        球拍屬性：攻擊拍<br/>
                                    </p>
                                    <GaSetting
                                        data = {{ 
                                            className:"CONTENT-AREA-BUY",
                                            category: '2D', 
                                            action: '2D_ShoppingCart_Click_atArrow790', 
                                            label: '',
                                            tag: 'link',
                                            path: { 
                                                pathname: "/rent",
                                                state: {
                                                    "level": 'low',
                                                }
                                            },
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-2.png" alt=""/>
                                    </GaSetting>
                                </div>
                                <span style={{cursor: 'pointer'}} onClick={ ()=>{this.toggleShowDetail('willShowDetailD', false)}}>
                                    <GaSetting 
                                        data = {{ 
                                            category: '2D', 
                                            action: 'Click', 
                                            label: '2D_Arrow790_Hide_BottomButton', 
                                            title: '2D_Arrow790_Hide_BottomButton',
                                            style: {cursor: 'pointer'},
                                        }}
                                    >
                                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn_close.png" width="100%" alt="2D_Arrow790_Hide_BottomButton" />
                                    </GaSetting>
                                </span>
                            </div>
                        </div>
                        :
                        <div id="NAV" onClick={ ()=>{this.toggleShowDetail('willShowDetailD', true)}}>
                            <GaSetting 
                                data = {{ 
                                    category: '2D', 
                                    action: 'Click', 
                                    label: '2D_Arrow790_Show', 
                                    title: '2D_Arrow790_Show',
                                    style: {cursor: 'pointer'}
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/nav_D.jpg" width="100%" alt="2D_Arrow790_Show" />
                            </GaSetting>
                        </div>
                    }

                    <span><img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/img_1.jpg" width="100%" alt=""/></span>
                    <div className="PROGRAM">
                        <div>
                            <div>
                                月繳60元 / 年繳498元 <br/>
                                一次付清 1982 元
                            </div>
                            <div>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: '2D_ShoppingCart_Click', 
                                        label: '', 
                                        tag: 'link',
                                        title: '2D_ShoppingCart',
                                        path: {
                                            pathname: '/rent',
                                            state: {
                                                "level": 'low',
                                            }
                                        },
                                        style: {cursor: 'pointer'}, 
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-4.png" width="100%" alt="2D_ShoppingCart" />
                                </GaSetting>
                            </div>
                        </div>
                    </div>
                    <div className="SERVICE">
                        <div>
                            <div>
                                貼心解說及訂購協助 <br/> 02-23564250分機377或688
                            </div>
                            <div>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: '2D_Service_Click', 
                                        label: '', 
                                        title: '2D_ServiceClick',
                                        path: 'https://docs.google.com/forms/d/1WJbHTntqkafxmoz3C3oykA1JD-TTRN4LH0CGqqUhpkI/edit',
                                        target: '_bank', 
                                        style: {cursor: 'pointer'},
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-go-2.png" alt="貼心解說及訂購協助"/>
                                </GaSetting>
                            </div>
                        </div>
                    </div>
                    <div className="DPURCHASE">
                        <div>
                            <div>
                                官網直購1882元 <br/> 一年保固
                            </div>
                            <div>
                                <GaSetting 
                                    data = {{ 
                                        category: '2D', 
                                        action: '2D_toEC_Click', 
                                        label: '', 
                                        title: '2D_toECShopClick',
                                        path: 'http://www.bonny-live.com/Product/List?tag=%E8%B6%85%E5%80%BC%E9%99%90%E9%87%8F%E6%8B%8D',
                                        target: '_bank',
                                        style: {cursor: 'pointer'},
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-buy-5.png" alt="官網直購1882元"/>
                                </GaSetting>
                            </div>
                        </div>
                    </div>
                    <div className="CASE">
                        <GaSetting 
                            data = {{ 
                                category: '2D', 
                                action: '2D_to3D_Click', 
                                label: '', 
                                tag: 'link',
                                title: '2D_to3DClick',
                                path: '/feather',
                                style: {cursor: 'pointer'}, 
                            }}
                        >
                            日付3元方案
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-add.png" alt=""/>
                        </GaSetting>
                        <GaSetting 
                            data = {{ 
                                category: '2D', 
                                action: '2D_to4D_Click', 
                                label: '', 
                                tag: 'link',
                                title: '2D_to4DClick',
                                path: '/classic',
                                style: {cursor: 'pointer'}, 
                            }}
                        >
                            日付4元方案
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/lowLevel/landingPageB/btn-add.png" alt=""/>
                        </GaSetting>
                    </div>
                </div>
            </Wrapper>
        )
    }

}
export default LandingPageB;
