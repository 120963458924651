import styled from 'styled-components';

export const Wrapper = styled.div `
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;
    margin: 10vh auto;

    .loadingIO {
        width: 50%;
        display: flex;
        align-items: center; 
        justify-content: center;

        @keyframes lds-eclipse {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            50% {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @-webkit-keyframes lds-eclipse {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            50% {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        .lds-eclipse {
            position: relative;
        }

        .lds-eclipse div {
            position: absolute;
            -webkit-animation: lds-eclipse 1.2s linear infinite;
            animation: lds-eclipse 1.2s linear infinite;
            width: 160px;
            height: 160px;
            top: 20px;
            left: 20px;
            border-radius: 50%;
            box-shadow: 0 4px 0 0 #e50914;
            -webkit-transform-origin: 80px 82px;
            transform-origin: 80px 82px;
        }

        .lds-eclipse {
            width: 200px;
            height: 200px;
            -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
            transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        }
    }

    .text {
        width: 100%;
        display: flex;
        align-items: center; 
        justify-content: center;
        text-align: center;

        h3 {
            font-size: 1.2rem;
            font-weight: 500;
            color: #fff;

            @media screen and (max-width: 480px) {
                font-size: 1rem;
            }
        }
    }

`
