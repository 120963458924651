import React, { Component } from "react";
import { Wrapper } from "./styled";

class SubmitButton extends Component {
    render() {
        const { text, onClick, imgType, style, noImg  } = this.props;
        return (
            <Wrapper style={style} onClick={onClick} disabled={this.props.disable}>
                {text}
                {imgType === "search" ? 
                    <img src="../../../assets/icons/search.png" alt={text} />
                : 
                    noImg ?
                    ''
                    :
                    <img src="../../../assets/icons/ArrowW.png" alt={text} />
                }
            </Wrapper>
        );
    }
}

SubmitButton.defaultProps = {
    label: "",
    text: "確認報名",
    error: null,
    onClick: null
};

export default SubmitButton;
