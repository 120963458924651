import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 10px 0;

    input,
    button {
      display: flex;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
    }

    input[type="text"]{
      font-size: 1rem;
      @media screen and (max-width: 480px){
        font-size: .8rem;
      }
    }

    button.inviteButton{
      width: auto;
      height: 35px;
      margin: auto;
      flex: none;
      -webkit-flex: none;
      -ms-flex: none;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      align-items:center; 
      justify-content:center;
      text-align: center;

      @media screen and (max-width: 480px){
        font-size: .8rem;
      }
    }
    
    > div {
			display: flex;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      div {
        width: 100%;
        input {
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
    
    button {
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        color: #fff;
        background: #2f82bf;
        margin-right: 5px;
        display: flex;
        align-items:center; 
        justify-content:center;
        text-align: center;
    }

    .suggestion-wrapper {
      display: flex;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      align-items:center; 
      justify-content:center;
      text-align: center;
      > img.suggestion-image {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
      > span {
        padding-left: 10px;
      }
      background-size: 38px 38px;
      background-repeat: no-repeat;
    }
`;
