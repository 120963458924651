import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    text-align: center;
    font-size: 30px;
  }

  .msg {
    margin: 30px auto;
  }
`
