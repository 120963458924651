import { createAction } from 'redux-actions';
import { fetchObject } from "./fetchModule";
import { history } from '../index';
// import { getJoinedList } from './newGroupActions';
import { localStorageUtility } from '../utilities';

export const getGroupDetailData = createAction("GET_GROUP_DETAIL");
export const getfollowerData = createAction("GET_FOLLOWER");
// export const signUpGroupData = createAction("SIGNUP_GROUP");
export const followUserData = createAction("FOLLOW_USER");
export const unFollowUserData = createAction("UNFOLLOW_USER");
export const followListData = createAction("FOLLOW_LIST");
export const paymentResult = createAction("GET_PAYMENT_RESULT_DATA");
export const shouldLoadingOpen = createAction("SHOULD_LOADING_OPEN");
export const setIsGetPaymentRes = createAction("SET_IS_GET_PAYMENT_RES"); // Application Success

export const getGroupDetail = (id) => {
    return (dispatch) => {
        dispatch(shouldLoadingOpen(true));
        fetchObject(`activity/${id}`, "", {
            method: "GET",
        })
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                dispatch(getGroupDetailData(json));
                dispatch(shouldLoadingOpen(false));
                dispatch(setIsGetPaymentRes(false));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
};

export const getFollower = (data) => {
    return (dispatch) => {
        fetchObject(`user/${data}`, "", {
            method: "GET",
        })
            .then(function (response) {
                if (response.ok) return response.json();
                else
                    response.json().then(function(err) {
                        if(err.code === 'TOKEN_INVALID'){
                            localStorageUtility('remove', 'myData');
                            history.replace('/login');
                        }
                    });
            })
            .then(function (json) {
                dispatch(getfollowerData(json));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
};

export const unFollowUser = (data) => {
    return (dispatch, getState) => {
        fetchObject(`follow/${data.id}`, "", {
            method: "DELETE",
        })
            .then(function (response) {
                dispatch(unFollowUserData());
                var data = { type: "follower" }
                followList(data)(dispatch)
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const followList = (data) => {
    return (dispatch, getState) => {
        fetchObject(`follow/list?type=${data.type}&search=${data.search || ''}`, "", {
            method: "GET",
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(followListData(json.data));
                //console.log(json);
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const followUser = (data) => {
    return (dispatch, getState) => {
        fetchObject(`follow`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                dispatch(followUserData(json.data));
                var data = { type: "follower" }
                followList(data)(dispatch)
            })
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
}; 


export const signUpGroup = (Body) => {
    //debugger
    return (dispatch) => {
        fetchObject(`join`, "", {
            method: "POST",
            body: JSON.stringify(Body)
        })
            .then((response) => {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                // console.log(json)
                if (json.code) {
                    throw new Error(json.code);
                } else {
                    return json;
                }
            })
            .then((data) => {
                // console.log(data);
                // dispatch(signUpGroupData(data));
                alert("報名成功！")
                if (data.redirectUrl) {
                    data.redirectUrl.slice(0, 4) === 'http' ?
                        window.location.href = data.redirectUrl
                        :
                        history.push(data.redirectUrl);
                } else {
                    // window.location.reload();
                    // dispatch(getGroupDetail(data.activity));
                    // dispatch(getJoinedList());
                    history.push('/sign_up_activity?' + data.activity);
                }
            }, (error) => {
                // 錯誤狀態判斷 顯示
                // console.log(error);
                switch(error.message) {
                    case "TOKEN_INVALID":
                        window.alert('登入過期，請重新登入');
                        localStorageUtility('remove', 'myData');
                        history.replace('/login');
                        break;
                    case "ACTIVITY_IS_FULL":
                        window.alert('此活動已滿團！');
                        if (error.redirectUrl) {
                            history.push(error.redirectUrl);
                        }
                        break;
                    case "JOIN_EXIST":
                        window.alert('您已對此活動報名');
                        if (error.redirectUrl) {
                            history.push('/my_info/participated_activities');
                        }
                        break;
                    case "ACTIVITY_NOT_EXIST":
                        window.alert('活動發生異常，請重新確認');
                        if (error.redirectUrl) {
                            history.push('/activity_list');
                        }
                        break;
                    default:
                        window.alert('非預期錯誤，請重新整理網頁或是聯繫客服');
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

function createForm(path, jsonForm, method = "post") {
    const form = document.createElement("form");
    form.id = "purchase_form";
    form.method = method;
    form.action = path;
    for (const key in jsonForm) {
        if (jsonForm.hasOwnProperty(key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.id = key;
            hiddenField.value = jsonForm[key];
            form.appendChild(hiddenField);
        }
    }
    
    document.body.appendChild(form);
}

export const createActivityPaymentOrder = (data) => {
    //debugger
    return (dispatch) => {
        fetchObject(`payment`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response) => {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                        dispatch(shouldLoadingOpen(true));
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                } 
            })
            .then((json) => {
                if (json.form) {
                    // 線上付費
                    // 建立 Form
                    createForm(json.url, json.form);
                    return json.form;
                } else if (json === 'OK') {
                    // 現場付費
                    window.alert('活動當天不要忘記付款囉～');
                } else {
                    throw new Error(json.code);
                }
            })
                    
            .then((data) => {
                dispatch(setIsGetPaymentRes(true));
            }, (error) => {
                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    case "TOKEN_INVALID":
                        window.alert('登入逾時，請重新登入！');
                        localStorageUtility('remove', 'myData')
                        window.location.href = "/login";
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                }
            })
            .catch((ex) => {
                console.log('parsing failed', ex)
            })
    }
}


export const getPaymentResult = (data) => {
     //debugger
    return (dispatch) => {
        fetchObject(`payment/${data}`, '', {
            method: 'GET',
        })  
            .then(function (response) { 
                dispatch(shouldLoadingOpen(true));
                return response.json();
            })
            .then(function (json) {
                if (json) {
                    return json;
                } else {
                    switch(json.code) {
                        case "TOKEN_INVALID":
                            window.alert('登入逾時，請重新登入！');
                            localStorageUtility('remove', 'myData')
                            window.location.href = "/login";
                            break;
                        default:
                            window.alert('請聯繫客服協助處理！');
                    }
                } 
            })
            .then((data) => { 
                dispatch(paymentResult(data));
                dispatch(shouldLoadingOpen(false));
            })
            .catch(function (ex) {
                window.location.href = "/ErrorPage";
                console.log('parsing failed', ex)
            })
    };
};