import React, { Component } from "react";
import moment from 'moment'
import { Wrapper } from './styled';
import { Features, } from "../../Common";
import Config from '../../config';
const style = {
    marginTop: "10px"
};

class Information extends Component {

    render() {
        const { activityInformation, } = this.props;
        const { plan, } = activityInformation;
        if ( activityInformation && activityInformation.tag ) {
            return (
                <Wrapper>  
                    <p>開團：{activityInformation.group_name}</p>
                    <p>已報名/徵求人數：{activityInformation.people - activityInformation.remain} <span>/</span> {activityInformation.people}</p>
                    <p>開始時間：{moment(activityInformation.start).format("YYYY年MM月DD日 LT")}</p>
                    <p>結束時間：{moment(activityInformation.end).format("YYYY年MM月DD日 LT")}</p>
                    <p>場地名稱：{activityInformation.place_name}</p>
                    <p>球場地址：{activityInformation.city}{activityInformation.area}-{activityInformation.address}</p>
                    <p>打球程度：{level(activityInformation.badminton_level)}</p>
                    {  
                        (plan && ( plan.Cash && (plan.Cash !== 0) ))
                        ?
                            <p>現場付費費用：NT$ {plan.Cash}元</p>
                        :
                        plan && plan.Cash === 0
                        ?
                            <p>現場付費費用： <span style={{color: 'red',}}>免費參加</span></p>
                        :
                            null
                    }
                    {
                        (plan && ( plan.CreditEcpay && (plan.CreditEcpay !== 0) ))
                        ?
                            <p>線上付費費用：NT$ {plan.CreditEcpay}元</p>
                        :
                            null
                    }
                    <p>場地數量：{activityInformation.place_count}</p>
                    <p>特色與備註：</p>
                    <Features features={activityInformation.tag.map(x => x.name)} />  
                    <iframe
                        title="GoogleMap"
                        width="100%"
                        height="250"
                        frameBorder="0"
                        style={style}
                        src={`https://www.google.com/maps/embed/v1/place?key=${Config.googleMapKey}&q=${activityInformation.city} ${activityInformation.area} ${activityInformation.address}`} 
                    /> 
                </Wrapper>
            )
        } else {
            return null
        }
    }
};

const level = level => {
    switch (level) {
        case 2:
            return "新手";
        case 3:
            return "中手";
        case 4:
            return "高手";
        case 5:
            return "無限制";
        default:
            return "入門";
    }
};

export default Information;
