import React, { Component } from 'react';

import { Wrapper } from './styled';

class SubmitButton extends Component {
  render() {
    const { onClick } = this.props;

    return (
      <Wrapper onClick={onClick}>
        <div>
        <span>送出</span>
        <img src='./assets/icons/ArrowW.png' alt='' />
        </div>
      </Wrapper>
    );
  }
}

export default SubmitButton;
