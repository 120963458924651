import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items:center; 
    // justify-content:center;

    .social-item {
      display: flex;
      align-items:center; 
      justify-content:center;
      text-align: center;
      flex: 1;
      margin: 0px;
      padding: 0px;
      font-size: 1rem;
      // opacity: .8;

      &:hover {
        span::before{
            content:'';
            color: #000;
            font-size: 10px;
            background-color: #fffcec;
            width: 5rem;
            height: 1.4rem;
            line-height: 1.4rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            position: absolute;
            bottom: -1.4rem;
            left: -0.4rem;
            opacity: 0.8;
        }
      }
      
      i {
        color: #da263c;
        margin-right: 2px;
      }
      span {
        color: #666;
        position: relative;
      }

    };


    .social-item:nth-child(1) {
      &:hover {
          span::before{
              content:'追蹤數/人';
          }
      }
    }
    .social-item:nth-child(2) {
        &:hover {
            span::before{
                content:'留言數/則';
            }
        }
    }
    .social-item:nth-child(3) {
        &:hover {
            span::before{
                content:'報名數/人';
            }
        }
    }
    .social-item:nth-child(4) {
        &:hover {
            span::before{
                content:'瀏覽數/人';
            }
        }
    }

    img {
      width: 24px;
      height: 24px;
    };
`
