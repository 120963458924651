import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SliderWrapper } from './styled.js';
import ReactGA from 'react-ga';
import Config from '../../config';
import { Link } from 'react-router-dom';
import { openExternalWebsiteUrl } from '../../../utilities';



export default class Banner extends React.Component {

    initializeReactGA() {
        ReactGA.initialize(Config.gaId);
        // ReactGA.pageview(window.location.pathname + window.location.search);
        // this.affiliateUseFunction = this.affiliateUseFunction.bind(this);
    }

    componentDidMount() {
        this.initializeReactGA();
        // console.log(window.location.pathname)
    }

    setTheGA(label){
        // alert(path)
        // alert(label)
        var fromUrl;
        if(window.location.pathname !== '/'){
            fromUrl = window.location.pathname;
        }else{
            fromUrl = 'index';
        }
        ReactGA.event({
          category: fromUrl + '-badminton',
          action: 'banner-click',
          label: 'banner-' + label,
        });
    }


    render() {
        var settings = {
            autoplay: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const { data, className } = this.props;
        //console.log(data);
        return (
            <SliderWrapper className={className}>
                <Slider {...settings}>
                    {data.map((item, i) => (
                        <div key={i}>
                            {
                                item.video.slice(0, 4) === 'http' ?
                                    window.ReactNativeWebView ?
                                    <span onClick={()=>{ 
                                        this.setTheGA(`${item.name}`)
                                        openExternalWebsiteUrl(item.video) 
                                    }}>
                                        <img src={item.url} alt={item.name} />
                                    </span>
                                    :
                                    <a href={item.video} onClick={()=>{this.setTheGA(`${item.name}`)}}>   
                                        <img src={item.url} alt={item.name} />
                                    </a>
                                :
                                <Link to={item.video} onClick={()=>{this.setTheGA(`${item.name}`)}}>   
                                    <img src={item.url} alt={item.name} />
                                </Link>
                            }
                        </div>
                    ))}
                </Slider>
            </SliderWrapper>
        );
    }
}

Banner.defaultProps = {
    data: []
};
