import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    verifyEmail
} from '../../actions';
import { Wrapper } from './styled';

class EmailVerification extends Component {
    constructor(props){
        super(props)
        this.state ={
            email: '',
        }
    }

    // ?email=aannoiuy34@gmail.com&code=69731
    componentDidMount(){
        this.props.verifyEmail(this.props.location.search.substr(1))
 
        let index 
        let queryElementArray = this.props.location.search && this.props.location.search.substr(1) && this.props.location.search.substr(1).split('&')
        queryElementArray && queryElementArray.forEach( (e, i) => { if(e.indexOf('email') > -1) index = i  } )

        this.setState({
            email: queryElementArray && queryElementArray[index].split('=')[1]
        })

    }

    render(){
        let userToken = localStorage && localStorage.getItem('myData') && JSON.parse(localStorage.getItem('myData')).token
        return(
            <Wrapper>
                {
                    this.props.message === 'verify success' ?
                    <div >
                        <h2>  驗證成功 </h2>
                        <hr/>
                        <span className="description">
                            你已用電子郵件 {this.state.email} 驗證成功，同時已擁有投票功能，請選擇想前往的頁面：
                        </span>

                        <div className="redirectArea">

                            <button onClick={ ()=>{ this.props.history.replace('/')}} >
                                回首頁
                            </button>
                            <button onClick={ ()=>{ this.props.history.replace('/media')}} >
                                前往投票
                            </button>
                        </div>
                    </div>

                    :
                    <div >
                        {
                            this.props.message === 'USER_VEIFIED' ?
                            <h2>  重複驗證 </h2>
                            :
                            <h2>  驗證錯誤 </h2>
                        }
                        <hr/>
                        <span className="description">
                            {
                                this.props.message === 'USER_VEIFIED' ?
                                '您已驗證成功過，並擁有投票功能，請選擇想前往的頁面：'
                                :
                                    userToken ?
                                    <>
                                        此次驗證出現錯誤，錯誤可能因為以下原因： <br/>
                                        <li>未正確透過驗證信驗證 </li> 
                                        <li>閒置太久未驗證，導致驗證碼過期，請前往個人資訊頁重新驗證 </li> 
                                        <li>其他因素 </li> 
                                        若您有任何疑問以及需求，請洽本公司客服專員為您服務，
                                        客服專線：
                                        <a href="tel:0223564250">
                                            02-23564250分機377或688
                                        </a> <br/>
                                        請選擇接下來的操作或前往個人資訊頁確認驗證資訊：
                                    </>
                                    :
                                    <>
                                        此次驗證出現錯誤，錯誤可能因為以下原因： <br/>
                                        <li>未正確透過驗證信驗證 </li> 
                                        <li>閒置太久未驗證，導致驗證碼過期，請登入後前往個人資訊頁重新驗證 </li> 
                                        <li>其他因素 </li> 
                                        若您有任何疑問以及需求，請洽本公司客服專員為您服務，
                                        客服專線：
                                        <a href="tel:0223564250">
                                            02-23564250分機377或688
                                        </a> <br/>
                                        請選擇接下來的操作或登入已了解更多驗證資訊：
                                    </>
                            }
                        </span>

                        <div className="redirectArea">

                            <button onClick={ ()=>{ this.props.history.replace('/')}} >
                                回首頁
                            </button>

                            {
                                this.props.message === 'USER_VEIFIED' ?
                                    <button onClick={ ()=>{ this.props.history.replace('/media')}} >
                                        前往投票
                                    </button>
                                :
                                    userToken ?
                                    <button onClick={ ()=>{ this.props.history.push('/my_info/profile')}} >
                                        個人資訊 <img src="../../../assets/icons/ArrowW.png" alt="前往個人資訊頁面" />
                                    </button>
                                    :
                                    <button onClick={ ()=>{ this.props.history.push('/login')}} >
                                        前往登入 <img src="../../../assets/icons/ArrowW.png" alt="前往登入頁面"/>
                                    </button>
                            }
                        </div>
                    </div>
                }
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    message: state.registerReducer.emailVerifyMessage,
});


const mapDispatchToProps = dispatch => ({
	verifyEmail: (query) => dispatch(verifyEmail(query)),
});


export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
