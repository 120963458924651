import styled from 'styled-components';

export const Wrapper = styled.div`
margin-bottom: 40px;
@media (max-width: 480px) {
    margin-bottom: 30vh;
}
    h2 {
      margin: 20px 20px 5px 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }
    a {
        text-decoration: none;
        color: unset;
    }
    .all,
    .unread {
        flex: 1;
        font-size: .8rem;
        text-align: center;
        cursor: pointer;
        position: relative;
        span.more {
            color: #fff;
            background-color: #DF1C2C;
            width: 28px;
            height: 28px;
            border-radius: 999rem;
            font-size: 10px;
            display: flex;
            align-items: center; 
            justify-content: center;
            position:absolute;
            right: -3rem;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }
        &.click {
            background-color: rgb(217, 29, 48);
            color: #fff;
            span.more {
                background-color: #fff;
                color: rgb(217, 29, 48);
            }
        }
    }
    .react-tabs {
        position: relative;
        top: -5px;
        .react-tabs__tab {
            padding: 10px 0;
            position: relative;
            list-style: none;
            cursor: pointer;
            width: 50%;
            text-align: center;
            display: inline-block;
            border: none;
            font-size: .8rem;
        }
    }
    span.bonny{
        color: #DF1C2C;
    }
`;

export const ListContent = styled.div`
    width: 100%;
    height: auto;
    a {
        &.cancel {
            pointer-events:none !important;
        }
    }
    .list {
        display: flex;
        align-items: center; 
        justify-content: center;
        margin: 20px 20px 10px 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid #eee;
        cursor: pointer;
        .Out_left {
            display: flex;
            align-items: center;
            justify-content: center;
            .avatar {
                display:flex;
                flex: 1;
                width: 60px;
                height: 60px;
                border: 2px solid #ccc;
                border-radius: 999rem;
                overflow: hidden;
                // background: url('/assets/images/DefaultUserImg.png');
                @media (max-width: 480px) {
                    width: 40px;
                    height: 40px;
                }
                .avatar_photo {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    background-color: #fff;
                    background-size: cover;
                    background-position: center;
                    border-radius: 999rem;
                    // box-shadow: 0 2px 5px 1px rgba(216,216,216,.8);
                }
                img {
                    width: auto;
                    height: 60px;
                    margin: auto;
                    @media (max-width: 480px) {
                        height: 40px;
                    }
                }
            }
        }
        .Out_right {
            flex: 1;
            margin-left: 10px;
            .date {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: 16px;
                font-size: .8rem;
                text-align: right;
            }
            .inside {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                align-items: center;
            }
            .In_right {
                -webkit-flex: none;
                flex: none;
                // width: 20px;
                color: gray;
                display: flex;
                justify-content: center;
                vertical-align: middle;
                align-items: center;
                .arrow {
                    line-height: 80px;
                    text-align: center;
                }
            }
            .In_left {
                flex: 1;
                .name {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 1.4rem;
                    font-size: 1rem;
                    font-weight: bold;
                    line-height: 1.4rem;
                    overflow: hidden;
                }
                .message {
                    width: 100%;
                    height: auto;
                    max-height: 3.6rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    font-size: .8rem;
                    line-height: 1.2rem;
                    overflow:hidden;  
                    text-overflow: ellipsis;  
                    white-space: normal;  
                    display:-webkit-box;  
                    -webkit-box-orient: vertical;  
                    -webkit-line-clamp: 3;
                    span {
                        /* position: relative;
                        top: 10px; */
                    }
                    /* &::after {
                        content: '...';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 0 5px;
                        background-color: #fff;
                    } */
                }
            }
        }
    }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            margin:auto;

            .lightBox-explain-Content-inside{
                padding: 20px;

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#FFF;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description > ul{
                    padding:0;

                    li{
                        list-style-type:none;
                    }
                }

                .LB-C-Description > ul.list-style-type-number{
                    li{
                        list-style-position: inside;
                        list-style-type: decimal;
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }
        }
`;
