import { createAction } from 'redux-actions';
import {
    SET_MAP_API_DATA,
    SET_MAP_API_DATA_FROM_ACTIVITY_SIGN_UP
} from '../constants/actionTypes';
import Config from '../component/config';

export const setMapApiData = createAction(SET_MAP_API_DATA);
export const setMapApiDataFromActivitySignUp = createAction(SET_MAP_API_DATA_FROM_ACTIVITY_SIGN_UP);

export const getMapApiData = (place) => {
    return dispatch => {
        if (place.address) {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${place.address}&key=${Config.googleMapKey}&language=zh-TW&sensor=true`)
                .then( response => {
                    return response.json();
                })
                .then(function (mapRes) {
                    let results = mapRes.results
                    let status = mapRes.status
                    if (results && results[0] && results[0].address_components) {
                        if (status === 'OK') {
                            // console.log(" get map data success");
                            dispatch(setMapApiData(results[0]))
                        }
                    }
                })
                .catch(err => {
                    console.log('err', err);
                });
        } else if (place.latitude) {
            // native . browser are the same
            fetch(`https://maps.google.com/maps/api/geocode/json?key=${Config.googleMapKey}&latlng=${place.latitude},${place.longitude}&language=zh-TW&sensor=true`)
                .then(res => {
                   return res.json()
                })
                .then(mapRes => {

                    let results = mapRes.results
                    let status = mapRes.status
                    if (results && results[0] && results[0].address_components) {
                        if (status === 'OK') {
                            // alert(" get map data success");
                            dispatch(setMapApiDataFromActivitySignUp(results[0]))
                        }
                    }
                })
                .catch(err => {
                    console.log('err', err);
                });
        }
    }
}



