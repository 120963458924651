import styled from 'styled-components';

export const ButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-around;
`;
