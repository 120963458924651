import React, { Component } from "react";
import { Wrapper, LabelWrapper } from "./styled";


class Hobby extends Component {
    render() {
        const {
            onChange,
            label,
            note,
            name,
            hobby
        } = this.props;

        return (
            <Wrapper>
                <div className="form-control">
    
                    {name === "hobby" && (
                        <LabelWrapper>
                            <label key={0}>{label}</label>
                            {hobby &&
                                hobby.map((lebel, i) => {
                                    return (
                                        <LabelButton
                                            key={i}
                                            value={lebel.id}
                                            onClick={e => onChange(e)}
                                            text={lebel.name}
                                        />
                                    );
                                })}
                        </LabelWrapper>
                    )}
                   

                    <p>{note}</p>
                </div>
            </Wrapper>
        );
    }
}
class LabelButton extends Component {
    constructor(props) {
        super(props);
        this.state = { active: false };
    }
    handleClick = e => {
        this.setState({
            active: !this.state.active
        });
        this.props.onClick(e);
    };
    render() {
        const { value, text } = this.props;
        return (
            <button
                className={this.state.active ? "active" : ""}
                value={value}
                onClick={e => this.handleClick(e)}
                name="hobby"
            >
                {text}
            </button>
        );
    }
}
export default Hobby;
