import React from 'react';
import querystring from 'querystring';

import { Wrapper } from './styled';

const getId = url => querystring.parse(url.substr(url.indexOf('?') + 1)).v;

export const Video = ({ url , title }) => {
  return <Wrapper>
  <iframe
    title={title}
    id="youtubeVideo" 
    src={'https://www.youtube.com/embed/' + getId(url) + '?rel=0&autoplay=1&loop=1&playlist=' + getId(url) + '&amp;showinfo=0'}
    frameBorder="0" 
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
    allowFullScreen>
  </iframe>
</Wrapper>;
}

export const Image = ({ url, imgProps }) => <img
  {...imgProps}
  src={'https://i.ytimg.com/vi/' + getId(url) + '/maxresdefault.jpg'}
  alt=""
/>;

export default {
  Video,
  Image,
};
