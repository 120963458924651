const initialState = {
	newGroup:{},
	levels: [],
	features: [],
	activity: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case 'CREATE_GROUP': {
			return state
		}
		case 'EDIT_GROUP_DATA': {
			return { ...state, activityInformation: action.payload };
		}

		case 'GET_LEVELS': {
			return { ...state, levels: action.payload }
		}
    
    	case 'GET_PREFERENCES': {
			return { ...state, preferences: action.payload }
    	}

		case 'CREATE_FEATURE': {
			console.log(action.payload)
			return { ...state, features: [...state.features, action.payload] }
		}
		case 'ADD_FEATURE': {
			console.log(action.payload)
			return { ...state,  features: [...state.features, action.payload] }
		}

		case 'GET_ACTIVITY': {
			return { ...state, activity: action.payload };
		}

		default:
			return state
	}

}
