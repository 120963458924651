import styled from 'styled-components';

export const Wrapper = styled.div `
        color: #fff
        
        .text {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 4px;
          }

    .facebook {
        width: 100%;
        border: 1px solid;
        color: white;
        padding: 14px;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        border-radius: 10px;
        background-color: #3b5998;
    }

    hr {
        border: none;
        border-top: 1px solid lightgray;
        margin: 30px -15px 0;
    }
`





