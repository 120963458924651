import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    margin-bottom: 40px;
    @media (max-width: 480px) {
        margin-bottom: 30vh;
    }
    .none{
        display:none;
    }
    button:disabled{
        background-color:grey;
    }
    hr{
        width: 100%;
        height: 1px;
        border: none;
        border-bottom: 1px solid #ccc;
    }
    .b1{
        text-align: center;
        width:100%
        background-color: #D5D5D5;
        border: 0 none
        padding-top: 10PX
        padding-bottom: 10PX
        font-size: large
        font-weight: bolder
        color: #fff
        border-radius: 5px
        font-weight: bolder
        img{
            padding-left: 5px;
            top: 5px;
            position: relative;
        }
    }

    #RacketDetail_program_content{
        width:calc( 100% - 40px );
        margin:auto;
        font-size: 14px;

        .title{
            h2{
                font-size: 1.2rem;
                margin-block-start: 0.83em;
                margin-block-end: 0.83em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                font-weight: bold;
            }
        }

        ul{
            padding:0;
            li{
                list-style:none;
                margin:2px 0;
                line-height:1.4rem;

                input[type="text"]{
                    width:calc( 100% - 14px );
                }

                input[type="text"].remark-input-style{
                    height:150px;
                }
            }

            li.racket_belong {
                display:flex;

                p {
                    flex: 0 0 5rem;
                    margin: 0;
                    line-height: 35px;
                }

                select {
                    border-bottom-right-radius: 0px;
                    border-top-right-radius: 0px;
                    flex:1;
                }

                button {
                    padding-top: 5px;
                    padding-bottom: 5px
                    height: 35px
                    border-color: rgba(0,0,0,0);
                    border-radius: 5px;
                    border-bottom-left-radius: 0px;
                    border-top-left-radius: 0px;
                    background-color: #DF1C2C;
                    color: #FFF;
                    cursor: pointer;
                    flex: 0 0 4rem;
                }
                .blue {
                    background-color: #2f82bf;
                }
            }

            li.racket_belong > button:hover{
                -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                color:#eee;
            }

            .margin-top-5px{
                margin-top:5px;
            }
            .margin-top-20px{
                margin-top:20px;
            }
            .margin-bottom-20px{
                margin-bottom:20px;
            }
            .border-style{
                border: 2px solid #DF1C2C;

                .img-top{
                    width:100%;
                    height:199px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                }
                .img-bottom{
                    width:100%;
                    height:22px;
                    padding-top:5px;
                    padding-bottom:3px;
                    background-color:#DF1C2C;
                    color:#FFF;
                    text-align:center;
                    line-height:22px;
                    font-weight:bold;
                }
            }
            .button-group{
                margin-top:40px;
                margin-bottom:60px;
                display:flex;
                justify-content:space-between;

                .button{
                    text-align: center;
                    flex: 1;
                    margin: 5px;
                    background-color: #DF1C2C;
                    border: 0 none;
                    padding: 10px 0;
                    font-size: large;
                    font-weight: 500;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                }
                .button-cancel{
                    background-color:#aaa;
                }
                div.button-cancel{
                    position: relative;
                    p{
                        display: none;
                        position: absolute;
                        width: 21em;
                        bottom: -1.7em;
                        right: 0em;
                        font-size: 8px;
                        line-height: 1.5em;
                        background-color: rgba(223,28,44,.6);
                        border-radius: 2px;
                        -webkit-box-shadow: 0 0 5px rgba(0,0,0,.3);
                        -moz-box-shadow: 0 0 5px rgba(0,0,0,.3);
                        box-shadow: 0 0 5px rgba(0,0,0,.3);
                    }
                }
                div.button-cancel:hover p{
                    display:block;
                }
                .button-blue{
                    background-color:#3b84b7;
                }
            }
            .button-only-one{
                margin-top:40px;
                margin-bottom:60px;
                display:block;

                button{
                    text-align: center;
                    width:100%;
                    margin: auto;
                    background-color: #DF1C2C;
                    border: 0 none;
                    padding: 10PX;
                    font-size: large;
                    font-weight: 500;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                }
            }
            .display-flex-mom{
                display:flex;

                .list-content-flex-son{
                    flex: 0 0 calc( 50% - 10px );
                }
                .margin-left-5px{
                    margin-left:5px;
                }
                .margin-right-5px{
                    margin-right:5px;
                }
            }
        }

    }
    .clear{
        clear:both;
    }
    .hide{
        display:none;
    }
    .button-blue-oval{
        padding:8px 0;
        margin-top:10px;
        border-radius:999rem;
        border:1px solid #3b84b7;
        width:100px;
        line-height:20px;
        color:#3b84b7;
        font-weight:bold;
        font-size:14px;
        float:right;
        cursor:pointer;
    }
    .button-blue-oval:hover{
        background-color:#3b84b7;
        color:#FFF;
    }
    .color-red{
        color:red;
    }
    .color-LightBlack{
        color:#222;
    }
    .font-weight-bold{
        font-weight:bold;
    }
    .margin-left-20px{
        margin-left:20px;
    }
    .margin-top-5px{
        margin-top:5px;
    }
    .margin-top-20px{
        margin-top:20px;
    }
    .margin-bottom-20px{
        margin-bottom:20px;
    }
    .close{
        display:none !important;
    }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            margin:auto;

            .lightBox-explain-Content-inside{
                padding: 20px;

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#FFF;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description > ul{
                    padding:0;

                    li{
                        list-style-type:none;
                    }
                }

                .LB-C-Description > ul.list-style-type-number{
                    li{
                        list-style-position: inside;
                        list-style-type: decimal;
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }

            .light-outside-button-group{
                width:100%;
                height:37px;
                display:flex;
                text-align:center;
                line-height:37px;
                font-size:20px;
                padding-bottom:8px;

                .light-outside-button-group-check{
                    flex:0 0 calc( 50% - 0.5px);
                    cursor:pointer;
                }
                .light-outside-button-group-line{
                    flex:0 0 1px;
                    border:none;
                    margin:0;
                    padding:0;
                    border-left:1px solid #000;
                }
                .light-outside-button-group-close{
                    flex:0 0 calc( 50% - 0.5px);
                    cursor:pointer;
                }
            }
        }
`
