import React, { Component } from "react";
import moment from 'moment-timezone';
import { Wrapper, LoginWrap, TabHeader, } from './styled';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { 
    signUpGroup, 
    getGroupDetail, 
    getJoinedList, 
    fetchRegister, 
    fetchlogin, 
    followUser, 
    unFollowUser, 
    followList, 
    fetchLoginFB, 
    createActivityPaymentOrder,
    fetchUserInfo,
    sendVerifyMobile,
    verifyMobile,
    shouldMobilelVerifyTrue,
    setMobileVerifyMessage
} from "../../actions";
import { 
    SubmitButton, 
    CommentBox, 
    LoadingIndicator, 
    Modal 
} from "../Common";
import Banner from '../../component/GroupDetail/Banner';
import Information from '../../component/GroupDetail/Information';
import Checkbox from './Checkbox';
import FacebookLoginButton from '../Login/ThirdPartyButton';
import UAParser from 'ua-parser-js';
import { setCookie, copyLink, validateEmail, localStorageUtility } from '../../utilities';
import store from '../../store';

var id = 0;
class QuickSignUpGroup extends Component {
      constructor(props) {
        super(props);
        const linkQuery = this.props.location.search;
        const LinkArr = linkQuery.split('&');
        if ( LinkArr.length !== 1 ) {
            this.props.history.push("/quick_sign_up_activity" + LinkArr[0]);
        }
        const userInfo = localStorageUtility('getAndParse', 'myData');
        store.dispatch(shouldMobilelVerifyTrue(false));
        this.state = {
            modalIsOpen: false,
            isLogined: userInfo.token ? true : false,
            isJoined: false,
            name: '',
            email: '',
            phone: '',
            prevPhone: '',
            password: '',
            passwordConfirmed: '',
            checked: false,
            regularJoin: false,
            user: userInfo,
            agent: {},
            paymentType: 'Cash',
            isSendPaymentClick: false,
            code: '',
            mobileInputIsDisable: 0,
            remindTime: 0,
            message: '',
            prevMessage: '',
        };
      }

    componentDidMount() {
        const url = window.location.href;
        const index = url.indexOf("?");
        id = url.substring(index + 1, url.length);
        this.props.getGroupDetail(id);

        const userInfo = localStorageUtility('getAndParse', 'myData');
        const token = userInfo ? userInfo.token : null;
        if (token) {
            this.props.getJoinedList();
            this.props.followList({ type: "follower" });
            this.props.getUserInfo();
        } else{
            let nowURL= window.location.href;
            setCookie('backurl', nowURL, 1);
        }

        var parser = new UAParser();
        let ua = navigator.userAgent;
        this.setState({
            agent: {
                browser: parser.setUA(ua).getBrowser(),
                os: parser.setUA(ua).getOS(),
                device: parser.setUA(ua).getDevice(),
            }
        }) 
    
        let expiresTime = localStorageUtility('get', 'expiresTime');
        if (!expiresTime) return
        let expiresDate = Date.parse(expiresTime);
        let now = moment().valueOf();
        if (now > expiresDate) {
            localStorageUtility('set', 'rememberMe', false);
            localStorageUtility('set', 'expiresTime', '');
            localStorageUtility('remove', 'myData');
        }
    }

    componentDidUpdate(prevProps, prevState){
        if( 
            this.props.isGetPaymentRes === true 
            && 
            this.state.paymentType === 'CreditEcpay' 
            && 
            this.state.isSendPaymentClick === true
        ) {
            document.getElementById("purchase_form").submit();
        }

        if (this.props.message !== "" && this.props.message !== prevProps.message) {
            this.setState({
                prevMessage: this.props.message,
            })
            this.onModalSwitch(true, this.messageContent(this.props.message));
        }
    }

    messageContent(message) {
        switch (message) {
            case 'SEND_SUCCESS':
                this.setState({
                    mobileInputIsDisable: 1,
                })
                return '已發送驗證碼至您的手機號碼：' + this.state.phone;
            case 'NO_CODE':
                return '未輸入驗證碼';
            case 'NO_PHONE_NUMBER':
                return '手機號碼未輸入';
            case 'INCORRECT_CODE':
                return '驗證碼不正確，請重新輸入';
            case 'CODE_EXPIRED':
                return '驗證碼過期，請重新驗證';
            case 'PHONE_USED':
                this.handleMobileVerifyCancel();
                return '此手機號碼已被註冊，請登入或重新輸入';
            case 'SEND_FAIL':
                return '簡訊發送失敗，請重新操作';
            case 'VERIFY_SUCCESS':
                this.countDown(2);
                return '恭喜，已成功驗證您的手機！';
            default:
                return '未知錯誤，請重新操作或聯絡客服';
        }
    }

    handleSubmit = (e) => {
        const userInfo = localStorageUtility('getAndParse', 'myData');
        const token = userInfo ? userInfo.token : "";

        if (!token) {
            document.cookie = "backurl="+window.location.pathname;
            alert('請先登入！')
            this.props.history.push('/login');
        } else if (this.props.userInfo && this.props.userInfo.phoneVerified === false) {
            document.cookie = "backurl="+window.location.pathname;
            alert('您的手機尚未經過驗證，請先完成驗證')
            this.props.history.replace('/my_info/profile')
        } else {
            this.props.signUpGroup({ activity: id, rejoin: this.state.regularJoin });
        }
    }

    onModalClose() {
        this.onModalSwitch(false);
    }
    
    onModalSwitch(setting, message) {
        this.setState({
            modalIsOpen: setting,
            message: message
        }, ()=>{
            // 開關 modal
            this.refs.modal && this.refs.modal[this.state.modalIsOpen ? 'openModal' : 'closeModal']();
        })
        store.dispatch(setMobileVerifyMessage(''));
    }

    countDown = (second) => {
        this.setState({
            remindTime: second,
        });
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            let remindTime = this.state.remindTime;
            const setRemindTime = remindTime -= 1;
            this.setState({
                remindTime: setRemindTime,
            });
            if (this.state.remindTime <= 0) {
                clearInterval(this.interval);
            }
        }, 1000);
    }

    showTime = () => {
        const timer = moment()
            .startOf('day')
            .add(this.state.remindTime, 'second');
        return timer.format('ss');
    }

    mobileNumberVerification = (mobile) => {
        const mobileRule = /^[09]{2}[0-9]{8}$/;
        if (mobile !== '' && String(mobile).replace(/\s+/g, "").length !== 0 && mobile.search(mobileRule) !== -1){
            return true;
        } else {
            return false;
        }
    }

    handleMobileVerifySend = () => {
        const { phone, prevPhone, mobileInputIsDisable, prevMessage } = this.state;
        let message;
        if (this.props.verify === false && this.showTime() === '00' ){
            if (this.mobileNumberVerification(phone)) {
                if (phone === prevPhone && mobileInputIsDisable !== 1) {
                    if (prevMessage !== 'SEND_SUCCESS' && prevMessage !== '') {
                        this.handleMobileVerifyCancel();
                        this.onModalSwitch(true, this.state.message);
                    } else {
                        message = '兩次手機號碼輸入相同，請輸入驗證碼或變更手機號碼';
                        // this.handleMobileVerifyCancel();
                        this.countDown(15);
                        this.onModalSwitch(true, message);
                        this.setState({
                            prevPhone: '',
                            mobileInputIsDisable: 1,
                        });
                    }
                } else {
                    this.props.sendVerifyMobile({phone});
                    this.countDown(30);
                    this.setState({
                        prevPhone: phone,
                    });
                }
            } else {
                message = '手機號碼有誤，請重新輸入';
                this.onModalSwitch(true, message);
            }
        } else if(this.props.verify){
            // 驗證完成，無動作
            message = '此手機號碼驗證已完成，請繼續完成註冊流程';
            this.onModalSwitch(true, message);
        } else {
            message = '操作過快，請等候' + this.showTime() + '秒';
            this.onModalSwitch(true, message);
        }
    }

    handleMobileVerifySubmit = () => {
        const { phone, code } = this.state;
        this.props.verifyMobile({phone, code});
    }

    handleMobileVerifyCancel = () => {
        if (this.state.phone) {
            this.countDown(3);
            this.setState({
                phone: '',
                code: '',
                mobileInputIsDisable: 0,
            });
        }
        store.dispatch(setMobileVerifyMessage(''));
        store.dispatch(shouldMobilelVerifyTrue(false));
    }

    openModal = (e) => {
        this.setState({ modalIsOpen: true });
    }

    sendPayment = (e) => {
        this.setState({ 
            paymentType: e.paymentType,
            isSendPaymentClick: true, 
        });
        this.props.createActivityPaymentOrder(e);
    }

    handleSwitch = (isJoined) => {
        this.setState({ isJoined: isJoined });
    }

    tabStyle = (isJoined) => {
        let actived
        if (isJoined) {
            actived = this.state.isJoined
            return "tab " + (actived ? "active" : "")
        } else {
            actived = !this.state.isJoined
            return "tab " + (actived ? "active" : "")
        }
    }

    registerAndSignin = (e) => {
        e.preventDefault();
        let message;

        if (this.state.password !== this.state.passwordConfirmed) {
            message = '密碼確認錯誤';
            this.onModalSwitch(true, message);
            return
        }

        let playerId = localStorageUtility('get', 'playerId');
        const payload = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone,
            code: this.state.code,
            badminton_level: 1,
            occupation: 1,
            signUpGroupId: id,
            redirectUrl: "/my_info",
            alertMessage: '',
            autoLogin: true,
            agent: this.state.agent,
            playerId: playerId,
        };
        let mobileRule = /^[09]{2}[0-9]{8}$/;
        let passwordRule = /^(?=.*[a-zA-Z]).{6,15}$/;

        if (payload.name === '' || !payload.name) { alert('姓名不得為空'); return };
        if (payload.email === '' || !payload.email) { alert('E-MAIL不得為空'); return };
        if ( validateEmail(payload.email) === false ) { alert('E-MAIL格式錯誤'); return };
        if (payload.phone === '' || !payload.phone) { alert('電話不得為空'); return };
        if (payload.code === '' || this.props.verify === false) { alert('請確實完成手機驗證'); return };
        if (String(payload.phone).replace(/\s+/g, "").length === 0 || payload.phone.search(mobileRule) === -1) { alert('電話格式錯誤'); return };
        if (payload.password === '' || !payload.password) { alert('密碼不得為空'); return };
        if (payload.password.search(passwordRule) === -1) { alert('密碼格式錯誤'); return };
        if (this.state.passwordConfirmed === '' && payload.password !== this.state.passwordConfirmed) { alert('請確認密碼不可為空且兩次密碼輸入皆相同！'); return };
        if (this.state.checked !== true) { alert('請閱讀並接受個資保護聲明'); return };
        
        let expiresTime= moment().tz("Asia/Taipei").add(1, "day").format() 
        localStorageUtility('set', 'expiresTime', expiresTime);
        
        this.props.fetchRegister(payload);
    }

    loginAndSignin = (e) => {
        e.preventDefault();
        if (this.state.email === '') { alert('帳號不得為空'); return };
        if (this.state.password === '') { alert('密碼不得為空'); return };

        this.props.login({
            email: this.state.email,
            password: this.state.password,
            redirectUrl: "/my_info/participated_activities",
            signUpGroupId: id,
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChecked = (e) => {
        this.setState({ checked: e.target.checked });
    }

    handleFollowUser = (e) => {
        e.preventDefault();
        const userInfo = localStorageUtility('getAndParse', 'myData');
        const token = userInfo ? userInfo.token : "";
        if (!token) {
            alert('請先登入！');
            this.props.history.push('/login');
        } else {
            var isFollowed = this.isFollowed();
            if (isFollowed) {
                this.props.unFollowUser({ id: this.getFollowId(this.props.activityInformation.user.id) });
            } else {
                this.props.followUser({ followee: this.props.activityInformation.user.id });
            }
        }
    }

    isFollowed = () => {
        var followList = this.props.follow
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === this.props.activityInformation.user.id)
                    return true
            }
        }
        return false
    }
    
    // 取消追蹤才會跑
    getFollowId = () => {
        var followList = this.props.follow;
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === this.props.activityInformation.user.id) {
                    return followList[i].id;
                }
            }
        }
        return 0;
    }

    regularJoin = (e) => {
        this.setState({ regularJoin: !this.state.isChecked })
    }

    onLoginFacebook(resp) {
        let expiresTime= moment().tz("Asia/Taipei").add(7, "day").format() 
        localStorageUtility('set', 'expiresTime', expiresTime);
        let agent = this.state.agent;
        let playerId = localStorageUtility('get', 'playerId');
        this.props.onFetchLoginFB(resp, { signUpGroupId: id, redirectUrl: window.location.href }, {agent, playerId:playerId} );
    }

    // 一進來就會跑
    isActivityBelongToCurrentUser = () => {
        let { activityInformation } = this.props;
        if (activityInformation && activityInformation.user && this.state.user) {
            return activityInformation.user.id === this.state.user.id
        }
        return false;
    }

    enableSignUp = (activityStatus, activityStartTime) => {
        let enableSignUp = true;
        const pastActivity = moment(activityStartTime).isBefore(moment());
        if (
            activityStatus === 0 
            ||
            activityStatus === -1 
            ||
            pastActivity
        ) {
            enableSignUp = false
        }
        return enableSignUp
    }

    render() {
        const { joinedList, activityInformation } = this.props;
        const refundPeriod = (activityInformation && activityInformation.refundPeriod) || '1';
        const groupFee = activityInformation && activityInformation.fee;
        const groupUserId = activityInformation.user && activityInformation.user.id;
        let isSignUp = false;
        let isPaid = true;
        let joinID = 0;
        let joinedStatus = true;

        if (joinedList && Array.isArray(joinedList)) {
            joinedList.forEach(joinedListData => {
                if (joinedListData && joinedListData.activity.id === Number(id)) {
                    let lengthCheck = joinedList.filter(n => n.activity.id === Number(id)).length;
                    if (lengthCheck <= 1) {
                        isSignUp = true;
                        joinID = joinedListData.id;
                        isPaid = joinedListData.paid;
                        joinedStatus = joinedListData.status;
                    } else if (joinedListData.status) {
                        let joinedListNew = joinedList.filter(n => n.activity.id === Number(id));
                        joinedListNew.sort(function(a, b){return a.createdAt - b.createdAt});
                        let joinedNew = joinedListNew[joinedListNew.length - 1];
                        isSignUp = true;
                        joinID = joinedNew.id;
                        isPaid = joinedNew.paid;
                        joinedStatus = joinedNew.status;
                    }
                }
            })
        }
        var signUp = () => {
            const {
                remain,
                status,
                start,
                end,
                frequency,
                plan,
                user
            } = activityInformation;
            const { 
                isLogined, 
                isJoined, 
                name, 
                email, 
                phone, 
                password, 
                passwordConfirmed, 
                checked, 
                regularJoin, 
                modalIsOpen,
                mobileInputIsDisable
            } = this.state;
            const userInfo = localStorageUtility('getAndParse', 'myData');

            if (isLogined === false && this.enableSignUp(activityInformation.status, activityInformation.start)) {
                return (
                    <LoginWrap key={0}>
                        <TabHeader key={1}>
                            <div className={this.tabStyle(true)} onClick={e => this.handleSwitch(true)} style={{cursor:'pointer'}}>會員快速報名</div>
                            <div className={this.tabStyle(false)} onClick={e => this.handleSwitch(false)} style={{cursor:'pointer'}}>非會員快速報名</div>
                        </TabHeader>
                        <h2>立即報名</h2>
                        { !isJoined ?
                                <div key={2}>
                                    {/* 快速登入 報名 */}
                                    <input name="name" onChange={this.onChange} value={name} type="text" placeholder="請輸入您的姓名" />
                                    <input name="email" onChange={this.onChange} value={email} type="text" placeholder="請輸入您的E-Mail(此為日後登入帳號)" />
                                    {/* <input name="phone" onChange={this.onChange} value={phone} type="text" placeholder="行動電話" /> */}
                                    <div className="flex alignCenter">
                                        <input 
                                            name='phone' 
                                            type="text" 
                                            value={phone} 
                                            onChange={this.onChange} 
                                            disabled={mobileInputIsDisable === 1 ? "disabled" : ""}
                                            placeholder="請輸入您的行動電話" 
                                            style={{ width:'calc(100% - (14px * 8))' ,borderRadius: `5px 0 0 5px` ,borderRight: `none`, margin: `0 auto` }}
                                        />
                                        <div 
                                            className="mobileVerifyButton" 
                                            onClick={this.handleMobileVerifySend}
                                        >
                                            {this.showTime() === '00' && mobileInputIsDisable !== 1 ? 
                                                "發送驗證碼" 
                                                :
                                                this.showTime() !== '00' && mobileInputIsDisable !== 1 ? 
                                                    "發送驗證碼(" + this.showTime() + ")" 
                                                    :
                                                        this.showTime() !== '00' && mobileInputIsDisable === 1 ? 
                                                            "請稍候 " + this.showTime() + "秒"
                                                            :
                                                            this.props.verify ?
                                                                "驗證完成"
                                                                : 
                                                                "重新發送"
                                            }
                                        </div>
                                    </div>
                                    {this.props.verify === false ?
                                        <div className="flex alignCenter" style={{width: `100%`,  margin: `10px auto 0 auto`}}>
                                            <input 
                                                name='code' 
                                                type="text" 
                                                value={this.state.code} 
                                                onChange={this.onChange} 
                                                disabled={this.props.verify ? "disabled" : ""}
                                                placeholder="請輸入驗證碼" 
                                                style={{ flex: `1` , margin: `0 auto`}}
                                            />
                                            <div 
                                                className="mobileVerifySubmit" 
                                                onClick={this.handleMobileVerifySubmit}
                                            >
                                                送出
                                            </div>
                                            <div className="mobileVerifyCancel" onClick={this.handleMobileVerifyCancel}>變更手機號碼</div>
                                        </div>
                                        :
                                        <div className="mobileVerifyCancel" onClick={this.handleMobileVerifyCancel} style={{width: `95%`, textAlign: `right`}}>變更手機號碼</div>
                                    }
                                    <input name="password" onChange={this.onChange} value={password} type="password" placeholder="請輸入您的密碼(6~15位英文數字混合)" />
                                    <input name="passwordConfirmed" onChange={this.onChange} value={passwordConfirmed} type="password" placeholder="請再次輸入您的密碼" />
                                    <Checkbox 
                                        onClick={this.handleChecked} 
                                        checked={checked} 
                                    />
                                    {/* this.props.activityInformation.frequency && 
                                        <div className="alwaysJoin" style={{width: '100%',}}>
                                            <div className="checkbox">
                                                <input type="checkbox" 
                                                    value={regularJoin} 
                                                    onChange={this.regularJoin} 
                                                />
                                            </div> 
                                            <h3>固定參加</h3>
                                        </div>
                                    */}
                                    <SubmitButton style={{margin: `20px auto 20px auto`}} onClick={this.registerAndSignin} />

                                    <div className="goToLogin">
                                        <h3>已擁有Bonny雲羽集帳號?</h3> 
                                        <div onClick={e => this.handleSwitch(true)} style={{cursor:'pointer'}}>
                                            <h4>立即登入報名</h4>
                                        </div>
                                        {/* <Link to="/login">
                                            <h4>我要登入</h4>
                                        </Link> */}
                                    </div>
                                </div> 
                            :
                            <div key={2}>
                                <input name="email" autocomplete="username" onChange={this.onChange} value={email} type="text" placeholder="帳號" />
                                <input name="password" type="password" autocomplete="current-password" onChange={this.onChange} value={password} placeholder="密碼" />
                                { this.props.activityInformation.frequency && 
                                        <div className="alwaysJoin" style={{width: '100%', marginBottom: '10px'}}>
                                            <div className="checkbox">
                                                <input type="checkbox" 
                                                    value={regularJoin} 
                                                    onChange={this.regularJoin} 
                                                />
                                            </div> 
                                            <h3>固定參加</h3>
                                        </div>
                                }
                                <SubmitButton style={{margin: `10px auto 20px auto`}} onClick={this.loginAndSignin} />
                            </div>
                        }
                        <FacebookLoginButton onLoginFacebook={resp => this.onLoginFacebook(resp)}/>
                    </LoginWrap>
                )
            } else {
                if (isLogined === true) { //登入
                    if (user && user.id !== userInfo.id) { //非團主
                        if (isSignUp === true && joinedStatus === true) { //已報名
                            if (new Date().valueOf() < end) {
                                if (status === 1) { //活動上架中
                                    if (groupFee !== 0) { //需付款
                                        if (isPaid) { //已付款
                                            return (
                                                <div key={1} className="b1">
                                                    已報名（已付款）
                                                </div>
                                            )
                                        }
                                        else { //未付款
                                            return ([
                                                <div key={1} className="b1" >
                                                    已報名（未付款）
                                                </div>,
                                                <div key={2} style={{height: '5px',}}></div>,
                                                (plan && ((plan.CreditEcpay !== 0) || (plan.CreditEcpay !== null) || (plan.CreditEcpay !== undefined) )) ? 
                                                    <div key={3}>
                                                        <Modal
                                                            title="前往付款確認"
                                                            onClick={(e) => {
                                                                this.sendPayment({activityId: parseInt(id), joinId: parseInt(joinID), paymentType: `CreditEcpay`},e)
                                                            }}
                                                            btnTheme={"two"}
                                                            text1="確認"
                                                            modalIsOpen={modalIsOpen}
                                                            noButton={true}
                                                            key= {'confirmjoin1'}
                                                            modalContent={
                                                                <div>
                                                                    <div>溫馨提醒：</div>
                                                                    <div>此活動於
                                                                        <span style={{color: `#da263c`,fontWeight: `500`,}}>
                                                                            {moment(parseInt(start)).subtract(refundPeriod, 'days').format('MM月DD日 LT')}
                                                                        </span>
                                                                        後僅提供請假服務，不提供退款服務，若同意再點選確認按鈕跳轉至線上付款頁面。
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <div id="otherActvity" style={{marginBottom: `5px`}}>
                                                                <SubmitButton text={"線上付費"} onClick={this.openModal}/>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                :
                                                null,
                                                (plan && ( (plan.Cash !== 0) || (plan.Cash !== null) || (plan.Cash !== undefined) )) ?
                                                    <div key={4}>
                                                        <Modal
                                                            title="付款未完成"
                                                            onClick={(e) => {
                                                                this.sendPayment({activityId: parseInt(id), joinId: parseInt(joinID), paymentType: `Cash`},e)
                                                            }}
                                                            btnTheme={"two"}
                                                            text1="確認"
                                                            modalIsOpen={modalIsOpen}
                                                            noButton={true}
                                                            modalContent={
                                                                <div>
                                                                    <div style={{textAlign: `center`,}}>請與團主聯絡付款事宜，謝謝您！</div>
                                                                    <div className="aLinkDiv" style={{width: `100%`,}}>
                                                                        <Link to={`/my_info/chatroom/user_id=` + groupUserId}>
                                                                            聯絡團主
                                                                        </Link> 
                                                                    </div>  
                                                                </div>
                                                            }
                                                        >
                                                            {
                                                            <div id="otherActvity">
                                                                <SubmitButton text={"現場付費"} onClick={this.openModal} />
                                                            </div>
                                                            }
                                                        </Modal>
                                                    </div>
                                                :
                                                null,
                                            ])
                                        }
                                    }
                                    else{ //活動不須付款
                                        return (
                                            <div key={1} className="b1">
                                                報名成功
                                            </div>
                                        )
                                    }
                                }
                                else if(status === -1){ //報名截止
                                    return (
                                        <div key={1} className="b1">
                                            報名截止
                                        </div>
                                    )
                                }
                                else{ //活動已取消
                                    return (
                                        <div key={1} className="b1">
                                            活動已取消
                                        </div>
                                    )
                                }
                            }
                            else{ //活動已結束
                                return (
                                    <div key={1} className="b1">
                                        活動已結束
                                    </div>
                                )
                            }
                        }
                        else{ //未報名
                            if(start >= new Date().valueOf()){ //活動未到期
                                if(remain !== 0){ //人數未滿
                                    if(this.isActivityBelongToCurrentUser() === false) {
                                        if(this.enableSignUp(status, start)){
                                            return ([
                                                <div id="otherActvity" style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                                                    {(this.isActivityBelongToCurrentUser() === false && frequency) 
                                                        &&
                                                        <div className="alwaysJoin" style={{width: '100%',}}>
                                                            <div className="checkbox">
                                                                <input type="checkbox" value={regularJoin} onChange={this.regularJoin} /> 
                                                            </div>
                                                            <h3>固定參加</h3>
                                                        </div>
                                                    }
                                                </div>,
                                                <Modal
                                                    title="報名確認"
                                                    onClick={(e) => {
                                                        this.handleSubmit(e);
                                                    }}
                                                    btnTheme={"two"}
                                                    text1="確認"
                                                    modalIsOpen={modalIsOpen}
                                                    noButton={true}
                                                    key = {'confirmjoin1'}
                                                    modalContent={
                                                        <div>
                                                            <div>點擊確認即完成報名，如需與團主聯絡可於【 My Bonny/我的報隊 】中查看開團資訊與討論。</div>
                                                            <br />
                                                            <div onClick={ ()=>{copyLink('/QuickSingUpGroup', true, window.location.search) }} style={style}>分享連結</div>
                                                        </div>
                                                    }
                                                >
                                                    <SubmitButton text={"我要報名"} onClick={this.openModal} />
                                                </Modal>
                                            ])
                                        }
                                    }
                                } 
                                else{
                                    return (                          
                                        <div key={1} className="b1">人數已滿</div>
                                    )
                                }
                            }
                            else{
                                return (                          
                                    <div key={1} className="b1">已結束報名</div>
                                )
                            }
                        }
                    }
                }
            }
        }

        return (  
            <Wrapper key={7}>
                <LoadingIndicator shouldOpen={this.props.loading}/>
                <Modal
                    ref='modal'
                    title='提示通知'
                    onClick={() => this.onModalClose()}
                    noButton={true}
                    btnTheme="one"
                    text2="關閉"
                    modalContent={
                        <div dangerouslySetInnerHTML={{__html: this.state.message}} />
                    }
                />
                <Banner activityInformation={this.props.activityInformation} follow={this.handleFollowUser} followList={this.props.follow} isBelongToMe={this.isActivityBelongToCurrentUser()} />
                <div className="main-form">
                    <Information activityInformation={this.props.activityInformation} comments={this.props.chatroom_data} follow={this.handleFollowUser} followList={this.props.follow} isBelongToMe={this.isActivityBelongToCurrentUser()} />
                    <br />
                    {signUp()}
                </div>
                <CommentBox chatroom={this.props.activityInformation.chatroom} />
            </Wrapper>
        );
    }
}

let style = {
    textAlign: "center",
    border: "1px solid #5590BE",
    padding: "5px",
    borderRadius: "20px",
    cursor: "pointer",
}

const mapStateToProps = state => ({
    loading: state.groupDetailReducer.shouldLoadingOpen,
    activityInformation: state.groupDetailReducer.activityInformation,
    joinedList: state.groupDetailReducer.joinedList,
    chatroom_data: state.commentReducer.chatroom_data,
    follow: state.groupDetailReducer.follow,
    isGetPaymentRes: state.groupDetailReducer.isGetPaymentRes,
    userInfo: state.myInfoReducer.userInfo,
    verify: state.registerReducer.shouldMobilelVerifyTrue,
    message: state.registerReducer.setMobileVerifyMessage,
});

const mapDispatchToProps = ({
    signUpGroup: signUpGroup,
    getGroupDetail: getGroupDetail,
    getJoinedList: getJoinedList,
    fetchRegister: fetchRegister,
    login: fetchlogin,
    followUser: followUser,
    followList: followList,
    unFollowUser: unFollowUser,
    onFetchLoginFB: fetchLoginFB,
    createActivityPaymentOrder: createActivityPaymentOrder,
    getUserInfo: fetchUserInfo,
    sendVerifyMobile: sendVerifyMobile,
    verifyMobile: verifyMobile,
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickSignUpGroup));


