import { setCookie, localStorageUtility} from '../utilities';
import { 
    setDeleteUserInfoToRedux,
    updateUserLoginStatus,
    fetchOffSocket,
} from '../actions';

// this.props ? 

export const logOut = () => {
    console.log("in log out utility");
    window.location.href = "/login";

    let token = localStorageUtility('getAndParse', 'myData').token;
    localStorageUtility('remove', token);
    localStorageUtility('remove', 'myData');

    setDeleteUserInfoToRedux()
    updateUserLoginStatus(false)

    setCookie('myinfo', '', -1);
    setCookie('backurl', '', -1);
    
    // updateNotifActivity 是 dead code，再和 Hanfour 確認
    // store.dispatch(updateNotifActivity());

    localStorageUtility('set', 'expiresTime', '');
    
    fetchOffSocket()

};