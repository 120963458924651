import { createAction } from "redux-actions";
import { fetchObject } from './fetchModule';
import { fetchUserInfo } from './myInfoActions';
import { signUpGroup } from './groupDetailActions';
import { history } from '../index';
import { getCookie, setCookie, localStorageUtility } from '../utilities';

export const updateUserLogin = createAction('UPDATE_USER_LOGIN');
export const confirmUserLoading = createAction('LOADING_DATA');

const handleLoggedIn = (data, Body) => dispatch => {
    if( localStorageUtility('get', 'myData') ){
        // 設定 token 尚未 update playerId 過
        localStorageUtility('set', JSON.parse(localStorage.getItem('myData')).token, 0);
    }

    if (Body.signUpGroupId && data.type !== "facebook") {
        fetchUserInfo({...Body, userId: data.id})(dispatch);
    } else if (Body.signUpGroupId && data.type === "facebook") {
        signUpGroup({ activity: Body.signUpGroupId, redirectUrl: Body.redirectUrl })(dispatch);
    } else if (Body.rentPay) {
        dispatch(updateUserLogin(true));
    } else {
        if (localStorageUtility('get', 'myData') != null && getCookie('backurl')) {
            if (getCookie('backurl') !== '') {
                window.location.href = getCookie('backurl');
                setCookie('backurl', '', -1); //清除返回頁面url
            }
            else {
                window.location.href = "/my_info";
            }
        } else {
            window.location.href = "/my_info";
        }

        // fetchOnSocket()

        // var io = sailsIOClient(socketIOClient);
        // // io.sails.url = 'https://bonnylive.biz:1337';
        // io.sails.url = "http://192.168.10.54:1337"; //'https://bonnylive.biz:1337';
        
        // io.sails.headers = { token: token , source : Config.source };
        // io.socket.post('/api/v1/user/subscribe');
    }
}

export const updateUserLoginStatus = (boolean) => {
    return (dispatch) => {
        dispatch(updateUserLogin(boolean));
    }
};

export const fetchlogin = (Body) => {
    //debugger
    // console.log('fetchlogin In:',Body);
    return (dispatch) => {
        fetchObject(`user/login`, "", {
            method: "POST",
            body: JSON.stringify(Body)
        })
            .then((response) => {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                // console.log(json);
                if (json.token) {
                    dispatch(confirmUserLoading(false));
                    localStorageUtility('set', 'myData', JSON.stringify(json));
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // console.log('data:', data);
                dispatch(handleLoggedIn(data, Body));
            }, (error) => {
                // 錯誤狀態判斷 顯示
                // console.log(error);
                switch(error.message) {
                    case "LOGIN_MISMATCH":
                        window.alert('帳號密碼有誤')
                        dispatch(confirmUserLoading(true));
                        break;
                    default:
                        window.alert('登入操作有誤，請重新操作');
                        history.replace('/login');
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const fetchLoginFB = (fbResp, body = {}, userDevice) => {
    //debugger
    // console.log('fetchlogin In:',Body);
    return (dispatch) => {
        fetchObject(`user/login_fb`, "", {
            method: 'post',
            body: JSON.stringify({ 
                access_token: fbResp.accessToken, 
                agent: userDevice.agent, 
                playerId: userDevice.playerId 
            }),
        })
            .then((response) => {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                // console.log(json);
                if (json.token) {
                    localStorageUtility('set', 'myData', JSON.stringify(json));
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // console.log('data:', data);
                if (data.register) {
                    //debugger
                    dispatch(handleLoggedIn({ ...data, type: 'facebook', redirectUrl: body.redirectUrl }, body));
                } else {
                    localStorageUtility('set', 'fbData', JSON.stringify({ bonny: data, fb: fbResp }))
                    userDevice && userDevice.agent && localStorageUtility('set', 'agent', userDevice.agent)
                    userDevice && userDevice.playerId && localStorageUtility('set', 'playerId', userDevice.playerId)
                    // path flow: register -> myInfo (no Login)
                    window.location.href = '/register';
                }
            }, (error) => {
                // 錯誤狀態判斷 顯示
                // console.log(error);
                switch(error.message) {
                    case "LOGIN_MISMATCH":
                        window.alert('帳號密碼有誤')
                        dispatch(confirmUserLoading(true));
                        break;
                    default:
                        window.alert('登入操作有誤，請重新操作');
                        history.replace('/login');
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}
