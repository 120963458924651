import React, { Component } from "react";
import { Wrapper } from './styled';
class Banner extends Component {
    
    render() {
        const { data } = this.props;
        return (
            <Wrapper>
                <div
                    style={{
                        backgroundImage: 
                            `url(${data && data.url.length !== 0 ? data['url'][0] : "/assets/images/DefaultBgimg.jpg"})`,
                        width: '100%',
                        height: '0',
                        paddingBottom: 50 + '%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                    }}
                ></div>
            </Wrapper>
        );

    }
}

export default Banner