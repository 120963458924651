import React, { Component } from 'react';
import { Wrapper } from './styled';
import { Modal } from './contract';
import ModalContent from "../ModalContent";

class Checkbox extends Component {

    render() {
        return (
            <Wrapper>
                <input className="ckbox" type="checkbox" 
                    onClick={this.props.onClick} 
                    value={this.props.checked} 
                />
                <span className="ckctext">我已閱讀並接受
                <Modal
                    title="《個資保護聲明》"
                    btnClass="ckcmodalbox"
                    btnText="《個資保護聲明》"
                    modalContent={
                        <ModalContent/>
                    }
                />
                </span>
            </Wrapper>
        );
    }
}

export default Checkbox;
