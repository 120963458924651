import styled from "styled-components";

export const Wrapper = styled.header`
    text-align: center;
    position: relative;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 57px;
    .logo {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin: auto;
        height: 100%;
    }
    .btn {
        width: 30px;
        height: 30px;
        background: #fff;
    }
    .navbar {
        background: #da263c;
        color: #fff;
        overflow: hidden;
        width: 100vw;
        height: 57px;
        display: flex;
        position: relative;
        // justify-content:space-between;
        .returnPage, .menu{
            color: #f2f2f2;
            text-decoration: none;
            text-align: center;
        }
        .returnPage {
            cursor: pointer;
            // max-width: 5rem;
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 57px;
            position: absolute;
            left: 20px
            @media (max-width: 480px){
                left: 10px;
            }
            i {
                width: 1rem;
                margin-right: .2rem;
                font-size: 1.2rem;
                line-height: 57px;
                @media (max-width: 480px) {
                    font-size: 1.1rem;
                }
            }
            span{
                flex: 1;
                font-size: 1rem;
                font-weight: bold;
                line-height: 57px;
                text-align: left;
                @media (max-width: 480px) {
                    font-size: .9rem;
                }
            }
        }
        a.logo {
            max-width: 153px;
            flex: 7;
            height: 57px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            img {
                height: 100%; 
            }
        }
        span.menu {
            width: 30px;
            height: 57px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            align-items: center;
            position: absolute;
            right: 20px;
            // @media (max-width: 480px){
            //     right: 10px;
            // }
            div {
                flex: 1;
                position: relative;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    // .navbar a {
    //     // float: right;
    //     display: block;
    //     color: #f2f2f2;
    //     text-align: center;
    //     padding: 14px 16px;
    //     text-decoration: none;
    //     font-size: 16px;
    // }

    // .navbar a.more {
    //     float: right;
    //     position: relative;
    //     // top: 4px;
    // }

    // .navbar ul {
    //     margin: 8px 0 0 0;
    //     list-style: none;
    // }

    // .returnPage{
    //     position: relative;
    //     left: 20px;
    //     line-height: 57px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     i{
    //         flex: 1;
    //     }
    //     span{
    //         flex: 1;
    //         font-size: 16px;
    //         font-weight: bold;
    //         line-height: 57px;
    //     }
    // }

    // .open-slide{
    //     right: 20px;
    // }

    @media (max-width: 480px){
        // .returnPage {
        //     left: 6px;
        // }
        // .returnPage span{
        //     font-size: 14px;
        // }
        // .open-slide {
        //     right: 0px;
        // }
    }
`;

export const SideNav = styled.div`
    position:fixed;
    right:-2px;
    height: calc( 100vh - 77px );
    width: ${props => (props.menuOpen ? "350px" : 0)};
    // float: right;
    position: fixed;
    right:-2px
    background-color: #f9fafb;
    overflow-x: hidden;
    padding-top: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, .87);
    -moz-box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, .87);
    box-shadow: 5px 7px 20px 0px rgba(0, 0, 0, .87);
    z-index: 100;
    a, .menuLink {
        width: calc( 100% - 60px );
        height: 70px;
        overflow: hidden;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        color: #da263c;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        transition: 0.3s;
        margin: 0 15px 0 35px;
        border-width: 0; 
        background-color: unset;
        border-bottom: 1px solid #da263c;
        position: relative;
        padding: 0;
        img {
            width: 50px;
            height: 50px;
            margin-left: 20px;
            // position: absolute;
            // left: 20px;
            // top: calc( ( 100% - 54px ) / 2 );
            // top: 0;
            // bottom: 0;
            // margin: auto;
            
            // border-radius: 999rem;
            // overflow: hidden;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cc283b+0,af1e2e+100 */
            // background: #cc283b; /* Old browsers */
            // background: -moz-linear-gradient(top, #cc283b 0%, #af1e2e 100%); /* FF3.6-15 */
            // background: -webkit-linear-gradient(top, #cc283b 0%,#af1e2e 100%); /* Chrome10-25,Safari5.1-6 */
            // background: linear-gradient(to bottom, #cc283b 0%,#af1e2e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc283b', endColorstr='#af1e2e',GradientType=0 ); /* IE6-9 */
        }
        .btn-close {
            position: absolute;
            top: 0;
            right: 22px;
            font-size: 36px;
            margin-left: 50px;
        }
        p{
            flex: 1;
            text-align: center;
            // line-height: 70px;  
        }
        &:hover {
            font-weight: bold;
        }

    }
    @media (max-width: 480px) {
        width: ${props => (props.menuOpen ? "65%" : 0)};
        a, .menuLink {
            height: 48px;
            // line-height: 48px;
            img{
                width: 36px;
                height: auto;
                left: 6px;
                // top: 6px;
            }
            p {
               // line-height: 48px; 
            }
        }
    }
    @media (max-width: 320px) {
        width: ${props => (props.menuOpen ? "75%" : 0)};
        padding-top: 22px;
        a, .menuLink {
            // line-height: 42px;
            height: 42px;
            img {
                width: 30px;
            }
            p {
               // line-height: 42px; 
            }
        }
    }
`;
export const HamburgerIcon = styled.div`
    width: 30px;
    height: 22px;
    position: relative;
    display: block;
    padding: 0;
    // top: 3px;
    .line {
        display: block;
        background: #ecf0f1;
        width: 30px;
        height: 4px;
        position: absolute;
        left: 0;
        border-radius: 4px;
        transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        &.line-1 {
            top: calc( 0% - 4px );
        }
        &.line-2 {
            top: calc( 50% - 4px );
        }
        &.line-3 {
            top: calc( 100% - 4px );
        }
    }
    &.active {
        .line-1 {
            transform: translateY(10.5px) translateX(0) rotate(45deg);
            -webkit-transform: translateY(10.5px) translateX(0) rotate(45deg);
            -moz-transform: translateY(10.5px) translateX(0) rotate(45deg);
        }
        .line-2 {
            opacity: 0;
        }
        .line-3 {
            transform: translateY(-11.5px) translateX(0) rotate(-45deg);
            -webkit-transform: translateY(-11.5px) translateX(0) rotate(-45deg);
            -moz-transform: translateY(-11.5px) translateX(0) rotate(-45deg);
        }
    }
`;
