import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Wrapper, 
    LabelWrapper 
} from './styled';
import OccupationSelect from '../../../../component/Register/OccupationSelect';
import { 
    SkillLevelSelect, 
    Select 
} from '../../../../component/Common';
import moment from 'moment-timezone';
import { CityData } from "../../../../component/SelectLocation/tw-city";
import { 
    sendVerifyMobile,
    verifyMobile,
    shouldMobilelVerifyTrue,
    setMobileVerifyMessage
} from '../../../../actions';
import store from '../../../../store';

class ModalContent extends Component {
    makeCityOption() {
        const counties = [
            {
                value: "居住縣市",
                disabled: false
            }
        ]
        CityData.counties.map(value => counties.push({ value: value }));
        return counties;
    }

    // 年
    listYears() {
        var years = [];
        const now = moment().format('YYYY');
        const short = now - 10;
        const long = now - 87;
        for (let i = short; i >=long ; i--) {
            years.push({
                val: `${i}`,
                text: `西元 ${i}年`
            });
        }
        return years;
    }

    // 月
    listMonths() {
        var months = [];
        for (let i = 1; i <= 12; i++) {
            months.push({
                val: `${i}`,
                text: `${i}月`
            });
        }
        return months;
    }

    // 日
    listDays() {
        var days = [];
        const getYearMonth = this.props.value + '-' + this.props.value2;
        const day = moment(getYearMonth, "YYYY-MM").daysInMonth();
        for (let i = 1; i <= day; i++) {
            days.push({
                val: `${i}`,
                text: `${i}日`
            });
        }
        return days;
    }

    render() {
        const {
            type,
            onChange,
            onChange2,
            onChange3,
            value,
            value2,
            value3,
            label,
            label2,
            label3,
            note,
            name,
            name2,
            placeholder,
            placeholder2,
            hobbyList,
        } = this.props;
        const getYears = this.listYears();
        const yearsList = getYears.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const getMonths = this.listMonths();
        const monthsList = getMonths.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const getDays = this.listDays();
        const daysList = getDays.map(({ text, val }, i) => (
            <option key={i} value={val}>
                {text}
            </option>
        ));

        const cityOptions = this.makeCityOption();

        return (
            <Wrapper>
                <div className="form-control">
                    {name === 'badminton_level' && (
                        <SkillLevelSelect
                            value={value}
                            levels={this.props.levels}
                            onChange={e => onChange(e)}
                        />
                    )}
                    {name === 'occupation' && (
                        <OccupationSelect
                            occupation={this.props.occupations}
                            value={value}
                            name={name}
                            onChange={e => onChange(e)}
                        />
                    )}
                    {name === 'hobby' && (
                        <LabelWrapper>
                            <label key={0}>{label}</label>
                            {hobbyList &&
                                hobbyList.map((label, i) => {
                                    // this.props.value 是現在選到得值 value 
                                    // value 應該會和 label.id相同 (label is from hobbyList)
                                    return (
                                        <LabelButton
                                            key={i}
                                            currentSelectHobby={value}
                                            hobbyId={label.id}
                                            text={label.name}
                                        />
                                    );
                                })}
                        </LabelWrapper>
                    )}
                    {name === 'gender' && (
                        <LabelWrapper>
                            <label key={0}>{label}</label>
                            <div style={{display: `flex`, alignItems: `center`}} onChange={e => onChange(e)}>
                                <input type="radio" name="gender" value="1" style={{width: `auto`}}/><span style={{marginRight: `1em`,marginLeft: `5px`}}>男</span>
                                <input type="radio" name="gender" value="2" style={{width: `auto`}}/><span style={{marginRight: `1em`,marginLeft: `5px`}}>女</span>
                            </div>
                        </LabelWrapper>
                    )}
                    {name === 'birthday' &&
                            [
                                <label key={0}>{label}</label>,
                                <select name='year' onChange={e => onChange(e)} value={value} style={{paddingLeft:`1em`}}>
                                    <option key={1} value='0'>
                                        請選擇西元年
                                    </option>
                                    {yearsList}
                                </select>,
                                label2 && <label key={2}>{label2}</label>,
                                <select name='month' onChange={(e) => onChange2(e)} value={value2} style={{paddingLeft:`1em`}}>
                                    <option key={3} value='0'>
                                        請選擇月份
                                    </option>
                                    {monthsList}
                                </select>,
                                label3 && <label key={4}>{label3}</label>,
                                <select name='day' onChange={(e) => onChange3(e)} value={value3} style={{paddingLeft:`1em`}}>
                                    <option key={5} value='0'>
                                        請選擇日期
                                    </option>
                                    {daysList}
                                </select>,
                            ]
                    }
                    {name === 'home' &&
                            [
                                <label key={0}>{label}</label>,
                                <Select
                                    name='city'
                                    value={value}
                                    options={cityOptions}
                                    onChange={(e) => onChange(e)}
                                />,
                                label2 && <label key={1}>{label2}</label>,
                                <input name="home" type="text" value={value2} placeholder="請輸入地址" onChange={(e) => onChange2(e)} />,
                            ]
                    }
                    {name === 'phone' && 
                        <LabelWrapper key={0}>
                            <label>{label}</label>
                            <MobileVerification
                                phone={this.props.value}
                                sendVerifyMobile={this.props.sendVerifyMobile}
                                verifyMobile={this.props.verifyMobile}
                                isMobileVerifySuccess={this.props.isMobileVerifySuccess}
                                message={this.props.message}
                                phoneVerified={this.props.phoneVerified}
                                userId={this.props.userId}
                                onChange={(e) => onChange(e)}
                            />
                        </LabelWrapper>
                    }
                    {
                        name !== 'occupation' &&
                        name !== 'badminton_level' &&
                        name !== 'hobby' &&
                        name !== 'gender' &&
                        name !== 'birthday' &&
                        name !== 'home' &&
                        name !== 'phone' &&
                        [
                            <label key={0}>{label}</label>,
                            <input
                                key={1}
                                type={type || 'text'}
                                name={name}
                                value={value}
                                onChange={e => onChange(e)}
                                placeholder={placeholder}
                            />,
                            label2 && <label key={2}>{label2}</label>,
                            name2 && <input
                                key={3}
                                type={type || 'text'}
                                name={name2}
                                value={value2}
                                onChange={e => onChange(e)}
                                placeholder={placeholder2}
                            />,
                        ]}
                    <p>{note}</p>
                </div>
            </Wrapper>
        );
    }
}

// labelButton 用 map 在跑，每個 hobby 會有一個自己的 labelButton
// 用 global variable : hobbyIdArr 存
let hobbyIdArr = []
class LabelButton extends Component {
    constructor(props) {
        super(props);
        const isThisHobbySelected = props.currentSelectHobby.indexOf(props.hobbyId) >= 0
        this.state = {
            active: isThisHobbySelected,
            id: null
        };
        if(isThisHobbySelected) hobbyIdArr.push(props.hobbyId)
    }
    handleClick = e => {
        const target = e.target;
        const hobbyId = parseInt(target.value, 10);
        this.setState(
            {
                active: !this.state.active,
                id: hobbyId
            },
            () => {
                if(this.state.active){
                    // 如果 hobbyIdArr 沒有 hobbyId 的話
                    if(hobbyIdArr.indexOf(hobbyId) < 0){
                        hobbyIdArr.push(hobbyId)
                    }
                }else {
                    // 回傳 active 的 hobby 但扣掉 hobbyId 的
                    let newArr = hobbyIdArr.filter((element, index, self) =>{
                        return self.indexOf(element) === index && element !== hobbyId;
                    });
                    hobbyIdArr = newArr
                }
                this.props.onClick(hobbyIdArr);
            }
        );
    };

    render() {
        const { hobbyId, text } = this.props;
        return (
            <button
                className={this.state.active ? 'active' : ''}
                value={hobbyId}
                onClick={e => this.handleClick(e)}
                name="hobby"
            >
                {text}
            </button>
        );
    }
}

// phone verification
class MobileVerification extends Component {
    constructor(props) {
        super(props);
        if (this.props.phoneVerified === true) {
            store.dispatch(shouldMobilelVerifyTrue(true));
        } else {
            store.dispatch(shouldMobilelVerifyTrue(false));
        }
        this.state = {
            userId: this.props.userId,
            phone: this.props.phone,
            prevPhone: '',
            mobileInputIsDisable: 0,
            remindTime: 0,
            message: '',
            prevMessage: '',
            modalIsOpen: false,
            code: '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.message !== "" && this.props.message !== prevProps.message) {
            let message;
            switch (this.props.message) {
                case 'SEND_SUCCESS':
                    message = '已發送驗證碼至您的手機號碼：' + this.state.phone;
                    break;
                case 'NO_CODE':
                    message = '未輸入驗證碼';
                    break;
                case 'NO_PHONE_NUMBER':
                    message = '手機號碼未輸入';
                    break;
                case 'INCORRECT_CODE':
                    message = '驗證碼不正確，請重新輸入';
                    break;
                case 'CODE_EXPIRED':
                    message = '驗證碼過期，請重新驗證';
                    break;
                case 'PHONE_USED':
                    message = '此手機號碼已被註冊，請登入或重新輸入';
                    this.handleMobileVerifyCancel();
                    break;
                case 'SEND_FAIL':
                    message = '簡訊發送失敗，請重新操作';
                    break;
                case 'VERIFY_SUCCESS':
                    this.countDown(2);
                    this.props.onChange(this.state.phone);
                    message = '恭喜，已成功驗證您的手機！';
                    break;
                default:
                    message = '未知錯誤，請重新操作或聯絡客服';
                    break;
            }
            this.setState({
                prevMessage: this.props.message,
                message: message,
            })

            alert(message);
            store.dispatch(setMobileVerifyMessage(''));
        }
    }

    countDown = (second) => {
        this.setState({
            remindTime: second,
        });
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            let remindTime = this.state.remindTime;
            const setRemindTime = remindTime -= 1;
            this.setState({
                remindTime: setRemindTime,
            });
            if (this.state.remindTime <= 0) {
                clearInterval(this.interval);
            }
        }, 1000);
    }

    showTime = () => {
        const timer = moment()
            .startOf('day')
            .add(this.state.remindTime, 'second');
        return timer.format('ss');
    }

    mobileNumberVerification = (mobile) => {
        const mobileRule = /^[09]{2}[0-9]{8}$/;
        if (mobile !== '' && String(mobile).replace(/\s+/g, "").length !== 0 && mobile.search(mobileRule) !== -1){
            return true;
        } else {
            return false;
        }
    }

    handleMobileVerifySend = () => {
        const { phone, userId, prevPhone, mobileInputIsDisable, prevMessage } = this.state;
        let message;
        if (this.props.phoneVerified === false && this.props.isMobileVerifySuccess === false && this.showTime() === '00' ){
            if (this.mobileNumberVerification(phone)){
                if (phone === prevPhone && mobileInputIsDisable === 1) {
                    if (prevMessage !== 'SEND_SUCCESS' && prevMessage !== '') {
                        this.handleMobileVerifyCancel();
                        message = this.state.message;
                    } else {
                        message = '兩次手機號碼輸入相同，請輸入驗證碼或變更手機號碼';
                        // this.handleMobileVerifyCancel();
                        this.countDown(15);
                        this.setState({
                            prevPhone: '',
                            mobileInputIsDisable: 1,
                        });
                    }
                } else {
                    this.props.sendVerifyMobile({phone,userId});
                    this.countDown(30);
                    this.setState({
                        prevPhone: phone,
                        mobileInputIsDisable: 1,
                    });
                }
            } else {
                message = '手機號碼格式有誤，請重新輸入';
                this.setState({
                    mobileInputIsDisable: 0,
                })
            }
        } else if(this.props.isMobileVerifySuccess || this.props.phoneVerified){
            if (this.props.phone !== phone ){
                if (this.mobileNumberVerification(phone)){
                    this.props.sendVerifyMobile({phone,userId});
                    this.countDown(30);
                    // message = '已發送驗證碼至您的手機號碼：' + phone;
                    this.setState({
                        mobileInputIsDisable: 1,
                    })
                } else {
                    message = '手機號碼格式有誤，請重新輸入';
                    this.setState({
                        mobileInputIsDisable: 0,
                    })
                }
            } else {
                message = '您已完成此手機號碼的驗證'
            }
        } else {
            message = '操作過快，請等候' + this.showTime() + '秒';
        }
        if(message){
            alert(message);
        }
    }

    handleMobileVerifySubmit = (e) => {
        const { phone, code, userId } = this.state;
        this.props.verifyMobile({phone, code, userId});
    }

    handleMobileVerifyCancel = (e) => {
        this.countDown(3);
        this.setState({
            phone: '',
            code: '',
            mobileInputIsDisable: 1,
        });
        store.dispatch(shouldMobilelVerifyTrue(false));
    }

    handleInputChange = (event) => {
        // 從 event object 拿到 target
        const target = event.target;
        // 從 target.type 可以知道欄位的 type
        // 或從 target.value 取出輸入的欄位值
        const value = target.value;
        // 從 target.name 得到該欄位設定的 name
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { 
            mobileInputIsDisable,
            phone
        } = this.state
        return (
            <div>
                <div className="flex alignCenter">
                    <input 
                        name='phone' 
                        type="text" 
                        value={phone} 
                        onChange={this.handleInputChange} 
                        disabled={this.props.isMobileVerifySuccess ? "disabled" : ""}
                        placeholder="請輸入您的行動電話" 
                        style={{ width:'calc(95% - (14px * 8))' ,borderRadius: `5px 0 0 5px` ,borderRight: `none`}}
                    />
                    <div 
                        className="mobileVerifyButton" 
                        onClick={this.handleMobileVerifySend}
                    >
                        {this.props.phoneVerified && mobileInputIsDisable !== 1?
                            "已驗證"
                            : 
                            this.showTime() === '00' && mobileInputIsDisable !== 1 ? 
                                "發送驗證碼" 
                                :
                                this.showTime() !== '00' && mobileInputIsDisable !== 1 ? 
                                    "發送驗證碼(" + this.showTime() + ")" 
                                    :
                                        this.showTime() !== '00' && mobileInputIsDisable === 1 ? 
                                            "請稍候 " + this.showTime() + "秒" 
                                            :
                                            this.props.isMobileVerifySuccess ?
                                                "驗證完成"
                                                :
                                                "發送驗證碼"
                        }
                    </div>
                </div>
                {this.props.phoneVerified !== true || this.props.isMobileVerifySuccess !== true ?
                    <div className="flex alignCenter" style={{marginTop: `10px`, width: `95%`}}>
                        <input 
                            name='code' 
                            type="text" 
                            value={this.state.code} 
                            onChange={this.handleInputChange} 
                            disabled={this.props.isMobileVerifySuccess ? "disabled" : ""}
                            placeholder="請輸入驗證碼" 
                            style={{flex: `1`}}
                        />
                        <div 
                            className="mobileVerifySubmit" 
                            onClick={this.handleMobileVerifySubmit}
                        >
                            送出
                        </div>
                        <div className="mobileVerifyCancel" onClick={this.handleMobileVerifyCancel}>變更手機號碼</div>
                    </div>
                    :
                    <div className="mobileVerifyCancel" onClick={this.handleMobileVerifyCancel} style={{width: `95%`, textAlign: `right`}}>變更手機號碼</div>
                }
            </div>
        );
    }
}

// export default ModalContent;

const mapStateToProps = state => ({
    isMobileVerifySuccess: state.registerReducer.shouldMobilelVerifyTrue,
    message: state.registerReducer.setMobileVerifyMessage,
});

const mapDispatchToProps = (dispatch) => ({
    sendVerifyMobile: (mobile) => {
        dispatch(sendVerifyMobile(mobile));
    },
    verifyMobile: (code) => {
        dispatch(verifyMobile(code));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);