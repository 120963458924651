import React from 'react';
import { connect } from 'react-redux';
import { fetchUserInfo, getAffiliateStatistic, getAffiliateHistory} from '../../../actions';
import { Wrapper } from './styled';
import Banner from "../Banner";
import { Modal } from "../../Common";
import { getSharedLink, copyLink, shareToLine, shareToFacebook } from '../../../utilities';

class Affiliates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            monthSelectData: 0,
            text: '展開',
        };
    }

    componentDidMount(){
        this.props.getUserInfo();
        this.props.getAffiliateStatistic();
        this.props.getAffiliateHistory();
    }

    // set share url
    componentDidUpdate(prevProps, prevState){
        if(this.props.userInfo && this.props.userInfo.affiliateId &&
            this.props.userInfo.affiliateId.code &&
            prevProps.userInfo.affiliateId !== this.props.userInfo.affiliateId ){
            var urlArray = window.location.href.split("/");
            var result = urlArray[0] + "//" + urlArray[2];
            let affiliateCode = this.props.userInfo.affiliateId.code;
            let introUrl = `${result}/rackets_lobby?aid=${affiliateCode}`;
            let homeUrl = `${result}/?aid=${affiliateCode}`;
            this.setState({ affiliateIntroUrl : `${introUrl}` });
            this.setState({ affiliateHomeUrl : `${homeUrl}` });
        }
    }
            
    handleShareToLine = (title , urlParam) => {
        const url = this.handleGetSharedLink(urlParam)

        let text = '波力首創「月繳或年繳」購買羽球拍，再享三年斷拍換新$599 保障，快看哪支球拍適合你'
        shareToLine(text, url)

    }
    
    handleShareToFacebook = (title, urlParam) => {
        const url = this.handleGetSharedLink(urlParam);
        let description = '&description=波力首創「月繳或年繳」購買羽球拍，再享三年斷拍換新$599 保障，快看哪支球拍適合你'
        let titleText = `&title=${title}`

        shareToFacebook(titleText, url, description)

    }

    handleGetSharedLink = (urlParam) => {
        

        let affiliateCode = this.props.userInfo && this.props.userInfo.affiliateId && this.props.userInfo.affiliateId.code;
        if(affiliateCode === undefined) {
            console.log("no affiliateCode"); 
            return
        }
        let search = '?aid=' + affiliateCode
        return getSharedLink(urlParam, true, search)

    }
    
    handleCopyLink = (urlParam) => {

        let affiliateCode = this.props.userInfo && this.props.userInfo.affiliateId && this.props.userInfo.affiliateId.code
        if(affiliateCode === undefined) {
            console.log("no affiliateCode"); 
            return
        }
        let search = '?aid=' + affiliateCode;

        copyLink(urlParam, true, search)
    }

    toggleShowHistoryList = (dom) => {
        let domElement = document.querySelector(`#${`historyDataList` + dom}`);

        if(domElement.className === "hide"){
            domElement.classList.remove("hide");
            this.setState({ text : `收合` })  
        }
        else{
            domElement.classList.add("hide"); 
            this.setState({ text : `展開` }) 
        }
    }                               


    render() {
        const { affiliateStatisticData, affiliateHistoryData, } = this.props;
        const thirtyDaysData = affiliateStatisticData.period;
        const totalData = affiliateStatisticData.total;
        const affiliateHistoryDataArray = Array.from(affiliateHistoryData);

        return ([
            <Banner key={0} />,
            <Wrapper key={1}>
                <div>
                    <h1> 分銷商專區 </h1>

                    <section className="affiliateShare">
                        <p style={{marginBottom: `10px`,color: `#aeaeae`}}> 我的分享網址</p>
                        <p>羽你蓮心</p>

                        <div className="affiliateShare__section">
                            <span> { this.state.affiliateIntroUrl ? this.state.affiliateIntroUrl : "" }</span>

                            <Modal
                                title="分享到"
                                // modalIsOpen={this.state.modalIsOpen}
                                noButton={true}
                                btnTheme="one"
                                notButtonStyle={{ float: "right" }}
                                modalContent={
                                    <div style={{ textAlign: 'center', display: 'flex', }}>
                                        {/* Line */}
                                        <div 
                                            onClick={ () =>{ this.handleShareToLine('羽你蓮心' , "/rackets_lobby")} } 
                                            style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/lineShareicon.png" alt=""/>
                                                <br />
                                                <div>Line</div>
                                        </div>
                                        {/* Facebook */}
                                        <div 
                                            onClick={ () =>{ this.handleShareToFacebook('羽你蓮心' , "/rackets_lobby")} } 
                                            style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/fbShareicon.png" alt=""/>
                                                <br />
                                                <div>Facebook</div>
                                        </div>
                                        {/* CopyLink */}
                                        <div 
                                            onClick={ ()=>{ this.handleCopyLink('/rackets_lobby') } } 
                                            style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/linkShareicon.png" alt=""/>
                                                <br />
                                                <div>複製連結</div>
                                        </div>

                                    </div>
                                }
                            >
                                {/* onCick 的 icon 放這邊 */}
                                {/* <span className="icon share" 
                                onClick={this.openModal}>
                                    <small>分享</small>
                                </span> */}
                                <button onClick={this.openModal}>立即分享</button>

                            </Modal>
                        </div>

                        <p>雲羽集</p>

                        <div className="affiliateShare__section">
                            <span> { this.state.affiliateHomeUrl ? this.state.affiliateHomeUrl : "" }</span>
                            <Modal
                                title="分享到"
                                // modalIsOpen={this.state.modalIsOpen}
                                noButton={true}
                                btnTheme="one"
                                notButtonStyle={{ float: "right" }}
                                modalContent={
                                    <div style={{ textAlign: 'center', display: 'flex', }}>
                                        {/* Line */}
                                        <div 
                                            onClick={ ()=>{ this.handleShareToLine('雲羽集' , '/') } } 
                                            style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/lineShareicon.png" alt=""/>
                                                <br />
                                                <div>Line</div>
                                        </div>
                                        {/* Facebook */}
                                        <div 
                                            onClick={ () =>{ this.handleShareToFacebook('雲羽集' , '/')} } 
                                            style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/fbShareicon.png" alt=""/>
                                                <br />
                                                <div>Facebook</div>
                                        </div>
                                        {/* CopyLink */}
                                        <div 
                                            onClick={ ()=>{this.handleCopyLink('/')} }
                                            style={{ cursor: "pointer", textAlign: "center", display: 'inline-block', flex: '1', }}>
                                                <img src="/assets/images/linkShareicon.png" alt=""/>
                                                <br />
                                                <div>複製連結</div>
                                        </div>
                                    </div>
                                }
                            >
                                <button onClick={this.openModal}>立即分享</button>
                            </Modal>
                        </div> 
                    </section>

                    <hr style={{width: `calc(100% - 40px)`,height: `1px`,margin: `0 20px`, border: `0`, borderBottom: `1px solid rgba(0,0,0,.5)`}}/>
                    
                    <section className="affiliateAnalyze">
                        <p style={{marginBottom: `10px`,color: `#aeaeae`}}>我的統計資訊</p>

                        <p> 活躍使用者 </p> 
                        <div className="affiliateAnalyze__people">
                            <p>
                                <span> 近30日</span> 
                                <span className="affiliateAnalyze__number"> 
                                    {thirtyDaysData && thirtyDaysData.visit}
                                    <span style={{fontSize: `1rem`,color: `#000`,marginLeft: `.5rem`,}}>/人次</span>
                                </span>
                            </p>

                            <p>
                                <span> 累計</span>   
                                <span className="affiliateAnalyze__number"> 
                                    {totalData && totalData.visit}
                                    <span style={{fontSize: `1rem`,color: `#000`,marginLeft: `.5rem`,}}>/人次</span>
                                </span>
                            </p>
                        </div>
                        
                        <hr style={{width: `100%`,height: `1px`, border: `0`, borderBottom: `2px solid rgba(255,255,255,1)`}}/>

                        <p> 不重複使用者 </p> 
                        <div className="affiliateAnalyze__people">
                            <p>
                                <span> 近30日</span> 
                                <span className="affiliateAnalyze__number"> 
                                    {thirtyDaysData && thirtyDaysData.firstVisit}
                                    <span style={{fontSize: `1rem`,color: `#000`,marginLeft: `.5rem`,}}>/人次</span>
                                </span>
                            </p>

                            <p>
                                <span> 累計</span>   
                                <span className="affiliateAnalyze__number"> 
                                    {totalData && totalData.firstVisit}
                                    <span style={{fontSize: `1rem`,color: `#000`,marginLeft: `.5rem`,}}>/人次</span>
                                </span>
                            </p>
                        </div>

                        <hr style={{width: `100%`,height: `1px`, border: `0`, borderBottom: `2px solid rgba(255,255,255,1)`}}/>
                        
                        <p> 成交訂單 </p> 
                        <div className="affiliateAnalyze__amount">
                            <p>
                                <span> 近30日</span> 
                                <span className="affiliateAnalyze__number">
                                    <span>$</span>
                                    {thirtyDaysData && thirtyDaysData.orderAmount}
                                    <span style={{marginLeft: `.5rem`}}>({thirtyDaysData && thirtyDaysData.orderNumber} 筆)</span>
                                </span>
                            </p> 
                            <p>
                                <span> 累計</span>  
                                <span className="affiliateAnalyze__number">
                                    <span>$</span>
                                    {totalData && totalData.orderAmount} 
                                    <span style={{marginLeft: `.5rem`}}>({totalData && totalData.orderNumber} 筆)</span>
                                </span>
                            </p>                        
                        </div>
                    </section>

                    <hr style={{width: `calc(100% - 40px)`,height: `1px`,margin: `0 20px`, border: `0`, borderBottom: `1px solid rgba(0,0,0,.5)`}}/>
                    <section className="affiliateHistory">
                        <p style={{marginBottom: `10px`,color: `#aeaeae`}}>對帳報表</p>
                        {/*
                        <div style={{marginTop: `20px`}}>
                            <div className="affiliateHistory__search">
                                <div className="affiliateHistory__search__title">起始日期</div>
                                <div className="affiliateHistory__search__input">
                                    <input 
                                        id="startDate" 
                                        name="startDate" 
                                        type="text" 
                                        value={this.state.startDate || ''} 
                                        placeholder="例如:20190101" 
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="affiliateHistory__search">
                                <div className="affiliateHistory__search__title">結束日期</div>
                                <div className="affiliateHistory__search__input">
                                    <input 
                                        id="endDate" 
                                        name="endDate" 
                                        type="text" 
                                        value={this.state.endDate || ''} 
                                        placeholder="例如:20191201" 
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div class="affiliateHistory__searchButton">
                                <div></div>
                                <button>搜尋</button>
                            </div>
                        </div>
                        */}
                    {affiliateHistoryDataArray.map((historyData, i) => ( 
                        // console.log(historyData),
                        //<span onClick={ ()=> {this.toggleShowHistoryList(`list${i}`) } }>開關</span>/
                        <div className="affiliateHistory__list" key={i} >
                            <div className="affiliateHistory__outside" style={{marginBottom: `10px`,color: `#000`}}>
                                <div className="affiliateHistory__title">
                                    {historyData.key.substring(0, 4)}年 
                                    {historyData.key.slice(-2)}月
                                </div>
                            </div>
                            <div id={"historyDataList" + parseInt(i + 1)} className="hide">
                                <div className="affiliateHistory__outside">
                                    <div className="affiliateHistory__title">累計成交筆數</div>
                                    <div className="affiliateHistory__content" style={{color: `#da263c`,}}>
                                        <span>{historyData.data && historyData.data.orderNumber}</span>
                                        <span>筆</span>
                                    </div>
                                </div>
                                <div className="affiliateHistory__outside">
                                    <div className="affiliateHistory__title">累計分潤金額</div>
                                    <div className="affiliateHistory__content" style={{color: `#da263c`,}}>
                                        <span>$</span>
                                        <span>{historyData.data && historyData.data.orderAmount}</span>
                                    </div>
                                </div>
                            </div> 
                            <div className="affiliateHistory__slide">
                                
                                <button id={"historyButton" + parseInt(i + 1)} onClick={ ()=> {this.toggleShowHistoryList(parseInt(i + 1)) } }>
                                    {this.state.text}
                                    <i className="material-icons">
                                        { this.state.text === '展開' ? 'arrow_drop_down' : 'arrow_drop_up' }
                                    </i>
                                </button>
                            </div> 
                        </div>
                    ))} 
                    </section>

                </div>
            </Wrapper>
        ]);
    }
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        userInfo: state.myInfoReducer.userInfo,
        affiliateStatisticData: state.affiliateReducer.getAffiliateStatisticData,
        affiliateHistoryData: state.affiliateReducer.getAffiliateHistoryData,
    }
};
const mapDispatchToProps = {
    getUserInfo: fetchUserInfo,
    getAffiliateStatistic: getAffiliateStatistic,
    getAffiliateHistory: getAffiliateHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);
