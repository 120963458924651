import React, { Component } from "react";
import { FormControl } from "../../Common";

export default class CourtPrice extends Component {

    render() {

        return (
            <FormControl>
                {/* <Select 
                    label={"打球費用："} 
                    options={options} 
                    // onChange={this.props.onChange} 
                    // value={this.props.value}
                     /> */}


                <div className="select">
                    { <label >  {/* htmlFor={name}> */} {"打球費用："}</label>  }
                    <input
                        value={this.props.value}
                        onChange={this.props.onChange}
                        placeholder="請輸入金額"
                        type="number"
                        step="50"
                        min="0"
                        max="5000"
                     />
                </div>
            </FormControl>
        );
    }
}
