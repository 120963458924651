import styled from 'styled-components';

export const Wrapper = styled.div `
    width:100%;
    h2{
      margin: 20px 20px 5px 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }
    select{
        width:100%
    };
    .modal-btn {
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      color: #fff;
      height: 35px;
      margin: 5px;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: #437cae;
      // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
      // text-shadow: 0px -2px #2e5577;
      border-radius: 999rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      &.active {
        background-color: #2e5577;
        color: #eee;
      }
      &:hover {
        background-color: #80a3c1;
        color: #eee;
        border: 2px solid #80a3c1;
        box-shadow: unset;
        text-shadow: unset;
        height: unset;
        min-height: 35px;
      }

      &.red {
        background-color: #da263c;
        // text-shadow: 0px -2px rgb(223,28,44);

        &.active {
          background-color: rgba(162,28,46,1);
          color: #eee;
        }
        &:hover {
          background-color: #d85969;
          color: #eee;
          border: 2px solid #d85969;
        }
      }

      &[disabled] {
        display: none;
        cursor: default;
        border-color: gray;
        color: gray;
      }
      &[disabled]:hover {
        background-color: lightgray;
      }
    }
    .list_button_outLine {
      width: 5rem;
      cursor: pointer;
      font-weight: 400;
      font-size: 0.8rem;
      color: #333;
      margin: 5px;
      background-color: #fff;
      box-shadow: unset;
      text-shadow: unset;
      border-radius: 999rem;
      border: 2px solid #D8D8D8;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 480px){
        width: auto;
        font-size: 12px;
        line-height: 20px;
        border-width: 1px;
      }

      &.active {
        background-color: rgba(162,28,46,1);
        color: #eee;
      }
      &:hover {
        background-color: #d85969;
        color: #eee;
        border: 2px solid #d85969;
      }
    }
`

export const NoticeWrapper = styled.div `
  width: calc( 100% - 12px);
  display: flex;
  align-items: center; 
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  margin: 5px 0 0 0;

  .leftArea {
    flex: 1;
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    flex-wrap: wrap;

    .data {
      flex: 0 0 100%;
      display: flex;
      align-items: center; 
      justify-content: flex-start;
      font-size: .8rem;
    }

    .groupName {
      flex: 0 0 100%;
      display: flex;
      align-items: center; 
      justify-content: flex-start;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .rightArea {
    width: 4rem;
    border-radius: 999rem;
    font-size: 12px;
    background-color: rgb(223,28,44);
    color: #fff;
    padding: 5px 0;
    // box-shadow: inset 0 -0.125rem rgba(0,0,0,0.2);
    // text-shadow: 0px -2px rgb(223,28,44);
    display: flex;
    align-items: center; 
    justify-content: center;

    &:hover {
      -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
      -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
      box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
      color: #ccc;
    }
  }
`