import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Select } from '../../../../component/Common';
import { Wrapper, SortSelect } from './styled';
import { localStorageUtility } from '../../../../utilities';

const sortOptions = [
    {
        text: '日期由新至舊',
        value: 'start desc'
    },
    {
        text: '剩餘人數由多到少',
        value: 'remain desc'
    },
    {
        text: '剩餘人數由少到多',
        value: 'remain asc'
    }
];

class TAB extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sort: "start desc",
        };
    }

    handleSortChange = e => {

        const user = localStorageUtility('getAndParse', 'myData');
        let Tab = localStorageUtility('get', 'Tab');
        this.setState(
            {
                [e.target.name]: e.target.value,
                status_end: (Tab === 'Opening' ? '>': '<'), 
                user: user.id
            },
            () => {
                this.props.onFetchActivity(this.state)
                this.props.updateSearchHostActivity(this.state);
            }
        );
    };

    changeToClosingTab = e => {
        localStorageUtility('set', 'Tab', 'Finished');
        const user = localStorageUtility('getAndParse', 'myData');
        this.props.onFetchActivity({ 'sort': 'start desc', 'status_end': '<', user: user.id })
        this.setState({ 'sort': "start desc" })
        // this.props.handleChangeTab(false)
        // alert('Finished');
    };
    
    changeToOpeningTab = e => {
        localStorageUtility('set', 'Tab', 'Opening');
        const user = localStorageUtility('getAndParse', 'myData');
        this.props.onFetchActivity({ 'sort': 'start desc', 'status_end': '>', user: user.id })
        this.setState({ 'sort': "start desc" })
        // this.props.handleChangeTab(true)
        // alert('Opening');
    };


    render() {
        const { sort } = this.state;
        return (
            <Wrapper >
                <Tabs>
                    <TabList>
                        <Tab onClick={this.changeToOpeningTab}>開團中</Tab>
                        <Tab onClick={this.changeToClosingTab}>已完成/已取消</Tab>
                    </TabList>

                    {/* 開團中 */}
                    <TabPanel>
                        <SortSelect style={{marginRight: `10px`}}>
                            排序
                            <div className="select-group">
                                <Select
                                    options={sortOptions}
                                    name="sort"
                                    value={sort}
                                    onChange={this.handleSortChange}
                                />
                            </div>
                        </SortSelect>
                    </TabPanel>
                    
                    {/* 已完成/已取消 */}
                    <TabPanel>
                        <SortSelect>

                            <div className="selectDiv">
                                {/* 不會 call API  */}
                                <div className="paymentStatusSort">
                                    <div className={this.props.shouldPaidHide ? "check" : ""}>
                                        <label className="hidePaidLabel" htmlFor="hidePaidRadioButton">未收款</label>
                                    </div>
                                    <div className={this.props.shouldPaidHide ? "" : "check"}>
                                        <label className="showAllLabel" htmlFor="showAllRadioButton">全部</label>
                                    </div>
                                </div>
                                
                                <div className="select-group">
                                    <span>排序</span>
                                    <Select
                                        options={sortOptions}
                                        name="sort"
                                        value={sort}
                                        onChange={this.handleSortChange}
                                    />
                                </div>
                            </div>



                        </SortSelect>
                    </TabPanel>
                </Tabs>
            </Wrapper>
        )
    }
}

export default TAB;
