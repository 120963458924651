import styled from 'styled-components';

export const Wrapper = styled.div `
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`

export const Header = styled.div `
    position: relative;
    display: flex;
    align-items:center; 
    justify-content:center;
    text-align: center;
    width: 100%;
    height: 57px;
    background-color: #da263c;

    img {
        width: auto;
        height: 57px;
    }

`

export const Body = styled.div `
    position: relative;
    display: flex;
    align-items:center; 
    justify-content:center;
    text-align: center;
    width: 100%;
    height: calc( 100vh - 57px );
`

export const Content = styled.div `
    position: relative;
    display: block;
    width: 440px;
    height: calc(92% - 57px);
    background-color: #feffff;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    overflow-y: auto;
    overflow-x: hidden;

    backface-visibility:hidden;
    -webkit-backface-visibility:hidden; /* Chrome 和 Safari */
    -moz-backface-visibility:hidden;    /* Firefox */
    -ms-backface-visibility:hidden;     /* Internet Explorer */

    box-shadow: 0 2px 15px 3px rgba(175,175,175,.25);
    div::-webkit-scrollbar { width: 0 !important }
    div::-moz-scrollbar { width: 0 !important }
    div::-ms-scrollbar { width: 0 !important }
    div::-o-scrollbar { width: 0 !important }
    div::scrollbar { width: 0 !important }
    div{ 
        -webkit-overflow-style: none;
        -moz-overflow-style: none;
        -ms-overflow-style: none;
        -o-overflow-style: none;
        overflow-style: none;
        overflow: -webkit-scrollbars-none; 
        overflow: -moz-scrollbars-none; 
        overflow: -ms-scrollbars-none; 
        overflow: -o-scrollbars-none; 
     }
    .banner {
        width: 100%;
    }

    @media (max-width: 480px) {
        width: 100%;
        height: calc( 100vh - 57px );
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }
`