import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from "./styled";
import Header from "../Header";
import MiddleContent from "../MiddleContent";
import Dialog from "../Dialog";
import { Banner, GaSetting } from "../Common";
import { getBannerList } from "../../actions";
import OptionButtons from './OptionButtons';
import { getCookie, setCookie } from '../../utilities';

class Home extends Component {
    constructor(props) {
        super(props);
        let landingPageCode = getCookie('LandingPage') || null;
        this.state = {
            landingPageCode: landingPageCode,
        }
    }
    
    componentDidMount() {
        this.props.getBannerList("frontpage");
        if (!getCookie('LandingPage')) {
            let getRandom = Math.floor(Math.random()*2);
            if(getRandom === 0){
                setCookie( 'LandingPage', 'A', 30);
                this.setState({landingPageCode: 'A'});
            }else{
                setCookie( 'LandingPage', 'B', 30);
                this.setState({landingPageCode: 'B'});
            }
        }
        else{
            this.setState({landingPageCode: getCookie('LandingPage')});
        }
    }

    render() {
        const isIndex = this.props.location.pathname === "/";
        const { banner_frontpage } = this.props.data;
        const pathname = this.props.location.pathname;
        const isOauth = pathname.indexOf('dialog') > -1;

        return (
            <Wrapper key={0} isIndex={isIndex} >
                {
                    isOauth ? 
                        <Dialog key={1} />
                    :
                    isIndex ? (
                    [
                        <GaSetting 
                            key={1}
                            data= {{
                                name: 'componentDidMount', 
                                category: 'index-badminton', 
                                action: 'index-pageView', 
                                label: 'form-' + this.state.landingPageCode,
                            }}
                        />,
                        <Header key={2} 
                            historyLength = {this.props.historyLength}    
                        />,
                        <Banner
                            key={3}
                            data={banner_frontpage}
                            className="index-slide"
                        />,
                        <OptionButtons
                            key={4} 
                            data={{landingPageCode: this.state.landingPageCode}}
                            disableActivitySignUpButton= {this.props.disableActivitySignUpButton}
                        />,
                    ]
                ) : (
                    <MiddleContent key={1} 
                        historyLength = {this.props.historyLength}    
                    />
                )}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    data: state.activity,
    disableActivitySignUpButton: state.activity.disableActivitySignUpButton,
});

const mapDispatchToProps = {
    getBannerList: getBannerList
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
