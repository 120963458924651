import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import moment from 'moment';
import { LoadingIndicator, } from "../../Common";
import { 
    getRacketOrderDetail, 
    cancelProgramOrReplaceRacket 
} from '../../../actions';
import { Wrapper } from './styled';

class CancelProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateReplackData: {},
            id: this.props.match && this.props.match.params && this.props.match.params.id,
        }
    }

    componentDidMount() {
        this.props.getRacketOrderDetail(this.state.id);
        this.setState({
            updateReplackData: {
                action: 'cancel',
                order_id: this.state.id,
                cancel_type: '', //理由
                cancel_note: '', //備註
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isCancelOrReplaceDone) {
            this.props.history.push(`/my_info/rent_contract_detail/${this.state.id}`);
        }
    }

    handleOnChange = (element, e) => {
        let updateReplackData = this.state.updateReplackData;
        if (element === 'cancel_type') {
            const cancel_type_val = e.target.value;
            if (cancel_type_val === '1') {
                document.getElementById("cancel_type_Text").innerHTML = '我已重複申請';
            } else if (cancel_type_val === '2') {
                document.getElementById("cancel_type_Text").innerHTML = '我已申請其他球拍';
            } else if (cancel_type_val === '3') {
                document.getElementById("cancel_type_Text").innerHTML = '其他';
            }
        }
        if (element === 'cancel_note') {
            const cancel_note_val = e.target.value;
            document.getElementById("cancel_note_Text").innerHTML = cancel_note_val;
        }
        var changeValue = '';
        var updateCheck = 0;
        if (element === 'agree') {
            if (updateReplackData.agree) {
                changeValue = false;
            }
            else {
                changeValue = true;
            }

            updateCheck = 1;
        }
        else {
            changeValue = e.target.value;
            updateCheck = 1;
        }
        if (updateCheck === 1) {
            this.setState(prevState => ({
                updateReplackData: {
                    ...prevState.updateReplackData,
                    [element]: changeValue
                }
            }))
        }
    }

    LightBoxCancelProgramOpen(e) {
        var lightboxcancelprogram = document.getElementById("lightbox-CancelProgram");
        lightboxcancelprogram.classList.remove("close");
    }

    LightBoxCancelProgramClose(e) {
        var lightboxcancelprogram = document.getElementById("lightbox-CancelProgram");
        lightboxcancelprogram.classList.add("close");
    }

    send = () => {
        document.getElementById("SendDataButton").disabled = true;
        //send rent
        const { cancelProgramOrReplaceRacket } = this.props;
        let updateReplackData = this.state.updateReplackData;
        let notifyMessage = '';
        var formCheck = 1;
        if (!updateReplackData.cancel_type) {
            notifyMessage = '請選擇取消理由';
            formCheck = 0;
            document.getElementById("SendDataButton").disabled = false;
        }
        else if (updateReplackData.cancel_type && updateReplackData.cancel_type === '3') {
            if (!updateReplackData.cancel_note) {
                notifyMessage = '請確認已填寫其他/備註！';
                formCheck = 0;
                document.getElementById("SendDataButton").disabled = false;
            }
        }

        if (formCheck === 1) //發送訂單
        {
            cancelProgramOrReplaceRacket(updateReplackData);
        }
        else //show message if form value check failed
        {
            alert(notifyMessage);
        }
    }

    render() {
        const { id } = this.state;
        const { data } = this.props;
        const racketOrderDetail = (this.props.data && this.props.data.racketOrderDetail);
        const order_data = data.racketOrderDetail.order && data.racketOrderDetail.order[0];
        const shipment_data = racketOrderDetail.shipment && racketOrderDetail.shipment[0];
        return (
            <Wrapper key={0}>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <h1
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 羽你蓮心專案 申請取消專案
                </h1>
                <form id="rent-form" method="POST" action="" >
                    <div id="RacketDetail_program_content">
                        <div className="title">
                            <h2> 羽你蓮心專案 </h2>
                        </div>
                        {/* #####   申請取消專案   ##### */}
                        {order_data &&
                            <ul id="cancel_program" onChange={this.changeItem}>
                                <li>申請取消專案：</li>
                                <li className="border-style margin-top-20px margin-bottom-20px">
                                    <div className="img-top"
                                        style={{ backgroundImage: `url(${order_data.racket_id.picture})` }}
                                    ></div>
                                    <div className="img-bottom">
                                        {order_data.racket_id.name}
                                    </div>
                                </li>
                                <li className="margin-left-20px color-LightBlack">
                                    申請日期：
                            {moment(new Date()).format('YYYY/MM/DD')}
                                </li>
                                <li className="margin-left-20px color-LightBlack display-flex-mom">
                                    <div className="list-content-flex-son margin-right-5px">
                                        專案時間(起)：
                                {
                                            order_data && order_data.contract_start_time !== 0
                                                ?
                                                moment(parseInt(data ? order_data.contract_start_time : '')).format('YYYY/MM/DD')
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="list-content-flex-son margin-left-5px">
                                        專案時間(訖)：
                                {
                                            (order_data && order_data.contract_end_time !== 0)
                                                ?
                                                moment(parseInt(data ? order_data.contract_end_time : '')).format('YYYY/MM/DD')
                                                :
                                                ''
                                        }
                                    </div>
                                </li>
                                <li className="margin-left-20px color-LightBlack display-flex-mom">
                                    <div className="list-content-flex-son margin-right-5px">
                                        球拍編號：
                                {shipment_data && shipment_data.racket_number}
                                    </div>
                                    <div className="list-content-flex-son margin-left-5px">
                                        目前換拍次數：
                                {order_data.replace_times}
                                    </div>
                                </li>
                                <li className="margin-left-20px margin-top-20px color-LightBlack">取消理由：</li>
                                <li className="margin-left-20px color-LightBlack">
                                    <select id="cancel_type" name="cancel_type" defaultValue="" onChange={(e) => this.handleOnChange("cancel_type", e)}>
                                        <option value="">---請選擇---</option>
                                        <option value="1">我已重複申請</option>
                                        <option value="2">我已申請其他球拍</option>
                                        <option value="3">其他：文字方塊自行填寫</option>
                                    </select>
                                </li>
                                <li className="margin-left-20px margin-top-20px color-LightBlack">其他/備註：</li>
                                <li className="margin-left-20px color-LightBlack">
                                    <textarea style=
                                        {{
                                            width: 100 + "%",
                                            maxWidth: 378 + "px",
                                            height: 150 + "px",
                                            minHeight: 150 + "px",
                                            borderStyle: "solid",
                                            borderWeight: 1 + "px",
                                            borderColor: "rgb(206, 212, 218)",
                                            borderRadius: 0.25 + "rem",
                                            padding: 10 + "px",
                                            boxSizing: "border-box"
                                        }} name="cancel_note" onChange={(e) => this.handleOnChange("cancel_note", e)}></textarea>
                                </li>
                                <li className="button-only-one">
                                    <div className="button" onClick={this.LightBoxCancelProgramOpen}>確認取消專案</div>
                                </li>
                            </ul>
                        }
                    </div>
                </form>

                {/*LightBox ----- 取消專案確認 */}
                <div id="lightbox-CancelProgram" className="lightBox-explain close">
                    <div className="lightBox-explain-Content">
                        <div className="lightBox-explain-Content-inside">
                            <div className="lightBox-inside-close-button" onClick={this.LightBoxCancelProgramClose}>X</div>
                            <div className="LB-C-title">確認取消申請專案</div>
                            <div className="LB-C-Description">
                                <ul>
                                    <li>申請取消日期：{moment(new Date()).format('YYYY/MM/DD')}</li>
                                    <li>專案編號：{id}</li>
                                    <li>球拍編號：{shipment_data && shipment_data.racket_number}</li>
                                    <li>取消球拍：{order_data && order_data.racket_id.name}</li>
                                    <li>專案起訖：
                                    {
                                            (order_data && order_data.contract_start_time !== 0)
                                                ?
                                                moment(parseInt(data ? order_data.contract_start_time : '')).format('YYYY/MM/DD')
                                                :
                                                ''
                                        }
                                        -
                                    {
                                            (order_data && order_data.contract_end_time !== 0)
                                                ?
                                                moment(parseInt(data ? order_data.contract_end_time : '')).format('YYYY/MM/DD')
                                                :
                                                ''
                                        }
                                    </li>
                                    <li><hr /></li>
                                    <li>取消理由：<span id="cancel_type_Text"></span></li>
                                    <li>其他/備註：<span id="cancel_note_Text"></span></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="light-outside-button-group">
                            <button id="SendDataButton" className="light-outside-button-group-check" onClick={this.send}>
                                確認
                            </button>
                            <div className="light-outside-button-group-line"></div>
                            <div className="light-outside-button-group-close" onClick={this.LightBoxCancelProgramClose}>取消</div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.rentReducer.shouldLoadingOpen,
    data: state.rentReducer,
    isCancelOrReplaceDone: state.rentReducer.isCancelOrReplaceDone,
})

const mapDispatchToProps = {
    cancelProgramOrReplaceRacket : (data) => cancelProgramOrReplaceRacket(data),
    getRacketOrderDetail: (id) => getRacketOrderDetail(id),
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CancelProgram));
