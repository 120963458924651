const initialState = {
	isLoveCodeCorrect: false,
	isMobileBarCodeCorrect: false,
}

export default function invoiceReducer(state = initialState, action) {
	switch (action.type) {
		case 'UPDATE_IS_LOVE_CODE_CORRECT': {
			return {
				...state,
				isLoveCodeCorrect: action.payload
			}
		}
		case 'UPDATE_IS_MOBILE_BAR_CODE_CORRECT': {
			return {
				...state,
				isMobileBarCodeCorrect: action.payload
			}
		}
		default:
			return state
	}
}
