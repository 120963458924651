import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client';
import Config from "../component/config.js";
import { token } from './fetchModule';

export const fetchOnSocket = () => {
    return dispatch => {

        let io
        if(window.io){
            io = window.io
        }else{
            io = sailsIOClient(socketIOClient);
            window.io = io;
        }
        io.sails.url = Config.socketUrl; 
        io.sails.headers = { token: token, source: Config.source };
        io.socket.post('/api/v1/user/subscribe', function (err, data) {
            console.log('connect to socket response:', JSON.stringify(data));
        });
    }

}

export const fetchOffSocket = () => {

    return dispatch => {

        let io
        if(window.io){
            io = window.io
        }else{
            io = sailsIOClient(socketIOClient);
            window.io = io;
        }

        //io.sails.url = 'https://bonnylive.biz:1337';
        io.sails.url = Config.socketUrl;
        io.sails.headers = { token: token, source: Config.source };
        io.socket.post('/api/v1/user/unsubscribe', function (err, data) {
            console.log('connect to socket response:', JSON.stringify(data));
        });
    }
}
