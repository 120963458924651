import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from './styled';
import moment from 'moment';
import {
	resetSearch,
	postLeaveActivity,
	fetchJoin,
	updateSearch,
} from "../../../actions";
import Banner from "../Banner";
import Tabs from './Tabs';
import { ActList, Card, InputButton, Modal } from "../../Common";
import { localStorageUtility } from '../../../utilities';

class JoinActivity extends Component {
	constructor(props) {
		super(props)
		const { query } = props.data;
		localStorageUtility('set', 'Tab', 'Success');
		this.state = {
			search: query.search || "",
			isHidePaid: 0,
			isNotPaid: 'check',
			isPaidDone: '',
			// isChecked: true,
		}
	}

	componentDidMount() {
		this.props.fetchJoin({ 'sort': 'start desc', 'status_end': '>', 'status': '1' });
	}

	onLeaveActivityClick(joinId) {
		this.props.leaveActivity({ join: joinId });
		setTimeout(() => this.props.fetchJoin({ 'sort': 'start desc', 'status_end': '>', 'status': '1' }), 100)
	}

	renderCardPairs = (data, i) => {
		const activityData = data && data.activity;
		return (
			<div key={i} style={{ opacity: `${activityData.status === 0 ? '0.5' : '1'}` }}>
				<Card key="4" data={activityData} url={`./participated_activity_detail/${activityData.id}/${data.id}`}>
					<div className="btn-group">
						{
							activityData.status !== 0 ?
								moment(activityData.end).valueOf() > new Date().valueOf() ?
									data.status === true ?
										data.paid === 1 && new Date().valueOf() >= moment(data.paidTime).add(1, 'h').valueOf() ?
											<Modal
												title='請假/取消'
												btnClass='list_button_outLine'
												btnText='請假/取消'
												modalContent={
													<div>
														<div>溫馨提醒：</div>
														<div>1. 此活動於
                                  							<span style={{ color: `#da263c`, fontWeight: `500`, }}>
																{moment(parseInt(activityData.start)).subtract(activityData.refundPeriod, 'days').format('MM月DD日 LT')}
															</span>
															後僅提供請假服務，不提供退款服務，若同意再點選確認按鈕跳轉至線上付款頁面。
                              							</div>
														<div>2. 如有問題請與團主聯絡。</div>
													</div>
												}
												onClick={() => this.onLeaveActivityClick(data.id)} />
											:
											data.paid === 1 && new Date().valueOf() < moment(data.paidTime).add(1, 'h').valueOf() ?
												<Modal
													title='請假/取消'
													btnClass='list_button_outLine'
													btnTheme="one"
													btnText='請假/取消'
													modalContent={
														<div>
															<div>溫馨提醒：</div>
															<div>1. 線上付款需於付款完成一小時後，方可請假或退款。</div>
															<div>2. 此活動於
                                    							<span style={{ color: `#da263c`, fontWeight: `500`, }}>
																	{moment(parseInt(activityData.start)).subtract(activityData.refundPeriod, 'days').format('MM月DD日 LT')}
																</span>
																後僅提供請假服務，不提供退款服務，若同意再點選確認按鈕跳轉至線上付款頁面。
                                							</div>
															<div>3. 如有問題請與團主聯絡。</div>
														</div>
													}
												/>
												:
												<Modal
													title='請假/取消'
													btnClass='list_button_outLine'
													btnText={'請假/取消'}
													modalContent={
														<div>
															<div>請確認是否要做請假動作？</div>
														</div>
													}
													onClick={() => this.onLeaveActivityClick(data.id)} />
										:
										null
									:
									null
								:
								<div className="list_button_outLine" style={{ color: `#da263c`, borderColor: `#da263c` }}>
									活動已取消
                			</div>
						}
					</div>
				</Card>
			</div>
		)
	}

	handleInputChange = e => {
		const target = e.target;
		const value =
			target.type === "checkbox" ? target.checked : target.value;
		const inputName = target.name;
		this.setState(
			{
				[inputName]: value
			},
			() => {
				if (inputName === "sort") {
					this.props.fetchJoin(this.state.search);
				}
			}
		);
	};

	handleHidePaid = () => {
		// console.log("filter unPaid, hide paid");
		this.setState({
			isHidePaid: 0,
			isNotPaid: 'check',
			isPaidDone: '',
			// isChecked: true 
		});
		// this.changeChoose('isChecked');
	}

	handleShowAll = () => {
		// console.log("show all button onclicked");
		this.setState({
			isHidePaid: 1,
			isNotPaid: '',
			isPaidDone: 'check',
			// isChecked: false 
		})
		// this.changeChoose('isHidePaid');
	}

	changeChoose(e) {
		var chooseNotPaidLabel = document.getElementById("chooseNotPaidLabel");
		var chooseIsPaidLabel = document.getElementById("chooseIsPaidLabel");
		// chooseNotPaidLabel.classList.toggle("check");
		// chooseIsPaidLabel.classList.toggle("check");

		if (e === 'isHidePaid') {
			chooseNotPaidLabel.classList.remove("check")
			chooseIsPaidLabel.classList.add("check")
		} else {
			chooseNotPaidLabel.classList.add("check")
			chooseIsPaidLabel.classList.remove("check")
		}
	}

	handleInputOnBlur = e => {
		this.props.onUpdateSearch(this.state.Joinsearch);
	};

	handleSubmit = () => {
		const Tab = localStorageUtility('get', 'Tab');
		var search = {
			// search: JSON.stringify(this.state.search),
			search: this.state.search,
			sort: '',
			status_end: ''
		}
		if (Tab === 'Success') {
			// this.state.sort = 'start asc'
			// this.state.status_end =  '>'
			search = {
				search: this.state.search,
				sort: 'start desc',
				status_end: '>'
			}
		} else {
			// this.state.sort = 'start asc'
			// this.state.status_end =  '<'
			search = {
				search: this.state.search,
				sort: 'start desc',
				status_end: '<'
			}
		}
		this.props.fetchJoin(search);
	};

	render() {
		// const Tab = localStorage.getItem('Tab');
		const { data, onUpdateSearch, search, fetchJoin } = this.props;
		return [
			<Banner key="0" />,
			<Wrapper key="1">
				<div className="header">
					<h2 > 我的報名 </h2>
					{ /* <InputButton key="1"/> */}
					<InputButton
						input={{
							placeholder: "團名",
							name: 'search',
							value: search,
							onChange: this.handleInputChange,
							onBlur: this.handleInputOnBlur
						}}
						onClick={this.handleSubmit}
					/>
				</div>
				<Tabs key="2" onUpdateSearch={onUpdateSearch} onFetchJoin={fetchJoin} isNotPaid={this.state.isNotPaid} isPaidDone={this.state.isPaidDone} />

				<div style={{ display: `none` }}>
					<input id="chooseNotPaid" type="radio" name="paymentStatusFilter" value="hidePaid"
						onClick={this.handleHidePaid}
						checked={this.state.isChecked} />
					{/*<span for="c1">未付款</span>*/}
					<input id="chooseIsPaid" type="radio" name="paymentStatusFilter" value="showAll"
						onClick={this.handleShowAll} />
					{/*<span for="c2">已付款</span>*/}
				</div>

				{ localStorageUtility('get', 'Tab') !== `Cancel` ?
					<ActList key="3">
						{
							data.activity
								.filter(n =>
									n.paid === this.state.isHidePaid
									// &&
									// n.activity.status !== 0
								)
								.map((data, i) =>
									this.renderCardPairs(data, i)
								)
						}
					</ActList>
					:
					<ActList key="3">
						{
							data.activity
								.map((data, i) =>
									this.renderCardPairs(data, i)
								)
						}
					</ActList>
				}
			</Wrapper>
		];
	}
}

const mapStateToProps = state => ({
	loading: state.loading,
	data: state.activity,
})

const mapDispatchToProps = {
	fetchJoin: fetchJoin,
	resetSearch: resetSearch,
	leaveActivity: postLeaveActivity,
	onUpdateSearch: updateSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinActivity);
