import styled from 'styled-components';

export const Wrapper = styled.div `
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: 480px) {
        margin-bottom: 30vh;    
    }

    h1{
        margin: 20px;
        font-size: 18px;
        font-weight: bold;
    }
    hr{
        border-color: rgba(0,0,0,.5);
        border-style: solid;
        border-top-width: 1px;
    }
    button{
        text-align: center;
        background-color: #2f82bf;
        color:white;
        border: 0px;
    }
    section{
        margin: 10px 0px;
        /* border: 1px black solid; */
        padding: 10px 20px 15px 20px;
        // border-bottom: 1px solid #000;
        background-color: rgba(233,233,233,0.2);
    }

    .affiliateShare{
        /* display: flex;
        flex-direction: column; */
        /* height: 220px; */
        /* justify-content: space-around; */
        &__section{
            display: flex;
            margin: 5px 0 10px;
            span{
                display: flex;
                flex: 1
                border: 1px solid rgb(206, 212, 218);
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
                border-right-width: 0px;
                overflow: hidden;
                // width: 80%;
                text-overflow: ellipsis;
                white-space: nowrap;            
                padding: 5px 0 5px 10px;
                background-color: #fff;
            }
            button{
                display: flex;
                width: 5rem;
                padding: 6px 0;
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
                justify-content: center;
                cursor: pointer;
            }
        }
    }

    .affiliateAnalyze{
        .affiliateAnalyze__number{
            font-size: 1.2rem;
            color: #da263c;
        }
        hr{
            border-top-width: 0.5px;
        }
        &__amount{
            margin-left: 40%;
            p{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
        &__people{
            margin-left: 40%;
            p{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }


    .affiliateHistory{
        select{
            width: 100%;
        }
        .affiliateHistory__searchButton{
            display: flex;
            margin-top: 10px;
            margin-bottom: 20px;
            div{
                flex: 1;
            }
            button{
                border-radius: 3px;
                padding: 5px 20px;
            }
        }
        .affiliateHistory__search{
            display:flex;
            align-items: center; 
            justify-content: center;
            text-align: center;
            margin-bottom: 5px;
            .affiliateHistory__search__title{
                display: flex;
                width: 4rem;
                margin-right: .5rem;
            }
            .affiliateHistory__search__input{
                display: flex;
                flex: 1;
            }
        }
        .affiliateHistory__list{
            .hide{
                display: none;
            }
            .affiliateHistory__slide{
                width: 100%;
                padding-top: 5px;
                display:flex;
                
                button{
                    flex: 0 0 100%;
                    background-color: unset;
                    text-align: right;
                    cursor: pointer;
                    color: rgb(59, 132, 183);
                    cursor: pointer;
                    padding-right: 1rem;
                    padding-bottom: 10px;
                    position: relative;
                    i.material-icons{
                        position: absolute;
                        top: 5px;
                    }
                }
            }
            background-color: rgba(213, 213, 213, 0.4);
            padding: 10px 10px 0 10px;
            border-radius: 5px;
            border: 1px solid rgb(204, 204, 204);

            margin: 10px 0px;

            color: #757575;

            .affiliateHistory__outside{
                display: flex;
                width: 100%;
                .affiliateHistory__title{
                    display:flex;
                    flex: 1;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                }
                .affiliateHistory__content{
                    display:flex;
                    flex: 1;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                }
            }

        }
    }

`;
