import styled from 'styled-components';

export const Wrapper = styled.div `

margin: 40px 20px;

h2{
    font-size: 1.8rem;
    color: rgba(218,38,60,1);
    font-weight: bold;
}

.description{
    line-height: 30px;
}

.redirectArea{
    display: flex;
    justify-content: space-around;
    
    button{
        width: 40%;
        background-color: rgb(223, 28, 44);
        border: 0px none;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: rgb(255, 255, 255);
        border-radius: 5px;
        margin-top: 40px;
    }
}

   
`