import React from "react";
import { Wrapper, HamburgerIcon, SideNav } from "./styled";
import { Link, withRouter } from 'react-router-dom';
import { GaSetting } from "../Common";
import { fetchPostPlayerId, createAffiliate, updateAffiliate, fetchOnSocket, updateActivitySignUpButton, setDeleteUserInfoToRedux, updateUserLoginStatus } from '../../actions';
import moment from 'moment';
import { connect } from "react-redux";
import UAParser from 'ua-parser-js';
import publicIP from 'react-native-public-ip';
import Config from '../config';
import { getCookie, setCookie, logOut, localStorageUtility } from '../../utilities';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            agent: {},
            affiliateData: {
                id: 0,
                userId: 0,
            },
            text: '',
            notAndroid: false,
        };
    }

    parseQueryString(string) {
        return string.slice(1).split("&")
        .map((queryParam) => {
            let kvp = queryParam.split("=")
            return { key: kvp[0], value: kvp[1] }
        })
        .reduce((query, kvp) => {
            query[kvp.key] = kvp.value
            return query
        }, {})
    }

    handleEventListener= (e)=>{
        document.removeEventListener('message', this.handleEventListener );
        
        // window will get many messages, so filter what we need
        if ( typeof e.data !== 'string' || e.data.indexOf('latitude') <= -1 ){
            return
        } 

        let location = JSON.parse(e.data)
        window.removeEventListener('message', this.handleEventListener );
        // no location permission, set default query 
        if( location.latitude === null){
            
        }else{
            localStorageUtility('set', 'location', e.data);
        }
        this.props.updateActivitySignUpButton(false)
    }

    componentDidMount() {
        // get user agent 
        var parser = new UAParser();
        let ua = navigator.userAgent
        let agent = {
            browser: parser.setUA(ua).getBrowser(),
            os: parser.setUA(ua).getOS(),
            device: parser.setUA(ua).getDevice(),
        }
        this.setState({
            agent: {
                browser: parser.setUA(ua).getBrowser(),
                os: parser.setUA(ua).getOS(),
                device: parser.setUA(ua).getDevice(),
            }
        })

        if (navigator.userAgent.indexOf("Android") <= -1) {
            this.setState({
                notAndroid: true
            })
        }

        // cause setState is async, so do it again
        localStorageUtility('set', 'agent', JSON.stringify({
            browser: parser.setUA(ua).getBrowser(),
            os: parser.setUA(ua).getOS(),
            device: parser.setUA(ua).getDevice()
        }));

        // 判斷是不是 webview，是的話打開 message event listener 接收經緯度資訊
        if( 
            ( agent.browser && agent.browser.name.indexOf('WebView') > -1 )
            ||
            window.ReactNativeWebView
        ){
            this.props.updateActivitySignUpButton(true)
            document.addEventListener('message', this.handleEventListener );
            window.addEventListener('message', this.handleEventListener );
        }

        // console.log(`affiliateUseFunction`)
        const queryarr= this.parseQueryString(window.location.search);
        // console.log('query string to arry');
        const aid = queryarr['aid'];

        if(!!aid){
            this.renderAndRuning({getAid: true});
        }
        else{
            this.renderAndRuning({getAid: false});
        }

        // this.publicIP();
        // this.affiliateUseFunction();
        // console.log(`componentDidMount`,this.props)
        // console.log("header did mount");
        // const Data = localStorage && localStorage.getItem('myData') ? JSON.parse(localStorage.getItem("myData")):'';
        let userInfo = localStorageUtility('getAndParse', 'myData');

        let affiliateSharerId = localStorageUtility('getAndParse', 'affiliateSharerId');
        if (!!userInfo.id) {
            this.setState({ text: "會員登出" });

            if(!!affiliateSharerId){
                // console.log(`!!affiliateSharerId`)
                this.setState({ affiliateData: {
                    id: affiliateSharerId && affiliateSharerId.id,
                    userId: userInfo.id,
                } });
                this.affiliateUseFunction();
            }else{
                // console.log(`!affiliateSharerId`)
            }
        } 
        else if (userInfo && !userInfo.token) {
            localStorageUtility('remove', 'myData');
            this.props.setDeleteUserInfoToRedux()
            this.props.updateUserLoginStatus(false)
            this.setState({ text: "會員登入" });
            this.props.history.replace('/Login');
            // window.location.href = "/Login";
        }
        else {
            localStorageUtility('remove', 'myData');
            this.props.setDeleteUserInfoToRedux()
            this.props.updateUserLoginStatus(false)
            this.setState({ text: "會員登入" });

            if(!!affiliateSharerId.nextPropsAffiliateData){
                this.setState({ affiliateData: {
                    id: affiliateSharerId && affiliateSharerId.id,
                    // userId: userInfo.id,
                } });
                this.affiliateUseFunction({
                    id: affiliateSharerId && affiliateSharerId.id,
                    // userId: userInfo.id,
                });
            }
        }

        // socket and oneSignal
        if(userInfo.token){

            this.props.fetchOnSocket()

            // add oneSignal subscribe event listener
            let print = () => {

                var OneSignal = window.OneSignal || null;
                
                if( !OneSignal || localStorageUtility('get', 'playerId') ){
                    return
                }

                OneSignal.init({
                    appId: Config.oneSignalAppId,
                });
                OneSignal.showHttpPrompt();

                OneSignal.on('popoverAllowClick', function (permissionClicked) {
                    // try to get ID until get ID
                    const tryToGetID = () => {
                        OneSignal.getUserId(function (userId) {
                            console.log('player_id', userId);
                            // alert(this.props) // errors
                            if (userId) {
                                localStorageUtility('set', 'playerId', userId);
                                console.log("set localStorage")
                                return userId

                                // return new Promise(function(resolve, reject){
                                //     window.setTimeout(function(){
                                //       console.log('A');
                                //       resolve('A');
                                //     }, (Math.random() + 1) * 1000);
                                // });

                            } else { setTimeout(() => { tryToGetID() }, 1500) }
                        })
                    }
                    tryToGetID()
                })
            }            
            print()
        }

        let expiresTime = localStorageUtility('get', 'expiresTime');
        if (!expiresTime) return
        let expiresDate = Date.parse(expiresTime)
        let now = moment().valueOf()

        if (now > expiresDate) {
            localStorageUtility('set', 'rememberMe', false);
            localStorageUtility('set', 'expiresTime', '');

            // localStorage.removeItem('expiresTime')
            localStorageUtility('remove', 'myData');
            this.props.setDeleteUserInfoToRedux()
            this.props.updateUserLoginStatus(false)

            this.setState({ text: "會員登入" });
            // window.location.href = "/Login";
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log(`componentWillReceiveProps`,this.props)
        // console.log(`componentWillReceiveProps`,nextProps)
        
        // const Data = localStorage && localStorage.getItem('myData') ? JSON.parse(localStorage.getItem("myData")):'';
        var userInfo = localStorageUtility('getAndParse', 'myData');

        var nextPropsAffiliateData = nextProps && nextProps.affiliateData;
        // console.log(nextPropsAffiliateData)
        // console.log(!!nextPropsAffiliateData.affiliateId === true)
        if(!!nextProps.affiliateData){
            let nextPropsAffiliateDataID
            if(!!nextProps.affiliateData.id === true && !!userInfo.id === false){
                localStorageUtility('set', 'affiliateSharerId', JSON.stringify({
                    id: nextPropsAffiliateData.id,
                }));
                nextPropsAffiliateDataID = nextProps && nextProps.affiliateData && nextProps.affiliateData.id;
                setCookie('affiliateID', nextPropsAffiliateDataID, 30);
            }
            else if(!!nextPropsAffiliateData.id && !!userInfo.id){
                localStorageUtility('set', 'affiliateSharerId', JSON.stringify({
                    id: nextPropsAffiliateData.id,
                    userId: userInfo.id,
                }));

                nextPropsAffiliateDataID = nextProps && nextProps.affiliateData && nextProps.affiliateData.id;
                setCookie('affiliateID', nextPropsAffiliateDataID, 30);
            }
            else if(nextPropsAffiliateData.firstEntry === false){
                this.localStorageSetting(nextPropsAffiliateData);
            }
        }

        if (!!userInfo.id) {
            this.setState({ text: "會員登出" });
        }
        else if (userInfo && !userInfo.token) {
            localStorageUtility('remove', 'myData');
            this.props.setDeleteUserInfoToRedux()
            this.props.updateUserLoginStatus(false)
            
            this.setState({ text: "會員登入" });
            // window.location.href = "/Login";
        }
        else {
            localStorageUtility('remove', 'myData');
            this.props.setDeleteUserInfoToRedux()
            this.props.updateUserLoginStatus(false)
            
            this.setState({ text: "會員登入" });
        }

        let expiresTime = localStorageUtility('get', 'expiresTime');
        if (!expiresTime) return
        let expiresDate = Date.parse(expiresTime)
        let now = moment().valueOf()

        if (now > expiresDate) {
            localStorageUtility('set', 'rememberMe', false);
            localStorageUtility('set', 'expiresTime', '');
            // localStorage.removeItem('expiresTime')
            localStorageUtility('remove', 'myData');
            this.props.setDeleteUserInfoToRedux()
            this.props.updateUserLoginStatus(false)
            
            this.setState({ text: "會員登入" });
            // window.location.href = "/Login";
        }
    }

    componentDidUpdate() {
        // console.log("header did update");

        let newToken = localStorageUtility('getAndParse', 'myData').token;
        let playerId = localStorageUtility('get', 'playerId');
        // console.log(newToken);
        // =0 會馬上 call
        if( localStorageUtility('get', 'myData') && 
        localStorageUtility('get', newToken) === '0'){
            if(playerId && playerId !== 'null'){
                localStorageUtility('set', newToken, 1);
                this.props.fetchPostPlayerId(playerId,this.state.agent)
            }
        } 
    }

    openSideMenu = () => {
        this.setState({ menuOpen: true });
    };
    closeSideMenu = () => {
        this.setState({ menuOpen: false });
    };

    clearLocalStorage = () => {
        logOut()
    }

    async renderAndRuning (e) {
        const getIp = await publicIP();
        // console.log(getIp)
        await this.setIP(getIp);
        await this.setRentLandingPage(getIp);
        if(!!e.getAid){
            await this.affiliateUseFunction();
        }
    }

    setIP(e){
        // var promise = e;
        // promise.then(function (data) {
            // console.log(data)
            var d = new Date();
            d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 30);
            document.cookie = `IP=${e};path=/;expires=${d.toGMTString()}`;
            // localStorage.setItem('getIP', JSON.stringify({get: true,ip: data}));
            // setCookie('IP', data, 30);
            // expected output: "Success!"
            // return data;
        // })
    }

    setRentLandingPage(e){
        let randomType = Math.floor(( (Math.random()*100) +2))%2
        if(e){
            // cookie 有 IP, 表示非初次造訪網站
            if(getCookie('LandingPage')){
                if(getCookie('IP')){
                }else{
                    setCookie('IP', e, 30)
                }
            }else{
                if(getCookie('IP')){
                    // // 單雙數判斷
                    if( randomType === 0 ){
                        setCookie( 'LandingPage', 'A', 30)
                    } 
                    if( randomType === 1 ){
                        setCookie( 'LandingPage', 'B', 30)
                    }
                }else{
                    setCookie('IP', e, 30)
                    // // 單雙數判斷
                    if( randomType === 0 ){
                        setCookie( 'LandingPage', 'A', 30)
                    } 
                    if( randomType === 1 ){
                        setCookie( 'LandingPage', 'B', 30)
                    }
                }
                
            }
            // if(getCookie('IP')){
            //     }else{
            //         // // 單雙數判斷
            //         if( randomType === 0 ){
            //             setCookie( 'LandingPage', 'A', 30)
            //         } 
            //         if( randomType === 1 ){
            //             setCookie( 'LandingPage', 'B', 30)
            //         }
            //     }
            // }else{
            //     setCookie('IP', e, 30)
            //     // 單雙數判斷
            //     if( randomType === 0 ){
            //         setCookie( 'LandingPage', 'A', 30)
            //     } 
            //     if( randomType === 1 ){
            //         setCookie( 'LandingPage', 'B', 30)
            //     }
            // }
        }
    }

    affiliateUseFunction (data){
        // console.log(`affiliateUseFunction`)
        const queryarr= this.parseQueryString(window.location.search);
        // console.log('query string to arry');
        const aid = queryarr['aid'];
        // console.log('*****get aid from location search*****');
        // console.log('aid='+aid);
        
        // const linkUrl = window.location.href;
        // console.log(linkUrl)
        // console.log(window.location)
        // const LinkArr = linkUrl.split('?aid=');
        // console.log(LinkArr)
        //************setting************
        //affiliate Data set in
        let affiliateData = localStorageUtility('getAndParse', 'affiliate');
        //user Data set in
        let userData = localStorageUtility('getAndParse', 'myData');

        let affiliateSharerId = localStorageUtility('getAndParse', 'affiliateSharerId');
        //************setting************

        var nowDate = new Date();
        // console.log(moment(nowDate).isSame(affiliateData.firstEntryDate, 'day'))
        // console.log(moment(nowDate).isAfter(affiliateData.firstEntryDate, 'day'))
        
        if(!!aid || !!affiliateData){
            // console.log(`a000`)
            if(!affiliateData){
                // console.log(`a001`)
                if(!!aid){
                    // console.log(`a002`)
                    this.localStorageSetting({firstEntry: true});
                    this.props.createAffiliate(localStorageUtility('get', 'affiliate'));
                }
            }
            else if(!!affiliateData){
                // console.log(`a002`)
                //One day passed
                if(moment(nowDate).isAfter(affiliateData.firstEntryDate, 'day')){
                    localStorageUtility('remove', 'affiliateSharerId');
                    // this.localStorageSetting({firstEntry: true});
                    // this.cookieSetting();
                    // this.props.createAffiliate(localStorage.getItem('affiliate'));
                    // console.log(`One day passed`)
                    //30 days passed 
                    if(moment().subtract(30, 'days').valueOf() > affiliateData.firstEntryDate){
                        localStorageUtility('remove', 'affiliate');
                        localStorageUtility('remove', 'affiliateSharerId');
                        document.cookie = affiliateSharerId.id + ";" + 0 + '; path=/';
                        // console.log(`30 days passed`)
                    }
                    else{
                        if(!aid){
                            // console.log(`a004`)
                            if(!userData.id){
                                // console.log(`a005`)
                                this.localStorageSetting({firstEntry: false});
                                this.props.createAffiliate( localStorageUtility('get', 'affiliate') ); 
                            }
                            else if(!!userData.id){
                                // console.log(`a006`)
                                if(!affiliateData.userId){
                                    // console.log(`a007`)
                                    // console.log(
                                    //     `id:`, affiliateSharerId && affiliateSharerId.nextPropsAffiliateData && affiliateSharerId.nextPropsAffiliateData.id,
                                    //     `userId:`, userData.id
                                    // )
                                    this.localStorageSetting({firstEntry: true});
                                    this.props.createAffiliate(localStorageUtility('get', 'affiliate'));
                                    // this.props.updateAffiliate({
                                    //     id: affiliateSharerId && affiliateSharerId.id,
                                    //     userId: userData.id,
                                    // });
                                }
                                else if(!!affiliateData.userId && affiliateData.userId !== userData.id){
                                    // console.log(`a008`)
                                    this.localStorageSetting({firstEntry: true});
                                    this.props.createAffiliate(localStorageUtility('get', 'affiliate'));     
                                }
                                else if(!!affiliateData.userId && affiliateData.userId === userData.id){
                                    // console.log(`a008`)
                                    this.localStorageSetting({firstEntry: false});
                                    this.props.createAffiliate(localStorageUtility('get', 'affiliate'));     
                                }
                            }
                        }
                        else if(!!aid){
                            // console.log(`a010`)
                            if(aid === affiliateData.code){
                                // console.log(`a011`)
                                if(!userData.id){
                                    // console.log(`a012`)
                                    this.localStorageSetting({firstEntry: false});
                                }
                                else if(!!userData.id){
                                    // console.log(`a013`)
                                    if(!affiliateData.userId){
                                        // console.log(`a014`)
                                        this.localStorageSetting({firstEntry: false});
                                        this.props.createAffiliate(localStorageUtility('get', 'affiliate'));
                                        // this.props.updateAffiliate({
                                        //     id: affiliateSharerId && affiliateSharerId.id,
                                        //     userId: userData.id,
                                        // });
                                    }
                                    else if(!!affiliateData.userId && affiliateData.userId !== userData.id){
                                        // console.log(`a008`)
                                        this.localStorageSetting({firstEntry: true});
                                        this.props.createAffiliate(localStorageUtility('get', 'affiliate'));     
                                    }
                                    else if(!!affiliateData.userId && affiliateData.userId === userData.id){
                                        // console.log(`a008`)
                                        this.localStorageSetting({firstEntry: false});
                                        this.props.createAffiliate(localStorageUtility('get', 'affiliate'));     
                                    }
                                }
                            }
                            else if(aid !== affiliateData.code){
                                // console.log(`a016#1`)
                                this.localStorageSetting({firstEntry: true});
                                this.props.createAffiliate(localStorageUtility('get', 'affiliate'));
                            }
                        }
                    }
                }
                else{
                    // console.log(`a003`)
                    if(!aid){
                        // console.log(`a004`)
                        if(!userData.id){
                            // console.log(`a005`)
                            this.localStorageSetting({firstEntry: false});
                        }
                        else if(!!userData.id){
                            // console.log(`a006`)
                            if(!affiliateData.userId){
                                // console.log(`a007`)
                                // console.log(
                                //     `id:`, affiliateSharerId && affiliateSharerId.nextPropsAffiliateData && affiliateSharerId.nextPropsAffiliateData.id,
                                //     `userId:`, userData.id
                                // )
                                this.localStorageSetting({firstEntry: true});
                                this.props.updateAffiliate({
                                    id: affiliateSharerId && affiliateSharerId.id,
                                    userId: userData.id,
                                });
                            }
                            else if(!!affiliateData.userId && userData.id !== affiliateData.userId){
                                // console.log(`a008`)
                                this.localStorageSetting({firstEntry: true});
                                this.props.createAffiliate(localStorageUtility('get', 'affiliate'));     
                            }
                            else if(userData.id === affiliateData.userId && affiliateData.firstEntry !== false){
                                // console.log(`a009`)
                                this.props.updateAffiliate({
                                    id: affiliateSharerId && affiliateSharerId.id,
                                    userId: userData.id,
                                });
                            }
                        }
                    }
                    else if(!!aid){
                        // console.log(`a010`)
                        if(aid === affiliateData.code){
                            // console.log(`a011`)
                            if(!userData.id){
                                // console.log(`a012`)
                                this.localStorageSetting({firstEntry: false});
                            }
                            else if(!!userData.id){
                                // console.log(`a013`)
                                if(!affiliateData.userId){
                                    // console.log(`a014`)
                                    this.localStorageSetting({firstEntry: true});
                                    this.props.updateAffiliate({
                                        id: affiliateSharerId && affiliateSharerId.id,
                                        userId: userData.id,
                                    });
                                }
                                else if(!!affiliateData.userId && userData.id !== affiliateData.userId){
                                    // console.log(`a015`)
                                    this.localStorageSetting({firstEntry: true});
                                    this.props.createAffiliate(localStorageUtility('get', 'affiliate'));     
                                }
                                else if(userData.id === affiliateData.userId){
                                    // console.log(`a016`)
                                    this.localStorageSetting({firstEntry: false});
                                }
                            }
                        }
                        else if(aid !== affiliateData.code){
                            // console.log(`a016#1`)
                            this.localStorageSetting({firstEntry: true});
                            this.props.createAffiliate(localStorageUtility('get', 'affiliate'));
                        }
                    }
                }
            }
            else {console.log(`other`)}
            this.affiliateCookieSetting();
        }
        // this.affiliateIDGetRefresh();
    }

    localStorageSetting(argument){
        // console.log(`affiliateUseFunction`)
        const queryarr= this.parseQueryString(window.location.search);
        // console.log('query string to arry');
        const aid = queryarr['aid'];
        // console.log('*****get aid from location search*****');
        // console.log('aid='+aid);

        const linkUrl = window.location.pathname;
        // // const linkQuery = this.props.location.search;
        // const LinkArr = linkUrl.split('?aid=');
        //************setting************
        //affiliate Data set in
        let affiliateData = localStorageUtility('getAndParse', 'affiliate');
        //user Data set in
        let userData = localStorageUtility('getAndParse', 'myData');
        //************setting************
    　　 var path = getCookie('path');
        var ip = getCookie('IP');

        var userId;
        if( !!userData === true ){
            userId = userData.id
        }else if( !!affiliateData === true ){
            userId = affiliateData.userId
        }

        localStorageUtility('set', 'affiliate', JSON.stringify({
            //會員 編號（table: user, field: id）
            // userId: userData && userData.id ? userData.id : affiliateData && affiliateData.userId ? affiliateData.userId : null,
            userId,
            //分銷商 編號（table: affiliate, field: code)
            code: !!aid ? aid : affiliateData.code,
            //是否首次進入(false: 否, true: 是)
            firstEntry: argument.firstEntry,
            //ip address
            ip: ip,
            //引流路徑
            path: !!aid ? linkUrl : path,
            firstEntryDate: moment().valueOf(),
        }));
    }
    
    affiliateCookieSetting(){
        // console.log(`affiliateUseFunction`)
        const queryarr= this.parseQueryString(window.location.search);
        // console.log('query string to arry');
        const aid = queryarr['aid'];
        // console.log('*****get aid from location search*****');
        // console.log('aid='+aid);

        const linkUrl = window.location.pathname;
        var affiliateData = localStorageUtility('getAndParse', 'affiliate');
        var affiliateSharerId = localStorageUtility('getAndParse', 'affiliateSharerId');
        var affiliateID;

        if(!!aid){
            setCookie('path', linkUrl, 1);
        }
        if(!affiliateSharerId){
            affiliateID = affiliateData.code;
        }else{
            affiliateID = affiliateSharerId.id;
        }
        setCookie('affiliateID', affiliateID, 30);
    }

    // affiliateIDGetRefresh(){
    //     const linkUrl = window.location.href;
    //     const LinkArr = linkUrl.split('?aid=');
    //     if(LinkArr.length != 1){
    //         window.location.href = LinkArr[0];
    //     }
    // }

    render() {
        const { menuOpen, notAndroid } = this.state;
        let pathname = window.location.pathname;

        return (
            <Wrapper style={{margin: 'auto'}}>
                <nav className="navbar">
                    {/* 讓首頁沒有上一頁 */}
                    {pathname !== '/' && this.props.historyLength !== 0 ? (
                        <span 
                            className="returnPage"
                            onClick={this.props.history.goBack}
                        >
                            <i className="material-icons">chevron_left</i>
                            <span>上一頁</span>
                        </span>
                    ) : ('')}
                    <Link to="/" className="logo">
                        <img alt="" src="/assets/images/logoWhite.svg" className="logo_img" />
                    </Link>
                    {/*<span className="open-slide" style={{
                        // position: `absolute`,
                        // right: 20 + `px`,
                    }}>*/}
                        <span
                            className="menu"
                            style={{ cursor: 'pointer'}}
                            onClick={
                                !menuOpen
                                    ? this.openSideMenu
                                    : this.closeSideMenu
                            }
                        >
                            <HamburgerIcon
                                className={!menuOpen ? "" : "active"}
                            >
                                <span className="line line-1" />
                                <span className="line line-2" />
                                <span className="line line-3" />
                            </HamburgerIcon>
                        </span>
                    {/*</span>*/}
                    {/*
                    <a className="more">更多運動 +</a>
                    */}
                </nav>
                <SideNav menuOpen={menuOpen}>
                    <div onClick={this.closeSideMenu}>
                        <GaSetting 
                            key = "menuLink_0"
                            data = {{
                                className: 'menuLink',
                                category: 'menu-badminton', 
                                action: 'menu-sign-click', 
                                label: '', 
                                tag: 'link',
                                path: '/activity_list',
                                disabled: this.props.disableActivitySignUpButton
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_activitySignUp.png" alt="臨打報名"/>
                            <p>臨打報名</p>
                        </GaSetting>
                        <GaSetting 
                            key = "menuLink_1"
                            data = {{
                                className: 'menuLink',
                                category: 'menu-badminton', 
                                action: 'menu-activity-click', 
                                label: '', 
                                tag: 'link',
                                path: '/create_activity',
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_newGroup.png" alt="開團找我"/>
                            <p>開團找我</p>
                        </GaSetting>
                        <GaSetting 
                            key = "menuLink_2"
                            data = {{
                                className: 'menuLink',
                                category: 'menu-badminton', 
                                action: 'menu-media-click', 
                                label: '', 
                                tag: 'link',
                                path: '/media',
                            }}
                        >
                            {
                                Config.isBonnyStar ?
                                <>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_bonny_star.png" alt="波力之星"/>
                                    <p>波力之星</p>
                                </>
                                :
                                <>
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_campus.png" alt="校園大使"/>
                                    <p>校園大使</p>
                                </>
                            }
                        </GaSetting>
                        <GaSetting 
                            key = "menuLink_3"
                            data = {{
                                className: 'menuLink',
                                category: 'menu-badminton', 
                                action: 'menu-know-click', 
                                label: '', 
                                tag: 'button',
                                target: '_bank',
                                path: 'https://www.bonny-live.com/WebDocument/SportCategory',
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_knowHow.png" alt="羽球知識家"/>
                            <p>羽球知識家</p>
                        </GaSetting>
                        { notAndroid &&
                            <GaSetting 
                                key = "menuLink_4"
                                data = {{
                                    className: 'menuLink',
                                    category: 'menu-badminton', 
                                    action: 'menu-live-click', 
                                    label: '', 
                                    tag: 'button',
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/Show/List',
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_hotLive.png" alt="Live直播"/>
                                <p>Live 直播</p>
                            </GaSetting>
                        }
                        <GaSetting 
                            key = "menuLink_5"
                            data = {{
                                className: 'menuLink',
                                category: 'menu-badminton', 
                                action: 'menu-equipment-click', 
                                label: '', 
                                tag: 'button',
                                target: '_bank',
                                path: 'https://www.bonny-live.com/Product/List?tag=%E7%BE%BD%E7%90%83%E6%8B%8D',
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_recommend.png" alt="配備推薦"/>
                            <p>配備推薦</p>
                        </GaSetting>
                        <GaSetting 
                            key = "menuLink_6"
                            data = {{
                                className: 'menuLink',
                                category: 'menu-badminton', 
                                action: 'menu-rent-click', 
                                label: '', 
                                tag: 'link',
                                path: '/rackets_lobby',
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_rentIntro.png" alt="羽你蓮心"/>
                            <p>羽你蓮心</p>
                        </GaSetting>
                        {this.state.text === '會員登出' ?
                            <GaSetting 
                                key = "menuLink_7"
                                data = {{
                                    className: 'menuLink',
                                    category: 'menu-badminton', 
                                    action: 'menu-myInfo-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/my_info',
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_myBonny.png" alt="MyBonny"/>
                                <p>My Bonny</p>
                            </GaSetting>
                            :
                            <GaSetting 
                                key = "menuLink_7"
                                data = {{
                                    className: 'menuLink',
                                    category: 'menu-badminton', 
                                    action: 'menu-myInfo-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/login',
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_myBonny.png" alt="MyBonny"/>
                                <p>My Bonny</p>
                            </GaSetting>
                        }
                        <div onClick={ this.state.text === '會員登出' ? this.clearLocalStorage : ()=>{} }>
                            <GaSetting 
                                key = "menuLink_8"
                                data = {{
                                    className: 'menuLink',
                                    category: 'menu-badminton', 
                                    action: this.state.text === '會員登出' ? 'menu-userLogout' : 'menu-userLogin', 
                                    label: this.state.text === '會員登出' ? 'logout' : 'login',
                                    tag: 'link',
                                    path: '/login',
                                    target: 'windowRedirect',
                                }}
                            >
                                <img src={this.state.text === '會員登出' ? 'https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_logout.png' : 'https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_logIn.png'} alt="Login"/>
                                <p>{this.state.text}</p>
                            </GaSetting>
                        </div>
                    </div>
                    <GaSetting 
                        key = "menuLink_9"
                        data = {{
                            className: 'menuLink',
                            category: 'menu-badminton', 
                            action: 'menu-contactUs-click', 
                            label: '', 
                            tag: 'button',
                            target: '_bank',
                            path: 'https://www.bonny-live.com/Service/Contact',
                        }}
                    >
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/menuicons/menu_contactUs.png" alt="聯絡客服"/>
                       <p>聯絡客服</p>
                    </GaSetting>

                </SideNav>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        affiliateData: state.affiliateReducer.createAffiliateData,
        disableActivitySignUpButton: state.activity.disableActivitySignUpButton,
    }
};
const mapDispatchToProps = ({
    fetchPostPlayerId: (playerId, agent) => fetchPostPlayerId({playerId: playerId, agent: agent}),
    fetchOnSocket: fetchOnSocket,
    createAffiliate: createAffiliate,
    updateAffiliate: updateAffiliate,
    updateActivitySignUpButton: updateActivitySignUpButton,
    setDeleteUserInfoToRedux: setDeleteUserInfoToRedux,
    updateUserLoginStatus: updateUserLoginStatus,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
