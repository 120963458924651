import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Wrapper } from './styled';
import CommentForm from './CommentForm';
import Comment from './Comment';
import {
    fetchAllComments,
    fetchPostComment,
    sendMessage
} from '../../../actions';
import { localStorageUtility } from '../../../utilities';

class CommentBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            replyName: ''
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.status === 'ok') {
            return { replyName: '', message: '' };
        }
        return null;
    }

    componentDidMount() {
        let { getComments, chatroom } = this.props;
        if (chatroom && chatroom.id) getComments(chatroom.id);
    }

    componentDidUpdate(prevProps, prevState){

        let { getComments, chatroom } = this.props;

        if (chatroom === '' || !chatroom.id ) return;
        if (
            (
                Object.keys(prevProps.chatroom).length === 0
                &&
                Object.keys(this.props.chatroom).length !== 0
            )
            ||
            (prevProps.chatroom.id !== chatroom.id)
        ) {
            getComments(chatroom.id);
        }
    }

    chooseTypes(chatroom) {
        let { activity, media, id } = chatroom;
        if (activity !== null) {
            return { type: 'activity', id: activity };
        } else if (media !== null) {
            return { type: 'media', id: media };
        } else {
            return { type: 'chatroom', id: id };
        }
    }

    onReplyClick(name) {
        console.log(name)
        this.setState({
            replyName: name
        });
    }

    onChange(val) {
        if (val.trim() === "") {
            this.setState({
                message: '',
                replyName: ''
            });
        } else {
            this.setState({
                message: val
            });
        }
    }

    onSubmit(e) {
        const { chatroom, to, commentType } = this.props;
        const { type, id } = this.chooseTypes(chatroom);
        const { message } = this.state;
        const data = localStorageUtility('get', 'myData');

        if (this.props.renderSelfMessage && !message.trim() === false) {
            this.props.renderSelfMessage({
                user: {
                    id: this.props.userId,
                    avatar: this.props.userAvatar,
                    name: this.props.userName
                },
                message: this.state.message
            })
        }

        if (data == null) {
            alert('請先登入');
            if (this.props.isMascot || this.props.isBonnyStar){
                document.cookie = "backurl="+window.location.pathname;
            }
            this.props.history.replace('/login');
            // window.location.replace('/login');
            return false;
        }

        if (commentType === 'comment' && message.trim() !== '') {
            this.props.onSubmit(id, type, message);
        }
        if (commentType === 'privateChat' && message.trim() !== '') {
            this.props.onSubmitMessage({ to, message });
            this.setState({
                message: ''
            })
        }
    }

    render() {
        let { comments, chatroom, commentType } = this.props;
        return (
            <Wrapper isBonnyStar= {this.props.isBonnyStar}>
                <div className="comment-header-wrapper">
                    {commentType !== 'privateChat' && (
                        <div className="comment-header">
                            <div className="comment-count">
                                留言：({comments.length})
                            </div>
                        </div>
                    )}
                    <CommentForm
                        {...this.chooseTypes(chatroom)}
                        onClick={e => this.onSubmit(e)}
                        onChange={value => this.onChange(value)}
                        value={this.state.replyName}
                        message={this.state.message}
                    />
                </div>
                {commentType !== 'privateChat' && (
                    <div className="comments">
                        {comments.map((comment, i) => (
                            <Comment
                                key={i}
                                isParent={true}
                                {...comment}
                                onClick={name => this.onReplyClick(name)}
                            />
                        ))}
                    </div>
                )}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    const { chatroom_data: data, status } = state.commentReducer;
    const comments = data.reply;
    const { messageInformation } = state.messageReducer;
    return { comments, status, messageInformation };
};

const mapDispatchToProps = dispatch => ({
    getComments: chatroom_id => {
        dispatch(fetchAllComments(chatroom_id));
    },
    onSubmit: (id, type, message) => {
        dispatch(fetchPostComment(id, type, message));
    },
    onSubmitMessage: data => {
        dispatch(sendMessage(data));
    }
});

CommentBox.defaultProps = {
    chatroom: '',
    to: '',
    commentType: 'comment',
    comments: [],
    status: ''
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentBox));
