import React from "react";
// import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Header2, LeftButton, RightButton, Buttons, Button, ModalContent } from "./styled";
import { localStorageUtility } from '../../../utilities';

const customStyles = {
    content: {
        position: "static",
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        borderRadius: "20px",
        marginTop: "-44px",
        maxHeight: "calc(100vh - 197px)",
        overflowX: "hidden",
    }
};

export default class ModalBox extends React.Component {
    constructor(props) {
        super(props);
        var A_where = (window.location.pathname).split('/');
        var where1 = A_where[1];
        var where2 = A_where[2];
        if(where1==='myInfo'&&!where2){
            this.state = {
                modalIsOpen: true
            };
        }else{
            this.state = {
                modalIsOpen: false
            };
        }
        
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };
    openModalWithAlert = () => {
        let userToken = localStorageUtility('getAndParse', 'myData').token;
        if(userToken){
            this.setState({ modalIsOpen: true });
        }else{
            document.cookie = "backurl="+window.location.pathname;
            alert('請先登入')
            this.props.redirect('/login');
        }
    };

    afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //console.log("afterOpenModal");
    };

    closeModal = () => {
        this.props.onClose && this.props.onClose();
        this.setState({ modalIsOpen: false });
    };

    handleSubmit = () => {
        if (false === this.props.onClick()) {
            return;
        }
        this.closeModal();
    };

    render() {
        const {
            disabled,
            btnClass,
            btnText,
            modalContent,
            title,
            // onClick,
            btnTheme,
            text1,
            text2,
            noButton,
            notButtonStyle,
            openModalAlert,
            // noLeftButton
        } = this.props;

        // openModalAlert add for media sharing
        return [
            noButton ? (
                openModalAlert ?    
                <div style={notButtonStyle} className={btnClass} onClick={this.openModalWithAlert} key="0">
                    {btnText} {this.props.children}
                </div>
                :
                <div style={notButtonStyle} className={btnClass} onClick={this.openModal} key="0">
                    {btnText} {this.props.children}
                </div>
            ) : (
                <button onClick={this.openModal} className={btnClass} key="0" disabled={disabled}>
                    {btnText} {this.props.children}
                </button>
            ),
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                key="1"
            >
                <Header2 style={{ textAlign: "center" }}>{title}</Header2>
                <ModalContent>
                    {modalContent}
                </ModalContent>
                {btnTheme === "two" ? (
                    <Buttons>
                        <LeftButton onClick={this.handleSubmit}>
                            {text1 ? text1 : "送出"}
                        </LeftButton>
                        <RightButton onClick={this.closeModal}>
                            {text2 || '取消'}
                        </RightButton>
                    </Buttons>
                ) : (
                    <Button onClick={this.closeModal}>{text2 || '取消'}</Button>
                )}
            </Modal>
        ];
    }
}

ModalBox.defaultProps = {
    disabled: false,
    title: null,
    btnClass: null,
    btnText: null,
    btnTheme: "two",
    modalContent: null,
    onSubmit: () => {}
};
