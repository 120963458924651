import styled from 'styled-components';

export const Wrapper = styled.div `
    width: 100%;
    select{
        width: 100%
    };
    a {
        text-decoration: none;
    }
    button > a{
        color: #fff;
    }
    .button-style {
        display: block;
        width: calc( 100% - 20px );
        margin: auto;
        padding: 10px;
        font-size: large;
        font-weight: 500;
        color: #fff;
        text-align: center;
        background-color: #DF1C2C;
        border: 0 none;
        border-radius: 5px;
        cursor: pointer;
        -webkit-text-decoration: none;
        text-decoration: none;
    }
    hr.margin-all-20px {
        margin: 20px;
    }

    #program_done{
        width:calc(100% - 40px);
        position:relative;
        left: 0;
        right: 0;
        margin: auto;
        margin-bottom: 60px;
        display:block;

        .title{
            margin-top: 30px;
            margin-bottom: 30px;
            color: #ce243b;
            text-align: center;
            h2{
                font-size: 1.6em;
                line-height: 1.6em;
                font-weight: bold;
            }
        }

        .sub_style{
            text-align:center;
            width:100%;
        }

        ul{
            margin: 20px;
            padding: 20px;
            border-top: 1px solid #ccc;
            li{
                list-style: none;
                margin-bottom: 10px;
                color: #999;
                span{
                    color: #333;
                }
                @media screen and (max-width: 480px){
                    font-size: 14px;
                }
                ul{
                    li{
                        list-style:disc;
                        list-style-position:inside;
                    }
                }
            }
        }
    }

    #program_fail{
        width: calc(100% - 40px);
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        margin-bottom: 60px;
        display: block;

        .title{
            margin-top: 30px;
            margin-bottom: 30px;
            color: #ce243b;
            text-align: center;
            h2{
                font-size: 1.6em;
                line-height: 1.6em;
                font-weight: bold;
            }
        }

        .sub_style{
            text-align: center;
            width: 100%;
            p {
                span {
                    color: #ce243b;
                }
            }
        }

        ul{
            margin: 20px;
            padding: 20px;
            border-top: 1px solid #ccc;
            li{
                list-style: none;
                margin-bottom: 10px;
                ul{
                    li{
                        list-style: disc;
                        list-style-position: inside;
                    }
                }
            }
        }
    }
`
