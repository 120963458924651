import React, { Component } from "react";
import { Wrapper } from './styled';
import { JoinedIcon, MessagesIcon } from '../../../Common';

class SocialSummary extends Component {

    render() {
        const { 
          join, 
          comment, 
          follow, 
          view 
        } = this.props;

        return (
            <Wrapper>
              <div className="social-item">
                  <i className="material-icons">
                    favorite
                  </i>
                <span>{follow}人</span>
              </div>
              <MessagesIcon className="social-item" counts={comment} />
              <JoinedIcon className="social-item" counts={join} />
              <div className="social-item">
                  <i className="material-icons">
                    visibility
                  </i>
                <span>{view}人</span>
              </div>
            </Wrapper>
        );
    }
}

SocialSummary.defaultProps = {
  join: 0,
  comment: 0,
  follow: 0,
  view: 0
}

export default SocialSummary;
