export const copyLink = (urlParam, isSearchRequire, search) => {
    let textField = document.createElement('textarea');
    let url = window.location.href;
    let arr = url.split("/");
    let result = arr[0] + "//" + arr[2];
    // window.location.host

    let link 
    if( isSearchRequire === true ){
        if(search){
            link = result + urlParam + search
        }else{
            console.log("no search, error");
        }
    }else{
        link = result + urlParam 
    }

    textField.innerText = link
    
    
    
    document.body.appendChild(textField);
    textField.select();

    // for iphone
    textField.setAttribute('readonly', 'readonly');
    textField.setSelectionRange(0, 9999)

    if (document.execCommand('copy')) {
        document.execCommand('copy');
        alert('連結已經複製');
    }else{
        alert('連結複製失敗')
    }

    textField.remove();

    // original
    // document.execCommand('copy');
    // textField.remove();
    // alert("連結已經複製");
}