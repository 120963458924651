import styled from 'styled-components';

export const Wrapper = styled.div`
    .city,.area{
        width: 22%;
        display:inline-block;
        vertical-align: bottom;
        margin-right: calc( 3% - 2px );
    }

    .address{
        width: 50% !important;
        display:inline-block;
        vertical-align: bottom;
    }

    // #mapSearch{
    //     display:inline-block;
    //     vertical-align: bottom;
        
    //     background-color: #fff;
    //     font-family: Roboto;
    //     font-size: 15px;
    //     font-weight: 300;
    //     margin-left: 12px;
    //     padding: 0 11px 0 13px;
    //     text-overflow: ellipsis;
    //     // width: 50% !important;
    //     // width: 400px;
        
    //     :focus {
    //         border-color: #4d90fe;
    //     }
    // }

`