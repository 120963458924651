import React, { Component } from 'react';
import { Wrapper } from './styled';
import Modal from '../Modal';
import ModalContent from "../ModalContent";
import { localStorageUtility } from '../../../utilities';

class Checkbox extends Component {

    handleChange(evt) {

        if (evt.target.checked === false) {
            localStorageUtility('set', 'check', JSON.stringify(evt.target.checked));
        }
        if (evt.target.checked === true) {
            localStorageUtility('set', 'check', JSON.stringify(evt.target.checked));
        }
    }

    render() {
        return (
            <Wrapper>
                <input className="ckbox" type="checkbox" 
                    defaultChecked={this.props.check} 
                    onChange={this.handleChange} 
                />
                <span className="ckctext">我已閱讀並接受</span>
                <Modal
                    title="《個資保護聲明》"
                    btnClass="ckcmodalbox"
                    btnText="《個資保護聲明》"
                    modalContent={
                        <ModalContent />
                    }
                />
            </Wrapper>
        );
    }
}

export default Checkbox;
