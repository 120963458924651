import styled from 'styled-components';

export const Wrapper = styled.div `
*{
    margin:0;
    padding:0;
}
#CONTENT{
    width:100%;
    max-width:440px;
    font-family:"微軟正黑體", Arial, Helvetica, sans-serif;
    margin-left:auto;
    margin-right:auto;
    position:relative;
}
#CONTENT img{
    position:relative;
    border:0;
    display:block;
    width:100%;
    height:auto;
}
#CONTENT h2{
    display:block; /*轉換成區塊*/
    width:100%;
    height:50px;
    color:#930;
    font-family:Arial, Helvetica, sans-serif;
    font-size:15px;
    text-align:center;
    line-height:50px; /*單一行拿來做垂直置中*/
    position:relative;
}

#CONTENT h1{
    display:block;
    font-size:30px;
    width:100%;
    background-color: #ac8967;
    color:#FFF;
    text-align:center;
    padding-top:20px;
    padding-bottom:30px;
    position:relative;
}

#CONTENT h3{
    display:block;
    width:calc(100% - 60px);
    background-color: #ac8967;
    color:#FFF;
    font-size:14px;
    text-align:left;
    line-height:23px;
    padding-bottom:30px;
    padding-left: 30px;
    padding-right: 30px;
    position:relative;
    }
    
#CONTENT h4{
    display:block;
    width:calc(100% - 60px);
    background-color: #ce243b;
    color:#FFF;
    font-size:14px;
    text-align:left;
    line-height:23px;
    padding-left: 30px;
    padding-right: 30px;
    position:relative;
    }

#CONTENT h5{
    display:block;
    width:100%;
    background-color: #ac8967;
    color:#FFF;
    font-size:21px;
    text-align:center;
    line-height:23px;
    padding-bottom:20px;
    position:relative;
    }

#CONTENT h5 a{
    color:#FFF;
    cursor:pointer;
    text-decoration:none;
    }


#CONTENT h6{
    display:block;
    width:100%;
    height: 120px;
    background-color: #ac8967;
    position:relative;
    }

#CONTENT p{
    font-size:13px;
    color:#999;
    line-height:3;
    background-color: #ac8967;
    color:#FFF;
    text-align:center;
}





    @media (max-width: 480px) {}
    }

    // LightBox ----- 說明文字
    .lightBox-explain{
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:999;
        background-color:rgba(0,0,0,.25);
        display:flex;
    }
    .lightBox-explain.close{
        display:none;
    }
        .lightBox-explain-Content{
            width:320px;
            height:auto;
            max-height: calc(100vh - 197px);    
            position: relative;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: hidden auto;
            border-radius: 20px;
            outline: none;
            margin:auto;

            .lightBox-explain-Content-inside{
                padding: 20px;

                .lightBox-inside-close-button{
                    border-radius:999rem;
                    border:1px solid #ccc;
                    background-color:#FFF;
                    width:20px;
                    height:20px;
                    line-height:20px;
                    text-align:center;
                    font-weight:bold;
                    font-size:14px;
                    cursor:pointer;
                    position:absolute;
                    right:10px;
                    top:10px;
                }

                .LB-C-title{
                    width:100%;
                    height:30px;
                    line-height:30px;
                    text-align:center;
                    font-size:20px;
                    font-weight:bold;
                    margin-top:20px;
                }

                .LB-C-Description > ul{
                    padding:0;

                    li{
                        list-style-type:none;
                    }
                }

                .LB-C-Description > ul.list-style-type-number{
                    li{
                        list-style-position: inside;
                        list-style-type: decimal;
                    }
                }
            }

            .lightBox-outside-close-button{
                text-align:center;
                height:37px;
                line-height:37px;
                font-size:20px;
                font-weight:bold;
                cursor:pointer;
                padding-bottom:8px;
            }

            .light-outside-button-group{
                width:100%;
                height:37px;
                display:flex;
                text-align:center;
                line-height:37px;
                font-size:20px;
                padding:10px 0;

                .light-outside-button-group-check{
                    flex:0 0 calc( 50% - 0.5px);
                    cursor:pointer;
                }
                .light-outside-button-group-line{
                    flex:0 0 1px;
                    border:none;
                    margin:0;
                    padding:0;
                    border-left:1px solid #000;
                }
                .light-outside-button-group-close{
                    flex:0 0 calc( 50% - 0.5px);
                    cursor:pointer;
                }
            }
        }
`
