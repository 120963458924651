import styled from 'styled-components';

export const Wrapper = styled.div `
  display: flex;
  align-items: center;
  > .user-info {
    flex: auto;
    display: flex;
    align-items: baseline;
    justify-content: space-around;

    .user-info-Data {
      flex: 1;
      margin-left: 10px;
      @media screen and (max-width: 480px){
        margin-left: 5px;
      }
    }
  }

  .avatar {
    width: 50px;
    height: 50px;
    border: 2px solid #ccc;
    border-radius: 999rem;
    overflow: hidden;

    @media screen and (max-width: 480px){
        width: 40px;
        height 40px;
    }
    .avatar_photo {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background-color: #fff;
      background-size: cover;
      background-position: center;
    }
  }
`
