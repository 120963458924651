import React, { Component } from "react";
class Banner extends Component {
    render() {
        const { data } = this.props;
        return (
            <div style={{
                width: 100 + "%",
                height: 200 + "px",
                backgroundColor: `#000`,
            }}>
                <div
                    style = {{
                        backgroundImage: `url(${data ? data['url'][0] : "/assets/images/08-banner.png"})`,
                        width: 'auto',
                        height: 100 + '%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                    }}
                ></div>
            </div>
        );
    }
}

export default Banner