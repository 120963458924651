import styled from "styled-components";

export const Wrapper = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    input {
        width: 25px;
    }

    .cktext {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        padding-left: 5px;
    }

`
