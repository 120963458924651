import React, { Component } from "react";
import FacebookLogin from 'react-facebook-login';
import { Wrapper } from './styled';
import Config from "../../../component/config.js";
import UAParser from 'ua-parser-js';


export default class CourtFeatures extends Component {
    constructor(props) {
        super();
        this.state = {
            isLineBrowser: false,
            isWebView: false,
        };
    }

    componentDidMount() {
      
        var parser = new UAParser();
        let ua= navigator.userAgent
        parser.setUA(ua).getBrowser() && parser.setUA(ua).getBrowser().name.indexOf('WebView') > -1
        ?
        this.setState({
            isWebView: true
        })
        :
        this.setState({
            isWebView: false
        })


        this.setState({
            isLineBrowser: this.isLine()
        })
    }

    isLine() {
        var u = navigator.userAgent
        let ua = u.toLowerCase()
        // Line judgement
        let isLine = ua.indexOf("line") > -1
        //  && ua.indexOf('iphone') > -1
        return isLine
    }

    render() {
        const facebookID = Config.facebook;
        const redirectUri = Config.facebookURL;
        return (
            <Wrapper>
                {
                    Config.shouldFBLoginHidden ?
                    <>
                    </>
                    :
                    <>
                        <label className="text">其他登入方式:</label>
                        {this.state.isLineBrowser || this.state.isWebView || window.ReactNativeWebView ?
                            <FacebookLogin
                                authType='rerequest'
                                appId={facebookID}
                                fields="name,email,picture"
                                cssClass="facebook"
                                textButton="Facebook登入"
                                callback={resp => this.props.onLoginFacebook(resp)}

                                isMobile={true}
                                redirectUri={redirectUri}

                            />
                            :
                            <FacebookLogin
                                authType='rerequest'
                                appId={facebookID}
                                fields="name,email,picture"
                                cssClass="facebook"
                                textButton="Facebook登入"
                                callback={resp => this.props.onLoginFacebook(resp)}

                                // isMobile={true}
                                // redirectUri="https://staging.bonnylive.biz/login"
                                disableMobileRedirect={true}
                            />
                        } 
                    </>
                }
            </Wrapper>
        );
    }
}
