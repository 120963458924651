import React, { Component } from "react";
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { 
    getGroupDetail, 
    getFollower,
    editGroup, 
    postSignUpLeaveActivity,
    fetchActivityInvite,
    fetchUserlist,
    followUser,
    unFollowUser,
    followList,
    createActivityPaymentOrder,
    getJoinedList,
} from "../../../actions";
import { 
    Modal, 
    ButtonsGroup, 
    CommentBox, 
    InviteButton, 
    SubmitButton,
    LoadingIndicator 
} from "../../Common";
import { Wrapper } from '../SignUpDetail/styled'
import Banner from "../ActivityDetail/Banner";
import SocialSummary from "../ActivityDetail/SocialSummary";
import ActivityContent from "../ActivityDetail/ActivityContent";
import SignUpInfo from "./SignUpInfo";
import ShareButton from "../ActivityDetail/ShareButton";
import { localStorageUtility } from '../../../utilities';

class SignUpDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersearch: '',
            inviteeIndex: -1,
            user: localStorageUtility('getAndParse', 'myData'),
            userID: localStorageUtility('getAndParse', 'myData').id,
            image:'',
            formDataGet:false,
            joinID: this.props.match && this.props.match.params && this.props.match.params.joinId,
            paymentType: 'Cash',
            isSendPaymentClick: false,
        }
    }

    componentDidMount(props) {
        const { getGroupDetail, match } = this.props;
        getGroupDetail(match.params.id);
        const user = localStorageUtility('getAndParse', 'myData');
        const token = user ? user.token : "";

        if (token) {
            this.props.followList({ type: "follower" });
        }  

        this.props.getJoinedList();
        
        let expiresTime = localStorageUtility('get', 'expiresTime');
        if (!expiresTime) return
        let expiresDate = Date.parse(expiresTime);
        let now = moment().valueOf();
        if (now > expiresDate) {
            localStorageUtility('set', 'rememberMe', false);
            localStorageUtility('set', 'expiresTime', '');
            localStorageUtility('remove', 'myData');
        }
    }

    componentDidUpdate(prevProps, prevState){
        if ((prevProps.data && prevProps.data.user === undefined) && (this.props.data && this.props.data.user !== undefined)) {
            this.setState({
                id: this.props.data.user.id,
            })
        }     

        if( 
            this.props.isGetPaymentRes === true 
            && 
            this.state.paymentType === 'CreditEcpay' 
            && 
            this.state.isSendPaymentClick === true
        ) {
            document.getElementById("purchase_form").submit();
        }
    }

    openModal = (e) => {
        this.setState({ modalIsOpen: true });
    }

    sendPayment = (e) => {
        this.setState({ 
            paymentType: e.paymentType,
            isSendPaymentClick: true, 
        });
        this.props.createActivityPaymentOrder(e);
    }

    onEditGroupClick(data) {
        this.props.dispatch(editGroup(data));
        this.props.history.push('/edit_activity');
    }

    onLeaveActivityClick = () => {
        this.props.onLeaveActivityClick({join: this.state.joinID});
    }

    onfollowClick(id) {
        this.props.onfollow(id);
    }


    handleInputChange = (event, { newValue, method }) => {
        this.props.fetchUserlist(newValue);
        this.setState({
            inviteeIndex: (newValue.trim() === "" ? -1 : this.state.inviteeIndex)
        });
    }

    handleInviteeSelected = ({ suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({inviteeIndex: suggestionIndex})
    }

    handleSubmit = (usersearch,activityid) => {
        let { inviteeIndex } = this.state;
        if( usersearch.length > 0 && inviteeIndex !== -1) {
            let userid = usersearch[inviteeIndex]["id"];
            this.props.onInvite(userid,activityid);
            // setTimeout(() => window.location.reload(), 100)
            setTimeout(() => this.props.getGroupDetail(activityid), 100)
        }
    };

    handleFollowUser = (id) => {
        var isFollowed = this.isFollowed(id);
        if (isFollowed) {
            this.props.unFollowUser({ id: this.getFollowId(id) });
        }
        else {
            this.props.followUser({ followee: id });
        }
        this.props.followList({ type: "follower" });
    }

    isFollowed = (id) => {
        var followList = this.props.follow;
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === id) {
                    return (true)
                }
            }
        }
        return false
    }

    getFollowId = (id) => {
        var followList = this.props.follow
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === id) {
                    return followList[i].id
                }
            }
        }
        return 0;
    }

    render() {
        const {
            id,
            summary,
            content,
            joinHost,
            chatroom,
            usersearch,
            data,
            joinedList,
        } = this.props;

        const groupUserId = data.user && data.user.id;
        var isPaid = false;
        var joinID = this.state.joinID;
        var activityId = id;
        var paidTime = 0; //付款時間
        var paymentType = `Cash`; //付款方式
        var status = true;
        var invoicesStatus = 0;
        var invoicesNumber = 0;

        if (joinedList && Array.isArray(joinedList)) {
            joinedList.forEach(joinedListData => {
                if( joinedListData.id === Number(joinID) ){
                    isPaid = joinedListData.paid === 0 ? false : true;
                    status = joinedListData.status;
                    paidTime = joinedListData.paidTime ? joinedListData.paidTime : 0;
                    paymentType = joinedListData.paymentTypePlugin ? joinedListData.paymentTypePlugin : `Cash`;
                    if(joinedListData.invoices.length > 0){
                        joinedListData.invoices.forEach(invoicesData => {
                            if(invoicesData.status === 1){
                                invoicesStatus = invoicesData.status;
                                invoicesNumber = invoicesData.number;
                            }
                        })
                    }
                }
            })
        }
    
        return [
            <Banner key={0} data={data.images}/>,
            <Wrapper key={1}>
                <LoadingIndicator shouldOpen={this.props.loading}/>
                <div 
                    className="content" 
                    style={{
                        margin: `20px`,
                        width: `calc( 100% - 40px )`,
                    }}
                >
                    <SocialSummary {...summary} />
                    <ActivityContent {...content}/>
                    <ButtonsGroup>
                        <div style={{display:'flex',flex:'1'}}>
                            <Link className="modal-btn" to={`/my_info/chatroom/user_id=${groupUserId}`}>聊聊</Link>
                        </div>
                        { 
                            data.status !== 0 ?
                            data.start > new Date().valueOf() && status === true ? 
                            new Date().valueOf() >= moment(paidTime).add(1, 'h').valueOf() ?
                                <div style={{display:'flex',flex:'1'}}>
                                    <Modal 
                                        title='請假/取消'
                                        btnClass='modal-btn'
                                        btnText='請假/取消'
                                        modalContent={
                                            <div>
                                                <div>溫馨提醒：</div>
                                                <div>1. 此活動於
                                                    <span style={{color: `#da263c`,fontWeight: `500`,}}>
                                                        {moment(parseInt(data.start)).subtract(data.refundPeriod, 'days').format('MM月DD日 LT')}
                                                    </span>
                                                    後僅提供請假服務，不提供退款服務，若同意再點選送出按鈕申請。
                                                </div>
                                                <div>2. 如有問題請與團主聯絡。</div>
                                            </div>
                                        }        
                                        onClick={() => this.onLeaveActivityClick()} 
                                    />
                                </div>
                            :
                                <div style={{display:'flex',flex:'1'}}>
                                    <Modal 
                                        title='請假/取消'
                                        btnClass='modal-btn'
                                        btnTheme="one"
                                        btnText='請假/取消'
                                        modalContent={
                                            <div>
                                                <div>溫馨提醒：</div>
                                                <div>1. 線上付款需於付款完成一小時後，方可請假或退款。</div>
                                                <div>2. 此活動於
                                                    <span style={{color: `#da263c`,fontWeight: `500`,}}>
                                                        {moment(parseInt(data.start)).subtract(data.refundPeriod, 'days').format('MM月DD日 LT')}
                                                    </span>
                                                    後僅提供請假服務，不提供退款服務，若同意再點選送出按鈕申請。
                                                </div>
                                                <div>3. 如有問題請與團主聯絡。</div>
                                            </div>
                                        }
                                    />
                                </div>
                            :
                                <div style={{display:'flex',flex:'1'}}>
                                    <div className="ButtonsGroup_disable">
                                        請假/取消
                                    </div>
                                </div>
                            :
                                <div style={{display:'flex',flex:'1'}}>
                                    <div className="ButtonsGroup_disable">
                                        活動已取消
                                    </div>
                                </div>
                        }
                    </ButtonsGroup>
                    {
                        status ?
                        moment(parseInt(content.start)).valueOf() >= new Date().valueOf() ?
                            isPaid === false ?
                                data.fee > 0 ?
                                    <div>
                                        <hr />
                                        <div className="b1">
                                            已報名（未付款）
                                        </div>
                                        <div style={{height: '5px',}}></div>
                                        { 
                                            (data.plan && ((data.plan.CreditEcpay !== 0) && (data.plan.CreditEcpay !== null) && (data.plan.CreditEcpay !== undefined) ))
                                            ?
                                                <div>
                                                    <Modal
                                                        title="前往付款確認"
                                                        onClick={(e) => {
                                                            this.sendPayment({activityId: parseInt(activityId), joinId: parseInt(joinID), paymentType: `CreditEcpay`},e)
                                                        }}
                                                        btnTheme={"two"}
                                                        text1="確認"
                                                        modalIsOpen={this.state.modalIsOpen}
                                                        noButton={true}
                                                        key = {'confirmjoin1'}
                                                        modalContent={
                                                            <div>
                                                                <div>溫馨提醒：</div>
                                                                <div>此活動於
                                                                    <span style={{color: `#da263c`,fontWeight: `500`,}}>
                                                                        {moment(parseInt(data.start)).subtract(data.refundPeriod, 'days').format('MM月DD日 LT')}
                                                                    </span>
                                                                    後僅提供請假服務，不提供退款服務，若同意再點選確認按鈕跳轉至線上付款頁面。
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                    {
                                                        <div id="otherActvity">
                                                            <SubmitButton text={"線上付費"} onClick={this.openModal}/>
                                                        </div>
                                                    }
                                                    </Modal>
                                                    <div style={{height: '5px',}}></div>
                                                </div>
                                            :
                                                null
                                        }
                                        { 
                                            (data.plan && ( (data.plan.Cash !== 0) && (data.plan.Cash !== null) && (data.plan.Cash !== undefined) ))
                                            ?
                                                <Modal
                                                    title="付款未完成"
                                                    onClick={(e) => {
                                                        this.sendPayment({activityId: parseInt(activityId), joinId: parseInt(joinID), paymentType: `Cash`},e)
                                                    }}
                                                    btnTheme={"two"}
                                                    text1="確認"
                                                    modalIsOpen={this.state.modalIsOpen}
                                                    noButton={true}
                                                    modalContent={
                                                        <div>
                                                            <div style={{textAlign: `center`,}}>請與團主聯絡付款事宜，謝謝您！</div>
                                                            <div className="aLinkDiv" style={{width: `100%`,}}>
                                                                <Link to={`/my_info/chatroom/user_id=` + groupUserId}>
                                                                    聯絡團主
                                                                </Link> 
                                                            </div>  
                                                        </div>
                                                    }
                                                >
                                                {
                                                    <div id="otherActvity">
                                                        <SubmitButton text={"現場付費"} onClick={this.openModal} />
                                                    </div>
                                                }
                                                </Modal>
                                            :
                                                null
                                        }
                                    </div>
                                : 
                                    <div>
                                        <hr />
                                        <div className="b1">已完成報名</div>
                                    </div>
                            :
                                    <ul>
                                        <hr />
                                        <li className="b1">
                                            已報名（已付款）
                                        </li>
                                        <li style={{color: `#999`, height: `2em`, marginTop: `20px`,}}>
                                            付款方式：
                                            {
                                                paymentType === `CreditEcpay` ? 
                                                    <span style={{color: `#333`, fontWeight: `500`,}}>線上付款</span>
                                                :
                                                    <span style={{color: `#333`, fontWeight: `500`,}}>現場付費</span>
                                            }
                                        </li>
                                        <li style={{color: `#999`, height: `2em`,}}>
                                            付款時間：
                                            <span style={{color: `#333`, fontWeight: `500`,}}>
                                                {moment(parseInt(paidTime)).format('YYYY年MM月DD日 LT')}
                                            </span>
                                        </li>
                                        <li style={{color: `#999`, height: `2em`,}}>
                                            發票號碼：
                                            <span style={{color: `#333`, fontWeight: `500`,}}>
                                                {invoicesStatus ? invoicesNumber : "尚未開立發票"}
                                            </span>
                                        </li>
                                    </ul>
                                : 
                                    <div>
                                        <hr />
                                        <div className="b1">活動已開始</div>
                                    </div>
                        :
                            <div>
                                <hr />
                                <div className="b1">報名已取消</div>
                            </div>
                    }
                    <hr />
                    <SignUpInfo onLeaveClick = {(id) => this.onLeaveActivityClick()} follow={(id) => this.handleFollowUser(id)} followList={this.props.follow} joinedPeopleData={joinHost.map((data) => data)} people={data.people}/>
                    <InviteButton
                        suggestions={usersearch}
                        inputProps={{
                            placeholder: "請輸入E-MAIL或帳號手動直接報名",
                            name: 'search',
                            onChange: this.handleInputChange
                        }}
                        onSuggestionSelected={this.handleInviteeSelected}
                        onClick={() => this.handleSubmit(usersearch,id)}
                    />
                    <ShareButton url={`https://${window.location.host}/quick_sign_up_activity?${id}`} />
                </div>
                <CommentBox chatroom={chatroom}/>
            </Wrapper>
        ];
    }
}

const mapStateToProps = (state) => {
    const { activityInformation: data } = state.groupDetailReducer;
    const { userList: userdata } = state.groupDetailReducer;
    const { shouldLoadingOpen: loading } = state.groupDetailReducer;
    const usersearch = userdata.data;
    const follow =  state.groupDetailReducer.follow;
    const isGetPaymentRes = state.groupDetailReducer.isGetPaymentRes;
    const joinedList = state.groupDetailReducer.joinedList;
    if(data && data.user !== undefined){
        localStorageUtility('set', 'GroupUserFollower', JSON.stringify(data.user.followeeCount));
    }
    const followerID = localStorageUtility('getAndParse', 'GroupUserFollower');
    const 
        id = data.id,
        summary = {
            join: data.join ? data.join.filter(data => data.status === true).length : 0,
            comment: data.comment,
            follow: followerID ? followerID : 0,
            view: data.view
        }, 
        content = {
            userType: data.type,
            createdAt: data.createdAt,
            groupName: data.group_name,
            people: data.people,
            start: data.start,
            end: data.end,
            placeName: data.place_name,
            city: data.city,
            area: data.area,
            address: data.address,
            badmintonLevel: data.badminton_level,
            cash: data.plan && data.plan.Cash,
            creditEcpay: data.plan && data.plan.CreditEcpay,
            placeCount: data.place_count,
            tag: data.tag,
            type: data.type,
            remain: data.remain,
        },
        chatroom = data.chatroom,
        joinHost = data.join;

    return {
        id, 
        summary, 
        content, 
        joinHost, 
        chatroom, 
        data, 
        usersearch, 
        follow, 
        joinedList,
        loading,
        isGetPaymentRes,
    }
}

const mapDispatchToProps = (dispatch) => ({
    getGroupDetail: (id) => {
        dispatch(getGroupDetail(id));
    },
    fetchUserlist: (data) => {
        dispatch(fetchUserlist(data))
    },
    followUser: (id) => {
        dispatch(followUser(id))
    },
    unFollowUser: (id) => {
        dispatch(unFollowUser(id))
    },
    followList: (id) => {
        dispatch(followList({followee:id}))
    },      
    onInvite: (userid,activityid) => {
        dispatch(fetchActivityInvite({user:userid,activity:activityid}))
    },
    getfollower:(data)=>{
        dispatch(getFollower(data));
    },
    createActivityPaymentOrder:(data)=>{
        dispatch(createActivityPaymentOrder(data));
    },
    onLeaveActivityClick: (join) => {
        dispatch(postSignUpLeaveActivity(join))
    },
    getJoinedList: (data) => {
        dispatch(getJoinedList(data));
    },
    dispatch: dispatch,
})

SignUpDetail.defaultProps = {
    summary: {
        people: 0,
        comment: 0,
        follow: 0,
        view: 0
    },
    joinHost: []
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpDetail));
