import 'whatwg-fetch';
import React from "react";
import { render } from "react-dom";
import { Provider } from 'react-redux'
import Home from "./component/Home";
import "./main.css";
import store from './store'
import { Router, Route } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
export const history = createBrowserHistory();

let historyLength = 0

history.listen((location, action) => {
    if(action === "PUSH"){
        ++historyLength
    }else if(action === "POP"){
        --historyLength
    }else{
        // console.log(':::::::::::::::::::::::: replace ')
    }
})

const App = () => <Router history={history}>
    <Route path="/" 
        render={(props) => (
            <Home
                historyLength = {historyLength}
                {...props}
            />
        )} 
    />
</Router>

render(
    <Provider store={store}><App /></Provider>, document.getElementById("root"));
