import styled from 'styled-components';

export const Wrapper = styled.div`
`;

export const ListContent = styled.div`
    margin-bottom: 40px;
    @media (max-width: 480px) {
        margin-bottom: 30vh;
    }
    .list{
        display: flex;
        align-items:flex-start;
        margin: 20px 20px;
        .Out_left{
            display: flex;
            width: 3.7rem;
            margin-right: 5px;
            align-items: center;
            justify-content: center;
            @media (max-width: 480px) {
                width: 40px;
            }
            .pic{
                width: 3rem;
                height: 3rem;
                border-radius: 999rem;
                overflow: hidden;
                position: relative;
                @media (max-width: 480px) {
                    width: 40px;
                    height: 40px;
                }
                img{
                    // width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .Out_right{
            flex: 1;
            display: flex;
            align-items: flex-start;
            .inside{
                width: 100%;
                display: flex;
                align-items: flex-start;
                .In_left{
                    flex: 9;
                    display: flex;
                    align-items:flex-end;
                    .name{
                        height: 1.2rem;
                        font-size: 1rem;
                        font-weight: 900;
                        line-height: 1.2rem;
                        //
                        display: none;
                    }
                    .message{
                        // height: 1.4rem;
                        // flex: 8;
                        width: auto;
                        max-width: 210px;   
                        font-size: 1rem;
                        color: #000;
                        font-weight: 400;
                        line-height: 1.4rem;
                        // overflow: hidden;  
                        // text-overflow: ellipsis; 
                        // white-space: normal;  
                        // display:-webkit-box;  
                        // -webkit-box-orient: vertical;  
                        // -webkit-line-clamp: 1;
                        //  
                        // text-overflow: ellipsis;
                        // white-space: pre-wrap; 
                        word-wrap:break-word;
                        word-break:normal;
                        padding: .5rem;
                        border-radius: 15px;
                        background-color: #eee;  
                        // p{
                        //     overflow-wrap: break-word;
                        //     word-break: break-all;
                        //     word-wrap: break-word; // 兼容IE
                        // }
                        @media (max-width: 320px) {
                            max-width: 160px;
                        }
                        p {
                            width: 100%;
                        }
                    }
                    .date{
                        flex: 1;
                        margin-left: 5px;
                        height: 1.1rem;
                        font-size: .8rem;
                        text-align: left;
                        color: #ccc;
                    }
                }
                .In_right{
                    // width: 5%;
                    flex: 1;
                    .arrow{
                        position: relative;
                        width: 100%;
                        height: 3.7rem;
                        .notice{
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            background-color: rgb(217, 29, 48);
                            border-radius: 999rem;
                            color: #fff;
                            width: 20px;
                            height: 20px;
                            font-size: 10px;
                            line-height: 20px;
                            padding: 2px 2px;
                            text-align: center; 
                        }
                        i{
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            text-align: center; 
                        }
                    }
                }
            }
        }
    }
    .list.myself{
        .Out_left{
            display: none;
        }
        .Out_right{
            .inside{
                .In_left{
                    .message{
                        // background-color: rgb(253, 43, 68);
                        background-color: #da263c;
                        color: #fff;
                    }
                    .date{
                        margin-left: 0px;
                        margin-right: 5px;
                        text-align: right;
                    }
                }
            }
        }
    }
`;
