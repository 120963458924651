import styled from "styled-components"

export const Wrapper = styled.button`
    width: 100%;
    background-color: unset !important;
    border: unset !important;
    padding: 0 !important;
    margin: auto !important;

    div {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        background-color: #df1c2c;
        padding: 10px 0;
        font-size: 20px;
        color: #fff;
        font-weight: 500;
        line-height: 2rem;
        border: unset;
        border-radius: 5px;

        i {
            font-size: 2rem;
            vertical-align: middle;
        }
    }
`
