import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPrivateChatList } from '../../../../actions';
import { Wrapper, ListContent } from './styled';
import Banner from '../../Banner';
import moment from 'moment';
import { localStorageUtility } from '../../../../utilities';

class Messages extends Component {

    componentDidMount() {
        this.props.getPrivateChatList();
    }

    render() {
        const { message } = this.props;
        const userObj = localStorageUtility('get', 'myData');
        const { id } = JSON.parse(userObj);
        return [
            <Banner key={0} />,
            <Wrapper key={1}>
                <h2>聊天內容</h2>
                <ListContent>
                    {message &&
                        message.map(m => {
                            const me = m.personOne && m.personOne.id === id;
                            const userData = me ? m.personTwo : m.personOne;
                            const unread = me
                                ? m.personOneUnread
                                : m.personTwoUnread;
                            // console.log(m);
                            return (
                                <Link to={`chatroom/id=${m.id}`} key={m.id}>
                                    <div className="list">
                                        <div className="Out_left">
                                            <div className="avatar">
                                                <div className="avatar_photo" style={{backgroundImage: `url(${userData.avatar ||'/assets/images/DefaultUserImg.png'})`}}></div>
                                            </div>
                                        </div>
                                        <div className="Out_right">
                                            <div className="inside">
                                                <div className="In_left">
                                                    <div className="name">
                                                        <span>
                                                            {userData.name}
                                                        </span>
                                                        <span className="date">
                                                            {moment(parseInt(m.lastMessage.updatedAt)).format('YYYY/MM/DD')}
                                                        </span>
                                                    </div>
                                                    <div className="message">
                                                        {m.lastMessage.message}
                                                    </div>
                                                </div>
                                                <div className="In_right">
                                                    <div className="arrow">
                                                        {
                                                            unread > 0 ?
                                                            (
                                                                <div className="notice">
                                                                    {unread}
                                                                </div>
                                                            )
                                                            :
                                                            <i className="material-icons" style={{color: `#808080`}}>chevron_right</i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                </ListContent>
            </Wrapper>
        ];
    }
}

const mapStateToProps = state => ({
    message: state.messageReducer.privateChat
});

const mapDispatchToProps = {
    getPrivateChatList: fetchPrivateChatList
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
