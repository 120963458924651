import React, { Component } from "react";
import { SkillLevelSelect, CourtAddress, Select, DatePicker, GaSetting } from "../../../component/Common";
import TimePicker from "./TimePicker";
import { Wrapper, TimePickerWrapper, DatePickerWrapper } from "./styled";
const options = [
    {
        text: "不設定人數",
        value: "",
        selected: true
    }
];
for (let i = 1; i <= 20; i++) {
    options.push({ text: i, value: i });
}
export default class extends Component {
    render() {
        const { onChange, data, dateOnChange } = this.props;
        return (
            <Wrapper>
                <label>打球日期</label>
                <DatePickerWrapper>
                    <GaSetting 
                        data = {{
                            category: 'activitySignUp-badminton', 
                            action: 'activitySignUp-DatePickerStartChange-click', 
                            label: '', 
                        }}
                    >
                        <DatePicker
                            className="startDate"
                            name={this.props.dateStartName}
                            ref={this.props.dateStartRef}
                            value={this.props.dateStart}
                            onChange={dateOnChange}
                        />
                    </GaSetting>
                    <span>-</span>
                    <GaSetting 
                        data = {{
                            category: 'activitySignUp-badminton', 
                            action: 'activitySignUp-DatePickerEndChange-click', 
                            label: '', 
                        }}
                    >
                        <DatePicker
                            className="endDate"
                            name={this.props.dateEndName}
                            ref={this.props.dateEndRef}
                            value={this.props.dateEnd}
                            onChange={dateOnChange}
                        />
                    </GaSetting>
                </DatePickerWrapper>
                <label>打球時段</label>
                <TimePickerWrapper>
                    <TimePicker
                        name="interval_start"
                        onChange={onChange}
                        value={data.interval_start}
                    />
                    <span>-</span>
                    <TimePicker
                        name="interval_end"
                        onChange={onChange}
                        value={data.interval_end}
                    />
                </TimePickerWrapper>
                <SkillLevelSelect
                    value={data.badminton_level}
                    forSearch={true}
                    levels={this.props.levels}
                    onChange={onChange}
                />
                <label>可報名名額</label>
                <Select
                    options={options}
                    name="remain"
                    value={this.props.remain}
                    onChange={onChange}
                />
                <CourtAddress onChange={onChange} data={data} label={`球場地址`}/>
            </Wrapper>
        );
    }
}
