import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { 
    notificationCountListener, 
    stopNotificationCountListener, 
    fetchPrivateChatList, 
    getNotification, 
    messageCountListener, 
    stopMessageCountListener,
    setUserInfoToRedux,
} from '../../../actions';
import { Wrapper } from './styled';
import Banner from '../Banner';
import { logOut, localStorageUtility } from '../../../utilities';
//import CoverPhoto from '../CoverPhoto';

class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '登入'
        };
    }

    clearLocalStorage = () => {
        logOut()
    };

    componentDidMount() {
        this.props.notificationCountListener()
        this.props.messageCountListener()
        const userInfo = localStorageUtility('getAndParse', 'myData');
        this.props.getPrivateChatList();
        this.props.getNotification();

        if (userInfo && userInfo.token !== null) {
            this.setState({ text: '登出' });
        } else {
            this.setState({ text: '登入' });
        }
    }

    componentDidUpdate(){
        if(this.props.userInfo && JSON.stringify(this.props.userInfo) !== '{}' ){
            this.props.setUserInfoToRedux(this.props.userInfo)
        }
    }

    render() {
        const { message, data, userInfo } = this.props;
        var messageUnreadNum = 0; //未讀訊息數
        const { id } = localStorageUtility('getAndParse', 'myData');
        message &&
        message.forEach(m => {
            const me = m.personOne && m.personOne.id === id;
            messageUnreadNum += me ? m.personOneUnread : m.personTwoUnread;
        })
        const unreadNoticeNum = data.notice.filter(n => !n.read).length;

        return [
            // <CoverPhoto />,
            <Banner key={0}/>,
            <Wrapper key={1}>
                <div className="div">
                    <Link className="text" to="/my_info/profile">
                        個人資料
                        <i className="material-icons">chevron_right</i>
                    </Link>
                    <hr className="hr" />
                    <Link className="text" to="/my_info/host_activities">
                        我的開團
                        <i className="material-icons">chevron_right</i>
                    </Link>
                    <hr className="hr" />
                    <Link className="text" to="/my_info/participated_activities">
                        我的報隊
                        <i className="material-icons">chevron_right</i>
                    </Link>
                    <hr className="hr" />
                    <Link className="text" to="/my_info/rent_contract_list">
                        羽你蓮心專案
                        <i className="material-icons">chevron_right</i>
                    </Link>
                    <hr className="hr" />
                    <Link className="text" to="/my_info/chat_room_list">
                        聊天內容
                        { 
                            // messageUnreadCount is from socket (at 2019 01 11)
                            // 假如有用 socket 更新就用 socket 的值
                            this.props.messageUnreadCount > 0 ?
                            (
                                <span className="notice">
                                    {
                                        this.props.messageUnreadCount < 100 ?
                                            this.props.messageUnreadCount
                                        :
                                        `99+`
                                    }
                                </span>
                            )
                            :
                            messageUnreadNum > 0   ?
                            (
                                <span className="notice">
                                    {messageUnreadNum < 100 ?
                                        messageUnreadNum
                                        :
                                        `99+`
                                    }
                                </span>
                            )
                            :
                            <i className="material-icons">chevron_right</i>
                        }
                    </Link>
                    <hr className="hr" />
                    <Link className="text" to="/my_info/follower_list">
                        我的追蹤
                        <i className="material-icons">chevron_right</i>
                    </Link>

                    {
                        userInfo && userInfo.affiliateStatus === 2 ?
                        <div>
                            <hr className="hr" />
                            <Link className="text" to="/my_info/social_selling_dashboard">
                                分銷商專區
                                <i className="material-icons">chevron_right</i>
                            </Link>

                        </div>
                        :
                        null
                    }
                    
                    <hr className="hr" />
                    <Link className="text" to="/my_info/notification_list">
                        我的通知
                        { 
                            this.props.notificationUnreadCount > 0 ?
                            (
                                <span className="notice">
                                    {this.props.notificationUnreadCount}
                                </span>
                            )
                            :
                            unreadNoticeNum > 0 ?
                            (
                                <span className="notice">
                                    {unreadNoticeNum < 100 ?
                                        unreadNoticeNum
                                        :
                                        `99+`
                                    }
                                </span>
                            )
                            :
                            <i className="material-icons">chevron_right</i>
                        }
                    </Link>
                    <hr className="hr" />
                    {/*<Link className="text" to="/my_info/service">
                        線上客服
                        <i className="material-icons">chevron_right</i>
                    </Link>
                    <hr className="hr" />*/}
                    <span
                        className="text"
                        onClick={this.clearLocalStorage}
                    >
                        {this.state.text}
                        <i className="material-icons">chevron_right</i>
                    </span>
                    <hr className="hr" />
                </div>
            </Wrapper>
        ];
    }
}

const mapStateToProps = state => ({
    message: state.messageReducer.privateChat,
    messageUnreadCount: state.messageReducer.privateChatUnreadCount,
    data: state.notificationReducer,
    notificationUnreadCount: state.notificationReducer.notificationUnreadCount,
    userInfo : state.myInfoReducer.userInfo,
})

const mapDispatchToProps = {
    getPrivateChatList: fetchPrivateChatList,
    getNotification: getNotification,
    messageCountListener: messageCountListener, 
    stopMessageCountListener: stopMessageCountListener,
    notificationCountListener: notificationCountListener,
    stopNotificationCountListener: stopNotificationCountListener,
    setUserInfoToRedux: setUserInfoToRedux,
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
