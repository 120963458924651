import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import Redirect from './Redirect';
import Login from './Login';
import AskForAuthorization from './AskForAuthorization';
import Register from './Register'; 
import ErrorPage from './ErrorPage';
import { Wrapper, Header, Body, Content } from "./styled";

class Dialog extends Component {

    render() {
        return (
            <Wrapper>
                <Header>
                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/logo/BonnySports-w.png" alt="BonnySports"/>
                </Header>
                {/*Body is used to clear origin style*/}
                <Body>
                    <Content>
                        <Switch>
                            <Route path="/dialog/oauth" component={Redirect} />
                            <Route path="/dialog/ask_for_authorization" component={AskForAuthorization} />
                            <Route path="/dialog/redirect" component={Redirect} />
                            <Route path="/dialog/register" component={Register} />
                            <Route path="/dialog/login" component={Login} />
                            <Route component={ErrorPage} />
                        </Switch>
                    </Content>
                </Body>
            </Wrapper>
        ); 
    }
}


export default Dialog;
