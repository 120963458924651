import { createAction } from "redux-actions";
import { fetchObject } from './fetchModule';
import { history } from '../index';
import querystring from 'querystring';
// loading Mask
export const shouldLoadingOpen = createAction("SHOULD_LOADING_OPEN");
export const getVerifyData = createAction("GET_VERIFY_DATA");
export const getAuthorizeData = createAction("GET_AUTHORIZE_DATA");

// OAuth 使用者資料驗證
// 必填欄位: clientId, redirectUri, scope
export const getVerify = (data) => {
    let params = querystring.stringify(data);
    return (dispatch) => {
        fetchObject(`OAuth/verify`, params, {
            method: 'GET',
        })
            .then(function(response) {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }
                    
                    return jsonparse;
                } 
            })
            .then((json) => { 
                if (typeof json.status !== 'undefined') {
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {  
                dispatch(getVerifyData(data));
            }, (error) => {
                switch(error.message) {
                    case "SOURCE_INVALID":
                    case "TOKEN_INVALID":
                        window.alert('資料發生異常，請重新操作。');
                        break;
                    case "OAUTH_INPUT_ERROR":
                    case "USER_NOT_EXIST":
                    case "CLIENT_INVALID":
                        window.alert('來源資料異常，請聯繫來源網站協助處理。');
                        break;
                    default:
                        window.alert('請聯繫來源網站協助處理！');
                }
                history.go(-1);
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    };
};

// 第三方向使用者提出授權請求
// 必填欄位： clientId, redirectUri, scope, authorization
export const postAuthorize = (body) => {
    //debugger
    return (dispatch) => {
        fetchObject(`OAuth/authorize`, "", {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((response) => {  
                // 打開 Loading Mask
                dispatch(shouldLoadingOpen(true));

                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                } 
            })
            .then((json) => {
                if (typeof json.status !== 'undefined') {
                    dispatch(getAuthorizeData(json));
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));
                history.replace({ pathname: '/dialog/redirect', state: { clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope, isAuthorization: true, code: data.code, status: data.status } });
            }, (error) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));

                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    case "OAUTH_INPUT_ERROR":
                    case "AUTHORIZATION_ERROR":
                    case "AUTHORIZATION_SAVE_ERROR":
                        window.alert('資料發生異常，請重新操作。')
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                }
                history.replace({ pathname: '/dialog/error', state: { clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope } });
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

// 已有雲羽集帳號，使用OAuth2.0協定登入驗證。
// 必填欄位: clientId, redirectUri, scope, email, password
export const userLogin = (body) => {
    //debugger
    return (dispatch) => {
        fetchObject(`OAuth/login`, "", {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((response) => {  
                // 打開 Loading Mask
                dispatch(shouldLoadingOpen(true));

                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                } 
            })
            .then((json) => {  
                if (typeof json.status !== 'undefined') {
                    // 設定 myData 到 localStorage
                    const myData = {id:json.userId, token:json.token, status:json.status, expireTime:json.expireTime}
                    localStorage.setItem('myData', JSON.stringify(myData)); 
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));
                history.replace({ pathname: '/dialog/ask_for_authorization', state: { clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope, email: body.email, status: data.status, code: data.code } });
            }, (error) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));

                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    case "SOURCE_INVALID":
                    case "CLIENT_INVALID":
                        window.alert('資料發生異常，請重新操作。')
                        break;
                    case "LOGIN_MISMATCH":
                        window.alert('帳號或是密碼有誤，請重新登入。')
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                        history.replace({ pathname: '/dialog/error', state: { clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope } });
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}


// 使用OAuth協定登入時，無雲羽集帳號，想要註冊新帳號
// 必填欄位: clientId, redirectUri, email, password
export const userRegister = (body) => {
    //debugger
    return (dispatch) => {
        fetchObject(`OAuth/signup`, "", {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((response) => {  
                // 打開 Loading Mask
                dispatch(shouldLoadingOpen(true));

                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                } 
            })
            .then((json) => {
                if (json === 'OK') {
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));
                history.replace({ pathname: '/dialog/login', state: {clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope} });
            }, (error) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));

                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    case "SOURCE_INVALID":
                    case "CLIENT_INVALID":
                        window.alert('資料發生異常，請重新操作。')
                        break;
                    case "EMAIL_USED":
                        window.alert('此電子信箱已經註冊過會員，請前往登入。')
                        history.replace({ pathname: '/dialog/login', state: { clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope } });
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                        history.replace({ pathname: '/dialog/error', state: { clientId: body.clientId, redirectUri: body.redirectUri, scope: body.scope } });
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

