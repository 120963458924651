
import styled from 'styled-components';

export const Wrapper = styled.div `
background-color: #d2d2d2;

@media (max-width: 480px) {
	padding-bottom: 120px;
	
}
.shoppingCart{
    cursor: pointer;
}
`
