import styled from "styled-components";

export const Wrapper = styled.div`
    background: #f2f2f2;
    color: #fff;
    font-size: 12px;
    padding: 0px 5px 7px 5px;
    overflow: hidden;
    position: relative;
    a{
        text-decoration: none;
        cursor: pointer;
    }
    .topInput{
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        height: 30px;
        line-height: 30px;
        text-align: center;
        .loc{
            width: 6em;
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            color: #E50614;
            span, p {
                font-weight: bold;
                // text-shadow: -1px 0 rgba(255,255,255,.3), 0 1px rgba(255,255,255,.3),1px 0 rgba(255,255,255,.3), 0 -1px rgba(255,255,255,.3);
            }
        }
        .date {
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            flex: 1;
            -webkit-flex: 1;
            -ms-flex: 1;
            width: calc(100% - 9em);
            span{
                width: 2em;
                text-align: center;
            }
            div {
                // width: calc(50% - 3em);
                // width: auto;
                div.react-datepicker__input-container{
                    width: 100%;
                    input {
                        width: 100%;
                        height: 30px;
                        text-align: center;
                        padding: 0;
                        margin: 0;
                        border-width: 0;
                    }
                    .select {
                        width: 15%;
                        select {
                            height: 32px;
                            width: 100%;
                            position: relative;
                            top: -2px;
                        }
                    }
                }
                // div.react-datepicker__portal{
                //     width:100vw;
                //     div{
                //         width:unset;
                //     }
                // }
            }
        }
        button.btn{
            border: none;
            margin: 0;
            padding: 0;
            width: 3em;
            font-size: 12px;
            text-align: center;
            background: none;
            color: #E50614;
            font-weight: bold;
            // text-shadow: -1px 0 rgba(255,255,255,.3), 0 1px rgba(255,255,255,.3),1px 0 rgba(255,255,255,.3), 0 -1px rgba(255,255,255,.3);
        }
    }
    .search-info{
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-top: 10px;
        .select{
            display: flex;
            width: 6em;
            margin: auto;
            select{
                width:100%;
                height: 30px;
            }
        }
        .input-button{
            display: flex;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            // width: calc(100% - 18em - 10px);
            margin: auto 5px;
            input{
                flex: 1;
                font-size: 12px;
                max-width: calc(70% - 5px);
                height: 30px;
                display: inline-block;
                vertical-align: bottom;
                margin:0;
                padding:0;
                padding-left: 5px;
                border-width: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            button{
                width: 30%;
                height: 30px;
                display: inline-block;
                vertical-align: bottom;
                margin:0;
                padding:0;
                border-width: 0px;
                border-top-left-radius: 0;
                border-bottom-left-radius:0;
                i{
                    line-height: 30px;
                }
            }
        }
        div{
            // width:100px;
            margin: auto;
            .select-group{
                width: 12em;
                .select{
                    width: 100%;
                    select{
                        width:100%;
                        height: 30px;
                    }
                }
            }
        }
    }
    .right-date {
        @media (max-width: 480px) {
            margin-left: -100px;
        }
        .react-datepicker__triangle {
            margin-left: 25% !important;
        }
    }

    input[type="text"] {
        padding-left: 5px;
    }
    @media (max-width: 480px) {
        input[type="text"] {
            padding: 0;
        }
    }
`;

export const SortSelect = styled.div`
    
`;

export const TopBarWrapper = styled.div`
    display: flex;
    align-items: center; 
    justify-content: space-between;
    
    label.dataText {
        width: 3rem;
        background-color: #fff;
        color: rgb(73, 80, 87);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 33px;
        display: flex;
        align-items: center; 
        justify-content: center;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(206, 212, 218);
        border-right: unset;
    }
    .dateSearch {
        flex: 0 0 50%;
        display: flex;
        align-items: center; 
        justify-content: center;
        margin-right: 5px;

        input[type="text"] {
            width: 100%;
            height: 33px;
            flex: 1;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: unset;

            &:hover {
                -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
                -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
                box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
                color: rgba(73, 80, 87, .8);
            }
        }
    }

    .sortSelect {
        flex: 1;

        div, select {
            width: 100%;
        }
    }
`;

export const BottomBarWrapper = styled.div`
    display: flex;
    align-items: center; 
    justify-content: space-between;

    div {
        width: 100%:
    }

    .selectLocation {
        display: flex;
        flex: 0 0 50%;
        align-items: center; 
        justify-content: space-between;
        margin-right: 5px;

        .loc {
            display: flex;
            flex: 1;
            margin-right: 5px;
            align-items: center; 
            justify-content: flex-start;
            background-color: #fff;
            color: rgb(73,80,87);
            border-radius: 5px;
            height: 33px;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(206,212,218);

            p.address {
                height: 35px;
                padding-left: 5px;
                flex: 1;
                display: flex;
                align-items: center; 
                justify-content: space-between;
                color: #da263c;
                font-weight: 500;
            }
            p.titleText {
                width: 2.52rem;
                flex: unset;
                padding-left: 0;
                text-align: center;
                font-weight: bold;

                i {
                    // margin: 0 2px;
                    color: rgba(218, 38, 60, .8);
                }
            }

            &:hover {
                .address {
                    -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
                    -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
                    box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.2) inset;
                    color: rgba(218, 38, 60, .6);
                }
            }
        }
        .map_button {
            width: auto;
            height: 35px;
            display: flex;
            align-items: center; 
            justify-content: center;

            a {
                color: #fff;
                display: flex;
                height: 35px;
                align-items: center; 
                justify-content: center;
                padding: 0 7px;
                border: none;
                border-radius: 5px;
                color: #fff;
                background: #da263c;  // #2f82bf
                text-align: center;
                cursor: pointer;

                &:hover {
                    -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                    -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                    box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                    color:#eee;
                }
            }
        }
    }

    .searchGroup {
        display: flex;
        flex: 1;
        align-items: center; 
        justify-content: center; 

        .activitysSearch {
            flex: 1;
            display: flex;
            align-items: center; 
            justify-content: center;
            margin-right: 5px;    

            input[type=text] {
            }   

            div.input-button {
                margin-right: 0;    

                button {    

                    &:hover {
                        -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                        -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                        box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                        color:#eee;
                    }
                }
            }
        }   

        .moreSearch {
            width: auto;
            margin-left: 2px;
            display: flex;
            align-items: center; 
            justify-content: center;    

            .loc {
                display: flex;
                align-items: center; 
                justify-content: center;    

                button.btn{
                    flex: 1;
                    height: 35px;
                    margin: 0;
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    background: #2f82bf;
                    text-align: center; 

                    &:hover {
                        -moz-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                        -webkit-box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                        box-shadow:1px 1px 3px 2px rgba(20%,20%,40%,0.5) inset;
                        color:#eee;
                    }
                }
            }
        }
    }

`;

