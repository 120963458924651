import React, { Component } from "react";
import { connect } from 'react-redux';
import { userRegister } from '../../../actions';
import {  
    LoadingIndicator, 
    GaSetting 
} from "../../Common";
import Checkbox from '../../Register/Checkbox';
import { Wrapper } from '../../Register/styled';
import { getCookie } from '../../../utilities';

class Register extends Component {
    constructor(props) {
        super(props);
        const landingPageCode = getCookie('LandingPage');
        let affiliateData = localStorage && localStorage.getItem('affiliate') ? JSON.parse(localStorage.getItem("affiliate")) : '';

        const state = this.props.location.state;
        this.state = {
            // name: '',
            email: '',
            // phone: '',
            password: '',
            authPassword: '',
            affiliateCode: affiliateData ? affiliateData.code : '',
            landingPageCode: landingPageCode ? landingPageCode : 'Origin',
            clientId: state && state.clientId,
            redirectUri: state && state.redirectUri,
            scope: state && state.scope,
        };
    }

    handleOnChange = (element, e) => {
        this.setState({ [element]: e.target.value })
    }

    handleInputChange = (event) => {
        // 從 event object 拿到 target
        const target = event.target;
        // 從 target.type 可以知道欄位的 type
        // 或從 target.value 取出輸入的欄位值
        const value = target.value;
        // 從 target.name 得到該欄位設定的 name
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let registercheck = 1;

        const { 
            // name, 
            email, 
            // phone, 
            affiliateCode, 
            password, 
            authPassword,
            clientId,
            redirectUri,
            scope
        } = this.state;
        const emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
        // const mobileRule = /^[09]{2}[0-9]{8}$/;
        const passwordRule = /^(?=.*[a-zA-Z]).{6,15}$/;

        // if (name === '') {
        //     alert('姓名不得為空');
        //     registercheck = 0;
        // }
        if (email === '' || email.search(emailRule) === -1) {
            alert('E-MAIL不得為空或格式錯誤');
            registercheck = 0;
        }
        // if (phone === '' || String(phone).replace(/\s+/g, "").length === 0 || phone.search(mobileRule) === -1) {
        //     alert('電話不得為空或格式錯誤');
        //     registercheck = 0;
        // }
        if (password === '' || password.search(passwordRule) === -1) {
            alert('密碼不得為空或格式錯誤');
            registercheck = 0;
        }
        if (authPassword === '') {
            alert('密碼驗證不得為空');
            registercheck = 0;
        }
        if (authPassword !== '' && authPassword !== password) {
            alert('密碼與驗證密碼不相符');
            registercheck = 0;
        }
        if (JSON.parse(localStorage.getItem('check')) !== true) {
            alert('請閱讀並接受個資保護聲明');
            registercheck = 0;
        }

        if (registercheck === 1) {
            const body = { 
                // name, 
                email, 
                password, 
                // phone, 
                affiliateCode, 
                clientId, 
                redirectUri, 
                scope, 
            };
            this.props.userRegister(body);
        } else {
            return '';
        }
    }

    render() {
        const { 
            // name,
            email,
            // phone,
            password,
            authPassword,
            landingPageCode,
            clientId,
            redirectUri,
            scope 
        } = this.state;
        return (
            <Wrapper key={1}>
                {
                    landingPageCode &&
                        <GaSetting
                            data = {{
                                name: 'componentDidMount', 
                                category: 'OAuth',
                                action: 'Register_PV',
                                label: `isType${landingPageCode}`,
                            }}
                        />
                }
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 會員註冊
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />

                <h2 className="title">會員註冊</h2>
                <form className="main-form" style={{textAlign: `left`}}>
                    {/*<div className="form-control" style={{display: `none`}}>
                        <label>姓名：</label>
                        <input name='name' type="text" value={name} onChange={this.handleInputChange} placeholder="請輸入您的姓名" />
                    </div>*/}
                    <div className="form-control">
                        <label>Email(登入帳號)：</label>
                        <input name='email' type="text" value={email} onChange={this.handleInputChange} placeholder="請輸入您的E-MAIL(此為日後登入帳號)"/>
                    </div>
                    {/*<div className="form-control" style={{display: `none`}}>
                        <label>行動電話：</label>
                        <input name='phone' type="text" value={phone} onChange={this.handleInputChange} placeholder="請輸入您的行動電話" />
                    </div>*/}
                    <div className="form-control">
                        <label>密碼：</label>
                        <input name='password' type="password" value={password} onChange={this.handleInputChange} placeholder="請輸入您的密碼(6~15位英文數字混合)" />
                    </div>
                    <div className="form-control">
                        <label>密碼確認：</label>
                        <input name='authPassword' type="password" value={authPassword} onChange={this.handleInputChange} placeholder="請再次輸入您的密碼" />
                    </div>
                </form>

                <Checkbox check={JSON.parse(localStorage.getItem('check'))} />

                <form className="main-form form" >
                    <div className='SubmitButton' onClick={this.handleSubmit}>
                        <GaSetting 
                            data = {{
                                className: 'Member', 
                                category: 'OAuth', 
                                action: 'Register_isSubmit', 
                                label: `isType${landingPageCode}`, 
                            }}
                        >
                            確認註冊
                            <i className="material-icons" style={{color: `#fff`, fontSize: `2rem`, verticalAlign: `middle`,}}>
                              arrow_right
                            </i>
                        </GaSetting>   
                    </div>
                    <span className="text" style={{fontSize: `16px`,}}>已擁有Bonny雲羽集帳號?</span>
                    <GaSetting
                        data = {{
                            className: 'link', 
                            category: 'OAuth', 
                            action: 'Register_isBackToLogin', 
                            label: `isType${landingPageCode}`,   
                            tag: 'link',
                            path: {
                                pathname: '/dialog/login',
                                state: {
                                    clientId: clientId,
                                    redirectUri: redirectUri,
                                    scope: scope,
                                }
                            },
                        }} 
                    >
                        我要登入
                    </GaSetting>
                </form>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.oauthReducer.shouldLoadingOpen,
});


const mapDispatchToProps = (dispatch) => ({
    userRegister: (body) => {
        dispatch(userRegister(body));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
