import React, { Component } from "react";
import moment from 'moment';
import { 
    Modal,
    SubmitButton, 
    CommentBox, 
    LoadingIndicator,
    InvoiceField,
} from "../Common";
import Banner from './Banner';
import Information from './Information';
import { Wrapper } from './styled';
import { connect } from "react-redux";
import { 
    signUpGroup, 
    getGroupDetail, 
    getJoinedList, 
    fetchRegister, 
    followUser, 
    unFollowUser, 
    followList, 
    createActivityPaymentOrder,
    setIsGetPaymentRes,
    fetchUserInfo
} from "../../actions";
import { withRouter, Link } from 'react-router-dom';
import store from '../../store';
import { localStorageUtility, copyLink } from '../../utilities';

var id = 0;
class GroupDetail extends Component {
    constructor(props) {
        super(props);
        const linkQuery = this.props.location.search;
        const LinkArr = linkQuery.split('&');
        if (LinkArr.length !== 1) {
            this.props.history.push("/sign_up_activity" + LinkArr[0]);
        }
        const data = localStorageUtility('get', 'myData');
        if ( !data ) {
            document.cookie = "backurl=" + window.location.href;
        }
        const userInfo = localStorageUtility('getAndParse', 'myData');
        var url = window.location.href;
        var index = url.indexOf("?");
        this.state = {
            isSignUp: false,
            modalIsOpen: false,
            isLogined: userInfo.token ? true : false,
            regularJoin: false,
            user: userInfo,
            groupID: url.substring(index + 1, url.length),
            paymentType: 'Cash',
            isSendPaymentClick: false,
            isInvoiceOptionShow: false,
            invoiceInfo:{
                // default value
                invoiceType: 'twoCopies',
                invoiceCheck: true,
                vatNumber: '',
                print: 0,
                donation: 0,
                loveCode: '',
                carruerType: 1,
                carruerNum: '',
                errMessage: '',
            },
        };
    }

    componentDidMount() {
        id = parseInt(this.state && this.state.groupID);
        this.props.getGroupDetail(id);

        let userInfo = localStorageUtility('getAndParse', 'myData');
        const token = userInfo ? userInfo.token : "";
        if (token){
            this.props.followList({ type: "follower" });
            this.props.getJoinedList();
            this.props.getUserInfo();
        }
        
        let expiresTime = localStorageUtility('get', 'expiresTime');
        if (!expiresTime) return
        let expiresDate = Date.parse(expiresTime);
        let now = moment().valueOf();

        if (now > expiresDate) {
            localStorageUtility('set', 'rememberMe', false);
            localStorageUtility('set', 'expiresTime', '');
            localStorageUtility('remove', 'myData');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let userInfo = localStorageUtility('getAndParse', 'myData');

        if(!prevProps.activityInformation.user && !!this.props.activityInformation.user){
            if(this.props.activityInformation.user && this.props.activityInformation.user.id === userInfo.id){  
                this.props.history.push('/my_info/host_activity_detail/' + id);
            }
        }

        if( 
            prevProps.isGetPaymentRes !== this.props.isGetPaymentRes 
            &&
            this.props.isGetPaymentRes === true 
            && 
            this.state.paymentType === 'CreditEcpay' 
            && 
            this.state.isSendPaymentClick === true
        ) {
            document.getElementById("purchase_form").submit();
        }
    }

    handleSubmit = (e) => {
        if ( this.state.isLogined === false ) {
            document.cookie = "backurl="+window.location.pathname;
            alert('請先登入！')
            this.props.history.push('/login');
        } else {
            this.props.signUpGroup({ activity: id, rejoin: this.state.regularJoin });
        }
    }

    checkMembership = () => {
        const { userInfo } = this.props;
        if (userInfo && userInfo.phoneVerified === false) {
            document.cookie = "backurl="+window.location.pathname;
            alert('您的手機尚未經過驗證，請先完成驗證')
            this.props.history.replace('/my_info/profile')
        } else {
            this.openModal();
        }
    }

    openModal = (e) => {
        this.setState({ modalIsOpen: true });
    }

    sendPayment = (paymentData) => {
        this.setState({ 
            paymentType: paymentData.paymentType,
            isSendPaymentClick: true, 
        });
        this.props.createActivityPaymentOrder(paymentData);
    }

    handleFollowUser = (e) => {
        e.preventDefault();
        const userInfo = localStorageUtility('getAndParse', 'myData');
        const token = userInfo ? userInfo.token : "";
        if (!token) {
            alert('請先登入！');
            this.props.history.push('/login');
        } else {
            var isFollowed = this.isFollowed();
            if (isFollowed) {
                this.props.unFollowUser({ id: this.getFollowId(this.props.activityInformation.user.id) });
            } else {
                this.props.followUser({ followee: this.props.activityInformation.user.id });
            }
        }
    }

    isActivityBelongToCurrentUser = () => {
        let { activityInformation } = this.props;
        if (activityInformation && activityInformation.user && this.state.user) {
            return activityInformation.user.id === this.state.user.id
        }
        return false;
    }

    isFollowed = () => {
        var followList = this.props.follow
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === this.props.activityInformation.user.id) {
                    return true;
                }
            }
        }
        return false;
    }

    getFollowId = () => {
        var followList = this.props.follow;
        if (followList && followList.length > 0) {
            for (var i = 0; i < followList.length; i++) {
                if (followList[i].followee.id === this.props.activityInformation.user.id) {
                    return followList[i].id;
                }
            }
        }
        return 0;
    }

    regularJoin = (e) => {
        this.setState({ regularJoin: e.target.checked });
    }

    enableSignUp = (activityStatus, activityStartTime) => {
        let enableSignUp = true
        const pastActivity = moment(activityStartTime).isBefore(moment());
        if (activityStatus === 0 || activityStatus === -1 || pastActivity) {
            enableSignUp = false;
        }
        return enableSignUp;
    }

    handleChangeInvoiceInfoState = (invoiceInfo)=>{
        this.setState({
            invoiceInfo:{
                ...invoiceInfo
            }
        })
    }

    handleShowInvoiceOption = (isShow)=>{
        this.setState({
            isInvoiceOptionShow: isShow
        })
    }

    render() {
        var { joinedList, activityInformation } = this.props;
        var refundPeriod = (activityInformation && activityInformation.refundPeriod) || '1';
        var groupFee = activityInformation && activityInformation.fee;
        var groupUserId = activityInformation.user && activityInformation.user.id;
        var isSignUp = false;
        var isPaid = true;
        var joinID = 0;
        var joinedStatus = true;

        if (joinedList && Array.isArray(joinedList)) {
            joinedList.forEach(joinedListData => {
                if (joinedListData && joinedListData.activity.id === id) {
                    let lengthCheck = joinedList.filter(n => n.activity.id === id).length;
                    if (lengthCheck <= 1) {
                        isSignUp = true;
                        joinID = joinedListData.id;
                        isPaid = joinedListData.paid;
                        joinedStatus = joinedListData.status;
                    } else {
                        if (joinedListData.status) {
                            let joinedListNew = joinedList.filter(n => n.activity.id === id);
                            joinedListNew.sort(function(a, b){return a.createdAt - b.createdAt});
                            let joinedNew = joinedListNew[joinedListNew.length - 1];
                            isSignUp = true;
                            joinID = joinedNew.id;
                            isPaid = joinedNew.paid;
                            joinedStatus = joinedNew.status;
                        }
                    }
                }
            })
        }

        var getSignUpButtonAndStatus= () => {
            var { remain, status, start, end, frequency, plan, user } = activityInformation;
            const userInfo = localStorageUtility('getAndParse', 'myData');

            if (this.state.isLogined === true) { //登入
                if (user && user.id !== userInfo.id) { //非團主
                    if (isSignUp === true && joinedStatus === true) { //已報名
                        if (new Date().valueOf() < end) {
                            if (status === 1) { //活動上架中
                                if (groupFee !== 0) { //需付款
                                    if (isPaid) { //已付款
                                        return (
                                            <div key={1} className="b1">
                                                已報名（已付款）
                                            </div>
                                        )
                                    }
                                    else { //未付款
                                        return ([
                                            <div key={1} className="b1" style={{marginBottom: '5px'}} >
                                                已報名（未付款）
                                            </div>,
                                            <div key={2} style={{display: 'flex', marginTop: '.5rem' }} >

                                                {/* // 線上付費按鈕 */}
                                                {(plan && ((plan.CreditEcpay !== 0) && (plan.CreditEcpay !== null) && (plan.CreditEcpay !== undefined) )) ? 
                                                    <div style={{display: 'flex', flex: '1'}} key={2}>
                                                        { this.state.isInvoiceOptionShow ?
                                                            <SubmitButton text={"線上付費"}
                                                                noImg ={true}
                                                                onClick={ ()=>{ 
                                                                    this.handleShowInvoiceOption(true);
                                                                }} 
                                                            />
                                                            :
                                                            <SubmitButton text={"線上付費"}
                                                                onClick={ ()=>{ 
                                                                    this.handleShowInvoiceOption(true);
                                                                    store.dispatch(setIsGetPaymentRes(false));
                                                                }} 
                                                            />
                                                        }
                                                    </div>
                                                :
                                                null}

                                                {
                                                    (plan && ((plan.CreditEcpay !== 0) && (plan.CreditEcpay !== null) && (plan.CreditEcpay !== undefined) )) &&
                                                    (plan && ( (plan.Cash !== 0) && (plan.Cash !== null) && (plan.Cash !== undefined) )) ?
                                                    <div style={{width: '4%'}}>
                                                    </div>
                                                    :
                                                    ""

                                                }

                                                {/* // 現場付費按鈕 */}
                                                {(plan && ( (plan.Cash !== 0) && (plan.Cash !== null) && (plan.Cash !== undefined) )) &&
                                                    <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end'}} key={3}>
                                                        <Modal
                                                            title="付款未完成"
                                                            onClick={(e) => {
                                                                this.sendPayment({activityId: parseInt(id), joinId: parseInt(joinID), paymentType: `Cash`},e)
                                                            }}
                                                            btnTheme={"two"}
                                                            text1="確認"
                                                            modalIsOpen={this.state.modalIsOpen}
                                                            noButton={true}
                                                            notButtonStyle={{width:'100%'}}
                                                            modalContent={
                                                                <div>
                                                                    <div style={{textAlign: `center`,}}>請與團主聯絡付款事宜，謝謝您！</div>
                                                                    <div className="aLinkDiv" style={{width: `100%`,}}>
                                                                        <Link to={`/my_info/chatroom/user_id=` + groupUserId}>
                                                                            聯絡團主
                                                                        </Link> 
                                                                    </div>  
                                                                </div>
                                                            }
                                                        >
                                                            <div id="otherActvity">
                                                                <SubmitButton text={"現場付費"} 
                                                                    style = {{background: '#2f82bf'}}
                                                                    onClick={ ()=>{ 
                                                                        this.openModal() 
                                                                        this.handleShowInvoiceOption(false)
                                                                    }} 
                                                                />
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                }

                                            </div>,
                                            
                                            // 發票
                                            <div key={3}>
                                            {
                                                this.state.isInvoiceOptionShow ?
                                                <>
                                                    <InvoiceField 
                                                        isGroupDetail = {true}
                                                        handleChangeParentState = {this.handleChangeInvoiceInfoState }
                                                    />
                                                    <br />

                                                    {
                                                        this.state.invoiceInfo.invoiceCheck === false ?
                                                        <div id="otherActvity">
                                                            <SubmitButton text={"前往付款"} 
                                                                onClick={ ()=>{
                                                                    console.log("click, check === false");
                                                                    alert(this.state.invoiceInfo.errMessage)
                                                                }}
                                                            />
                                                        </div>
                                                        :

                                                        <Modal
                                                            title="前往付款確認"
                                                            onClick={(e) => {
                                                                delete this.state.invoiceInfo.errMessage
                                                                delete this.state.invoiceInfo.invoiceCheck
                                                                delete this.state.invoiceInfo.invoiceType
                                                                this.sendPayment(
                                                                    {
                                                                        activityId: parseInt(id), 
                                                                        joinId: parseInt(joinID), 
                                                                        paymentType: `CreditEcpay`,
                                                                        ...this.state.invoiceInfo,
                                                                    },
                                                                    e
                                                                )
                                                            }}
                                                            modalIsOpen={this.state.modalIsOpen}
                                                            btnTheme={"two"}
                                                            text1="確認"
                                                            noButton={true}
                                                            key= {'confirmjoin1'}
                                                            modalContent={
                                                                <div>
                                                                    <div>溫馨提醒：</div>
                                                                    <div>此活動於
                                                                        <span style={{color: `#da263c`,fontWeight: `500`,}}>
                                                                            {moment(parseInt(start)).subtract(refundPeriod, 'days').format('MM月DD日 LT')}
                                                                        </span>
                                                                        後僅提供請假服務，不提供退款服務，若同意再點選確認按鈕跳轉至線上付款頁面。
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <div id="otherActvity">
                                                                <SubmitButton text={"前往付款"} 
                                                                    onClick={ ()=>{
                                                                        // 在 sent 時候 delete，下面有沒有 delete 會影響上面 console 的 state，why?
                                                                        this.openModal()
                                                                    }}
                                                                />
                                                            </div>
                                                        </Modal>
                                                    }
                                                </>
                                                :
                                                ''
                                            }
                                            </div>,
                                            <hr key={4} style={{marginTop: '2rem'}} />,
                                        ])
                                    }
                                }
                                else{ //活動不須付款
                                    return (
                                        <div key={1} className="b1">
                                            報名成功
                                        </div>
                                    )
                                }
                            }
                            else if(status === -1){ //報名截止
                                return (
                                    <div key={1} className="b1">
                                        報名截止
                                    </div>
                                )
                            }
                            else{ //活動已取消
                                return (
                                    <div key={1} className="b1">
                                        活動已取消
                                    </div>
                                )
                            }
                        }
                        else{ //活動已結束
                            return (
                                <div key={1} className="b1">
                                    活動已結束
                                </div>
                            )
                        }
                    }
                    else{ //未報名
                        if(start >= new Date().valueOf()){ //活動未到期
                            if(remain !== 0){ //人數未滿
                                if(this.isActivityBelongToCurrentUser() === false) {
                                    if(this.enableSignUp(status, start)){
                                        return ([
                                            (this.isActivityBelongToCurrentUser() === false && frequency) 
                                            &&
                                                <div key='0' className="checkbox">
                                                    <input type="checkbox" value={this.state.regularJoin} onChange={this.regularJoin}/> 
                                                    <h3>固定參加</h3>
                                                </div>
                                            ,
                                            <Modal
                                                title="報名確認"
                                                onClick={(e) => {
                                                    this.handleSubmit(e);
                                                }}
                                                btnTheme={"two"}
                                                text1="確認"
                                                modalIsOpen={this.state.modalIsOpen}
                                                noButton={true}
                                                key = {'confirmjoin1'}
                                                modalContent={
                                                    <div>
                                                        <div>點擊確認即完成報名，如需與團主聯絡可於【 My Bonny/我的報隊 】中查看開團資訊與討論。</div>
                                                        <br />
                                                        <div className="shareLinkButton" onClick={ ()=>{ copyLink('/quick_sign_up_activity', true, `?${this.state.groupID}`) }} >分享連結</div>
                                                    </div>
                                                }
                                            >
                                                {
                                                    <div id="otherActvity">
                                                        <SubmitButton text={"我要報名"} onClick={this.checkMembership} />
                                                    </div>
                                                }
                                            </Modal>
                                        ])
                                    }
                                }
                            } 
                        }
                        else{
                            return (                          
                                <div key={1} className="b1">已結束報名</div>
                            )
                        }
                    }
                }
            }
            else { //未登入
                return (
                    <div>
                        <Link key={1} className="b1" to="/login">請先登入</Link>
                    </div>
                )
            }
        }
        
        return (
            <Wrapper key={0}>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <Banner 
                    activityInformation={this.props.activityInformation} 
                    comments={this.props.chatroom_data} 
                    follow={this.handleFollowUser} 
                    followList={this.props.follow} 
                    isBelongToMe={this.isActivityBelongToCurrentUser()} 
                />
                <div className="main-form">
                    <Information 
                        activityInformation={this.props.activityInformation} 
                        comments={this.props.chatroom_data} 
                        follow={this.handleFollowUser} 
                        followList={this.props.follow} 
                        isBelongToMe={this.isActivityBelongToCurrentUser()} 
                    />
                    <br />

                    {getSignUpButtonAndStatus()}

                </div>
                <CommentBox chatroom={this.props.activityInformation.chatroom} />
                {this.isActivityBelongToCurrentUser() === false ?
                    <div className="m_b_100"></div>
                    :
                    null
                }
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.groupDetailReducer.shouldLoadingOpen,
    activityInformation: state.groupDetailReducer.activityInformation,
    joinedList: state.groupDetailReducer.joinedList,
    chatroom_data: state.commentReducer.chatroom_data,
    follow: state.groupDetailReducer.follow,
    isGetPaymentRes: state.groupDetailReducer.isGetPaymentRes,
    userInfo: state.myInfoReducer.userInfo,
});

const mapDispatchToProps = ({
    signUpGroup: signUpGroup,
    getGroupDetail: getGroupDetail,
    getJoinedList: getJoinedList,
    fetchRegister: fetchRegister,
    followUser: followUser,
    followList: followList,
    unFollowUser: unFollowUser,
    createActivityPaymentOrder: createActivityPaymentOrder,
    getUserInfo: fetchUserInfo,
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupDetail));