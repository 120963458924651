export const getCookie =  (key) => {
    let cookieEqual = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        let cookiePart = cookieArray[i];
        cookiePart = cookiePart.replace(/^\s+/g, '')
        if (cookiePart.indexOf(cookieEqual) === 0) {
            return cookiePart.substring(cookieEqual.length, cookiePart.length);
        }
    }
    return "";
}

// for(let i = 0; i < arrCookie.length; i++){
//             let arr = arrCookie[i].split("=");
//             //找到名稱為path的cookie，並返回它的值
//             if("path" === arr[0]){
//                 path = arr[1];
//                 break;
//            　}
// }


// getCookie(name) {
//     let cookie = {};
//     document.cookie.split(';').forEach(function (el) {
//         let [k, v] = el.split('=');
//         cookie[k.trim()] = v;
//     })
//     return cookie[name];
// }