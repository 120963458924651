import React, { Component } from "react";
import { Wrapper } from './styled.js';
import { openExternalWebsiteUrl } from '../../../utilities';

class ContractLightBox extends Component {

  lightBox(e){
      var deal = document.getElementById("ContractLightBox");
      deal.classList.remove("close");
  }
  deal(e){
      var deal = document.getElementById("ContractLightBox");
      deal.classList.add("close");
  }
  render() {
    return (
		<Wrapper>
      <div id="ContractLightBox" className="lightBox-Contract close">
        <div className="lightBox-Contract-Content">
            <div className="lightBox-Contract-Content-inside">
                <div className="lightBox-inside-close-button" onClick={this.deal}>X</div>
                <div className="title">羽你蓮心會員合約書</div>
                <div>
                  <p className="sub">
                    前言
                  </p>
                  <p>波力公司長期以提升「身心靈」為推廣運動願景，有感於年輕球友需長期存錢方能購置「頂級羽拍」，又得面臨眾人皆具的「非預期撞擊或斷裂」的恐懼，波力歷經36年的創新研發，得以推出「全面關照球友痛楚」之業界首創，且與球友互信之「連心」頂級羽拍會員限量專案「羽你蓮心」，也得以讓偏鄉學子揮享頂級拍的歡欣，真切瀰漫在羽球飛動的聲影中。</p>
                  <p>透過「羽你蓮心」，入門球友亦可輕鬆暢享頂級拍，享有三年蓮心保障期，以最低門檻、最大保障，使用最適頂級拍。三年約滿後若想換置新拍，且不想留用原舊拍，波力建議轉贈給偏鄉學校（名冊波力提供），並透過波力付費指派之物流寄至指定學校，俾球友與資源短缺的學子們持久連心，蓮心久久。</p>
                  <p>波力「羽你蓮心」，為所有「球友」和資短「學子」傳達一份「連結」心意，也期盼認許「物盡其用」的熱情同好，響應「羽您蓮心」的創舉！</p>
                  <br/>
                  <p className="sub">
                    合約書
                  </p>
                  <p>茲「羽你蓮心」會員（以下簡稱「甲方」）透過薩摩亞商順譽世界企業有限公司台灣分公司（以下簡稱「乙方」）指定網頁申請羽你蓮心會員專案，為便利甲方繳納蓮心專案服務費用，及便利乙方透過指定代收平台金流服務收取費用，甲、乙雙方同意為「網路電子商務代收代付」之服務事宜特立本契約，雙方約定遵守條款如下：</p>
                  <p className="sub">壹、合約期間</p>
                  <p className="indent">甲、乙雙方同意，本合約起訖日依甲方首次線上申請，且付款後十天列為合約起始日，為期三年，依合約起始日往後三年為截止日，本合約如有修正或變更，得經雙方同意後換文為之。</p>
                  <p className="sub">貳、合作事項</p>
                  <ul className="indent">
                    {this.props.coorporationMatterText1 ? 
                      this.props.coorporationMatterText1 
                      :
                      <li>
                        一、  甲方同意並接受透過乙方會員專案（不限活動網址、廣告、公告、活動規定或宣傳內容），由甲方線上擇一申請乙方提供之波力三支頂級羽拍（1982Ⅱ、InfinityⅡ、PrincessⅡ定價皆為新台幣6,800元），並自合約起始日起，由乙方提供甲方三年蓮心保障期（不含羽線及柄皮）。
                      </li>
                    }
                    {this.props.coorporationMatterText2 ?
                      this.props.coorporationMatterText2 
                      :
                      <li>
                        二、  甲方同意接受並遵循乙方付款條件獲取球拍所有權：甲方可選擇年繳制，月繳制，或是一次付清。
                        <p>若選擇年繳制者，三年內分四期，每年初依定期定額方式支付新台幣999元，甲方於首次付款999元後，即可獲取選配球拍和贈品，乙方將於確認甲方付款後，將球拍和贈品寄達甲方指定地址。三年期滿當時，除分別於第一/第二/第三年初已付三期款各新台幣999元之外，甲方須於合約期滿日之前支付第四期款999元，以獲取球拍所有權，以上合計年繳制總支付金額為3996元。</p>
                        <p>若選擇月繳制者，三年內分三十六期，每月依定期定額方式支付新台幣115元，甲方於首次付款115元後，即可獲取選配球拍和贈品，乙方將於確認甲方付款後，將球拍和贈品寄達甲方指定地址。甲方須繳滿三十六期，以獲取球拍所有權。以上合計月繳制總支付金額為4140元。</p>
                        <p>若選擇一次付清者，則於首年申請時，支付3996元，一次繳清費用，即可獲取選配球拍和贈品。除了原專案贈品，一次付清者將加贈波力運動襪一雙。乙方將於確認甲方付款後，將球拍和贈品寄達甲方指定地址。</p>
                      </li>
                    }
                    <li>
                      三、  大力士條款：甲方同意並接受因乙方公司據近四十年承創國際名牌及頂尖球星用拍的經驗，足以判斷球拍是否正常受護，故對於合約期間三年內斷拍換新次數最多以兩次為限（含），若甲方於合約期間內到達此約定上限，甲方則即無條件同意並接受乙方於約滿後可能不再與甲方續約此專案的權利。
                    </li>
                    <li>
                      四、  本契約由乙方提供甲方三年蓮心保障期（不含羽線及柄皮），甲方同意接受並遵循乙方「羽你蓮心」使用規則，於合約期間內，首次申請斷拍換新經乙方核定後，甲方須先支付新台幣599元蓮心保障費給乙方，乙方將於確認收到款項後把新拍寄達給甲方指定地址。而在第二次申請斷拍換新經乙方核定後，甲方須先行支付新台幣899元蓮心保障費給乙方，乙方將於確認收到款項後把新拍寄達給甲方指定地址。甲方同意並接受若三年內斷換新拍達到兩次，則在第二次新拍寄達甲方時，本約即視為期滿，故甲方須在乙方第二次新拍寄出前（除年繳制確認前三期已完繳外），應繳清未到期之所有應付款項，以取得具高殘值的球拍所有權。
                    </li>
                    <li>
                      五、  甲、乙雙方同意，甲方申請斷拍換新服務，需於乙方核定更換新拍，並由甲方付款後十個工作天內（限台灣本島，台灣離島則為二十個工作天內），乙方將甲方申請之羽拍寄於甲方指定地址。收回斷拍與寄送新拍，皆由乙方指定之物流，運費皆由乙方支付（限台灣本島，台灣離島部分，由甲方先將斷拍原貌照片提供給乙方核定是否屬斷拍，經乙方照片核定屬斷拍者，則乙方寄送新拍至甲方指定地址並負擔運費。若乙方依照片無法核定是否斷拍，可要求甲方寄回原拍以完成核定，運費由甲方自行負擔。乙方於收受前開原拍並核定屬斷拍後，寄送新拍至甲方指定地址，並負擔寄送新拍之運費。如乙方收受前開原拍然核定非屬斷拍，則甲方需負擔寄回原拍至甲方指定地址之運費）。
                    </li> 
                    <li>
                      六、  甲、乙雙方同意並接受，於合作期間內凡甲方支付乙方費用，乙方則須在首次支付費用時立即開立合約期滿之全額發票（換拍支付蓮心保障費則額外開立發票）。
                    </li> 
                    <li>
                      七、  甲、乙雙方同意並接受，於合作期間內且期滿三年後，若甲方均未提出過任何一次斷拍換新之申請，且在三個月內申請持續蓮心會員者，即可獲得乙方特製之蓮心球衣乙件，價值新台幣1000元以上。
                    </li>
                    <li>
                      八、  甲、乙雙方同意並接受如遇合約期間，除欲調整為一次付清蓮心專案服務費用外，不得更動付款條件，若遇甲方提出申請（不限電話、Email）告知改為一次付清蓮心專案服務費用，則線上申請即轉由乙方客服線下處理，並由乙方調整線上專案付款方式。
                    </li> 
                  </ul>
                  <p className="sub">叁、合作專案</p>
                  <ul className="indent">
                    <li>一、  甲方同意並接受以線上勾選方式進行線上支付蓮心專案服務費用，並同意於合約期間內不更動付款方式，斷拍換新亦不影響申請時選擇之付款條件（即年繳/月繳/一次付清），皆依照首次付款方式支付蓮心專案服務費用。
                      {this.props.coorporationMatterText3 ?
                        this.props.coorporationMatterText3
                        :
                        <ul className="square">
                          <li>年繳：三年內分四期，每年依定期定額方式支付新台幣999元，三年期滿當
                              時，除分別於第一/第二/第三年初已付三期款各新台幣999元之外，須
                              於合約期滿日之前支付第四期款999元，以獲取球拍所有權，以上合計
                              年繳制總支付金額為3996元。
                          </li>
                          <li>月繳：三年內分三十六期，每月依定期定額方式支付新台幣115元，須繳滿三
                              十六期，以獲取球拍所有權。以上合計月繳制總支付金額為4140元。
                          </li>
                          <li>一次付清3996元，加贈波力運動襪一雙。</li>
                        </ul>
                      }
                    </li>
                    <li>二、  甲方同意依照乙方「羽你蓮心」報名指定網址，以線上勾選方式選配球拍，並同意於線上選擇後，遵守合約期間內不更換選配球拍。</li>
                    <li>三、  甲方獲取選配球拍時，乙方將隨拍另贈價值千元之羽線與柄皮。甲方同意以下列勾選方式進行選配贈品方案，並同意當贈品方案選擇為贈品B時（甲方指定一般22±1或進階24±1磅數由波力客製化穿線服務乙次、波力羽球線4組、兼具加大握柄功能之柄皮1條），即同意放棄空拍七天鑑賞期並直接使用該服務。
                      <ul className="square">
                        <li>贈品A：波力羽球線6組（羽拍穿線甲方自理）、兼具加大握柄功能之柄皮1條，市值約1000元。</li>
                        <li>贈品B：甲方指定一般22±1或進階24±1磅數由波力客製化穿線服務乙次、波力羽球線4組、兼具加大握柄功能之柄皮1條，市值約1000元。</li>
                      </ul>
                    </li>
                    <li>四、  甲方於本專案約滿後，若想換置新拍，且不想留用原舊拍，則甲方可選擇將舊拍轉贈給乙方建議之偏鄉學校（名冊由乙方提供），並透過乙方付費指派之物流，寄送至甲方指定之學校。例如：南投縣長流國小體育組收。地址：國姓鄉長流村大長路559號，電話：049-243-1006</li>
                  </ul>
                  <p className="sub">肆、服務義務</p>
                  <ul className="indent">
                    <li>一、  甲方如已委託乙方辦理定期定額支付蓮心專案服務費用，應依本契約指定之條件及服務日期，以定期定額或直接付款的方式，經由綠界平台金流服務支付至乙方帳戶。</li>
                    <li>二、  甲方如已定期定額購買有價蓮心專案服務，甲方同意授權乙方指定之金融機構應按期將委繳戶之各項費用，自甲方之帳戶進行自動扣款轉帳付款作業並將轉帳款項撥入乙方指定帳戶，作為甲方買入使用乙方蓮心專案服務之費用，除一次付清外，依照甲方選擇年繳或月繳其一，並依該方案付款條件支付蓮心專案服務費用。</li>
                    <li>三、  甲方同意並接受使用前開方案前須先行加入乙方（薩摩亞商順譽世界企業有限公司 台灣分公司）旗下之網站雲羽集bonny-badminton會員，並同意此帳號僅能申請使用蓮心方案上限為兩次（含）（合約期滿續約者不在此限），每次僅能申請一支。</li>
                    <li>四、  甲方同意並接受申請使用本服務，應於網站登錄完整、詳實、真實且為符合身分證明文件之個人資料，甲方所登錄之資料有錯誤或已變更時，應隨時更正之。</li>
                    <li>五、  乙方向甲方保證，因合約須交付甲方之商品需為新品，且均能正常使用無虞。若非新品或有非可歸責於甲方事由所發生之毀壞，乙方同意免費予以更換。</li>
                    <li>六、  甲方因信用卡毀損滅失、有效期間屆滿續卡等情形而更換新卡、但未更換卡號時，本授權書之效力並不因此而受影響。</li>
                    <li>七、  甲方支付之定期定額費用如因甲方信用卡到期或其他一切不可歸咎於乙方因素導致無法執行扣款時，乙方有權先行通知甲方進行付款，若經乙方通知甲方遲未辦理，乙方有權要求甲方負擔損失賠償之責。</li>
                    <li>八、  本契約之終止，不影響甲乙雙方於本契約終止前已發生之權利義務。</li>
                  </ul>
                  <p className="sub">伍、保障期</p>
                  <ul className="indent">
                    <li>一、  本合約自起始日起，由乙方提供甲方三年蓮心保障期（不含羽線及柄皮），惟因下列因素所引起之商品故障，不在此限。
                      <ul className="disc">
                        <li>甲方未依球拍標示建議予允拉線張力範圍內進行拉線，導致影響球拍使用性與壽命，甚至造成框體損毀。</li>
                        <li>甲方未依照正常使用或非正常操作運作，敲擊羽拍(含羽球)外的物品，或其他人為過失所引起者。</li>
                        <li>甲方擅自將商品放置陽光下的密閉汽車內，或置放於暖爐等熱源附近，導致球拍結構軟化發生扭曲變形等損壞。</li>
                        <li>甲方擅自維護或修理該商品所引起者。</li>
                        <li>甲方擅自將商品未依正常保護放置拍套裡，因放置重物導致變形或損壞。</li>
                        <li>甲方商品遭受蓄意破壞或竊盜者。</li>
                        <li>水災、火災、地震及閃電雷擊等人力不可抗拒因素所引起者。</li>
                      </ul>
                    </li>
                    <li>二、  服務時間：<br/>
                      本合約提供斷拍換新服務為正常上班時間，星期一至星期五（09:00~18:00），每天八小時提供服務，甲方可於乙方上班時間，立即通知乙方。除因天災、地變、政府法令或其他不可歸責於乙方事由致無法營業者，乙方得暫時停業或縮短營業時間。
                    </li>
                    <li>三、  斷拍換新核定<br/>
                      甲、乙雙方同意，甲方若使用斷拍換新服務須透過乙方指定之物流業收回先行將斷拍原貌寄回乙方指定地址後，經乙方核定確認後由乙方自行指定之物流業寄出新拍於甲方指定地址，且運費皆由乙方負擔（限台灣本島，台灣離島部分，由甲方先將斷拍原貌照片提供給乙方核定是否屬斷拍，經乙方照片核定屬斷拍者，則乙方寄送新拍至甲方指定地址並負擔運費。若乙方依照片無法核定是否斷拍，可要求甲方寄回原拍以完成核定，運費由甲方自行負擔。乙方於收受前開原拍並核定屬斷拍後，寄送新拍至甲方指定地址，並負擔寄送新拍之運費。如乙方收受前開原拍然核定非屬斷拍，則甲方需負擔寄回原拍至甲方指定地址之運費）。
                    </li>
                  </ul>
                  <p className="sub">陸、帳密義務</p> 
                  <ul className="indent">
                    <li>一、  甲方於本服務註冊程序中應自行設定一組帳號及密碼，經乙方網站核對確認後即設定該組帳號及密碼予甲方使用，該組帳號一經設定即不得變更，且僅供甲方使用，乙方不得將該組帳號、密碼轉讓、交付、揭露或出借予第三人，如因而產生糾紛，由乙方自行負責。</li>
                    <li>二、  乙方取得之密碼需於甲方提供之修改機制進行變更。</li>
                    <li>三、  乙方就本條之帳號及密碼的保管負全責，如因保管不周致帳號、密碼遭他人非法使用，此屬可歸責於乙方之情形，亦由乙方自行負責。</li>
                    <li>四、  任一方發現第三人非法使用甲方之帳號，或有使用安全遭異常破壞之情形時，應立即通知對方。乙方接獲甲方通知，或甲方通知乙方後，經乙方確認有前述情事，甲方得暫停該組帳號或密碼之使用權限，並更換新帳號或密碼予乙方。</li>
                  </ul>
                  <p className="sub">柒、保密義務</p>
                  <ul className="indent">
                    <li>一、  甲乙雙方因本合作案而知悉之他方營業秘密或相關資料，一律視為機密不得對外透露；倘因違反本項規定致他方遭受損害者，可歸責之一方應負相關損害賠償之責。</li>
                    <li>二、  乙方因履行本契約所取得甲方一切資料及資訊（包括但不限於書面、電子郵件、錄音資料、電磁紀錄等），不得以直接或間接、有償或無償或其他方式揭露、公開或散佈之。乙方違反前項規定者，甲方得請求損害賠償。</li>
                    <li>三、  甲乙雙方同意受「個人資料保護法」、施行細則以及相關法令規定之拘束，保護因履行本契約相對方所交付或因履行本契約而可得知悉之個人資料。如有違反致他方受有損害，違約方應負損害賠償責任（包括但不限於第三人之求償費用等）。</li>
                  </ul>  
                  <p className="sub">捌、資料運用</p>
                  <ul className="indent">
                    <li>一、  乙方應擔保向甲方陳述、提供之商業登記資料、負責人姓名、地址等相關資料均為真實無訛，且應依據稅法相關規定辦理統一發票開立或報稅事宜。</li>
                    <li>二、  乙方保証不得將甲方交易之資料外洩。但不包括出示給主管機關（台灣地區與大陸地區）或第三方支付機構之收單銀行核查。</li>
                    <li>三、  甲方同意乙方因應本服務作業需求提供銀行或第三方支付機構相關資料包括姓名、電話、手機號碼、統一編號、Mail等。</li>
                  </ul>  
                  <p className="sub">玖、智慧財產權</p>
                  <ul className="indent">
                    <li>甲方同意並接受乙方網站上之所有著作及資料，其著作權、專利權、商標權、營業秘密、其他智慧財產權、所有權或其他權利，均為乙方或其權利人所有，除事先經乙方或其權利人之合法授權外，甲方不得擅自重製、公開傳輸、改作、編輯或以其他任何形式、基於任何目的加以使用，否則應負所有法律責任。</li>
                  </ul>  
                  <p className="sub">拾、合約之生效</p>
                  <ul>
                    <li>一、  甲方在線上勾選「我已詳細閱讀 《 合約 》，並同意申請線上服務契約，並願意遵守規則。」時，即表示甲方已經閱讀、了解本合約之規定，且甲方同意本合約條款之規定。若甲方違反本合約或法令規定，乙方得隨時以書面或電子郵件通知甲方後，立即停止帳號使用權、停止提供本服務，並終止本合約，且甲方違反法律規定之情事，應自負法律責任。</li>
                    <li>二、  甲方同意若於合約未到期時違反履行合約（包括給付不能、或給付遲延、或不完全給付）時，則視甲方不履行而生損害之賠償總額，應給付乙方懲罰性違約金。</li>
                  </ul>  
                  <p className="sub">拾壹、合約之變更或終止</p>
                  <ul className="indent">
                    <li>一、  乙方保有增加、更新、暫停或終止營運本服務之任一營運專案或提供加值服務之權利，如有乙方欲暫停或終止本服務任一專案之營運或任一加值服務之提供，乙方將於預定暫停或終止日之三十日前在乙方網站公告之。</li>
                    <li>二、  乙方保留隨時修改本合約、管理規範或規則之權利，並在未來如有任何修改時，乙方將於網站頁面公告之，或以電子郵件方式通知甲方。</li>
                    <li>三、  乙方未依前項規進行公告及通知者，其合約之變更無效。</li>
                    <li>四、  甲方於通知到達後十五日內，如甲方繼續使用本服務，則視為甲方已同意並接受乙方合約變更之內容。如甲方為反對之表示者，視為甲方對乙方終止本合約之通知。</li>
                    <li>五、  於本合約存續期間，如任一方有違反本合約條款之事由，經他方書面通知改善而於30日內未改善者，他方得終止本合約。如係可歸責於甲方之事由經乙方依本條款約定終止契約，則甲方須將球拍返還予乙方，且已付之球拍金額不予退還；如係可歸責於乙方之事由經甲方依本條款約定終止契約，則甲方得選擇將球拍餘款付清取得球拍所有權或返還球拍予乙方並請求乙方無息退還已收之球拍價款。</li>
                  </ul>  
                  <p className="sub">拾貳、未盡事宜</p>
                  <ul className="indent">
                    <li>本合約如有其他未盡事宜導致修正必要，除法令或本契約另有約定外，乙方得以書面、電子媒體方式通知甲方或於乙方網站以公告方式調整之，甲方若未於前揭通知或公告日後五日內以書面向乙方表示異議時，則修訂之條款自前開通知或公告當日起生效。</li>
                  </ul>  
                  <p className="sub">拾參、管轄法院</p>
                  <ul className="indent">
                    <li>本合約與各線上管理規範、規則之解釋及適用、以及甲方因使用本服務而與乙方間所生之權利義務關係，雙方同意以中華民國法律為準據法；若有合作爭議時，雙方應依誠信原則、協商共謀解決為主；倘有涉訟情事，雙方同意以台灣台中地方法院為第一審管轄法院。</li>
                  </ul>  
                </div>
            </div>
            <hr/>
            <div className="lightBox-footer">
              <div className="lightBox-footer-button lightBox-footer-button-left" onClick={this.deal}>確定</div>
              <div className="lightBox-footer-button lightBox-footer-button-right">
                {
                  window.ReactNativeWebView ?
                  <span onClick={ ()=>{
                    openExternalWebsiteUrl('https://www.bonny-badminton.tw/assets/contract/BonnyBadminton_contract.doc', 'download', '下載2')
                  }}>
                    下載
                  </span>
                  :
                  <a download href="../assets/contract/BonnyBadminton_contract.doc" target="_blank" >
                    下載
                  </a>
                }
              </div>
            </div>
        </div>
      </div>     
		</Wrapper>
    )
  }
}

export default ContractLightBox;
