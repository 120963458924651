import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSearch, updateSearchCity } from "../../actions";
import { Wrapper } from "./styled";
import { Select, SubmitButton } from "../Common";
import { CityData } from "./tw-city";
import moment from "moment";
import { localStorageUtility } from '../../utilities';


class SelectLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cityOptions: this.makeCityOption(), //counties,,
            areaOptions: this.makeAreaOption(),
            area: props.data.query.area,
            city: props.data.query.city,
        };
    }

    makeCityOption =() =>{
        const counties = [
            {
                value: "選擇其他縣市",
                disabled: true
            }
        ]
        CityData.counties.map(value => counties.push({ value: value }));
        return counties;
    }

    // const 
    makeAreaOption = () => {
        
        const districts = [{text: '不限區', value: ''}];
    
        CityData.districts[0][0].map(value =>
            districts.push({ text: `--- 台北市 ${value}---`, value: value })
        );
    
        return districts;
    };

    handleSelect = e => {
        const index = e.nativeEvent.target.selectedIndex;
        const currentCity = e.target.value;
        const districts = [{text: '不限區', value: ''}];
        CityData.districts[index - 1][0].map(value =>
            districts.push({
                text: `--- ${currentCity} ${value}---`,
                value: value
            })
        );
        this.setState({
            city: currentCity,
            area: "",
            areaOptions: districts,
        });
    };
    handleSelectArea = e => {
        this.setState({
            area: e.target.value
        });
    };
    handleUpdateSearch = () => {

        localStorageUtility('set', 'activityLocationQuery', JSON.stringify({ city: this.state.city|| "台北市", area: this.state.area }) );
        localStorageUtility('set', 'activityLocationQueryExpire', moment().add(30, "days") );

        const data = {
            area: this.state.area,
            city: this.state.city,
        };
        this.props.onUpdateSearch(data);
        this.props.history.push("/activity_list");
    };
    render() {
        const { cityOptions, areaOptions, area, city } = this.state;

        return (
            <Wrapper>
                <img src="/assets/images/TaipeiBanner.jpg" className="banner" alt="" />
                <div className="main-form">
                    <Select
                        value={city}
                        options={cityOptions}
                        onChange={this.handleSelect}
                    />
                    <Select
                        value={area}
                        options={areaOptions}
                        onChange={this.handleSelectArea}
                    />
                    <SubmitButton
                        text="確認"
                        onClick={this.handleUpdateSearch}
                    />
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    data: state.activity
});

const mapDispatchToProps = {
    onUpdateSearch: updateSearch,
    onUpdateSearchCity: updateSearchCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocation);
