
import React, { Component } from "react";

class InfiniteScroll extends Component {

    componentDidUpdate(prevProps, prevState){
        // console.log("%c%s", "color:purple; font-size:20px;", prevProps.shouldLoadMore, this.props.shouldLoadMore)        

        // 都是 true 之後才傳進來
        // alert(prevProps.shouldLoadMore)
        // alert(this.props.shouldLoadMore)

        if(this.props.shouldLoadMore &&
        this.props.shouldLoadMore !== prevProps.shouldLoadMore){
            this.loadMore(this.props.query)
        }
    }

    loadMore = ( query )=>{
        // const { fetchDataFn, shouldLoadMore, query, limit, skip } = this.props

        // alert('in load more function ')
        if(this.props.shouldLoadMore){
            this.props.setShouldLoadMore(false)
            this.props.fetchDataFn({ ...query, limit:this.props.limit , skip: this.props.limit + this.props.skip })
            this.props.setOffset( this.props.limit + this.props.skip)
            // when data back?
            // this.props.setAlreadyShowActivityNumber( this.props.limit + this.props.shownNumber)
            
            var loading = document.getElementById("Loading");
            if(loading ){

            // if(loading === true){
                loading.classList.add("close");
            // }else{
                // alert('not find')
            // }
            }
            return
        } 
    }

    render() {
        // 滑到底 load資料
        return <div>
            {/* <span>InfiniteScroll</span> */}
            {/* why??? */}
            {this.props.children}
        </div>
    }
}

export default InfiniteScroll;
