const initialState = {
    activity_data: {},
    userInfo: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "SET_USERINFO_DATA": {
            return { ...state, userInfo: action.payload };
        }
        case "SET_DELETE_USERINFO_DATA": {
            return { ...state, userInfo: action.payload };
        }
        case "GET_MY_INFO_ACTIVITY_DATA": {
            let data = action.payload;
            return { ...state, activity_data: data };
        }
        case "GET_USER_INFO": {
            let data = action.payload;
            return { ...state, userInfo: data };
        }
        case "PUT_USER_INFO": {
            let data = action.payload;
            return { ...state, userInfo: data };
        }
        case "GET_OTHER_USER_INFO": {
            let data = action.payload;
            return { ...state, otherUserInfo: data };
        }
        case "GET_HOBBY_LIST": {
            let data = action.payload;
            return { ...state, hobby: data };
        }
        default:
            return state;
    }
}
