import React, { Component } from "react";
import { Route, Redirect, Switch } from 'react-router'
import { Wrapper } from "./styled";
// component below
import Footer from "../Footer";
// import { ScrollToTop } from '../Common'
import ActivitySignUp from "../ActivitySignUp";
import Login from "../Login";
import Register from "../Register";
import Media from "../Media";
import Forget from "../Forget";
import Reset from "../Forget/Reset";
import NewGroup from "../NewGroup";
import QuickSignUpGroup from "../QuickSingUpGroup";
import EditGroup from "../EditGroup";
import Myinfo from "../Myinfo";
import GroupDetail from "../GroupDetail";
import Header from "../Header";
import Rent from "../Rent";
import RacketsLobby from "../RacketsLobby";
import Classic from "../Racket/HighLevelRacket";
import Feather from "../Racket/MediumLevelRacket";
import Limited from "../Racket/LowLevelRacket";
import PaymentResult from "../PaymentResult";
import SelectLocation from "../SelectLocation";
import LocationFindActivity from "../LocationFindActivity";
import ActivityPaymentResult from "../ActivityPaymentResult";
import EmailVerification from "../EmailVerification";
import ErrorPage from "../ErrorPage";
// component above
import Config from "../config.js";

import MetaTags from 'react-meta-tags';
import invoke from 'react-native-webview-invoke/browser';

// import { Snow } from './styled';

class MiddleContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            statusBarHeight: 0,
            metaComponentName: '',
        }
    }

    componentDidMount() {
        this.webViewInvoke();
    }

    webViewInvoke() {
        const get = invoke.default.bind('get')
        get()
            .then((data) => {
                this.setState({
                    isMobile: true,
                    statusBarHeight: data.statusBarHeight,
                })
            })
    }

    handleChangeMetaTags = (name) => {
        this.setState({
            metaComponentName: name
        })
    }

    updateMetaTags = (metaComponentName) => {
        const facebookID = Config.facebook;
        switch (metaComponentName) {
            case 'BonnyBadminton':
                return <MetaTags>
                    <title>Bonny波力雲羽集 | 羽球人的雲端助手</title>
                    <meta name="keywords" content="Bonny,波力,羽球,羽你蓮心,羽球場,羽球教練,羽球隊,烏缺" />
                    <meta name="description" content="找場地，找球友，球隊管理一站滿足，還有業界首創羽你蓮心購拍方案，讓球友月繳年繳購買熱銷款碳纖維球拍，再享三年蓮心保障，斷拍換新$599起。" />
                    <meta property="og:title" content="Bonny波力雲羽集 | 羽球人的雲端助手" />
                    <meta property="og:site_name" content="Bonny波力雲羽集 | 羽球人的雲端助手" />
                    <meta property="og:url" content="https://www.bonny-badminton.tw/" />
                    <meta property="og:image" content="https://d2vsnoltqccfhr.cloudfront.net/39fd771f-d2dc-45e0-88d4-7ab4a1e14d09.jpg" />
                    <meta property="og:description" content="找場地，找球友，球隊管理一站滿足，還有業界首創羽你蓮心購拍方案，讓球友月繳年繳購買熱銷款碳纖維球拍，再享三年蓮心保障，斷拍換新$599起。" />
                    <meta property="fb:app_id" content={facebookID} />
                    <meta itemProp="name" content="Bonny波力雲羽集 | 羽球人的雲端助手" />
                    <meta itemProp="description" content="找場地，找球友，球隊管理一站滿足，還有業界首創羽你蓮心購拍方案，讓球友月繳年繳購買熱銷款碳纖維球拍，再享三年蓮心保障，斷拍換新$599起。" />
                    <meta itemProp="image" content="https://d2vsnoltqccfhr.cloudfront.net/39fd771f-d2dc-45e0-88d4-7ab4a1e14d09.jpg" />
                </MetaTags>
            case 'BonnyBadmintonIntro':
                return <MetaTags>
                    <title>Bonny羽你蓮心|首創羽球拍三年保障專案</title>
                    <meta name="keywords" content="Bonny,羽你蓮心,台灣碳纖維羽球拍品牌,烏缺,羽球拍" />
                    <meta name="description" content="台灣碳纖維羽球拍品牌Bonny為讓人人都買得起頂級球拍，推出月繳及年繳買Bonny專業碳纖維羽球拍專案，還可享三年內斷拍換新$599的蓮心保障。" />
                    <meta property="og:title" content="Bonny羽你蓮心| 首創羽球拍三年保障專案" />
                    <meta property="og:site_name" content="Bonny羽你蓮心| 首創羽球拍三年保障專案" />
                    <meta property="og:url" content="https://www.bonny-badminton.tw/rackets_lobby" />
                    <meta property="og:image" content="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/key.jpg" />
                    <meta property="og:description" content="台灣碳纖維羽球拍品牌Bonny為讓人人都買得起頂級球拍，推出月繳及年繳買Bonny專業碳纖維羽球拍專案，還可享三年內斷拍換新$599的蓮心保障。" />
                    <meta property="fb:app_id" content={facebookID} />
                    <meta itemProp="name" content="Bonny羽你蓮心|首創羽球拍三年保障專案" />
                    <meta itemProp="description" content="台灣碳纖維羽球拍品牌Bonny為讓人人都買得起頂級球拍，推出月繳及年繳買Bonny專業碳纖維羽球拍專案，還可享三年內斷拍換新$599的蓮心保障。" />
                    <meta itemProp="image" content="https://d2vsnoltqccfhr.cloudfront.net/image/landingPage/key.jpg" />
                </MetaTags>
            default:
                return <MetaTags>
                    <title>Bonny波力雲羽集 | 羽球人的雲端助手</title>
                    <meta name="keywords" content="Bonny,波力,羽球,羽你蓮心,羽球場,羽球教練,羽球隊,烏缺" />
                    <meta name="description" content="找場地，找球友，球隊管理一站滿足，還有業界首創羽你蓮心購拍方案，讓球友月繳年繳購買熱銷款碳纖維球拍，再享三年蓮心保障，斷拍換新$599起。" />
                    <meta property="og:title" content="Bonny波力雲羽集 | 羽球人的雲端助手" />
                    <meta property="og:site_name" content="Bonny波力雲羽集 | 羽球人的雲端助手" />
                    <meta property="og:url" content="https://www.bonny-badminton.tw/" />
                    <meta property="og:image" content="https://d2vsnoltqccfhr.cloudfront.net/39fd771f-d2dc-45e0-88d4-7ab4a1e14d09.jpg" />
                    <meta property="og:description" content="找場地，找球友，球隊管理一站滿足，還有業界首創羽你蓮心購拍方案，讓球友月繳年繳購買熱銷款碳纖維球拍，再享三年蓮心保障，斷拍換新$599起。" />
                    <meta property="fb:app_id" content={facebookID} />
                    <meta itemProp="name" content="Bonny波力雲羽集 | 羽球人的雲端助手" />
                    <meta itemProp="description" content="找場地，找球友，球隊管理一站滿足，還有業界首創羽你蓮心購拍方案，讓球友月繳年繳購買熱銷款碳纖維球拍，再享三年蓮心保障，斷拍換新$599起。" />
                    <meta itemProp="image" content="https://d2vsnoltqccfhr.cloudfront.net/39fd771f-d2dc-45e0-88d4-7ab4a1e14d09.jpg" />
                </MetaTags>
        }
    }


    render() {
        return [
            <Header key="0" isMobile={this.state.isMobile} statusBarHeight={this.state.statusBarHeight} 
                historyLength ={this.props.historyLength}
            />,
            <Wrapper key="1" id="content" style={{ height: `${this.state.isMobile ? 'calc(100vh - 129px)' : ''}` }}>
                {this.updateMetaTags(this.state.metaComponentName)}
                <Switch>
                {/*ActivitySignUp*/}
                    <Route path="/activity_list" component={ActivitySignUp} /> 
                    <Redirect strict from='/ActivitySignUp' to='/activity_list' />
                {/*NewGroup*/}
                    <Route path="/create_activity" component={NewGroup} />
                    <Redirect strict from='/NewGroup' to='/create_activity' />
                {/*EditGroup*/}
                    <Route path="/edit_activity" component={EditGroup} />
                    <Redirect strict from='/EditGroup' to='/edit_activity' />
                {/*Login*/}
                    <Route path="/login" component={Login} />
                {/*Register*/}
                    <Route path="/register" component={Register} />
                {/*Media*/}
                    <Route path="/media" component={Media} />
                {/*Forget*/}
                    <Route exact path="/forget" component={Forget} />
                {/*Reset*/}
                    <Route path="/forget/reset" component={Reset} />
                {/*Myinfo*/}
                    <Route path="/my_info" component={Myinfo} />
                    <Redirect strict from='/Myinfo' to='/my_info' />
                {/*SelectLocation*/}
                    <Route path="/select_location" component={SelectLocation} />
                    <Redirect strict from='/SelectLocation' to='/select_location' />
                {/*LocationFindActivity*/}
                    <Route path="/location_find_activity" component={LocationFindActivity} />
                    <Redirect strict from='/LocationFindActivity' to='/location_find_activity' />
                {/*GroupDetail*/}
                    <Route path="/sign_up_activity" component={GroupDetail} />
                    <Redirect strict from='/GroupDetail' to='/sign_up_activity' />
                {/*QuickSignUpGroup*/}
                    <Route path="/quick_sign_up_activity" component={QuickSignUpGroup} />
                    <Redirect strict from='/QuickSignUpGroup' to='/quick_sign_up_activity' />
                {/*PaymentResult*/}
                    <Route path="/rent_payment_result/:orderId/:paymentId" component={PaymentResult} />
                    <Redirect strict from='/PaymentResult/:orderId/:paymentId' to='/rent_payment_result/:orderId/:paymentId' />
                {/*Rent*/}
                    <Route path="/rent" component={Rent} />
                {/*RacketsLobby*/}
                    <Route path="/rackets_lobby"
                        render={(props) => (
                            <RacketsLobby
                                handleChangeMetaTags={this.handleChangeMetaTags}
                                {...props}
                            />
                        )}
                    />
                    <Redirect strict from='/RacketsLobby' to='/rackets_lobby' />
                    <Redirect strict from='/Intro' to='/rackets_lobby' />
                {/*Classic*/}
                    <Route path="/classic" component={Classic} />
                {/*Feather*/}
                    <Route path="/feather" component={Feather} />
                    <Redirect strict from='/MediumLevelRacket' to='/feather' />
                {/*Limited*/}
                    <Route path="/limited" component={Limited} />
                {/*ActivityPaymentResult*/}
                    <Route path="/activity_payment_result/:paymentNumber" component={ActivityPaymentResult} />
                    <Redirect strict from='/ActivityPaymentResult/:paymentNumber' to='/activity_payment_result/:paymentNumber' />
                    <Route path="/email_verification" component={EmailVerification} />
                {/*ErrorPage*/}
                    <Route component={ErrorPage} />
                </Switch>
            </Wrapper>,
            <Footer key="2" />,
        ];
    }

}

export default MiddleContent;
