
import { localStorageUtility} from '../utilities';


//  modal ?

export const voteCount = ( id, _like, isVoteToday, authorName, updateVoteFn, redirect) => {
    let userToken = localStorageUtility('getAndParse', 'myData').token;
    if(userToken){
        if ( !isVoteToday){
            if (window.confirm(`確認投給 ${authorName}?\n(同一帳號一日限投一票)`)) {
                updateVoteFn({
                    id: id,
                    type: 'vote',
                }) 
            } else {
                // console.log("in confirm false");
                return
            }
        } else{
            alert('今日已投過(同一帳號一日限投一票)')
        }
    }else{
        document.cookie = "backurl=" + window.location.pathname;
        alert('請先登入')
        redirect('/login');
    }

};