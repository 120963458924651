import React, { Component } from "react";
import Select from "../Select/";
import { FormControl } from "../../../component/Common";

export default class AgeSelect extends Component {
    options() {
        const { isStart, isEnd } = this.props;
        var options = [];
        for (let i = 0; i <= 100; i+=5) {
            options.push({ value: i, text: i })
        }

        if (isStart) {
          options[0].text = "無限制";
        } else if (isEnd) {
          options[options.length - 1].text = "無限制";
        }

        return options
    }

    render() {
        const options = this.options();

        return (
            <FormControl>
                <Select label={`球友年齡${this.props.t}：`} options={options} onChange={this.props.onChange} value={this.props.value}/>
            </FormControl>
        );
    }
}
