import React, { Component } from "react";
import { Wrapper, ChildrenWrapper } from "./styled";

let id = 0;
const prefix = '__bonnylive__image__uploader__';
const getId = () => prefix + ++id;

class ImageUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputId: getId(),
            fileName: '',
            file: null,
        }
    }

    handleCancel(e) {
      this.setState({
          fileName: '',
          file: null,
      }, () => this.props.onCancel())
    }

    handleFileUpload (e) {
        const sizeLimit = 10 * 1024 * 1024; // 10MB
        const file = e.target.files[0];
        if (this.props.useSizeLimit && file.size > sizeLimit) {
            e.target.value = '';
            alert('oops!! 檔案大小不可大於10MB喔!');
            return;
        }

        this.setState({
            fileName : e.target.value.split( '\\' ).pop(),
            file,
        }, () => this.props.onChange(file))
    }

    render(){
        const { showThumbnail, url, isWrapMode, needSkip, children } = this.props;
        const { inputId, fileName } = this.state

        if (showThumbnail && url) {
            return (
                <Wrapper>
                    <div className="thumbnail">
                        <img alt="" src={url} />
                        <div
                          className="cancel"
                          onClick={e => this.handleCancel(e)}
                        >
                            X
                        </div>
                    </div>
                </Wrapper>
            )
        }

        if (isWrapMode && children) {
            return (
                <ChildrenWrapper>
                    <label
                        htmlFor={inputId}
                        onClick={e => needSkip() && e.preventDefault()}
                    >
                        {this.props.children}
                    </label>
                    <input
                        style={{ display: 'none' }}
                        id={inputId} type="file" accept="image/gif,image/jpeg,image/jpg,image/png" onChange={e => this.handleFileUpload(e)}
                    />
                </ChildrenWrapper>
            );
        }

        return (
            <Wrapper className="img-uploader">
                <label className='title'>上傳照片：</label>
                <input type="text" disabled value={fileName} className="fileNames"/>
                <div className="upload-btn-wrapper">
                    <label htmlFor={inputId}>
                        {/*<img src="./assets/icons/Upload.png" alt="" />*/}
                        <i className="material-icons">
                            cloud_upload
                        </i>
                        <div>上傳</div>
                    </label>
                    <input id={inputId} type="file" accept="image/gif,image/jpeg,image/jpg,image/png" onChange={e => this.handleFileUpload(e)}/>
                </div>
                <p className="hint">
                    建議您上傳2560(寬) x 1920(高)像素以內、檔案大小10MB以下的JPG、GIF、PNG圖片。
                </p>
            </Wrapper>
        );
    }
}

ImageUploader.defaultProps = {
    label: "",
    onChange: null,
    error: null,
    onClick: null
};

export default ImageUploader;
