import styled from 'styled-components';

export const Wrapper = styled.div `
    width: 100%;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    margin-bottom: 10px;

    position: relative;

    @media (max-width: 480px) {
        border-radius: 0;
    }

    .background {
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        position: relative;
        border-bottom: 2px solid #ccc;

        .icon_bg {
            position: absolute;
            bottom: 10px;
            right: 20px;
            z-index: 10;
            i{
                font-size: 1.4rem;
                width: 2rem;
                height: 2rem;
                // line-height: 2.2rem;
                text-align: center;
                border: 2px solid #333;
                border-radius: 999rem;
                color: #333;
                background-color: rgba(255,255,255,0.6);
                box-shadow: 0 1px 10px 1px rgba(255,255,255,0.4);
                text-shadow: 0 1px 5px 1px rgba(255,255,255,0.4);
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                &:hover{
                    background-color: rgba(216,216,216,0.6);
                }
            }
        }
    }

    .userInfo{
        width: 100%;
        height: auto;
        position: relative;
        margin-top: -15%;

        .image_Cropper {
            width: 80px;
            height: 80px;
            display: block;
            position: relative;
            padding-top: 20px;
            margin: auto;
            &_Photo{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                overflow: hidden;
                background-color: #fff;
                background-size: cover;
                background-position: center;
                border: 2px solid #ccc;
                border-radius: 999rem;
                box-shadow: 0 2px 5px 1px rgba(216,216,216,.8);
            }
            @media (max-width: 480px) {
                width: 60px;
                height: 60px;
            }

            .icon_avatar{
                position: absolute;
                bottom: -10px;
                right: -15px;
                i{
                    font-size: 1rem;
                    width: 1.6rem;
                    height: 1.6rem;
                    // line-height: 2rem;
                    text-align: center;
                    border: 2px solid #333;
                    border-radius: 999rem;
                    color: #333;
                    background-color: rgba(255,255,255,0.6);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    &:hover{
                        background-color: rgba(216,216,216,0.6);
                    }
                }
            }
        }

        p.userName{
            // position: relative;
            text-align: center;
            font-weight: bold;
            font-size: 1rem;
            color: #333;
            margin: auto;
            margin-top: 10px;
            width: 100%;
            white-space: nowrap;
            // text-shadow: -1px -1px 0 rgba(255, 255, 255, 1), 1px -1px 0 rgba(255, 255, 255, 1), -1px 1px 0 rgba(255, 255, 255, 1), 1px 1px 0 rgba(255, 255, 255, 1);
            @media (max-width: 480px) {
                font-size: 12px;
            }
        }
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

`;
