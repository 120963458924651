import styled from "styled-components";

export const Wrapper = styled.div`

display: flex;
flex-direction: column;
justify-content: space-around;

.carruerNum{
    input::placeholder{
        color: rgb(206, 212, 218);
    }
}

.field{
    input[type=radio] {
        height: 15px;
    }
}

.label{
    margin: 10px 0px;
}

.carruerType,.invoiceType,.isPrint{
    margin-right: 1rem;
}

.fontSize14p{
    font-size: 14px;
}

`