import React, { Component } from "react";
import { Route } from 'react-router-dom';
import Contract from './Contract';
import LandingPage from './LandingPage';
import { getCookie } from '../../../utilities';

class Classic extends Component {
    constructor(props) {
        super(props);
        var landingPageCode = getCookie('LandingPage');
        this.state = {
            landingPageCode: landingPageCode,
        };
    }

    lightBox(e){
        var deal = document.getElementById("ContractLightBox");
        deal.classList.remove("close");
    }

    render() {
        const { match } = this.props;
        return (
            <div>
                <h1 
                    style={{
                        opacity: '0',
                        position: 'fixed',
                        zIndex: '-999999',
                        left: '-100vw',
                        top: '-100vh',
                        width: '0px',
                        height: '0px',
                    }}
                >
                    BonnyBadminton 雲羽集 羽你蓮心 Bonny頂級球拍
                </h1>
                <Route exact path={match.url} component={LandingPage} />
                <Route path="/classic/contract" component={Contract} />
            </div>
        );
        
    }
}

export default Classic;
