import { handleActions } from 'redux-actions';

const initialState = {
    privateChat: [],
    privateChatInfo: {},
    // add unread count because if there is no message it will be only unReadCount
    privateChatUnreadCount: null,
    reloadMessage: false,
    messageInformation: {},
};
const messageReducer = handleActions(
    {
        SET_PRIVATECHAT_LIST: (state, { payload }) => {
            return { ...state, privateChat: payload };
        },
        SET_PRIVATECHAT_INFO: (state, { payload }) => {
            return { ...state, privateChatInfo: payload };
        },
        SAVE_MESSAGE_RES_TO_REDUX: (state, { payload }) => {
            return { ...state, messageInformation: payload };
        },
        SET_MESSAGE_UNREAD_COUNT: (state, { payload }) => {
            return { ...state, privateChatUnreadCount: payload.unReadCount }
        },
        SET_SOCKET_MESSAGE: (state, { payload }) => {
            // need to refactor
            state.privateChatInfo.message && state.privateChatInfo.message.unshift(payload)
            return {
                ...state,
                privateChatInfo: {
                    ...state.privateChatInfo,
                    message: state.privateChatInfo.message || [payload] 
                }
            };
        }
    },
    initialState
);

export default messageReducer;
