import React, { Component } from "react";
import { Wrapper } from "./styled";

class FollowButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
     // isFollowed:this.props.paid
  }
  }
  isFollowed = () => {
    var followList = this.props.followList
    //console.log(followList);
    if (followList && followList.length > 0) {
      for (var i = 0; i < followList.length; i++) {
        if (followList[i].followee.id === this.props.groupUserId)
        
          return true
          
      }
    }
    return false
  }
  render() {
    const { onClick } = this.props;
    return (
        <Wrapper onClick={onClick} isFollowed>
          {/*<img src={this.isFollowed()? '/assets/icons/SocialLike02Active.png': '/assets/icons/SocialLike02.png'} alt=""/>*/}
          {this.isFollowed() ?
            <i className="material-icons">
              favorite
            </i>
            :
            <i className="material-icons">
              favorite_border
            </i>
          }
          <div className="text"> {this.isFollowed()? '追蹤中': '追蹤'} </div>
        </Wrapper>
    );
  }
}

FollowButton.defaultProps = {
  label: "",
  onChange: null,
  error: null,
  onClick: () => {},
};

export default FollowButton;
