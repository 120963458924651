import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import moment from 'moment';
import {
	postpone,
	getGroupDetail,
	getFollower,
	editGroup,
	updateGroup,
	duplicateGroup,
	updateNotifActivity,
	postLeaveActivity,
	putjoinpaid,
	updatejoinpaidStatus,
	fetchActivityInvite,
	fetchUserlist,
	fetchUpdateActivityData,
	followUser,
	unFollowUser,
	followList
} from "../../../actions";
import { 
	Modal, 
	ButtonsGroup, 
	RoundButton, 
	CommentBox, 
	InviteButton, 
	LoadingIndicator 
} from "../../Common";
import { Wrapper } from './styled';
import Banner from "./Banner";
import SocialSummary from "./SocialSummary";
import ActivityContent from "./ActivityContent";
import GroupInfo from "./GroupInfo";
import ShareButton from "./ShareButton";
import ModalContent from './ModalContent';
import { localStorageUtility } from '../../../utilities';

class ActivityDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usersearch: [],
			inviteeIndex: -1,
			user: localStorageUtility('getAndParse', 'myData'),
			signUpStatus: true,
			cancelStatus: true,
		}
	}

	componentDidMount() {
		const { onInit, match } = this.props;
		onInit(match.params.id);
		localStorageUtility('set', 'activityid', match.params.id);
		const user = localStorageUtility('getAndParse', 'myData');
		const token = user ? user.token : "";
		if (token) {
			this.props.followList({ type: "follower" })
		}
		if( Object.keys(this.props.data).length > 0){
			this.handleActivityButtonStatus(this.props.data.status)
		}
	}

	componentDidUpdate(prevProps) {
		//  success will rerender
		if (prevProps.putjoinpaidStatus !== this.props.putjoinpaidStatus &&
			this.props.putjoinpaidStatus === 'success'){
			this.props.getGroupDetail(this.props.id)
			this.props.updatejoinpaidStatus()
		}

		if(prevProps.data.status !== this.props.data.status){
			this.handleActivityButtonStatus(this.props.data.status)
		}


		const state = this.props.location.state;
		if (!state) {
			return;
		}

		this.refs.modal && this.refs.modal[state.notifiedActivity ? 'openModal' : 'closeModal']();


	}

	handleActivityButtonStatus = (dataStatus) => {
		if(dataStatus === 0 ){
			this.setState({
				signUpStatus: true,
				signUpText: '報名截止',
				cancelText: '已取消',
				cancelStatus: true,
			})
		}
		if(dataStatus === 1 ){
			this.setState({
				signUpStatus: false,
				signUpText: '報名截止',
				cancelText: '取消開團',
				cancelStatus: false,
			})
		}
		if(dataStatus === -1 ){
			this.setState({
				signUpStatus: true,
				signUpText: '已截止',
				cancelText: '取消開團',
				cancelStatus: false,
			})
		}
	}

	onEditGroupClick(data) {
		this.props.editGroup(data);
		this.props.history.push('/edit_activity');
	}

	onCancelClick(id) {
		this.props.onCancelGroup(id);
		setTimeout(() => {
			this.props.history.replace("/my_info/host_activities");
		}, 100);
	}

	onCancelSignUpClick(id) {
		this.props.onCancelSignUp(id);
		setTimeout(() => {
			this.props.history.replace("/my_info/host_activities");
		}, 100);
	}

	onLeaveActivityClick(joinID, userID) {
		this.props.onLeaveActivityClick({ join: joinID, user: userID });
	}

	onCancelNotification(id, skip) {
		const state = this.props.location.state;
		if (state && state.notifiedActivity.id === id) {
			this.props.updateNotifActivity();
		}
		this.props.onCancelNotification(id, skip);
	}

	onPostpone(id) {
		this.props.onPostpone(id);
	}

	handleFollowUser = (id) => {
		var isFollowed = this.isFollowed(id);
		if (isFollowed) {
			this.props.unFollowUser({ id: this.getFollowId(id) });
		}
		else {
			this.props.followUser({ followee: id });
		}
		this.props.followList({ type: "follower" });
	}

	isFollowed = (id) => {
		var followList = this.props.follow
		if (followList && followList.length > 0) {
			for (var i = 0; i < followList.length; i++) {
				if (followList[i].followee.id === Number(id)) {
					return true
				}
			}
		}
		return false
	}

	getFollowId = (id) => {
		var followList = this.props.follow;
		if (followList && followList.length > 0) {
			for (var i = 0; i < followList.length; i++) {
				if (followList[i].followee.id === Number(id)) {
					return followList[i].id
				}
			}
		}
		return 0;
	}

	onCheckPaidClick(id, isPaid) {
		this.props.onCheckPaid(id, { paid: isPaid })
	}

	handleInputChange = (event, { newValue, method }) => {
		this.props.fetchUserlist(newValue);
		// 推測是為了防止 select 後，inviteeIndex 有值，又把輸入欄位改為空的 error handling
		this.setState({
			inviteeIndex: (newValue.trim() === "" ? -1 : this.state.inviteeIndex)
		});
	};

	handleInviteeSelected = ({ suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		this.setState({ inviteeIndex: suggestionIndex });
	}

	handleSubmit = (usersearch, activityid) => {
		// userSearch = this.props.userData.data，所有搜尋到的 user 結果
		let { inviteeIndex } = this.state;
		if ( usersearch && usersearch.length > 0 && inviteeIndex !== -1) {
			let userid = usersearch[inviteeIndex]["id"];
			this.props.onInvite(userid, activityid);
			setTimeout(() => this.props.getGroupDetail(activityid), 1000)
		}
	};

	render() {
		const { 
			id, 
			summary, 
			content,  
			joinHost, 
			chatroom,  
			usersearch, 
			data,  
			activityFee 
		} = this.props;
		
		// disabled === true 則不會顯示 再次開團 相關 Button
		// frequency 如果為 Null, 代表該活動 並非 固定開團，因此 disable 按鈕
		// 如果活動尚未結束也 disable 按鈕，因為活動結束才需要再次開團
		const disabled = data.frequency === null || data.end > moment().valueOf();

		return [
			<Modal
				key={0}
				ref='modal'
				title='再次開團'
				noButton={true}
				notButtonStyle={{ display: 'none' }}
				text1='確認'
				modalContent={
					<ModalContent
						ref='content'
						activity={this.props.data}
						onCancelNotification={() => {
							this.refs.modal.closeModal();
							this.onCancelNotification(id);
						}}
						onPostpone={() => {
							this.refs.modal.closeModal();
							this.onPostpone(id);
						}}
					/>
				}
				onClick={() => {
					const newDateTime = this.refs.content.getDateTime();
					if (!newDateTime) {
						return;
					}

					const activity = this.props.data;
					this.onCancelNotification(activity.id, true);
					this.props.duplicateGroup(activity, newDateTime);
				}}
				onClose={() => {
					this.props.history.replace(this.props.location.pathname);
				}}
			/>,
			<Banner key={1} data={this.props.data.images} />,
			<Wrapper key={2}>
				<LoadingIndicator shouldOpen={this.props.loading}/>
				<SocialSummary {...summary} />
				<ActivityContent {...content} />
				<ButtonsGroup>
					<RoundButton onClick={() => this.onEditGroupClick(data)}> 編輯開團資訊 </RoundButton>
				</ButtonsGroup>
				<ButtonsGroup>
					<Modal
						disabled={this.state.signUpStatus}
						title='報名截止'
						btnClass='modal-btn'
						btnText={this.state.signUpText}
						onClick={() => this.onCancelSignUpClick(id)} />
					<Modal
						disabled={this.state.cancelStatus}
						title='取消開團'
						btnClass='modal-btn'
						btnText={this.state.cancelText}
						onClick={() => this.onCancelClick(id)} />
				</ButtonsGroup>
				<hr />

				<GroupInfo 
					activityFee= {activityFee}
					onLeaveClick={(joinID, userID) => this.onLeaveActivityClick(joinID, userID)} 
					handleChangePaid={(id, isPaid) => this.onCheckPaidClick(id, isPaid)} 
					follow={(id) => this.handleFollowUser(id)} 
					followList={this.props.follow} 
					joinedPeopleData={joinHost.map((data) => data)} 
					people={data.people} 
				/>
				<InviteButton
					suggestions={usersearch}
					inputProps={{
						placeholder: "請輸入E-MAIL或帳號手動直接報名",
						name: 'search',
						onChange: this.handleInputChange
					}}
					onSuggestionSelected={this.handleInviteeSelected}
					onClick={() => this.handleSubmit(usersearch, id)}
				/>
				<ShareButton 
					url={`https://${window.location.host}/quick_sign_up_activity?${id}`} 
					activityId={id} 
					groupName={this.props.data.group_name}
				/>
				
				<ButtonsGroup>
					<RoundButton
						disabled={disabled}
						onClick={() => this.refs.modal.openModal()}
					>
						再次開團?
      				</RoundButton>
				</ButtonsGroup>
				<ButtonsGroup>
					<Modal
						disabled={disabled}
						title='一日後將繼續提醒'
						btnClass='modal-btn'
						btnText='一日內不再提醒'
						onClick={() => this.onPostpone(id)}
					/>
					<Modal
						disabled={disabled}
						title='將取消提醒，暫不開團'
						btnClass='modal-btn'
						btnText='暫不開團並取消提醒'
						onClick={() => this.onCancelNotification(id)}
					/>
				</ButtonsGroup>
			</Wrapper>,
			<CommentBox key={3} chatroom={chatroom} />
		];
	}
}

const mapStateToProps = (state) => {
	const { activityInformation: data } = state.groupDetailReducer;
	const { follower: followerdata } = state.groupDetailReducer;
	const { userList: userdata } = state.groupDetailReducer;
	const { shouldLoadingOpen: loading } = state.groupDetailReducer;
	const usersearch = userdata.data;
	const follow = state.groupDetailReducer.follow;
	const putjoinpaidStatus = state.activity.putjoinpaidStatus;
	const id = data.id,
		summary = {
			join: data.join ? data.join.filter(e => e.status === true).length : 0,
			comment: data.comment,
			follow: followerdata.followeeCount ? followerdata.followeeCount : 0,
			view: data.view,
		},
		content = {
			createdAt: data.createdAt,
			groupName: data.group_name,
			people: data.people,
			start: data.start,
			end: data.end,
			placeName: data.place_name,
			city: data.city,
			area: data.area,
			address: data.address,
			badmintonLevel: data.badminton_level,
			cash: data.plan && data.plan.Cash,
			creditEcpay: data.plan && data.plan.CreditEcpay,
			placeCount: data.place_count,
			tag: data.tag,
			type: data.type,
			remain: data.remain,
		},
		chatroom = data.chatroom,
		joinHost = data.join,
		activityFee = data.fee;

	return { 
		putjoinpaidStatus, 
		id, 
		summary, 
		content, 
		joinHost, 
		chatroom, 
		data, 
		usersearch, 
		follow, 
		activityFee, 
		loading 
	}
}

const mapDispatchToProps = (dispatch) => ({
	onInit: (id) => {
		dispatch(getGroupDetail(id));
		const user = localStorageUtility('getAndParse', 'myData');
		dispatch(getFollower(user.id));
	},

	updatejoinpaidStatus: ()=>{
		dispatch(updatejoinpaidStatus());
	},

	getGroupDetail: (id) =>{
		dispatch(getGroupDetail(id ))		
	},

	onCancelGroup: (id) => {
		dispatch(fetchUpdateActivityData(id, { status: 0 }))
	},
	fetchUserlist: (data) => {
		dispatch(fetchUserlist(data))
	},

	onInvite: (userid, activityid) => {
		dispatch(fetchActivityInvite({ user: userid, activity: activityid }))

	},
	onCancelSignUp: (id) => {
		dispatch(fetchUpdateActivityData(id, { status: -1 }))
	},
	onLeaveActivityClick: (data) => {
		dispatch(postLeaveActivity(data))
	},
	onCheckPaid: (id, isPaid) => {
		dispatch(putjoinpaid(id, isPaid))
	},
	followUser: (id) => {
		dispatch(followUser(id))
	},
	unFollowUser: (id) => {
		dispatch(unFollowUser(id))
	},
	followList: (id) => {
		dispatch(followList({ followee: id }))
	},
	onCancelNotification: (id, skip) => dispatch(updateGroup({ id, notify: false }, skip)),
	onPostpone: id => dispatch(postpone(id)),
	duplicateGroup: (activity, newDateTime) => dispatch(duplicateGroup(activity, newDateTime)),
	updateNotifActivity: () => dispatch(updateNotifActivity()),
	editGroup: (data)=> dispatch(editGroup(data)),
	// dispatch: dispatch,
})

ActivityDetail.defaultProps = {
	summary: {
		people: 0,
		comment: 0,
		follow: 0,
		view: 0,
	},
	joinHost: []
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityDetail));
