import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchActivity,
  getBannerList,
  updateSearch,
  updateSearchCity,
  getLevels,
} from "../../actions";
import { localStorageUtility } from '../../utilities';
import SearchBar from "./SearchBar";
import { ActList, Card, Banner, LoadingIndicator, InfiniteScroll } from "../Common";

class ActivitySignUp extends Component {
	constructor(props){ 
		super()
		this.state={
			limit: 5,
			alreadyShowActivityNumber: 0,
			shouldAddActivity: false,

			activities: [],
			isActivityHasValue: false,

			// defaultQuery = {{ latitude:25.034263, longitude:121.564553 }} 


		}
		this.onListScroll = this.onListScroll.bind(this);
	}

	componentDidMount() {
		this.props.getBannerList("activity");
		this.props.getLevels();

		window.addEventListener('scroll', this.onListScroll, true);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onListScroll, true);
	}

	componentDidUpdate(prevProps, prevState){

		if( this.props.activityData && this.props.activityData.activity &&
			this.props.activityData.activity !== prevProps.activityData.activity){
			if(this.state.activities && this.state.activities.length > 0 &&
				JSON.stringify(this.props.activityData.activity) !== JSON.stringify(prevProps.activityData.activity)){
				// alert("will add activities to this.state, array isnt null");

					// push data 
				this.setState({
					activities: [ ...this.state.activities.concat( this.props.activityData.activity ) ],
				})
			}else if(this.state.activities.length === 0 ){
				// alert("will add activities to this.state, array is [] ");
				this.setState({
					activities: [ ...this.state.activities.concat( this.props.activityData.activity ) ],
					isActivityHasValue: true,
				})
				
			}
		}

		// alert(this.state.shouldAddActivity)


	}

	onListScroll(e){
		let middleContentHeight = document.querySelector('#content').clientHeight
		let srcElement = e.srcElement

		// element.scrollHeight - element.scrollTop === element.clientHeight
		let scrollPercent = (middleContentHeight +srcElement.scrollTop) / srcElement.scrollHeight   //(300+ actListHeight)
		// let scrollPercent = (e.srcElement.scrollTop + 669) / srcElement.scrollHeight   //(300+ actListHeight)
		// console.log( "scrollHeight and scrollTop are : ", srcElement.scrollHeight, srcElement.scrollTop);
		// console.log(e.srcElement.childNodes[0]);
		// console.log( scrollPercent);

		if(scrollPercent > 0.9){
			if(scrollPercent === 1){
				// console.log(" will add some data");
				// console.log('this.state.shouldAddactivity is : ', this.state.shouldAddActivity);
				// alert(" at the bottom");	
			}
			// cause when calculating height or scroll, it will round, so it sometimes will larger than 1 or less than 1 (0.99多)
			if(scrollPercent > 0.8 ){
				// if(scrollPercent > 0.8 ){
				
				// alert(" over the bottom lollllllllllllll");
				if(this.state.isActivityHasValue){
					// alertxf('shouldAddActivity is true')

					// because before calling API in infinite scroll will add first then calling
					if( this.state.alreadyShowActivityNumber+this.state.limit <= this.props.total ){
						
						let loadingIcons = document.getElementsByClassName("shouldLittleIcon")
						if(loadingIcons.length > 0){
							// console.log( " in isActivityHasValue" )//, document.getElementsByClassName("shouldLittleIcon").classList);
							let lastLoadingIcon = document.getElementsByClassName("shouldLittleIcon")[loadingIcons.length-1]
							lastLoadingIcon.classList.remove("close");
						}
						if(this.props.loading === false){
							this.setState({ shouldAddActivity: true})
						}
					}else{
						// console.log('no more data')
					}

				}
			}
		}
	}

	setAlreadyShowActivityNumber = ( alreadyShowActivityNumber ) =>{
		this.setState({
			alreadyShowActivityNumber : alreadyShowActivityNumber
		})
	}
	clearAndFetchActivities = (query)=>{
		// set isActivityHasValue to false because it will make shouldAddActivity is true 
		// before infiniteScroll renders so infiniteScroll cann't judge with its didUpdate
		this.setState({
			activities : [],
			alreadyShowActivityNumber: 0,
			isActivityHasValue: false,
			shouldAddActivity: false
		})

		this.props.onSubmit({ 
			...query, 
			limit: this.state.limit,
			skip: 0,
			// skip: this.state.alreadyShowActivityNumber
		})

		
	}

	setShouldAddActivity = shouldAddActivity =>{
		// shouldAddActivity ? 
		// this.setState({ shouldAddActivity : true })
		// :
		// this.setState({ shouldAddActivity : false })
		this.setState({
			shouldAddActivity: shouldAddActivity
		})
	}
	
	setQuery = (query)=>{
		// console.log("will set this.state.query:", query);
		this.setState({
			query: query
		})

		
	}

	renderActList = () => {
		// activityData 是全部的 data, 然後用 state 的 activities 做渲染

		const { loading } = this.props;
		const { activities } = this.state
		const userInfo = localStorageUtility('getAndParse', 'myData');
		
		if (activities && activities.length === 0) { //.filter(data => data.remain !== 0)
			if (loading) {
				return <LoadingIndicator shouldOpen={true} key="3" />
			} else {
				return <label key="3" style={{ textAlign: "center" }}>無符合條件的團</label>;
			}
		} else {
			return <InfiniteScroll 
				key="2"
				query={this.state.query}
				limit={this.state.limit}
				skip= {this.state.alreadyShowActivityNumber}
				
				fetchDataFn={this.props.onSubmit} 
				setOffset = { this.setAlreadyShowActivityNumber}
				setShouldLoadMore ={this.setShouldAddActivity}
				shouldLoadMore={this.state.shouldAddActivity}>

				<ActList key="2" 
				onScroll={this.onListScroll}>
					{activities &&
						activities.map((data, i) => ( //.filter(data => data.remain !== 0)
							<Card
								data={data}
								key={i}
								url={data.user && data.user.id === userInfo.id ? `/my_info/host_activity_detail/${data.id}` : `/sign_up_activity?${data.id}`}
							/>)
						)}

					{
						this.props.total >= this.state.alreadyShowActivityNumber +this.state.limit ?
							this.state.limit >= this.props.total ?
							<div style={{
								display: 'block',
								textAlign: 'center',
								lineHeight: '30px',
								// background: '#2f82bf',
								color: '#666',
								marginTop: '50px',
							}}>沒有更多的團囉~</div>
							:
							<LoadingIndicator key="4" 
							shouldOpen={true}
							shouldLittleIcon={true} />
						:
						<div style={{
							display: 'block',
							textAlign: 'center',
							lineHeight: '30px',
							// background: '#2f82bf',
							color: '#666',
							marginTop: '50px',
						}}>沒有更多的團囉~</div>
					}

					{/* { this.state.shouldAddActivity ? 
						<div>
							{ alert('loadingIndicator shows cause shouldAdd')}
							{console.log("%c%s", "color:purple; font-size:20px;", this.state.activities)}
							<LoadingIndicator key="2" shouldLittleIcon={true} />
						</div>
						:
						<p>
							{ alert('loadingIndicator disappears cause shouldAdd is false')}
						</p>
					} */}
				</ActList>
			</InfiniteScroll>
		}
	}
	render() {
		const { activityData, onUpdateSearch, onUpdateSearchCity, levels } = this.props;

		return[
			<Banner
				key="0"
				data={activityData.banner_activity}
				className="banner-slide"
			/>,
			<SearchBar
				key="1"
				onUpdateSearch={onUpdateSearch}
				onUpdateSearchCity={onUpdateSearchCity}
				// for provided previous search 
				activityData={activityData}
				levels={levels}
				setQuery ={this.setQuery}
				clearAndFetchActivities ={this.clearAndFetchActivities}
				limit={this.state.limit}
				// shouldAddActivity={this.state.shouldAddActivity}
				// setShouldAddActivity ={this.setShouldAddActivity}
				// setAlreadyShowActivityNumber ={this.setAlreadyShowActivityNumber}
			/>,
			this.renderActList()
		]
	}
}

const mapStateToProps = state => ({
	
	loading: state.activity.loading,
	activityData: state.activity,
	total: state.activity.totalActivity,
	activityType: state.activity.activityType,
	levels: state.newGroupReducer.levels,
	// mapAddress: state.googleMapReducer.address.addressArray,
	// mapAddressFormat: state.googleMapReducer.address.formatted_address,
});

const mapDispatchToProps = {
  onSubmit: fetchActivity,
  getBannerList: getBannerList,
  getLevels: getLevels,
  onUpdateSearch: updateSearch,
  onUpdateSearchCity: updateSearchCity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySignUp);
