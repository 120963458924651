import React, { Component } from 'react';
import moment from 'moment';
import { TimeForPlay, Modal } from '../../../Common';
import { Wrapper } from '../../styled';
import { ButtonWrapper } from './styled';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = this.toState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activity !== nextProps.activity) {
      this.setState({ ...this.state, ...this.toState(nextProps) });
    }
  }

  toState(props) {
    let time = null;

    const { activity } = props;
    if (activity) {
      time = {
        startDate: moment.unix(activity.start/1000),
        startTime: moment.unix(activity.start/1000).format('HH:mm'),
        endDate: moment.unix(activity.end/1000),
        endTime: moment.unix(activity.end/1000).format('HH:mm'),
      };

      const { frequency } = activity;
      if (frequency) {
        const { type, value } = frequency;
        time.startDate.add(value, type);
        time.endDate.add(value, type);
      }
    }

    return { time };
  }

  getDateTime() {
    if (!this.state.time) {
      return null;
    }

    let { startDate, startTime, endDate, endTime } = this.state.time;
    return {
      start: this.toDateTime(startDate, startTime).unix() * 1000,
      end: this.toDateTime(endDate, endTime).unix() * 1000,
    }
  }

  toDateTime(date, time) {
    return moment(date.format('YYYY/MM/DD') + ' ' + time);
  }

  onChange(element, e) {
    let { startDate, startTime, endDate, endTime } = this.state.time;
    const time = { ...this.state.time };

    if (element === 'startDate') {
      startDate = time[element] = e.d;
    } else if (element === 'startTime') {
      startTime = time[element] = e;
    } else if (element === 'endDate') {
      endDate = time[element] = e.d;
    } else {
      endTime = time[element] = e;
    }

    let start = this.toDateTime(startDate, startTime);
    let end = this.toDateTime(endDate, endTime);

    if (start.isAfter(end)) {
      if (element === 'startDate' || element === 'startTime') {
        time['endDate'] = moment(startDate);
        time['endTime'] = startTime;
      } else {
        time['startDate'] = moment(endDate);
        time['startTime'] = endTime;
      }
    }

    this.setState({ time });
  }

  render() {
    if (!this.state.time) {
      return null;
    }

    const { startDate, startTime, endDate, endTime } = this.state.time;

    return (
      <Wrapper>
        <TimeForPlay
          label='開始時間：'
          date={startDate}
          time={startTime}
          name={'d'}
          onDateChange={(e) => this.onChange('startDate', e)}
          onTimeChange={(e) => this.onChange('startTime', e)}
        />
        <TimeForPlay
          label='結束時間：'
          date={endDate}
          time={endTime}
          name={'d'}
          onDateChange={(e) => this.onChange('endDate', e)}
          onTimeChange={(e) => this.onChange('endTime', e)}
        />
        <ButtonWrapper>
          <Modal
            title='一日後將繼續提醒'
            btnClass='modal-btn small'
            btnText='一日內不再提醒'
            onClick={() => this.props.onPostpone()}
          />
          <Modal
            title='將取消提醒，暫不開團'
            btnClass='modal-btn small'
            btnText='暫不開團並取消提醒'
            onClick={() => this.props.onCancelNotification()}
          />
        </ButtonWrapper>
      </Wrapper>
    );
  }
}
