import { handleActions } from "redux-actions";
const initialState = {
	activityInformation: {},
	joinedList: {},
	userList: {},
	follower: {},
	follow: {},
	payment: {},
	paymentResult: {},
	shouldLoadingOpen: true,
    isGetPaymentRes: false,
};

const groupDetailReducer = handleActions(
	{
		GET_GROUP_DETAIL: (state, { payload }) => {
			return {
                ...state,
                activityInformation: payload,
            };
		},

		SHOULD_LOADING_OPEN: (state, { payload }) => {
			return {
                ...state,
                shouldLoadingOpen: payload,
            };
		},
		
		GET_FOLLOWER: (state, { payload }) => {
			return { ...state, follower: payload };
		},

		GET_JOIN_HOST_DATA: (state, { payload }) => {
			return { ...state, joinHost: payload };
		},

		// signup_group: (state, { payload }) => {
		// 	return { ...state };
		// },

		GET_JOINED_LIST: (state, { payload }) => {
			return { ...state, joinedList: payload };
		},

		FOLLOW_USER: (state, { payload }) => {
			return { ...state };
		},

		UNFOLLOW_USER: (state, { payload }) => {
			return { ...state };
		},
		
		GET_USER_LIST: (state, { payload }) => {
			return { ...state, userList: payload };
		},

		FOLLOW_LIST: (state, { payload }) => {
			return { ...state, follow: payload };
		},

		GET_PAYMENT_RESULT_DATA: (state, { payload }) =>{
			return { ...state, paymentResult: payload };
		},

        SET_IS_GET_PAYMENT_RES: (state, { payload }) => {
            return { ...state, isGetPaymentRes: payload, };
        },
	},
	initialState
);

export default groupDetailReducer;
