import { handleActions } from "redux-actions";

const initialState = {
    createAffiliateData: {},
    getAffiliateStatisticData: {},
    getAffiliateHistoryData: {},
};

const affiliReducer = handleActions(
    {
        CREATE_AFFILIATE_DATA: (state, { payload }) => {
            return { ...state, createAffiliateData: payload }
        },
        GET_AFFILIATE_STATISTIC_DATA: (state, { payload }) => {
            return { ...state, getAffiliateStatisticData: payload }
        },
        GET_AFFILIATE_HISTORY_DATA: (state, { payload }) => {
            return { ...state, getAffiliateHistoryData: payload }
        },
    },
    initialState
);

export default affiliReducer;
