import styled from 'styled-components';

export const Wrapper = styled.div `
    p{
        font-weight:bold
    }

    .icons{
        text-align:center;
        display: flex;
        flex: 1;
        align-items:center; 
        justify-content:center;

        div {
            padding: 0;

            display: flex;
            flex: 1;
            align-items:center; 
            justify-content:center;

            i {
              color: #da263c;
              margin-right: 2px;
            }
            span {
              padding-left:5px;  
              color: #666;
            } 
        }
    }

    .header{
        width:100%
    }

    .tab{
        background: #DF1C2C;
        display: inline-block
    }
`
