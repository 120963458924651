import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getVerify } from "../../../actions";
import { Wrapper } from './styled';

class Redirect extends Component {
    constructor(props) {
        super(props);
        const userInfo = localStorage && localStorage.getItem('myData') ? JSON.parse(localStorage.getItem("myData")) : '';
        const linkQuery = this.props.location.search;
        const LinkArr = linkQuery.split('&');
        let clientId;
        let redirectUri;
        let scope;

        // 判斷是否 OAuth 所需要的資料都有拿到
        if (this.props.location.pathname === "/dialog/oauth") {
            if (LinkArr.length !== 3) {
                this.props.history.replace("/dialog/error");
            } else {
                clientId = LinkArr[0].split('client_id=')[1];
                redirectUri = LinkArr[1].split('redirect_uri=')[1];
                scope = LinkArr[2].split('scope=')[1];
            }
        }

        const state = this.props.location.state;
        this.state = {
            userInfo: userInfo || '',
            clientId: state ? state.clientId : clientId,
            redirectUri: state ? state.redirectUri : redirectUri,
            scope: state ? state.scope : scope,
            isAuthorization: state ? state.isAuthorization : false,
            code: state ? state.code : '',
            status: state ? state.status : '',
        }
    }

    componentDidMount() {
        const { clientId, redirectUri, scope, code, status } = this.state;
        if (this.props.location.pathname === "/dialog/oauth") {
            this.props.getVerify({'clientId': clientId, 'redirectUri': redirectUri, 'scope': scope});
        }

        if (code && status === 2) {
            // 首次登入授權
            let path;
            if(redirectUri.indexOf('http') < 0){
                path = `https://${redirectUri}?code=${code}`;
            } else {
                path = `${redirectUri}?code=${code}`;
            }
            setTimeout(() => { window.location.href = path }, 2000);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // "description": "資料驗證狀態 (0: 未登入, 1: 已登入未驗證, 2: 已登入已驗證)"
        const { userInfo, clientId, redirectUri, scope, code, status } = this.state;
        const { verifyData } = this.props;
        const getStatus = verifyData ? verifyData.status : status;
        const getCode = verifyData ? verifyData.code : code;

        if (getStatus !== 2) {
            if (!prevProps.verifyData.status && !prevState.status && JSON.stringify(getStatus)) {
                if (getStatus === 0) {
                    // console.log('User login has not been completed yet')
                    this.props.history.replace({ pathname: '/dialog/login', state: { clientId: clientId, redirectUri: redirectUri, scope: scope } });
                } else if (getStatus === 1) {
                    // console.log('User has login but no verification')
                    this.props.history.replace({ pathname: '/dialog/ask_for_authorization', state: { userInfo: userInfo, clientId: clientId, redirectUri: redirectUri, scope: scope } });
                }
            }
        } else if (prevProps.verifyData.code === undefined && !prevState.code && getCode) {
            // 已經登入授權過, 再次登入
            this.setState({ isAuthorization: true })
            let path;
            if(redirectUri.indexOf('http') < 0){
                path = `https://${redirectUri}?code=${getCode}`;
            } else {
                path = `${redirectUri}?code=${getCode}`;
            }
            setTimeout(() => { window.location.href = path }, 2000);
        }
    }


    render() {
        const { isAuthorization } = this.state;
        const style = {
            backgroundColor: `#000`,
            width: `100%`,
            height: `100%`,
            position: `fixed`,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 2147483647,
        }
        return (
            <div style={style}>
                <Wrapper>
                    <div className="loadingIO">
                        <div className="lds-css ng-scope">
                            <div className="lds-eclipse">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div className="text">
                    {
                        isAuthorization ?
                            <h3>已登入會員並已授權 Bonny-Live 使用..</h3>
                        :
                            <h3>轉址中..</h3>
                    }
                    </div>
                </Wrapper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    verifyData: state.oauthReducer.verifyData,
});

const mapDispatchToProps = ({
    getVerify: getVerify,
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Redirect));
