import React, { Component } from "react";
import { Wrapper } from "./styled";

class ErrorPage extends Component {

    render() {
        return (
            <Wrapper>
                <div id='error-inner'>
                    {/*<div className="logo">
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/logo/BonnySports-B.png" alt="Bonny" />
                    </div>*/}
                    <div className="tv">
                        <div className="tv-frame">
                            <div className="aerial"></div>
                            <div className="buttons"></div>
                        </div>
                    </div>
                    <h2 className="text">
                        Oops! 無法瀏覽此頁面
                    </h2>
                    <h1 className="back-home">
                        <div title="返回上頁" onClick={this.props.history.goBack}>
                            返回上頁
                            <i className="material-icons">
                                arrow_right
                            </i>
                        </div>
                    </h1>
                </div>
            </Wrapper>
        );
    }
}


export default ErrorPage;