import styled from "styled-components";

export const Header2 = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
`

export const ModalContent = styled.div`
    .shareLinkButton{
        text-align: center;
        border: 1px solid #5590BE;
        padding: 5px;
        border-radius: 20px;
        cursor: pointer;
    }
    
    div.aLinkDiv{
        a{
            cursor: pointer; 
            width: 100px;
            height: 1.5em;
            line-height: 1.5em;
            font-size: 12px;
            border-radius: 999rem;
            color: rgb(223, 28, 44);
            text-align: center;
            border: 1px solid #ccc;
            padding: 6px 0px;
            margin: 20px auto auto;
            display: block;
            &:hover {
                color: #fff;
                background-color: rgb(223, 28, 44);
            }
        }
    }
`;

export const Buttons = styled.div`
    margin: 20px -22px -20px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    button {
        background: #fff;
        line-height: 3em;
        width: 50%;
        font-size: 16px;
    }
`;

export const Button = styled.a`
    margin: 20px -22px -20px;
    display: block;
    background: #fff;
    line-height: 52px;
    width: 100%;
    font-size: 16px;
    padding: 0 22px;
    text-align: center;
    border-top: 1px solid #ccc;
    &:hover {
        cursor: pointer;
    }
`;

export const LeftButton = styled.button`
    border-bottom-left-radius: 20px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: none;
    border-bottom: none;
    flex: 1;
`;

export const RightButton = styled.button`
    border-bottom-right-radius: 20px;
    border-top: 1px solid #ccc;
    border-left: none;
    border-right: none;
    border-bottom: none;
    flex: 1;
`;
